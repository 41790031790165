import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "xincome-section debt" }
const _hoisted_2 = { class: "amount" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }


import { Applicant, LOSApplication } from "@/models/opal";

interface Props {
  application: LOSApplication | any;
  primaryApplicant: Applicant | any;
  secondaryApplicant: Applicant | any;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'IncomeDebt',
  props: {
    application: {},
    primaryApplicant: {},
    secondaryApplicant: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.secondaryApplicant)
        ? (_openBlock(), _createElementBlock("h2", _hoisted_3, _toDisplayString(_ctx.application.debtData["secondary"]?.debtValue
          ? `$${parseInt(_ctx.application.debtData["secondary"]?.debtValue)?.toLocaleString("en-US")}`
          : "Not Provided"), 1))
        : (_openBlock(), _createElementBlock("h2", _hoisted_4, _toDisplayString(_ctx.application.debtData["primary"]?.debtValue
          ? `$${parseInt(_ctx.application.debtData["primary"]?.debtValue)?.toLocaleString("en-US")}`
          : "Not Provided"), 1)),
      _cache[0] || (_cache[0] = _createElementVNode("h5", null, "Total Monthly Debt", -1))
    ])
  ]))
}
}

})