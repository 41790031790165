import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "xpage-content-page",
  id: "xapplication-tab"
}
const _hoisted_2 = { class: "xtitlearea" }
const _hoisted_3 = { class: "name-container" }

import { ref, computed } from "vue";
import Notifications from "@/components/shared/Notifications.vue";
import ApplicationComments from "@/components/ApplicationComments.vue";
import NameButton from "@/components/application/NameButton.vue";
import ApplicationForms from "@/components/application/ApplicationForms.vue";
import PermissionsWrapper from "@/components/wrappers/PermissionsWrapper.vue";
import { Permissions, PermissionLevels } from "@/models/permissions";
import PermissionsButton from "@/components/wrappers/PermissionsButton.vue";
import { LOSApplication } from "@/models/opal";

interface Props {
  isTabActive: boolean;
  application: LOSApplication;
  applicant: any;
  applicants: any;
  applicantType: any;
  authorizedThirdParty?: any;
  backup: any;
  commentsId: any;
  disabled: boolean;
  canEdit: boolean;
  notificationsData: object;
  primaryApplicant: any;
  secondaryApplicant?: any;
  showForms: any;
  store: any;
  comments: any;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ApplicationTab',
  props: {
    isTabActive: { type: Boolean },
    application: {},
    applicant: {},
    applicants: {},
    applicantType: {},
    authorizedThirdParty: {},
    backup: {},
    commentsId: {},
    disabled: { type: Boolean },
    canEdit: { type: Boolean },
    notificationsData: {},
    primaryApplicant: {},
    secondaryApplicant: {},
    showForms: {},
    store: {},
    comments: {}
  },
  emits: [
  "editBtnClicked",
  "nameBtnClicked",
  "addAuthorizedThirdParty",
  "getApplication",
  "getAuthorizedThirdParty",
  "disableForm",
  "applicantUpdated",
  "authorizedThirdPartyUpdated",
  "discardChanges",
  "schoolConfigurationUpdated",
],
  setup(__props: any, { emit: __emit }) {

const emit = __emit;

const props = __props;
const cancelBtnClicked = ref<boolean>(false);
const commentData = computed(() => props.application.commentData);

function isSelected(applicantType: any) {
  return props?.applicants?.selected === applicantType;
}

const isDisabled = ref(props.disabled);

const onEditClick = () => {
  if (isDisabled.value) {
    cancelBtnClicked.value = false;
    emit("editBtnClicked");
  } else {
    cancelBtnClicked.value = true;
  }
};

return (_ctx: any,_cache: any) => {
  return (_ctx.isTabActive)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(Notifications, { notificationsData: _ctx.notificationsData }, null, 8, ["notificationsData"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(PermissionsButton, {
            permissionIds: [_unref(Permissions).ProgramRequirements],
            permissionLevel: _unref(PermissionLevels).Update,
            disabled: !_ctx.canEdit,
            showDisabledIfNotVisible: true,
            onClick: onEditClick,
            class: "xedit"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.disabled ? "Edit" : "Cancel"), 1)
            ]),
            _: 1
          }, 8, ["permissionIds", "permissionLevel", "disabled"]),
          _cache[11] || (_cache[11] = _createElementVNode("h2", null, "Application", -1)),
          _createVNode(ApplicationComments, {
            type: "application",
            id: _ctx.commentsId,
            comments: commentData.value
          }, null, 8, ["id", "comments"])
        ]),
        _cache[13] || (_cache[13] = _createElementVNode("h3", { class: "smallcapitals" }, "Select an Applicant:", -1)),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(NameButton, {
            applicant: _ctx.primaryApplicant || [],
            selected: isSelected('primary'),
            applicantType: 'primary',
            onNameBtnClicked: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('nameBtnClicked', 'primary')))
          }, null, 8, ["applicant", "selected"]),
          (_ctx.secondaryApplicant?.id)
            ? (_openBlock(), _createBlock(NameButton, {
                key: 0,
                applicant: _ctx.secondaryApplicant,
                selected: isSelected('secondary'),
                applicantType: 'secondary',
                onNameBtnClicked: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('nameBtnClicked', 'secondary')))
              }, null, 8, ["applicant", "selected"]))
            : _createCommentVNode("", true),
          _createVNode(PermissionsWrapper, {
            permissionLevel: _unref(PermissionLevels).Read
          }, {
            default: _withCtx(() => [
              (_ctx.authorizedThirdParty?.id !== 0)
                ? (_openBlock(), _createBlock(NameButton, {
                    key: 0,
                    applicant: _ctx.authorizedThirdParty,
                    selected: isSelected('authorizedThirdParty'),
                    applicantType: 'authorizedThirdParty',
                    onNameBtnClicked: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('nameBtnClicked', 'authorizedThirdParty')))
                  }, null, 8, ["applicant", "selected"]))
                : (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass(["xnamebutton", { xselected: isSelected('authorizedThirdParty') }]),
                    onClick: _cache[3] || (_cache[3] = ($event: any) => {
            _ctx.$emit('nameBtnClicked', 'authorizedThirdParty');
            _ctx.$emit('addAuthorizedThirdParty');
          })
                  }, _cache[12] || (_cache[12] = [
                    _createElementVNode("h5", null, "+ Add Authorized Third Party", -1)
                  ]), 2))
            ]),
            _: 1
          }, 8, ["permissionLevel"])
        ]),
        _createVNode(ApplicationForms, {
          application: _ctx.application,
          applicants: _ctx.applicants,
          applicant: _ctx.applicant,
          primaryApplicant: _ctx.primaryApplicant,
          secondaryApplicant: _ctx.secondaryApplicant,
          authorizedThirdParty: _ctx.authorizedThirdParty,
          applicantType: _ctx.applicantType,
          store: _ctx.store,
          disabled: _ctx.disabled,
          showForms: _ctx.showForms,
          backup: _ctx.backup,
          canEdit: _ctx.canEdit,
          cancelBtnClicked: cancelBtnClicked.value,
          onDisableForm: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('disableForm'))),
          onGetApplication: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('getApplication'))),
          onGetAuthorizedThirdParty: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('getAuthorizedThirdParty'))),
          onApplicantUpdated: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('applicantUpdated', $event))),
          onSchoolConfigurationUpdated: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('schoolConfigurationUpdated', $event))),
          onAuthorizedThirdPartyUpdated: _cache[9] || (_cache[9] = ($event: any) => (
        _ctx.$emit('authorizedThirdPartyUpdated', $event)
      )),
          onDiscardChanges: _cache[10] || (_cache[10] = ($event: any) => (_ctx.$emit('discardChanges', $event)))
        }, null, 8, ["application", "applicants", "applicant", "primaryApplicant", "secondaryApplicant", "authorizedThirdParty", "applicantType", "store", "disabled", "showForms", "backup", "canEdit", "cancelBtnClicked"])
      ]))
    : _createCommentVNode("", true)
}
}

})