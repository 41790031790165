import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed, toRefs, ref, watch } from "vue";
import { usePermissionsAccess } from "@/composables/usePermissionsAccess";
import { Permissions, PermissionLevels } from "@/models/permissions";


export default /*@__PURE__*/_defineComponent({
  __name: 'PermissionsFormField',
  props: {
  permissionIds: {
    type: Array as () => Permissions[],
    default: () => [],
  },
  permissionLevel: {
    type: Number as () => PermissionLevels,
    default: PermissionLevels.None,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  showDisabledIfNotVisible: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: String,
    default: "",
  },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const props = __props;

const {
  permissionIds,
  permissionLevel,
  disabled,
  showDisabledIfNotVisible,
  modelValue,
} = toRefs(props);

const emit = __emit;

const permissionsAccess = usePermissionsAccess();

const internalModel = computed({
  get: () => modelValue.value,
  set: (value) => {
    emit("update:modelValue", value);
  },
});

const isVisible = computed(() => {
  const permissionIdsValue = permissionIds?.value || [];
  return (
    permissionIdsValue.length === 0 ||
    permissionsAccess.hasPermission(permissionIdsValue, permissionLevel.value)
  );
});

const isDisabled = computed(() => {
  return disabled.value || (!isVisible.value && showDisabledIfNotVisible.value);
});

const wrapperClass = computed(() => ({
  "permission-wrapper": true,
  "disable-clicks": isDisabled.value,
}));


return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_FormKit, _mergeProps({
      disabled: isDisabled.value,
      type: "text",
      class: wrapperClass.value
    }, _ctx.$attrs, {
      modelValue: internalModel.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((internalModel).value = $event))
    }), null, 16, ["disabled", "class", "modelValue"])
  ]))
}
}

})