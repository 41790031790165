import { Identity } from '@/types/identity';
import { defineStore } from 'pinia'
import { $api } from "@/services/api1";
import store from '@/store';
import { useApplicationStore } from '@/store/store/applicationVMStore';
import axios from 'axios';

export const useIdentityStore = defineStore("identityVM", {
  state: () => {
    return {
      identity: {
        requirementDecision: {
          primaryIdVerificationRun: false,
          requirementDecisionId: 0,
          requirementStatusId: 0,
          requirementStatusName: "Open",
          requirementTypeId: 2,
          requirementTypeName: "Identity",
        },
        idVerifications: {},
      } as Identity,
      selectedApplicantId: 0,
      lexisNexisResults: {},
    };
  },

  getters: {
    requirementDecision: (state) => state.identity.requirementDecision,
    idVerifications: (state) => state.identity.idVerifications,
  },

  actions: {
    async getIdentity(applicationId: number) {
      try {
        const response = await $api.id.getIdentityDetails(applicationId);
        if (response) {
          this.identity = {
            ...this.identity,
            requirementDecision: response.requirementDecision || {},
            idVerifications: response.idVerifications || {},
          };
          console.log("Identity data fetched & updated");}
      } catch (error) {
        console.error("Failed to fetch:", error);
      }
    },

    updateRequirementDecision(updateDecision) {
      if (updateDecision) {
        this.identity.requirementDecision = {
          ...this.identity.requirementDecision,
          ...updateDecision,
        };
      } else {
        console.log("No update decision provided");
      }
    },

    async getLexisNexisData(personId: number) {
      const applicationStore = useApplicationStore();
      const payload = {
        username: store.state.oidcStore.user?.preferred_username || "",
        applicantId: applicationStore.ApplicantSelected.id || 0,
        personId: personId || 0,
      };

      try {
        const response = await axios.post(
          "/api/id/ExecuteIdVerification",
          payload
        );

        if (response && response.data) {
          this.lexisNexisResults = response.data;
          console.log(
            "LexisNexis data fetched & updated: ",
            this.lexisNexisResults
          );

          await this.getIdentity(this.selectedApplicantId);
        }
      } catch (error) {
        console.error("Failed to fetch:", error);
      }
    },
  },
});
