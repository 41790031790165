import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "section-header" }
const _hoisted_2 = { class: "section-header-title" }

import { reactive, computed, ref, watch } from "vue";
  import { useStore } from "vuex";
  import StipulationsTable from "@/components/stipulations/StipulationsTable.vue";
  import AddStipulationForm from "@/components/stipulations/AddStipulationForm.vue";
  import { LOSApplication, Stipulation, Section } from "@/models/opal";
  import PermissionsButton from "../wrappers/PermissionsButton.vue";
  import { Permissions, PermissionLevels } from "@/models/permissions";

  interface Props {
    section: Section;
    collapsed: boolean;
    stipulations: Array<Stipulation>;
    application: LOSApplication;

  }
  
export default /*@__PURE__*/_defineComponent({
  __name: 'StipulationsSection',
  props: {
    section: {},
    collapsed: { type: Boolean },
    stipulations: {},
    application: {}
  },
  emits: [
    "getApplicationStips",
    "updateStipulationsData",
    "stipsUpdated",
    "stipAdded",
    "stipUpdated",
    "getApplicationComments",
  ],
  setup(__props: any, { emit: __emit }) {

  const emit = __emit;

  const store = useStore();

  const props = __props;
  // const sortedSectionItems = computed(() => props.section.items);
  const applicationStatus = computed(() =>
    props.application.data.applicationStatusName?.trim().toLowerCase()
  );

  let section = reactive({} as Section);
  // let filteredStips = reactive([] as any[]);
  let info = reactive({} as any);
  info.section = computed(() => props.section);
  info.collapsed = computed(() => props.collapsed);
  const addStip = ref<boolean>(false);

  function handleStipForm() {
    addStip.value = !addStip.value;
  }

  const sortedStips = computed(() => props.section.items);

  section = Object.assign({}, props.section);

  watch(
    () => props.section.items,
    () => {
      // console.log("props.section.items data changed");
      section = Object.assign({}, props.section);
    },
    { deep: true }
  );

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["section", 
      _unref(info).section.collapsible && _unref(info).section.collapsed
        ? 'collapsed'
        : 'expanded'
    ])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, _toDisplayString(_unref(info).section.title), 1),
      (
          _unref(info).section.name === 'open' &&
          (applicationStatus.value === 'submitted & processing' ||
            applicationStatus.value === 'evaluation')
        )
        ? (_openBlock(), _createBlock(PermissionsButton, {
            key: 0,
            showDisabledIfNotVisible: true,
            permissionIds: [_unref(Permissions).Stipulations],
            permissionLevel: _unref(PermissionLevels).Update,
            class: "xedit right",
            onClick: handleStipForm
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(addStip.value ? "Cancel" : "Add Stip"), 1)
            ]),
            _: 1
          }, 8, ["permissionIds", "permissionLevel"]))
        : _createCommentVNode("", true),
      (_unref(info).section.collapsible && sortedStips.value.length > 0)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            class: "section-header-toggle toggle",
            type: "button",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(info).section.collapsed = !_unref(info).section.collapsed))
          }, _toDisplayString(!_unref(info).section.collapsed ? "-" : "+"), 1))
        : _createCommentVNode("", true)
    ]),
    (!_unref(info).section.collapsed)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["section-content", 
            _unref(info).section.collapsible && _unref(info).section.collapsed
              ? 'collapsed'
              : 'expanded'
          ])
        }, [
          _createVNode(StipulationsTable, {
            application: _ctx.application,
            stipulations: _ctx.stipulations,
            status: _unref(section).name,
            items: _unref(section).items,
            onUpdateStipulationsData: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('updateStipulationsData', $event))),
            onGetApplicationComments: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('getApplicationComments'))),
            onGetApplicationStips: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('getApplicationStips'))),
            onStipsUpdated: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('stipsUpdated', $event))),
            onStipUpdated: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('stipUpdated', $event)))
          }, null, 8, ["application", "stipulations", "status", "items"])
        ], 2))
      : _createCommentVNode("", true),
    (addStip.value)
      ? (_openBlock(), _createBlock(AddStipulationForm, {
          key: 1,
          application: _ctx.application,
          stipulations: _ctx.stipulations,
          onClose: _cache[6] || (_cache[6] = ($event: any) => (addStip.value = false)),
          onCloseModal: _cache[7] || (_cache[7] = ($event: any) => (addStip.value = false)),
          onGetApplicationStips: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('getApplicationStips'))),
          onGetApplicationComments: _cache[9] || (_cache[9] = ($event: any) => (_ctx.$emit('getApplicationComments'))),
          onStipsUpdated: _cache[10] || (_cache[10] = ($event: any) => (_ctx.$emit('stipsUpdated', $event))),
          onStipAdded: _cache[11] || (_cache[11] = ($event: any) => (_ctx.$emit('stipAdded', $event)))
        }, null, 8, ["application", "stipulations"]))
      : _createCommentVNode("", true)
  ], 2))
}
}

})