import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "card-grid" }
const _hoisted_2 = { key: 0 }

import '@/assets/css/card.css';
	
	import { LoanDetails } from '@/models/loans';
	import ServicerInterestDetailsCard from '../loc/ServicerInterestDetailsCard.vue';
	import LoanProgramDetailsCard from '../loc/LoanProgramDetailsCard.vue';
	import ServicerDetailsCard from '../loc/ServicerDetailsCard.vue';
	import ImportantDatesCard from '../loc/ImportantDatesCard.vue';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'DetailsTab',
  props: {
    loanDetails: {}
  },
  setup(__props: any) {

	

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.loanDetails.loan.productType === 2)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(ImportantDatesCard, {
              keyDates: _ctx.loanDetails.keyDates
            }, null, 8, ["keyDates"])
          ]))
        : _createCommentVNode("", true),
      _createVNode(ServicerInterestDetailsCard, { loanDetails: _ctx.loanDetails }, null, 8, ["loanDetails"]),
      _createVNode(LoanProgramDetailsCard, { loanDetails: _ctx.loanDetails }, null, 8, ["loanDetails"]),
      _createVNode(ServicerDetailsCard, { loanDetails: _ctx.loanDetails }, null, 8, ["loanDetails"])
    ])
  ]))
}
}

})