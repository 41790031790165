import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/images/logo-student-choice.png'


const _hoisted_1 = { class: "xsidebar-head" }
const _hoisted_2 = { class: "xsidebar-nav" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 0,
  class: "batch-id-display"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { style: {"display":"none"} }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { key: 3 }

import { ref, computed, watch } from "vue";
	import store from "@/store";
	import { useRoute, useRouter } from "vue-router";
	import { Permissions, PermissionLevels } from "@/models/permissions";
	import PermissionsWrapper from "../wrappers/PermissionsWrapper.vue";
	import { useApplicantsStore } from "@/store/store/applicantsStore";
	import { useBatchesStore } from "@/store/store/batchesStore";

  
export default /*@__PURE__*/_defineComponent({
  __name: 'Sidebar',
  setup(__props) {

	const batchesStore = useBatchesStore();
  const route = useRoute();
  const router = useRouter();
  const batchId = computed(() => batchesStore.getBatchId());
  const batchData = ref<{
    disbursementBatch: any;
    disbursements: any[];
  } | null>(null);

	const userType = ref<"Admin" | "Manager" | "Concierge" | "Processor">(
		"Admin"
	);

	const canEdit = useApplicantsStore();

	function isActive(routeName: string, matchPaths: string[] = []) {
		const currentRoute = route.name;
		const currentPath = route.path;

		const isRouteNameMatch = currentRoute === routeName;
		const isPathMatch = matchPaths.some(path => currentPath.includes(path));

		return isRouteNameMatch || isPathMatch;
	}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["xsidebar", [_unref(store).state.mobileMenu ? 'xsidebar-visible' : '']])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_link, { to: "/" }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("img", {
            src: _imports_0,
            alt: "Student Choice Logo"
          }, null, -1)
        ])),
        _: 1
      }),
      _cache[1] || (_cache[1] = _createElementVNode("h1", null, "Loan Origination System", -1))
    ]),
    _createElementVNode("nav", _hoisted_2, [
      (userType.value == 'Admin')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _cache[6] || (_cache[6] = _createElementVNode("h3", null, "Menu", -1)),
            _createVNode(_component_router_link, {
              to: "/dashboard",
              class: "icon-home",
              style: {"display":"none"}
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("Dashboard")
              ])),
              _: 1
            }),
            _createVNode(_component_router_link, {
              to: "/applications",
              class: _normalizeClass(["icon-doc-text", { 'router-link-active': _ctx.$route.name == 'Application' }])
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("Applications")
              ])),
              _: 1
            }, 8, ["class"]),
            _createVNode(_component_router_link, {
              to: "/loans",
              class: _normalizeClass(["icon-money", { 'router-link-active': _ctx.$route.name == 'Loan' }])
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode(" Loans ")
              ])),
              _: 1
            }, 8, ["class"]),
            _createVNode(PermissionsWrapper, {
              permissionIds: [_unref(Permissions).DisbursementBatches],
              permissionLevel: _unref(PermissionLevels).Read
            }, {
              default: _withCtx(() => [
                _createVNode(_component_router_link, {
                  to: "/batches",
                  class: _normalizeClass(["icon-money-1", {
							'router-link-active': isActive('Batches', ['disbursementbatches', 'payoff-batches']),
						}])
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode(" Disbursement Batches ")
                  ])),
                  _: 1
                }, 8, ["class"]),
                (batchId.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(_component_font_awesome_icon, {
                        icon: ['fas', 'chevron-left'],
                        class: "chevron-left"
                      }),
                      _createVNode(_component_font_awesome_icon, {
                        icon: ['fas', 'coins'],
                        class: "batch-edit-icon"
                      }),
                      _createTextVNode(" Batch ID: " + _toDisplayString(batchId.value), 1)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["permissionIds", "permissionLevel"])
          ]))
        : _createCommentVNode("", true),
      _createTextVNode(" " + _toDisplayString(batchData.value) + " ", 1),
      (userType.value == 'Manager')
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_router_link, {
              to: "/dashboard",
              class: "icon-home"
            }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode("Dashboard")
              ])),
              _: 1
            }),
            _createVNode(_component_router_link, {
              to: "/applications",
              class: _normalizeClass(["icon-doc-text", { 'router-link-active': _ctx.$route.name == 'Application' }])
            }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode(" Applications ")
              ])),
              _: 1
            }, 8, ["class"]),
            _createVNode(_component_router_link, {
              to: "/loans",
              class: "icon-money",
              style: {"display":"none"}
            }, {
              default: _withCtx(() => _cache[9] || (_cache[9] = [
                _createTextVNode(" Loans ")
              ])),
              _: 1
            }),
            _createElementVNode("div", _hoisted_6, [
              _cache[11] || (_cache[11] = _createElementVNode("h3", null, "Configuration", -1)),
              _createVNode(_component_router_link, { to: "/administration" }, {
                default: _withCtx(() => _cache[10] || (_cache[10] = [
                  _createTextVNode("Administration")
                ])),
                _: 1
              })
            ])
          ]))
        : _createCommentVNode("", true),
      (userType.value == 'Concierge')
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createVNode(_component_router_link, {
              to: "/dashboard",
              class: "icon-home"
            }, {
              default: _withCtx(() => _cache[12] || (_cache[12] = [
                _createTextVNode("Dashboard")
              ])),
              _: 1
            }),
            _createVNode(_component_router_link, {
              to: "/applications",
              class: _normalizeClass(["icon-doc-text", { 'router-link-active': _ctx.$route.name == 'Application' }])
            }, {
              default: _withCtx(() => _cache[13] || (_cache[13] = [
                _createTextVNode(" Applications ")
              ])),
              _: 1
            }, 8, ["class"]),
            _createVNode(_component_router_link, {
              to: "/loans",
              class: "icon-money",
              style: {"display":"none"}
            }, {
              default: _withCtx(() => _cache[14] || (_cache[14] = [
                _createTextVNode(" Loans ")
              ])),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      (userType.value == 'Processor')
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createVNode(_component_router_link, {
              to: "/dashboard",
              class: "icon-home"
            }, {
              default: _withCtx(() => _cache[15] || (_cache[15] = [
                _createTextVNode("Dashboard")
              ])),
              _: 1
            }),
            _createVNode(_component_router_link, {
              to: "/processing",
              class: "icon-doc-text"
            }, {
              default: _withCtx(() => _cache[16] || (_cache[16] = [
                _createTextVNode(" Processing ")
              ])),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
}

})