import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "xincome-section dti" }
const _hoisted_2 = { class: "dti" }
const _hoisted_3 = { class: "box hide900" }
const _hoisted_4 = {
  class: "mask",
  style: {"margin":"auto"}
}
const _hoisted_5 = { class: "gauge-text" }
const _hoisted_6 = {
  key: 1,
  class: "semi-circle--mask default-dti"
}
const _hoisted_7 = { class: "show900" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { style: {"text-align":"center"} }

import {
  computed,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { Applicant } from "@/models/opal";

interface Props {
  primaryApplicant: Applicant | any;
  secondaryApplicant: Applicant | any;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'DebtToIncome',
  props: {
    primaryApplicant: {},
    secondaryApplicant: {}
  },
  setup(__props: any) {

const props = __props;

const verifiedDti = computed(() => {
  let verified = 0;
  if (props.secondaryApplicant.dti) {
    props.secondaryApplicant.dti.forEach((dti: any) => {
      // calculate total verified
      if (dti.verified === true) {
        verified += dti.calculatedDTI;
      }
    });
  } else if (props.primaryApplicant?.dti && !props.secondaryApplicant) {
    props.primaryApplicant.dti.forEach((dti: any) => {
      // calculate total verified
      if (dti.verified === true) {
        verified += dti.calculatedDTI;
      }
    });
  }

  return verified;
});

const statedDti = computed(() => {
  let stated = 0;
  if (props.secondaryApplicant.dti) {
    props.secondaryApplicant.dti.forEach((dti: any) => {
      // calculate total verified
      if (dti.verified === false) {
        stated += dti.calculatedDTI;
      }
    });
  } else if (props.primaryApplicant?.dti && !props.secondaryApplicant) {
    props.primaryApplicant.dti.forEach((dti: any) => {
      // calculate total verified
      if (dti.verified === false) {
        stated += dti.calculatedDTI;
      }
    });
  }

  return stated;
});

const applicantCalculatedDti = computed(() => {
  return `
  -webkit-transform:rotate(${(verifiedDti.value || 0) * 180}deg);
  -moz-transform:rotate(${(verifiedDti.value || 0) * 180}deg);
  transform:rotate(${(verifiedDti.value || 0) * 180}deg);`;
});

function formatAsPercentage(dti: any) {
  const dtiPercentage = (Number(dti) * 100).toFixed(0);
  return dtiPercentage;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", null, _toDisplayString(verifiedDti.value ? `${formatAsPercentage(verifiedDti.value)}%` : "N/A"), 1)
          ]),
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "semi-circle" }, null, -1)),
          (verifiedDti.value > 0)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "semi-circle--mask",
                style: _normalizeStyle(applicantCalculatedDti.value)
              }, null, 4))
            : (_openBlock(), _createElementBlock("div", _hoisted_6))
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        (verifiedDti.value > 0)
          ? (_openBlock(), _createElementBlock("h2", _hoisted_8, _toDisplayString(`${formatAsPercentage(verifiedDti.value)}%`), 1))
          : (_openBlock(), _createElementBlock("h2", _hoisted_9, "N/A"))
      ]),
      _createElementVNode("h5", _hoisted_10, " Stated Debt-To-Income " + _toDisplayString(statedDti.value ? `${formatAsPercentage(statedDti.value)}%` : "N/A"), 1),
      _cache[1] || (_cache[1] = _createElementVNode("div", null, null, -1))
    ])
  ]))
}
}

})