export enum DocumentStatusTypes {
  NOT_REVIEWED = 1,
  ACCEPTED = 2,
  GENERATED = 3,
  SIGNED = 4,
  ESCALATED = 5,
  REJECTED = 6,
}

export enum DocumentStatusReasonTypes {
  UNREADABLE = 1,
  INCOMPLETE = 2,
  NOT_USABLE = 3,
  DATA_INCOMPLETE = 4,
  UNIQUE_DOCUMENT_TYPE = 5,
  UNSURE_OF_DOCUMENT_INFO = 6,
  PARTIALLY_USABLE_DOCUMENT = 7,
  OTHER = 8,
  DUPLICATE = 9,
}

export class DocumentStatusTypeIds {
  public static get NOT_REVIEWED(): number {
    return DocumentStatusTypes.NOT_REVIEWED;
  }
  public static get ACCEPTED(): number {
    return DocumentStatusTypes.ACCEPTED;
  }
  public static get GENERATED(): number {
    return DocumentStatusTypes.GENERATED;
  }
  public static get SIGNED(): number {
    return DocumentStatusTypes.SIGNED;
  }
  public static get ESCALATED(): number {
    return DocumentStatusTypes.ESCALATED;
  }
  public static get REJECTED(): number {
    return DocumentStatusTypes.REJECTED;
  }
}

export class DocumentStatusReasonIds {
  public static get UNREADABLE(): number {
    return DocumentStatusReasonTypes.UNREADABLE;
  }
  public static get INCOMPLETE(): number {
    return DocumentStatusReasonTypes.INCOMPLETE;
  }
  public static get NOT_USABLE(): number {
    return DocumentStatusReasonTypes.NOT_USABLE;
  }
  public static get DATA_INCOMPLETE(): number {
    return DocumentStatusReasonTypes.DATA_INCOMPLETE;
  }
  public static get UNIQUE_DOCUMENT_TYPE(): number {
    return DocumentStatusReasonTypes.UNIQUE_DOCUMENT_TYPE;
  }
  public static get UNSURE_OF_DOCUMENT_INFO(): number {
    return DocumentStatusReasonTypes.UNSURE_OF_DOCUMENT_INFO;
  }
  public static get PARTIALLY_USABLE_DOCUMENT(): number {
    return DocumentStatusReasonTypes.PARTIALLY_USABLE_DOCUMENT;
  }
  public static get OTHER(): number {
    return DocumentStatusReasonTypes.OTHER;
  }
  public static get DUPLICATE(): number {
    return DocumentStatusReasonTypes.DUPLICATE;
  }
}
