import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "xincome-section total" }
const _hoisted_2 = { class: "amount" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "stated-amount" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }

import {
  computed,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { Applicant, LOSApplication } from "@/models/opal";

interface Props {
  primaryApplicant: Applicant | any;
  secondaryApplicant: Applicant | any;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'Income',
  props: {
    primaryApplicant: {},
    secondaryApplicant: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.secondaryApplicant)
        ? (_openBlock(), _createElementBlock("h2", _hoisted_3, _toDisplayString(_ctx.secondaryApplicant.totalVerifiedIncome
            ? `$${_ctx.secondaryApplicant.totalVerifiedIncome.toLocaleString(
                "en-US"
              )}`
            : "$0"), 1))
        : (_openBlock(), _createElementBlock("h2", _hoisted_4, _toDisplayString(_ctx.primaryApplicant.totalVerifiedIncome
            ? `$${_ctx.primaryApplicant.totalVerifiedIncome.toLocaleString("en-US")}`
            : "$0"), 1)),
      _cache[0] || (_cache[0] = _createElementVNode("h5", null, "Verified Current Income", -1))
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.secondaryApplicant)
        ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.secondaryApplicant.statedIncome
            ? `($${_ctx.secondaryApplicant.statedIncome.toLocaleString(
                "en-US"
              )} STATED)`
            : "No Stated Income"), 1))
        : (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.primaryApplicant.statedIncome
            ? `($${_ctx.primaryApplicant.statedIncome.toLocaleString(
                "en-US"
              )} STATED)`
            : "No Stated Income"), 1))
    ])
  ]))
}
}

})