import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "xpage-content-page",
  id: "stipulations-tab"
}
const _hoisted_2 = { class: "xtitlearea" }
const _hoisted_3 = { class: "stipulations" }

import {
    reactive,
    computed,
    ref,
    watch,
    onMounted,
  } from "vue";
  import { useStore } from "vuex";
  import StipulationsSection from "@/components/stipulations/StipulationsSection.vue";
  import ApplicationComments from "@/components/ApplicationComments.vue";
  import { LOSApplication, Stipulation, Section } from "@/models/opal";
  import AddStipulationForm from "@/components/stipulations/AddStipulationForm.vue";
  // import { useIsCreditUnionUser } from "@/use/useIsCreditUnionUser";
  import { Permissions, PermissionLevels } from "@/models/permissions";
  import PermissionsButton from "../wrappers/PermissionsButton.vue";
  import PermissionsWrapper from "@/components/wrappers/PermissionsWrapper.vue";

  interface Props {
    isTabActive: boolean;
    commentsId: any;
    commentsComponentKey: any;
    applicants: any;
    application: LOSApplication;
    store?: any;
    primaryApplicant?: any;
    secondaryApplicant?: any;
    canEdit?: boolean
  }
  
export default /*@__PURE__*/_defineComponent({
  __name: 'StipulationsTab',
  props: {
    isTabActive: { type: Boolean },
    commentsId: {},
    commentsComponentKey: {},
    applicants: {},
    application: {},
    store: {},
    primaryApplicant: {},
    secondaryApplicant: {},
    canEdit: { type: Boolean }
  },
  emits: [
    "getApplicationComments",
    "getApplicationStips",
    "getApplicationDocs",
    "getApplicantDocs",
    "stipsUpdated",
    "stipAdded",
    "stipUpdated",
  ],
  setup(__props: any, { emit: __emit }) {

  const store = useStore();
  const emit = __emit;

  const props = __props;
  const commentData = computed(() => props.application.commentData);
  const sections = reactive([
    {
      title: "Open",
      name: "open",
      collapsible: false,
      collapsed: false,
      items: [],
    },
    {
      title: "Pending",
      name: "pending",
      collapsible: false,
      collapsed: false,
      items: [],
    },
    {
      title: "Cancelled",
      name: "cancelled",
      collapsible: true,
      collapsed: true,
      items: [],
    },
    {
      title: "Completed",
      name: "completed",
      collapsible: true,
      collapsed: true,
      items: [],
    },
    {
      title: "CU-Override",
      name: "cu-override",
      collapsible: true,
      collapsed: true,
      items: [],
    },
  ]);
  const stips = computed(() => props.application.stipulationsData);
  // const isCreditUnionUser = useIsCreditUnionUser();
  const allowAddStips = computed(
    () =>
      props.application.data.applicationStatusName?.toLowerCase()?.trim() ===
        "evaluation" ||
      props.application.data.applicationStatusName?.toLowerCase()?.trim() ===
        "submitted & processing"
  );
  const addStip = ref<boolean>(false);
  const showAddStipsButton = computed(() => {
    const show =
      (props.application.stipulationsData.length === 0 &&
        allowAddStips.value) ||
      (props.application.stipulationsData.length > 0 &&
        allowAddStips.value &&
        sections.find((s: Section) => s.name === "open")?.items?.length === 0);
    return show;
  });
  function handleStipForm() {
    addStip.value = !addStip.value;
  }
  function sortItems() {
    sections.forEach((s: any) => {
      // reset items
      s.items = [];

      // repopulate
      s.items = filterStips(s.name);
    });
  }

  function filterStips(status) {
    let filteredStips = [] as Stipulation[];

    if (status) {
      filteredStips = stips.value.filter(
        (s: Stipulation) => s.stipulationStatusName.toLowerCase() === status
      );
    } else {
      filteredStips = stips.value;
    }
    return filteredStips;
  }

  watch(
    () => props?.isTabActive,
    () => {
      emit("getApplicationDocs");
    }
  );
  watch(
    () => props?.application.stipulationsData,
    () => {
      sortItems();
    },
    { deep: true }
  );

  onMounted(() => {
    sortItems();
    if (props.isTabActive) {
      // console.log("StipulationsTab.vue sections sorted", sections);
    }
  });
  watch(
    () => props.isTabActive,
    () => {
      sortItems();
      if (props.isTabActive) {
        // console.log("StipulationsTab.vue sections sorted", sections);
      }
    }
  );

return (_ctx: any,_cache: any) => {
  return (_ctx.isTabActive)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (showAddStipsButton.value)
            ? (_openBlock(), _createBlock(PermissionsButton, {
                key: 0,
                permissionIds: [_unref(Permissions).Stipulations],
                permissionLevel: _unref(PermissionLevels).Update,
                showDisabledIfNotVisible: true,
                class: "xedit",
                onClick: handleStipForm
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(addStip.value ? "Cancel" : "Add Stip"), 1)
                ]),
                _: 1
              }, 8, ["permissionIds", "permissionLevel"]))
            : _createCommentVNode("", true),
          _cache[10] || (_cache[10] = _createElementVNode("h2", null, "Stipulations", -1)),
          _createVNode(ApplicationComments, {
            type: "stipulations",
            id: _ctx.commentsId,
            comments: commentData.value
          }, null, 8, ["id", "comments"])
        ]),
        _cache[11] || (_cache[11] = _createElementVNode("div", { class: "xstipulation-sections" }, null, -1)),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(PermissionsWrapper, {
            permissionIds: [_unref(Permissions).Stipulations],
            permissionLevel: _unref(PermissionLevels).Update,
            showDisabledIfNotVisible: true
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sections.filter((s) => s.items.length > 0), (section, index) => {
                return (_openBlock(), _createBlock(StipulationsSection, {
                  key: index,
                  section: section,
                  collapsed: section.collapsed,
                  application: _ctx.application,
                  stipulations: _ctx.application.stipulationsData,
                  onGetApplicationStips: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('getApplicationStips'))),
                  onGetApplicationComments: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('getApplicationComments'))),
                  onStipsUpdated: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('stipsUpdated', $event))),
                  onStipUpdated: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('stipUpdated', $event)))
                }, null, 8, ["section", "collapsed", "application", "stipulations"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["permissionIds", "permissionLevel"])
        ]),
        _createElementVNode("div", null, [
          (addStip.value)
            ? (_openBlock(), _createBlock(AddStipulationForm, {
                key: 0,
                application: _ctx.application,
                stipulations: _ctx.application.stipulationsData,
                onClose: _cache[4] || (_cache[4] = ($event: any) => (addStip.value = false)),
                onCloseModal: _cache[5] || (_cache[5] = ($event: any) => (addStip.value = false)),
                onGetApplicationStips: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('getApplicationStips'))),
                onGetApplicationComments: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('getApplicationComments'))),
                onStipsUpdated: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('stipsUpdated', $event))),
                onStipAdded: _cache[9] || (_cache[9] = ($event: any) => (_ctx.$emit('stipAdded', $event)))
              }, null, 8, ["application", "stipulations"]))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})