import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { shallowRef, onMounted, watch } from "vue";
import { useComponentFactory } from "@/composables/useComponentFactory";

interface Props {
  identifier: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ComponentFactory',
  props: {
    identifier: {}
  },
  setup(__props: any) {

const props = __props;

const {createComponent} = useComponentFactory();

const currentComponent = shallowRef(null);

onMounted(() => {
    currentComponent.value = createComponent(props.identifier);
});

watch(
  () => props.identifier, 
  (newIdentifier) => {    
    currentComponent.value = null;
    currentComponent.value = createComponent(newIdentifier);
  },
  { immediate: true }
);


return (_ctx: any,_cache: any) => {
  return (currentComponent.value)
    ? (_openBlock(), _createBlock(_resolveDynamicComponent(currentComponent.value), { key: 0 }))
    : _createCommentVNode("", true)
}
}

})