import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "context-display"
}
const _hoisted_2 = { class: "context-container" }
const _hoisted_3 = { class: "context-table" }
const _hoisted_4 = { class: "table-row" }
const _hoisted_5 = { class: "table-row" }
const _hoisted_6 = ["title", "id"]

import { computed, reactive, onBeforeMount, watch } from "vue";
import { LOSType, ProgramLimit } from "@/models/opal";
import ProgramRequirementsContextMenu from "@/components/income/ProgramRequirementsContextMenu.vue";
import useFormatter from "@/use/formatter";
import { useStore } from "vuex";
import { UpdateApplicationRequirementDecisionRequest } from "@/models/programRequirements";
import { $api } from "@/services/api1";
import functions from "@/use/functions";
import PermissionsWrapper from "@/components/wrappers/PermissionsWrapper.vue";
import { Permissions, PermissionLevels } from "@/models/permissions";
import { useProgramRequirementsStore } from "@/store/store/programRequirementsStore";
import { useLoadingStore } from "@/store/store/loadingStore";

interface Props {
  application: any;
  applicant: any;
  canEdit: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ProgramRequirements',
  props: {
    application: {},
    applicant: {},
    canEdit: { type: Boolean }
  },
  emits: [
  "programRequirementDecisionUpdated",
  "programRequirementDecisionAdded",
  "checkIncomeTab",
],
  setup(__props: any, { emit: __emit }) {

const programRequirementsStore = useProgramRequirementsStore();

const props = __props;
const store = useStore();

const emit = __emit;

const { formatMoney, formatDecimalAsPercentage } = useFormatter();

const showProgramRequirements = computed(
  () => props.application?.data?.applicationStatusName !== "Not Submitted"
);

const soloApp = computed<boolean>(
  () => props.application.data.metadata.withCoApplicants === false
);

const incomeProgramRequirements = computed(() =>
  store?.state.types["ProgramRequirements"]?.results?.filter(
    (r: any) =>
      r.name?.trim() === "DTI" ||
      r.name?.trim() === "Employment" ||
      r.name?.trim() === "Income"
  )
);

const programRequirementsDecisions = computed(
  () => props.application.programRequirements
);

const defaultApplicationRequirementDecision = computed(() =>
  store?.state?.types["RequirementStatuses"]?.results?.find(
    (s: LOSType) => s.name?.trim() === "Open"
  )
);

const incomeLimit = computed(() => {
  const incomeLimitTypeName = getIncomeProgramLimit();
  const value = props.application.programLimits?.find(
    (p: ProgramLimit) => p.limitTypeName === `${incomeLimitTypeName}`
  )?.value;
  return value && !isNaN(value) ? formatMoney(value) : "N/A";
});

const yearsOfEmployment = computed(() => {
  const employmentLimit = getEmploymentLimit();
  const years = props.application.programLimits?.find(
    (p: ProgramLimit) => p.limitTypeName === `${employmentLimit}`
  )?.value;

  if (incomeLimit?.value === "N/A" && years === undefined) {
    return "N/A";
  } else {
    return `${years / 12} ${years / 12 > 1 ? "Years" : "Year"}` || "N/A";
  }
});

const dti = computed(() => {
  const limitTypeName = getDTIProgramLimit();
  const decimal = props.application.programLimits?.find(
    (p: ProgramLimit) => p.limitTypeName === limitTypeName
  )?.value;
  return decimal ? formatDecimalAsPercentage(decimal) : "N/A";
});

let programRequirementInformation = reactive({} as any);

function limitTypeName(limit: string) {
  let applicantType = soloApp.value ? "Borrower" : "CoBorrower";

  let statusAbbreviation = "CondApproved";

  return `${applicantType}${limit}${statusAbbreviation}`;
}

function shouldShowTooltip(requirementName: string): boolean {
  const name = requirementName.trim().toLowerCase();
  return name !== "employment" && name !== "dti";
}

function getEmploymentLimit() {
  let limit = "MinEmployment";
  return limitTypeName(limit);
}

function getIncomeProgramLimit() {
  let limit = "MinIncome";
  return limitTypeName(limit);
}

function getDTIProgramLimit() {
  let limit = "MaxDTI";
  return limitTypeName(limit);
}

function programLimitValue(programLimit: string) {
  switch (programLimit) {
    case "DTI":
    case "Pricing - DTI":
      return dti.value;
    case "Employment":
      return yearsOfEmployment.value;
    case "Pricing - Income":
    case "Income":
      return incomeLimit.value;
    default:
      return "";
  }
}

function applicationRequirementDecision(programRequirement: any) {
  let applicationRequirementDecision = programRequirementsDecisions.value?.find(
    (r: any) =>
      r.requirementTypeName
        ?.toLowerCase()
        ?.includes(`${programRequirement.name?.toLowerCase()}`)
  );
  return applicationRequirementDecision;
}

function getRequirementType(programRequirement: any) {
  let requirementType = store?.state.types["Requirements"]?.results?.find(
    (r: any) =>
      r.name
        ?.toLowerCase()
        ?.includes(`${programRequirement.name?.toLowerCase()}`)
  );
  return requirementType;
}

function setData() {
  incomeProgramRequirements.value.forEach((pr: any) => {
    programRequirementInformation[pr.name] = {
      programRequirementTypeName: pr.name,
      applicationId:
        applicationRequirementDecision(pr)?.applicationId ||
        props?.application?.data?.id,
      applicationRequirementDecisionId: applicationRequirementDecision(pr)?.id,
      programRequirementLimit: programLimitValue(pr.name),
      applicationRequirement: applicationRequirementDecision(pr),
      requirementTypeId: applicationRequirementDecision(pr)?.requirementTypeId,
      requirementTypeName:
        applicationRequirementDecision(pr)?.requirementTypeName,
      requirementStatusId:
        applicationRequirementDecision(pr)?.requirementStatusId,
      requirementStatusName:
        applicationRequirementDecision(pr)?.requirementStatusName,
    };
    if (
      pr.name?.trim()?.toLowerCase() !== "employment" &&
      !programRequirementInformation[pr.name]
        .applicationRequirementDecisionId &&
      programRequirementInformation[pr.name].programRequirementLimit !== "N/A"
    ) {
      programRequirementInformation[pr.name].requirementTypeId =
        getRequirementType(pr)?.id;
      programRequirementInformation[pr.name].requirementTypeName =
        getRequirementType(pr)?.name;
      programRequirementInformation[pr.name].requirementStatusId =
        defaultApplicationRequirementDecision?.value?.id;
      programRequirementInformation[pr.name].requirementStatusName =
        defaultApplicationRequirementDecision?.value?.name;
    }
  });
}

async function addApplicationRequirementDecision(event, programRequirement) {
  const applicationId = event?.applicationId || props.application?.data?.id;

  const response = await $api.applications.addApplicationRequirementDecision(
    applicationId,
    event
  );

  if (response?.length > 0) {
    const record = response.find(
      (r: any) =>
        r.requirementTypeId ===
        programRequirementInformation[programRequirement.name].requirementTypeId
    );
    if (record) {
      programRequirementInformation[
        programRequirement.name
      ].applicationRequirementDecisionId = record.id;
      programRequirementInformation[
        programRequirement.name
      ].applicationRequirement = record;
      programRequirementInformation[
        programRequirement.name
      ].requirementStatusId = record.requirementStatusId;
      programRequirementInformation[
        programRequirement.name
      ].requirementStatusName = record.requirementStatusName;
      verify();
      emit("programRequirementDecisionAdded", response);
    } else {
      console.log("There was an issue adding the program requirement status");
      functions.openModal({
        title: "Error",
        description:
          "There was an issue updating the program requirement status",
      });
    }
  }
}

async function updateApplicationRequirementDecision(
  event: UpdateApplicationRequirementDecisionRequest,
  requirement: any
) {
  const loadingStore = useLoadingStore();

  const requirementInfo = programRequirementInformation[requirement.name];
  const isEmployment = requirement.name?.trim() === "Employment";
  const isDTI = requirement.name?.trim() === "DTI";

  if (
    (isEmployment && requirementInfo.programRequirementLimit !== "N/A") ||
    (isDTI && requirementInfo.programRequirementLimit === "N/A")
    // (requirement.name?.trim() === "Income" &&
    //   requirementInfo.programRequirementLimit === "N/A")
  ) {
    functions.openModal({
      title: "Error",
      description: "This requirement cannot be updated.",
    });
    await addApplicationRequirementDecision(event, requirement);
    return;
  }

  loadingStore.setLoading(true);

  try {
    const request = {
      applicationId: event?.applicationId || props.application?.data?.id,
      applicationRequirementDecisionId:
        event?.applicationRequirementDecisionId ??
        requirementInfo.applicationRequirementDecisionId,
      requirementStatusId: event.requirementStatusId,
    };

    const response =
      await $api.applications.updateApplicationRequirementDecisionStatus(
        request.applicationId,
        request.applicationRequirementDecisionId,
        request.requirementStatusId,
        request
      );

    if (response && response.success) {
      const result =
        programRequirementsStore.programRequirementsDecisions?.find(
          (r: any) => r.id === request?.applicationRequirementDecisionId
        );

      if (result?.id) {
        result.requirementStatusId = event.requirementStatusId;
        const status = store.state.types["RequirementStatuses"].results.find(
          (s: LOSType) => s.id === event.requirementStatusId
        );
        result.requirementStatusName = status?.name;
        programRequirementsStore.updateProgramRequirement("Income", result);
        verify();
        emit("programRequirementDecisionUpdated", result);
      } else {
        console.log("No result found in program requirements store");
      }
      functions.openModal({
        title: "Success",
        description: "Program requirement status updated successfully.",
      });
    } else {
      const errorMessage = response?.errors?.[0] || "Unknown error";
      functions.openModal({
        title: "Error",
        html: `
    <div style="text-align: center;">
      <p>There was an issue updating the program requirement status.</p>
      <div style="color: red; font-weight: bold;">${errorMessage}</div>
    </div>
  `,
      });
      console.log("Error updating program requirement status", response);
    }
  } catch (error) {
    console.log("Error updating program requirement status.", error);
  } finally {
    loadingStore.setLoading(false);
  }
}

function verify() {
  let verificationResults = [
    { requirement: "DTI", status: "incomplete" },
    { requirement: "Employment", status: "incomplete" },
    { requirement: "Income", status: "incomplete" },
  ];
  incomeProgramRequirements.value?.forEach((r) => {
    const notRequired =
      programRequirementInformation["Income"].programRequirementLimit ===
        "N/A" &&
      programRequirementInformation["Employment"].programRequirementLimit ===
        "N/A" &&
      programRequirementInformation["DTI"].programRequirementLimit === "N/A";

    const requirementInfo = programRequirementInformation[r.name];
    const isEmployment =
      requirementInfo.programRequirementTypeName === "Employment";
    const isDTI = requirementInfo.programRequirementTypeName === "DTI";

    if (
      notRequired === true ||
      (!isEmployment &&
        requirementInfo.programRequirementLimit !== "N/A" &&
        (requirementInfo.requirementStatusName === "Passed" ||
          requirementInfo.requirementStatusName === "Passed - CU Override")) ||
      (isDTI &&
        requirementInfo.programRequirementLimit === "N/A" &&
        requirementInfo.requirementStatusName === undefined) ||
      (isEmployment &&
        requirementInfo.programRequirementLimit !== "N/A" &&
        requirementInfo.requirementStatusName === undefined) ||
      (requirementInfo.programRequirementTypeName === "Income" &&
        requirementInfo.programRequirementLimit === "N/A")
    ) {
      const result = verificationResults?.find(
        (v: any) => v.requirement === r.name
      );
      if (result) {
        result.status = "complete";
      }
    } else if (
      programRequirementInformation[r.name].requirementStatusName === "Failed"
    ) {
      const result = verificationResults?.find(
        (v: any) => v.requirement === r.name
      );
      if (result) {
        result.status = "failed";
      }
    }
  });

  let complete = verificationResults.every(
    (incomeRequirement) => incomeRequirement.status === "complete"
  );
  let failedRequirements = verificationResults?.filter(
    (r) => r.status === "failed"
  );
  let openRequirements = verificationResults?.filter(
    (r) => r.status === "incomplete"
  );

  let result = "incomplete";

  if (complete) {
    result = "complete";
  } else if (failedRequirements?.length > 0) {
    result = "failed";
  } else if (openRequirements?.length > 0 && failedRequirements?.length === 0) {
    result = "incomplete";
  }

  emit("checkIncomeTab", result);
}

onBeforeMount(() => {
  setData();
  verify();
});

watch(
  () => props.application.programRequirements,
  () => {
    setData();
    verify();
  },
  { deep: true }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(PermissionsWrapper, {
    disabled: !_ctx.canEdit,
    showDisabledIfNotVisible: true,
    permissionIds: [_unref(Permissions).Income],
    permissionLevel: _unref(PermissionLevels).Update
  }, {
    default: _withCtx(() => [
      (showProgramRequirements.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("table", _hoisted_3, [
                _cache[0] || (_cache[0] = _createElementVNode("thead", null, [
                  _createElementVNode("tr", { class: "context-header" }, [
                    _createElementVNode("th"),
                    _createElementVNode("th", { class: "content-header-text" }, "Program Requirements"),
                    _createElementVNode("th")
                  ])
                ], -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(incomeProgramRequirements.value, (programRequirement) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: programRequirement.name
                  }, [
                    _createVNode(ProgramRequirementsContextMenu, {
                      name: `${programRequirement.name.trim()}`,
                      application: _ctx.application,
                      programRequirement: programRequirement,
                      appRequirementInformation: 
                _unref(programRequirementInformation)[programRequirement.name]
              ,
                      onUpdateRequirementStatus: ($event: any) => (
                updateApplicationRequirementDecision($event, programRequirement)
              ),
                      onAddRequirementStatus: ($event: any) => (
                addApplicationRequirementDecision($event, programRequirement)
              )
                    }, null, 8, ["name", "application", "programRequirement", "appRequirementInformation", "onUpdateRequirementStatus", "onAddRequirementStatus"]),
                    _createElementVNode("td", _hoisted_4, _toDisplayString(programRequirement.description), 1),
                    _createElementVNode("td", _hoisted_5, _toDisplayString(_unref(programRequirementInformation)[programRequirement.name]
                  ?.programRequirementLimit), 1),
                    _createElementVNode("td", {
                      class: "program-requirement-status table-row",
                      title: 
                shouldShowTooltip(programRequirement.name)
                  ? 'Right click to update status if not in N/A'
                  : ''
              ,
                      id: `${programRequirement.name.trim()}`
                    }, _toDisplayString(_unref(programRequirementInformation)[programRequirement.name]
                  ?.requirementStatusName || "N/A"), 9, _hoisted_6)
                  ]))
                }), 128))
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["disabled", "permissionIds", "permissionLevel"]))
}
}

})