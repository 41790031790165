import { TabVM } from "./application";
import { Tag } from "./tag";

export enum TabIdentifiers {
  ApplicationTabSLR = "ApplicationTabSLR",
  ApplicationTabLOC = "ApplicationTabLOC",
  CreditTabLOC = "CreditTabLOC",
  CreditTabSLR = "CreditTabSLR",
  DocumentTabSLR = "DocumentTabSLR",
  DocumentTabLOC = "DocumentTabLOC",
  IncomeTab = "IncomeTab",
  IncomeTabLOC = "IncomeTabLOC",
  IncomeTabSLR = "IncomeTabSLR",
  IdentityTab = "IdentityTab",
  IdentityTabLOC = "IdentityTabLOC",
  IdentityTabSLR = "IdentityTabSLR",
  MembershipTabLOC = "MembershipTabLOC",
  MembershipTabSLR = "MembershipTabSLR",
  FinalReviewTabLOC = "FinalReviewTabLOC",
  FinalReviewTabSLR = "FinalReviewTabSLR",
  EvaluationTabLOC = "EvaluationTabLOC",
  EvaluationTabSLR = "EvaluationTabSLR",
  EsignTabLOC = "EsignTabLOC",
  EsignTabSLR = "EsignTabSLR",
  PricingTabLOC = "PricingTabLOC",
  PricingTabSLR = "PricingTabSLR",
  OverridesTabLOC = "OverridesTabLOC",
  OverridesTabSLR = "OverridesTabSLR",
  StipulationsTabLOC = "StipulationsTabLOC",
  StipulationsTabSLR = "StipulationsTabSLR",
  DocumentUploaded = "DocumentUploaded",
  DocumentGenerated = "DocumentGenerated",
  AddStipFormLOC = "AddStipFormLOC",
  AddStipFormSLR = "AddStipFormSLR",
  EvaluationCreditDecisionLOC = "EvaluationCreditDecisionLOC",
  EvaluationCreditDecisionSLR = "EvaluationCreditDecisionSLR",
  PayoffTabSLR = "PayoffTabSLR",
  PayoffTabLOC = "PayoffTabLOC",
  PayoffTab = "PayoffTab",
}

export type TabIdentifiersType = keyof typeof TabIdentifiers;

export enum TabStatus {
  Incomplete = "incomplete",
  Complete = "complete",
  Completed = "Completed",
  Rejected = "Rejected",
  Failed = "Failed",
}

export interface TabResponse{
   success: boolean,
   results: TabTagsVM
}

export interface TabTagsVM{
  tabs: TabVM[]
  tags: Tag[];
}

export class TabStatusNames {
  public static get Incomplete(): string {
    return TabStatus.Incomplete;
  }
  public static get Completed(): string {
    return TabStatus.Completed;
  }
  public static get Rejected(): string {
    return TabStatus.Rejected;
  }
  public static get Failed(): string {
    return TabStatus.Failed;
  }
}
