import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "xmodal-animate" }
const _hoisted_2 = ["data-fullscreen"]
const _hoisted_3 = { class: "xmodal" }
const _hoisted_4 = {
  key: 0,
  class: "xmodal-title"
}
const _hoisted_5 = {
  key: 1,
  class: "xmodal-description"
}
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = {
  key: 3,
  class: "xmodal-buttons"
}
const _hoisted_8 = ["onClick"]
const _hoisted_9 = {
  key: 4,
  class: "xmodal-buttons"
}
const _hoisted_10 = {
  key: 5,
  class: "xmodal-buttons"
}

import { computed } from "vue";
import store from "@/store";
import OpalButton from "@/components/shared/OpalButton.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'Modal',
  setup(__props) {

const modalActive = computed<boolean>(() => store.state.modal.active);

const hasButtons = computed<boolean>(() => store.state.modal.buttons?.length > 0 && !store.state.modal.loading);
const hasLoading = computed < boolean > (() => store.state.modal.buttons?.length > 0 && store.state.modal.loading);

const closeModal = (): void => {
  store.state.modal.active = false;
};

return (_ctx: any,_cache: any) => {
  const _directive_auto_animate = _resolveDirective("auto-animate")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    (modalActive.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "xmodal-wrapper",
          onClick: _withModifiers(closeModal, ["self"])
        }, [
          _createElementVNode("div", {
            class: "xmodal-inner",
            "data-fullscreen": _unref(store).state.modal.fullscreen
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", {
                class: "xmodal-close",
                onClick: closeModal
              }),
              (_unref(store).state.modal.title)
                ? (_openBlock(), _createElementBlock("h2", _hoisted_4, _toDisplayString(_unref(store).state.modal.title), 1))
                : _createCommentVNode("", true),
              (_unref(store).state.modal.description)
                ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_unref(store).state.modal.description), 1))
                : _createCommentVNode("", true),
              (_unref(store).state.modal.html)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    innerHTML: _unref(store).state.modal.html,
                    class: "xmodal-html"
                  }, null, 8, _hoisted_6))
                : _createCommentVNode("", true),
              (hasButtons.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(store).state.modal.buttons, (button, index) => {
                      return (_openBlock(), _createElementBlock("button", {
                        key: index,
                        onClick: ($event: any) => (button.onClick())
                      }, _toDisplayString(button.title), 9, _hoisted_8))
                    }), 128))
                  ]))
                : (hasLoading.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createVNode(OpalButton, {
                        text: _unref(store).state.modal.title,
                        loading: true
                      }, null, 8, ["text"])
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createElementVNode("button", { onClick: closeModal }, "Close")
                    ]))
            ])
          ], 8, _hoisted_2)
        ]))
      : _createCommentVNode("", true)
  ])), [
    [_directive_auto_animate, _ctx.$animate]
  ])
}
}

})