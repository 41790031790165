import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, vShow as _vShow, vModelSelect as _vModelSelect, vModelCheckbox as _vModelCheckbox, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, unref as _unref } from "vue"

const _hoisted_1 = { class: "xpage-content-page" }
const _hoisted_2 = { class: "xpage-breadcrumbs" }
const _hoisted_3 = { class: "xpage-inner" }
const _hoisted_4 = { class: "xpage-searchbar" }
const _hoisted_5 = { class: "xsearchbox" }
const _hoisted_6 = { class: "xsearchbox-field" }
const _hoisted_7 = { class: "tooltip-text" }
const _hoisted_8 = { class: "page-size-selector" }
const _hoisted_9 = { style: {"display":"flex"} }
const _hoisted_10 = { class: "xpage-search-filters" }
const _hoisted_11 = { class: "xpage-search-filter-content" }
const _hoisted_12 = { class: "xpage-search-filter-section" }
const _hoisted_13 = { class: "xpage-search-filter-section-content" }
const _hoisted_14 = { class: "xcheck" }
const _hoisted_15 = { class: "xcheck" }
const _hoisted_16 = { class: "xpage-search-filter-section" }
const _hoisted_17 = { class: "xpage-search-filter-section-content" }
const _hoisted_18 = { class: "xcheck" }
const _hoisted_19 = ["id", "value"]
const _hoisted_20 = {
  key: 1,
  class: "xmodal-wrapper"
}
const _hoisted_21 = { class: "xmodal-inner" }
const _hoisted_22 = { class: "xmodal" }
const _hoisted_23 = { class: "inner-modal" }
const _hoisted_24 = { class: "inner-modal" }
const _hoisted_25 = { class: "xcheck" }
const _hoisted_26 = ["id", "value"]
const _hoisted_27 = { class: "xmodal-buttons" }
const _hoisted_28 = {
  key: 0,
  class: "table-data"
}
const _hoisted_29 = {
  key: 0,
  class: "results-info"
}
const _hoisted_30 = { class: "results-info-pages" }
const _hoisted_31 = {
  key: 0,
  class: "pagination"
}
const _hoisted_32 = { class: "pagination-controls" }
const _hoisted_33 = ["disabled"]
const _hoisted_34 = ["onClick"]
const _hoisted_35 = ["disabled"]
const _hoisted_36 = { id: "fifthTable" }
const _hoisted_37 = ["onClick"]
const _hoisted_38 = { class: "header-container" }
const _hoisted_39 = { class: "header-text" }
const _hoisted_40 = { class: "caret-container" }
const _hoisted_41 = { key: 0 }
const _hoisted_42 = ["onClick"]
const _hoisted_43 = {
  key: 1,
  class: "xfoundnothing"
}
const _hoisted_44 = { key: 0 }
const _hoisted_45 = {
  key: 1,
  class: "hasFiltersApplied"
}

import { onMounted, ref, Ref, watch, computed, reactive } from "vue";
import Loader from "@/components/Loader.vue";
import Page from "@/components/layout/Page.vue";
import { Permissions, PermissionLevels } from "@/models/permissions";
import PermissionsWrapper from "@/components/wrappers/PermissionsWrapper.vue";
import { useApplicantsStore } from "@/store/store/applicantsStore";
import { useRouter } from "vue-router";
import axios from "axios";
import { useBatchesStore } from "@/store/store/batchesStore";
import CircleLoader from "@/components/shared/CircleLoader.vue";

interface SearchResultItem {
	id: number;
	batchNumber: any;
	batchType: number;
	batchTypeDescription: string;
	batchDateFormatted: string;
	approvedDateFormatted: string | null;
	statusHumanized: string;
	approved: boolean;
}

const pageName = "Disbursement Batches";

export default /*@__PURE__*/_defineComponent({
  __name: 'Batches',
  setup(__props) {

const { loadData } = useInitialData();
const router = useRouter();
const batchesStore = useBatchesStore();
const loading = ref(false);
const searchLoading = ref(false);

const canEdit = useApplicantsStore();
const isModalVisible = ref(false);

const searchFilter = ref("");
const searchTerm = ref("");

const approvedCheckbox = ref(false);
const pendingCheckbox = ref(false);
const showCreditUnion = reactive<Record<number, boolean>>({});

const searchResults: Ref<SearchResultItem[]> = ref([]);
const selectedCreditUnions = ref<{ id: number; name: string }[]>([]);

// const ascending = ref<boolean>(false);
const sortColumn = ref<string>("");
const showTooltip = ref(false);

const totalCount = ref(0);
const pageSize = ref(30);
const currentPage = ref(1);
const searchQuery = ref("");

const searchPreformed = ref(false);

const approved = computed(() => {
	if (approvedCheckbox.value && pendingCheckbox.value) {
		return null;
	} else if (approvedCheckbox.value) {
		return true;
	} else if (pendingCheckbox.value) {
		return false;
	}
	return null;
});

const lenderIds = computed(() => {
	return selectedCreditUnions.value.map((cu) => cu.id);
});

const displayCreditUnions = computed(() => {
	return selectedCreditUnions.value.length > 0
		? selectedCreditUnions
		: filteredCreditUnions;
});

const creditUnionFilterEngaged = computed(() => {
	return lenderIds.value.length > 0;
});

const filterDescription = computed(() => {
	let descriptions: string[] = [];
	if (approved.value === true) {
		descriptions.push("Approved");
	} else if (approved.value === false) {
		descriptions.push("Pending");
	}
	if (creditUnionFilterEngaged.value) {
		descriptions.push(
			`${lenderIds.value.length} Credit Union${
				lenderIds.value.length > 1 ? "s" : ""
			}`
		);
	}
	return descriptions.length ? `${descriptions.join(", ")}` : "";
});

const creditUnions = computed(() =>
	batchesStore.lenders.map((union) => {
		return {
			id: union.id,
			name: union.name,
		};
	})
);

const editDisbursementBatch = (id: number, batchType: number) => {
	if (batchType === 1) { // loc
		router.push({ name: "BatchEdit", params: { batchNumber: id } });
	} else if (batchType == 2) {
		router.push({ name: "PayoffBatchDetails", params: { batchId: id } });
	} else {
		alert(`Batch details are not yet implemented for type ${batchType}.`);
	}
};

const filteredCreditUnions = computed(() => {
	const filtered = creditUnions.value.filter(
		(creditUnion) =>
			!searchTerm.value ||
			creditUnion.name.toLowerCase().includes(searchTerm.value.toLowerCase())
	);

	filtered.sort((a, b) => a.name.localeCompare(b.name));
	return filtered;
});

const totalPages = computed(() => {
	if (pageSize.value > 0) {
		return Math.ceil(totalCount.value / pageSize.value);
	} else {
		return 1;
	}
});

const searchParams = computed(() => ({
	search: searchQuery.value.trim() || null,
	approved: approved.value !== null ? approved.value : null,
	lenderIds: lenderIds.value.length > 0 ? lenderIds.value : undefined,
	createdSince: null,
	page: {
		index: currentPage.value,
		size: pageSize.value,
	},
}));

function createColumnHeaders(columns: { key: string; name: string }[]) {
	return columns.map((col) => ({
		key: col.key,
		name: col.name,
	}));
}

const displayedColumns = ref([
	{ key: "batchNumber", name: "Batch Number" },
	{ key: "batchTypeDescription", name: "Batch Type" },
	{ key: "batchDateFormatted", name: "Batch Date" },
	{ key: "approvedDateFormatted", name: "Approved Date" },
	{ key: "status", name: "Status" },
]);

const columnHeaders = computed(() =>
	createColumnHeaders(displayedColumns.value)
);

const closeModalAndClearSearch = () => {
	isModalVisible.value = false;
};

const clearSearchQuery = () => {
	searchQuery.value = "";
	searchPreformed.value = false;
	disbursementSearch(true);
};

watch(pageSize, () => {
	currentPage.value = 1;
});

const changePageSize = () => {
	disbursementSearch();
};

const resetCheckboxes = () => {
	creditUnions.value.forEach((creditUnion) => {
		showCreditUnion[creditUnion.id] = false;
	});
};

const totalPageCountText = computed(() => {
	return `${totalPages.value} ${totalPages.value === 1 ? "page" : "pages"}`;
});

const disbursementSearch = async (
	event?: MouseEvent | KeyboardEvent | boolean
) => {
	let resetPage = false;

	if (typeof event === "boolean") {
		resetPage = event;
	}

	if (resetPage) {
		currentPage.value = 1;
	}

	loading.value = true;
	searchPreformed.value = true;

	try {
		const response = await axios.post(
			"/api/disbursementbatches/search",
			searchParams.value
		);
		totalCount.value = response.data.totalCount;
		searchResults.value = response.data.items;
	} catch (error) {
		console.error(error);
	} finally {
		loading.value = false;
	}
};

// Sorting
const sorting = reactive({
	column: null,
	direction: "asc",
});

function sortTable(column) {
	if (sorting.column === column) {
		sorting.direction = sorting.direction === "asc" ? "desc" : "asc";
	} else {
		sorting.column = column;
		sorting.direction = "asc";
	}
	searchResults.value.sort((a, b) => {
		let aValue = a[column],
			bValue = b[column];

		if (aValue === null && bValue === null) {
			return 0;
		} else if (aValue === null) {
			return sorting.direction === "asc" ? -1 : 1;
		} else if (bValue === null) {
			return sorting.direction === "asc" ? 1 : -1;
		}

		if (!isNaN(Date.parse(aValue)) && !isNaN(Date.parse(bValue))) {
			aValue = new Date(aValue);
			bValue = new Date(bValue);
		}

		if (aValue < bValue) {
			return sorting.direction === "asc" ? -1 : 1;
		} else if (aValue > bValue) {
			return sorting.direction === "asc" ? 1 : -1;
		} else {
			return 0;
		}
	});
}

function changePage(page: number) {
	currentPage.value = page;
}

const filteredSearchResults = computed(() => {
	if (approved.value === null) {
		return searchResults.value;
	} else if (approved.value) {
		return searchResults.value.filter((item) => item.approved === true);
	} else {
		return searchResults.value.filter((item) => item.approved === false);
	}
});

const pagedSearchResults = async () => {
	const searchParams = {
		search: null,
		batchStatus: 0,
		lenderIds: [],
		createdSince: null,
		page: {
			index: 1,
			size: 30,
		},
	};
	const response = await axios.post(
		"/api/disbursementbatches/search",
		searchParams
	);
	searchFilter.value = response.data.items;
};

creditUnions.value.forEach((creditUnion) => {
	showCreditUnion[creditUnion.id] = false;
});

watch(
	searchParams,
	() => {
		disbursementSearch();
	},
	{ deep: true }
);

watch(searchTerm, () => {
	resetCheckboxes();
	disbursementSearch();
});

function useInitialData() {
	const loadData = async () => {
		try {
			await Promise.all([
				disbursementSearch(),
				batchesStore.loadLenders(),
			]);
			processLenderData();
		} catch (error) {
			console.error("Failed To load initial Data:", error);
		}
	};

	const processLenderData = () => {
		creditUnions.value.forEach((creditUnion) => {
			showCreditUnion[creditUnion.id] = false;
		});
	};
	return { loadData, processLenderData };
}

onMounted(async () => {
	await loadData();
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (loading.value)
      ? (_openBlock(), _createBlock(Loader, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(Page, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_router_link, {
              to: "/",
              class: "icon-home"
            }),
            _cache[15] || (_cache[15] = _createElementVNode("span", null, ">", -1)),
            _createVNode(_component_router_link, { to: `/batches` }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(pageName))
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", { class: "title-page" }, [
              _createElementVNode("h2", null, _toDisplayString(pageName))
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchQuery).value = $event)),
                    onMouseover: _cache[1] || (_cache[1] = ($event: any) => (showTooltip.value = true)),
                    onMouseout: _cache[2] || (_cache[2] = ($event: any) => (showTooltip.value = false)),
                    onKeyup: _cache[3] || (_cache[3] = _withKeys(($event: any) => (disbursementSearch($event)), ["enter"])),
                    placeholder: `Search by CLUID, Name or Ref ID...`
                  }, null, 544), [
                    [_vModelText, searchQuery.value]
                  ]),
                  _withDirectives(_createElementVNode("span", _hoisted_7, " Enter exact to search by CLUID, Name or Ref ID... ", 512), [
                    [_vShow, showTooltip.value]
                  ]),
                  _createElementVNode("i", {
                    class: "icon-cancel",
                    onClick: clearSearchQuery
                  })
                ]),
                _createElementVNode("div", { class: "xsearchbox-submit" }, [
                  _createElementVNode("button", {
                    onClick: disbursementSearch,
                    class: "icon-search"
                  }, _cache[16] || (_cache[16] = [
                    _createElementVNode("span", null, "Search", -1)
                  ]))
                ]),
                _cache[17] || (_cache[17] = _createElementVNode("div", { class: "xpage-settings" }, null, -1))
              ]),
              _createElementVNode("div", _hoisted_8, [
                _withDirectives(_createElementVNode("select", {
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((pageSize).value = $event)),
                  onChange: changePageSize
                }, _cache[18] || (_cache[18] = [
                  _createElementVNode("option", { value: "30" }, "30", -1),
                  _createElementVNode("option", { value: "60" }, "60", -1),
                  _createElementVNode("option", { value: "90" }, "90", -1)
                ]), 544), [
                  [_vModelSelect, pageSize.value]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _cache[28] || (_cache[28] = _createElementVNode("div", { class: "xpage-search-filter-header" }, [
                  _createElementVNode("div", { class: "xpage-search-filter-title" }, [
                    _createElementVNode("h3", null, "Search Filters")
                  ]),
                  _createElementVNode("div")
                ], -1)),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _cache[23] || (_cache[23] = _createElementVNode("div", { class: "xpage-search-filter-section-title" }, [
                      _createElementVNode("h3", null, "Disbursement Status")
                    ], -1)),
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("label", _hoisted_14, [
                        _cache[19] || (_cache[19] = _createTextVNode(" Approved ")),
                        _withDirectives(_createElementVNode("input", {
                          type: "checkbox",
                          id: "approved",
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((approvedCheckbox).value = $event))
                        }, null, 512), [
                          [_vModelCheckbox, approvedCheckbox.value]
                        ]),
                        _cache[20] || (_cache[20] = _createElementVNode("span", null, null, -1))
                      ]),
                      _createElementVNode("label", _hoisted_15, [
                        _cache[21] || (_cache[21] = _createTextVNode(" Pending ")),
                        _withDirectives(_createElementVNode("input", {
                          type: "checkbox",
                          id: "pending",
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((pendingCheckbox).value = $event))
                        }, null, 512), [
                          [_vModelCheckbox, pendingCheckbox.value]
                        ]),
                        _cache[22] || (_cache[22] = _createElementVNode("span", null, null, -1))
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_16, [
                    _cache[27] || (_cache[27] = _createElementVNode("h3", null, "Credit Union", -1)),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      placeholder: "Search by Credit Union",
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((searchTerm).value = $event))
                    }, null, 512), [
                      [_vModelText, searchTerm.value]
                    ]),
                    _createElementVNode("div", _hoisted_17, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(displayCreditUnions.value.value.slice(0, 8), (creditUnion, index) => {
                        return (_openBlock(), _createElementBlock("div", { key: index }, [
                          (searchLoading.value)
                            ? (_openBlock(), _createBlock(CircleLoader, { key: 0 }))
                            : _createCommentVNode("", true),
                          _createElementVNode("label", _hoisted_18, [
                            _createTextVNode(_toDisplayString(creditUnion.name) + " ", 1),
                            _withDirectives(_createElementVNode("input", {
                              type: "checkbox",
                              id: `checkbox-${index}`,
                              value: creditUnion,
                              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((selectedCreditUnions).value = $event))
                            }, null, 8, _hoisted_19), [
                              [_vModelCheckbox, selectedCreditUnions.value]
                            ]),
                            _cache[24] || (_cache[24] = _createElementVNode("span", null, null, -1))
                          ])
                        ]))
                      }), 128))
                    ]),
                    (filteredCreditUnions.value.length > 8)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: "xseeall",
                          onClick: _cache[9] || (_cache[9] = ($event: any) => (isModalVisible.value = !isModalVisible.value))
                        }, " See all "))
                      : _createCommentVNode("", true),
                    (isModalVisible.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                          _createElementVNode("div", _hoisted_21, [
                            _createElementVNode("div", _hoisted_22, [
                              _createElementVNode("div", {
                                class: "xmodal-close",
                                onClick: closeModalAndClearSearch
                              }, " X "),
                              _cache[26] || (_cache[26] = _createElementVNode("h2", null, "Credit Union", -1)),
                              _withDirectives(_createElementVNode("input", {
                                class: "xmodal-search",
                                type: "text",
                                placeholder: "Search by Credit Union",
                                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((searchTerm).value = $event))
                              }, null, 512), [
                                [_vModelText, searchTerm.value]
                              ]),
                              _createElementVNode("div", _hoisted_23, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredCreditUnions.value, (creditUnion, index) => {
                                  return (_openBlock(), _createElementBlock("div", { key: index }, [
                                    _createElementVNode("div", _hoisted_24, [
                                      _createElementVNode("label", _hoisted_25, [
                                        _createTextVNode(_toDisplayString(creditUnion.name) + " ", 1),
                                        _withDirectives(_createElementVNode("input", {
                                          type: "checkbox",
                                          id: `checkbox-${index}`,
                                          value: creditUnion,
                                          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((selectedCreditUnions).value = $event))
                                        }, null, 8, _hoisted_26), [
                                          [_vModelCheckbox, selectedCreditUnions.value]
                                        ]),
                                        _cache[25] || (_cache[25] = _createElementVNode("span", null, null, -1))
                                      ])
                                    ])
                                  ]))
                                }), 128))
                              ]),
                              _createElementVNode("div", _hoisted_27, [
                                _createElementVNode("button", {
                                  onClick: _cache[12] || (_cache[12] = ($event: any) => (isModalVisible.value = false))
                                }, " Show " + _toDisplayString(totalCount.value) + " Results ", 1)
                              ])
                            ])
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              (filteredSearchResults.value.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                    (totalCount.value > 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                          _createTextVNode(" Found " + _toDisplayString(totalCount.value) + " results ", 1),
                          _createElementVNode("span", _hoisted_30, " Page " + _toDisplayString(currentPage.value) + " of " + _toDisplayString(totalPageCountText.value), 1),
                          (totalPages.value > 1)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                                _createElementVNode("div", _hoisted_32, [
                                  _createElementVNode("button", {
                                    onClick: _cache[13] || (_cache[13] = ($event: any) => (changePage(currentPage.value - 1))),
                                    disabled: currentPage.value <= 1
                                  }, " < ", 8, _hoisted_33),
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(totalPages.value, (n) => {
                                    return (_openBlock(), _createElementBlock("button", {
                                      key: n,
                                      class: _normalizeClass({ xclicked: n === currentPage.value }),
                                      onClick: ($event: any) => (changePage(n))
                                    }, _toDisplayString(n), 11, _hoisted_34))
                                  }), 128)),
                                  _createElementVNode("button", {
                                    onClick: _cache[14] || (_cache[14] = ($event: any) => (changePage(currentPage.value + 1))),
                                    disabled: currentPage.value >= totalPages.value
                                  }, " > ", 8, _hoisted_35)
                                ])
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true),
                    _createVNode(PermissionsWrapper, {
                      disabled: !_unref(canEdit),
                      showDisabledIfNotVisible: true,
                      permissionIds: [_unref(Permissions).DisbursementBatches],
                      permissionLevel: _unref(PermissionLevels).Read
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("table", _hoisted_36, [
                          _createElementVNode("thead", null, [
                            _createElementVNode("tr", null, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(columnHeaders.value, (header) => {
                                return (_openBlock(), _createElementBlock("th", {
                                  key: header.key,
                                  onClick: ($event: any) => (sortTable(header.key)),
                                  class: _normalizeClass({ 'selected-column': header.key === sortColumn.value })
                                }, [
                                  _createElementVNode("div", _hoisted_38, [
                                    _createElementVNode("div", _hoisted_39, _toDisplayString(header.name), 1),
                                    _createElementVNode("div", _hoisted_40, [
                                      (sorting.column === header.key)
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_41, _toDisplayString(sorting.direction === "asc" ? "▲" : "▼"), 1))
                                        : _createCommentVNode("", true)
                                    ])
                                  ])
                                ], 10, _hoisted_37))
                              }), 128))
                            ])
                          ]),
                          _createElementVNode("tbody", null, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredSearchResults.value, (row) => {
                              return (_openBlock(), _createElementBlock("tr", {
                                key: row.id,
                                onClick: ($event: any) => (editDisbursementBatch(row.id, row.batchType)),
                                class: "clickable-row"
                              }, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(displayedColumns.value, (col) => {
                                  return (_openBlock(), _createElementBlock("td", {
                                    key: col.key
                                  }, _toDisplayString(row[col.key]), 1))
                                }), 128))
                              ], 8, _hoisted_42))
                            }), 128))
                          ])
                        ])
                      ]),
                      _: 1
                    }, 8, ["disabled", "permissionIds", "permissionLevel"])
                  ]))
                : _createCommentVNode("", true),
              (filteredSearchResults.value.length === 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_43, [
                    _cache[33] || (_cache[33] = _createElementVNode("h2", { class: "header" }, "No matches found", -1)),
                    _createElementVNode("p", null, [
                      _cache[32] || (_cache[32] = _createElementVNode("b", null, "0 results", -1)),
                      (searchQuery.value)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_44, [
                            _cache[29] || (_cache[29] = _createTextVNode(" for \" ")),
                            _createElementVNode("b", null, _toDisplayString(searchQuery.value), 1),
                            _cache[30] || (_cache[30] = _createTextVNode(" \" "))
                          ]))
                        : _createCommentVNode("", true),
                      (approved.value !== null || creditUnionFilterEngaged.value)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_45, [
                            _cache[31] || (_cache[31] = _createTextVNode(" with the filter ")),
                            _createElementVNode("b", null, _toDisplayString(filterDescription.value), 1)
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _: 1
      })
    ])
  ], 64))
}
}

})