import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "knockout-container"
}
const _hoisted_2 = { class: "knockouts" }
const _hoisted_3 = { class: "left-section" }
const _hoisted_4 = { class: "knockout-knockoutcode" }
const _hoisted_5 = { class: "knockout-knockoutvalue" }
const _hoisted_6 = { class: "knockout-knockoutdecision" }
const _hoisted_7 = { class: "right-section" }
const _hoisted_8 = {
  class: "w-6 h-6",
  fill: "none",
  stroke: "currentColor",
  viewBox: "0 0 24 24",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_9 = {
  class: "w-6 h-6",
  fill: "none",
  stroke: "currentColor",
  viewBox: "0 0 24 24",
  xmlns: "http://www.w3.org/2000/svg"
}

import { ref, computed } from "vue";

interface Props {
	creditKnockouts: Knockout[] | null;
	knockout: Knockout;
}

interface Knockout {
	knockoutCode: string;
	knockoutDescription: string;
	value: string;
	decision: string;
	open: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CreditKnockouts',
  props: {
    creditKnockouts: {},
    knockout: {}
  },
  emits: ["toggleOpen"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const open = ref(false);
const emit = __emit;

const addSpaceBeforeCaps = (str: string) => {
	return str.replace(/([A-Z])/g, " $1").trim();
};

const contentStyle = computed(() => ({
	maxHeight: open.value ? "100%" : "0",
	transition: "max-height 0.2s ease-out",
}));

const infoStyle = computed(() => ({
	opacity: open.value ? "1" : "0",
	transition: "opacity 0.2s ease-out",
}));

const handleToggleOpen = () => {
	emit("toggleOpen");
};

return (_ctx: any,_cache: any) => {
  return (props.knockout.knockoutCode !== 'Score')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: _normalizeClass(["knockout", { open: open.value }]),
            onClick: handleToggleOpen
          }, [
            _createElementVNode("header", null, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("h4", _hoisted_4, _toDisplayString(props.knockout.knockoutCode), 1),
                _createElementVNode("h4", _hoisted_5, " Results: " + _toDisplayString(props.knockout.value !== "0" ? props.knockout.value : "None"), 1),
                _createElementVNode("h4", _hoisted_6, _toDisplayString(props.knockout.decision !== "0"
								? addSpaceBeforeCaps(props.knockout.decision)
								: "None"), 1)
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("button", {
                  class: "koButton",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (open.value = !open.value))
                }, [
                  _withDirectives((_openBlock(), _createElementBlock("svg", _hoisted_8, _cache[1] || (_cache[1] = [
                    _createElementVNode("path", {
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      "stroke-width": "2",
                      d: "M20 12H4"
                    }, null, -1)
                  ]), 512)), [
                    [_vShow, open.value]
                  ]),
                  _withDirectives((_openBlock(), _createElementBlock("svg", _hoisted_9, _cache[2] || (_cache[2] = [
                    _createElementVNode("path", {
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      "stroke-width": "2",
                      d: "M12 6v6m0 0v6m0-6h6m-6 0H6"
                    }, null, -1)
                  ]), 512)), [
                    [_vShow, !open.value]
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", {
              style: _normalizeStyle(contentStyle.value),
              class: "knockout-knockoutdescription"
            }, [
              _createElementVNode("p", {
                style: _normalizeStyle(infoStyle.value),
                class: "info"
              }, _toDisplayString(props.knockout.knockoutDescription), 5)
            ], 4)
          ], 2)
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})