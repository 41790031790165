import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "context-display"
}
const _hoisted_2 = { class: "context-container" }
const _hoisted_3 = { class: "context-table" }
const _hoisted_4 = {
  class: "program-requirement-status table-row",
  title: `Right click to update status if not in N/A`,
  id: `Identity`
}

import { computed } from "vue";
import { useStore } from "vuex";
import { LOSType } from "@/models/opal";
import {
  AddApplicationRequirementDecisionRequest,
  UpdateApplicationRequirementDecisionRequest,
} from "@/models/programRequirements";
import { useProgramRequirementsStore } from "@/store/store/programRequirementsStore";
import IdentityProgramRequirementsContextMenu from "@/components/identity/IdentityProgramRequirementsContextMenu.vue";
import { $api } from "@/services/api1";
import functions from "@/use/functions";
import { useLoadingStore } from "@/store/store/loadingStore";


interface Props {
  application: any;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'IdentityProgramRequirements',
  props: {
    application: {}
  },
  emits: ["getTags"],
  setup(__props: any, { emit: __emit }) {

const programRequirementsStore = useProgramRequirementsStore();

const emit = __emit;
const props = __props;
const store = useStore();

const showProgramRequirements = computed(
  () => props.application?.data?.applicationStatusName !== "Not Submitted"
);

const defaultApplicationRequirementDecision = computed(() =>
  store?.state?.types["RequirementStatuses"]?.results?.find(
    (s: LOSType) => s.name?.trim() === "Open"
  )
);

const identityRequirementType = computed(() =>
  store?.state?.types["Requirements"]?.results?.find(
    (r: any) => r.name?.trim() === "Identity"
  )
);

const defaultIdentityRequirementDecision = computed(() => {
  return {
    applicationId: props?.application?.data?.id,
    applicationRequirementDecisionId: 0,
    id: 0,
    overrides: [],
    requirementTypeId: identityRequirementType?.value?.id,
    requirementTypeName: identityRequirementType?.value?.name,
    requirementStatusId: defaultApplicationRequirementDecision?.value?.id,
    requirementStatusName: defaultApplicationRequirementDecision?.value?.name,
  };
});

async function addApplicationRequirementDecision(
  event: AddApplicationRequirementDecisionRequest
) {
  const applicationId = event?.applicationId || props.application?.data?.id;
  const response = await $api.applications.addApplicationRequirementDecision(
    applicationId,
    event
  );
  if (response?.length > 0) {
    programRequirementsStore.programRequirementsDecisions = response;
    emit("getTags");
  }
}

async function updateApplicationRequirementDecision(
  event: UpdateApplicationRequirementDecisionRequest
) {
  const loadingStore = useLoadingStore();
  loadingStore.setLoading(true);

  try {
    const response =
      await $api.applications.updateApplicationRequirementDecisionStatus(
        props.application?.data?.id,
        event.applicationRequirementDecisionId,
        event.requirementStatusId,
        event
      );

    if (response && response.success) {
      let result = programRequirementsStore.programRequirementsDecisions?.find(
        (r: any) => r.id === event?.applicationRequirementDecisionId
      );

      if (result) {
        result.requirementStatusId = event.requirementStatusId;

        const status = store.state.types["RequirementStatuses"].results.find(
          (s: LOSType) => s.id === event.requirementStatusId
        );
        result.requirementStatusName = status?.name;

        emit("getTags");
        programRequirementsStore.updateProgramRequirement("Identity", result);
      } else {
        console.log("No result found in program requirements store");
      }

      functions.openModal({
        title: "Success",
        description: "Program requirement status updated successfully",
      });
    } else {
      functions.openModal({
        title: "Error",
        description:
          "There was an issue updating the program requirement status",
      });
    }
  } catch (error) {
    functions.openModal({
      title: "Error",
      description: "There was an issue updating the program requirement status",
    });
  } finally {
    loadingStore.setLoading(false);
  }
}

return (_ctx: any,_cache: any) => {
  return (showProgramRequirements.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("table", _hoisted_3, [
            _cache[4] || (_cache[4] = _createElementVNode("thead", null, [
              _createElementVNode("tr", { class: "context-header" }, [
                _createElementVNode("th"),
                _createElementVNode("th", { class: "content-header-text" }, "Program Requirement"),
                _createElementVNode("th")
              ])
            ], -1)),
            _createElementVNode("tr", null, [
              _createVNode(IdentityProgramRequirementsContextMenu, {
                name: `Identity`,
                application: _ctx.application,
                programRequirement: 
              _unref(programRequirementsStore).identityProgramRequirementDecision
                ?.length > 0
                ? _unref(programRequirementsStore).identityProgramRequirementDecision[0]
                : defaultIdentityRequirementDecision.value
            ,
                onUpdateRequirementStatus: _cache[0] || (_cache[0] = ($event: any) => (
              updateApplicationRequirementDecision($event)
            )),
                onAddRequirementStatus: _cache[1] || (_cache[1] = ($event: any) => (addApplicationRequirementDecision($event)))
              }, null, 8, ["application", "programRequirement"]),
              _cache[2] || (_cache[2] = _createElementVNode("td", { class: "table-row" }, "Identity Verification", -1)),
              _cache[3] || (_cache[3] = _createElementVNode("td", { class: "table-row" }, " Applicant(s) who do not pass Identity check have provided documentation to validate identity requirement. ", -1)),
              _createElementVNode("td", _hoisted_4, _toDisplayString(_unref(programRequirementsStore).identityProgramRequirementDecision
                ?.length > 0
                ? _unref(programRequirementsStore).identityProgramRequirementDecision[0]
                    ?.requirementStatusName
                : defaultIdentityRequirementDecision.value?.requirementStatusName), 1)
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})