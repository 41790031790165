import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "column" }
const _hoisted_3 = { class: "column" }
const _hoisted_4 = { class: "column" }

import { computed } from 'vue';
	import { LoanDetails } from '@/models/loans';
	import Summary from '@/components/loan/Summary.vue';
	import Borrower from './Borrower.vue';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'Details',
  props: {
    referenceId: {},
    loanDetails: {}
  },
  setup(__props: any) {

	const props = __props;

	const primaryBorrower = computed(() => {
		const borrowers = props.loanDetails.borrowers || [];
		if (borrowers && borrowers.length > 0) {
			return borrowers[0];
		}
		return null;
	});

	const coBorrower = computed(() => {
		const borrowers = props.loanDetails.borrowers || [];
		if (borrowers && borrowers.length > 1) {
			return borrowers[1];
		}
		return null;
	});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(Summary, { loanDetails: _ctx.loanDetails }, null, 8, ["loanDetails"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(Borrower, { borrower: primaryBorrower.value }, null, 8, ["borrower"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(Borrower, { borrower: coBorrower.value }, null, 8, ["borrower"])
      ])
    ])
  ]))
}
}

})