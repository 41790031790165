import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { key: 3 }

import { computed } from "vue";
import { Stipulation, LOSApplication } from "@/models/opal";


export default /*@__PURE__*/_defineComponent({
  __name: 'StipVerification',
  props: {
    stipulation: {},
    application: {},
    hasStipVerification: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const shouldShowComponent = computed(() => {
	return (
		props.stipulation.addressInput?.fullAddress ||
		props.stipulation.nameInput?.fullName ||
		props.stipulation.dobInput?.dateOfBirth ||
		props.stipulation.ssnInput?.ssn
	);
});

const formattedSSN = computed(() => {
    const ssn = props.stipulation.ssnInput?.ssn;
    if (ssn && ssn.length === 9) {
        return `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5)}`;
    }
    return ssn;
});

return (_ctx: any,_cache: any) => {
  return (_ctx.hasStipVerification && shouldShowComponent.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (props.stipulation.addressInput?.fullAddress)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
              _cache[0] || (_cache[0] = _createElementVNode("strong", null, "Address:", -1)),
              _createTextVNode(" " + _toDisplayString(props.stipulation.addressInput?.fullAddress), 1)
            ]))
          : (props.stipulation.nameInput?.fullName)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                _cache[1] || (_cache[1] = _createElementVNode("strong", null, "Name:", -1)),
                _createTextVNode(" " + _toDisplayString(props.stipulation.nameInput?.fullName), 1)
              ]))
            : (props.stipulation.dobInput?.dateOfBirth)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                  _cache[2] || (_cache[2] = _createElementVNode("strong", null, "DOB:", -1)),
                  _createTextVNode(" " + _toDisplayString(props.stipulation.dobInput?.dateOfBirth), 1)
                ]))
              : (props.stipulation.ssnInput?.ssn)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                    _cache[3] || (_cache[3] = _createElementVNode("strong", null, "SSN:", -1)),
                    _createTextVNode(" " + _toDisplayString(formattedSSN.value), 1)
                  ]))
                : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
}

})