import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "document-year-container" }
const _hoisted_2 = { class: "column" }
const _hoisted_3 = { class: "xname" }
const _hoisted_4 = { class: "column" }
const _hoisted_5 = { class: "auto-width" }

import { ref, computed } from "vue";
import { FieldSchema } from "@/models/form";
import BaseFormField from "@/components/form-fields/BaseFormField.vue";
import { $api } from "@/services/api1";
import functions from "@/use/functions";
import useFunctions from "@/use/functions1";
import useFormHelpers from "@/use/formHelpers";
import { Permissions, PermissionLevels } from "@/models/permissions";
import PermissionsWrapper from "@/components/wrappers/PermissionsWrapper.vue";
import { useIncomeTabStore } from "@/store/store/incomeTabStore";
import { useDocumentsTabStore } from "@/store/store/documentsTabStore";

interface IncomeDocument {
  applicantId: number;
  applicantDocumentId: string;
  year: number | null;
  documentYear: string | null;
  documentId: number;
}

interface Props {
  incomeDocument: IncomeDocument;
  incomeDocuments: IncomeDocument[];
  canEdit: boolean;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'IncomeDocumentYear',
  props: {
    incomeDocument: {},
    incomeDocuments: {},
    canEdit: { type: Boolean }
  },
  emits: ["documentYearChanged"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;
const incomeTabStore = useIncomeTabStore();
const documentsTabStore = useDocumentsTabStore();
const { convertYearToDateTime } = useFunctions();
const { yearOptions } = useFormHelpers();

const isEditMode = ref(false);
const isSaving = ref(false);
const editableYear = ref<number>(props.incomeDocument.year ?? 0);
const originalYear = ref<number | null>(props.incomeDocument.year ?? null);

const formattedYear = computed(() => {
  return props.incomeDocument.year ? props.incomeDocument.year : "——";
});

const documentSchema = {
  year: {
    name: "year",
    type: "select",
    hideLabel: true,
    hideErrorMessage: true,
    required: false,
  } as FieldSchema,
};

function handleEditClick() {
  if (isEditMode.value) {
    saveYear();
  } else {
    enterEditMode();
  }
}

function enterEditMode() {
  editableYear.value = props.incomeDocument.year ?? 0;
  originalYear.value = props.incomeDocument.year;
  isEditMode.value = true;
}

async function saveYear() {
  if (editableYear.value === originalYear.value) {
    isEditMode.value = false;
    return;
  }
  isSaving.value = true;
  const updatedDocument: IncomeDocument = {
    ...props.incomeDocument,
    year: editableYear.value,
    documentYear: editableYear.value
      ? convertYearToDateTime(editableYear.value)
      : null,
  };
  try {
    const response = await $api.documents.updateApplicantDocument(
      updatedDocument.applicantId,
      Number(updatedDocument.applicantDocumentId),
      updatedDocument
    );
    if (response.applicantDocumentId) {
      //update stores
      documentsTabStore.updateDocument(response.documentId, response);
      incomeTabStore.updateIncomeDocument(
        response.applicantDocumentId,
        response
      );
      emit("documentYearChanged", response);
      isEditMode.value = false;
    } else {
      functions.openModal({
        title: "Error",
        description: "There was an issue updating the document year",
      });
    }
  } catch (error) {
    functions.openModal({
      title: "Error",
      description: "There was an issue updating the document year " + error,
    });
    editableYear.value = originalYear.value ?? 0;
  } finally {
    isSaving.value = false;
  }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(PermissionsWrapper, {
      disabled: !_ctx.canEdit,
      showDisabledIfNotVisible: true,
      permissionIds: [_unref(Permissions).Income],
      permissionLevel: _unref(PermissionLevels).Update
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _cache[1] || (_cache[1] = _createTextVNode(" Document Year ")),
              _withDirectives(_createElementVNode("div", { class: "xtext" }, _toDisplayString(formattedYear.value), 513), [
                [_vShow, !isEditMode.value]
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("button", {
              type: "button",
              class: _normalizeClass(["edit-link", { loading: isSaving.value }]),
              "aria-label": "Edit Document Year"
            }, [
              _createElementVNode("span", {
                class: "button-text",
                onClick: handleEditClick
              }, _toDisplayString(isEditMode.value ? "Save" : "Edit"), 1)
            ], 2)
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _withDirectives(_createVNode(BaseFormField, {
            modelValue: editableYear.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((editableYear).value = $event)),
            modelModifiers: { number: true },
            schema: documentSchema.year,
            options: _unref(yearOptions)(5)
          }, null, 8, ["modelValue", "schema", "options"]), [
            [_vShow, isEditMode.value]
          ])
        ])
      ]),
      _: 1
    }, 8, ["disabled", "permissionIds", "permissionLevel"])
  ]))
}
}

})