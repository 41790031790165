import { ref, onMounted } from 'vue';
import axios from 'axios';
import { ServiceType } from '@/models/loans';

export function usePayoffData() {
	const payoffDisbursementStatusOptions = ref<ServiceType[]>([]);
	const payoffDisbursementStatusReasonOptions = ref<ServiceType[]>([]);
	const payoffDisbursementTypeOptions = ref<ServiceType[]>([]);

	async function getStatusOptionsAsync() {
		try {
			const response = await axios.get('/api/payofftypes/PayoffDisbursementStatus');

			payoffDisbursementStatusOptions.value = response.data.map((type: ServiceType) => ({
				id: type.id,
				name: type.name
			}));
		} catch (error) {
			console.error('Error fetching payoff statuses:', error);
		}
	}

	async function getStatusReasonOptionsAsync() {
		try {
			const response = await axios.get('/api/payofftypes/PayoffDisbursementStatusReason');

			payoffDisbursementStatusReasonOptions.value = response.data.map((type: ServiceType) => ({
				id: type.id,
				name: type.name
			}));
		} catch (error) {
			console.error('Error fetching payoff status reasons:', error);
		}
	}

	async function getMethodTypeOptionsAsync() {
		try {
			const response = await axios.get('/api/payofftypes/PayoffDisbursementType');

			payoffDisbursementTypeOptions.value = response.data.map((type: ServiceType) => ({
				id: type.id,
				name: type.name
			}));
		} catch (error) {
			console.error('Error fetching payoff method types:', error);
		}
	}

	onMounted(async () => {
		await getStatusOptionsAsync();
		await getStatusReasonOptionsAsync();
		await getMethodTypeOptionsAsync();
	});

	return {
		payoffDisbursementStatusOptions,
		payoffDisbursementStatusReasonOptions,
		payoffDisbursementTypeOptions
	};
}
