import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { id: "applicationDetails" }
const _hoisted_2 = { id: "collapseDetails" }

import Loader from "@/components/Loader.vue";
import BreadCrumb from "@/components/application/BreadCrumb.vue";
import Page from "@/components/layout/Page.vue";
import Details from "@/components/v2/application/Details.vue";
import ApplicationApplicantDetails from "@/components/v2/application/ApplicationApplicantDetails.vue";
import TabWrapper from "@/components/wrappers/TabWrapper.vue";
import { onMounted, onUnmounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useApplicationStore } from "@/store/store/applicationVMStore";
import { useTypeStore } from "@/store/store/typeStore";
import { Type } from "@/types/types";
import { useStipulationStore } from "@/store/store/stipulationStore";


export default /*@__PURE__*/_defineComponent({
  __name: 'Application',
  setup(__props) {

const route = useRoute();
const applicationStore = useApplicationStore();
const typeStore = useTypeStore();
const stipulationStore = useStipulationStore();
const isReady = ref(false);

onMounted(async () => {
  await Promise.all([
    applicationStore.getApplication(route.params.referenceId.toString()),
    applicationStore.getApplicationSummary(route.params.referenceId.toString()),
    typeStore.getType(Type.JudgmentDenials),
    typeStore.getType(Type.ApplicationStatusReasons),
    typeStore.getDocServiceType(),
  ]);

  isReady.value = true;
});

onUnmounted(() => {
  applicationStore.$reset();
  stipulationStore.$reset();
});

return (_ctx: any,_cache: any) => {
  return (isReady.value)
    ? (_openBlock(), _createBlock(Page, { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(BreadCrumb),
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(Details)
            ]),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(ApplicationApplicantDetails)
            ]),
            _createVNode(TabWrapper)
          ])
        ]),
        _: 1
      }))
    : (_openBlock(), _createBlock(Loader, { key: 1 }))
}
}

})