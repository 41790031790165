import { ref } from 'vue';
import axios from 'axios';
import functions from '@/use/functions';
import { Draw } from '@/models/loans';

export function useDrawActions(loanId: number, onSuccessAsync: () => Promise<void>) {
	const isSubmitting = ref<boolean>(false);

	async function createDrawAsync(formData: Record<string, any>) {
		isSubmitting.value = true;

		try {
			const request = {
				loanId: loanId,
				...formData
			};

			await axios.post(`/api/loanservice/loan/${loanId}/draw`, request);

			await onSuccessAsync();
		} catch (error) {
			functions.openModal({
				title: 'Oops!',
				description: 'There was an issue creating a draw. Please try again later.'
			});
		} finally {
			isSubmitting.value = false;
		}
	}

	async function updateDrawAsync(formData: Record<string, any>, draw: Draw | null) {
		if (!draw) {
			return;
		}

		isSubmitting.value = true;

		try {
			const request = {
				id: draw.id,
				certifiedAmount: draw.certifiedAmount,
				...formData
			};

			await axios.patch(`/api/loanservice/draws/${draw.id}`, request);

			await onSuccessAsync();
		} catch (error) {
			functions.openModal({
				title: 'Oops!',
				description: `There was an issue updating draw ${draw.cluid}. Please try again later.`
			});
		} finally {
			isSubmitting.value = false;
		}
	}

	return {
		isSubmitting,
		createDrawAsync,
		updateDrawAsync
	};
}
