import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, vModelCheckbox as _vModelCheckbox, unref as _unref, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, vModelRadio as _vModelRadio, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "xpage-content-page" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "xfields" }
const _hoisted_5 = { class: "xfield" }
const _hoisted_6 = { class: "xfield" }
const _hoisted_7 = { class: "xfield" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = ["value"]
const _hoisted_10 = {
  key: 0,
  class: "error-message"
}
const _hoisted_11 = { class: "checkbox-container" }
const _hoisted_12 = { class: "xfields" }
const _hoisted_13 = { class: "checkfield" }
const _hoisted_14 = ["disabled"]
const _hoisted_15 = { class: "checkfield" }
const _hoisted_16 = ["disabled"]
const _hoisted_17 = { class: "xform-section-loc" }
const _hoisted_18 = { class: "exception-table" }
const _hoisted_19 = { class: "exception-text" }
const _hoisted_20 = { class: "exception-list" }
const _hoisted_21 = { class: "pricing-list" }
const _hoisted_22 = ["value", "disabled", "required"]
const _hoisted_23 = { class: "xform-section-loc" }
const _hoisted_24 = { class: "exception-table" }
const _hoisted_25 = { class: "exception-text" }
const _hoisted_26 = { class: "exception-list" }
const _hoisted_27 = { class: "pricing-list" }
const _hoisted_28 = { class: "exception-list" }
const _hoisted_29 = { class: "pricing-list" }
const _hoisted_30 = { class: "xform-section-loc-variable" }
const _hoisted_31 = { class: "exception-table" }
const _hoisted_32 = { class: "exception-text" }
const _hoisted_33 = { class: "exception-list" }
const _hoisted_34 = { class: "pricing-list" }
const _hoisted_35 = { class: "exception-list" }
const _hoisted_36 = { class: "pricing-list" }
const _hoisted_37 = { class: "exception-text" }
const _hoisted_38 = { class: "exception-list" }
const _hoisted_39 = { class: "pricing-list" }
const _hoisted_40 = { class: "exception-list" }
const _hoisted_41 = { class: "pricing-list" }
const _hoisted_42 = { class: "exception-list" }
const _hoisted_43 = { class: "pricing-list" }
const _hoisted_44 = { id: "dropDownProgramTiers" }
const _hoisted_45 = { style: {"display":"flex","justify-content":"space-between"} }
const _hoisted_46 = { class: "pricing-type" }
const _hoisted_47 = ["disabled", "required"]
const _hoisted_48 = ["value"]
const _hoisted_49 = {
  key: 0,
  class: "error-message"
}
const _hoisted_50 = { class: "xform-section-existing-rates" }
const _hoisted_51 = { id: "tblExistingFixedRate" }
const _hoisted_52 = { class: "exception-table" }
const _hoisted_53 = ["checked", "value", "disabled"]
const _hoisted_54 = { class: "exception-text" }
const _hoisted_55 = { class: "exception-list" }
const _hoisted_56 = { class: "pricing-list" }
const _hoisted_57 = { class: "exception-list" }
const _hoisted_58 = { class: "pricing-list" }
const _hoisted_59 = {
  id: "tblExistingVariableRate",
  class: "tblExistingVariableRate"
}
const _hoisted_60 = { class: "exception-table" }
const _hoisted_61 = ["checked", "value", "disabled"]
const _hoisted_62 = { class: "exception-text" }
const _hoisted_63 = { class: "exception-list" }
const _hoisted_64 = { class: "pricing-list" }
const _hoisted_65 = { class: "exception-list" }
const _hoisted_66 = { class: "pricing-list" }
const _hoisted_67 = { class: "exception-text" }
const _hoisted_68 = { class: "exception-list" }
const _hoisted_69 = { class: "pricing-list" }
const _hoisted_70 = { class: "exception-list" }
const _hoisted_71 = { class: "pricing-list" }
const _hoisted_72 = { class: "exception-list" }
const _hoisted_73 = { class: "pricing-list" }
const _hoisted_74 = { class: "xform-section-existing-rates" }
const _hoisted_75 = { class: "exception-table" }
const _hoisted_76 = { class: "exception-text" }
const _hoisted_77 = { class: "input-suffix-container" }
const _hoisted_78 = ["disabled"]
const _hoisted_79 = { class: "exception-list" }
const _hoisted_80 = { class: "pricing-list" }
const _hoisted_81 = { class: "exception-list" }
const _hoisted_82 = { class: "input-suffix-container" }
const _hoisted_83 = ["disabled"]
const _hoisted_84 = { class: "exception-text" }
const _hoisted_85 = { class: "exception-list" }
const _hoisted_86 = { class: "input-suffix-container" }
const _hoisted_87 = ["disabled"]
const _hoisted_88 = { class: "exception-list" }
const _hoisted_89 = { class: "input-suffix-container" }
const _hoisted_90 = ["disabled"]
const _hoisted_91 = { style: {"display":"flex","flex-flow":"column","padding":"30px 0 0 0"} }
const _hoisted_92 = ["disabled", "required"]
const _hoisted_93 = ["value"]
const _hoisted_94 = {
  key: 0,
  class: "error-message"
}
const _hoisted_95 = { class: "credit-union-decision-text" }
const _hoisted_96 = ["disabled"]
const _hoisted_97 = { class: "remaining-textbox" }
const _hoisted_98 = { class: "checkbox-container-acknowledge" }
const _hoisted_99 = { class: "xfields" }
const _hoisted_100 = { class: "checkfield" }
const _hoisted_101 = { class: "xfield" }
const _hoisted_102 = { class: "button-container" }
const _hoisted_103 = ["disabled"]
const _hoisted_104 = ["disabled"]

import { useEvaluationsTabStore } from "@/store/store/evaluationsStore";
import {
	AnalystReview,
	ExceptionTypes,
	OptionProgramTierTypes,
	OptionProgramTiers,
	ProgramTier,
	Recommendation,
} from "@/types/evaluation";
import useFormatter from "@/use/formatter";
import functions from "@/use/functions";
import axios, { AxiosResponse } from "axios";
import Loader from "@/components/Loader.vue";
import { computed, onMounted, ref } from "vue";
import PermissionsWrapper from "@/components/wrappers/PermissionsWrapper.vue";
import { Permissions, PermissionLevels } from "@/models/permissions";

interface Props {
	application: any;
	applicants: any;
	canEdit: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'EvaluationsCreditUnionDecision',
  props: {
    application: {},
    applicants: {},
    canEdit: { type: Boolean }
  },
  emits: ["submitForm"],
  setup(__props: any, { emit: __emit }) {

const store = useEvaluationsTabStore();


const analystReview = ref<AnalystReview>({
	applicationExceptionId: 0,
	reviewBy: "",
	reviewDateTime: "",
	decisionId: 0,
	comments: "",
	isCustom: false,
	exceptions: []
});

const isEdit = ref<boolean>(true);

//1.) To handle the custom pricing for pricing exceptions.
//2.) fixed Rate Selection is what is current selected
//3.) variable rate selection is what is current selected

const customPricingSelection = ref<ProgramTier>({});

const fixedRateSelection = ref<ProgramTier>({});

const variableRateSelection = ref<ProgramTier>({});

const existingFixedRates = ref<Array<ProgramTier>>([]);

const existingVariableRates = ref<Array<ProgramTier>>([]);

const existingVariableRateSelection = ref<number>();

const existingFixedRateSelection = ref<number>();

//To keep track of loc limit.
const newLOCLimit = ref<number>(0);
const userIsEditing = ref(false);

const displayedLOCLimit = computed({
	get: () => (userIsEditing.value ? newLOCLimit.value.toString() : formatMoney(newLOCLimit.value)),
	set: (value) => {
		newLOCLimit.value = parseMoney(value);
	},
});

//Drop Downs..
const recommendations = ref<Array<Recommendation>>([]);

const pricingReasons = ref<Array<Recommendation>>([]);

const optionProgramTiers = ref<Array<OptionProgramTiers>>([
	{
		id: OptionProgramTierTypes.ExistingPricingTier,
		name: "Choose Existing Program Tiers",
	},
	{
		id: OptionProgramTierTypes.CustomPricingTier,
		name: "Create Custom Program Tiers",
	},
]);

//This two variables are to track the selected option for both pricing drop downs.
const programTierOptionSelection = ref<number>(0);
const pricingExcpetionReasonSelected = ref<number>(0);

//This is to keep track when the user select different program tiers.
//Also going to use this one as well for custom.
//Ex. Custom will only have one element and program tiers always two.
const existingProgramTiersSelection = ref<Array<any>>([]);
const isAcknowledged = ref(false);
const isSubmitting = ref(false);

const { formatMoney } = useFormatter();

const message = ref("");
const props = __props;
const emit = __emit;

//Display elements when needed
const showLOCLimitException = ref<boolean>(false);
const addPricingException = ref<boolean>(false);
const showPricingErrorMsg = ref<boolean>(false);

function reloadWindow() {
	window.location.reload();
}

const remainingCharacters = computed(() => 512 - (analystReview.value.comments?.length || 0));

//When check box press for add loc limit exception wire the form.
const onChangeLOCLimit = () => {
	if (showLOCLimitException.value) {
    
		addOrUpdateLOCException();
		
	}else{
		removeException([ExceptionTypes.LocLimitMod]);
	}
};

//In the event that the user press Add Pricing Exception under  Existing Program Tiers but doesn't change
// the radio buttons this prevents from submission.
const isExistingProgramTiersDifferent = () => {
	//Lets Get the selected value
	const foundFixedRateSelection = existingFixedRates.value.find((item) => item.isSelected);

	const foundVariableRateSelection = existingVariableRates.value.find((item) => item.isSelected);

	if (
		foundFixedRateSelection?.pricingTierId == fixedRateSelection.value.pricingTierId ||
		foundVariableRateSelection?.pricingTierId == variableRateSelection.value.pricingTierId
	) {
		showPricingErrorMsg.value = true;
		return false;
	}

	showPricingErrorMsg.value = false;

	return true;
};

const HandleCustomProgramPricingSelection = () => {

	const exceptionTypes = getPricingExceptionTypes();

	const indexToUpdate = analystReview.value.exceptions?.findIndex(e => {
    return e.exceptionTypeId !== undefined && exceptionTypes.includes(e.exceptionTypeId);}) ?? -1;
	if(indexToUpdate !== -1 && analystReview.value.exceptions !== undefined){
		analystReview.value.exceptions[indexToUpdate].exceptionTypeId = pricingExcpetionReasonSelected.value;

		if(analystReview.value.exceptions[indexToUpdate].pricingTierIds === undefined)
		{	
			analystReview.value.exceptions[indexToUpdate].pricingTierIds = [];
		}

		if (variableRateSelection.value.isSelected && fixedRateSelection.value.isSelected) {
			analystReview.value.exceptions[indexToUpdate].customPricing = {
				f: customPricingSelection?.value?.floor,
				c: customPricingSelection?.value?.ceiling,
				m: customPricingSelection?.value?.margin,
				r: customPricingSelection?.value?.fixedRate,
			};
		} else {
			var fixedRate = getLastFixedRate();
			if (fixedRate !== undefined) {
				analystReview.value.exceptions[indexToUpdate].pricingTierIds?.push(fixedRate.pricingTierId!);
			}
			var variableRate = getLastVariableRate();

			if (variableRate !== undefined) {
				analystReview.value.exceptions[indexToUpdate].pricingTierIds?.push(variableRate.pricingTierId!);
			}

			analystReview.value.exceptions[indexToUpdate].customPricing = {
				f: customPricingSelection?.value?.floor,
				c: customPricingSelection?.value?.ceiling,
				m: customPricingSelection?.value?.margin,
				r: customPricingSelection?.value?.fixedRate,
			};
		}
	}


};

const HandleExistingProgramTiersSelection = () => {
	//Reset to nothing.
	existingProgramTiersSelection.value = [];

	const exceptionTypes = getPricingExceptionTypes();

	const indexToUpdate = analystReview.value.exceptions?.findIndex(e => {
    return e.exceptionTypeId !== undefined && exceptionTypes.includes(e.exceptionTypeId);}) ?? -1;
	if(indexToUpdate !== -1 && analystReview.value.exceptions !== undefined){
		analystReview.value.exceptions[indexToUpdate].pricingTierIds = [];

		//Lets Get the selected value
		const foundFixedRateSelection = existingFixedRates.value.find((item) => item.isSelected);

		const foundVariableRateSelection = existingVariableRates.value.find((item) => item.isSelected);

		if (
			foundFixedRateSelection?.pricingTierId == fixedRateSelection.value.pricingTierId &&
			foundVariableRateSelection?.pricingTierId == variableRateSelection.value.pricingTierId
		) {
			return false;
		}

		analystReview.value.exceptions[indexToUpdate].exceptionTypeId = pricingExcpetionReasonSelected.value;

		if (
			foundFixedRateSelection !== undefined &&
			!analystReview.value.exceptions[indexToUpdate].pricingTierIds?.includes(foundFixedRateSelection.pricingTierId!)
		) {
			analystReview.value.exceptions[indexToUpdate].pricingTierIds?.push(foundFixedRateSelection.pricingTierId!);
		}

		if (
			foundVariableRateSelection !== undefined &&
			!analystReview.value.exceptions[indexToUpdate].pricingTierIds?.includes(foundVariableRateSelection.pricingTierId!)
		) {
			analystReview.value.exceptions[indexToUpdate].pricingTierIds?.push(foundVariableRateSelection.pricingTierId!);
		}
	}
	
};

const onExistingFixedRateChange = () => {
	existingFixedRates.value.forEach((item) => {
		item.isSelected = item.pricingTierId == existingFixedRateSelection.value;
	});

	HandleExistingProgramTiersSelection();
};

const onExistingVariableRateChange = () => {
	existingVariableRates.value.forEach((item) => {
		item.isSelected = item.pricingTierId == existingVariableRateSelection.value;
	});

	HandleExistingProgramTiersSelection();
};

const addOrUpdateLOCException = () => {
	if(analystReview.value.exceptions === undefined){
			analystReview.value.exceptions = [];
		}


		const indexToUpdate = analystReview.value
						.exceptions?.findIndex(obj => obj.exceptionTypeId == ExceptionTypes.LocLimitMod) ?? -1;

	    if(indexToUpdate !== -1 && analystReview.value.exceptions !== undefined){
			analystReview.value.exceptions[indexToUpdate] = {
				exceptionTypeId: ExceptionTypes.LocLimitMod,
				locLimit: newLOCLimit.value.toString(),
				pricingTierIds: [],
				customPricing: undefined,
			}
		}else{
			analystReview.value.exceptions!.push({
				exceptionTypeId: ExceptionTypes.LocLimitMod,
				locLimit: newLOCLimit.value.toString(),
				pricingTierIds: [],
				customPricing: undefined,
			})
		}
}

const removeException = (exceptionTypes: Array<ExceptionTypes>) => {
		const indexToRemove = analystReview.value.exceptions?.findIndex(e => {
			return e.exceptionTypeId !== undefined && exceptionTypes.includes(e.exceptionTypeId);
		}) ?? -1;


		if (indexToRemove !== -1) {
				analystReview.value.exceptions?.splice(indexToRemove, 1); // Remove object at indexToRemove
		}
}

const onLOCLimitExceptionChange = () => {
	if (showLOCLimitException.value) {
		//Add Exception if not already exist on the array.
		addOrUpdateLOCException();
	} else {
		removeException([ExceptionTypes.LocLimitMod]);
	}
};

const IsProgramTiersSame = () => {
	return (
		customPricingSelection.value.fixedRate == fixedRateSelection.value.fixedRate?.replace("%", "") &&
		customPricingSelection.value.margin == variableRateSelection.value.margin?.replace("%", "") &&
		customPricingSelection.value.floor == variableRateSelection.value.floor?.replace("%", "") &&
		customPricingSelection.value.ceiling == variableRateSelection.value.ceiling?.replace("%", "")
	);
};

const getLastVariableRate = () => {
	return existingVariableRates.value.slice(-1)[0];
};

const getLastFixedRate = () => {
	return existingFixedRates.value.slice(-1)[0];
};

const getPricingExceptionTypes = () =>{
	return [
				ExceptionTypes.PricingDefault, 
				ExceptionTypes.PricingRateCreditScoreAdjustment, 
				ExceptionTypes.PricingRateOther,
				ExceptionTypes.PricingRateRateMatchBeat
			];
}

const onProgramTiersChange = () => {
	// customPricingSelection

	if (variableRateSelection.value.isSelected) {
		customPricingSelection.value.fixedRate = fixedRateSelection.value.fixedRate?.replace("%", "");

		customPricingSelection.value.variableRate = variableRateSelection.value.variableRate;

		customPricingSelection.value.margin = variableRateSelection.value.margin?.replace("%", "");

		customPricingSelection.value.index = variableRateSelection.value.index;

		customPricingSelection.value.floor = variableRateSelection.value.floor?.replace("%", "");

		customPricingSelection.value.ceiling = variableRateSelection.value.ceiling?.replace("%", "");
	} //since no variable rates selected get the lowest and assign index.
	else {
		const lastVariableRate = getLastVariableRate();

		const lastFixedRate = getLastFixedRate();

		if (lastVariableRate !== undefined) customPricingSelection.value.index = lastVariableRate.index;

		customPricingSelection.value.fixedRate =
			lastFixedRate !== undefined ? lastFixedRate.fixedRate?.replace("%", "") : "0";
	}

	analystReview.value.isCustom = programTierOptionSelection.value == OptionProgramTierTypes.CustomPricingTier;
};

const onPricingExceptionChange = () => {
	if (addPricingException.value) {
		getPricingException();

		// Add a default pricing..
		//We adding temporary pricing..
		if(analystReview.value.exceptions === undefined)
		{
			analystReview.value.exceptions = [];
		}

		analystReview.value.exceptions!.push({
			exceptionTypeId: ExceptionTypes.PricingDefault,
			locLimit: undefined,
			customPricing: undefined,
			pricingTierIds: [],
		});
	} else {

		if(analystReview.value.exceptions !== undefined){
			const exceptionTypes = getPricingExceptionTypes();

			removeException(exceptionTypes);
		}
	
		analystReview.value.isCustom = false;
		if (showLOCLimitException.value) {
			onLOCLimitExceptionChange();
		}
	}
};

const onChangeMarginCal = () => {
	const customMargin = Number(customPricingSelection?.value?.margin).toFixed(4);

	let indexMargin = Number(customPricingSelection.value.index?.replace("%", "")).toFixed(4);

	if (variableRateSelection.value.isSelected) {
		indexMargin = Number(variableRateSelection.value.index?.replace("%", "")).toFixed(4);
	}

	const floor = Number(customPricingSelection?.value?.floor).toFixed(4);

	const ceiling = Number(customPricingSelection?.value?.ceiling).toFixed(4);

	if (parseFloat(customMargin) + parseFloat(indexMargin) < parseFloat(floor)) {
		customPricingSelection.value.margin = variableRateSelection.value.margin?.replace("%", "");
		customPricingSelection.value.variableRate = variableRateSelection.value.variableRate;
		alert("Variable rate can't be lower than Floor");
		return;
	} else if (parseFloat(customMargin) + parseFloat(indexMargin) > parseFloat(ceiling)) {
		customPricingSelection.value.margin = variableRateSelection.value.margin?.replace("%", "");
		customPricingSelection.value.variableRate = variableRateSelection.value.variableRate;
		alert("Variable rate can't be higher than Ceiling");
		return;
	}

	// Convert the strings back to numbers before performing addition
	const customCal = (parseFloat(customMargin) + parseFloat(indexMargin)).toFixed(4);

	customPricingSelection.value.variableRate = `${customCal}%`;
};

// disable fixed rate custom input if no fixedRateSelection
const disableCustomFixedRate = computed(() => {
	return !fixedRateSelection.value.fixedRate && getLastFixedRate() === undefined;
});

const canSubmitApproval = () => {
	const approval = 1;

	if (analystReview.value.decisionId === approval) {
		if (store.hasNOI) return true;

		if (newLOCLimit.value <= 0 && showLOCLimitException.value) return true;

		if (addPricingException.value && programTierOptionSelection.value == OptionProgramTierTypes.ExistingPricingTier) {
			return !isExistingProgramTiersDifferent();
		}
	}

	return false;
};

async function submitForm() {
	if (canSubmitApproval()) {
		functions.openModal({
			title: "Incomplete Application",
			description: "Application must have Line of Credit or Pricing set to Save approval.",
			buttons: [
				{
					title: "OK",
					onClick: async () => {
						await functions.closeModal();
					},
				},
			],
		});
		return;
	}
	if (addPricingException.value && programTierOptionSelection.value == OptionProgramTierTypes.ExistingPricingTier) {
		if (!isExistingProgramTiersDifferent()) {
			return; //prevent submission;
		}

		HandleExistingProgramTiersSelection();
	} else if (
		addPricingException.value &&
		programTierOptionSelection.value == OptionProgramTierTypes.CustomPricingTier
	) {
		if (IsProgramTiersSame()) {
			alert("Custom pricing values need to change.");
			return; //prevent submission;
		}

		HandleCustomProgramPricingSelection();
	} else if (showLOCLimitException.value && newLOCLimit.value == 0) {
		alert("The LOC limit needs to be greater than 0.");
		return; //prevent submission;
	}

	isSubmitting.value = true;

	try {
		const referenceId = props.application.data.referenceId;
		const url = `/api/applications/${referenceId}/Exception`;
		const exceptionsUrl = `/api/applications/${referenceId}/Exceptions`;

		let response: AxiosResponse<any, any>;

		if (analystReview.value.applicationExceptionId == 0) {

			response = await axios.post(url, analystReview.value);
		} else {
			response = await axios.put(url, analystReview.value);
		}

		if (response.data.success) {
			
			analystReview.value = response.data.results;
			functions.openModal({
				title: "Credit Union Decision",
				description: "Credit Union Decision has been saved.",
				buttons: [
					{
						title: "OK",
						onClick: async () => {
							await functions.closeModal();
							reloadWindow();
						},
					},
				],
			});
		} else {
		
			functions.openModal({
				title: "Credit Union Decision",
				description: "Credit Union Decision has not been saved.",
				buttons: [
					{
						title: "OK",
						onClick: async () => {
							await functions.closeModal();
							reloadWindow();
						},
					},
				],
			});
		}
	} catch (error) {
		console.log("Error saving details:", error);
	}

	isSubmitting.value = false;
}

function resetForm() {
	analystReview.value = {
		reviewBy: "",
		reviewDateTime: "",
		decisionId: 0,
		comments: "",
		exceptions: []
	};
	showLOCLimitException.value = false;
	addPricingException.value = false;
	showPricingErrorMsg.value = false;
	programTierOptionSelection.value = 0;
	pricingExcpetionReasonSelected.value = 0;
	newLOCLimit.value = 0;

	// reset custom pricing selection
	customPricingSelection.value = {};
	fixedRateSelection.value = {};
	variableRateSelection.value = {};

	// reset existing rates selection
	existingFixedRateSelection.value = 0;
	existingVariableRateSelection.value = 0;

	// clear existing rates
	existingFixedRates.value.forEach((rate) => (rate.isSelected = false));
	existingVariableRates.value.forEach((rate) => (rate.isSelected = false));
}

function parseMoney(value: string) {
	const match = value.match(/[\d.]+/);
	return match ? parseInt(match[0], 10) : 0;
}

function handleNewLOCInput(event: Event): void {
	const target = event.target as HTMLInputElement;
	const value = target.value;
	if (value !== undefined) {
		newLOCLimit.value = parseMoney(value);
	}
}

async function getRecommendations() {
	const url = "/api/types/CU/Decision/Recomendations";
	try {
		const response = await axios.get(url);
		recommendations.value = response.data;
	} catch (error) {
		console.log("Error saving details:", error);
	}
}

async function getPricingExceptionReasons() {
	const url = `/api/types/pricing/exception/reasons`;

	try {
		const response = await axios.get(url);

		pricingReasons.value = response.data;
	} catch (error) {
		console.log("Error saving details:", error);
	}
}

async function getPricingException() {
	const referenceId = props.application.data.referenceId;
	const url = `/api/applications/${referenceId}/Pricing/Details`;
	try {
		const response = await axios.get(url);

		if (response.data.success) {
			fixedRateSelection.value = response.data.results.fixedRate;

			variableRateSelection.value = response.data.results.variableRate;

			existingFixedRates.value = response.data.results.fixedRates;

			existingVariableRates.value = response.data.results.variableRates;
		}
	} catch (error) {
		console.log("Error saving details:", error);
	}
}

const canBeModified = () => {
	const underReview = 5;

	if (analystReview.value.decisionId == underReview || analystReview.value.decisionId == 0) {
		isEdit.value = true;
	} else {
		isEdit.value = !analystReview.value.reviewDateTime;
	}
};

async function getExistingException() {
	const referenceId = props.application.data.referenceId;
	const url = `/api/applications/${referenceId}/CU/Exception`;
	try {
		const response = await axios.get(url);

		if (response.data.success) {
			analystReview.value = response.data.results;
			
			if (analystReview.value.exceptions !== undefined) {
				analystReview.value.exceptions.forEach(e => {
					if(e.pricingTierIds === undefined){
						e.pricingTierIds = [];
					}
				});
			}
			canBeModified();
		}
	} catch (error) {
		console.log("Error saving details:", error);
	}
}

onMounted(() => {
	getRecommendations();
	getPricingExceptionReasons();
	getExistingException();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (isSubmitting.value)
      ? (_openBlock(), _createBlock(Loader, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("form", {
        onSubmit: _withModifiers(submitForm, ["prevent"]),
        autocomplete: "off"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _cache[51] || (_cache[51] = _createElementVNode("div", { class: "card-header" }, [
            _createElementVNode("h3", null, "Credit Union Decision")
          ], -1)),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _cache[17] || (_cache[17] = _createElementVNode("label", { class: "title-label" }, "Exception Decision By", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((analystReview.value.reviewBy) = $event)),
                  class: "input-field-textarea",
                  placeholder: "Exception decision by",
                  disabled: ""
                }, null, 512), [
                  [_vModelText, analystReview.value.reviewBy]
                ])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _cache[18] || (_cache[18] = _createElementVNode("label", { class: "title-label" }, "Exception Created Date and Time", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((analystReview.value.reviewDateTime) = $event)),
                  class: "input-field-textarea",
                  placeholder: "Date and Time",
                  disabled: ""
                }, null, 512), [
                  [_vModelText, analystReview.value.reviewDateTime]
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _cache[20] || (_cache[20] = _createElementVNode("label", { class: "title-label" }, [
                  _createTextVNode(" Credit Decision Reason "),
                  _createElementVNode("i", null, "*")
                ], -1)),
                _withDirectives(_createElementVNode("select", {
                  class: "input-field",
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((analystReview.value.decisionId) = $event)),
                  disabled: isAcknowledged.value,
                  required: ""
                }, [
                  _cache[19] || (_cache[19] = _createElementVNode("option", {
                    disabled: "",
                    value: ""
                  }, "Select CD Recommendation", -1)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(recommendations.value, (recommendation, index) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: index,
                      value: recommendation.id
                    }, _toDisplayString(recommendation.name), 9, _hoisted_9))
                  }), 128))
                ], 8, _hoisted_8), [
                  [_vModelSelect, analystReview.value.decisionId]
                ]),
                (!analystReview.value.decisionId)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_10, "Please select a credit decision reason"))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _withDirectives(_createElementVNode("input", {
                    type: "checkbox",
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((showLOCLimitException).value = $event)),
                    onChange: onLOCLimitExceptionChange,
                    disabled: isAcknowledged.value,
                    placeholder: "Exception initiated by"
                  }, null, 40, _hoisted_14), [
                    [_vModelCheckbox, showLOCLimitException.value]
                  ])
                ]),
                _cache[21] || (_cache[21] = _createElementVNode("span", { class: "checkbutton" }, "Add LOC Limit Exception", -1)),
                _createElementVNode("div", _hoisted_15, [
                  _withDirectives(_createElementVNode("input", {
                    type: "checkbox",
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((addPricingException).value = $event)),
                    onChange: onPricingExceptionChange,
                    disabled: isAcknowledged.value,
                    placeholder: "Date and Time"
                  }, null, 40, _hoisted_16), [
                    [_vModelCheckbox, addPricingException.value]
                  ])
                ]),
                _cache[22] || (_cache[22] = _createElementVNode("span", { class: "checkbutton" }, "Add Pricing Exception", -1)),
                _cache[23] || (_cache[23] = _createElementVNode("div", { class: "checkfield" }, null, -1))
              ])
            ]),
            _createVNode(PermissionsWrapper, {
              disabled: !_ctx.canEdit,
              showDisabledIfNotVisible: true,
              permissionIds: [_unref(Permissions).Evaluation],
              permissionLevel: _unref(PermissionLevels).Update
            }, {
              default: _withCtx(() => [
                _withDirectives(_createElementVNode("div", _hoisted_17, [
                  _cache[25] || (_cache[25] = _createElementVNode("h3", null, "LOC Limit Exception", -1)),
                  _createElementVNode("table", _hoisted_18, [
                    _cache[24] || (_cache[24] = _createElementVNode("tr", null, [
                      _createElementVNode("td", { class: "exception-text-header" }, "LOC-Program Limit"),
                      _createElementVNode("td", { class: "exception-text-header" }, "New LOC Limit")
                    ], -1)),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_19, _toDisplayString(_unref(formatMoney)(_ctx.application.data.loanAmount)), 1),
                      _createElementVNode("td", _hoisted_20, [
                        _createElementVNode("div", _hoisted_21, [
                          _createElementVNode("input", {
                            type: "text",
                            value: displayedLOCLimit.value,
                            onInput: handleNewLOCInput,
                            onFocus: _cache[5] || (_cache[5] = ($event: any) => (userIsEditing.value = true)),
                            onBlur: _cache[6] || (_cache[6] = ($event: any) => (userIsEditing.value = false)),
                            name: "locLimit",
                            onChange: onChangeLOCLimit,
                            disabled: isAcknowledged.value,
                            required: showLOCLimitException.value
                          }, null, 40, _hoisted_22)
                        ])
                      ])
                    ])
                  ])
                ], 512), [
                  [_vShow, showLOCLimitException.value]
                ])
              ]),
              _: 1
            }, 8, ["disabled", "permissionIds", "permissionLevel"]),
            _createVNode(PermissionsWrapper, {
              disabled: !_ctx.canEdit,
              showDisabledIfNotVisible: true,
              permissionIds: [_unref(Permissions).Evaluation],
              permissionLevel: _unref(PermissionLevels).Update
            }, {
              default: _withCtx(() => [
                _withDirectives(_createElementVNode("div", _hoisted_23, [
                  _cache[27] || (_cache[27] = _createElementVNode("h3", { class: "pr-loc-large-text" }, "Pricing Exceptions", -1)),
                  _cache[28] || (_cache[28] = _createElementVNode("h5", { class: "pr-loc-small-text" }, "Assigned Pricing (if available)", -1)),
                  _cache[29] || (_cache[29] = _createElementVNode("br", null, null, -1)),
                  _cache[30] || (_cache[30] = _createElementVNode("label", { class: "caption-label" }, "Fixed Rate", -1)),
                  _createElementVNode("table", _hoisted_24, [
                    _cache[26] || (_cache[26] = _createElementVNode("tr", null, [
                      _createElementVNode("td", { class: "exception-text-header" }, "Pricing Tier"),
                      _createElementVNode("td", { class: "exception-text-header" }, "Score Range"),
                      _createElementVNode("td", { class: "exception-text-header" }, "Fixed Rate")
                    ], -1)),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_25, _toDisplayString(fixedRateSelection.value.letterGradePricingTier), 1),
                      _createElementVNode("td", _hoisted_26, [
                        _createElementVNode("div", _hoisted_27, _toDisplayString(fixedRateSelection.value.scoreRange), 1)
                      ]),
                      _createElementVNode("td", _hoisted_28, [
                        _createElementVNode("div", _hoisted_29, _toDisplayString(fixedRateSelection.value.fixedRate), 1)
                      ])
                    ])
                  ])
                ], 512), [
                  [_vShow, addPricingException.value]
                ]),
                _withDirectives(_createElementVNode("div", _hoisted_30, [
                  _cache[32] || (_cache[32] = _createElementVNode("label", { class: "caption-label" }, "Variable Rate", -1)),
                  _createElementVNode("table", _hoisted_31, [
                    _cache[31] || (_cache[31] = _createElementVNode("tr", null, [
                      _createElementVNode("td", { class: "exception-text-header" }, "Pricing Tier"),
                      _createElementVNode("td", { class: "exception-text-header" }, "Score Range"),
                      _createElementVNode("td", { class: "exception-text-header" }, "Variable Rate"),
                      _createElementVNode("td", { class: "exception-text-header" }, "Margin"),
                      _createElementVNode("td", { class: "exception-text-header" }, "Index"),
                      _createElementVNode("td", { class: "exception-text-header" }, "Floor"),
                      _createElementVNode("td", { class: "exception-text-header" }, "Ceiling")
                    ], -1)),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_32, _toDisplayString(variableRateSelection.value.letterGradePricingTier), 1),
                      _createElementVNode("td", _hoisted_33, [
                        _createElementVNode("div", _hoisted_34, _toDisplayString(variableRateSelection.value.scoreRange), 1)
                      ]),
                      _createElementVNode("td", _hoisted_35, [
                        _createElementVNode("div", _hoisted_36, _toDisplayString(variableRateSelection.value.variableRate), 1)
                      ]),
                      _createElementVNode("td", _hoisted_37, _toDisplayString(variableRateSelection.value.margin), 1),
                      _createElementVNode("td", _hoisted_38, [
                        _createElementVNode("div", _hoisted_39, _toDisplayString(variableRateSelection.value.index), 1)
                      ]),
                      _createElementVNode("td", _hoisted_40, [
                        _createElementVNode("div", _hoisted_41, _toDisplayString(variableRateSelection.value.floor), 1)
                      ]),
                      _createElementVNode("td", _hoisted_42, [
                        _createElementVNode("div", _hoisted_43, _toDisplayString(variableRateSelection.value.ceiling), 1)
                      ])
                    ])
                  ])
                ], 512), [
                  [_vShow, addPricingException.value]
                ])
              ]),
              _: 1
            }, 8, ["disabled", "permissionIds", "permissionLevel"]),
            _createVNode(PermissionsWrapper, {
              disabled: !_ctx.canEdit,
              showDisabledIfNotVisible: true,
              permissionIds: [_unref(Permissions).Evaluation],
              permissionLevel: _unref(PermissionLevels).Update
            }, {
              default: _withCtx(() => [
                _withDirectives(_createElementVNode("div", _hoisted_44, [
                  _createElementVNode("div", _hoisted_45, [
                    _createElementVNode("div", _hoisted_46, [
                      _cache[34] || (_cache[34] = _createElementVNode("label", { class: "title-label" }, [
                        _createTextVNode(" Select the type of pricing exception you'd like to make. "),
                        _createElementVNode("i", null, "*")
                      ], -1)),
                      _withDirectives(_createElementVNode("select", {
                        onChange: onProgramTiersChange,
                        class: "input-field",
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((programTierOptionSelection).value = $event)),
                        disabled: isAcknowledged.value,
                        required: addPricingException.value
                      }, [
                        _cache[33] || (_cache[33] = _createElementVNode("option", { value: "" }, "--Select--", -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(optionProgramTiers.value, (programTier, index) => {
                          return (_openBlock(), _createElementBlock("option", {
                            key: index,
                            value: programTier.id
                          }, _toDisplayString(programTier.name), 9, _hoisted_48))
                        }), 128))
                      ], 40, _hoisted_47), [
                        [_vModelSelect, programTierOptionSelection.value]
                      ]),
                      (!programTierOptionSelection.value)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_49, " Please select a type of pricing exception "))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _withDirectives(_createElementVNode("div", _hoisted_50, [
                    _createElementVNode("div", _hoisted_51, [
                      _cache[36] || (_cache[36] = _createElementVNode("label", { class: "caption-label" }, "Fixed Rate", -1)),
                      _createElementVNode("table", _hoisted_52, [
                        _cache[35] || (_cache[35] = _createElementVNode("tr", null, [
                          _createElementVNode("td", { class: "exception-text-header" }, "Select"),
                          _createElementVNode("td", { class: "exception-text-header" }, "Pricing Tier"),
                          _createElementVNode("td", { class: "exception-text-header" }, "Score Range"),
                          _createElementVNode("td", { class: "exception-text-header" }, "Fixed Rate")
                        ], -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(existingFixedRates.value, (fixedRate, index) => {
                          return (_openBlock(), _createElementBlock("tr", { key: index }, [
                            _createElementVNode("td", null, [
                              _withDirectives(_createElementVNode("input", {
                                type: "radio",
                                required: "",
                                name: "existingFixedRate",
                                checked: fixedRate.isSelected,
                                value: fixedRate.pricingTierId,
                                disabled: isAcknowledged.value,
                                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((existingFixedRateSelection).value = $event)),
                                onChange: onExistingFixedRateChange
                              }, null, 40, _hoisted_53), [
                                [_vModelRadio, existingFixedRateSelection.value]
                              ])
                            ]),
                            _createElementVNode("td", _hoisted_54, _toDisplayString(fixedRate.letterGradePricingTier), 1),
                            _createElementVNode("td", _hoisted_55, [
                              _createElementVNode("div", _hoisted_56, _toDisplayString(fixedRate.scoreRange), 1)
                            ]),
                            _createElementVNode("td", _hoisted_57, [
                              _createElementVNode("div", _hoisted_58, _toDisplayString(fixedRate.fixedRate), 1)
                            ])
                          ]))
                        }), 128))
                      ])
                    ]),
                    _createVNode(PermissionsWrapper, {
                      disabled: !_ctx.canEdit,
                      showDisabledIfNotVisible: true,
                      permissionIds: [_unref(Permissions).Evaluation],
                      permissionLevel: _unref(PermissionLevels).Update
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_59, [
                          _cache[38] || (_cache[38] = _createElementVNode("label", { class: "caption-label" }, "Variable Rate", -1)),
                          _createElementVNode("table", _hoisted_60, [
                            _cache[37] || (_cache[37] = _createElementVNode("tr", null, [
                              _createElementVNode("td", { class: "exception-text-header" }, "Select"),
                              _createElementVNode("td", { class: "exception-text-header" }, "Pricing Tier"),
                              _createElementVNode("td", { class: "exception-text-header" }, "Score Range"),
                              _createElementVNode("td", { class: "exception-text-header" }, "Variable Rate"),
                              _createElementVNode("td", { class: "exception-text-header" }, "Margin"),
                              _createElementVNode("td", { class: "exception-text-header" }, "Index"),
                              _createElementVNode("td", { class: "exception-text-header" }, "Floor"),
                              _createElementVNode("td", { class: "exception-text-header" }, "Ceiling")
                            ], -1)),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(existingVariableRates.value, (variableRate, index) => {
                              return (_openBlock(), _createElementBlock("tr", { key: index }, [
                                _createElementVNode("td", null, [
                                  _withDirectives(_createElementVNode("input", {
                                    type: "radio",
                                    required: "",
                                    name: "existingVariableRate",
                                    checked: variableRate.isSelected,
                                    value: variableRate.pricingTierId,
                                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((existingVariableRateSelection).value = $event)),
                                    disabled: isAcknowledged.value,
                                    onChange: onExistingVariableRateChange
                                  }, null, 40, _hoisted_61), [
                                    [_vModelRadio, existingVariableRateSelection.value]
                                  ])
                                ]),
                                _createElementVNode("td", _hoisted_62, _toDisplayString(variableRate.letterGradePricingTier), 1),
                                _createElementVNode("td", _hoisted_63, [
                                  _createElementVNode("div", _hoisted_64, _toDisplayString(variableRate.scoreRange), 1)
                                ]),
                                _createElementVNode("td", _hoisted_65, [
                                  _createElementVNode("div", _hoisted_66, _toDisplayString(variableRate.variableRate), 1)
                                ]),
                                _createElementVNode("td", _hoisted_67, _toDisplayString(variableRate.margin), 1),
                                _createElementVNode("td", _hoisted_68, [
                                  _createElementVNode("div", _hoisted_69, _toDisplayString(variableRate.index), 1)
                                ]),
                                _createElementVNode("td", _hoisted_70, [
                                  _createElementVNode("div", _hoisted_71, _toDisplayString(variableRate.floor), 1)
                                ]),
                                _createElementVNode("td", _hoisted_72, [
                                  _createElementVNode("div", _hoisted_73, _toDisplayString(variableRate.ceiling), 1)
                                ])
                              ]))
                            }), 128))
                          ])
                        ])
                      ]),
                      _: 1
                    }, 8, ["disabled", "permissionIds", "permissionLevel"])
                  ], 512), [
                    [_vShow, programTierOptionSelection.value == _unref(OptionProgramTierTypes).ExistingPricingTier]
                  ]),
                  _withDirectives(_createElementVNode("div", _hoisted_74, [
                    _cache[44] || (_cache[44] = _createElementVNode("div", { class: "caption-label-create-new-container" }, [
                      _createElementVNode("label", { class: "caption-label-create-new" }, "Create New Pricing for this Application")
                    ], -1)),
                    _createElementVNode("table", _hoisted_75, [
                      _cache[43] || (_cache[43] = _createElementVNode("tr", null, [
                        _createElementVNode("td", { class: "exception-text-header" }, "Fixed Rate"),
                        _createElementVNode("td", { class: "exception-text-header" }, "Variable Rate"),
                        _createElementVNode("td", { class: "exception-text-header" }, "Margin"),
                        _createElementVNode("td", { class: "exception-text-header" }, "Index"),
                        _createElementVNode("td", { class: "exception-text-header" }, "Floor"),
                        _createElementVNode("td", { class: "exception-text-header" }, "Ceiling")
                      ], -1)),
                      _createElementVNode("tr", null, [
                        _createElementVNode("td", _hoisted_76, [
                          _createElementVNode("div", _hoisted_77, [
                            _withDirectives(_createElementVNode("input", {
                              id: "customFixedRate",
                              type: "number",
                              step: "0.0001",
                              class: "suffixed-input",
                              disabled: isAcknowledged.value || disableCustomFixedRate.value,
                              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((customPricingSelection.value.fixedRate) = $event))
                            }, null, 8, _hoisted_78), [
                              [_vModelText, customPricingSelection.value.fixedRate]
                            ]),
                            _cache[39] || (_cache[39] = _createElementVNode("span", { class: "input-suffix" }, "%", -1))
                          ])
                        ]),
                        _createElementVNode("td", _hoisted_79, [
                          _createElementVNode("div", _hoisted_80, _toDisplayString(customPricingSelection.value.variableRate), 1)
                        ]),
                        _createElementVNode("td", _hoisted_81, [
                          _createElementVNode("div", _hoisted_82, [
                            _withDirectives(_createElementVNode("input", {
                              id: "customMarginRate",
                              type: "number",
                              step: "0.0001",
                              disabled: isAcknowledged.value,
                              class: "suffixed-input",
                              onChange: onChangeMarginCal,
                              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((customPricingSelection.value.margin) = $event)),
                              required: ""
                            }, null, 40, _hoisted_83), [
                              [_vModelText, customPricingSelection.value.margin]
                            ]),
                            _cache[40] || (_cache[40] = _createElementVNode("span", { class: "input-suffix" }, "%", -1))
                          ])
                        ]),
                        _createElementVNode("td", _hoisted_84, _toDisplayString(customPricingSelection.value.index), 1),
                        _createElementVNode("td", _hoisted_85, [
                          _createElementVNode("div", _hoisted_86, [
                            _withDirectives(_createElementVNode("input", {
                              id: "customFloorRate",
                              type: "number",
                              step: "0.0001",
                              disabled: isAcknowledged.value,
                              class: "suffixed-input",
                              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((customPricingSelection.value.floor) = $event)),
                              required: ""
                            }, null, 8, _hoisted_87), [
                              [_vModelText, customPricingSelection.value.floor]
                            ]),
                            _cache[41] || (_cache[41] = _createElementVNode("span", { class: "input-suffix" }, "%", -1))
                          ])
                        ]),
                        _createElementVNode("td", _hoisted_88, [
                          _createElementVNode("div", _hoisted_89, [
                            _withDirectives(_createElementVNode("input", {
                              id: "customCeilingRate",
                              type: "number",
                              step: "0.0001",
                              disabled: isAcknowledged.value,
                              class: "suffixed-input",
                              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((customPricingSelection.value.ceiling) = $event)),
                              required: ""
                            }, null, 8, _hoisted_90), [
                              [_vModelText, customPricingSelection.value.ceiling]
                            ]),
                            _cache[42] || (_cache[42] = _createElementVNode("span", { class: "input-suffix" }, "%", -1))
                          ])
                        ])
                      ])
                    ])
                  ], 512), [
                    [_vShow, programTierOptionSelection.value == _unref(OptionProgramTierTypes).CustomPricingTier]
                  ]),
                  _createElementVNode("div", _hoisted_91, [
                    _cache[46] || (_cache[46] = _createElementVNode("label", { class: "title-label" }, [
                      _createTextVNode(" Pricing Exception Reason "),
                      _createElementVNode("i", null, "*")
                    ], -1)),
                    _withDirectives(_createElementVNode("select", {
                      style: {"width":"320px"},
                      class: "input-field",
                      "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((pricingExcpetionReasonSelected).value = $event)),
                      disabled: isAcknowledged.value,
                      required: addPricingException.value
                    }, [
                      _cache[45] || (_cache[45] = _createElementVNode("option", { value: "" }, "--Select--", -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(pricingReasons.value, (pricing, index) => {
                        return (_openBlock(), _createElementBlock("option", {
                          key: index,
                          value: pricing.id
                        }, _toDisplayString(pricing.name), 9, _hoisted_93))
                      }), 128))
                    ], 8, _hoisted_92), [
                      [_vModelSelect, pricingExcpetionReasonSelected.value]
                    ]),
                    (!pricingExcpetionReasonSelected.value)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_94, " Please select a pricing exception reason "))
                      : _createCommentVNode("", true)
                  ])
                ], 512), [
                  [_vShow, addPricingException.value]
                ]),
                _createElementVNode("div", _hoisted_95, [
                  _cache[47] || (_cache[47] = _createElementVNode("label", { class: "title-label" }, "Credit Union Comments", -1)),
                  _withDirectives(_createElementVNode("textarea", {
                    "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((analystReview.value.comments) = $event)),
                    disabled: isAcknowledged.value || !isEdit.value,
                    id: "message",
                    maxlength: "512",
                    rows: "4",
                    class: "block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500",
                    placeholder: "Write up your comments here...",
                    required: ""
                  }, null, 8, _hoisted_96), [
                    [_vModelText, analystReview.value.comments]
                  ]),
                  _createElementVNode("div", _hoisted_97, _toDisplayString(remainingCharacters.value) + " characters remaining ", 1)
                ]),
                _createElementVNode("div", _hoisted_98, [
                  _createElementVNode("div", _hoisted_99, [
                    _createElementVNode("div", _hoisted_100, [
                      _withDirectives(_createElementVNode("input", {
                        type: "checkbox",
                        "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((isAcknowledged).value = $event)),
                        placeholder: "Exception initiated by",
                        required: ""
                      }, null, 512), [
                        [_vModelCheckbox, isAcknowledged.value]
                      ])
                    ]),
                    _cache[48] || (_cache[48] = _createElementVNode("span", { class: "checkbutton" }, "I acknowledge I reviewed and completed Application Exception.", -1)),
                    _cache[49] || (_cache[49] = _createElementVNode("div", { class: "checkfield" }, null, -1)),
                    _cache[50] || (_cache[50] = _createElementVNode("div", { class: "checkfield" }, null, -1))
                  ])
                ])
              ]),
              _: 1
            }, 8, ["disabled", "permissionIds", "permissionLevel"]),
            _createVNode(PermissionsWrapper, {
              disabled: !_ctx.canEdit,
              showDisabledIfNotVisible: true,
              permissionIds: [_unref(Permissions).Evaluation],
              permissionLevel: _unref(PermissionLevels).Update
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_101, [
                  _createElementVNode("div", _hoisted_102, [
                    _createElementVNode("button", {
                      class: "draw-button submit",
                      onClick: _withModifiers(resetForm, ["prevent"]),
                      disabled: !isEdit.value
                    }, "Cancel", 8, _hoisted_103),
                    _createElementVNode("button", {
                      class: "draw-button submit",
                      type: "submit",
                      disabled: !isEdit.value
                    }, "Save", 8, _hoisted_104)
                  ])
                ])
              ]),
              _: 1
            }, 8, ["disabled", "permissionIds", "permissionLevel"])
          ])
        ])
      ], 32)
    ])
  ], 64))
}
}

})