import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "xpage" }

import { onMounted, ref } from 'vue';
import Loader from '../Loader.vue';
import Header from '../layout/Header.vue';
import Sidebar from '../search-page/Sidebar.vue';
import store from '@/store';
import modal from '@/components/Modal.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Page',
  setup(__props) {

var loading = ref<boolean>(true);

onMounted(async () => {
	loading.value = false;
	store.state.mobileMenu = false;
});

return (_ctx: any,_cache: any) => {
  return (_unref(loading))
    ? (_openBlock(), _createBlock(Loader, { key: 0 }))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        _createVNode(Header),
        _createVNode(Sidebar),
        _createElementVNode("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _createVNode(modal)
      ], 64))
}
}

})