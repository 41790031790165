import { ref, onMounted } from 'vue';
import axios from 'axios';
import { EnrollmentTermModel } from '@/models/opal';
import { ServiceType } from '@/models/loans';

export function useDrawData(programTypeId: number) {
	const certificationMethodOptions = ref<ServiceType[]>([]);
	const drawStatusOptions = ref<ServiceType[]>([]);
	const enrollmentTermOptions = ref<EnrollmentTermModel[]>([]);
	const schoolLevelOptions = ref<ServiceType[]>([]);

	async function getCertificationMethodsAsync() {
		try {
			const response = await axios.get('/api/loantypes/CertificationMethod');
			certificationMethodOptions.value = response.data.results.map((type: ServiceType) => ({
				id: type.id,
				name: type.name
			}));
		} catch (error) {
			console.error('Error fetching certification methods:', error);
		}
	}

	async function getDrawStatusesAsync() {
		try {
			const response = await axios.get('/api/loantypes/DrawStatusType');
			drawStatusOptions.value = response.data.results.map((type: ServiceType) => ({
				id: type.id,
				name: type.name
			}));
		} catch (error) {
			console.error('Error fetching draw statuses:', error);
		}
	}

	async function getEnrollmentTermsAsync() {
		try {
			const response = await axios.get('/api/loanservice/enrollmentterms');
			enrollmentTermOptions.value = response.data;
		} catch (error) {
			console.error('Error fetching enrollment terms:', error);
		}
	}

	async function getSchoolLevelsAsync() {
		try {
			const response = await axios.get(`/api/types/SchoolLevels?programTypeId=${programTypeId}`);
			schoolLevelOptions.value = response.data.results;
		} catch (error) {
			console.error('Error fetching school level options:', error);
		}
	}

	onMounted(async () => {
		await getSchoolLevelsAsync();
		await getEnrollmentTermsAsync();
		await getCertificationMethodsAsync();
		await getDrawStatusesAsync();
	});

	return {
		certificationMethodOptions,
		drawStatusOptions,
		enrollmentTermOptions,
		schoolLevelOptions
	};
}
