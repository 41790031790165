import { computed, Ref } from 'vue';
import * as yup from 'yup';
import { Disbursement, ServiceType, ValidDisbursementStatusIds } from '@/models/loans';

export interface ValidationData {
	selectedDisbursement: Ref<Disbursement | null>;
	mode: Ref<'view' | 'edit'>;
	disbursementStatusOptions: Ref<ServiceType[]>;
	disbursementStatusReasonOptions: Ref<ServiceType[]>;
}

export function useDisbursementFormFields(data: ValidationData) {
	const formFields = computed(() => {
		const fields = [
			{
				name: 'sequenceNumber',
				label: 'Sequence',
				type: 'string',
				value: data.selectedDisbursement.value?.sequenceNumber,
				width: 'col-4',
				showOnEdit: true,
				disabledOnEdit: true
			},
			{
				name: 'disbursementStatusTypeId',
				label: 'DIsbursement Status',
				type: 'select',
				value: data.selectedDisbursement.value?.disbursementStatusTypeId,
				width: 'col-4',
				options: getFilteredDisbursementStatuses(data).value,
				showOnEdit: true,
				disabledOnEdit: false
			},
			{
				name: 'drawDisbursementStatusReasonTypeId',
				label: 'Disbursement Status Reason',
				type: 'select',
				value: data.selectedDisbursement.value?.drawDisbursementStatusReasonTypeId,
				width: 'col-4',
				options: data.disbursementStatusReasonOptions.value,
				showOnEdit: true,
				disabledOnEdit: false
			},
			{
				name: 'transactionDate',
				label: 'Disbursement Date',
				type: 'date',
				value: data.selectedDisbursement.value?.transactionDate,
				width: 'col-4',
				showOnEdit: true,
				disabledOnEdit: true
			},
			{
				name: 'certifiedAmount',
				label: 'Certified Amount',
				type: 'number',
				value: data.selectedDisbursement.value?.certifiedAmount,
				width: 'col-4',
				showOnEdit: true,
				disabledOnEdit: true
			},
			{
				name: 'disbursedAmount',
				label: 'Disbursed Amount',
				type: 'number',
				value: data.selectedDisbursement.value?.disbursedAmount,
				width: 'col-4',
				showOnEdit: true,
				disabledOnEdit: false
			},
			{
				name: 'comment',
				label: 'Comment',
				type: 'string',
				value: '',
				width: 'col-12',
				showOnEdit: true,
				disabledOnEdit: false
			}
		];

		return fields.filter((field) => {
			if (data.mode.value === 'edit') {
				return field.showOnEdit !== false;
			}

			return true;
		});
	});

	const editSchema = computed(() => {
		return yup.object({
			disbursementStatusTypeId: yup
				.number()
				.typeError('Disbursement Status is invalid')
				.required('Disbursement Status is required')
				.min(1),
			drawDisbursementStatusReasonTypeId: yup
				.number()
				.nullable()
				.typeError('Status Reason is invalid')
				.min(1)
				.when('disbursementStatusTypeId', {
					is: 4,
					then: (schema) => schema.required('Status Reason is required'),
					otherwise: (schema) => schema.notRequired()
				}),
			disbursedAmount: yup
				.number()
				.typeError('Disbursed Amount is invalid')
				.required('Disbursed Amount is required')
				.min(50, 'Must be greater than $50')
				.test('has-certified-amount', 'Certified Amount is required to enter Disbursed Amount', function () {
					return data.selectedDisbursement.value?.certifiedAmount !== undefined;
				})
				.test(
					'is-under-limit',
					`Disbursed Amount cannot exceed $${data.selectedDisbursement.value?.certifiedAmount}`,
					function (value) {
						const limit = data.selectedDisbursement.value?.certifiedAmount;
						return limit ? value <= limit : false;
					}
				),
			comment: yup
				.string()
				.nullable()
				.max(4000)
				.typeError('Status Reason is invalid')
				.when('disbursementStatusTypeId', {
					is: 4,
					then: (schema) => schema.required('Comment is required'),
					otherwise: (schema) => schema.notRequired()
				})
		});
	});

	const validationSchema = computed(() => {
		switch (data.mode.value) {
			case 'edit':
				return editSchema.value;
			default:
				return yup.object({});
		}
	});

	return {
		formFields,
		validationSchema
	};
}

function getFilteredDisbursementStatuses(data: ValidationData) {
	return computed(() => {
		if (data.mode.value === 'view') {
			return data.disbursementStatusOptions.value;
		}

		const filteredOptions = data.disbursementStatusOptions.value.filter((status) =>
			ValidDisbursementStatusIds.includes(status.id)
		);

		if (data.mode.value === 'edit' && data.selectedDisbursement.value?.disbursementStatusTypeId !== null) {
			const selectedOption = data.disbursementStatusOptions.value.find(
				(status) => status.id === data.selectedDisbursement.value?.disbursementStatusTypeId
			);

			if (selectedOption && !filteredOptions.includes(selectedOption)) {
				filteredOptions.push(selectedOption);
			}
		}

		return filteredOptions;
	});
}
