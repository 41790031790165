import { computed, Ref } from 'vue';
import * as yup from 'yup';
import { Borrower, ServiceType, UpdateDocumentRequest } from '@/models/loans';

export interface ValidationData {
	document: Ref<UpdateDocumentRequest>;
	borrowers: Borrower[];
	documentTypeOptions: Ref<ServiceType[]>;
}

export function useFormFields(data: ValidationData) {
	const borrowerOptions: ServiceType[] = data.borrowers.map((borrower) => ({
		id: borrower.id,
		name: borrower.fullName
	}));

	const formFields = computed(() => {
		return [
			{
				name: 'documentTypeId',
				label: 'Document Type',
				type: 'select',
				value: data.document.value.documentTypeId,
				width: 'col-12',
				options: data.documentTypeOptions.value
			},
			{
				name: 'borrowerId',
				label: 'Borrower',
				type: 'select',
				value: data.document.value.borrowerId,
				width: 'col-12',
				options: borrowerOptions
			}
		];
	});

	const validationSchema = computed(() => {
		return yup.object({
			documentTypeId: yup
				.number()
				.typeError('Document Type is invalid')
				.required('Document Type is required')
				.min(1),
			borrowerId: yup
				.number()
				.typeError('Borrower is invalid')
				.required('Borrower is required')
				.min(1),
		});
	});

	return {
		formFields,
		validationSchema
	};
}
