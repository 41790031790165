import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, vShow as _vShow, mergeProps as _mergeProps, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

import { computed, toRefs, ref } from "vue";
  import { usePermissionsAccess } from "@/composables/usePermissionsAccess";
  import { Permissions, PermissionLevels } from "@/models/permissions";

  
export default /*@__PURE__*/_defineComponent({
  __name: 'PermissionsButton',
  props: {
    permissionIds: {
      type: Array as () => Permissions[],
      default: () => [],
    },
    permissionLevel: {
      type: Number as () => PermissionLevels,
      default: PermissionLevels.None,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showDisabledIfNotVisible: {
      // shows the button if not visible but button is disabled
      type: Boolean,
      default: false,
    },
    class: {
      type: [String, Object],
      default: "",
    },
  },
  emits: ["click"],
  setup(__props, { emit: __emit }) {

  const props = __props;

  const {
    permissionIds,
    permissionLevel,
    disabled,
    showDisabledIfNotVisible,
    class: buttonClass,
  } = toRefs(props);

  const emit = __emit;

  const handleClick = (event) => {
    emit("click", event);
  };

  const permissionsAccess = usePermissionsAccess();

  const isVisible = computed(() => {
    const permissionIdsValue = permissionIds?.value || [];
    return (
      permissionIdsValue.length === 0 ||
      permissionsAccess.hasPermission(permissionIdsValue, permissionLevel.value)
    );
  });

  const isDisabled = computed(() => {
    return (
      disabled.value || (!isVisible.value && showDisabledIfNotVisible.value)
    );
  });

return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("button", _mergeProps({
    class: _unref(buttonClass),
    disabled: isDisabled.value,
    onClick: handleClick
  }, _ctx.$attrs), [
    _renderSlot(_ctx.$slots, "default")
  ], 16, _hoisted_1)), [
    [_vShow, isVisible.value || _unref(showDisabledIfNotVisible)]
  ])
}
}

})