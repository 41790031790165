import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["value"]
const _hoisted_3 = { key: 1 }
const _hoisted_4 = ["for"]
const _hoisted_5 = ["id", "name", "onUpdate:modelValue", "disabled"]

import { ref, reactive, watchEffect, computed } from 'vue';
	import { Form, Field, ErrorMessage } from 'vee-validate';
	import { Schema } from 'yup';
	import { Borrower } from '@/models/loans';

	interface FormField {
		name: string;
		label: string;
		type: string;
		value: any;
		width: string;
	}

	
export default /*@__PURE__*/_defineComponent({
  __name: 'UploadDocumentForm',
  props: {
    isUploading: { type: Boolean },
    borrowers: {},
    fields: {},
    validationSchema: {}
  },
  setup(__props: any, { expose: __expose }) {

	const props = __props;

	const formRef = ref();

	const formData = reactive<Record<string, any>>({});

	const isFormValid = computed(() => {
		formRef.value?.validate();
		return Object.keys(formRef.value?.errors).length === 0;
	});

	watchEffect(() => {
		props.fields.forEach((field) => {
			formData[field.name] = field.value;
		});
	});

	__expose({
		isFormValid,
		formData
	});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Form), {
    ref_key: "formRef",
    ref: formRef,
    "validation-schema": _ctx.validationSchema,
    class: "form-container"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (field) => {
        return (_openBlock(), _createElementBlock("div", {
          key: field.name,
          class: _normalizeClass(['form-input', field.width])
        }, [
          _createElementVNode("label", {
            for: field.name
          }, _toDisplayString(field.label), 9, _hoisted_1),
          (field.type === 'select')
            ? (_openBlock(), _createBlock(_unref(Field), {
                key: 0,
                as: "select",
                id: field.name,
                name: field.name,
                modelValue: formData[field.name],
                "onUpdate:modelValue": ($event: any) => ((formData[field.name]) = $event),
                disabled: _ctx.isUploading
              }, {
                default: _withCtx(() => [
                  _cache[0] || (_cache[0] = _createElementVNode("option", null, null, -1)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(field.options, (option) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: option.id,
                      value: option.id
                    }, _toDisplayString(option.name), 9, _hoisted_2))
                  }), 128))
                ]),
                _: 2
              }, 1032, ["id", "name", "modelValue", "onUpdate:modelValue", "disabled"]))
            : (field.type === 'checkbox')
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("label", {
                    for: field.name
                  }, [
                    _withDirectives(_createElementVNode("input", {
                      type: "checkbox",
                      id: field.name,
                      name: field.name,
                      "onUpdate:modelValue": ($event: any) => ((formData[field.name]) = $event),
                      disabled: _ctx.isUploading
                    }, null, 8, _hoisted_5), [
                      [_vModelCheckbox, formData[field.name]]
                    ]),
                    _cache[1] || (_cache[1] = _createElementVNode("span", { class: "custom-checkbox" }, null, -1))
                  ], 8, _hoisted_4)
                ]))
              : _createCommentVNode("", true),
          _createVNode(_unref(ErrorMessage), {
            name: field.name,
            class: "error"
          }, null, 8, ["name"])
        ], 2))
      }), 128))
    ]),
    _: 1
  }, 8, ["validation-schema"]))
}
}

})