import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "page-content" }
const _hoisted_2 = { class: "credit-decision-container" }
const _hoisted_3 = { class: "text-selection" }
const _hoisted_4 = { class: "main-content" }
const _hoisted_5 = { class: "panel-wrapper" }
const _hoisted_6 = {
  key: 0,
  class: "credit-panel"
}
const _hoisted_7 = { class: "side-panel" }
const _hoisted_8 = { class: "view-another-pull-container" }
const _hoisted_9 = ["disabled"]

import { computed, ref, watch } from "vue";
import CreditReissueSLR from "@/components/v2/credit/refi-loan/CreditReissueSLR.vue";
import { useCreditStore } from "@/store/store/creditStore";
import CreditDecisionsPanelSLR from "@/components/v2/credit/refi-loan/CreditDecisionsPanelSLR.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'CreditResultsSLR',
  setup(__props) {

const creditStore = useCreditStore();
const step = ref(1);
const panelCount = ref(1);
const selectedReason = ref("");

const showPreviousPullPanel = ref(true);


const statusConfig = computed(() => {
  const decision = creditStore.creditTab.overallApplicationCreditDecision;
const matchingConfig = Object.values(creditStore.decisionMapping).find(
    (config) => config.label === decision
  );
  return matchingConfig ?? {
    label: "Unknown",
    color: "#000",
    textColor: "#fff",
  }
});

watch(selectedReason, (newValue) => {
  if (newValue) {
    step.value = 4;
  }
});

function addCreditDecisionPanel() {
  if (panelCount.value < 2) {
    panelCount.value++;
    showPreviousPullPanel.value = true;
  }
}

function handleClosePreviousPull() {
  panelCount.value = 1;
  showPreviousPullPanel.value = false;
}

function removePanel() {
  if (!showPreviousPullPanel.value) {
    panelCount.value = 1;
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("h2", { class: "title" }, "Overall Application Credit Decision:", -1)),
        _createElementVNode("span", {
          class: "status-pill",
          style: _normalizeStyle({
            backgroundColor: statusConfig.value.color,
            color: statusConfig.value.textColor,
          })
        }, _toDisplayString(_unref(creditStore).creditTab.overallApplicationCreditDecision), 5)
      ]),
      _cache[1] || (_cache[1] = _createElementVNode("p", { class: "description" }, [
        _createTextVNode(" The Overall Application Credit Decision is based on a variety of factors, including both the primary "),
        _createElementVNode("br"),
        _createTextVNode(" and co-applicants’ credit scores, as well as the knockout decisions. Income and DTI, if applicable are "),
        _createElementVNode("br"),
        _createTextVNode(" evaluated separately. ")
      ], -1))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(CreditDecisionsPanelSLR, { isPreviousPull: false }),
        _createVNode(_Transition, {
          name: "fade-side",
          onAfterLeave: removePanel
        }, {
          default: _withCtx(() => [
            (panelCount.value === 2 && showPreviousPullPanel.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(CreditDecisionsPanelSLR, {
                    isPreviousPull: true,
                    onClosePreviousPull: handleClosePreviousPull
                  })
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(CreditReissueSLR)
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_Transition, { name: "fade-side" }, {
        default: _withCtx(() => [
          (panelCount.value < 2 || !showPreviousPullPanel.value)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "view-another-pull-btn",
                onClick: addCreditDecisionPanel,
                disabled: !_unref(creditStore).HasManyCreditHistories
              }, " View Another Pull ", 8, _hoisted_9))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ])
  ]))
}
}

})