import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "xpage-search-filter" }
const _hoisted_2 = { class: "xpage-search-filter-scroll" }
const _hoisted_3 = { class: "section-description" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["onUpdate:modelValue", "placeholder"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "xradio" }
const _hoisted_8 = ["name", "checked", "onInput"]
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "xcheck" }
const _hoisted_11 = ["checked", "onInput"]
const _hoisted_12 = {
  key: 0,
  style: {"padding-left":"20px"}
}
const _hoisted_13 = ["checked", "onInput"]
const _hoisted_14 = ["onClick"]
const _hoisted_15 = {
  key: 2,
  class: "xmodal-wrapper"
}
const _hoisted_16 = { class: "xmodal-inner" }
const _hoisted_17 = { class: "xmodal" }
const _hoisted_18 = ["onUpdate:modelValue", "placeholder"]
const _hoisted_19 = { style: {"text-align":"left","max-height":"300px","overflow-y":"auto"} }
const _hoisted_20 = { class: "xcheck" }
const _hoisted_21 = ["checked", "onInput"]
const _hoisted_22 = { class: "xmodal-buttons" }
const _hoisted_23 = { key: 0 }
const _hoisted_24 = { key: 0 }

import { ref, PropType, watch } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'SearchFilter',
  props: {
    applications: {
        type: Object as PropType<any>,
        required: true
    },
    triggerSearch: {
        type: Function,
        required: true
    },
    pageNumber: {
        type: Number,
        required: false
    },
    pageTotal: {
        type: Number,
        required: true
    },
    maxFilterResults: {
        type: Number,
        required: false,
        default: 5
    },
    sectionResultsExceedMaxVisible: {
        type: Function,
        required: false,
        default: (section: any) => {
            return section.results.length > 5;
        }
    },
    filteredSectionResults: {
        type: Function,
        required: false,
        default: (section: any) => {
            return section.results;
        }
    },
    selectFilter: {
        type: Function,
        required: false,
        default: (event: any, item: any, parent: any) => {
            if (parent) {
                parent.checked = parent.children.every((child: any) => child.checked);
            }
        }
    }
},
  setup(__props) {

const props = __props;

let localApplications = ref(JSON.parse(JSON.stringify(props.applications)));

watch(() => props.applications.query, (newQuery) => {
    localApplications.value.query.totalCount = newQuery.totalCount;
}, { deep: true });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(["xpage-search-filters", { visible: _unref(localApplications).filterDisplay }]),
      onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_unref(localApplications).filterDisplay = false), ["self"]))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "xpage-search-filters-close",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(localApplications).filterDisplay = false))
        }, " X "),
        _cache[9] || (_cache[9] = _createElementVNode("h3", null, "Filters", -1)),
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.applications.filters, (section, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "xpage-search-filter-section",
              key: index
            }, [
              _createElementVNode("h3", _hoisted_3, _toDisplayString(section.description), 1),
              (__props.sectionResultsExceedMaxVisible(section))
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      "onUpdate:modelValue": ($event: any) => ((section.search) = $event),
                      placeholder: `Filter ${section.description}...`
                    }, null, 8, _hoisted_5), [
                      [
                        _vModelText,
                        section.search,
                        void 0,
                        { trim: true }
                      ]
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.filteredSectionResults(
                    section
                  ).slice(0, __props.maxFilterResults), (item, itemIndex) => {
                return (_openBlock(), _createElementBlock("div", { key: itemIndex }, [
                  (section.type == 'radio')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createElementVNode("label", _hoisted_7, [
                          _createTextVNode(_toDisplayString(item.name) + " ", 1),
                          _createElementVNode("input", {
                            type: "radio",
                            name: 'radio' + section.param,
                            checked: item.checked,
                            onInput: ($event: any) => (__props.selectFilter($event, item, section))
                          }, null, 40, _hoisted_8),
                          _cache[5] || (_cache[5] = _createElementVNode("span", null, null, -1))
                        ])
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createElementVNode("label", _hoisted_10, [
                          _createTextVNode(_toDisplayString(item.name) + " ", 1),
                          _createElementVNode("input", {
                            type: "checkbox",
                            checked: item.checked,
                            onInput: ($event: any) => (__props.selectFilter($event, item))
                          }, null, 40, _hoisted_11),
                          _cache[6] || (_cache[6] = _createElementVNode("span", null, null, -1))
                        ]),
                        (item.children)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (child, childIndex) => {
                                return (_openBlock(), _createElementBlock("label", {
                                  key: childIndex,
                                  class: "xcheck"
                                }, [
                                  _createTextVNode(_toDisplayString(child.name) + " ", 1),
                                  _createElementVNode("input", {
                                    type: "checkbox",
                                    checked: child.checked,
                                    onInput: ($event: any) => (__props.selectFilter($event, child, item))
                                  }, null, 40, _hoisted_13),
                                  _cache[7] || (_cache[7] = _createElementVNode("span", null, null, -1))
                                ]))
                              }), 128))
                            ]))
                          : _createCommentVNode("", true)
                      ]))
                ]))
              }), 128)),
              (__props.sectionResultsExceedMaxVisible(section))
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "xseeall",
                    onClick: ($event: any) => (_unref(localApplications).modal = index)
                  }, " See all ", 8, _hoisted_14))
                : _createCommentVNode("", true),
              (_unref(localApplications).modal === index)
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("div", _hoisted_17, [
                        _createElementVNode("div", {
                          class: "xmodal-close",
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(localApplications).modal = false))
                        }, " X "),
                        _createElementVNode("h2", null, _toDisplayString(section.description), 1),
                        _withDirectives(_createElementVNode("input", {
                          type: "text",
                          "onUpdate:modelValue": ($event: any) => ((section.search) = $event),
                          placeholder: `Filter ${section.description}...`
                        }, null, 8, _hoisted_18), [
                          [
                            _vModelText,
                            section.search,
                            void 0,
                            { trim: true }
                          ]
                        ]),
                        _createElementVNode("div", _hoisted_19, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.filteredSectionResults(
                            section
                          ), (item, itemIndex) => {
                            return (_openBlock(), _createElementBlock("div", { key: itemIndex }, [
                              _createElementVNode("label", _hoisted_20, [
                                _createTextVNode(_toDisplayString(item.name) + " ", 1),
                                _createElementVNode("input", {
                                  type: "checkbox",
                                  checked: item.checked,
                                  onInput: ($event: any) => (__props.selectFilter($event, item))
                                }, null, 40, _hoisted_21),
                                _cache[8] || (_cache[8] = _createElementVNode("span", null, null, -1))
                              ])
                            ]))
                          }), 128))
                        ]),
                        _createElementVNode("div", _hoisted_22, [
                          _createElementVNode("button", {
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(localApplications).modal = false))
                          }, [
                            _createTextVNode(" Show " + _toDisplayString(_unref(localApplications).query.totalCount) + " Result", 1),
                            (_unref(localApplications).query.totalCount != 1)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_23, "s"))
                              : _createCommentVNode("", true)
                          ])
                        ])
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ])
      ]),
      _createElementVNode("div", {
        class: "xpage-search-filter-confirm",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(localApplications).filterDisplay = false))
      }, [
        _createTextVNode(" Show " + _toDisplayString(__props.applications.query.totalCount) + " Result", 1),
        (__props.applications.query.totalCount != 1)
          ? (_openBlock(), _createElementBlock("span", _hoisted_24, "s"))
          : _createCommentVNode("", true)
      ])
    ], 2)
  ]))
}
}

})