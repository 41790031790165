import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "income-verification-wrapper"
}
const _hoisted_2 = ["value"]
const _hoisted_3 = ["id"]
const _hoisted_4 = { class: "table-wrapper has-mobile-cards" }
const _hoisted_5 = { class: "table is-fullwidth is-hoverable is-fullwidth" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["id"]

import {
  onMounted,
  reactive,
  computed,
  watch,
} from "vue";
import { useStore } from "vuex";
import useValidation from "@/use/validation";
import { FormSchema, FieldSchema, Option } from "@/models/form";
import { $api } from "@/services/api1";
import functions from "@/use/functions";
import { Income } from "@/models/incomes";
import { Applicant, IncomeDocument, LOSApplication } from "@/models/opal";
import IncomeDocumentHeader from "@/components/income/IncomeDocumentHeader.vue";
import IncomeRow from "@/components/income/IncomeRow.vue";

import IncomeTotals from "@/components/income/IncomeTotals.vue";
import { Permissions, PermissionLevels } from "@/models/permissions";
import PermissionsWrapper from "@/components/wrappers/PermissionsWrapper.vue";
import { useIncomeTabStore } from "@/store/store/incomeTabStore";
import { useDocumentsTabStore } from "@/store/store/documentsTabStore";

interface Props {
  store: any;
  applicant: Applicant | boolean | object;
  applicants: object;
  application: LOSApplication;
  docs?: Array<any> | undefined;
  canEdit: boolean;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'IncomeTable',
  props: {
    store: {},
    applicant: { type: [Object, Boolean] },
    applicants: {},
    application: {},
    docs: {},
    canEdit: { type: Boolean }
  },
  emits: [
  "getDocs",
  "recalculate",
  "getApplication",
  "addBtnClicked",
  "getApplicantIncomes",
  "getComments",
  "applicantIncomeUpdated",
],
  setup(__props: any, { emit: __emit }) {

const incomeTabStore = useIncomeTabStore();
const documentsTabStore = useDocumentsTabStore();
const store = useStore();
const emit = __emit;

const props = __props;
const { validateForm } = useValidation();

// Constants
const headers = [
  { name: "Employer Name", required: false },
  { name: "Income Type", required: true },
  { name: "Amount", required: true },
  { name: "Frequency", required: true },
  { name: "Income Year", required: true },
  { name: "Total Annual Income", readOnly: true },
];

// Dynamic Variables
let incomeDocuments = reactive([] as any[]);
let originalIncomes = reactive({} as any);

// computed
const incomes = computed(() => (props?.applicant as Applicant).incomes || []);
// HELPER FXNS

function cancelButtonClicked(income: any, incomeDocument: any) {
  replaceObjectWithId(incomeDocument?.incomes, income.id, income);

  incomeTabStore.updateIncomeDocument(
    incomeDocument.applicantDocumentId,
    incomeDocument
  );

  incomeTabStore.cancel(income);
}

function discardChangesClicked(income: any, incomeDocument: any) {
  const originalIncome = originalIncomes[income.id];
  replaceObjectWithId(incomeDocument?.incomes, income.id, originalIncome);

  incomeTabStore.updateIncomeDocument(
    incomeDocument.applicantDocumentId,
    incomeDocument
  );

  removeObjectKeyValuePair(income.id || income.incomeId);
  incomeTabStore.untoggleEdit(income);
}

function confirmIncomeUpdate(
  applicantId: number,
  income: any,
  incomeDocument: any
) {
  income.incomeAmount =
    typeof income.incomeAmount === "string"
      ? parseInt(income.incomeAmount)
      : income.incomeAmount;

  const modalHtml = functions.checkIncomeFormChanges(
    income,
    originalIncomes[income.id]
  );
  if (!modalHtml) {
    functions.closeModal();
  } else {
    functions.openModal({
      title: "Confirm",
      description: "Please review your changes before submitting...",
      html: modalHtml,
      buttons: [
        {
          title: "Cancel",
          onClick: () => {
            cancelButtonClicked(income, incomeDocument);
            functions.closeModal();
          },
        },
        {
          title: "Discard Changes",
          onClick: async () => {
            discardChangesClicked(income, incomeDocument);
            functions.closeModal();
          },
        },
        {
          title: "Save Changes",
          onClick: async () => {
            incomeTabStore.untoggleEdit(income);
            await updateIncome(applicantId, income, incomeDocument);
          },
        },
      ],
    });
  }
}
function removeObjectWithId(arr: Array<any>, id: number) {
  const objWithIdIndex = arr.findIndex((obj) => obj.id === id);

  if (objWithIdIndex > -1) {
    arr.splice(objWithIdIndex, 1);
  }

  return arr;
}
function replaceObjectWithId(arr: Array<any>, id: number, newObj: object) {
  const objWithIdIndex = arr.findIndex((obj) => obj.id === id);

  if (objWithIdIndex > -1) {
    arr[objWithIdIndex] = Object.assign({}, newObj);
  }

  return arr;
}

function confirmDelete(incomeDocument: any, income: any) {
  if (income.id === 0) {
    incomeDocument?.incomes.pop();
  } else {
    functions.openModal({
      title: "Confirm",
      description: "Are you sure you want to delete this income?",
      buttons: [
        { title: "No", onClick: () => functions.closeModal() },
        { title: "Yes", onClick: () => deleteIncome(incomeDocument, income) },
      ],
    });
  }
}
function getIncomeTotal(income: any) {
  const incomeTypes = {
    Annual: 1,
    Monthly: 12,
    "Bi-Weekly": 26,
    Weekly: 52,
    Bonus: 1,
    "Semi-Monthly": 24,
    Quarterly: 4,
  };
  // calculate the total income based on the income type provided
  income.incomeTotal =
    income.incomeAmount * incomeTypes[income.incomePeriodTypeName];

  return income.incomeTotal;
}
function dtoToRequest(income: any) {
  income["incomeId"] = income["id"];
  delete income["id"];

  income["verified"] = income["isVerified"];
  delete income["isVerified"];

  income["employerName"] = income["applicantEmploymentName"];
  delete income["applicantEmploymentName"];
}
function responseToDto(income: Income) {
  income["id"] = income["incomeId"];
  delete income["incomeId"];

  income["isVerified"] = income["verified"];
  delete income["verified"];

  income["applicantEmploymentName"] = income["employerName"];
  delete income["employerName"];
}
function handleDocumentStatusChange(event: any, incomeDocument: any) {
  incomeDocument.documentStatusReasonId = event.documentStatusReasonId;
  incomeDocument.documentStatusReasonName = event.documentStatusReasonName;
  incomeDocument.documentStatusReasonTypeName = event.documentStatusReasonId;
  incomeDocument.documentStatusTypeId = event.documentStatusTypeId;
  incomeDocument.documentStatusTypeName = event.documentStatusTypeName;

  incomeTabStore.updateIncomeDocument(
    incomeDocument.applicantDocumentId,
    incomeDocument
  );
}
function handleDocumentYearChange(event: any, incomeDocument: any) {
  incomeDocument.documentYear = event.documentYear;
  incomeDocument.year = event.year;

  incomeTabStore.updateIncomeDocument(
    incomeDocument.applicantDocumentId,
    incomeDocument
  );
}

// Core Functions (CRUD)
async function addIncome(incomeDocument: any, income: any) {
  const applicantDocumentId = incomeDocument.applicantDocumentId;
  // Build request
  let request = {
    id: 0,
    applicantId: (props?.applicant as Applicant).id,
    applicantDocumentId: income.applicantDocumentId,
    incomeYearTypeId: income.incomeYearTypeId,
    incomeTypeId: income.incomeTypeId,
    incomeAmount: income.incomeAmount,
    incomePeriodTypeId: income.incomePeriodTypeId,
    incomeDate: null,
    verified: true,
    incomeStatusTypeId: 1,
    applicantEmploymentId: income.applicantEmploymentId,
    employerName: income.applicantEmploymentName,
    applicantEmploymentTypeId: income.applicantEmploymentTypeId,
  };
  const response = await $api.applicants.addIncome(request);

  if (response.errors || !response.id) {
    functions.openModal({
      title: "Error",
      description: "There was an issue adding new income",
    });

    console.log(`Error: addIncome()`, response.errors);
  } else {
    // use incomeDate to assign a year
    if (response.incomeDate) {
      const dt = new Date(response.incomeDate);
      income.year = dt.getFullYear();
    }

    const addedIncome = {
      ...response,
      isEditing: false,
      year: response.incomeDate
        ? new Date(response.incomeDate).getFullYear()
        : null,
      incomeTotal: getIncomeTotal(response),
    };

    replaceObjectWithId(incomeDocument.incomes, 0, addedIncome);
    incomeTabStore.updateIncomeDocument(
      incomeDocument.applicantDocumentId,
      incomeDocument
    );
    emit("getApplicantIncomes", true);
    emit("applicantIncomeUpdated", true);
    return true;
  }
}
async function updateIncome(
  applicantId: number,
  income: any,
  incomeDocument: any
) {
  // for mapping purposes since loscoreservice not mapping the dto <--> request
  dtoToRequest(income);

  const response = await $api.applicants.updateIncome(
    applicantId,
    income.incomeId,
    income
  );

  responseToDto(income);

  if (response.errors) {
    functions.openModal({
      title: "Error",
      description: "There was an issue saving the Income",
    });

    console.log(`Error: updateIncome()`, response.errors);
  }
  if (response.id) {
    const updatedIncome = {
      ...response,
      isEditing: false,
      year: response.incomeDate
        ? new Date(response.incomeDate).getFullYear()
        : null,
      incomeTotal: getIncomeTotal(response),
    };
    removeObjectKeyValuePair(response.id);
    replaceObjectWithId(incomeDocument.incomes, response.id, updatedIncome);
    incomeTabStore.updateIncomeDocument(
      incomeDocument.applicantDocumentId,
      incomeDocument
    );
    emit("getApplicantIncomes", true);
    emit("applicantIncomeUpdated", true);
    functions.closeModal();
  }
}
async function deleteIncome(incomeDocument: any, income: any) {
  functions.closeModal();

  const response = await $api.applicants.deleteIncome(
    (props?.applicant as Applicant).id,
    income.id || income.incomeId
  );
  if (response.errors) {
    functions.openModal({
      title: "Error",
      description: "There was an issue deleting the Income",
    });

    console.log(`Error: deleteIncome()`, response.errors);
  } else {
    removeObjectWithId(incomeDocument?.incomes, income.id || income.incomeId);
    incomeTabStore.updateIncomeDocument(
      incomeDocument.applicantDocumentId,
      incomeDocument
    );
    emit("getApplicantIncomes", true);
    emit("applicantIncomeUpdated", true);
  }
}
async function getIncomeDocuments() {
  incomeDocuments = [];

  const uploadedDocuments = documentsTabStore.documents?.filter(
    (d: any) =>
      d.documentStatusTypeName.trim() !== "Generated" &&
      d.applicantId === incomeTabStore.selectedApplicant?.id
  );
  for (let doc of uploadedDocuments) {
    let document = {
      ...doc,
      documentName: doc?.uploadedDocumentMetadata?.originalFileName
        ? doc.uploadedDocumentMetadata.originalFileName
        : "Unknown",
      year: doc.documentYear ? new Date(doc.documentYear).getFullYear() : null,
      formDisabled: true,
      editStatus: false,
      editYear: false,
      editType: false,
      showDocument: false,
      showStatusReasonSection: false,
      showStatusReasonCommentArea: false,
      incomeTabVisible: doc.documentCategories.find(
        (dc: any) => dc.categoryName === "Income"
      )
        ? true
        : false,
      comment: "",
      incomes: [],
    } as IncomeDocument;

    if (document.incomeTabVisible) {
      const index = incomeDocuments.findIndex(
        (item) => item.applicantDocumentId === document.applicantDocumentId
      );

      if (index !== -1) {
        incomeDocuments[index] = document;
      } else {
        incomeDocuments.push(document);
      }
    }
  }
  // Link incomes to each income doc if income docs exist
  if (incomeDocuments?.length > 0) {
    if (incomes.value && incomes.value?.length > 0) {
      for (let incomeDocument of incomeDocuments) {
        for (let income of incomes.value) {
          if (
            income.applicantDocumentId === incomeDocument.applicantDocumentId
          ) {
            incomeDocument.incomes.push(income);
          }
        }
      }
    }
  }

  // update pinia stores
  incomeTabStore.incomeDocuments = incomeDocuments;
}
async function saveChanges(event: any) {
  const incomeId = event.income.id || event.income.incomeId;
  const valid = validateForm(event.schema as FormSchema, event.income);
  if (incomeId) {
    if (valid) {
      incomeTabStore.untoggleEdit(event.income);
      confirmIncomeUpdate(
        (props?.applicant as Applicant).id,
        event.income,
        event.incomeDocument
      );
    }
  } else {
    if (valid) {
      incomeTabStore.untoggleEdit(event.income);
      await addIncome(event.incomeDocument, event.income);
    } else {
      return;
    }
  }
}

/** TABLE METHODS **/
function addRow(incomeDocument: any) {
  /////////////////////////////////////////////////////
  // description: adds a new income row to the table //
  /////////////////////////////////////////////////////
  const newIncome = {
    id: 0,
    incomeTotal: 0,
    applicantDocumentId: incomeDocument.applicantDocumentId,
    applicantEmploymentId: null,
    applicantEmploymentTypeId: null,
    applicantEmploymentName: null,
    applicantId: (props?.applicant as Applicant).id,
    employmentEndDate: null,
    employmentStartDate: null,
    incomeAmount: undefined,
    incomeDate: null,
    incomePeriodTypeId: undefined,
    incomeStatusTypeId: undefined,
    incomeTypeId: undefined,
    incomeYearTypeId: undefined,
    isVerified: true,
  };

  if (incomeDocument.documentStatusTypeName === "Accepted") {
    incomeDocument?.incomes.push(newIncome);
    toggleEdit(
      incomeDocument,
      incomeDocument?.incomes[incomeDocument?.incomes.length - 1]
    );
  } else {
    functions.openModal({
      title: "Error",
      description: `Please change document status to "Accepted" prior to adding new incomes.`,
    });
  }
}

function toggleEdit(incomeDocument: any, income: any, originalIncome?: Income) {
  incomeTabStore.toggleEdit(incomeDocument, income);
  const incomeId = income.id || income.incomeId;
  const original =
    originalIncome ||
    incomeDocument?.incomes?.find((i: Income) => i.id === incomeId);

  originalIncomes[incomeId] = Object.assign({}, original);
}

function toggleDetails(incomeDocument: any) {
  incomeTabStore.toggleDetails(incomeDocument);
}

function removeObjectKeyValuePair(incomeId: number) {
  const keys = Object.keys(originalIncomes);

  if (keys.includes(`${incomeId}`)) {
    delete originalIncomes[`${incomeId}`];
  }
}

onMounted(() => {
  getIncomeDocuments();
});

// get latest income docs when toggling between applicants or documents changed
watch(
  () => documentsTabStore.documents,
  () => {
    incomeTabStore.getIncomeDocumentsByApplicant();
  },
  { deep: true, immediate: true }
);

watch(
  () => incomeTabStore.selectedApplicant,
  () => {
    incomeTabStore.getIncomeDocumentsByApplicant();
  }
);

return (_ctx: any,_cache: any) => {
  return (_unref(incomeTabStore).incomeDocuments.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[2] || (_cache[2] = _createElementVNode("h3", null, "Income Verification", -1)),
        _createVNode(IncomeTotals, { applicant: _ctx.applicant }, null, 8, ["applicant"]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(incomeTabStore).incomeDocuments, (incomeDocument) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "income-document",
            key: incomeDocument.applicantDocumentId,
            value: incomeDocument
          }, [
            _createVNode(IncomeDocumentHeader, {
              incomeDocument: incomeDocument,
              applicantDocumentId: incomeDocument.applicantDocumentId,
              incomeDocuments: _unref(incomeDocuments),
              application: _ctx.application,
              onGetComments: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('getComments'))),
              onToggleDetails: ($event: any) => (toggleDetails(incomeDocument)),
              onDocumentStatusChanged: ($event: any) => (
          handleDocumentStatusChange($event, incomeDocument)
        ),
              onDocumentYearChanged: ($event: any) => (handleDocumentYearChange($event, incomeDocument)),
              canEdit: _ctx.canEdit
            }, null, 8, ["incomeDocument", "applicantDocumentId", "incomeDocuments", "application", "onToggleDetails", "onDocumentStatusChanged", "onDocumentYearChanged", "canEdit"]),
            _withDirectives(_createElementVNode("div", {
              class: "b-table",
              style: {"margin-bottom":"15px"},
              id: `${incomeDocument.documentId}_details`
            }, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("table", _hoisted_5, [
                  _createElementVNode("thead", null, [
                    _createElementVNode("tr", null, [
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(headers, (header, index) => {
                        return _createElementVNode("th", {
                          class: "table-header",
                          key: index
                        }, [
                          _createElementVNode("label", null, [
                            _createTextVNode(_toDisplayString(header.name) + " ", 1),
                            (header.required)
                              ? (_openBlock(), _createElementBlock("i", _hoisted_6, "*"))
                              : _createCommentVNode("", true)
                          ])
                        ])
                      }), 64)),
                      _createElementVNode("th", null, [
                        _createVNode(PermissionsWrapper, {
                          disabled: !_ctx.canEdit,
                          showDisabledIfNotVisible: true,
                          permissionIds: [_unref(Permissions).Income],
                          permissionLevel: _unref(PermissionLevels).Update
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", {
                              onClick: ($event: any) => (addRow(incomeDocument)),
                              class: "xblackpill-thick"
                            }, " Add  ", 8, _hoisted_7)
                          ]),
                          _: 2
                        }, 1032, ["disabled", "permissionIds", "permissionLevel"])
                      ])
                    ])
                  ]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(incomeDocument.incomes, (income) => {
                    return (_openBlock(), _createElementBlock("tbody", {
                      key: income?.incomeId || income?.id,
                      id: `${income?.incomeId}` || `${income?.id}`
                    }, [
                      _createVNode(IncomeRow, {
                        canEdit: _ctx.canEdit,
                        incomeRecord: income,
                        "income-document": incomeDocument,
                        onToggleEdit: ($event: any) => (
                  toggleEdit(
                    $event.incomeDocument,
                    $event.income,
                    $event.originalIncome
                  )
                ),
                        onSaveChanges: _cache[1] || (_cache[1] = ($event: any) => (saveChanges($event))),
                        onConfirmDelete: ($event: any) => (
                  confirmDelete($event.incomeDocument, $event.income)
                )
                      }, null, 8, ["canEdit", "incomeRecord", "income-document", "onToggleEdit", "onConfirmDelete"])
                    ], 8, _hoisted_8))
                  }), 128))
                ])
              ])
            ], 8, _hoisted_3), [
              [_vShow, _unref(incomeTabStore).isIncomeDetailShowing(incomeDocument)]
            ])
          ], 8, _hoisted_2))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}
}

})