import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "xpage-content-page",
  id: "xevaluations-tab"
}
const _hoisted_2 = { class: "xtitlearea" }
const _hoisted_3 = {
  key: 0,
  class: "xpage-content"
}

import { ref, watch, onMounted } from "vue";
import ApplicationComments from "@/components/ApplicationComments.vue";
import { ApplicationDetail } from "@/types/application-details";
import axios from "axios";
import EvaluationDetails from "./EvaluationsDetails.vue";
import EvaluationsAnalystReview from "./EvaluationsAnalystReview.vue";
import EvaluationsCreditUnionDecision from "./EvaluationsCreditUnionDecision.vue";
import { computed } from "vue";
import { Permissions, PermissionLevels } from "@/models/permissions";
import PermissionsWrapper from "@/components/wrappers/PermissionsWrapper.vue";

interface Props {
  isTabActive: boolean;
  applicants: any;
  application: any;
  commentsId: any;
  store?: any;
  disabled?: boolean;
  canEdit: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'EvaluationsTab',
  props: {
    isTabActive: { type: Boolean },
    applicants: {},
    application: {},
    commentsId: {},
    store: {},
    disabled: { type: Boolean },
    canEdit: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;
const commentData = computed(() => props.application.commentData);
const fullService = ref(false);

const isReadOnlyLocal = computed(
  () => props.application.data.applicationStatusId !== 2
);

const showEvaluationsTab = computed(() => ({
  show: true,
  isReadOnlyLocal: isReadOnlyLocal.value,
}));

const forceRerender = (component: any) => {
  component.value += 1;
};

const applicationDetails = ref<ApplicationDetail>({});

const isFullService = computed(
  () => applicationDetails.value.isFullService || false
);
// console.log("isFullService:", isFullService.value);

async function applicationsDetails() {
  const applicationId = props.application.data.id;
  const url = `/api/applications/${applicationId}/details`;
  try {
    const response = await axios.get(url);
    fullService.value = response.data.isFullService;
    applicationDetails.value = response.data;
    // console.log("applicationDetails:", applicationDetails.value);
  } catch (error) {
    console.log("Error saving details:", error);
  }
}

onMounted(async () => {
  await applicationsDetails();
});

return (_ctx: any,_cache: any) => {
  return (_ctx.isTabActive)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[1] || (_cache[1] = _createElementVNode("h2", null, "Evaluation", -1)),
          _createVNode(ApplicationComments, {
            type: "evaluations",
            id: _ctx.commentsId,
            comments: commentData.value
          }, null, 8, ["id", "comments"])
        ]),
        (showEvaluationsTab.value.show)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(PermissionsWrapper, {
                permissionIds: [_unref(Permissions).Evaluation],
                permissionLevel: _unref(PermissionLevels).Read
              }, {
                default: _withCtx(() => [
                  _createVNode(EvaluationDetails, {
                    applicants: _ctx.applicants,
                    application: _ctx.application,
                    store: _ctx.store
                  }, null, 8, ["applicants", "application", "store"])
                ]),
                _: 1
              }, 8, ["permissionIds", "permissionLevel"]),
              _createVNode(PermissionsWrapper, {
                disabled: !_ctx.canEdit,
                showDisabledIfNotVisible: true,
                permissionIds: [_unref(Permissions).AnalystReview],
                permissionLevel: _unref(PermissionLevels).Update
              }, {
                default: _withCtx(() => [
                  (isFullService.value)
                    ? (_openBlock(), _createBlock(EvaluationsAnalystReview, {
                        key: 0,
                        applicants: _ctx.applicants,
                        application: _ctx.application,
                        store: _ctx.store,
                        disabled: _ctx.disabled
                      }, null, 8, ["applicants", "application", "store", "disabled"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["disabled", "permissionIds", "permissionLevel"]),
              _createVNode(PermissionsWrapper, {
                disabled: !_ctx.canEdit,
                showDisabledIfNotVisible: true,
                permissionIds: [_unref(Permissions).Evaluation],
                permissionLevel: _unref(PermissionLevels).Update
              }, {
                default: _withCtx(() => [
                  _createVNode(EvaluationsCreditUnionDecision, {
                    applicants: _ctx.applicants,
                    application: _ctx.application,
                    canEdit: _ctx.canEdit,
                    onSubmitForm: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('submitForm', $event)))
                  }, null, 8, ["applicants", "application", "canEdit"])
                ]),
                _: 1
              }, 8, ["disabled", "permissionIds", "permissionLevel"])
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
}

})