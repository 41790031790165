import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "xpage-content-page",
  id: "final-review-tab"
}
const _hoisted_2 = { class: "xtitlearea" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { key: 3 }
const _hoisted_7 = { key: 4 }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }

import { ref, computed } from "vue";
import axios from "axios";
import functions from "@/use/functions.js";
import { FinalReviewApplication, TagStatus } from "@/models/opal";
import ApplicationComments from "@/components/ApplicationComments.vue";
import StatusReviewForm from "./StatusReviewForm.vue";
import PriorityStatusForm from "./PriorityStatusForm.vue";
import PermissionsWrapper from "@/components/wrappers/PermissionsWrapper.vue";
import { Permissions, PermissionLevels } from "@/models/permissions";
import { TagTypeIds } from "@/types/tag";

interface Props {
  isTabActive: boolean;
  application: FinalReviewApplication;
  commentsId: any;
  canEdit: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FinalReviewTab',
  props: {
    isTabActive: { type: Boolean },
    application: {},
    commentsId: {},
    canEdit: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;
const tag = ref(
  props.application.tags?.find((tag) => tag.tagTypeId === TagTypeIds.FinalReview)
);
const commentData = computed(() => props.application.commentData);

const form = ref({
  comment: "",
  approved: false,
  lenderId: props.application.data.lenderId,
  programTypeId: props.application.data.programTypeId,
  productTypeId: props.application.data.productTypeId,
});

async function submitForm() {
  let modalOptions = {
    title: "",
    description: "",
  };

  try {
    const response = await axios.post(
      `/api/applications/${props.application.data.id}/final-review/${tag.value?.id}`,
      form.value
    );

    if (response.data) {
      tag.value = response.data;

      modalOptions.title = "Success";
      modalOptions.description = "Submitted and processed application review.";

      // reset form
      form.value.comment = "";
      form.value.approved = false;
    } else {
      throw new Error("Failed to update tag.");
    }
  } catch (error) {
    console.error(error);

    modalOptions.title = "Error";
    modalOptions.description =
      "Failed to submit and process application review.";
  } finally {
    functions.openModal(modalOptions);
  }
}

return (_ctx: any,_cache: any) => {
  return (_ctx.isTabActive)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[0] || (_cache[0] = _createElementVNode("h2", null, "Final Review", -1)),
          _createVNode(ApplicationComments, {
            type: "final-review",
            id: _ctx.commentsId,
            comments: commentData.value
          }, null, 8, ["id", "comments"])
        ]),
        _createVNode(PermissionsWrapper, {
          disabled: !_ctx.canEdit,
          showDisabledIfNotVisible: true,
          permissionIds: [_unref(Permissions).FinalReview],
          permissionLevel: _unref(PermissionLevels).Update
        }, {
          default: _withCtx(() => [
            (!tag.value || !tag.value.tagStatusId)
              ? (_openBlock(), _createElementBlock("h3", _hoisted_3, "Oops, something went wrong..."))
              : (tag.value.tagStatusId == _unref(TagStatus).Removed)
                ? (_openBlock(), _createElementBlock("h3", _hoisted_4, "Removed"))
                : (tag.value.tagStatusId == _unref(TagStatus).Closed)
                  ? (_openBlock(), _createElementBlock("h3", _hoisted_5, "Closed"))
                  : (tag.value.tagStatusId == _unref(TagStatus).Priority)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createVNode(PriorityStatusForm, { onFormSubmit: submitForm })
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        (tag.value.tagStatusId == _unref(TagStatus).Open)
                          ? (_openBlock(), _createElementBlock("h3", _hoisted_8, "Managerial Review"))
                          : (tag.value.tagStatusId == _unref(TagStatus).Reviewed)
                            ? (_openBlock(), _createElementBlock("h3", _hoisted_9, " Reviewed - Final "))
                            : _createCommentVNode("", true),
                        _createVNode(StatusReviewForm, {
                          form: form.value,
                          onFormSubmit: submitForm
                        }, null, 8, ["form"])
                      ]))
          ]),
          _: 1
        }, 8, ["disabled", "permissionIds", "permissionLevel"])
      ]))
    : _createCommentVNode("", true)
}
}

})