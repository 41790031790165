import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, vShow as _vShow, withModifiers as _withModifiers, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "form-wrapper" }
const _hoisted_2 = {
  key: 0,
  autocomplete: "off"
}
const _hoisted_3 = ["disabled"]
const _hoisted_4 = { class: "xform-section" }
const _hoisted_5 = { class: "xfields" }
const _hoisted_6 = { class: "xform-section" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "xfields" }
const _hoisted_9 = {
  key: 1,
  class: "xwarningbox"
}
const _hoisted_10 = { class: "xform-section" }
const _hoisted_11 = { class: "xfields" }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = ["disabled"]
const _hoisted_14 = { class: "xform-section" }
const _hoisted_15 = { class: "xfields" }
const _hoisted_16 = { class: "xform-section" }
const _hoisted_17 = { class: "xfields" }
const _hoisted_18 = { class: "xform-section" }

import {
	reactive,
	computed,
	onMounted,
	watch,
	ref,
} from "vue";
import BaseFormField from "@/components/form-fields/BaseFormField.vue";
import { FieldSchema, Option } from "@/models/form";
import { useStore } from "vuex";
import functions from "@/use/functions";
import { $api } from "@/services/api1";
import { Applicant, LOSType } from "@/models/opal";
import useFunctions from "@/use/functions1";
import useValidation from "@/use/validation";
import { onBeforeMount } from "vue";
import { NationalIdNumberTypes } from "@/types/application";
import { Permissions, PermissionLevels } from "@/models/permissions";
import { useApplicationStore} from "@/store/store/applicationStore"
import PermissionsFormField from "../wrappers/PermissionsFormField.vue";
import ApplicationSlrRefinanceSection from "@/components/application/slr-refi/ApplicationSlrRefinanceSection.vue";
import ApplicationLineOfCreditSchoolSection from "@/components/application/line-of-credit/ApplicationLineOfCreditSchoolSection.vue";
import axios from "axios";


interface Props {
	application: any;
	applicants: any;
	applicant: any;
	primaryApplicant: any;
	secondaryApplicant?: any;
	authorizedThirdParty?: any;
	applicantType: any;
	disabled: boolean;
	canEdit: boolean;
	store: any;
	showForms: any;
	backup: any;
	cancelBtnClicked: boolean;
}
// for SLRSchoolInfoVM
interface ApplicationVMDetail {
	results: {
		tabApplicationViewModel: {
			school: {
				schoolName: string | null;
				highestDegreeLevel: string | null;
				degreeProgram: string | null;
			};
		};
	};
}

export default /*@__PURE__*/_defineComponent({
  __name: 'ApplicationForms',
  props: {
    application: {},
    applicants: {},
    applicant: {},
    primaryApplicant: {},
    secondaryApplicant: {},
    authorizedThirdParty: {},
    applicantType: {},
    disabled: { type: Boolean },
    canEdit: { type: Boolean },
    store: {},
    showForms: {},
    backup: {},
    cancelBtnClicked: { type: Boolean, default: false }
  },
  emits: [
	"getApplication",
	"cancelBtnClicked",
	"getAuthorizedThirdParty",
	"disableForm",
	"applicantUpdated",
	"authorizedThirdPartyUpdated",
	"schoolConfigurationUpdated",
	"discardChanges",
],
  setup(__props: any, { emit: __emit }) {

const store = useStore();
const applicationStore = useApplicationStore();

const { decodeHTMLEncodedStr, getInitial } = useFunctions();
const { validateForm, validateNationalIdNumber } = useValidation();
const emit = __emit;
let selected = ref("");
let application = reactive({} as any);
let person = reactive({} as any) as any;
let schoolConfigBackup = reactive({} as any);
let updatedPerson = reactive({} as any);
let schoolInfo = reactive({} as any);
let schema = reactive({} as any);
let schemas = reactive({} as any);
let addressSchemas = reactive({} as any);
let accountDetailsSchemas = reactive({} as any);
const applicationVMDetail = ref<ApplicationVMDetail | null>(null);
const schoolVMInfo = ref<
	ApplicationVMDetail["results"]["tabApplicationViewModel"]["school"] | null
>(null);

let schoolInformation = reactive({
	school: {},
	schoolConfiguration: {},
	form: {},
	degreePrograms: [],
}) as any;

const props = __props;

const current = computed(() => props?.applicant?.applicantTypeName);
const applicationId = computed(() => props.application?.data?.id);
const { sortLosTypesByName, sortLosTypesByDescription } = useFunctions();
const currentlySelected = computed(
	() => props?.applicants?.selected || "primary"
);
let currentApplicant = computed(
	() => props?.applicants[currentlySelected.value]
);
const nationalIdNumberTypes = computed(
	() => props?.store.state.types["NationalIdNumberTypes"]?.results
);
const itinNationalIdNumberTypeId = computed(
	() => nationalIdNumberTypes.value?.find((t: LOSType) => t.name === "ITIN")?.id
);
const ssnNationalIdNumberTypeId = computed(
	() => nationalIdNumberTypes.value?.find((t: LOSType) => t.name === "SSN")?.id
);
const updateNationalIdNumberLabel = (applicantType: string) =>
	props?.applicants[applicantType].nationalIdNumberTypeId ==
	NationalIdNumberTypes.ITIN
		? "Individual Taxpayer Identification Number"
		: "Social Security Number";

// for SLRSchoolInfoVM
const highestDegreeLevel = computed(() => {
	return schoolVMInfo.value?.highestDegreeLevel || "N/A";
});

const schoolNameVM = computed(() => {
	return schoolVMInfo.value?.schoolName || "N/A";
});

const degreeProgramVM = computed(() => {
	return schoolVMInfo.value?.degreeProgram || "N/A";
});

const primaryApplicantSchema = reactive({
	firstName: {
		name: "firstName",
		type: "text",
		label: "First Name",
		placeholder: "First Name",
		required: true,
		errorMessage: "First Name is required.",
		maxLength: 40,
	} as FieldSchema,
	middleName: {
		name: "middleName",
		type: "text",
		label: "Middle Name",
		placeholder: "Middle Name",
		required: false,
		maxLength: 40,
	} as FieldSchema,
	lastName: {
		name: "lastName",
		type: "text",
		label: "Last Name",
		placeholder: "Last Name",
		required: true,
		errorMessage: "Last Name is required.",
		maxLength: 40,
	} as FieldSchema,
	nationalIdNumber: {
		type: "nationalIdNumber",
		label: updateNationalIdNumberLabel("primary"),
		placeholder: "###-##-####",
		required: false,
	} as FieldSchema,
	citizenshipStatusId: {
		name: "citizenshipStatusId",
		type: "select",
		label: "Citizenship Status",
		required: true,
		errorMessage: "Citizenship status is required.",
	} as FieldSchema,
	dateOfBirth: {
		name: "dateOfBirth",
		label: "Date of Birth",
		type: "date",
		placeholder: "YYYY-MM-DD",
		required: true,
	} as FieldSchema,
});
const secondaryApplicantSchema = reactive({
	firstName: {
		name: "firstName",
		type: "text",
		label: "First Name",
		placeholder: "First Name",
		required: true,
		errorMessage: "First Name is required.",
		maxLength: 40,
	} as FieldSchema,
	middleName: {
		name: "middleName",
		type: "text",
		label: "Middle Name",
		placeholder: "Middle Name",
		required: false,
		maxLength: 40,
	} as FieldSchema,
	lastName: {
		name: "lastName",
		type: "text",
		label: "Last Name",
		placeholder: "Last Name",
		required: true,
		errorMessage: "Last Name is required.",
		maxLength: 40,
	} as FieldSchema,
	nationalIdNumber: {
		type: "nationalIdNumber",
		label: updateNationalIdNumberLabel("secondary"),
		placeholder: "###-##-####",
		required: false,
	} as FieldSchema,
	citizenshipStatusId: {
		name: "citizenshipStatusId",
		type: "select",
		label: "Citizenship Status",
		required: true,
		errorMessage: "Citizenship status is required.",
	} as FieldSchema,
	dateOfBirth: {
		name: "dateOfBirth",
		label: "Date of Birth",
		type: "date",
		placeholder: "YYYY-MM-DD",
		required: true,
	} as FieldSchema,
});
const addressSchema = reactive({
	address1: {
		name: "addrLine1",
		type: "text",
		subtype: "address",
		label: "Permanent Street Address",
		placeholder: "Street",
		required: true,
		maxLength: 50,
	} as FieldSchema,
	address2: {
		name: "addrLine2",
		type: "text",
		subtype: "address",
		label: "Unit Number",
		placeholder: "Unit #",
		required: false,
		maxLength: 25,
	} as FieldSchema,
	addressUnitTypeId: {
		name: "unitType",
		type: "select",
		subtype: "address",
		label: "Unit Type",
		required: false,
	} as FieldSchema,
	city: {
		name: "city",
		type: "text",
		label: "City",
		subtype: "address",
		placeholder: "City",
		required: true,
		maxLength: 40,
	} as FieldSchema,
	stateId: {
		name: "state",
		type: "select",
		subtype: "address",
		label: "State",
		required: true,
	} as FieldSchema,
	postalCode: {
		type: "zipcode",
		label: "Zip Code",
		placeholder: "Zip Code",
		errorMessage: "Zip code is required.",
		title: "Zip Code Format: XXXXX or XXXXX-XXXX",
		required: true,
	} as FieldSchema,
	countryId: {
		type: "select",
		label: "Country",
		errorMessage: "Country is required.",
		required: true,
	} as FieldSchema,
});
const accountDetailsSchema = reactive({
	phoneNumber: {
		type: "phone",
		label: "Preferred Phone Number",
		placeholder: "Phone Number",
		required: true,
	} as FieldSchema,
	phoneNumberTypeId: {
		name: "phoneNumberTypeId",
		type: "select",
		required: true,
		label: "Phone Type",
	} as FieldSchema,
	emailAddress: {
		type: "email",
		label: "Email Address",
		placeholder: "Email Address",
		required: true,
		maxLength: 100,
	} as FieldSchema,
	userId: {
		type: "text",
		label: "User Id",
		required: false,
	} as FieldSchema,
});
const schoolInfoSchema = reactive({
	school: {
		name: "school",
		type: "autocomplete",
		autocompleteProperty: "name",
		autoCompleteResultsErrorMessage: "No matches found",
		hideLabel: false,
		label: "School",
		placeholder: "Search...",
		required: false,
	} as FieldSchema,
	schoolLevelTypeId: {
		type: "select",
		label: "Year in School",
	} as FieldSchema,
	major: {
		type: "select",
		label: "Major",
	} as FieldSchema,
	degreeProgram: {
		type: "select",
		label: "Degree Program",
	} as FieldSchema,
});
const authorizedThirdPartySchema = reactive({
	firstName: {
		name: "firstName",
		type: "text",
		label: "First Name",
		placeholder: "First Name",
		required: true,
		errorMessage: "First Name is required.",
		maxLength: 40,
	} as FieldSchema,
	middleName: {
		name: "middleName",
		type: "text",
		label: "Middle Name",
		placeholder: "Middle Name",
		required: false,
		maxLength: 40,
	} as FieldSchema,
	lastName: {
		name: "lastName",
		type: "text",
		label: "Last Name",
		placeholder: "Last Name",
		required: true,
		errorMessage: "Last Name is required.",
		maxLength: 40,
	} as FieldSchema,
	dateOfBirth: {
		name: "dateOfBirth",
		label: "Date of Birth",
		type: "date",
		placeholder: "YYYY-MM-DD",
	} as FieldSchema,
	emailAddress: {
		name: "emailAddress",
		type: "email",
		label: "Email Address",
		placeholder: "Email Address",
		required: true,
		maxLength: 100,
	} as FieldSchema,
	phoneNumber: {
		name: "phoneNumber",
		type: "phone",
		label: "Preferred Phone Number",
		placeholder: "Phone Number",
		required: true,
	} as FieldSchema,
	phoneNumberTypeId: {
		name: "phoneNumberTypeId",
		type: "select",
		required: true,
		label: "Phone Type",
	} as FieldSchema,
	authorizedByApplicantId: {
		name: "authorizedByApplicantId",
		type: "select",
		label: "Authorized by Applicant",
		required: true,
	} as FieldSchema,
});
const authorizedThirdPartyAddressSchema = reactive({
	address1: {
		name: "address1",
		type: "text",
		subtype: "address",
		label: "Permanent Street Address",
		placeholder: "Street",
		required: true,
		maxLength: 50,
	} as FieldSchema,
	address2: {
		name: "address2",
		type: "text",
		subtype: "address",
		label: "Unit Number",
		placeholder: "Unit #",
		required: false,
		maxLength: 25,
	} as FieldSchema,
	addressUnitTypeId: {
		name: "unitType",
		type: "select",
		subtype: "address",
		label: "Unit Type",
		required: false,
	} as FieldSchema,
	city: {
		name: "city",
		type: "text",
		label: "City",
		subtype: "address",
		placeholder: "City",
		required: true,
		maxLength: 40,
	} as FieldSchema,
	stateId: {
		name: "state",
		type: "select",
		subtype: "address",
		label: "State",
		required: true,
	} as FieldSchema,
	postalCode: {
		type: "zipcode",
		label: "Zip Code",
		placeholder: "Zip Code",
		errorMessage: "Zip code is required.",
		title: "Zip Code Format: XXXXX or XXXXX-XXXX",
		required: true,
	} as FieldSchema,
	countryId: {
		type: "select",
		label: "Country",
		errorMessage: "Country is required.",
		required: true,
	} as FieldSchema,
});
const applicantOptions = props.application?.applicants.map((a: Applicant) => {
	return {
		label: `${a.fullName} - ${a.applicantTypeName}`,
		value: a.id,
	};
});
const sortedCitizenshipStatuses = sortLosTypesByName(
	props?.store.state.types["Citizenship"]?.results
);
const citizenshipStatusOptions = sortedCitizenshipStatuses?.map((c: any) => {
	return {
		label: c.name,
		value: c.id,
	};
});
const sortedStates = sortLosTypesByName(
	props?.store.state.types["States"]?.results
);
const statesOptions = sortedStates?.map((c: any) => {
	return {
		label: c.name,
		value: c.id,
	} as Option;
});
const sortedCountries = sortLosTypesByName(
	props?.store.state.types["Countries"]?.results
);
const countryOptions = sortedCountries?.map((c: any) => {
	return {
		label: c.name,
		value: c.id,
	};
});
const sortedPhoneTypes = sortLosTypesByName(
	props?.store.state.types["PhoneNumbers"]?.results
);
const phoneTypeOptions = sortedPhoneTypes?.map((c: any) => {
	return {
		label: c.name,
		value: c.id,
	};
});
const programTypeId = computed(
	() => props?.application?.data?.programTypeId || 1
);
const LocApplication = computed(
	() => props?.application?.data?.productTypeId === 1
);
const SlrApplication = computed(
	() => props?.application?.data?.productTypeId === 2
);
const sortedSchoolLevels = computed(() =>
	sortLosTypesByName(
		props?.store.state.types[`SchoolLevels${programTypeId.value}`]?.results
	)
);
const schoolLevels = sortedSchoolLevels?.value?.map((c: any) => {
	return {
		label: c.name,
		value: c.id,
	};
});
const sortedMajors = sortLosTypesByName(props?.store.state.types["Majors"]);
const majors = sortedMajors?.map((c: any) => {
	return {
		label: `${c.majorName} (${c.description})`,
		value: c.majorName,
	};
});
const sortedDegreePrograms = computed(() =>
	sortLosTypesByDescription(props?.store.state.types["GraduateDegreePrograms"])
);
const degreePrograms = sortedDegreePrograms?.value?.map((c: any) => {
	return {
		label: c.description,
		value: c.id,
	};
});
function discardChanges() {
	emit("discardChanges", props?.backup);
	person = Object.assign({}, props?.backup);
	application.data.schoolConfiguration = Object.assign({}, schoolConfigBackup);
	if (
		props?.applicantType === "Primary Applicant" ||
		props?.applicantType === "Co-Applicant"
	) {
		const validPersonalInfo = validateForm(
			schemas[currentlySelected.value],
			person
		);
		const validAddress = person?.addresses[0]?.id
			? validateForm(
					addressSchemas[currentlySelected.value],
					person?.addresses[0]
			  )
			: true;
		const validAccountDetails = validateForm(
			accountDetailsSchemas[currentlySelected.value],
			{
				...person.contactInfo,
				userId: person.userId,
			}
		);
		accountDetailsSchemas[currentlySelected.value].emailAddress.touched = false;
	}
	emit("disableForm");
}
async function setInformation() {
	selected.value = current.value || "primary";
	if (
		props?.applicantType === "Primary Applicant" ||
		props?.applicantType === "Co-Applicant"
	) {
		person = currentApplicant.value;
		application = Object.assign({}, props?.application);
	} else {
		person = currentApplicant.value;
		person.dateOfBirth = person.dateOfBirth
			? person?.dateOfBirth?.split("T")?.[0]
			: person?.dateOfBirth;
	}
	// set school
	await setSchoolConfiguration();
}
async function setSchoolConfiguration() {
	const school = props?.store.state.types["Schools"]?.find(
		(s: any) =>
			s?.name === props?.application?.data?.schoolConfiguration?.schoolName
	);
	schoolInformation = {
		school: school || {},
		schoolConfiguration: props?.application?.data?.schoolConfiguration,
	};
	// implement updates once degree programs is different for each school
	// schoolInformation.degreePrograms = degreePrograms.value;
	schoolConfigBackup = Object.assign(
		{},
		props?.application?.data?.schoolConfiguration
	);
}
function setNationalIdNumberTypeId(nationalIdNumberTypeId: any) {
	switch (nationalIdNumberTypeId) {
		case itinNationalIdNumberTypeId.value:
			return itinNationalIdNumberTypeId.value;
		default:
			return ssnNationalIdNumberTypeId.value;
	}
}
function confirmApplication() {
	const validPersonalInfo = validateForm(
		schemas[currentlySelected.value],
		person
	);
	const validAddress = validateForm(
		addressSchemas[currentlySelected.value],
		person.addresses[0]
	);
	const validAccountDetails = validateForm(
		accountDetailsSchemas[currentlySelected.value],
		{
			...person.contactInfo,
			userId: person.userId,
		}
	);
	if (validPersonalInfo && validAddress && validAccountDetails) {
		if (
			currentApplicant.value.nationalIdNumberTypeId ===
			NationalIdNumberTypes.ITIN
		) {
			validateNationalIdNumber(
				schemas[currentlySelected.value],
				person.nationalIdNumber
			);
		}
		person.nationalIdNumberTypeId = setNationalIdNumberTypeId(
			person.nationalIdNumberTypeId
		);
		person.fullName = functions.capitalize(
			`${decodeHTMLEncodedStr(person.firstName) || ""} ${
				decodeHTMLEncodedStr(person.lastName) || ""
			}`.trim() || "No Name"
		);
		person.initials = `${person.firstName?.charAt(0) || ""}${
			person.lastName?.charAt(0) || ""
		}`.trim();

		application.data.schoolConfiguration.schoolName =
			applicationStore.school.name;
		const appDataBackup = {
			...schoolConfigBackup,
			...props?.backup,
		};
		const appData = {
			...application.data.schoolConfiguration,
			...person,
		};
		const options = {
			programTypeId: application.data.programTypeId,
			productTypeId: application.data.productTypeId,
		};
		let modalHtml = functions.checkFormChanges(appData, appDataBackup, options);
		updatedPerson = person;
		functions.openModal({
			title: "Confirm",
			description: "Please review your changes before submitting...",
			html: modalHtml,
			buttons: [
				{ title: "Cancel", onClick: () => functions.closeModal() },
				{ title: "Save Changes", onClick: () => saveApplication() },
			],
		});
	} else {
		functions.openModal({
			title: "Alert",
			description: "Form contains errors. Please see fields marked in red.",
		});
	}
}
async function updateAuthorizedThirdParty() {
	// Check all forms
	const validPersonalInfo = validateForm(authorizedThirdPartySchema, person);
	const validAddress = validateForm(
		authorizedThirdPartyAddressSchema,
		person.address
	);
	if (validPersonalInfo && validAddress) {
		// person.phoneNumber = person.phoneNumber.split("-").join("");
		if (person.dateOfBirth) {
			person.dateOfBirth = new Date(person.dateOfBirth);
		} 
		else {
			person.dateOfBirth = null;
		}		
		const response = await $api.applications.updateAuthorizedThirdParty(
			application?.data?.id,
			person
		);
		if (response.error) {
			functions.openModal({
				title: "Error",
				description: "There was an issue saving the Authorized Third Party",
			});
		} else {
			// use response
			person = response;
			person.dateOfBirth = person?.dateOfBirth?.split("T")?.[0];
			person.initials =
				getInitial(person.firstName) + getInitial(person.lastName);
			person.fullName = `${decodeHTMLEncodedStr(
				person.firstName
			)} ${decodeHTMLEncodedStr(person.lastName)}`;
			person.addedByUserId = response.addedByUserId;
			authorizedThirdPartySchema.emailAddress.touched = false;
			// setInformation();
			functions.openModal({
				title: "Success!",
				description: "The Authorized Third Party has been saved",
			});
			emit("authorizedThirdPartyUpdated", person);
			emit("disableForm");
		}
	}
}
async function updateApplicantInfo() {
	person.emailAddress = person.contactInfo.emailAddress;
	person.phoneNumber = person.contactInfo.phoneNumber.split("-").join("");
	person.phoneNumberTypeId = person.contactInfo.phoneNumberTypeId;
	const response = await $api.applicants.updateApplicantInfo(person);
	if (response.error) {
		functions.openModal({
			title: "Error",
			description: "There was an issue updating the Applicant Info",
		});
		return false;
	} else {
		return true;
	}
}
async function updateApplicantAddress() {
	const applicantId = person.id;
	const addressId = person?.addresses?.[0]?.id;
	const address = person?.addresses?.[0];
	// rename the field "id" to "addressId" for api validation
	address["addressId"] = address["id"];
	delete address["id"];
	const response = await $api.applicants.updateApplicantAddress(
		applicantId,
		addressId,
		address
	);
	if (response.error) {
		functions.openModal({
			title: "Error",
			description: "There was an issue updating the Applicant Address",
		});
		return false;
	} else {
		address["id"] = address["addressId"];
		delete address["addressId"];
		return true;
	}
}
async function updateApplicationSchoolConfiguration() {

	schoolInformation.schoolConfiguration.schoolName =
		applicationStore.school.name;
	schoolInformation.schoolConfiguration.schoolId = applicationStore.school.id;
	const response = await $api.applications.updateApplicationSchoolConfiguration(
		applicationId.value,
		schoolInformation.schoolConfiguration
	);
	if (response.error || response.errors) {
		functions.openModal({
			title: "Error",
			description:
				"There was an issue updating the application school configuration",
		});
		schoolInformation.schoolConfiguration =
			application.data.schoolConfiguration;
		return false;
	} else {
		return true;
	}
}
async function saveAuthorizedThirdParty() {
	// set modal loading button
	functions.setModalLoading();
	await updateAuthorizedThirdParty();
}
async function saveApplication() {
	emit("disableForm");
	// set modal loading button
	functions.setModalLoading();
	// update the applicant info
	const request1 = await updateApplicantInfo();
	// update the applicant address
	const request2 = await updateApplicantAddress();
	// update the application school configuration
	const request3 = await updateApplicationSchoolConfiguration();
	//if all requests are successful
	if (request1 && request2 && request3) {
		person = updatedPerson;
		emit("applicantUpdated", updatedPerson);
		emit("schoolConfigurationUpdated", schoolInformation.schoolConfiguration);
		functions.openModal({
			title: "Success!",
			description: "Application has been saved",
		});
	} else {
		functions.openModal({
			title: "Error",
			description: "There was an error saving your changes",
		});
	}
	accountDetailsSchemas[currentlySelected.value].emailAddress.touched = false;
}
const getApplicationVMDetail = async () => {
	const referenceId = application?.data?.referenceId;
	if (!referenceId) {
		console.error("Reference ID is undefined");
		return;
	}
	try {
		const response = await axios.get(`/api/applications/${referenceId}/detail`);
		applicationVMDetail.value = response.data;
		return response.data; // Add this line to return the data
	} catch (error) {
		console.error(error);
	}
};
setInformation();
watch(current, async (newQuestion, oldQuestion) => {
	setInformation();
	schemas[`selected`] = currentlySelected.value;
});
watch(
	() => props?.cancelBtnClicked,
	(value) => {
		if (value === true) {
			discardChanges();
			setInformation();
		}
	}
);
watch(applicationVMDetail, (newValue) => {
	if (newValue) {
		schoolVMInfo.value = newValue.results.tabApplicationViewModel.school;
	}
});
onBeforeMount(() => {
	["primary", "secondary"].forEach((at: string) => {
		schemas[at] =
			at === "primary" ? primaryApplicantSchema : secondaryApplicantSchema;
		addressSchemas[at] = Object.assign({}, addressSchema);
		accountDetailsSchemas[at] = { ...accountDetailsSchema };
		schemas[`selected`] = currentlySelected.value;
	});
});
onMounted(() => {
	functions.getType("NationalIdNumberTypes");
	getApplicationVMDetail();
	// Store the school information
	if (applicationVMDetail.value) {
		schoolInfo.value =
			applicationVMDetail.value.results.tabApplicationViewModel.school;
	}
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showForms)
      ? (_openBlock(), _createElementBlock("form", _hoisted_2, [
          _createElementVNode("fieldset", { disabled: _ctx.disabled }, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h3", null, _toDisplayString(_ctx.applicant.applicantTypeName), 1),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(BaseFormField, {
                  schema: _unref(schemas)[currentlySelected.value].firstName,
                  modelValue: _unref(person).firstName,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(person).firstName) = $event))
                }, null, 8, ["schema", "modelValue"]),
                _createVNode(BaseFormField, {
                  schema: _unref(schemas)[currentlySelected.value].middleName,
                  modelValue: _unref(person).middleName,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(person).middleName) = $event))
                }, null, 8, ["schema", "modelValue"]),
                _createVNode(BaseFormField, {
                  schema: _unref(schemas)[currentlySelected.value].lastName,
                  modelValue: _unref(person).lastName,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(person).lastName) = $event))
                }, null, 8, ["schema", "modelValue"]),
                _createVNode(PermissionsFormField, {
                  class: "xfield",
                  label: "Social Security Number",
                  disabled: !_ctx.canEdit,
                  showDisabledIfNotVisible: true,
                  permissionIds: [_unref(Permissions).ApplicantSSN],
                  permissionLevel: _unref(PermissionLevels).Update,
                  schema: _unref(schemas)[currentlySelected.value].nationalIdNumber,
                  modelValue: _unref(person).nationalIdNumber,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(person).nationalIdNumber) = $event))
                }, null, 8, ["disabled", "permissionIds", "permissionLevel", "schema", "modelValue"]),
                _createVNode(BaseFormField, {
                  schema: _unref(schemas)[currentlySelected.value].citizenshipStatusId,
                  modelValue: _unref(person).citizenshipStatusId,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(person).citizenshipStatusId) = $event)),
                  modelModifiers: { number: true },
                  options: _unref(citizenshipStatusOptions)
                }, null, 8, ["schema", "modelValue", "options"]),
                _createVNode(BaseFormField, {
                  schema: _unref(schemas)[currentlySelected.value].dateOfBirth,
                  modelValue: _unref(person).dateOfBirth,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(person).dateOfBirth) = $event))
                }, null, 8, ["schema", "modelValue"])
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _cache[30] || (_cache[30] = _createElementVNode("h3", null, "Address", -1)),
              (_unref(person)?.addresses[0]?.id || _unref(person)?.addresses[0]?.addressId)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(BaseFormField, {
                        schema: _unref(addressSchemas)[currentlySelected.value].address1,
                        modelValue: _unref(person).addresses[0].address1,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(person).addresses[0].address1) = $event))
                      }, null, 8, ["schema", "modelValue"]),
                      _createVNode(BaseFormField, {
                        schema: _unref(addressSchemas)[currentlySelected.value].address2,
                        modelValue: _unref(person).addresses[0].address2,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_unref(person).addresses[0].address2) = $event))
                      }, null, 8, ["schema", "modelValue"]),
                      _createVNode(BaseFormField, {
                        schema: _unref(addressSchemas)[currentlySelected.value].city,
                        modelValue: _unref(person).addresses[0].city,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_unref(person).addresses[0].city) = $event))
                      }, null, 8, ["schema", "modelValue"]),
                      _createVNode(BaseFormField, {
                        schema: _unref(addressSchemas)[currentlySelected.value].stateId,
                        modelValue: _unref(person).addresses[0].stateId,
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_unref(person).addresses[0].stateId) = $event)),
                        modelModifiers: { number: true },
                        options: _unref(statesOptions)
                      }, null, 8, ["schema", "modelValue", "options"]),
                      _createVNode(BaseFormField, {
                        schema: _unref(addressSchemas)[currentlySelected.value].postalCode,
                        modelValue: _unref(person).addresses[0].postalCode,
                        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_unref(person).addresses[0].postalCode) = $event))
                      }, null, 8, ["schema", "modelValue"]),
                      _createVNode(BaseFormField, {
                        schema: _unref(addressSchemas)[currentlySelected.value].countryId,
                        modelValue: _unref(person).addresses[0].countryId,
                        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_unref(person).addresses[0].countryId) = $event)),
                        modelModifiers: { number: true },
                        options: _unref(countryOptions)
                      }, null, 8, ["schema", "modelValue", "options"])
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_9, " This applicant does not have an address in the database "))
            ]),
            _createElementVNode("div", _hoisted_10, [
              _cache[31] || (_cache[31] = _createElementVNode("h3", null, "Account Details", -1)),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(BaseFormField, {
                  schema: _unref(accountDetailsSchemas)[currentlySelected.value].phoneNumber,
                  modelValue: _unref(person).contactInfo.phoneNumber,
                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_unref(person).contactInfo.phoneNumber) = $event))
                }, null, 8, ["schema", "modelValue"]),
                _createVNode(BaseFormField, {
                  schema: _unref(accountDetailsSchemas)[currentlySelected.value].phoneNumberTypeId,
                  modelValue: _unref(person).contactInfo.phoneNumberTypeId,
                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_unref(person).contactInfo.phoneNumberTypeId) = $event)),
                  modelModifiers: { number: true },
                  options: _unref(phoneTypeOptions)
                }, null, 8, ["schema", "modelValue", "options"]),
                _createVNode(BaseFormField, {
                  schema: _unref(accountDetailsSchemas)[currentlySelected.value].emailAddress,
                  modelValue: _unref(person).contactInfo.emailAddress,
                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_unref(person).contactInfo.emailAddress) = $event))
                }, null, 8, ["schema", "modelValue"]),
                _createVNode(BaseFormField, {
                  disabled: "",
                  schema: _unref(accountDetailsSchemas)[currentlySelected.value].userId,
                  modelValue: _unref(person).userId,
                  "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_unref(person).userId) = $event))
                }, null, 8, ["schema", "modelValue"])
              ])
            ]),
            (LocApplication.value)
              ? (_openBlock(), _createBlock(ApplicationLineOfCreditSchoolSection, {
                  key: 0,
                  schemas: schoolInfoSchema,
                  schoolInformation: _unref(schoolInformation),
                  schoolLevels: _unref(schoolLevels),
                  programTypeId: programTypeId.value,
                  majors: _unref(majors),
                  degreePrograms: _unref(degreePrograms),
                  current: current.value,
                  application: _unref(application)
                }, null, 8, ["schemas", "schoolInformation", "schoolLevels", "programTypeId", "majors", "degreePrograms", "current", "application"]))
              : _createCommentVNode("", true),
            (SlrApplication.value)
              ? (_openBlock(), _createBlock(ApplicationSlrRefinanceSection, {
                  key: 1,
                  current: current.value,
                  application: _unref(application),
                  canEdit: _ctx.canEdit,
                  highestDegreeLevel: highestDegreeLevel.value,
                  schoolNameVM: schoolNameVM.value,
                  degreeProgramVM: degreeProgramVM.value
                }, null, 8, ["current", "application", "canEdit", "highestDegreeLevel", "schoolNameVM", "degreeProgramVM"]))
              : _createCommentVNode("", true)
          ], 8, _hoisted_3),
          _withDirectives(_createElementVNode("button", {
            class: "submit",
            type: "submit",
            onClick: _withModifiers(confirmApplication, ["prevent"])
          }, " Submit ", 512), [
            [_vShow, _ctx.canEdit && !_ctx.disabled]
          ])
        ]))
      : (_openBlock(), _createElementBlock("form", _hoisted_12, [
          _createElementVNode("fieldset", { disabled: _ctx.disabled }, [
            _createElementVNode("div", _hoisted_14, [
              _cache[34] || (_cache[34] = _createElementVNode("h3", null, "Authorized Third Party", -1)),
              _createElementVNode("div", _hoisted_15, [
                _createVNode(BaseFormField, {
                  schema: authorizedThirdPartySchema.firstName,
                  modelValue: _unref(person).firstName,
                  "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_unref(person).firstName) = $event))
                }, null, 8, ["schema", "modelValue"]),
                _createVNode(BaseFormField, {
                  schema: authorizedThirdPartySchema.lastName,
                  modelValue: _unref(person).lastName,
                  "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_unref(person).lastName) = $event))
                }, null, 8, ["schema", "modelValue"]),
                _createVNode(BaseFormField, {
                  schema: authorizedThirdPartySchema.emailAddress,
                  modelValue: _unref(person).emailAddress,
                  "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_unref(person).emailAddress) = $event))
                }, null, 8, ["schema", "modelValue"]),
                _createVNode(BaseFormField, {
                  schema: authorizedThirdPartySchema.phoneNumber,
                  modelValue: _unref(person).phoneNumber,
                  "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_unref(person).phoneNumber) = $event))
                }, null, 8, ["schema", "modelValue"]),
                _createVNode(BaseFormField, {
                  schema: authorizedThirdPartySchema.phoneNumberTypeId,
                  modelValue: _unref(person).phoneNumberTypeId,
                  "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_unref(person).phoneNumberTypeId) = $event)),
                  modelModifiers: { number: true },
                  options: _unref(phoneTypeOptions)
                }, null, 8, ["schema", "modelValue", "options"]),
                _createVNode(BaseFormField, {
                  schema: authorizedThirdPartySchema.dateOfBirth,
                  modelValue: _unref(person).dateOfBirth,
                  "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_unref(person).dateOfBirth) = $event))
                }, null, 8, ["schema", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_16, [
                _cache[32] || (_cache[32] = _createElementVNode("h3", null, "Address", -1)),
                _createElementVNode("div", _hoisted_17, [
                  _createVNode(BaseFormField, {
                    schema: authorizedThirdPartyAddressSchema.address1,
                    modelValue: _unref(person).address.address1,
                    "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_unref(person).address.address1) = $event))
                  }, null, 8, ["schema", "modelValue"]),
                  _createVNode(BaseFormField, {
                    schema: authorizedThirdPartyAddressSchema.address2,
                    modelValue: _unref(person).address.address2,
                    "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_unref(person).address.address2) = $event))
                  }, null, 8, ["schema", "modelValue"]),
                  _createVNode(BaseFormField, {
                    schema: authorizedThirdPartyAddressSchema.city,
                    modelValue: _unref(person).address.city,
                    "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_unref(person).address.city) = $event))
                  }, null, 8, ["schema", "modelValue"]),
                  _createVNode(BaseFormField, {
                    schema: authorizedThirdPartyAddressSchema.stateId,
                    modelValue: _unref(person).address.stateId,
                    "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_unref(person).address.stateId) = $event)),
                    modelModifiers: { number: true },
                    options: _unref(statesOptions)
                  }, null, 8, ["schema", "modelValue", "options"]),
                  _createVNode(BaseFormField, {
                    schema: authorizedThirdPartyAddressSchema.postalCode,
                    modelValue: _unref(person).address.postalCode,
                    "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_unref(person).address.postalCode) = $event))
                  }, null, 8, ["schema", "modelValue"]),
                  _createVNode(BaseFormField, {
                    schema: authorizedThirdPartyAddressSchema.countryId,
                    modelValue: _unref(person).address.countryId,
                    "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_unref(person).address.countryId) = $event)),
                    modelModifiers: { number: true },
                    options: _unref(countryOptions)
                  }, null, 8, ["schema", "modelValue", "options"])
                ])
              ]),
              _createElementVNode("div", _hoisted_18, [
                _cache[33] || (_cache[33] = _createElementVNode("h3", null, "Authorization", -1)),
                _createVNode(BaseFormField, {
                  schema: authorizedThirdPartySchema.authorizedByApplicantId,
                  options: _unref(applicantOptions),
                  modelValue: _unref(person).authorizedByApplicantId,
                  "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_unref(person).authorizedByApplicantId) = $event)),
                  modelModifiers: { number: true }
                }, null, 8, ["schema", "options", "modelValue"])
              ])
            ])
          ], 8, _hoisted_13),
          _withDirectives(_createElementVNode("button", {
            class: "submit",
            type: "submit",
            onClick: _cache[29] || (_cache[29] = _withModifiers(($event: any) => (saveAuthorizedThirdParty()), ["prevent"]))
          }, " Submit ", 512), [
            [_vShow, _ctx.canEdit && !_ctx.disabled]
          ])
        ]))
  ]))
}
}

})