import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, unref as _unref, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "xpage-content-page" }
const _hoisted_2 = { class: "xpage-breadcrumbs" }
const _hoisted_3 = { class: "xpage-disbursements" }
const _hoisted_4 = { class: "batch-header-info xpage-inner" }
const _hoisted_5 = { class: "batch-header-info-item" }
const _hoisted_6 = { class: "batch-header-info-label" }
const _hoisted_7 = { class: "batch-header-info-sub-value" }
const _hoisted_8 = { class: "batch-header-info-value" }
const _hoisted_9 = { class: "batch-header-info-value" }
const _hoisted_10 = { class: "date-card" }
const _hoisted_11 = { class: "batch-subheader-info" }
const _hoisted_12 = { class: "batch-subheader-info-item" }
const _hoisted_13 = { class: "batch-subheader-info-label" }
const _hoisted_14 = { class: "batch-subheader-info-label" }
const _hoisted_15 = { class: "batch-subheader-info-label" }
const _hoisted_16 = { class: "xpage-inner" }
const _hoisted_17 = { class: "db-table" }
const _hoisted_18 = ["onClick"]
const _hoisted_19 = { class: "header-container" }
const _hoisted_20 = { class: "header-text" }
const _hoisted_21 = {
  key: 0,
  class: "separator-container"
}
const _hoisted_22 = ["onClick"]
const _hoisted_23 = ["disabled", "checked", "onClick"]
const _hoisted_24 = ["disabled", "checked", "onClick"]
const _hoisted_25 = { class: "disbursement-buttons-container" }

import Loader from "@/components/Loader.vue";
import Page from "@/components/layout/Page.vue";
import store from "@/store";
import { useRoute } from "vue-router";
import { onMounted, ref, computed, onUnmounted } from "vue";
import { Permissions, PermissionLevels } from "@/models/permissions";
import PermissionsWrapper from "@/components/wrappers/PermissionsWrapper.vue";
import PermissionsButton from "@/components/wrappers/PermissionsButton.vue";
import { useBatchesStore } from "@/store/store/batchesStore";
import axios from "axios";
import functions from "@/use/functions";
import useFunctions from "@/use/functions1";

const pageName = "Edit Disbursement Batch";

export default /*@__PURE__*/_defineComponent({
  __name: 'BatchEdit',
  setup(__props) {

const { decodeHTMLEncodedStr } = useFunctions();

const batchesStore = useBatchesStore();
const loading = ref(false);
const route = useRoute();

const batchData = ref<{
	disbursementBatch: any;
	disbursements: any[];
} | null>(null);

const batchId = computed(() => batchesStore.getBatchId());
const ascending = ref<boolean>(true);
const sortColumn = ref<string>("");

const isApproved = computed(() => {
	return batchData.value?.disbursementBatch.approved === true;
});

const goBack = () => {
	window.history.back();
};

const firstName = computed(() => {
	return decodeHTMLEncodedStr(store.state.oidcStore.user.given_name);
});

const lastName = computed(() => {
	return decodeHTMLEncodedStr(store.state.oidcStore.user.family_name);
});

const navigateToDisbursement = (referenceId: string) => {
	// ! Just for testing purposes in case we actually don't' to open the disbursement in a new tab
	// router.push(`/loans/${referenceId}`);
	const url = `/loans/${referenceId}`;
	window.open(url, "_blank");
};

const columnHeaders = computed(() => {
	return [
		{ key: "inBatch", name: "In", sortable: true },
		{ key: "outBatch", name: "Out", sortable: true },
		{ key: "separator", name: "", sortable: false },
		{ key: "lenderName", name: "Credit Union", sortable: true },
		{ key: "borrowerName", name: "Borrower ( F/L )", sortable: true },
		{
			key: "disbursedAmountFormatted",
			name: "Sch Disb Amt",
			sortable: true,
		},
		{ key: "disbursementMethod", name: "Disb Method", sortable: true },
		{ key: "transactionDateFormatted", name: "Disb Date", sortable: true },
		{ key: "referenceId", name: "Reference Id#", sortable: true },
		{
			key: "lenderUseOnly",
			name: "Lender Use (LoanId + DrawId + Disb Id#)",
			sortable: true,
		},
		{ key: "sequenceNumber", name: "Disb Seq Id#", sortable: true },
	];
});

const sortedDisbursements = computed(() => {
	if (!batchData.value?.disbursements) return [];
	const disbursements = [...batchData.value.disbursements];
	disbursements.sort((a, b) => {
		const key = sortColumn.value as keyof typeof a;
		let aValue = a[key];
		let bValue = b[key];

		if (key === "outBatch") {
			aValue = !a.inBatch;
			bValue = !b.inBatch;
		}

		if (typeof aValue === "number" && typeof bValue === "number") {
			return ascending.value ? aValue - bValue : bValue - aValue;
		} else if (typeof aValue === "string" && typeof bValue === "string") {
			return ascending.value
				? aValue.localeCompare(bValue)
				: bValue.localeCompare(aValue);
		} else if (typeof aValue === "boolean" && typeof bValue === "boolean") {
			if (aValue !== bValue) {
				return ascending.value ? (aValue ? 1 : -1) : aValue ? -1 : 1;
			}
		}
		return 0;
	});
	return disbursements;
});

async function handleDisbursementChange(disbursement: any, add: boolean) {
	try {
		if (add) {
			await axios.post(
				`/api/disbursementbatches/${batchId.value}/disbursement/${disbursement.id}`
			);
			console.log("Add disbursement response:");
			disbursement.inBatch = true;
		} else {
			await axios.delete(
				`/api/disbursementbatches/${batchId.value}/disbursement/${disbursement.id}`
			);
			disbursement.inBatch = false;
		}
	} catch (error) {
		console.error("failed to remove disbursement from batch:", error);
	}
}

async function approveDisbursements() {
	if (batchData.value) {
		try {
			const approvedByUserName = `${firstName.value}${lastName.value}`;
			const response = await axios.post(
				`/api/disbursementbatches/${batchId.value}/approve`,
				{
					batchId: batchId.value,
					approvedByUserId: store.state.oidcStore.user.sub,
					approvedByUserName: approvedByUserName,
				}
			);

			console.log("Approval response:", response.data);
			functions.openModal({
				title: "Success!",
				description: "The disbursement batch has been saved",
			});
			goBack();
		} catch (error) {
			functions.openModal({
				title: "Error",
				description:
					"There was an issue approving the disbursement batch. Please verify that it has not been approved already.",
			});
		}
	} else {
		console.error("batchData.value is null");
	}
}

function toggleSort(column: string) {
	if (sortColumn.value === column) {
		ascending.value = !ascending.value;
	} else {
		sortColumn.value = column;
		ascending.value = true;
	}
}

onMounted(async () => {
	const batchNumber = route.params.batchNumber;
	if (!batchNumber) {
		console.error("batchNumber is null");
		return;
	}
	try {
		const response = await axios.get(`/api/disbursementbatches/${batchNumber}`);
		batchData.value = response.data;
		batchesStore.setBatchData(batchData.value);
	} catch (error) {
		console.error("failed to load batch data:", error);
	}
});

onUnmounted(() => {
	batchesStore.clearBatchData();
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (loading.value)
      ? (_openBlock(), _createBlock(Loader, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(Page, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_router_link, {
              to: "/",
              class: "icon-home"
            }),
            _cache[1] || (_cache[1] = _createElementVNode("span", null, ">", -1)),
            _createVNode(_component_router_link, { to: `/batches` }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("Batches")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", { class: "xpage-inner batch-edit-title" }, [
              _createElementVNode("h2", null, _toDisplayString(pageName))
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_6, [
                    _cache[2] || (_cache[2] = _createElementVNode("span", { class: "batch-title" }, "Batch ID:", -1)),
                    _createTextVNode(" " + _toDisplayString(batchData.value?.disbursementBatch.id), 1)
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _cache[3] || (_cache[3] = _createElementVNode("span", { class: "batch-title" }, "Created:", -1)),
                    _createTextVNode(" " + _toDisplayString(batchData.value?.disbursementBatch.createdAtUtcFormatted), 1)
                  ])
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_8, [
                    _cache[4] || (_cache[4] = _createElementVNode("span", { class: "batch-title" }, "Approved By:", -1)),
                    _createTextVNode(" " + _toDisplayString(batchData.value?.disbursementBatch.approvedBy), 1)
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _cache[5] || (_cache[5] = _createElementVNode("span", { class: "batch-title" }, "Approved Date:", -1)),
                    _createTextVNode(" " + _toDisplayString(batchData.value?.disbursementBatch.approvedAtUtcFormatted), 1)
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _cache[6] || (_cache[6] = _createElementVNode("span", { class: "batch-title" }, "Prefund:", -1)),
                    _createTextVNode(" " + _toDisplayString(batchData.value?.disbursementBatch.prefundReportDateFormatted), 1)
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    _cache[7] || (_cache[7] = _createElementVNode("span", { class: "batch-title" }, "Disb Rpt:", -1)),
                    _createTextVNode(" " + _toDisplayString(batchData.value?.disbursementBatch.disbursementReportDateFormatted), 1)
                  ]),
                  _createElementVNode("div", _hoisted_15, [
                    _cache[8] || (_cache[8] = _createElementVNode("span", { class: "batch-title" }, "Acct Rpt:", -1)),
                    _createTextVNode(" " + _toDisplayString(batchData.value?.disbursementBatch.accountingReportDateFormatted), 1)
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_16, [
              (loading.value)
                ? (_openBlock(), _createBlock(Loader, { key: 0 }))
                : _createCommentVNode("", true),
              _createElementVNode("div", null, [
                _createVNode(PermissionsWrapper, {
                  showDisabledIfNotVisible: true,
                  permissionIds: [_unref(Permissions).DisbursementBatches],
                  permissionLevel: _unref(PermissionLevels).Update
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("table", _hoisted_17, [
                      _createElementVNode("thead", null, [
                        _createElementVNode("tr", null, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(columnHeaders.value, (header) => {
                            return (_openBlock(), _createElementBlock("th", {
                              key: header.key,
                              onClick: ($event: any) => (toggleSort(header.key)),
                              class: _normalizeClass({
												clickable_row: header.sortable,
												'padded-header':
													header.key === 'inBatch' || header.key === 'outBatch',
											})
                            }, [
                              _createElementVNode("div", _hoisted_19, [
                                _createElementVNode("div", _hoisted_20, _toDisplayString(header.name), 1)
                              ]),
                              (header.key === 'separator')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_21))
                                : _createCommentVNode("", true)
                            ], 10, _hoisted_18))
                          }), 128))
                        ])
                      ]),
                      _createElementVNode("tbody", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sortedDisbursements.value, (disbursement, index) => {
                          return (_openBlock(), _createElementBlock("tr", {
                            key: index,
                            onClick: ($event: any) => (navigateToDisbursement(disbursement.referenceId)),
                            class: "clickableRow"
                          }, [
                            _createElementVNode("td", null, [
                              _createElementVNode("input", {
                                disabled: isApproved.value || disbursement.inBatch,
                                class: "radio-in",
                                type: "radio",
                                checked: disbursement.inBatch,
                                onClick: _withModifiers(($event: any) => (
													handleDisbursementChange(disbursement, true)
												), ["stop"])
                              }, null, 8, _hoisted_23)
                            ]),
                            _createElementVNode("td", null, [
                              _createElementVNode("input", {
                                disabled: isApproved.value || !disbursement.inBatch,
                                class: "radio-out",
                                type: "radio",
                                checked: !disbursement.inBatch,
                                onClick: _withModifiers(($event: any) => (
													handleDisbursementChange(disbursement, false)
												), ["stop"])
                              }, null, 8, _hoisted_24)
                            ]),
                            _cache[9] || (_cache[9] = _createElementVNode("td", { class: "separator" }, null, -1)),
                            _createElementVNode("td", null, _toDisplayString(_unref(functions).getLenderName(disbursement.lenderId)), 1),
                            _createElementVNode("td", null, _toDisplayString(disbursement.borrowerName), 1),
                            _createElementVNode("td", null, _toDisplayString(disbursement.disbursedAmountFormatted), 1),
                            _createElementVNode("td", null, _toDisplayString(disbursement.disbursementMethod), 1),
                            _createElementVNode("td", null, _toDisplayString(disbursement.transactionDateFormatted), 1),
                            _createElementVNode("td", null, _toDisplayString(disbursement.referenceId), 1),
                            _createElementVNode("td", null, _toDisplayString(disbursement.lenderUseOnly), 1),
                            _createElementVNode("td", null, _toDisplayString(disbursement.sequenceNumber), 1)
                          ], 8, _hoisted_22))
                        }), 128))
                      ])
                    ])
                  ]),
                  _: 1
                }, 8, ["permissionIds", "permissionLevel"])
              ]),
              _createElementVNode("div", _hoisted_25, [
                _createVNode(PermissionsButton, {
                  permissionIds: [_unref(Permissions).DisbursementBatches],
                  permissionLevel: _unref(PermissionLevels).Update,
                  onClick: goBack,
                  class: "xedit"
                }, {
                  default: _withCtx(() => _cache[10] || (_cache[10] = [
                    _createTextVNode(" Back ")
                  ])),
                  _: 1
                }, 8, ["permissionIds", "permissionLevel"]),
                _createVNode(PermissionsButton, {
                  disabled: isApproved.value,
                  permissionIds: [_unref(Permissions).DisbursementBatches],
                  permissionLevel: _unref(PermissionLevels).Update,
                  onClick: approveDisbursements,
                  class: "xedit"
                }, {
                  default: _withCtx(() => _cache[11] || (_cache[11] = [
                    _createTextVNode(" Approve ")
                  ])),
                  _: 1
                }, 8, ["disabled", "permissionIds", "permissionLevel"])
              ])
            ])
          ])
        ]),
        _: 1
      })
    ])
  ], 64))
}
}

})