import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, vModelText as _vModelText } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "app-credit-decision" }
const _hoisted_3 = { class: "app-credit-decision-value" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  class: "xusericon",
  "data-size": "small"
}
const _hoisted_6 = { class: "xusericon-picture" }
const _hoisted_7 = { class: "testbox-outer" }
const _hoisted_8 = { class: "testbox-name" }
const _hoisted_9 = { class: "testbox-type" }
const _hoisted_10 = { class: "columns-container" }
const _hoisted_11 = {
  key: 0,
  class: "left-column"
}
const _hoisted_12 = { class: "data-box-top" }
const _hoisted_13 = { class: "credit-score-section" }
const _hoisted_14 = { class: "credit-score" }
const _hoisted_15 = { class: "data-box-top" }
const _hoisted_16 = {
  class: "status-section",
  style: {"margin-top":"-15px"}
}
const _hoisted_17 = ["disabled"]
const _hoisted_18 = { class: "data-box-kos" }
const _hoisted_19 = { class: "ko-number" }
const _hoisted_20 = { class: "ko-number" }
const _hoisted_21 = {
  class: "w-6 h-6",
  fill: "none",
  stroke: "currentColor",
  viewBox: "0 0 24 24",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_22 = {
  class: "w-6 h-6",
  fill: "none",
  stroke: "currentColor",
  viewBox: "0 0 24 24",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_23 = { class: "data-box-kos" }
const _hoisted_24 = { class: "ko-number" }
const _hoisted_25 = { class: "ko-number" }
const _hoisted_26 = { class: "data-box-kos" }
const _hoisted_27 = { class: "factor-number" }
const _hoisted_28 = { class: "factor-number" }
const _hoisted_29 = { class: "right-column" }
const _hoisted_30 = {
  key: 0,
  class: "right-container"
}
const _hoisted_31 = { class: "credit-createDate" }
const _hoisted_32 = { class: "textbox-container" }

import { computed, ref, onMounted } from "vue";
  import useFormatter from "@/use/formatter";
  import CreditKnockouts from "@/components/credit/CreditKnockouts.vue";
  import CreditKnockoutsFactors from "@/components/credit/CreditKnockoutsFactors.vue";
  import CreditReissue from "@/components/credit/CreditReissue.vue";
  import CreditLiabilities from "./CreditLiabilities.vue";
  import Loader from "@/components/Loader.vue";
  import { Permissions, PermissionLevels } from "@/models/permissions";
  import PermissionsWrapper from "@/components/wrappers/PermissionsWrapper.vue";

  interface Props {
    applicants: any;
    application: any;
    creditKnockouts: any[] | null;
    creditKnockoutsFactors: any[] | null;
    creditReport: any;
    getCreditReport: any;
    getCreditsByApplication: any;
    credits: any;
    store: any;
    canEdit: boolean;
  }

  
export default /*@__PURE__*/_defineComponent({
  __name: 'CreditResults',
  props: {
    applicants: {},
    application: {},
    creditKnockouts: {},
    creditKnockoutsFactors: {},
    creditReport: {},
    getCreditReport: {},
    getCreditsByApplication: {},
    credits: {},
    store: {},
    canEdit: { type: Boolean }
  },
  emits: [
    "select-applicant",
    "toggleOpen",
    "setLiabilities",
    "getCreditReportData",
    "applicantLiabilityUpdated",
  ],
  setup(__props: any, { emit: __emit }) {

  const reloadKey = ref(0);
  const { formatDateTime } = useFormatter();
  const props = __props;
  const emit = __emit;

  const creditKnockouts = ref<any[]>([]);
  const tradesOpen = ref<boolean>(true);
  const isApplicantSelected = ref<string[]>([]);
  const isDataLoaded = ref(false);

  const selectedApplicant = computed(
    () => props.application?.creditReport?.[props.applicants?.selected]
  );

  const selectedApplicantFullName = computed(() => {
    return props.applicants?.[props.applicants?.selected]?.fullName;
  });

  const filteredApplicants = computed(() => {
    return ["primary", "secondary"].filter((type) => {
      return (
        props.applicants &&
        props.applicants[type] &&
        (props.applicants[type]?.initials || props.applicants[type]?.fullName)
      );
    });
  });

  const decisionMapping = {
    1: { label: "Hard Decline", color: "#b02a37", textColor: "white" },
    2: { label: "Soft Decline", color: "#ffc0cb", textColor: "black" },
    3: { label: "Evaluate", color: "#ffb800", textColor: "white" },
    4: {
      label: "Conditionally Approved",
      color: "#0ecb50",
      textColor: "white",
    },
  };

  const buttonConfig = computed(() => {
    let defaultDecisionId =
      selectedApplicant.value?.creditReport?.decisionTypeId ?? "N/A";

    if (
      props.application.creditData !== undefined &&
      props.application.creditData.length > 0
    ) {
      const applicantId = props.applicants?.[props.applicants?.selected]?.id;
      const creditData =
        props.application.creditData[0].applicantCredits.filter(
          (x: any) => x.applicantId === applicantId
        );

      if (creditData.length > 0) {
        defaultDecisionId = creditData[0].creditStatusId;
      }
    }

    return (
      decisionMapping[defaultDecisionId] ?? {
        label: "N/A",
        color: "#000",
        textColor: "white",
        disabled: true,
      }
    );
  });

  const humanReadableReport = computed(() => {
    const newValue =
      props.application?.creditReport[props.applicants?.selected]
        ?.humanReadableReport;
    if (newValue !== undefined) {
      return newValue;
    } else {
      console.log("newValue is undefined or not present");
      return newValue ?? "";
    }
  });

  // Filtering knockOutId #22 - Score from total Knockouts
  const getKnockouts = (): any[] | null => {
    const selectedType = props.applicants?.selected;
    const allKnockouts =
      props.application?.creditReport?.[selectedType]?.creditKnockouts || [];

    const filteredKnockouts = allKnockouts.filter(
      (knockout: any) =>
        knockout.knockOutId !== 22 && knockout.knockoutCode !== "Score"
    );
    return filteredKnockouts.length > 0 ? filteredKnockouts : null;
  };

  const getKnockoutsFactors = (): any[] => {
    const selectedType = props.applicants?.selected;
    return (
      props.application?.creditReport?.[selectedType]?.creditKnockoutsFactors ||
      null
    );
  };

  const getLiabilities = (): any[] => {
    const selectedType = props.applicants?.selected;
    return (
      props.application?.creditReport?.[selectedType]?.applicantLiabilities ||
      null
    );
  };

  const liabilitiesCount = computed(() => {
    const liabilities = getLiabilities();
    return liabilities ? liabilities.length : 0;
  });

  const creditKnockoutsCount = computed(() => {
    const knockouts = getKnockouts();
    // console.log("knockouts", knockouts); //Filtered
    return knockouts ? knockouts.length : 0;
  });

  const creditKnockoutsFactorsCount = computed(() => {
    const knockoutsFactors = getKnockoutsFactors();
    return knockoutsFactors ? knockoutsFactors.length : 0;
  });

  const handleToggleOpen = (index) => {
    const knockouts = getKnockouts();
    if (knockouts && knockouts[index]) {
      knockouts[index].open = !knockouts[index].open;
    } else {
      console.log("knockout factors not found");
      return;
    }
  };

  onMounted(async () => {
    const { data, applicants } = props.application;

    if (data.id) {
      isDataLoaded.value = false;
      if (applicants) {
        isApplicantSelected.value = ["primary", "secondary"].filter(
          (type) => !!applicants[type] != null
        );
      }
      try {
        (await props.getCreditsByApplication(data.id)) || [];
        isDataLoaded.value = true;
      } catch (error) {
        console.log(error);
      }
    }
  });

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "xpage-content",
    key: reloadKey.value
  }, [
    (!isDataLoaded.value)
      ? (_openBlock(), _createBlock(Loader, { key: 0 }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.application?.creditReport?.[_ctx.applicants?.selected])
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("p", _hoisted_2, [
                  _cache[5] || (_cache[5] = _createTextVNode(" Application Credit Decision: ")),
                  _createElementVNode("span", _hoisted_3, _toDisplayString(props.application.creditData?.[0].creditDecisionStatusTypeName), 1)
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", null, [
            _cache[14] || (_cache[14] = _createElementVNode("h3", { class: "smallcapitals" }, "Select an Applicant:", -1)),
            _createElementVNode("div", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredApplicants.value, (person, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: _normalizeClass(["xnamebutton", { xselected: _ctx.applicants.selected == person }]),
                  onClick: ($event: any) => (emit('select-applicant', person))
                }, [
                  _createElementVNode("h4", null, [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.applicants[person]?.initials), 1),
                      _cache[6] || (_cache[6] = _createElementVNode("div", {
                        class: "xusericon-gender",
                        "data-gender": ""
                      }, null, -1))
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.applicants[person]?.fullName), 1),
                      _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.applicants[person]?.applicantTypeName), 1)
                    ])
                  ])
                ], 10, _hoisted_4))
              }), 128))
            ]),
            _cache[15] || (_cache[15] = _createElementVNode("div", { class: "xform-section-credit" }, null, -1)),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", null, [
                (_ctx.application?.creditReport?.[_ctx.applicants?.selected])
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("div", _hoisted_13, [
                          _cache[7] || (_cache[7] = _createElementVNode("div", { class: "credit-score-header" }, "Credit Score:", -1)),
                          _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.application?.creditReport?.[_ctx.applicants?.selected]
                        ?.creditScore || "N/A"), 1)
                        ])
                      ]),
                      _cache[13] || (_cache[13] = _createElementVNode("div", { class: "form-section-data-top" }, null, -1)),
                      _createElementVNode("div", _hoisted_15, [
                        _createElementVNode("div", _hoisted_16, [
                          _cache[8] || (_cache[8] = _createElementVNode("div", { class: "status-text" }, "Credit Status:", -1)),
                          _createElementVNode("div", {
                            class: "status-button-credit",
                            style: _normalizeStyle([{"margin-top":"10px"}, {
                      backgroundColor: buttonConfig.value.color,
                      color: buttonConfig.value.textColor,
                    }]),
                            disabled: buttonConfig.value.disabled
                          }, _toDisplayString(buttonConfig.value.label), 13, _hoisted_17)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_18, [
                        _createElementVNode("div", _hoisted_19, " Liabilities (" + _toDisplayString(liabilitiesCount.value) + " total) ", 1),
                        _createElementVNode("div", _hoisted_20, [
                          _createElementVNode("button", {
                            class: "koButton",
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (tradesOpen.value = !tradesOpen.value))
                          }, [
                            _withDirectives((_openBlock(), _createElementBlock("svg", _hoisted_21, _cache[9] || (_cache[9] = [
                              _createElementVNode("path", {
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round",
                                "stroke-width": "2",
                                d: "M20 12H4"
                              }, null, -1)
                            ]), 512)), [
                              [_vShow, tradesOpen.value]
                            ]),
                            _withDirectives((_openBlock(), _createElementBlock("svg", _hoisted_22, _cache[10] || (_cache[10] = [
                              _createElementVNode("path", {
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round",
                                "stroke-width": "2",
                                d: "M12 6v6m0 0v6m0-6h6m-6 0H6"
                              }, null, -1)
                            ]), 512)), [
                              [_vShow, !tradesOpen.value]
                            ])
                          ])
                        ])
                      ]),
                      _createVNode(PermissionsWrapper, {
                        disabled: !_ctx.canEdit,
                        showDisabledIfNotVisible: true,
                        permissionIds: [_unref(Permissions).Credit],
                        permissionLevel: _unref(PermissionLevels).Update
                      }, {
                        default: _withCtx(() => [
                          (tradesOpen.value)
                            ? (_openBlock(), _createBlock(CreditLiabilities, {
                                key: 0,
                                applicants: _ctx.applicants,
                                application: _ctx.application,
                                onSetLiabilities: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('setLiabilities', $event))),
                                onGetCreditReportData: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('getCreditReportData'))),
                                onApplicantLiabilityUpdated: _cache[3] || (_cache[3] = ($event: any) => (
                    _ctx.$emit('applicantLiabilityUpdated')
                  ))
                              }, null, 8, ["applicants", "application"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["disabled", "permissionIds", "permissionLevel"]),
                      _createElementVNode("div", _hoisted_23, [
                        _createElementVNode("div", _hoisted_24, [
                          _createVNode(_component_font_awesome_icon, {
                            icon: ['fas', 'flag'],
                            class: "ko-icon"
                          }),
                          _cache[11] || (_cache[11] = _createTextVNode(" Knockouts "))
                        ]),
                        _createElementVNode("div", _hoisted_25, _toDisplayString(creditKnockoutsCount.value) + " total", 1)
                      ]),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.application?.creditReport?.[
                  _ctx.applicants?.selected
                ]?.creditKnockouts, (knockout, index) => {
                        return (_openBlock(), _createBlock(CreditKnockouts, {
                          key: index,
                          knockout: knockout,
                          open: knockout.open,
                          onToggleOpen: ($event: any) => (handleToggleOpen(index)),
                          creditKnockouts: creditKnockouts.value
                        }, null, 8, ["knockout", "open", "onToggleOpen", "creditKnockouts"]))
                      }), 128)),
                      _createElementVNode("div", _hoisted_26, [
                        _createElementVNode("div", _hoisted_27, [
                          _createVNode(_component_font_awesome_icon, {
                            icon: ['fas', 'flag'],
                            class: "factor-icon"
                          }),
                          _cache[12] || (_cache[12] = _createTextVNode(" Score Factors "))
                        ]),
                        _createElementVNode("div", _hoisted_28, _toDisplayString(creditKnockoutsFactorsCount.value) + " total ", 1)
                      ]),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.application?.creditReport?.[
                  _ctx.applicants?.selected
                ]?.creditKnockoutsFactors, (knockoutFactors, index) => {
                        return (_openBlock(), _createBlock(CreditKnockoutsFactors, {
                          key: index,
                          knockoutFactors: knockoutFactors,
                          creditKnockoutsFactors: _ctx.creditKnockoutsFactors,
                          knockoutFactorsDescription: 
                  knockoutFactors.factorDescription
                
                        }, null, 8, ["knockoutFactors", "creditKnockoutsFactors", "knockoutFactorsDescription"]))
                      }), 128)),
                      _createVNode(CreditReissue, {
                        application: _ctx.application,
                        applicant: _ctx.applicants?.selected,
                        applicants: _ctx.applicants,
                        canEdit: _ctx.canEdit,
                        applicantFullName: selectedApplicantFullName.value,
                        creditId: 
                    _ctx.application?.creditReport?.[_ctx.applicants?.selected]
                      ?.creditReport?.id
                  ,
                        knockoutCode: 
                    _ctx.application?.creditReport?.[_ctx.applicants?.selected]
                      ?.creditReport?.knockoutCode
                  ,
                        store: _ctx.store
                      }, null, 8, ["application", "applicant", "applicants", "canEdit", "applicantFullName", "creditId", "knockoutCode", "store"])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_29, [
                (_ctx.application?.creditReport?.[_ctx.applicants?.selected])
                  ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                      _createElementVNode("div", _hoisted_31, " Credit as of: " + _toDisplayString(_unref(formatDateTime)(
                    _ctx.application?.creditReport?.[_ctx.applicants?.selected]
                      .creditReport.createdAtUtc
                  ) || "Credit report not found") + " for " + _toDisplayString(_ctx.applicants[_ctx.applicants.selected]?.fullName), 1),
                      _createElementVNode("div", _hoisted_32, [
                        _withDirectives(_createElementVNode("textarea", {
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((humanReadableReport).value = $event)),
                          readonly: "",
                          disabled: "",
                          class: "custom-textarea"
                        }, "                ", 512), [
                          [_vModelText, humanReadableReport.value]
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ], 64))
  ]))
}
}

})