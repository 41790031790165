import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "actions-container" }
const _hoisted_3 = {
  key: 0,
  class: "spinner-container"
}
const _hoisted_4 = {
  key: 1,
  class: "table-wrapper"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "header-label" }
const _hoisted_7 = { key: 0 }

import { onMounted, ref, computed } from 'vue';
	import { useRoute, useRouter } from 'vue-router';
	import axios from 'axios';
	import functions from '@/use/functions';
	import Spinner from '@/components/shared/Spinner.vue';
	import DocumentRow from '@/components/loan/documents/DocumentRow.vue';
	import { DocumentArrowUpIcon, ArrowPathIcon } from '@heroicons/vue/24/solid';
	import UploadDocumentModal from '@/components/loan/documents/UploadDocumentModal.vue';
	import PermissionsWrapper from '@/components/wrappers/PermissionsWrapper.vue';
	import { Permissions, PermissionLevels } from '@/models/permissions';
	import { Borrower, LoanDocument } from '@/models/loans';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'DocumentsTab',
  props: {
    loanId: {},
    borrowers: {}
  },
  setup(__props: any) {

	const props = __props;

	const route = useRoute();
	const router = useRouter();

	const isLoading = ref<boolean>(false);
	const documents = ref<LoanDocument[]>([]);
	const isUploadModalOpen = ref(false);

	const headers = ref([
		{ label: 'Name', sortable: true, key: 'name' },
		{ label: 'Upload Date & Time', sortable: true, key: 'uploadAtUtcFormatted' },
		{ label: 'Document Type', sortable: true, key: 'documentTypeHumanized' },
		{ label: 'Borrower', sortable: true, key: 'borrowerId' },
		{ label: '', sortable: false, key: '', class: 'no-hover' }
	]);

	const closeUploadModal = () => (isUploadModalOpen.value = false);

	const sortKey = ref('');
	const sortOrder = ref(1);

	const sortedDocuments = computed(() => {
		if (!sortKey.value) {
			return documents.value; // no sorting, return original order
		}

		return [...documents.value].sort((a, b) => {
			let valA = a ? a[sortKey.value] : null;
			let valB = b ? b[sortKey.value] : null;

			if (typeof valA === 'string') {
				valA = valA.toLowerCase();
			}

			if (typeof valB === 'string') {
				valB = valB.toLowerCase();
			}

			if (valA < valB) {
				return -1 * sortOrder.value;
			} else if (valA > valB) {
				return 1 * sortOrder.value;
			}

			return 0;
		});
	});

	const sortBy = (key: string) => {
		if (sortKey.value === key) {
			if (sortOrder.value === 1) {
				sortOrder.value = -1; // second click, descending
			} else if (sortOrder.value === -1) {
				sortKey.value = ''; // third click, reset sort
				sortOrder.value = 1; // default sort order for the next click
			}
		} else {
			sortKey.value = key;
			sortOrder.value = 1; // first click, ascending
		}

		router.push({
			query: {
				...route.query,
				documentsSortKey: sortKey.value,
				documentsSortOrder: sortOrder.value.toString()
			},
			hash: route.hash
		});
	};

	const getSortArrow = (key: string) => {
		if (sortKey.value === key) {
			return sortOrder.value === 1 ? '▲' : sortOrder.value === -1 ? '▼' : ' ';
		}

		return '';
	};

	const isActiveSort = (key: string) => (sortKey.value === key ? 'active-sort' : '');

	async function getDocumentsAsync() {
		isLoading.value = true;

		try {
			const response = await axios(`/api/loandocuments/loan/${props.loanId}`);

			documents.value = response.data;
		} catch (error) {
			functions.openModal({
				title: 'Oops!',
				description: 'There was an issue getting the loan documents. Please try again later.'
			});
		} finally {
			isLoading.value = false;
		}
	}

	function intializeSorting() {
		const querySortKey = route.query.documentsSortKey as string;
		const querySortOrder = parseInt(route.query.documentsSortOrder as string, 10);

		if (querySortKey) {
			sortKey.value = querySortKey;
		}

		if (!isNaN(querySortOrder) && (querySortOrder === 1 || querySortOrder === -1)) {
			sortOrder.value = querySortOrder;
		}
	}

	onMounted(async () => {
		intializeSorting();

		await getDocumentsAsync();
	});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(PermissionsWrapper, {
        permissionIds: [_unref(Permissions).DocumentProcessing],
        permissionLevel: _unref(PermissionLevels).Create
      }, {
        default: _withCtx(() => [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (isUploadModalOpen.value = true))
          }, [
            _createVNode(_unref(DocumentArrowUpIcon), { class: "action-icon" }),
            _cache[1] || (_cache[1] = _createTextVNode(" Upload Document "))
          ])
        ]),
        _: 1
      }, 8, ["permissionIds", "permissionLevel"]),
      _createElementVNode("button", {
        onClick: getDocumentsAsync,
        class: "secondary"
      }, [
        _createVNode(_unref(ArrowPathIcon), { class: "action-icon" }),
        _cache[2] || (_cache[2] = _createTextVNode(" Refresh "))
      ])
    ]),
    (isLoading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(Spinner)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("table", null, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(headers.value, (header) => {
                  return (_openBlock(), _createElementBlock("th", {
                    key: header.key,
                    class: _normalizeClass([header.class, isActiveSort(header.key)]),
                    onClick: ($event: any) => (sortBy(header.key))
                  }, [
                    _createElementVNode("span", _hoisted_6, [
                      _createTextVNode(_toDisplayString(header.label) + " ", 1),
                      (header.sortable)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(getSortArrow(header.key)), 1))
                        : _createCommentVNode("", true)
                    ])
                  ], 10, _hoisted_5))
                }), 128))
              ])
            ]),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sortedDocuments.value, (document) => {
                return (_openBlock(), _createBlock(DocumentRow, {
                  key: document.id,
                  document: document,
                  borrowers: _ctx.borrowers,
                  onDocumentChangedAsync: getDocumentsAsync
                }, null, 8, ["document", "borrowers"]))
              }), 128))
            ])
          ])
        ])),
    (isUploadModalOpen.value)
      ? (_openBlock(), _createBlock(UploadDocumentModal, {
          key: 2,
          loanId: _ctx.loanId,
          borrowers: _ctx.borrowers,
          onClose: closeUploadModal
        }, null, 8, ["loanId", "borrowers"]))
      : _createCommentVNode("", true)
  ]))
}
}

})