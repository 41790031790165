import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = { class: "xform-section" }
const _hoisted_3 = { class: "xfields" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { class: "xform-section" }
const _hoisted_6 = { class: "xfields" }
const _hoisted_7 = {
  class: "submit",
  type: "submit"
}

import { computed, reactive, onMounted, watch, ref } from "vue";
import { Applicant, LOSType } from "@/models/opal";
import { FieldSchema } from "@/models/form";
import BaseFormField from "@/components/form-fields/BaseFormField.vue";
import BaseFormFieldCheckbox from "@/components/form-fields/BaseFormField.vue";
import { onBeforeMount } from "vue";
import useFunctions from "@/use/functions1";
import Loader from "@/components/Loader.vue";
import { Permissions, PermissionLevels } from "@/models/permissions";
import PermissionsWrapper from "@/components/wrappers/PermissionsWrapper.vue";

interface Props {
  canEdit: boolean;
  disabled: boolean;
  applicant?: Applicant;
  membershipTypes: LOSType[];
  applicants?: any;
  primaryApplicant?: Applicant | any;
  secondaryApplicant?: Applicant | any;
  cancelBtnClicked: boolean;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'MembershipForm',
  props: {
    canEdit: { type: Boolean },
    disabled: { type: Boolean, default: false },
    applicant: {},
    membershipTypes: {},
    applicants: {},
    primaryApplicant: {},
    secondaryApplicant: {},
    cancelBtnClicked: { type: Boolean }
  },
  emits: ["submitForm", "toggle"],
  setup(__props: any, { emit: __emit }) {

const { sortLosTypesByName } = useFunctions();

const props = __props;

const emit = __emit;
const primaryApplicantMembership = reactive({}) as any;
const secondaryApplicantMembership = reactive({}) as any;
const loading = ref(false);

const sortedMembershipOptions = computed(() =>
  sortLosTypesByName(props?.membershipTypes)
);
const membershipOptions = computed(() => {
  const options = sortedMembershipOptions?.value?.map((membership: any) => {
    return {
      label: membership.name,
      value: membership.id,
    };
  });
  return options;
});
let membershipSchemas = reactive({} as any);

onBeforeMount(() => {
  ["primary", "secondary"].forEach((t: string) => {
    membershipSchemas[t] = {
      memberId: {
        name: "memberId",
        type: "text",
        label: "Member ID",
        required: false,
      } as FieldSchema,
      membershipStatusId: {
        name: "membershipStatusId",
        type: "select",
        label: "Membership Status",
        required: false,
      } as FieldSchema,
      isJointOnStudentAccount: {
        name: "isJointOnStudentAccount",
        type: "checkbox",
        label: "Is Joint on Student Account",
        required: false,
      },
    };
  });
});

function setMembershipInfo() {
  if (props?.primaryApplicant) {
    primaryApplicantMembership.applicantId = props?.primaryApplicant?.id;
    primaryApplicantMembership.memberId =
      props?.primaryApplicant?.membership?.memberId;
    primaryApplicantMembership.membershipStatusId =
      props?.primaryApplicant?.membership?.membershipStatusId;
    primaryApplicantMembership.isMember =
      props?.primaryApplicant?.membership?.isMember;
    primaryApplicantMembership.isJointOnStudentAccount =
      props?.primaryApplicant?.membership?.isJointOnStudentAccount;
  }
  if (props?.secondaryApplicant) {
    secondaryApplicantMembership.applicantId = props?.secondaryApplicant?.id;
    secondaryApplicantMembership.memberId =
      props?.secondaryApplicant?.membership?.memberId;
    secondaryApplicantMembership.membershipStatusId =
      props?.secondaryApplicant?.membership?.membershipStatusId;
    secondaryApplicantMembership.isMember =
      props?.secondaryApplicant?.membership?.isMember;
    secondaryApplicantMembership.isJointOnStudentAccount =
      props?.secondaryApplicant?.membership?.isJointOnStudentAccount;
  }
}

function submitForm() {
  loading.value = true;
  emit("submitForm", {
    primaryApplicantMembership,
    secondaryApplicantMembership,
  });
  loading.value = false;
}

function discardChanges() {
  setMembershipInfo();
  emit("toggle");
}

watch(
  () => props?.cancelBtnClicked,
  (value) => {
    if (value === true) {
      discardChanges();
    }
  }
);

onMounted(() => {
  setMembershipInfo();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (loading.value)
      ? (_openBlock(), _createBlock(Loader, {
          key: 0,
          class: "loader-overlay"
        }))
      : _createCommentVNode("", true),
    _createElementVNode("form", {
      onSubmit: _withModifiers(submitForm, ["prevent"]),
      autocomplete: "off"
    }, [
      (_ctx.primaryApplicant)
        ? (_openBlock(), _createElementBlock("fieldset", {
            key: 0,
            disabled: _ctx.disabled
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", null, [
                _createElementVNode("h3", null, _toDisplayString(_ctx.primaryApplicant.applicantTypeName), 1),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(BaseFormField, {
                    schema: _unref(membershipSchemas)['primary'].memberId,
                    modelValue: primaryApplicantMembership.memberId,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((primaryApplicantMembership.memberId) = $event))
                  }, null, 8, ["schema", "modelValue"]),
                  _createVNode(BaseFormField, {
                    schema: _unref(membershipSchemas)['primary'].membershipStatusId,
                    modelValue: primaryApplicantMembership.membershipStatusId,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((primaryApplicantMembership.membershipStatusId) = $event)),
                    options: membershipOptions.value
                  }, null, 8, ["schema", "modelValue", "options"])
                ])
              ])
            ])
          ], 8, _hoisted_1))
        : _createCommentVNode("", true),
      (_ctx.secondaryApplicant)
        ? (_openBlock(), _createElementBlock("fieldset", {
            key: 1,
            disabled: _ctx.disabled
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", null, [
                _createElementVNode("h3", null, _toDisplayString(_ctx.secondaryApplicant.applicantTypeName), 1),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(BaseFormField, {
                    schema: _unref(membershipSchemas)['secondary'].memberId,
                    modelValue: secondaryApplicantMembership.memberId,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((secondaryApplicantMembership.memberId) = $event))
                  }, null, 8, ["schema", "modelValue"]),
                  _createVNode(BaseFormField, {
                    schema: _unref(membershipSchemas)['secondary'].membershipStatusId,
                    modelValue: secondaryApplicantMembership.membershipStatusId,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((secondaryApplicantMembership.membershipStatusId) = $event)),
                    options: membershipOptions.value
                  }, null, 8, ["schema", "modelValue", "options"]),
                  _createVNode(PermissionsWrapper, {
                    disabled: !_ctx.canEdit,
                    showDisabledIfNotVisible: true,
                    permissionIds: [_unref(Permissions).ProofOfMembership],
                    permissionLevel: _unref(PermissionLevels).Update,
                    class: "checkbox-wrapper"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(BaseFormFieldCheckbox, {
                        schema: 
                    _unref(membershipSchemas)['secondary'].isJointOnStudentAccount
                  ,
                        modelValue: secondaryApplicantMembership.isJointOnStudentAccount,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((secondaryApplicantMembership.isJointOnStudentAccount) = $event))
                      }, null, 8, ["schema", "modelValue"])
                    ]),
                    _: 1
                  }, 8, ["disabled", "permissionIds", "permissionLevel"])
                ])
              ])
            ])
          ], 8, _hoisted_4))
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("button", _hoisted_7, "Submit", 512), [
        [_vShow, !_ctx.disabled]
      ])
    ], 32)
  ]))
}
}

})