import { defineComponent as _defineComponent } from 'vue'
import { withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "xpage-overview-collapse" }
const _hoisted_2 = {
  key: 0,
  class: "xpage-overview"
}
const _hoisted_3 = { class: "xpage-overview-cols" }
const _hoisted_4 = { class: "xpage-overview-cols-inner" }
const _hoisted_5 = {
  class: "xpage-overview-col",
  "data-type": "details"
}
const _hoisted_6 = { class: "xdetails-table" }
const _hoisted_7 = { class: "xdetails-col" }
const _hoisted_8 = { class: "xtext" }
const _hoisted_9 = { class: "xdetails-col" }
const _hoisted_10 = { class: "xtext" }
const _hoisted_11 = { class: "xdetails-col" }
const _hoisted_12 = { class: "xtext" }
const _hoisted_13 = { class: "xdetails-col" }
const _hoisted_14 = {
  key: 0,
  class: "xtext"
}
const _hoisted_15 = { class: "xdetails-col" }
const _hoisted_16 = { class: "xtext" }
const _hoisted_17 = { class: "xdetails-col" }
const _hoisted_18 = { class: "xtext" }
const _hoisted_19 = {
  key: 0,
  class: "xpage-overview-col",
  "data-type": "applicant"
}
const _hoisted_20 = { colspan: "2" }
const _hoisted_21 = {
  class: "xusericon",
  "data-size": "small"
}
const _hoisted_22 = { class: "xusericon-picture" }
const _hoisted_23 = { class: "xblackpill" }
const _hoisted_24 = { class: "xname" }
const _hoisted_25 = { class: "xtext" }
const _hoisted_26 = { class: "xtext" }
const _hoisted_27 = {
  class: "xtext",
  style: {"text-transform":"capitalize"}
}
const _hoisted_28 = { class: "xtext" }
const _hoisted_29 = { class: "xtext" }
const _hoisted_30 = { class: "xtext" }
const _hoisted_31 = {
  key: 1,
  class: "xpage-overview-col",
  "data-type": "missing-applicant"
}
const _hoisted_32 = {
  key: 2,
  class: "xpage-overview-col",
  "data-type": "applicant"
}
const _hoisted_33 = { colspan: "2" }
const _hoisted_34 = {
  class: "xusericon",
  "data-size": "small"
}
const _hoisted_35 = { class: "xusericon-picture" }
const _hoisted_36 = { class: "xblackpill" }
const _hoisted_37 = { class: "xname" }
const _hoisted_38 = { class: "xtext" }
const _hoisted_39 = { class: "xtext" }
const _hoisted_40 = {
  class: "xtext",
  style: {"text-transform":"capitalize"}
}
const _hoisted_41 = { class: "xtext" }
const _hoisted_42 = { class: "xtext" }
const _hoisted_43 = { class: "xtext" }
const _hoisted_44 = {
  key: 3,
  class: "xpage-overview-col",
  "data-type": "missing-coapplicant"
}

import { ref, onMounted } from "vue";
import slideDown from "@/components/SlideDown.vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import { useApplicationStore } from "@/store/store/applicationVMStore";


export default /*@__PURE__*/_defineComponent({
  __name: 'ApplicationApplicantDetails',
  setup(__props) {

const applicationStore = useApplicationStore();
const { viewModel } = storeToRefs(applicationStore);

const showDetails = ref(false);
const mobileHidden = ref(false);

const route = useRoute();
const store = useStore();

// const applicantNationalIdLabel = (applicantType: string) =>
// 		(props.applicants[applicantType]?.nationalIdNumberTypeId == NationalIdNumberTypes.SSN) ?
// 		NationalIdNumberTypes[NationalIdNumberTypes.SSN] : NationalIdNumberTypes[NationalIdNumberTypes.ITIN]

const toggleApplicationDetails = () => {
  showDetails.value = !showDetails.value;
  if (localStorage.getItem("cache.applicationDetailsDisplay") == "false") {
    localStorage.setItem("cache.applicationDetailsDisplay", "true");
  } else {
    localStorage.setItem("cache.applicationDetailsDisplay", "false");
  }
};

onMounted(() => {
  if (localStorage.getItem("cache.applicationDetailsDisplay") !== "false") {
    showDetails.value = true;
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("a", {
        href: "#",
        class: _normalizeClass(["xcollapse-desktop", [showDetails.value ? 'icon-up-open-big' : 'icon-down-open-big']]),
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (toggleApplicationDetails()), ["prevent"]))
      }, null, 2)
    ]),
    (_unref(viewModel))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(slideDown, {
            class: _normalizeClass({ xhideformobile: mobileHidden.value })
          }, {
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "xdetails-col-header" }, "Application Details", -1)),
                      _createElementVNode("div", _hoisted_7, [
                        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "xname" }, "ID", -1)),
                        _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(viewModel)?.referenceId), 1)
                      ]),
                      _createElementVNode("div", _hoisted_9, [
                        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "xname" }, "Created", -1)),
                        _createElementVNode("div", _hoisted_10, _toDisplayString(_unref(viewModel)?.created), 1)
                      ]),
                      _createElementVNode("div", _hoisted_11, [
                        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "xname" }, "Expiration", -1)),
                        _createElementVNode("div", _hoisted_12, _toDisplayString(_unref(viewModel)?.expiration), 1)
                      ]),
                      _createElementVNode("div", _hoisted_13, [
                        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "xname" }, "Analyst Recommendation", -1)),
                        (_unref(viewModel)?.applicationDetailsVM?.hasRecommendation)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(_unref(viewModel)?.applicationDetailsVM?.analystRecommendation), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_15, [
                        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "xname" }, "CU Service", -1)),
                        _createElementVNode("div", _hoisted_16, _toDisplayString(_unref(viewModel)?.applicationDetailsVM?.isFullService
                        ? "Full Service"
                        : "Self Service"), 1)
                      ]),
                      _createElementVNode("div", _hoisted_17, [
                        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "xname" }, "Status", -1)),
                        _createElementVNode("div", _hoisted_18, _toDisplayString(_unref(viewModel)?.statusName), 1)
                      ])
                    ])
                  ]),
                  (_unref(viewModel)?.hasPrimaryApplicant)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                        _createElementVNode("table", null, [
                          _createElementVNode("tr", null, [
                            _createElementVNode("td", _hoisted_20, [
                              _createElementVNode("div", _hoisted_21, [
                                _createElementVNode("div", _hoisted_22, _toDisplayString(_unref(viewModel)?.primaryApplicant?.initials), 1),
                                _cache[8] || (_cache[8] = _createElementVNode("div", {
                                  class: "xusericon-gender",
                                  "data-gender": ""
                                }, null, -1))
                              ]),
                              _createTextVNode(" " + _toDisplayString(_unref(viewModel)?.primaryApplicant?.fullName) + " ", 1),
                              _createElementVNode("div", _hoisted_23, _toDisplayString(_unref(viewModel)?.primaryApplicant?.applicantTypeName), 1)
                            ])
                          ]),
                          _createElementVNode("tr", null, [
                            _createElementVNode("td", _hoisted_24, _toDisplayString(_unref(viewModel)?.primaryApplicant?.nationalIdLabel), 1),
                            _createElementVNode("td", _hoisted_25, _toDisplayString(_unref(viewModel)?.primaryApplicant?.nationalIdNumber), 1)
                          ]),
                          _createElementVNode("tr", null, [
                            _cache[9] || (_cache[9] = _createElementVNode("td", { class: "xname" }, "Birthdate", -1)),
                            _createElementVNode("td", _hoisted_26, _toDisplayString(_unref(viewModel)?.primaryApplicant?.birthday), 1)
                          ]),
                          _createElementVNode("tr", null, [
                            _cache[10] || (_cache[10] = _createElementVNode("td", { class: "xname" }, "Address", -1)),
                            _createElementVNode("td", _hoisted_27, _toDisplayString(_unref(viewModel)?.primaryApplicant?.addressDisplay), 1)
                          ]),
                          _createElementVNode("tr", null, [
                            _cache[11] || (_cache[11] = _createElementVNode("td", { class: "xname" }, "Phone", -1)),
                            _createElementVNode("td", _hoisted_28, _toDisplayString(_unref(viewModel)?.primaryApplicant?.phoneDisplay), 1)
                          ]),
                          _createElementVNode("tr", null, [
                            _cache[12] || (_cache[12] = _createElementVNode("td", { class: "xname" }, "Email", -1)),
                            _createElementVNode("td", _hoisted_29, _toDisplayString(_unref(viewModel)?.primaryApplicant?.emailAddress), 1)
                          ]),
                          _createElementVNode("tr", null, [
                            _cache[13] || (_cache[13] = _createElementVNode("td", { class: "xname" }, "Username", -1)),
                            _createElementVNode("td", _hoisted_30, _toDisplayString(_unref(viewModel)?.primaryApplicant?.username), 1)
                          ])
                        ])
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_31, _cache[14] || (_cache[14] = [
                        _createElementVNode("div", null, "This application is missing a Primary Applicant", -1)
                      ]))),
                  (_unref(viewModel)?.hasCoApplicant)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                        _createElementVNode("table", null, [
                          _createElementVNode("tr", null, [
                            _createElementVNode("td", _hoisted_33, [
                              _createElementVNode("div", _hoisted_34, [
                                _createElementVNode("div", _hoisted_35, _toDisplayString(_unref(viewModel)?.coApplicant?.initials), 1),
                                _cache[15] || (_cache[15] = _createElementVNode("div", {
                                  class: "xusericon-gender",
                                  "data-gender": ""
                                }, null, -1))
                              ]),
                              _createTextVNode(" " + _toDisplayString(_unref(viewModel)?.coApplicant?.fullName) + " ", 1),
                              _createElementVNode("div", _hoisted_36, _toDisplayString(_unref(viewModel)?.coApplicant?.applicantTypeName), 1)
                            ])
                          ]),
                          _createElementVNode("tr", null, [
                            _createElementVNode("td", _hoisted_37, _toDisplayString(_unref(viewModel)?.coApplicant?.nationalIdLabel), 1),
                            _createElementVNode("td", _hoisted_38, _toDisplayString(_unref(viewModel)?.coApplicant?.nationalIdNumber), 1)
                          ]),
                          _createElementVNode("tr", null, [
                            _cache[16] || (_cache[16] = _createElementVNode("td", { class: "xname" }, "Birthdate", -1)),
                            _createElementVNode("td", _hoisted_39, _toDisplayString(_unref(viewModel)?.coApplicant?.birthday), 1)
                          ]),
                          _createElementVNode("tr", null, [
                            _cache[17] || (_cache[17] = _createElementVNode("td", { class: "xname" }, "Address", -1)),
                            _createElementVNode("td", _hoisted_40, _toDisplayString(_unref(viewModel)?.coApplicant?.addressDisplay), 1)
                          ]),
                          _createElementVNode("tr", null, [
                            _cache[18] || (_cache[18] = _createElementVNode("td", { class: "xname" }, "Phone", -1)),
                            _createElementVNode("td", _hoisted_41, _toDisplayString(_unref(viewModel)?.coApplicant?.phoneDisplay), 1)
                          ]),
                          _createElementVNode("tr", null, [
                            _cache[19] || (_cache[19] = _createElementVNode("td", { class: "xname" }, "Email", -1)),
                            _createElementVNode("td", _hoisted_42, _toDisplayString(_unref(viewModel)?.coApplicant?.emailAddress), 1)
                          ]),
                          _createElementVNode("tr", null, [
                            _cache[20] || (_cache[20] = _createElementVNode("td", { class: "xname" }, "Username", -1)),
                            _createElementVNode("td", _hoisted_43, _toDisplayString(_unref(viewModel)?.coApplicant?.username), 1)
                          ])
                        ])
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_44, _cache[21] || (_cache[21] = [
                        _createElementVNode("div", null, "This application has no Co-Applicant", -1)
                      ]))),
                  _cache[22] || (_cache[22] = _createElementVNode("div", { class: "clear" }, null, -1))
                ])
              ], 512), [
                [_vShow, showDetails.value]
              ])
            ]),
            _: 1
          }, 8, ["class"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})