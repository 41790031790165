import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "xincome-section total" }
const _hoisted_2 = { class: "amount" }
const _hoisted_3 = { class: "stated-amount" }

import {
  computed,
} from "vue";
import { Applicant } from "@/models/opal";

interface Props {
  applicants: any;
  primaryApplicant: Applicant | any;
  secondaryApplicant: Applicant | any;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'CreditIncome',
  props: {
    applicants: {},
    primaryApplicant: {},
    secondaryApplicant: {}
  },
  setup(__props: any) {

const props = __props;

const verifiedIncome = computed(() => {
  if (props?.applicants?.selected === 'secondary') {
    return props?.secondaryApplicant?.totalVerifiedIncome
      ? `$${props?.secondaryApplicant?.totalVerifiedIncome.toLocaleString(
        "en-US"
      )}`
      : "N/P";
  }
  else if (props?.applicants?.selected === 'primary') {
    return props?.primaryApplicant?.totalVerifiedIncome
      ? `$${props?.primaryApplicant?.totalVerifiedIncome.toLocaleString(
        "en-US"
      )}`
      : "N/P";
  }
  else {
    return `Not Provided`
  }

});
const statedIncome = computed(() => {
  if (props?.applicants?.selected === 'secondary') {
    return props?.secondaryApplicant?.statedIncome
      ? `($${props?.secondaryApplicant?.statedIncome.toLocaleString(
        "en-US"
      )} STATED)`
      : "No Stated Income"
  }
  else if (props?.applicants?.selected === 'primary') {
    return props?.primaryApplicant.statedIncome
      ? `($${props?.primaryApplicant.statedIncome.toLocaleString(
        "en-US"
      )} STATED)`
      : "No Stated Income"
  }
  else {
    return `Not Provided`
  }
});



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", null, _toDisplayString(verifiedIncome.value), 1),
      _cache[0] || (_cache[0] = _createElementVNode("h5", null, "Verified Current Income", -1))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", null, _toDisplayString(statedIncome.value), 1)
    ])
  ]))
}
}

})