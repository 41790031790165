import axios from "axios";
import functions from "@/use/functions.js";
import { RolePermission } from "@/models/permissions";
import { PricingVM } from "@/types/pricing";
import {
  AvailableToDrawAmount,
  CreateDrawRequest,
  DocumentRotateRequest,
  DocumentSplitRequest,
  DocumentSplitResponse,
  Draw,
  EnrollmentTermModel,
  MergeDocumentsRequest,
  UpdateDrawRequest,
  UpdateDrawRequestedAmountRequest,
  UpdateDrawStatusesRequest,
} from "@/models/opal";
import { Esign } from "@/types/esign";
import {
  Credit,
  CreditHistory,
  CreditResponse,
  CreditTab,
} from "@/types/credit";
import { Income } from "@/types/income";
import { Identity } from "@/types/identity";
import { SubmitApplicationReviewRequest } from "@/types/finalReview";
import {
  LinkUnlinkDocumentToRefiLoanRequest,
  AddAttentionToRequest,
  UpdateRefiLoanRequest,
  RefiLoanRequest,
} from "@/types/payoff";
import { ApplicationComment } from "@/types/application";
import useFunctions from "@/use/functions1";
import { TabResponse } from "@/types/tabs";

const { decodeHTMLEncodedStr } = useFunctions();

class BaseApiService {
  // Since .NET us handling the routing, we don't need to include baseUrl
  // All API calls in controllers will start with {api}
  baseUrl = "api";
  resource: string;

  constructor(resource: string) {
    if (!resource) throw new Error("Resource is not provided");
    this.resource = resource;
  }

  getUrl(id = "") {
    return `${window.location.origin}/${this.baseUrl}/${this.resource}/${id}`;
  }

  handleErrors(err: any): Promise<any> {
    // Note: here you may want to add your errors handling
    console.log("ERROR", { err });
    // add error:true at the end in case message is used elsewhere
    if (err.response?.data && typeof err.response.data === "object") {
      return { ...err.response.data, error: true };
    } else {
      return { ...err, error: true };
    }
  }
}

class AccountApiService extends BaseApiService {
  constructor() {
    super("account");
  }

  async signout(unifiId: string): Promise<any> {
    try {
      const response = await axios(this.getUrl(`signout/${unifiId}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}

class ApplicationsService extends BaseApiService {
  constructor() {
    super("applications");
  }

  async getApplicationByRefId(refId: number): Promise<any> {
    try {
      const response = await axios(this.getUrl(`ref/${refId}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getApplicationById(id: number): Promise<any> {
    try {
      const response = await axios(this.getUrl(`${id}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getApplicationCredits(appId: number): Promise<any> {
    try {
      const response = await axios(this.getUrl(`${appId}/credits`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getCreditsByApplication(appId: number): Promise<any> {
    try {
      const response = await axios(this.getUrl(`${appId}/credits`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getProgramLimits(appId: number): Promise<any> {
    try {
      const response = await axios(this.getUrl(`${appId}/programLimits`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getRatePlanByApplication(appId: number): Promise<any> {
    try {
      const response = await axios(this.getUrl(`${appId}/rateplans`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getRatePlanSingleByApplication(id: number): Promise<any> {
    // note this uri looks weird
    try {
      const response = await axios(this.getUrl(`${id}/rateplans/${id}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getAuthorizedThirdParty(appId: number): Promise<any> {
    try {
      const response = await axios(this.getUrl(`${appId}/authorizedusers`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getLoanConfig(id: number): Promise<any> {
    try {
      const response = await axios(this.getUrl(`${id}/loanConfigs`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getInitialDraw(id: number): Promise<any> {
    try {
      const response = await axios(this.getUrl(`${id}/initialdraw`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async migrateToLoanService(appId: number, body: any): Promise<any> {
    try {
      const response = await axios(this.getUrl(`${appId}/migrate`), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async updateAuthorizedThirdParty(appId: number, body: any): Promise<any> {
    try {
      const response = await axios.put(
        this.getUrl(`${appId}/authorizedusers`),
        body
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async updateApplicantMembership(body: any): Promise<any> {
    try {
      const response = await axios.post(this.getUrl(`membership`), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async updateApplicationStatus(appId: number, appStatusId: number, body: any) {
    try {
      const response = await axios.put(
        this.getUrl(`${appId}/status/${appStatusId}`),
        body
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getApplicationJudgementDenials(appId: number) {
    try {
      const response = await axios(this.getUrl(`${appId}/judgmentdenials`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async addOrUpdateJudgementDenials(appId: number, body: any) {
    try {
      const response = await axios.post(
        this.getUrl(`${appId}/judgmentdenials`),
        body
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async addOrUpdateApplicationComment(body: any) {
    try {
      const response = await axios.post(this.getUrl(`comments`), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getApplicationRatePlans(appId: number) {
    try {
      const response = await axios(this.getUrl(`${appId}/rateplans`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async addOrUpdateAppLoanConfig(body: any) {
    try {
      const response = await axios.post(this.getUrl("loanConfig"), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getAppLoanConfig(appId: number) {
    try {
      const response = await axios(this.getUrl(`${appId}/loanConfig`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getApplicationRatePlanByRatePlanId(appId: number, ratePlanId: number) {
    try {
      const response = await axios(
        this.getUrl(`${appId}/RatePlans/${ratePlanId}`)
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getSlimApplication(referenceId: string) {
    try {
      const response = await axios(this.getUrl(`${referenceId}/Slim`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getTabTagsAsync(referenceId: string) {
    try {
      const response = await axios(this.getUrl(`${referenceId}/tab-tags`));

      return response.data || {} as TabResponse;
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getApplicationComments(appId: number) {
    try {
      const response = await axios(this.getUrl(`${appId}/comments`));
      if (Array.isArray(response.data) && response.data?.length > 0) {
        response.data.forEach((comment: ApplicationComment) => {
          comment.comment = decodeHTMLEncodedStr(comment.comment);
          comment.userDisplayName = decodeHTMLEncodedStr(
            comment.userDisplayName
          );
          comment.initials = decodeHTMLEncodedStr(comment.initials);
        });
      }

      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async addApplicationRequirementDecision(
    applicationId: number,
    body: any
  ): Promise<any> {
    try {
      const response = await axios.post(
        this.getUrl(`${applicationId}/RequirementDecisions`),
        body
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async updateApplicationRequirementDecisionStatus(
    applicationId: number,
    applicationRequirementDecisionId: number,
    requirementStatusId: number,
    body: any
  ): Promise<any> {
    try {
      const response = await axios.put(
        this.getUrl(
          `${applicationId}/RequirementDecisions/${applicationRequirementDecisionId}/status/${requirementStatusId}`
        ),
        body
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getApplicationStipulations(appId: number) {
    try {
      const response = await axios(this.getUrl(`${appId}/stipulations`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getApplicationStipulationList(appId: number) {
    try {
      const response = await axios(this.getUrl(`${appId}/stipulation/list`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async addApplicationStipulation(appId: number, body: any) {
    try {
      const response = await axios.post(
        this.getUrl(`${appId}/stipulations`),
        body
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
  async addApplicantStipulation(appId: number, applicantId: number, body: any) {
    try {
      const response = await axios.post(
        this.getUrl(`${appId}/applicants/${applicantId}/Stipulations`),
        body
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async updateApplicationStipulation(appId: number, stipId: number, body: any) {
    try {
      const response = await axios.put(
        this.getUrl(`${appId}/stipulations/${stipId}`),
        body
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async updateApplicantStipulation(
    appId: number,
    applicantId: number,
    stipId: number,
    body: any
  ) {
    try {
      const response = await axios.put(
        this.getUrl(
          `${appId}/applicants/${applicantId}/stipulations/${stipId}`
        ),
        body
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async fulfillApplicationStipulation(
    appId: number,
    applicantId: number,
    stipId: number,
    body: any
  ) {
    try {
      const response = await axios.post(
        this.getUrl(
          `${appId}/applicants/${applicantId}/stipulations/${stipId}/fulfill`
        ),
        body
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async fullfillApplicantStipulation(appId: number, stipId: number, body: any) {
    try {
      const response = await axios.put(
        this.getUrl(`${appId}/stipulations/${stipId}/fulfill`),
        body
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async linkDocumentToStipulation(
    appId: number,
    stipulationId: number,
    body: any
  ): Promise<any> {
    try {
      const response = await axios.post(
        this.getUrl(`${appId}/stipulations/${stipulationId}/link`),
        body
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async unlinkDocumentFromStipulation(
    appId: number,
    stipulationId: number,
    applicantDocumentId: number
  ): Promise<any> {
    try {
      const response = await axios.post(
        this.getUrl(
          `${appId}/stipulations/${stipulationId}/unlink/${applicantDocumentId}`
        )
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async updateApplicationSchoolConfiguration(applicationId: number, body) {
    try {
      const response = await axios.patch(
        this.getUrl(`${applicationId}/school`),
        body
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getApplicationProgramRequirements(appId: number) {
    try {
      const response = await axios(this.getUrl(`${appId}/requirements`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getApplicationProgramResult(appId: number) {
    try {
      const response = await axios(
        this.getUrl(`${appId}/ApplicationProgramResult`)
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getApplicationTags(id: number): Promise<any> {
    try {
      const response = await axios(this.getUrl(`${id}/tags`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async submitApplicationFinalReview(
    applicationId: number,
    tagId: number,
    body: SubmitApplicationReviewRequest
  ) {
    try {
      const response = await axios.post(
        this.getUrl(`${applicationId}/final-review/${tagId}`),
        body
      );
      return response || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getManagerialReviewDetails(applicationId: number) {
    try {
      const response = await axios(
        this.getUrl(`${applicationId}/managerial-review/details`)
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getFinalReviewDecision(applicationId: number) {
    try {
      const response = await axios(
        this.getUrl(`${applicationId}/final-review/Decisions`)
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}

class ApplicantsService extends BaseApiService {
  constructor() {
    super("applicants");
  }

  async getUsername(body: any): Promise<any> {
    try {
      const response = await axios.post(this.getUrl(`username`), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getIdentityByApplicant(applicantId: number): Promise<any> {
    try {
      const response = await axios(
        this.getUrl(`${applicantId}/identityverifications`)
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getDtiByApplicant(applicantId: number): Promise<any> {
    try {
      const response = await axios(this.getUrl(`${applicantId}/dtis`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getIdentificationResults(applicantId: number): Promise<any> {
    try {
      const response = await axios(
        this.getUrl(`IdentificationResults/${applicantId}`)
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async createEmployment(applicantId: number, employmentId = 0): Promise<any> {
    try {
      const response = await axios.put(
        this.getUrl(`${applicantId}/Employment/${employmentId}`)
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async updateApplicantInfo(body: any): Promise<any> {
    try {
      const response = await axios.post(this.getUrl(`update`), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async addIncome(body: any) {
    try {
      const response = await axios.post(this.getUrl(`income`), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async updateIncome(
    applicantId: number,
    incomeId: number,
    body: any
  ): Promise<any> {
    try {
      const response = await axios.put(
        this.getUrl(`${applicantId}/income/${incomeId}`),
        body
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async deleteIncome(applicantId: number, incomeId: number): Promise<any> {
    try {
      const response = await axios.delete(
        this.getUrl(`${applicantId}/income/${incomeId}`)
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async linkDocumentToIncome(
    applicantId: number,
    incomeId: number,
    body: any
  ): Promise<any> {
    try {
      const response = await axios.put(
        this.getUrl(`${applicantId}/income/${incomeId}/document`),
        body
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getIncomes(applicantId: number): Promise<any> {
    try {
      const response = await axios(this.getUrl(`${applicantId}/incomes`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async updateMembership(body: any) {
    try {
      const response = await axios.post(this.getUrl(`membership`), body);
      return response.data || [];
    } catch (err) {
      functions.openModal({
        title: "Error",
        description: "There was an issue updating the Applicant Membership",
      });

      return this.handleErrors(err);
    }
  }

  async getDocuments(applicantId: number): Promise<any> {
    try {
      const response = await axios(this.getUrl(`${applicantId}/documents`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async updateApplicantAddress(
    applicantId: number,
    addressId: number,
    body: any
  ) {
    try {
      const response = await axios.put(
        this.getUrl(`${applicantId}/address/${addressId}`),
        body
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getApplicantCalculatedIncome(applicantId: number) {
    try {
      const response = await axios(
        this.getUrl(`${applicantId}/income/calculated`)
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getLiabilitiesByApplicantId(applicantId: number) {
    try {
      const response = await axios(this.getUrl(`${applicantId}/liabilities`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getLiabilitiesByCreditId(applicantId: number, creditReportId: number) {
    try {
      const response = await axios(
        this.getUrl(`${applicantId}/Credit/${creditReportId}/liabilities/`)
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getLiabilityById(applicantId: number, applicantLiabilityId: number) {
    try {
      const response = await axios(
        this.getUrl(`${applicantId}/liabilities/${applicantLiabilityId}`)
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async updateApplicantLiability(applicantId: number, body: any) {
    try {
      const response = await axios.put(
        this.getUrl(`${applicantId}/liabilities/Update`),
        body
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async addApplicantLiabilities(applicantId: number) {
    try {
      const response = await axios.post(
        this.getUrl(`${applicantId}/liabilities`)
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getDebtByCreditFromLiabilities(
    applicantId: number,
    creditReportId: number
  ) {
    try {
      const response = await axios(
        this.getUrl(`${applicantId}/credit/${creditReportId}/liabilities/debt`)
      );
      return response || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}

class CommentsService extends BaseApiService {
  constructor() {
    super("comments");
  }

  async getLoanComments(loanId: number): Promise<any> {
    try {
      const response = await axios(this.getUrl(`Loan/${loanId}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
  async getDrawComments(drawId: number): Promise<any> {
    try {
      const response = await axios(this.getUrl(`Draw/${drawId}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
  async addLoanComment(body: any) {
    try {
      const response = await axios.post(this.getUrl(`Loan`), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
  async addDrawComment(body: any) {
    try {
      const response = await axios.post(this.getUrl(`Draw`), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}

class ConfigurationApiService extends BaseApiService {
  constructor() {
    super("configuration");
  }
}

class CreditsService extends BaseApiService {
  constructor() {
    super("credits");
  }

  async getCreditReportByApplicant(
    applicationId: number,
    applicantId: number
  ): Promise<Credit> {
    try {
      const response = await axios(
        this.getUrl(`${applicationId}/detail/${applicantId}`)
      );
      console.log(response.data);
      return response.data.results || null;
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getCreditReportByReference(
    referenceId: string,
    creditHistory: CreditHistory
  ): Promise<CreditResponse> {
    try {
      const response = await axios.post(
        this.getUrl(`${referenceId}/detail`),
        creditHistory
      );

      return response.data.results || null;
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getRawCreditReport(creditReportId: number): Promise<any> {
    try {
      const response = await axios(this.getUrl(`${creditReportId}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getCreditScore(creditReportId: number): Promise<any> {
    try {
      const response = await axios(
        this.getUrl(`creditscore/${creditReportId}`)
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getCreditKnockouts(creditReportId: number): Promise<any> {
    try {
      const response = await axios(this.getUrl(`knockouts/${creditReportId}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getCreditKnockoutsFactors(creditReportId: number): Promise<any> {
    try {
      const response = await axios(this.getUrl(`factors/${creditReportId}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getCreditReport(id: number): Promise<any> {
    try {
      const response = await axios(this.getUrl(`${id}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getDebtByCredit(creditId: number): Promise<any> {
    try {
      const response = await axios(this.getUrl(`${creditId}/debt`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getProgramLimit(appId: number, typeId: number) {
    try {
      const response = await axios(
        this.getUrl(`${appId}/debt/programLimits/${typeId}`)
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getCreditIncome(applicationId: number): Promise<Income> {
    try {
      const response = await axios(this.getUrl(`${applicationId}/income`));

      return response.data.results || ({} as Income);
    } catch (error) {
      return this.handleErrors(error);
    }
  }
}

class DocumentsService extends BaseApiService {
  constructor() {
    super("documents");
  }

  async getDocumentsByApplication(id: number): Promise<any> {
    try {
      const response = await axios(this.getUrl(`application/${id}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getDocumentsByApplicant(id: number): Promise<any> {
    try {
      const response = await axios(this.getUrl(`applicant/${id}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async updateDocumentStatus(
    applicationId: number,
    applicantId: number,
    applicantDocumentId: number,
    body: any
  ): Promise<any> {
    try {
      const response = await axios.put(
        this.getUrl(
          `application/${applicationId}/applicant/${applicantId}/${applicantDocumentId}/status`
        ),
        body
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async updateApplicantDocument(
    applicantId: number,
    applicantDocumentId: number,
    body: any
  ): Promise<any> {
    try {
      const response = await axios.put(
        this.getUrl(`applicant/${applicantId}/${applicantDocumentId}`),
        body
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async updateDocumentType(
    applicationId: number,
    applicantId: number,
    applicantDocumentId: number,
    body: any
  ): Promise<any> {
    try {
      const response = await axios.put(
        this.getUrl(
          `application/${applicationId}/applicant/${applicantId}/${applicantDocumentId}/type`
        ),
        body
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async splitDocument(
    documentId: number,
    request: DocumentSplitRequest
  ): Promise<DocumentSplitResponse> {
    try {
      const response = await axios.post(
        this.getUrl(`${documentId}/split`),
        request
      );
      return (response.data as DocumentSplitResponse) || {};
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async rotateDocument(
    documentId: number,
    request: DocumentRotateRequest
  ): Promise<any> {
    try {
      const response = await axios.post(
        this.getUrl(`${documentId}/rotate`),
        request
      );
      return response.data || {};
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async mergeDocuments(request: MergeDocumentsRequest): Promise<any> {
    try {
      const response = await axios.post(this.getUrl("merge"), request);
      return response.data || {};
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getDocument(documentId: number) {
    try {
      const response = await axios(this.getUrl(`${documentId}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getDocumentPdfProperties(documentId: number) {
    try {
      const response = await axios(this.getUrl(`${documentId}/pdf-properties`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getDownloadLink(documentId: number) {
    try {
      const response = await axios(this.getUrl(`${documentId}/link`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}

class EsignService extends BaseApiService {
  constructor() {
    super("esign");
  }

  async getApplicationEsignStatus(applicationId: number) {
    try {
      const response = await axios(this.getUrl(`esignstatus/${applicationId}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getApplicationEsignList(applicationId: number): Promise<Esign[]> {
    try {
      const response = await axios(this.getUrl(`${applicationId}/list`));
      return response.data.results.esignRecords || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}
class IdService extends BaseApiService {
  constructor() {
    super("id");
  }

  async getLexisNexisReport(body: any): Promise<any> {
    try {
      const response = await axios.post(
        this.getUrl(`ExecuteIdVerification`),
        body
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getIdentityDetails(applicationId: number): Promise<Identity> {
    try {
      const response = await axios.get(
        this.getUrl(`${applicationId}/Application`)
      );

      return response.data.results || ({} as Identity);
    } catch (error) {
      return this.handleErrors(error);
    }
  }
}

class LendersService extends BaseApiService {
  constructor() {
    super("lenders");
  }

  async getLenders(): Promise<any> {
    try {
      const response = await axios(this.getUrl());
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}

class LoanService extends BaseApiService {
  constructor() {
    super("loanservice");
  }

  async getLoans(): Promise<any> {
    try {
      const response = await axios.post(this.getUrl(`loans/filter`), {});
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getLoan(referenceId: number): Promise<any> {
    try {
      const response = await axios(
        this.getUrl(`loans/details/ref/${referenceId}`)
      );
      return response || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getAvailableToDrawAmount(
    loanId: number
  ): Promise<AvailableToDrawAmount> {
    try {
      const response = await axios.get(
        this.getUrl(`loans/${loanId}/availableToDrawAmount`)
      );
      return (response.data as AvailableToDrawAmount) || {};
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async createDraw(loanId: number, request: CreateDrawRequest): Promise<any> {
    try {
      const response = await axios.post(
        this.getUrl(`loan/${loanId}/draw`),
        request
      );
      return response || {};
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getDraw(drawId: number): Promise<Draw> {
    try {
      const response = await axios.get(this.getUrl(`draws/${drawId}`));
      return (response.data as Draw) || {};
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async updateDraw(drawId: number, request: UpdateDrawRequest): Promise<any> {
    try {
      const response = await axios.patch(
        this.getUrl(`draws/${drawId}`),
        request
      );
      return response || {};
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async updateDrawRequestedAmount(
    drawId: number,
    request: UpdateDrawRequestedAmountRequest
  ): Promise<any> {
    try {
      const response = await axios.post(
        this.getUrl(`draws/${drawId}/requestedAmount`),
        request
      );
      return response.data || {};
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async updateDrawStatusesAsync(
    drawId: number,
    request: UpdateDrawStatusesRequest
  ): Promise<any> {
    try {
      const response = await axios.post(this.getUrl("draws/statuses"), request);
      return response || {};
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getEnrollmentTermsAsync(): Promise<EnrollmentTermModel[]> {
    try {
      const response = await axios.get(this.getUrl("enrollmentTerms"));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async terminateDraw(drawId: number): Promise<any> {
    try {
      const response = await axios.patch(
        this.getUrl(`draws/${drawId}/terminate`)
      );
      return response || {};
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}

class SchoolService extends BaseApiService {
  constructor() {
    super("schools");
  }
  async getSchools() {
    try {
      const response = await axios(this.getUrl(``));
      return response || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
  async getSchool(schoolCode: string, schoolBranch: string) {
    try {
      const response = await axios(
        this.getUrl(`${schoolCode}/${schoolBranch}`)
      );
      return response || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getSchoolsByProgram(
    programTypeId: number,
    productTypeId: number,
    lenderId: number
  ) {
    try {
      const url = this.getUrl("");
      const response = await axios.get(url, {
        params: {
          programTypeId: programTypeId,
          productTypeId: productTypeId,
          lenderId: lenderId,
        },
      });

      return response || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getGraduateDegreePrograms(schoolId?: number) {
    try {
      const response = await axios(this.getUrl(`graduateDegreePrograms`));
      return response || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}

class TypesService extends BaseApiService {
  constructor() {
    super("types");
  }

  async getType(type: string): Promise<any> {
    try {
      const response = await axios(this.getUrl(`${type}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getTypeByProgram(type: string, query: any): Promise<any> {
    try {
      const response = await axios(this.getUrl(`${type}`), { params: query });
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getDocServiceType(type: string): Promise<any> {
    try {
      const response = await axios(this.getUrl(`doc/${type}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getStipulationTypes(): Promise<any> {
    try {
      const response = await axios(this.getUrl("stipulations"));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}
class PayoffsService extends BaseApiService {
  constructor() {
    super("payoffs");
  }

  async getApplicationPayoffs(applicationId: number) {
    try {
      const response = await axios(this.getUrl(`${applicationId}`));
      return response.data || {};
    } catch (err) {
      return this.handleErrors(err);
    }
  }
  async addPayoff(body: RefiLoanRequest) {
    try {
      const response = await axios.post(this.getUrl(`Create`), body);
      return response.data || {};
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async updatePayoff(body: UpdateRefiLoanRequest) {
    try {
      const response = await axios.put(this.getUrl(`Update`), body);
      return response.data || {};
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async linkDocumentToRefiLoan(body: LinkUnlinkDocumentToRefiLoanRequest) {
    try {
      const response = await axios.put(this.getUrl(`LinkDoc`), body);
      return response.data || {};
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async unlinkDocumentFromRefiLoan(body: LinkUnlinkDocumentToRefiLoanRequest) {
    try {
      const response = await axios.put(this.getUrl(`UnlinkDoc`), body);
      return response.data || {};
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async verifyPayoffs(applicationId: number) {
    try {
      const response = await axios(
        this.getUrl(`${applicationId}/VerifyPayoffs`)
      );
      return response.data || {};
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async addAttentionTo(body: AddAttentionToRequest) {
    try {
      const response = await axios.post(this.getUrl(`attentionto`), body);
      return response.data || {};
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}

class PricingService extends BaseApiService {
  constructor() {
    super("pricing");
  }

  async getPricingTier(
    pricingTierId: number,
    applicationId: number
  ): Promise<any> {
    try {
      const response = await axios(
        this.getUrl(`${pricingTierId}/${applicationId}/PricingTier`)
      );
      return response.data || {};
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getPricingDetail(applicationId: number): Promise<PricingVM> {
    try {
      const response = await axios(this.getUrl(`${applicationId}/detail`));
      return response.data.results || {};
    } catch (error) {
      return this.handleErrors(error);
    }
  }
}

class PermissionsService extends BaseApiService {
  constructor() {
    super("permissions");
  }

  async getRolesPermissions(): Promise<RolePermission[]> {
    try {
      const response = await axios.get(this.getUrl("rolepermissions"));
      return (response.data as RolePermission[]) || {};
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}

export const $api = {
  account: new AccountApiService(),
  applications: new ApplicationsService(),
  applicants: new ApplicantsService(),
  comments: new CommentsService(),
  configuration: new ConfigurationApiService(),
  credits: new CreditsService(),
  documents: new DocumentsService(),
  esign: new EsignService(),
  id: new IdService(),
  lenders: new LendersService(),
  loans: new LoanService(),
  schools: new SchoolService(),
  types: new TypesService(),
  pricing: new PricingService(),
  permissions: new PermissionsService(),
  payoffs: new PayoffsService(),
};
