import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/modal-close.png'


const _hoisted_1 = { class: "modal-mask" }
const _hoisted_2 = { class: "modal-wrapper" }
const _hoisted_3 = { class: "modal-container" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = { class: "modal-title" }
const _hoisted_6 = {
  key: 0,
  class: "subheader"
}
const _hoisted_7 = {
  key: 1,
  class: "header"
}
const _hoisted_8 = {
  key: 2,
  class: "postheader"
}
const _hoisted_9 = { class: "modal-body" }
const _hoisted_10 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "modal" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                (_ctx.$slots.subheader)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_6, [
                      _renderSlot(_ctx.$slots, "subheader", {}, undefined, true)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.$slots.header)
                  ? (_openBlock(), _createElementBlock("h1", _hoisted_7, [
                      _renderSlot(_ctx.$slots, "header", {}, undefined, true)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.$slots.postheader)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_8, [
                      _renderSlot(_ctx.$slots, "postheader", {}, undefined, true)
                    ]))
                  : _renderSlot(_ctx.$slots, "header", { key: 3 }, undefined, true)
              ]),
              (_ctx.closeBtn)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    href: "",
                    class: "close-btn",
                    "aria-label": "Close",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('close')), ["prevent"]))
                  }, _cache[1] || (_cache[1] = [
                    _createElementVNode("img", {
                      src: _imports_0,
                      alt: "Close modal button"
                    }, null, -1)
                  ])))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_9, [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ]),
            _createElementVNode("div", _hoisted_10, [
              _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
            ])
          ])
        ])
      ])
    ]),
    _: 3
  }))
}