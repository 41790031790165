import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { computed, ref, reactive } from "vue";
import { ContextMenuComponent as EjsContextmenu } from "@syncfusion/ej2-vue-navigations";
import { MenuItemModel, MenuEventArgs } from '@syncfusion/ej2-navigations';
import { enableRipple } from '@syncfusion/ej2-base';
import { LOSApplication } from "@/models/opal";
import { useStore } from "vuex";
import useFunctions from "@/use/functions1";

interface Props {
  name?: string;
  application: LOSApplication;
  programRequirement: any;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'IdentityProgramRequirementsContextMenu',
  props: {
    name: {},
    application: {},
    programRequirement: {}
  },
  emits: ["updateRequirementStatus", "addRequirementStatus"],
  setup(__props: any, { emit: __emit }) {

enableRipple(true);

const store = useStore();
const cmenuIdentity = ref({} as any);
const props = __props;
const { sortLosTypesByName } = useFunctions();
const applicationStatus = computed(() => props.application.data.applicationStatusName?.trim().toLowerCase());
const contextMenuId = computed(() => `${props.name?.trim()}`);

const emit = __emit;
const sortedRequirementStatuses =  sortLosTypesByName(store?.state.types["RequirementStatuses"]?.results);
const requirementStatusOptions = computed(() => sortedRequirementStatuses?.map(
  (o: any) => {
    return {
    text: o.name,
    id: `RequirementStatusIdSelected_${o.id}`,
    showItemOnClick: true,
    items: [{
      text: o.description,
      id: `RequirementStatusIdSelectedDescription_${o.id}`,
    }]
    } 
}));

let menuItems: MenuItemModel[] = reactive(requirementStatusOptions.value);

async function onSelect(args: MenuEventArgs) {
  const menuObj = cmenuIdentity.value;

  if (args.item.id) {
    const requirementStatusId = args.item.id.split("_")?.[1].trim() || "";
    let request = {} as any;

    if (props.programRequirement?.id > 0 || props.programRequirement?.applicationRequirementDecisionId > 0) {
      request = {
        applicationId: props.programRequirement?.applicationId || props.application?.data?.id,
        applicationRequirementDecisionId: props.programRequirement?.id || props.programRequirement?.applicationRequirementDecisionId,
        requirementStatusId: parseInt(requirementStatusId),
      }

      emit('updateRequirementStatus', request);
    } else {
      request = {
        applicationId: props.programRequirement?.applicationId || props.application?.data?.id,
        requirementTypeId:  props.programRequirement?.requirementTypeId,
        requirementStatusId:  parseInt(requirementStatusId),
      }
      emit('addRequirementStatus', request);
    }

    menuObj.close();
  }
}

return (_ctx: any,_cache: any) => {
  return (applicationStatus.value === 'submitted & processing' || applicationStatus.value === 'evaluation')
    ? (_openBlock(), _createBlock(_unref(EjsContextmenu), {
        key: 0,
        ref_key: "cmenuIdentity",
        ref: cmenuIdentity,
        target: `#${contextMenuId.value}`,
        items: _unref(menuItems),
        onSelect: onSelect
      }, null, 8, ["target", "items"]))
    : _createCommentVNode("", true)
}
}

})