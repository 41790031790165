import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

import { ref, computed } from 'vue';
	import axios from 'axios';
	import functions from '@/use/functions';
	import { Permissions, PermissionLevels } from '@/models/permissions';
	import PermissionsWrapper from '@/components/wrappers/PermissionsWrapper.vue';
	import { BatchReferenceModel } from '../../models/payoffBatches';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'BatchPayoffRow',
  props: {
    batchId: {},
    payoffReference: {},
    isBatchApproved: { type: Boolean },
    isProcessing: { type: Boolean },
    onBatchUpdateAsync: { type: Function }
  },
  setup(__props: any) {

	const props = __props;

	const isIn = ref(!props.payoffReference.isDeleted);
	const isToggling = ref(false);

	const payoff = computed(() => props.payoffReference.payoff);

	const toggleAddRemoveAsync = async () => {
		if (props.isBatchApproved || props.isProcessing || isToggling.value) {
			return;
		}

		isToggling.value = true;

		try {
			await updateReferenceAsync(isIn.value ? 'add' : 'remove');
		} catch (error) {
			console.error(error);
		} finally {
			isToggling.value = false;
		}
	};

	const updateReferenceAsync = async (action: 'add' | 'remove') => {
		try {
			await axios.post(`/api/payoffbatches/${props.batchId}/${action}/${props.payoffReference.payoffId}`);
			await props.onBatchUpdateAsync();
		} catch (error) {
			console.error(error);

			functions.openModal({
				title: 'Oops!',
				description: `Failed to ${action} the payoff ${action === 'add' ? 'to' : 'from'} the batch.`
			});

			isIn.value = !isIn.value; // reset
		}
	};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("tr", null, [
    _createElementVNode("td", null, [
      _createVNode(PermissionsWrapper, {
        showDisabledIfNotVisible: true,
        permissionIds: [_unref(Permissions).DisbursementBatches],
        permissionLevel: _unref(PermissionLevels).Update
      }, {
        default: _withCtx(() => [
          _createElementVNode("label", null, [
            _withDirectives(_createElementVNode("input", {
              type: "checkbox",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((isIn).value = $event)),
              disabled: _ctx.isBatchApproved || _ctx.isProcessing || isToggling.value,
              onChange: toggleAddRemoveAsync
            }, null, 40, _hoisted_1), [
              [_vModelCheckbox, isIn.value]
            ]),
            _cache[1] || (_cache[1] = _createElementVNode("span", { class: "custom-checkbox" }, null, -1))
          ])
        ]),
        _: 1
      }, 8, ["permissionIds", "permissionLevel"])
    ]),
    _createElementVNode("td", null, _toDisplayString(payoff.value?.lenderName), 1),
    _createElementVNode("td", null, _toDisplayString(payoff.value?.borrowerName), 1),
    _createElementVNode("td", null, _toDisplayString(payoff.value?.disbursementAmountFormatted), 1),
    _createElementVNode("td", null, _toDisplayString(payoff.value?.disbursementStatusHumanized), 1),
    _createElementVNode("td", null, _toDisplayString(payoff.value?.disbursementMethodHumanized), 1),
    _createElementVNode("td", null, _toDisplayString(payoff.value?.referenceId), 1),
    _createElementVNode("td", null, _toDisplayString(payoff.value?.lenderUseOnly), 1),
    _createElementVNode("td", null, _toDisplayString(payoff.value?.sequenceNumber), 1)
  ]))
}
}

})