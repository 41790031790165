import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "stip-modal" }
const _hoisted_2 = {
  key: 0,
  class: "stip-modal-header"
}
const _hoisted_3 = {
  key: 1,
  class: "stip-modal-header"
}
const _hoisted_4 = { class: "xfields" }

import ModalOpal from "@/components/ModalOpal.vue";
  import { reactive, computed, onMounted, ref } from "vue";
  import { LOSApplication, LOSType, Stipulation } from "@/models/opal";
  import BaseFormField from "@/components/form-fields/BaseFormField.vue";
  import { useStore } from "vuex";
  import { FieldSchema, FormSchema } from "@/models/form";
  import UppyUploader from "@/components/UppyUploader.vue";
  import useValidation from "@/use/validation";
  import { $api } from "@/services/api1";
  import OpalButton from "@/components/shared/OpalButton.vue";
  import useStipulations from "@/use/stipulations";
  import functions from "@/use/functions";

  interface Props {
    stipulation: Stipulation;
    application: LOSApplication;
    status: string;
  }
  
export default /*@__PURE__*/_defineComponent({
  __name: 'StipCancellationForm',
  props: {
    stipulation: {},
    application: {},
    status: {}
  },
  emits: [
    "closeCancellationForm",
    "statusReasonChanged",
    "getApplicationStips",
    "stipUpdated",
    "getApplicationComments",
  ],
  setup(__props: any, { emit: __emit }) {

  const emit = __emit;

  const store = useStore();
  const props = __props;
  const { validateForm } = useValidation();
  const applicants = computed(() => props?.application?.applicants);
  const CuOverrideForm = computed(() =>
    store.state.types["DocumentType"]?.results.find(
      (d: any) => d.name === "CU Override Form"
    )
  );
  const cancellationReasons = computed(() =>
    store.state.types["StipulationStatusReasons"]?.results.filter(
      (l: LOSType) =>
        l.name === "Replaced" ||
        l.name === "Unable to Provide" ||
        l.description.toLowerCase() === "item not needed"
    )
  );
  const loading = ref<boolean>(false);
  const stip = computed(() => props.stipulation);
  const { stipulationStatusReasonId } = useStipulations();
  const stipulationCancellationReasonOptions = computed(() =>
    cancellationReasons.value.map((sr: LOSType) => {
      return {
        label: sr.description,
        value: sr.id,
      };
    })
  );

  const stipulationCancellationFormSchema = reactive({
    stipulationStatusReasonId: {
      name: "stipStatusReason",
      type: "select",
      label: "Cancellation Reason",
      errorMessage: "A cancellation reason is required",
      hideLabel: true,
      required: true,
    } as FieldSchema,
  }) as FormSchema;

  let stipulationForm = reactive({
    stipulationStatusReasonId: null,
  } as any);

  function validCancellationReason() {
    return (
      props.stipulation.stipulationStatusReasonName === "Replaced" ||
      props.stipulation.stipulationStatusReasonName === "Unable to Provide" ||
      props.stipulation.stipulationStatusReasonName.toLowerCase() ===
        "item not needed"
    );
  }

  function resetForm() {
    stipulationForm.stipulationStatusReasonId = null;
  }
  function closeModal() {
    emit("getApplicationStips", true);
    emit("closeCancellationForm");
  }
  async function handleUploadSuccess(event: any) {
    emit("closeCancellationForm");
  }

  async function submitForm() {
    const valid = validateForm(stipulationCancellationFormSchema, {
      stipulationStatusReasonId: stipulationForm.stipulationStatusReasonId,
    });
    if (valid) {
      loading.value = true;
      await updateStipulationStatusReason();
    }
  }

  async function handleCUOverride(status = "CU-Override") {
    const statusUpdate = store.state.types["StipulationStatuses"]?.results.find(
      (s: any) => s.name?.trim() === status?.trim()
    );

    let updateStipRequest = {
      stipulationStatusId: statusUpdate.id,
      stipulationStatusReasonId: stipulationStatusReasonId(status),
      stipulationTextOverride: null,
      revertToDefaultText: null,
      updatedByUserId: store.state.oidcStore.user.sub,
      requestedFor: props.stipulation.fullName || null,
    };
    const original = props.application.stipulationsData.find(
      (s: Stipulation) => s.id === stip.value.id
    );

    if (stip.value.applicantId) {
      const response = await $api.applications.updateApplicantStipulation(
        stip.value.applicationId,
        stip.value.applicantId,
        stip.value.id,
        updateStipRequest
      );
      if (response.id) {
        loading.value = false;
        stip.value.stipulationStatusReasonId =
          response.stipulationStatusReasonId;
        stip.value.stipulationStatusReasonName =
          response.stipulationStatusReasonName;
        emit("getApplicationComments");
      } else {
        emit("closeCancellationForm");
        functions.openModal({
          title: "Error",
          description: "There was an issue updating the stip status",
        });
      }
    } else {
      const response = await $api.applications.updateApplicationStipulation(
        stip.value.applicationId,
        stip.value.id,
        stipulationForm
      );
      if (response.id) {
        loading.value = false;
        stip.value.stipulationStatusReasonId =
          response.stipulationStatusReasonId;
        stip.value.stipulationStatusReasonName =
          response.stipulationStatusReasonName;
        emit("getApplicationComments");
      } else {
        functions.openModal({
          title: "Error",
          description: "There was an issue updating the stip status",
        });
        stip.value.stipulationStatusReasonId =
          original.stipulationStatusReasonId;
        stip.value.stipulationStatusReasonName =
          original.stipulationStatusReasonName;
      }
    }
  }

  async function updateStipulationStatusReason() {
    let updateStipRequest = {
      stipulationStatusId: stip.value.stipulationStatusId,
      stipulationStatusReasonId: stipulationForm.stipulationStatusReasonId,
      stipulationTextOverride: null,
      revertToDefaultText: null,
      updatedByUserId: store.state.oidcStore.user.sub,
    };
    const original = props.application.stipulationsData.find(
      (s: Stipulation) => s.id === stip.value.id
    );

    if (stip.value.applicantId) {
      const response = await $api.applications.updateApplicantStipulation(
        stip.value.applicationId,
        stip.value.applicantId,
        stip.value.id,
        updateStipRequest
      );
      if (response.id) {
        loading.value = false;
        stip.value.stipulationStatusReasonId =
          response.stipulationStatusReasonId;
        stip.value.stipulationStatusReasonName =
          response.stipulationStatusReasonName;
        // emit('statusReasonChanged', stip.value);
        emit("getApplicationStips", true);
        emit("getApplicationComments");
        // emit('stipUpdated', response);
      } else {
        functions.openModal({
          title: "Error",
          description: "There was an issue updating the stip status",
        });
        stip.value.stipulationStatusReasonId =
          original.stipulationStatusReasonId;
        stip.value.stipulationStatusReasonName =
          original.stipulationStatusReasonName;
      }
    } else {
      const response = await $api.applications.updateApplicationStipulation(
        stip.value.applicationId,
        stip.value.id,
        stipulationForm
      );
      if (response.id) {
        loading.value = false;
        stip.value.stipulationStatusReasonId =
          response.stipulationStatusReasonId;
        stip.value.stipulationStatusReasonName =
          response.stipulationStatusReasonName;
        emit("getApplicationStips", true);
        emit("getApplicationComments");
        // emit('statusReasonChanged', stip.value);
        // emit('closeCancellationForm');
        // emit('stipUpdated', response);
      } else {
        functions.openModal({
          title: "Error",
          description: "There was an issue updating the stip status",
        });
        stip.value.stipulationStatusReasonId =
          original.stipulationStatusReasonId;
        stip.value.stipulationStatusReasonName =
          original.stipulationStatusReasonName;
      }
    }
  }

  onMounted(async () => {
    if (props.status === "CU-Override") {
      await handleCUOverride();
    }
    const validReason = validCancellationReason();
    if (validReason) {
      stipulationForm.stipulationStatusReasonId =
        props.stipulation.stipulationStatusReasonId;
    } else {
      resetForm();
    }
  });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ModalOpal, { onClose: closeModal }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.status === 'Cancelled')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[1] || (_cache[1] = [
              _createElementVNode("h3", { class: "modal-title" }, "Please select a cancellation reason", -1)
            ])))
          : _createCommentVNode("", true),
        (_ctx.status === 'CU-Override')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[2] || (_cache[2] = [
              _createElementVNode("h3", { class: "modal-title" }, "Please upload a CU Override form", -1)
            ])))
          : _createCommentVNode("", true),
        _createElementVNode("form", {
          onSubmit: _withModifiers(submitForm, ["prevent"])
        }, [
          _createElementVNode("fieldset", null, [
            _createElementVNode("div", _hoisted_4, [
              (_ctx.status === 'Cancelled')
                ? (_openBlock(), _createBlock(BaseFormField, {
                    key: 0,
                    schema: 
                stipulationCancellationFormSchema.stipulationStatusReasonId
              ,
                    options: stipulationCancellationReasonOptions.value,
                    modelValue: _unref(stipulationForm).stipulationStatusReasonId,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(stipulationForm).stipulationStatusReasonId) = $event)),
                    modelModifiers: { number: true }
                  }, {
                    "default-option": _withCtx(() => _cache[3] || (_cache[3] = [])),
                    _: 1
                  }, 8, ["schema", "options", "modelValue"]))
                : _createCommentVNode("", true),
              (_ctx.status === 'CU-Override')
                ? (_openBlock(), _createBlock(UppyUploader, {
                    key: 1,
                    applicationId: _ctx.application.data.id,
                    applicantId: _ctx.stipulation.applicantId,
                    userId: _unref(store).state.oidcStore.user.sub,
                    documentTypeId: CuOverrideForm.value.id,
                    onUploadSuccess: handleUploadSuccess
                  }, null, 8, ["applicationId", "applicantId", "userId", "documentTypeId"]))
                : _createCommentVNode("", true)
            ])
          ]),
          (_ctx.status === 'Cancelled')
            ? (_openBlock(), _createBlock(OpalButton, {
                key: 0,
                class: "submit",
                type: "submit",
                loading: loading.value,
                text: "Save Changes"
              }, null, 8, ["loading"]))
            : _createCommentVNode("", true)
        ], 32)
      ])
    ]),
    _: 1
  }))
}
}

})