import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { computed, ref, watch, reactive } from "vue";
import { ContextMenuComponent as EjsContextmenu } from "@syncfusion/ej2-vue-navigations";
import { MenuItemModel, MenuEventArgs, BeforeOpenCloseMenuEventArgs } from '@syncfusion/ej2-navigations';
import { enableRipple } from '@syncfusion/ej2-base';
import { Stipulation, Applicant, LOSApplication } from "@/models/opal";
import { useStore } from "vuex";

interface Props {
  name?: string;
  stipulation: Stipulation;
  application: LOSApplication,
}

export default /*@__PURE__*/_defineComponent({
  __name: 'StipContextMenu',
  props: {
    name: {},
    stipulation: {},
    application: {}
  },
  emits: ["linkDocument", "unlinkDocument", "toggleDetails", "toggleDocument", "toggleEdit", "toggleStipText", "updateStipulationStatus", "updateStipulationText"],
  setup(__props: any, { emit: __emit }) {

enableRipple(true);
const store = useStore();
const cmenu = ref({} as any);

const props = __props;
const emit = __emit;

const contextMenuId = computed(() => `${props.name?.trim()}`);
const stipulationInfo = reactive({} as any);
const stip = computed(() => props.stipulation);
const applicationStatus = computed(() => props.application.data.applicationStatusName?.trim().toLowerCase());

const stipTextMenuItem = ref<string>("Edit Stip Text");
const showDocumemtMenuItem = ref<string>("Show Document");

const stipulationStatusOptions = computed(() => store.state.types['StipulationStatuses']?.results?.map((stip: any) => {
  return {
    text: stip.name,
    id: `StipulationStatusReasonIdSelected_${stip.id}`,
    showItemOnClick: false,
  }
}));

const showDetails = ref<boolean>(false);

const applicantOptions = computed(() => props.application.applicants?.map((a: Applicant) => {
  return {
    text: `${a.fullName} - ${a.applicantTypeName}`,
    id: `ApplicantIdSelected_${a.id}`,
    showItemOnClick: true,
  }
}
));

const documentOptions = computed(() => props.application.documents.filter((d: any) => d.documentStatusTypeName.trim() !== "Generated")?.map((d: any) => {
  return {
    text: d.uploadedDocumentMetadata.originalFileName,
    id: `ApplicantDocumentIdSelected_${d.applicantDocumentId}`,
    showItemOnClick: false
  }
}));

const documentStipMenuItems: MenuItemModel[] = reactive([
  {
    text: stipTextMenuItem.value,
    id: "StipText"
  },
  {
    text: showDocumemtMenuItem.value,
    id: "ShowDocument",
  },
  {
    text: "Link Document",
    id: "LinkDocument",
    items: documentOptions.value
  },
  {
    text: "Unlink Document",
    id: "UnlinkDocument"
  },
  { separator: true },
  {
    text: "Status",
    id: "StipulationStatus",
    items: stipulationStatusOptions.value,
  },
  {
    text: "Assign",
    id: "ApplicantId",
    items: applicantOptions.value
  },
]);

const menuItems: MenuItemModel[] = reactive([
  {
    text: `Show Details`,
    id: "ShowDetails"
  },
  { separator: true },
  {
    text: stipTextMenuItem.value,
    id: "StipText"
  },
  { separator: true },
  {
    text: "Status",
    id: "StipulationStatus",
    items: stipulationStatusOptions.value,
  },
  {
    text: "Assign",
    id: "ApplicantId",
    items: applicantOptions.value
  },
]);

const readOnlyMenuItems: MenuItemModel[] = reactive([
  {
    text: `Show Details`,
    id: "ShowDetails"
  },
  {
    text: "Show Document",
    id: "ShowDocument",
  },
]);

const items = computed(() => props.stipulation.isExpectedDocument ? documentStipMenuItems : menuItems);

function handleUnlinkDocument(args: MenuEventArgs) {
  const info = {
    stipulationId: stip.value.id,
    applicantDocumentId: stip.value.applicantDocumentId
  }
  emit('unlinkDocument', info);
}

function handleLinkDocument(args: MenuEventArgs) {
  if (args.item.id?.includes('ApplicantDocumentIdSelected') && args.item.text) {
    const applicantDocumentId = args.item.id.split("_")?.[1].trim() || "";
    const info = {
      stipulationId: stip.value.id,
      applicantDocumentId: parseInt(applicantDocumentId),
      documentName: args.item.text,
      requestedFor: stip.value.applicantId ? stip.value.fullName : "Application",
      changeStatusCurrentlyLinkedApprovedPendingDocument: false,
      // changeStatusCurrentlyLinkedApprovedPendingDocumentTo: #TODO
    }
    emit('linkDocument', info);
  }
}

function handleStipulationStatusChange(args: MenuEventArgs) {
  const status = stipulationStatusOptions.value && stipulationStatusOptions.value.length > 0 ? store.state.types['StipulationStatuses']?.results.find((s: any) => s.name?.trim() === args.item.text?.trim()) : false;
  emit('updateStipulationStatus', status);
}

function handleApplicantReassignment(args: MenuEventArgs) {
  if (args.item.id?.includes('ApplicantIdSelected') && args.item.text) {
    //#Todo future functionality, set to disabled
    const applicantFullName = args.item.text.split("-")?.[0].trim() || "";
    const assigned = applicantOptions.value && applicantOptions.value.length > 0 ? props.application.applicants.find((a: Applicant) => a.fullName?.trim() === applicantFullName) : false;
  }
}

function handleActionBasedStips(args: BeforeOpenCloseMenuEventArgs) {
  setShowDetailsMenuItemTextArg(args);
}

function setShowDocumentMenuItemTextArg(args: BeforeOpenCloseMenuEventArgs) {
  let showDocumentMenuItem = args.items.find((i: any) => i.id === 'ShowDocument');
  if (showDocumentMenuItem) {
    showDocumentMenuItem.text = props.stipulation.showDocument ? 'Hide Document' : 'Show Document';
  }
}

function setLinkDocumentOptions(args: BeforeOpenCloseMenuEventArgs) {
  let linkDocumentMenuItem = args.items.find((i: any) => i.id === 'LinkDocument');
  if (linkDocumentMenuItem) {
    linkDocumentMenuItem.items = documentOptions.value;
  }
}

function setShowDetailsMenuItemTextArg(args: BeforeOpenCloseMenuEventArgs) {
  let showDetailsMenuItem = args.items.find((i: any) => i.id === 'ShowDetails');
  if (showDetailsMenuItem) {
    showDetailsMenuItem.text = props.stipulation.showDetails ? 'Hide Details' : 'Show Details';
  }
}

function setEditStipTextMenuItemTextArg(args: BeforeOpenCloseMenuEventArgs) {
  let editStipTextMenuItem = args.items.find((i: any) => i.id === 'StipText');
  if (editStipTextMenuItem) {
    editStipTextMenuItem.text = props.stipulation.editStipText ? 'Save Stip Text' : 'Edit Stip Text';
  }
}

function toggleReadOnlyFunctionality(args: BeforeOpenCloseMenuEventArgs, menuObj: any) {
  if (stip.value.isExpectedDocument) {
    // hide doc viewer if no linked document
    if (stip.value.documentId === null && stip.value.applicantDocumentId === null) {
      menuObj.hideItems(['Show Details', 'Show Document']);
    }

    // hide details option for document based stips
    if (stip.value.documentId && stip.value.documentId > 0 && stip.value.applicantDocumentId && stip.value.applicantDocumentId > 0) {
      menuObj.hideItems(['Show Details']);
    }

    setShowDetailsMenuItemTextArg(args);
  } else {
    menuObj.hideItems(['Show Document']);
    setShowDocumentMenuItemTextArg(args);
  }
}

function toggleInternalUserFunctionality(args: BeforeOpenCloseMenuEventArgs, menuObj: any) {
  // Document stip
  if (stip.value.isExpectedDocument) {
    // Requirement: Do not allow linking/unlinking of document when stip is in Completed and CU-Override statuses
    if (stip.value.stipulationStatusName.toLowerCase() === 'completed' || stip.value.stipulationStatusName.toLowerCase() === 'cu-override') {
      menuObj.hideItems(['Link Document', 'Unlink Document']);
      if (!stip.value.documentId && !stip.value.applicantDocumentId) {
        menuObj.hideItems(['Show Document']);
      }
    } else {
      // If applicant document linked, hide link option
      if (stip.value.documentId && stip.value.documentId > 0 && stip.value.applicantDocumentId && stip.value.applicantDocumentId > 0) {
        menuObj.hideItems(['Link Document']);
        menuObj.showItems(['Unlink Document']);
      }
      // If no applicant document linked, hide doc toggler, show link document
      if (!stip.value.documentId && !stip.value.applicantDocumentId) {
        menuObj.hideItems(['Unlink Document', 'Show Document']);
        menuObj.showItems(['Link Document']);
        // sets document options
        setLinkDocumentOptions(args);
      }
    }

    setShowDocumentMenuItemTextArg(args);
  }

  // Action based stip
  if (!stip.value.isExpectedDocument) {
    handleActionBasedStips(args)
  }

  // All stips
  setEditStipTextMenuItemTextArg(args);
}

function beforeItemRender(args: MenuEventArgs) {
  if (args.item.text === 'Assign' || args.item.id?.includes('ApplicantIdSelected_')) {
    args.element.classList.add('inactive');
  }
}

function beforeOpen(args: BeforeOpenCloseMenuEventArgs) {
  const menuObj = cmenu.value;

  // Limited functionality to readonly
  // if ((isExternalUser.value)) {
  //   toggleReadOnlyFunctionality(args, menuObj);
    toggleInternalUserFunctionality(args, menuObj);

  stipulationInfo.stip = Object.assign({}, props.stipulation);
}

async function onSelect(args: MenuEventArgs) {
  if (args.item.id?.includes('StipulationStatusReasonIdSelected') && args.item.text) {
    handleStipulationStatusChange(args);
  }
  else if (args.item.id?.includes('ApplicantIdSelected') && args.item.text) {
    handleApplicantReassignment(args);
  }
  else if (args.item.id?.includes('ApplicantDocumentIdSelected') && args.item.text) {
    handleLinkDocument(args);
  }
  else if (args.item.text === 'Unlink Document') {
    handleUnlinkDocument(args);
  }
  else if (args.item.text === 'Show Details' || args.item.text === 'Hide Details') {
    emit('toggleDetails');
  }
  else if (args.item.text === 'Show Document' || args.item.text === 'Hide Document') {
    emit('toggleDocument');
  }
  else if (args.item.text === 'Edit') {
    emit('toggleEdit');
  }
  else if (args.item.text === 'Edit Stip Text') {
    emit('toggleStipText', stip.value.stipulationText);
  }
  else if (args.item.text === 'Save Stip Text') {
    emit('updateStipulationText')
  }
  else {
    console.log(`${args}`);
  }
}

watch(() => props?.stipulation.editingStipText, () => {
  stipTextMenuItem.value = props?.stipulation.editingStipText ? 'Save Stip Text' : 'Edit Stip Text';
});

watch(() => props?.stipulation?.showDocument, () => {
  showDocumemtMenuItem.value = props.stipulation.showDocument ? 'Hide Document' : 'Show Document';
});

watch(() => props?.stipulation.showDetails, (value) => {
  showDetails.value = value;
});


return (_ctx: any,_cache: any) => {
  return (applicationStatus.value === 'submitted & processing' || applicationStatus.value === 'evaluation')
    ? (_openBlock(), _createBlock(_unref(EjsContextmenu), {
        key: 0,
        ref_key: "cmenu",
        ref: cmenu,
        target: `#${contextMenuId.value}`,
        items: items.value,
        select: onSelect,
        beforeOpen: beforeOpen,
        beforeItemRender: beforeItemRender
      }, null, 8, ["target", "items"]))
    : _createCommentVNode("", true)
}
}

})