import { computed, Ref } from 'vue';
import * as yup from 'yup';
import { Draw, ServiceType, ValidDrawStatusIds } from '@/models/loans';
import { EnrollmentTermModel } from '@/models/opal';
import enrollmentStatuses from '@/models/enrollmentStatuses';

export interface ValidationData {
	selectedDraw: Ref<Draw | null>;
	mode: Ref<'view' | 'add' | 'edit'>;
	availableToDrawAmount: Ref<number | null>;
	drawStatusOptions: Ref<ServiceType[]>;
	certificationMethodOptions: Ref<ServiceType[]>;
	schoolLevelOptions: Ref<ServiceType[]>;
	enrollmentTermOptions: Ref<EnrollmentTermModel[]>;
}

const enrollmentStatusOptions: ServiceType[] = enrollmentStatuses.map((status) => ({
	id: status.value,
	name: status.label
}));

const maxRequestedAmount = 99999.99;

export function useDrawFormFields(data: ValidationData) {
	const formFields = computed(() => {
		const fields = [
			{
				name: 'school',
				label: 'School',
				type: 'school-select',
				value: {
					schoolDOEId: data.selectedDraw.value?.schoolCode || '',
					schoolBranchDOEId: data.selectedDraw.value?.schoolBranch || ''
				},
				width: 'col-4',
				showOnAdd: true,
				showOnEdit: true,
				disabledOnEdit: true
			},
			{
				name: 'schoolLevelTypeId',
				label: 'School Level',
				type: 'select',
				value: data.selectedDraw.value?.schoolLevelTypeId,
				width: 'col-4',
				options: data.schoolLevelOptions.value,
				showOnAdd: true,
				showOnEdit: true,
				disabledOnEdit: false
			},
			{
				name: 'certificationMethodId',
				label: 'Certification Method',
				type: 'select',
				value: data.selectedDraw.value?.certificationMethodId,
				width: 'col-4',
				options: data.certificationMethodOptions.value,
				showOnAdd: false,
				showOnEdit: true,
				disabledOnEdit: true
			},
			{
				name: 'enrollmentStatus',
				label: 'Enrollment Status',
				type: 'select',
				value: data.selectedDraw.value?.enrollmentStatus,
				width: 'col-4',
				options: enrollmentStatusOptions,
				showOnAdd: true,
				showOnEdit: true,
				disabledOnEdit: false
			},
			{
				name: 'enrollmentTermId',
				label: 'Enrollment Term',
				type: 'select',
				value: data.selectedDraw.value?.enrollmentTermId,
				width: 'col-4',
				options: getFilteredEnrollmentTerms(data).value,
				showOnAdd: true,
				showOnEdit: true,
				disabledOnEdit: true
			},
			{
				name: 'drawStatusTypeId',
				label: 'Draw Status',
				type: 'select',
				value: data.selectedDraw.value?.drawStatusTypeId,
				width: 'col-4',
				options: getFilteredDrawStatuses(data).value,
				showOnAdd: false,
				showOnEdit: true,
				disabledOnEdit: false
			},
			{
				name: 'requestedAmount',
				label: 'Requested Amount',
				type: 'number',
				value: data.selectedDraw.value?.requestedAmount,
				width: 'col-4',
				showOnAdd: true,
				showOnEdit: true,
				disabledOnEdit: false
			},
			{
				name: 'cluid',
				label: 'CLUID',
				type: 'string',
				value: data.selectedDraw.value?.cluid,
				width: 'col-4',
				showOnAdd: false,
				showOnEdit: true,
				disabledOnEdit: true
			},
			{
				name: 'sequenceNumber',
				label: 'Sequence',
				type: 'string',
				value: data.selectedDraw.value?.sequenceNumber,
				width: 'col-4',
				showOnAdd: false,
				showOnEdit: true,
				disabledOnEdit: true
			},
			{
				name: 'anticipatedGraduationDate',
				label: 'Anticipated Graduation Date',
				type: 'date',
				value: data.selectedDraw.value?.anticipatedGraduationDate,
				width: 'col-4',
				showOnAdd: true,
				showOnEdit: true,
				disabledOnEdit: true
			},
			{
				name: 'loanPeriodStartDate',
				label: 'Loan Period Start Date',
				type: 'date',
				value: data.selectedDraw.value?.loanPeriodStartDate,
				width: 'col-4',
				showOnAdd: false,
				showOnEdit: true,
				disabledOnEdit: true
			},
			{
				name: 'loanPeriodEndDate',
				label: 'Loan Period End Date',
				type: 'date',
				value: data.selectedDraw.value?.loanPeriodEndDate,
				width: 'col-4',
				showOnAdd: false,
				showOnEdit: true,
				disabledOnEdit: true
			}
		];

		return fields.filter((field) => {
			if (data.mode.value === 'add') {
				return field.showOnAdd !== false;
			} else if (data.mode.value === 'edit') {
				return field.showOnEdit !== false;
			}

			return true;
		});
	});

	const addSchema = computed(() => {
		return yup.object({
			school: yup
				.object()
				.shape({
					schoolDOEId: yup.string().required('School selection is required'),
					schoolBranchDOEId: yup.string().required('School selection is required')
				})
				.typeError('School selection is required'),
			schoolLevelTypeId: yup
				.number()
				.typeError('School Level is invalid')
				.required('School Level is required')
				.min(1),
			enrollmentStatus: yup
				.number()
				.typeError('Enrollment Status is invalid')
				.required('Enrollment Status is required')
				.min(1)
				.test(
					'less-than-half-for-summer-term',
					'Less than Half is only allowed for Summer terms',
					function (value) {
						const enrollmentTermId = this.parent.enrollmentTermId;
						const selectedTerm = data.enrollmentTermOptions.value.find(
							(term) => term.id === enrollmentTermId
						);
						const isSummerTerm = selectedTerm?.name?.toLowerCase().includes('summer');

						return value !== 3 || isSummerTerm;
					}
				),
			enrollmentTermId: yup
				.number()
				.typeError('Enrollment Term is invalid')
				.required('Enrollment Term is required')
				.min(1),
			requestedAmount: yup
				.number()
				.typeError('Requested Amount is invalid')
				.required('Requested Amount is required')
				.min(250, 'Must be greater than $250')
				.test(
					'is-under-max-limit',
					`Requested Amount cannot exceed $${data.availableToDrawAmount.value || maxRequestedAmount}`,
					function (value) {
						const maxLimit = data.availableToDrawAmount.value || maxRequestedAmount;
						return value <= maxLimit;
					}
				),
			anticipatedGraduationDate: yup
				.date()
				.typeError('Anticipated Graduation Date is invalid')
				.required('Anticipated Graduation Date is required')
				.test(
					'date-after-term-start',
					'Anticipated Graduation Date cannot be earlier than the Enrollment Term start date',
					function (value) {
						const enrollmentTermId = this.parent.enrollmentTermId;
						const selectedTerm = data.enrollmentTermOptions.value.find(
							(term) => term.id === enrollmentTermId
						);
						const termStartDate =
							selectedTerm && selectedTerm.startDate ? new Date(selectedTerm.startDate) : null;

						return termStartDate ? value >= termStartDate : true;
					}
				)
		});
	});

	const editSchema = computed(() => {
		return yup.object({
			schoolLevelTypeId: yup
				.number()
				.typeError('School Level is invalid')
				.required('School Level is required')
				.min(1),
			drawStatusTypeId: yup
				.number()
				.typeError('Draw Status is invalid')
				.required('Draw Status is required')
				.min(1),
			enrollmentStatus: yup
				.number()
				.typeError('Enrollment Status is invalid')
				.required('Enrollment Status is required')
				.min(1),
			requestedAmount: yup
				.number()
				.typeError('Requested Amount is invalid')
				.required('Requested Amount is required')
				.min(250, 'Must be greater than $250')
				.test(
					'is-under-max-limit',
					`Requested Amount cannot exceed $${data.availableToDrawAmount.value || maxRequestedAmount}`,
					function (value) {
						const maxLimit = data.availableToDrawAmount.value || maxRequestedAmount;
						return value <= maxLimit;
					}
				)
		});
	});

	const validationSchema = computed(() => {
		switch (data.mode.value) {
			case 'add':
				return addSchema.value;
			case 'edit':
				return editSchema.value;
			default:
				return yup.object({});
		}
	});

	return {
		formFields,
		validationSchema
	};
}

function getFilteredEnrollmentTerms(data: ValidationData) {
	return computed(() => {
		if (data.mode.value === 'view') {
			return data.enrollmentTermOptions.value;
		}

		const today = new Date();

		const filteredOptions = data.enrollmentTermOptions.value.filter((term) => {
			const visibleStartDate = term.visibleStartDate ? new Date(term.visibleStartDate) : null;
			const visibleEndDate = term.visibleEndDate ? new Date(term.visibleEndDate) : null;

			const datesAreValid =
				visibleStartDate &&
				visibleEndDate &&
				!isNaN(visibleStartDate.getTime()) &&
				!isNaN(visibleEndDate.getTime());

			const datesAreInRange =
				visibleStartDate && visibleEndDate && visibleStartDate <= today && visibleEndDate >= today;

			return datesAreValid && datesAreInRange;
		});

		if (data.mode.value === 'edit' && data.selectedDraw.value?.enrollmentTermId !== null) {
			const selectedOption = data.enrollmentTermOptions.value.find(
				(term) => term.id === data.selectedDraw.value?.enrollmentTermId
			);

			if (selectedOption && !filteredOptions.includes(selectedOption)) {
				filteredOptions.push(selectedOption);
			}
		}

		return filteredOptions;
	});
}

function getFilteredDrawStatuses(data: ValidationData) {
	return computed(() => {
		if (data.mode.value === 'view') {
			return data.drawStatusOptions.value;
		}

		const filteredOptions = data.drawStatusOptions.value.filter((status) => ValidDrawStatusIds.includes(status.id));

		if (data.mode.value === 'edit' && data.selectedDraw.value?.drawStatusTypeId !== null) {
			const selectedOption = data.drawStatusOptions.value.find(
				(status) => status.id === data.selectedDraw.value?.drawStatusTypeId
			);

			if (selectedOption && !filteredOptions.includes(selectedOption)) {
				filteredOptions.push(selectedOption);
			}
		}

		return filteredOptions;
	});
}
