import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content" }

import '@uppy/dashboard/dist/style.css';
	import { ref, onMounted, onBeforeUnmount, computed, watch } from 'vue';
	import { useStore } from 'vuex';
	import { getEnvironment } from '@/config/oidc';
	import Uppy, { Uppy as UppyType } from '@uppy/core';
	import Dashboard from '@uppy/dashboard';
	import XHRUpload from '@uppy/xhr-upload';
	import type { ComponentPublicInstance } from 'vue';
	import UploadDocumentForm from '@/components/loan/documents/UploadDocumentForm.vue';
	import { useFormFields } from '@/composables/loan/documents/useUploadDocumentForm';
	import { useDocumentData } from '@/composables/loan/documents/useDocumentData';
	import { Borrower } from '@/models/loans';

	interface UploadDocumentFormInstance {
		isFormValid: () => boolean;
		formData: () => Record<string, any>;
	}

	interface FormData {
		borrowerId: number;
		documentTypeId: number;
		borrowerVisible: boolean;
	}

	
export default /*@__PURE__*/_defineComponent({
  __name: 'LoanDocumentUploader',
  props: {
    loanId: {},
    borrowers: {}
  },
  setup(__props: any) {

	const store = useStore();

	const props = __props;

	const uppyInstance = ref<UppyType | null>(null);
	const dashboardElement = ref<HTMLDivElement | null>(null);

	const uploadFormRef = ref<(ComponentPublicInstance & UploadDocumentFormInstance) | null>(null);

	const isFormValid = computed(() => uploadFormRef.value?.isFormValid);
	const formData = computed(() => uploadFormRef.value?.formData);

	const isUploading = ref<boolean>(false);

	const { documentTypeOptions } = useDocumentData();

	const { formFields, validationSchema } = useFormFields({
		borrowers: props.borrowers,
		documentTypeOptions
	});

	onMounted(() => {
		uppyInstance.value = new Uppy({
			restrictions: {
				maxNumberOfFiles: 1,
				allowedFileTypes: [
					'.doc',
					'.docx',
					'.pdf',
					'.png',
					'.jpg',
					'.jpeg',
					'.heic',
					'.tif',
					'.tiff',
					'.xls',
					'.xlsx'
				]
			},
			debug: false,
			autoProceed: false,
			infoTimeout: 6000
		});

		if (!dashboardElement.value) {
			return;
		}

		uppyInstance.value.use(Dashboard, {
			target: dashboardElement.value,
			showProgressDetails: true,
			showSelectedFiles: true,
			showLinkToFileUploadResult: true,
			proudlyDisplayPoweredByUppy: false,
			inline: true,
			width: '100%',
			disabled: !isFormValid.value
		});

		uppyInstance.value.use(XHRUpload, {
			endpoint: `${getEnvironment().documents}/api/v1/docUploadService/uploaddocument/`,
			formData: true,
			fieldName: 'documentToUpload',
			bundle: true,
			withCredentials: true,
			headers: {
				Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
				Accept: '*/*'
			}
		});

		uppyInstance.value.on('complete', (result) => {
			console.log('Upload complete:', result.successful);
		});

		uppyInstance.value.on('upload', () => {
			const data = { ...formData.value } as FormData;

			uppyInstance.value?.setMeta({
				entityTypeId: 2, // loan
				entityId: props.loanId,
				personId: data.borrowerId,
				documentTypeId: data.documentTypeId,
				applicantVisible: data.borrowerVisible
			});
		});
	});

	onBeforeUnmount(() => {
		uppyInstance.value?.close();
	});

	watch(isFormValid, (newVal) => {
		if (uppyInstance.value) {
			uppyInstance.value.getPlugin('Dashboard')?.setOptions({
				disabled: !newVal
			});
		}
	});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(UploadDocumentForm, {
      ref_key: "uploadFormRef",
      ref: uploadFormRef,
      isUploading: isUploading.value,
      borrowers: _ctx.borrowers,
      fields: _unref(formFields),
      validationSchema: _unref(validationSchema)
    }, null, 8, ["isUploading", "borrowers", "fields", "validationSchema"]),
    _createElementVNode("div", {
      ref_key: "dashboardElement",
      ref: dashboardElement
    }, null, 512)
  ]))
}
}

})