<template>
	<div
		v-if="
			props.notificationsData &&
			props.notificationsData.length > 0 &&
			incompleteCount() > 0
		"
		class="xnotifications"
		:data-status="incompleteCount() > 0 ? 'Incomplete' : 'Complete'">
		<div class="xnotification-close">
			<i
				:class="[notificationsOpen ? 'icon-up-open-big' : 'icon-down-open-big']"
				@click="notificationsOpen = !notificationsOpen"></i>
		</div>

		<div
			class="xnotification-header"
			:class="{ hoverclass: !notificationsOpen }"
			@click="notificationsOpen = !notificationsOpen">
			<i class="icon-ok-circle"></i>
			<span v-if="incompleteCount() > 1"
				>There are {{ incompleteCount() }} incomplete tasks</span
			>
			<span v-else-if="incompleteCount() == 1">{{
				notificationsData[0].title
			}}</span>
			<span v-else-if="incompleteCount() < 1"
				>All tasks completed for this section! Click for details.</span
			>
		</div>

		<div class="xnotification-wrapper" v-if="notificationsOpen">
			<div
				v-for="(notification, index) in notificationsComputed"
				class="xnotification"
				:data-status="notification.status"
				:key="index"
				:value="notification.id">
				<div class="xnotification-status">
					<i>{{ notification.status }}</i>
				</div>
				<div class="xnotification-count">{{ notification.id }}</div>
				<div class="xnotification-message">
					<div class="xnotification-title">{{ notification.title }}</div>
					<div class="xnotification-text">{{ notification.text }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { computed, defineProps, ref } from 'vue';

	const props = defineProps(['notificationsData']);
	var notificationsOpen = ref(false);

	function incompleteCount() {
		return props.notificationsData.filter(
			(notification) => notification.status == 'Incomplete'
		).length;
	}

	const notificationsComputed = computed(function () {
		var data = props.notificationsData;

		// put all incomplete notifications at the front of the array
		return data.sort((a, b) => b.status.localeCompare(a.status));
	});
</script>

<style scoped>
	div.xnotifications {
		font-family: 'Montserrat';
		border-radius: 4px;
		margin-bottom: 30px;
	}

	div.xnotification {
		font-family: Montserrat, arial, sans-serif;
		font-weight: 700;
		padding: 10px 20px;
		border-left: 10px solid #ffc539;
		background: #fffdf8;
		margin: 0;
		box-shadow: 2px 0px 4px #0000001a;
	}

	div.xnotification[data-status='Complete'] {
		border-left: 10px solid #0ecb50;
	}

	div.xnotification-header {
		display: block;
		align-items: center;
		cursor: pointer;
		border: 1px solid #ffeab4;
		border-left: 10px solid #ffc539;
		border-bottom: 0;
		padding: 10px 22px;
		background: #fff6e0;
		box-shadow: 2px 3px 4px #0000001a;
		-webkit-user-select: none;
		-moz-user-select: none;
		user-select: none;
	}

	div.xnotification-header.hoverclass:hover {
		background: #fff9ea;
	}

	div.xnotification-header i {
		margin-right: 15px;
		font-size: 37px;
		color: #ffc539;
		vertical-align: middle;
	}

	div.xnotification-header span {
		vertical-align: middle;
	}

	div.xnotifications[data-status='Complete'] div.xnotification-header {
		border: 1px solid #c3e9c3;
		border-left: 10px solid #0ecb50;
		background: #dff3df;
	}

	div.xnotifications[data-status='Complete'] div.xnotification-header:hover {
		background: #ebf5eb;
	}

	div.xnotifications[data-status='Complete'] div.xnotification-header i {
		color: #0ecb50;
	}

	div.xnotification-count {
		display: inline-block;
		vertical-align: top;
		width: 26px;
		height: 26px;
		border: 2px solid black;
		border-radius: 30px;
		text-align: center;
		line-height: 22px;
		margin-right: 10px;
		margin-bottom: 20px;
	}

	div.xnotification-status {
		display: block;
	}

	div.xnotification-status i {
		background: rgb(255 184 0);
		display: inline-block;
		border-radius: 25px;
		padding: 3px 12px;
		color: white;
		font-size: 11px;
		font-style: normal;
		margin-bottom: 6px;
		margin-left: 0;
	}

	div.xnotification[data-status='Complete'] div.xnotification-status i {
		background: #0ecb50;
	}

	div.xnotification-message {
		display: inline-block;
	}

	div.xnotification-title {
		font-size: 18px;
		line-height: 26px;
		white-space: nowrap;
	}

	div.xnotification-text {
		font-size: 12px;
		font-weight: 600;
		color: #bbbbbb;
		padding-top: 1px;
		white-space: nowrap;
	}

	div.xnotification-close {
		float: right;
		color: #000000a6;
		width: 30px;
		height: 30px;
		margin-top: 20px;
		margin-right: 20px;
		text-align: center;
		line-height: 30px;
		font-family: 'Montserrat', arial;
		font-weight: 700;
		font-size: 21px;
		cursor: pointer;
	}

	@media screen and (max-width: 620px) {
		div.xnotification-header[data-v-43a3e4e8] {
			padding: 10px 16px;
		}

		div.xnotification-header i {
			margin-right: 10px;
			font-size: 25px;
		}

		div.xnotification-header span {
			font-size: 11px;
			white-space: nowrap;
			width: 65%;
			display: inline-block;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		div.xnotification-close[data-v-43a3e4e8] {
			margin-top: 13px;
			margin-right: 11px;
			font-size: 15px;
		}

		div.xnotification-count {
			display: none;
		}

		div.xnotification-title[data-v-43a3e4e8] {
			line-height: 19px;
			margin-bottom: 4px;
			white-space: unset;
			font-size: 13px;
		}

		div.xnotification-text[data-v-43a3e4e8] {
			white-space: unset;
		}
	}
</style>
