import { computed, Ref } from 'vue';
import * as yup from 'yup';
import { PayoffDetails, ServiceType } from '@/models/loans';

export interface ValidationData {
	payoff: Ref<PayoffDetails>;
	mode: Ref<'view' | 'edit'>;
	payoffDisbursementStatusOptions: Ref<ServiceType[]>;
	payoffDisbursementStatusReasonOptions: Ref<ServiceType[]>;
	payoffDisbursementTypeOptions: Ref<ServiceType[]>;
}

const validReasonIdsByStatus: Record<number, number[]> = {
	1: [1, 2], // waiting to be batched, already batched
	2: [3], // batch approved
	3: [4], // sent to servicer
	4: [5], // approved cx after rtc
	5: [6, 7, 8] // overpayment, full cx, stopped payment/reissue
};

export function useFormFields(data: ValidationData) {
	const formFields = computed(() => {
		const fields = [
			{
				name: 'disbursementAmount',
				label: 'Amount',
				type: 'number',
				value: data.payoff.value.adjustedDisbursementAmount,
				width: 'col-3',
				showOnEdit: true,
				disabledOnEdit: true
			},
			{
				name: 'payoffFees',
				label: 'Fees',
				type: 'number',
				value: data.payoff.value.adjustedFees,
				width: 'col-3',
				showOnEdit: true,
				disabledOnEdit: true
			},
			{
				name: 'totalAmount',
				label: 'Total Amount',
				type: 'number',
				value: data.payoff.value.adjustedDisbursementAmount + data.payoff.value.adjustedFees,
				width: 'col-3',
				showOnEdit: true,
				disabledOnEdit: true
			},
			{
				name: 'transactionDate',
				label: 'Date Sent',
				type: 'date',
				value: data.payoff.value.transactionDate,
				width: 'col-3',
				showOnEdit: true,
				disabledOnEdit: true
			},
			{
				name: 'settleDate',
				label: 'Settle Date',
				type: 'date',
				value: data.payoff.value.settleDate,
				width: 'col-3',
				showOnEdit: true,
				disabledOnEdit: false
			},
			{
				name: 'payoffDisbursementStatusId',
				label: 'Status',
				type: 'select',
				value: data.payoff.value.payoffDisbursementStatusId,
				width: 'col-3',
				options: data.payoffDisbursementStatusOptions.value,
				showOnEdit: true,
				disabledOnEdit: false
			},
			{
				name: 'payoffDisbursementStatusReasonId',
				label: 'Reason',
				type: 'select',
				value: data.payoff.value.payoffDisbursementStatusReasonId,
				width: 'col-3',
				options: data.payoffDisbursementStatusReasonOptions.value,
				showOnEdit: true,
				disabledOnEdit: false
			},
			{
				name: 'payoffDisbursementTypeId',
				label: 'Method',
				type: 'select',
				value: data.payoff.value.payoffDisbursementTypeId,
				width: 'col-3',
				options: data.payoffDisbursementTypeOptions.value,
				showOnEdit: true,
				disabledOnEdit: true
			}
		];

		return fields.filter((field) => {
			if (data.mode.value === 'edit') {
				return field.showOnEdit !== false;
			}

			return true;
		});
	});

	const editSchema = computed(() => {
		return yup.object({
			settleDate: yup.date().typeError('Settle Date is invalid').required('Settle Date is required'),
			payoffDisbursementStatusId: yup
				.number()
				.typeError('Status is invalid')
				.required('Status is required')
				.min(1),
			payoffDisbursementStatusReasonId: yup
				.number()
				.typeError('Reason is invalid')
				.required('Reason is required')
				.when('payoffDisbursementStatusId', (statusId, schema) => {
					const validStatusId = Array.isArray(statusId) ? statusId[0] : statusId;

					if (typeof validStatusId !== 'number') {
						return schema;
					}

					// get valid reason ids
					const validReasonIds = validReasonIdsByStatus[validStatusId] || [];

					// get valid reason names
					const validReasonNames = data.payoffDisbursementStatusReasonOptions.value
						.filter((reason) => validReasonIds.includes(reason.id))
						.map((reason) => reason.name);

					return schema.oneOf(validReasonIds, `Reason must be one of: ${validReasonNames.join(', ')}`);
				})
		});
	});

	const validationSchema = computed(() => {
		switch (data.mode.value) {
			case 'edit':
				return editSchema.value;
			default:
				return yup.object({});
		}
	});

	return {
		formFields,
		validationSchema
	};
}
