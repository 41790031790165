import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"




export default /*@__PURE__*/_defineComponent({
  __name: 'CircleLoader',
  props: {
  stretch: Boolean,
  inverse: Boolean
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["loader-wrapper", {
      stretched: __props.stretch,
      inverse: __props.inverse,
    }])
  }, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "loader" }, null, -1)
  ]), 2))
}
}

})