import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "xpage-content-page",
  id: "xesign-tab"
}
const _hoisted_2 = { class: "xtitlearea" }

import { computed } from "vue";
import ApplicationComments from "@/components/ApplicationComments.vue";
import EsignEnvelopes from "@/components/esign/EsignEnvelopes.vue";

interface Props {
  isTabActive: boolean;
  commentsId: any;
  commentsComponentKey: any;
  applicants: any;
  application: any;
  store?: any;
  notificationsData: any;
  canEdit: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ESignTab',
  props: {
    isTabActive: { type: Boolean },
    commentsId: {},
    commentsComponentKey: {},
    applicants: {},
    application: {},
    store: {},
    notificationsData: {},
    canEdit: { type: Boolean }
  },
  emits: ["setEsignData"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;
const commentData = computed(() => props.application.commentData);

return (_ctx: any,_cache: any) => {
  return (_ctx.isTabActive)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[1] || (_cache[1] = _createElementVNode("h2", null, "E-Sign", -1)),
          _createVNode(ApplicationComments, {
            type: "esign",
            id: _ctx.commentsId,
            comments: commentData.value
          }, null, 8, ["id", "comments"])
        ]),
        _createVNode(EsignEnvelopes, {
          application: _ctx.application,
          onSetEsignData: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('setEsignData', $event))),
          canEdit: _ctx.canEdit
        }, null, 8, ["application", "canEdit"])
      ]))
    : _createCommentVNode("", true)
}
}

})