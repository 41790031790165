import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "xform-section"
}
const _hoisted_2 = { class: "xfields" }
const _hoisted_3 = { class: "xfield" }
const _hoisted_4 = ["value"]
const _hoisted_5 = ["value"]
const _hoisted_6 = { class: "xfield" }
const _hoisted_7 = ["value"]


import PermissionsWrapper from "@/components/wrappers/PermissionsWrapper.vue";
import { Permissions, PermissionLevels } from "@/models/permissions";


export default /*@__PURE__*/_defineComponent({
  __name: 'ApplicationSlrRefinanceSection',
  props: {
    current: {},
    application: {},
    highestDegreeLevel: {},
    schoolNameVM: {},
    degreeProgramVM: {},
    canEdit: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (
				_ctx.current === 'Primary Applicant' &&
				_ctx.application?.data?.schoolConfiguration
			)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _cache[3] || (_cache[3] = _createElementVNode("h3", null, "School", -1)),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _cache[0] || (_cache[0] = _createElementVNode("label", null, "Highest Degree Level", -1)),
              _createElementVNode("input", {
                type: "text",
                value: _ctx.highestDegreeLevel,
                class: "input-field-textarea",
                placeholder: "School Name",
                disabled: ""
              }, null, 8, _hoisted_4)
            ]),
            _createVNode(PermissionsWrapper, {
              class: "xfield",
              disabled: !_ctx.canEdit,
              permissionIds: [_unref(Permissions).ProgramRequirements],
              permissionLevel: _unref(PermissionLevels).Update
            }, {
              default: _withCtx(() => [
                _cache[1] || (_cache[1] = _createElementVNode("label", null, "School Name", -1)),
                _createElementVNode("input", {
                  type: "text",
                  value: _ctx.schoolNameVM,
                  class: "input-field-textarea",
                  placeholder: "School Name"
                }, null, 8, _hoisted_5)
              ]),
              _: 1
            }, 8, ["disabled", "permissionIds", "permissionLevel"]),
            _createElementVNode("div", _hoisted_6, [
              _cache[2] || (_cache[2] = _createElementVNode("label", null, "Degree Program", -1)),
              _createElementVNode("input", {
                type: "text",
                value: _ctx.degreeProgramVM,
                class: "input-field-textarea",
                placeholder: "Degree Program",
                disabled: ""
              }, null, 8, _hoisted_7)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})