import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "xpage-tabs" }
const _hoisted_2 = ["data-status"]
const _hoisted_3 = ["data-status"]
const _hoisted_4 = ["data-status"]
const _hoisted_5 = ["data-status"]

import { computed } from "vue";
import { Permissions, PermissionLevels } from "@/models/permissions";
import PermissionsWrapper from "@/components/wrappers/PermissionsWrapper.vue";
import { useProgramRequirementsStore } from "@/store/store/programRequirementsStore";
import { ApplicationStatusIds } from "@/types/application";
import { TagTypeIds } from "@/types/tag";

interface Props {
  application: {
    tabs: any;
    tags: [
      {
        tagTypeId: number;
      }
    ];
    data: {
      id: number;
      applicationStatusId: number;
    };
  };
  openTab: any;
  isTabActive: any;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ApplicationTabsGroup',
  props: {
    application: {},
    openTab: {},
    isTabActive: {}
  },
  emits: ["toggleApplicationDetails"],
  setup(__props: any, { emit: __emit }) {

const programRequirementsStore = useProgramRequirementsStore();

const props = __props;

const emits = __emit;

const hasFinalReviewTag = computed(() =>
  props.application.tags.some((tag) => tag.tagTypeId == TagTypeIds.FinalReview)
);

const showEvaluationsTab = computed(() => {
  const isReadOnlyLocal =
    props.application.data.applicationStatusId !==
    ApplicationStatusIds.EVALUATION;
  return {
    show: true,
    isReadOnlyLocal: isReadOnlyLocal,
  };
});

const showOverridesTab = computed(() =>
  [
    ApplicationStatusIds.SUBMITTED_AND_PROCESSING,
    ApplicationStatusIds.DECLINED,
    ApplicationStatusIds.CANCELLED,
  ].includes(props.application?.data?.applicationStatusId)
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(PermissionsWrapper, {
      permissionLevel: _unref(PermissionLevels).Read
    }, {
      default: _withCtx(() => [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openTab('application'))),
          class: _normalizeClass({ xselected: _ctx.isTabActive('application') })
        }, [
          _createElementVNode("i", {
            class: "icon-ok-circle",
            "data-status": _ctx.application.tabs.application
          }, null, 8, _hoisted_2),
          _cache[13] || (_cache[13] = _createTextVNode(" Application "))
        ], 2)
      ]),
      _: 1
    }, 8, ["permissionLevel"]),
    _createVNode(PermissionsWrapper, {
      permissionIds: [_unref(Permissions).ProofOfMembership],
      permissionLevel: _unref(PermissionLevels).Read
    }, {
      default: _withCtx(() => [
        _createElementVNode("button", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openTab('membership'))),
          class: _normalizeClass({ xselected: _ctx.isTabActive('membership') })
        }, [
          _createElementVNode("i", {
            class: "icon-ok-circle",
            "data-status": _ctx.application.tabs.membership
          }, null, 8, _hoisted_3),
          _cache[14] || (_cache[14] = _createTextVNode(" Membership "))
        ], 2)
      ]),
      _: 1
    }, 8, ["permissionIds", "permissionLevel"]),
    _createVNode(PermissionsWrapper, {
      permissionIds: [_unref(Permissions).Identity],
      permissionLevel: _unref(PermissionLevels).Read
    }, {
      default: _withCtx(() => [
        _createElementVNode("button", {
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openTab('identity'))),
          class: _normalizeClass({ xselected: _ctx.isTabActive('identity') })
        }, [
          _createElementVNode("i", {
            class: "icon-ok-circle",
            "data-status": _unref(programRequirementsStore).identityTabCheckmarkStatus
          }, null, 8, _hoisted_4),
          _cache[15] || (_cache[15] = _createTextVNode(" Identity "))
        ], 2)
      ]),
      _: 1
    }, 8, ["permissionIds", "permissionLevel"]),
    _createVNode(PermissionsWrapper, {
      permissionIds: [_unref(Permissions).Pricing],
      permissionLevel: _unref(PermissionLevels).Read
    }, {
      default: _withCtx(() => [
        _createElementVNode("button", {
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openTab('pricing'))),
          class: _normalizeClass({ xselected: _ctx.isTabActive('pricing') })
        }, " Pricing ", 2)
      ]),
      _: 1
    }, 8, ["permissionIds", "permissionLevel"]),
    _createVNode(PermissionsWrapper, {
      permissionIds: [_unref(Permissions).Income],
      permissionLevel: _unref(PermissionLevels).Read
    }, {
      default: _withCtx(() => [
        _createElementVNode("button", {
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.openTab('income'))),
          class: _normalizeClass({ xselected: _ctx.isTabActive('income') })
        }, [
          _createElementVNode("i", {
            class: "icon-ok-circle",
            "data-status": _ctx.application.tabs.income
          }, null, 8, _hoisted_5),
          _cache[16] || (_cache[16] = _createTextVNode("Income "))
        ], 2)
      ]),
      _: 1
    }, 8, ["permissionIds", "permissionLevel"]),
    _createVNode(PermissionsWrapper, {
      permissionIds: [_unref(Permissions).Credit],
      permissionLevel: _unref(PermissionLevels).Read
    }, {
      default: _withCtx(() => [
        _createElementVNode("button", {
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.openTab('credit'))),
          class: _normalizeClass({ xselected: _ctx.isTabActive('credit') })
        }, " Credit ", 2)
      ]),
      _: 1
    }, 8, ["permissionIds", "permissionLevel"]),
    _createVNode(PermissionsWrapper, {
      permissionIds: [_unref(Permissions).Evaluation],
      permissionLevel: _unref(PermissionLevels).Read
    }, {
      default: _withCtx(() => [
        (showEvaluationsTab.value.show)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.openTab('evaluations'))),
              class: _normalizeClass({
          xselected: _ctx.isTabActive('evaluations'),
        })
            }, " Evaluation ", 2))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["permissionIds", "permissionLevel"]),
    _createVNode(PermissionsWrapper, {
      permissionIds: [_unref(Permissions).Overrides],
      permissionLevel: _unref(PermissionLevels).Read
    }, {
      default: _withCtx(() => [
        (showOverridesTab.value)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.openTab('overrides'))),
              class: _normalizeClass({ xselected: _ctx.isTabActive('overrides') })
            }, " Overrides ", 2))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["permissionIds", "permissionLevel"]),
    _createVNode(PermissionsWrapper, {
      permissionIds: [_unref(Permissions).ProgramRequirements],
      permissionLevel: _unref(PermissionLevels).Read
    }, {
      default: _withCtx(() => [
        _createElementVNode("button", {
          onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.openTab('stipulations'))),
          class: _normalizeClass({ xselected: _ctx.isTabActive('stipulations') })
        }, " Stipulations ", 2)
      ]),
      _: 1
    }, 8, ["permissionIds", "permissionLevel"]),
    _createVNode(PermissionsWrapper, {
      permissionIds: [_unref(Permissions).FinalReview],
      permissionLevel: _unref(PermissionLevels).Read
    }, {
      default: _withCtx(() => [
        (hasFinalReviewTag.value)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.openTab('final-review'))),
              class: _normalizeClass({ xselected: _ctx.isTabActive('final-review') })
            }, " Final Review ", 2))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["permissionIds", "permissionLevel"]),
    _createVNode(PermissionsWrapper, {
      permissionIds: [_unref(Permissions).DocumentProcessing],
      permissionLevel: _unref(PermissionLevels).Read
    }, {
      default: _withCtx(() => [
        _createElementVNode("button", {
          onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.openTab('documents'))),
          class: _normalizeClass({ xselected: _ctx.isTabActive('documents') })
        }, " Documents ", 2)
      ]),
      _: 1
    }, 8, ["permissionIds", "permissionLevel"]),
    _createVNode(PermissionsWrapper, {
      permissionIds: [_unref(Permissions).Esign],
      permissionLevel: _unref(PermissionLevels).Read
    }, {
      default: _withCtx(() => [
        _createElementVNode("button", {
          onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.openTab('esign'))),
          class: _normalizeClass({ xselected: _ctx.isTabActive('esign') })
        }, " E-Sign ", 2)
      ]),
      _: 1
    }, 8, ["permissionIds", "permissionLevel"]),
    _createVNode(PermissionsWrapper, {
      permissionLevel: _unref(PermissionLevels).Read
    }, {
      default: _withCtx(() => [
        _createElementVNode("button", {
          onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.openTab('history'))),
          class: _normalizeClass({ xselected: _ctx.isTabActive('history') }),
          style: {"display":"none"}
        }, " History ", 2)
      ]),
      _: 1
    }, 8, ["permissionLevel"]),
    _cache[17] || (_cache[17] = _createElementVNode("div", { class: "clear" }, null, -1))
  ]))
}
}

})