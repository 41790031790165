import { defineStore, storeToRefs } from "pinia";
import { ref, computed } from "vue";
import {
  IncomeDocument,
  Applicant,
  LOSDocument,
  Income,
  AuthorizedThirdParty,
} from "@/models/opal";
import { useDocumentsTabStore } from "@/store/store/documentsTabStore";
import { useApplicantsStore } from "@/store/store/applicantsStore";
import { FormSchema, FieldSchema, Option } from "@/models/form";
import useFormatter from "@/use/formatter";
import { useTypeStore } from "@/store/store/typeStore";
import functions from "@/use/functions";
import { ProductIds } from "@/types/types";
import { useApplicationStore } from "@/store/store/applicationVMStore";
import {
  AddOrUpdateIncomeRequest,
  ApplicantIncomeViewModel,
} from "@/models/incomes";

export const useIncomeTabStore = defineStore("incomeTab", () => {
  const typesStore = useTypeStore();

  const { formatMoney } = useFormatter();

  const incomeDocuments = ref<Array<IncomeDocument>>([]);
  const { documents } = storeToRefs(useDocumentsTabStore());
  const { applicants } = storeToRefs(useApplicantsStore());
  const { productTypeId } = storeToRefs(useApplicationStore());
  const selectedApplicant = computed<Applicant | AuthorizedThirdParty>(
    () => applicants.value[applicants.value.selected]
  );
  const selectedApplicantIncomes = computed<Array<Income>>(
    () => applicants.value[applicants.value.selected].incomes || []
  );
  const currentlyEditingIncomeIds = ref<Array<number>>([] as number[]);
  const currentlyViewingDetailIds = ref<Array<number>>([] as number[]);

  const incomePeriodTypeOptions = computed(
    () => typesStore.getIncomePeriodTypes
  );

  const schema = ref({
    incomeYearTypeId: {
      name: "incomeYearTypeId",
      type: "select",
      hideLabel: true,
      hideErrorMessage: true,
      required: true,
    } as FieldSchema,
    applicantEmploymentName: {
      name: "applicantEmploymentName",
      type: "text",
      hideLabel: true,
      hideErrorMessage: true,
    } as FieldSchema,
    applicantEmploymentTypeId: {
      name: "applicantEmploymentTypeId",
      type: "select",
      hideLabel: true,
      hideErrorMessage: true,
    } as FieldSchema,
    incomeTypeId: {
      name: "incomeType",
      type: "select",
      hideLabel: true,
      required: true,
      hideErrorMessage: true,
    } as FieldSchema,
    incomeAmount: {
      name: "incomeAmount",
      type: "text",
      hideLabel: true,
      required: true,
      hideErrorMessage: true,
    } as FieldSchema,
    incomePeriodTypeId: {
      name: "incomePeriodTypeId",
      type: "select",
      hideLabel: true,
      required: true,
      hideErrorMessage: true,
    } as FieldSchema,
    incomeStatusTypeId: {
      name: "incomeStatus",
      type: "select",
      hideLabel: true,
      // required: true,
      hideErrorMessage: true,
    } as FieldSchema,
  } as FormSchema);

  function getSchemas() {
    const schemas = {} as any;
    incomeDocuments.value?.forEach((incomeDocument: IncomeDocument) => {
      incomeDocument?.incomes?.forEach((income: Income) => {
        schemas[income.id] = schema;
      });
    });

    return schemas;
  }
  function getIncomeDocumentsByApplicant() {
    // reset income docs
    incomeDocuments.value = [];

    const uploadedDocuments = documents.value?.filter(
      (d: LOSDocument) =>
        d.documentStatusTypeName.trim() !== "Generated" &&
        d.applicantId === selectedApplicant.value?.id
    );
    for (const doc of uploadedDocuments) {
      const document = {
        ...doc,
        documentName: doc?.uploadedDocumentMetadata?.originalFileName
          ? doc.uploadedDocumentMetadata.originalFileName
          : "Unknown",
        year: doc.documentYear
          ? new Date(doc.documentYear).getFullYear()
          : null,
        formDisabled: true,
        editStatus: false,
        editYear: false,
        editType: false,
        showDocument: false,
        showStatusReasonSection: false,
        showStatusReasonCommentArea: false,
        incomeTabVisible: doc.documentCategories.find(
          (dc: any) => dc.categoryName === "Income"
        )
          ? true
          : false,
        comment: "",
        incomes: [],
      } as IncomeDocument;

      if (document.incomeTabVisible) {
        const index = incomeDocuments.value.findIndex(
          (item: IncomeDocument) =>
            item.applicantDocumentId === document.applicantDocumentId
        );

        if (index !== -1) {
          incomeDocuments.value[index] = document;
        } else {
          incomeDocuments.value?.push(document);
        }
      }
    }

    // Link incomes to each income doc if income docs exist
    if (incomeDocuments.value?.length > 0) {
      if (
        selectedApplicantIncomes.value &&
        selectedApplicantIncomes.value?.length > 0
      ) {
        for (const incomeDocument of incomeDocuments.value) {
          for (const income of selectedApplicantIncomes.value) {
            if (
              income.applicantDocumentId === incomeDocument.applicantDocumentId
            ) {
              incomeDocument.incomes.push(income);
              // incomeDocument.incomeFormSchemas[income.id] = schema
            }
          }
        }
      }
    }
  }

  function updateIncomeDocument(
    applicantDocumentId: number,
    updatedIncomeDocument: IncomeDocument
  ) {
    const index = incomeDocuments.value.findIndex(
      (item: IncomeDocument) => item.applicantDocumentId === applicantDocumentId
    );

    if (index !== -1) {
      incomeDocuments.value[index] = updatedIncomeDocument;
    }
  }

  function toggleIncomeDocumentPdfViewer(incomeDocument: IncomeDocument) {
    const incomeDocumentIndex = incomeDocuments.value.findIndex(
      (item: IncomeDocument) =>
        item.applicantDocumentId === incomeDocument.applicantDocumentId
    );
    if (incomeDocumentIndex !== -1) {
      incomeDocuments.value[incomeDocumentIndex].showDocument =
        !incomeDocuments.value[incomeDocumentIndex].showDocument;
    }
  }

  function toggleEdit(incomeDocument: IncomeDocument, income: Income) {
    const incomeId = income.id || income.incomeId;
    const index = currentlyEditingIncomeIds.value.findIndex(
      (id) => id === incomeId
    );

    if (index === -1) {
      currentlyEditingIncomeIds.value.push(incomeId);
    }
  }

  function isEditing(income: Income) {
    const incomeId = income?.id || income?.incomeId;
    return currentlyEditingIncomeIds.value?.includes(incomeId) || false;
  }

  function isIncomeDetailShowing(incomeDocument: IncomeDocument) {
    const applicantDocumentId = incomeDocument.applicantDocumentId;
    return currentlyViewingDetailIds.value?.includes(applicantDocumentId);
  }

  function untoggleEdit(income: Income) {
    const incomeId = income.id || income.incomeId;
    const index = currentlyEditingIncomeIds.value.findIndex(
      (id) => id === incomeId
    );

    if (index !== -1) {
      currentlyEditingIncomeIds.value.splice(index, 1);
    }
  }

  function cancel(income: Income) {
    const incomeId = income.id || income.incomeId;
    const index = currentlyEditingIncomeIds.value.findIndex(
      (id) => id === incomeId
    );

    if (index === -1) {
      currentlyEditingIncomeIds.value.push(incomeId);
    }
  }
  function calculateIncomeTotal(
    income: any,
    incomePeriodTypeId: number,
    incomePeriodTypeName?: string
  ) {
    if (income && incomePeriodTypeId) {
      const incomeTypes = {
        Annual: 1,
        Monthly: 12,
        "Bi-Weekly": 26,
        Weekly: 52,
        Bonus: 1,
        "Semi-Monthly": 24,
        Quarterly: 4,
      };

      if (incomePeriodTypeName) {
        if (productTypeId.value === ProductIds.REFI) {
          const id =
            typeof income.incomePeriodTypeId === "string"
              ? parseInt(income.incomePeriodTypeId)
              : income.incomePeriodTypeId;
          const incomePeriodTypeOption =
            incomePeriodTypeOptions.value?.find((o: Option) => o.value === id)
              ?.label || "";
          income.incomeYearAmount =
            parseFloat(income.incomeAmount) *
            incomeTypes[incomePeriodTypeOption];
          return formatMoney(income.incomeYearAmount);
        } else {
          income.incomeTotal =
            parseFloat(income.incomeAmount) * incomeTypes[incomePeriodTypeName];
          return formatMoney(income.incomeTotal);
        }
      }
    }
  }

  function toggleDetails(incomeDocument: IncomeDocument) {
    // add to array if not present
    const index = currentlyViewingDetailIds.value.findIndex(
      (id) => id === incomeDocument.applicantDocumentId
    );

    if (index === -1) {
      currentlyViewingDetailIds.value.push(incomeDocument.applicantDocumentId);
    } else {
      currentlyViewingDetailIds.value.splice(index, 1);
    }
  }

  function discardChanges(
    incomeDocument: IncomeDocument,
    originalIncome: Income
  ) {
    const incomeId = originalIncome.id || originalIncome.incomeId;

    const incomeDocumentIndex = incomeDocuments.value.findIndex(
      (item: IncomeDocument) =>
        item.applicantDocumentId === incomeDocument.applicantDocumentId
    );
    const incomeDocumentToEdit = incomeDocuments.value[incomeDocumentIndex];
    const index = incomeDocumentToEdit.incomes.findIndex(
      (income: Income) => incomeId === income.id
    );

    if (index !== -1) {
      incomeDocumentToEdit.incomes[index] = originalIncome;
    }
  }

  function addOrUpdateIncomeRequest(
    applicantId: number,
    income: ApplicantIncomeViewModel
  ): AddOrUpdateIncomeRequest {
    const addOrUpdateIncomeRequest: AddOrUpdateIncomeRequest = {
      applicantDocumentId: income.applicantDocumentId,
      employerName: income.applicantEmploymentName,
      verified: true,
      incomeYearTypeId: income.incomeYearTypeId,
      incomePeriodTypeId: income.incomePeriodTypeId,
      incomeAmount: income.incomeAmount,
      incomeTypeId: income.incomeTypeId,
      incomeId: income.id || 0,
      applicantId: applicantId,
    };
    return addOrUpdateIncomeRequest;
  }

  function checkIncomeFormChanges(object1, object2) {
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // description: returns an html string of changes in a form by comparing the before object with the after object //
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    var html = "";
    const ignoreFields = [
      "incomePeriodTypeId",
      "incomeTypeId",
      "incomeYearTypeId",
      "incomeYearAmount",
    ];
    functions.compareIncomeObjects(object1, object2).forEach((reference) => {
      var before = reference.reduce((o, key) => o[key], object2);
      var after = reference.reduce((o, key) => o[key], object1);
      if (before === after) {
        // do not add to table if the values are the same
        return;
      }
      // ignore the calculated incomeTotal, isEditing
      else if (ignoreFields.includes(reference[0])) {
        return;
      } else {
        if (reference[0] === "incomeTypeName") {
          reference[0] = "incomeType";
        }

        if (reference[0] === "incomePeriodTypeName") {
          reference[0] = "frequency";
        }

        if (reference[0] === "incomeYearTypeName") {
          reference[0] = "incomeYear";
        }

        if (reference[0] === "incomeYearAmountDisplay") {
          reference[0] = "totalAnnualIncome";
        }
        // get the last variable from the reference array, and convert the camelCase to add spaces
        var name = reference.pop().replace(/([a-z])([A-Z])/g, `$1 $2`);

        // Handle amount
        html += `<tr><td class="xbefore">${name}</td><td class="xafter">${before}</td><td class="xafter">${after}</td></tr>`;
      }
    });

    return html === ""
      ? false
      : `<table class='xmodal-changes'>${html}</table>`;
  }

  return {
    incomeDocuments,
    updateIncomeDocument,
    selectedApplicant,
    getIncomeDocumentsByApplicant,
    toggleEdit,
    toggleIncomeDocumentPdfViewer,
    currentlyEditingIncomeIds,
    untoggleEdit,
    isEditing,
    isIncomeDetailShowing,
    toggleDetails,
    cancel,
    getSchemas,
    calculateIncomeTotal,
    discardChanges,
    checkIncomeFormChanges,
    addOrUpdateIncomeRequest,
  };
});
