import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "slide-container",
  ref: "content"
}

import { ref, nextTick, watch } from 'vue';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'SlideTransition',
  props: {
    isVisible: { type: Boolean }
  },
  setup(__props: any) {

	const props = __props;
	const contentRef = ref<HTMLElement | null>(null);

	watch(
		() => props.isVisible,
		async (newVal) => {
			if (newVal && contentRef.value) {
				await nextTick();

				// set height based on expand
				contentRef.value.style.height = `${contentRef.value.scrollHeight}px`;
			}
		}
	);

	const beforeEnter = (el: Element) => {
		const element = el as HTMLElement;

		element.style.height = '0';
		element.style.overflow = 'hidden';
	};

	const enter = (el: Element) => {
		const element = el as HTMLElement;

		nextTick(() => {
			element.style.transition = 'height 0.5s ease';
			element.style.height = `${element.scrollHeight}px`;
		});
	};

	const afterEnter = (el: Element) => {
		const element = el as HTMLElement;

		// remove inline height for responsive behavior
		element.style.height = 'auto';
		element.style.overflow = '';
	};

	const beforeLeave = (el: Element) => {
		const element = el as HTMLElement;

		// set to fixed ensuring transition
		element.style.height = `${element.scrollHeight}px`;
	};

	const leave = (el: Element) => {
		const element = el as HTMLElement;

		nextTick(() => {
			element.style.transition = 'height 0.5s ease';
			element.style.height = '0';
		});
	};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Transition, {
    onBeforeEnter: beforeEnter,
    onEnter: enter,
    onAfterEnter: afterEnter,
    onBeforeLeave: beforeLeave,
    onLeave: leave
  }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default")
      ], 512), [
        [_vShow, _ctx.isVisible]
      ])
    ]),
    _: 3
  }))
}
}

})