import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row-content" }
const _hoisted_3 = { class: "header" }
const _hoisted_4 = { class: "details-content" }

import { ref, computed } from 'vue';
	import { TrashIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/24/solid';
	import { Borrower, ServiceType, SplitDocumentOptions } from '@/models/loans';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'DocumentSplitOption',
  props: {
    options: {},
    documentTypeOptions: {},
    borrowers: {},
    onRemoveOption: { type: Function }
  },
  setup(__props: any) {

	const props = __props;

	const isVisible = ref<boolean>(false);

	const borrower = computed(() => {
		const borrowers = props.borrowers || [];

		if (borrowers && borrowers.length > 0) {
			return borrowers.find((b) => b.id === props.options.applicantId);
		}

		return null;
	});

	const documentType = computed(() => {
		const documentTypeOptions = props.documentTypeOptions || [];

		if (documentTypeOptions && documentTypeOptions.length > 0) {
			return documentTypeOptions.find((b) => b.id === props.options.documentType);
		}

		return null;
	});

	const pageRange = computed(() => `${props.options.startIndex + 1}-${props.options.endIndex + 1}`);

	const handleRemoveOption = () => props.onRemoveOption(props.options);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", null, _toDisplayString(documentType.value?.name) + " (" + _toDisplayString(pageRange.value) + ")", 1),
        _createElementVNode("button", {
          class: "dropdown",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (isVisible.value = !isVisible.value))
        }, [
          (isVisible.value)
            ? (_openBlock(), _createBlock(_unref(ChevronUpIcon), {
                key: 0,
                class: "action-icon"
              }))
            : (_openBlock(), _createBlock(_unref(ChevronDownIcon), {
                key: 1,
                class: "action-icon"
              }))
        ])
      ]),
      _createElementVNode("button", {
        class: "danger",
        onClick: handleRemoveOption
      }, [
        _createVNode(_unref(TrashIcon), { class: "action-icon" })
      ])
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_4, [
      _createElementVNode("p", null, [
        _cache[1] || (_cache[1] = _createTextVNode(" Document Type: ")),
        _createElementVNode("span", null, _toDisplayString(documentType.value?.name || _ctx.options.documentType || 'Unknown'), 1)
      ]),
      _createElementVNode("p", null, [
        _cache[2] || (_cache[2] = _createTextVNode(" Borrower: ")),
        _createElementVNode("span", null, _toDisplayString(borrower.value?.fullName || _ctx.options.applicantId || 'Unknown'), 1)
      ]),
      _createElementVNode("p", null, [
        _cache[3] || (_cache[3] = _createTextVNode(" Pages: ")),
        _createElementVNode("span", null, _toDisplayString(pageRange.value), 1)
      ]),
      _createElementVNode("p", null, [
        _cache[4] || (_cache[4] = _createTextVNode(" Borrower Visible: ")),
        _createElementVNode("span", null, _toDisplayString(_ctx.options.applicantViewable ? 'Yes' : 'No'), 1)
      ])
    ], 512), [
      [_vShow, isVisible.value]
    ])
  ]))
}
}

})