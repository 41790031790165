import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "uploaded-documents" }
const _hoisted_2 = {
  key: 2,
  class: "uploader-padding"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "xpage-history" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "column-item name center-text document-info truncate" }
const _hoisted_7 = { class: "column-item name center-text document-info wrap-text" }
const _hoisted_8 = ["onUpdate:modelValue", "disabled", "onChange"]
const _hoisted_9 = ["value"]
const _hoisted_10 = { class: "actions" }
const _hoisted_11 = { key: 1 }

import { computed, reactive, watch, ref, onMounted } from "vue";
import { useStore } from "vuex";
import UppyUploader from "@/components/UppyUploader2.vue";
import PdfViewerModal from "@/components/PdfViewerModal.vue";
import { Document, LOSApplication } from "@/models/opal";
import BaseFormField from "@/components/form-fields/BaseFormField.vue";
import BaseFormFieldRadioGroup from "@/components/form-fields/BaseFormFieldRadioGroup.vue";
import { FormSchema, FieldSchema } from "@/models/form";
import { $api } from "@/services/api1";
import functions from "@/use/functions";
import useFunctions from "@/use/functions1";
import OpalButton from "@/components/shared/OpalButton.vue";
import { Permissions, PermissionLevels } from "@/models/permissions";
import PermissionsWrapper from "@/components/wrappers/PermissionsWrapper.vue";
import { Applicant } from "@/models/opal";
import { useDocumentsTabStore } from "@/store/store/documentsTabStore";
import { usePdfViewerModalStore } from "@/store/store/pdfViewerModalStore";
import { usePermissionsStore } from "@/store/store/permissionsStore";
import axios from "axios";
import DocumentStatus from "@/components/documents/DocumentStatus.vue";
import { DocumentEntityTypes } from "@/types/types";

interface Props {
  isTabActive: boolean | null;
  store?: any | null;
  applicants: any | null;
  application: LOSApplication;
  documentDate: any | null;
  canEdit?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DocumentsUploaded',
  props: {
    isTabActive: { type: [Boolean, null] },
    store: {},
    applicants: {},
    application: {},
    documentDate: {},
    canEdit: { type: Boolean }
  },
  emits: [
  "toggle",
  "initializeSelectedTypes",
  "getApplicationComments",
  "getPdfDocument",
],
  setup(__props: any, { emit: __emit }) {

const pdfViewerModalStore = usePdfViewerModalStore();
const documentsTabStore = useDocumentsTabStore();
const permissionsStore = usePermissionsStore();

const store = useStore();
const emit = __emit;
const { sortLosTypesByName } = useFunctions();

const props = __props;
const selectedApplicantId = ref<undefined | number>(undefined);
const selectionDisabled = ref<boolean>(false);

const applicationStatusId = ref<number>(
  props.application.data.applicationStatusId
);

const roleIds = computed(() => {
  return permissionsStore.currentUser.roles?.map((role) => role.id);
});

const getOwnerName = (applicantId: number) => {
  const currentApplicant = applicantOwnerOptions.value?.find(
    (option) => option.value === applicantId
  );
  return {
    currentName: currentApplicant ? currentApplicant.label : "Unknown",
    allNames: applicantOwnerOptions.value,
  };
};

const canUploadInFinalStatus = computed(() => {
  const allowedRoles = [17, 16, 13, 5];
  const allowedStatuses = [4, 5, 6];
  return (
    allowedStatuses.includes(applicationStatusId.value) &&
    roleIds.value?.some((id) => allowedRoles.includes(id))
  );
});

const applicantOptions = computed(() => {
  return props.application.applicants?.map((a: Applicant) => {
    return {
      label: `${a.fullName} - ${a.applicantTypeName}`,
      value: a.id,
    };
  });
});

const applicantOwnerOptions = computed(() => {
  return props.application.applicants?.map((a: Applicant) => {
    return {
      label: `${a.applicantTypeName}`,
      value: a.id,
    };
  });
});

const hasNoGeneratedDocs = computed(() => {
  return (
    props.application.documents.length > 0 &&
    props.application.documents.some(
      (doc: any) => doc.documentStatusTypeName !== "Generated"
    )
  );
});

const documentTypes = sortLosTypesByName(
  store.state.types["DocumentType"]?.results
);

const documentTypeOptions = documentTypes?.map((d: any) => {
  return {
    label: d.name,
    value: d.id,
  };
});

const filteredDocuments = computed(() =>
  props.application.documents.filter(
    (d: Document) => d.documentStatusTypeName !== "Generated"
  )
);

const headers = reactive([
  { title: "Document Name" },
  { title: "Upload Date & Time" },
  { title: "Document Owner" },
  { title: "Document Type" },
  { title: "Document Status" },
]);

let schemas = reactive({} as FormSchema);

const disableDocTypeClick = (event: Event) => {
  event.stopPropagation();
};

const applicantSchema = reactive({
  applicant: {
    name: "applicant",
    type: "radio",
    hideLabel: true,
  } as FieldSchema,
});

const schema = reactive({
  documentTypeID: {
    name: "documentTypeID",
    type: "select",
    hideLabel: true,
    hideErrorMessage: true,
    required: true,
  } as FieldSchema,
});

const iconClass = computed(() => {
  return props.application.documentsUploadDisplay
    ? "icon-up-open-big"
    : "icon-down-open-big";
});

function resetApplicantSelection() {
  selectedApplicantId.value = undefined;
  selectionDisabled.value = false;
}

function handleChange(document: any, event: any) {
  event.stopPropagation();
  const selectedTypeName =
    event.target.options[event.target.selectedIndex].text;
  const selectedTypeId = event.target.options[event.target.selectedIndex].value;
  document.documentType.name = selectedTypeName;
  document.documentType.documentTypeID = selectedTypeId;
}

async function updateDocumentType(document: Document) {
  const originalDocumentTypeId = document.documentTypeId;
  if (document.documentType.documentTypeID !== originalDocumentTypeId) {
    const payload = {
      ApplicantId: document.applicantId,
      AppDocumentId: document.applicantDocumentId,
      DocumentId: document.documentId,
      DocumentTypeId: document.documentType.documentTypeID,
    };
    try {
      const response = await $api.documents.updateDocumentType(
        props.application.data.id,
        document.applicantId,
        document.applicantDocumentId,
        payload
      );
      if (response.documentId) {
        documentsTabStore.updateDocument(document.documentId, {
          documentType: {
            name: documentTypes.find(
              (dt: any) => dt.id === response.documentTypeId
            )?.name,
            documentTypeID: response.documentTypeId,
          },
          documentId: response.documentId,
          documentTypeId: response.documentTypeId,
        });
        emit("getApplicationComments");
      } else {
        throw new Error("Error updating document type");
      }
    } catch (error) {
      console.log("Error updating document type:", error);
      functions.openModal({
        title: "Error",
        description: "There was an issue updating the document type",
      });
      document.documentType.documentTypeID = originalDocumentTypeId;
    }
  }
}

async function updateDocumentOwner(document: Document) {
  const originalOwnerId =
    documentsTabStore.currentlyEditingDocOwners[
      documentsTabStore.currentlyEditingDocIds.indexOf(document.documentId)
    ];
  const newOwnerId = document.applicantId;

  if (newOwnerId !== originalOwnerId) {
    const payload = {
      toApplicantId: newOwnerId,
      documentId: document.applicantDocumentId,
    };
    try {
      const response = await axios.put(
        `/api/documents/applicant/${originalOwnerId}/Document/Owner`,
        payload
      );
      if (response.status === 200) {
        documentsTabStore.updateDocument(document.documentId, {
          applicantId: newOwnerId,
        });
        document.loading = false;
        emit("getApplicationComments");
      } else {
        throw new Error("Error updating document owner");
      }
    } catch (error) {
      console.error("Error updating document owner:", error);
      functions.openModal({
        title: "Error",
        description: "There was an issue updating the document owner",
      });
      document.applicantId = originalOwnerId;
    }
  }
}

async function toggleEdit(document: Document) {
  const isEditing = documentsTabStore.currentlyEditingDocIds.includes(
    document.documentId
  );

  if (isEditing) {
    try {
      await updateDocumentType(document);
      documentsTabStore.untoggleEditMode(document.documentId);
      emit("getApplicationComments");
    } catch (error) {
      console.log("Error updating document type:", error);
    } finally {
      document.loading = false;
    }
  } else {
    documentsTabStore.toggleEditMode(document.documentId, document.applicantId);
  }
}

function toggleSplit(document: Document) {
  documentsTabStore.documentPdfModalOpen = true;
  let request = {
    name: document.name + document.fileExtension, // ???
    document: document.documentId,
    splitting: true,
  };
  emit("getPdfDocument", request);
}

function handleRowClick(document: Document) {
  pdfViewerModalStore.pdfModal.active = true;
  emit("getPdfDocument", {
    name: document.name + document.fileExtension, // ???
    document: document.documentId,
    splitting: false,
  });
}

watch(
  () => filteredDocuments.value,
  () => {
    // reset schemas
    schemas = {};
    if (filteredDocuments.value && filteredDocuments.value.length > 0) {
      filteredDocuments.value.forEach((fd: any) => {
        schemas[fd.documentId] = {
          name: `documentTypeId`,
          type: "select",
          hideLabel: true,
          hideErrorMessage: true,
          required: true,
        } as FieldSchema;
      });
    }
  }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _cache[6] || (_cache[6] = _createElementVNode("h3", null, "Uploaded Documents", -1)),
      (!canUploadInFinalStatus.value)
        ? (_openBlock(), _createBlock(PermissionsWrapper, {
            key: 0,
            disabled: !_ctx.canEdit,
            showDisabledIfNotVisible: true,
            permissionIds: [_unref(Permissions).DocumentProcessing],
            permissionLevel: _unref(PermissionLevels).Update
          }, {
            default: _withCtx(() => [
              _createElementVNode("button", {
                class: "xregular mb-20",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('toggle')))
              }, [
                _cache[4] || (_cache[4] = _createTextVNode(" Upload a file ")),
                _createElementVNode("i", {
                  class: _normalizeClass(iconClass.value)
                }, null, 2)
              ])
            ]),
            _: 1
          }, 8, ["disabled", "permissionIds", "permissionLevel"]))
        : (_openBlock(), _createElementBlock("button", {
            key: 1,
            class: "xregular mb-20",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (emit('toggle')))
          }, [
            _cache[5] || (_cache[5] = _createTextVNode(" Upload a file ")),
            _createElementVNode("i", {
              class: _normalizeClass(iconClass.value)
            }, null, 2)
          ])),
      (_ctx.application.documentsUploadDisplay)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(BaseFormFieldRadioGroup, {
              modelValue: selectedApplicantId.value,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((selectedApplicantId).value = $event)),
              schema: applicantSchema.applicant,
              options: applicantOptions.value,
              disabled: selectionDisabled.value
            }, null, 8, ["modelValue", "schema", "options", "disabled"]),
            _createVNode(UppyUploader, {
              applicationId: _ctx.application.data.id,
              applicantId: selectedApplicantId.value,
              userId: _unref(store).state.oidcStore.user.sub,
              "entity-type-id": _unref(DocumentEntityTypes).Application,
              onFileAdded: _cache[3] || (_cache[3] = ($event: any) => (selectionDisabled.value = true)),
              onUploadSuccess: resetApplicantSelection
            }, null, 8, ["applicationId", "applicantId", "userId", "entity-type-id"])
          ]))
        : _createCommentVNode("", true),
      _createVNode(PermissionsWrapper, {
        permissionIds: [_unref(Permissions).DocumentProcessing],
        permissionLevel: _unref(PermissionLevels).Read
      }, {
        default: _withCtx(() => [
          (hasNoGeneratedDocs.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(PdfViewerModal),
                _createElementVNode("table", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(headers, (header, index) => {
                    return (_openBlock(), _createElementBlock("th", {
                      key: index,
                      class: "column-title"
                    }, [
                      _createElementVNode("span", null, _toDisplayString(header.title), 1)
                    ]))
                  }), 128)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredDocuments.value, (document, index) => {
                    return (_openBlock(), _createElementBlock("tr", {
                      class: "xclickable",
                      key: index,
                      onClick: ($event: any) => (handleRowClick(document))
                    }, [
                      _createElementVNode("td", _hoisted_6, [
                        _createElementVNode("span", null, _toDisplayString(document.uploadedDocumentMetadata?.originalFileName), 1)
                      ]),
                      _createElementVNode("td", _hoisted_7, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.documentDate(`${document.createdAtUtc}Z`)), 1)
                      ]),
                      _createElementVNode("td", {
                        class: "column-item name center-text",
                        onClick: _withModifiers(disableDocTypeClick, ["stop"])
                      }, [
                        _withDirectives(_createElementVNode("select", {
                          "onUpdate:modelValue": ($event: any) => ((document.applicantId) = $event),
                          class: "fit-content",
                          disabled: 
                    !_unref(documentsTabStore).currentlyEditingDocIds?.includes(
                      document.documentId
                    )
                  ,
                          onChange: ($event: any) => (updateDocumentOwner(document))
                        }, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getOwnerName(document.applicantId)
                      .allNames, (option) => {
                            return (_openBlock(), _createElementBlock("option", {
                              key: option.value,
                              value: option.value
                            }, _toDisplayString(option.label), 9, _hoisted_9))
                          }), 128))
                        ], 40, _hoisted_8), [
                          [_vModelSelect, document.applicantId]
                        ])
                      ]),
                      _createElementVNode("td", {
                        class: "selection-item-dropdown name center-text",
                        onClick: _withModifiers(disableDocTypeClick, ["stop"])
                      }, [
                        _createVNode(BaseFormField, {
                          disabled: 
                    !_unref(documentsTabStore).currentlyEditingDocIds?.includes(
                      document.documentId
                    )
                  ,
                          schema: 
                    _unref(schemas)[document.documentId] || schema.documentTypeID
                  ,
                          modelValue: document.documentType.documentTypeID,
                          "onUpdate:modelValue": ($event: any) => ((document.documentType.documentTypeID) = $event),
                          modelModifiers: { number: true },
                          options: _unref(documentTypeOptions),
                          onChange: ($event: any) => (handleChange(document, $event))
                        }, null, 8, ["disabled", "schema", "modelValue", "onUpdate:modelValue", "options", "onChange"])
                      ]),
                      _createElementVNode("td", null, [
                        _createVNode(DocumentStatus, {
                          documents: [document],
                          document: document,
                          application: _ctx.application,
                          canEdit: _ctx.canEdit,
                          onClick: disableDocTypeClick
                        }, null, 8, ["documents", "document", "application", "canEdit"])
                      ]),
                      _createVNode(PermissionsWrapper, {
                        permissionIds: [_unref(Permissions).DocumentProcessing],
                        permissionLevel: _unref(PermissionLevels).Update
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("td", _hoisted_10, [
                            _createVNode(OpalButton, {
                              onClick: _withModifiers(($event: any) => (toggleEdit(document)), ["stop"]),
                              disabled: _unref(documentsTabStore).isEditDisabled(document),
                              class: _normalizeClass([{
                      'disabled-button':
                        _unref(documentsTabStore).isEditDisabled(document),
                    }, "save-button-documents"]),
                              loading: document.loading,
                              text: 
                      _unref(documentsTabStore).currentlyEditingDocIds?.includes(
                        document.documentId
                      )
                        ? 'Save'
                        : 'Edit'
                    
                            }, null, 8, ["onClick", "disabled", "class", "loading", "text"]),
                            _createVNode(OpalButton, {
                              onClick: _withModifiers(($event: any) => (toggleSplit(document)), ["stop"]),
                              class: "save-button-documents",
                              loading: document.loading,
                              text: `Split`
                            }, null, 8, ["onClick", "loading"])
                          ])
                        ]),
                        _: 2
                      }, 1032, ["permissionIds", "permissionLevel"])
                    ], 8, _hoisted_5))
                  }), 128))
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_11, "This application has 0 documents"))
        ]),
        _: 1
      }, 8, ["permissionIds", "permissionLevel"])
    ])
  ]))
}
}

})