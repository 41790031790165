import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "breadcrumb"
}
const _hoisted_2 = { class: "xpage-breadcrumbs" }

import { useRoute } from "vue-router";

interface Application { 
  data: {
    referenceId: string;
    loanId?: string;
  };
}

interface Props {
  application: Application;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ApplicationBreadcrumb',
  props: {
    application: {}
  },
  setup(__props: any) {

const props = __props;
const route = useRoute();



return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    (props.application.data)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_router_link, {
              to: "/",
              class: "icon-home"
            }),
            _cache[1] || (_cache[1] = _createElementVNode("span", null, ">", -1)),
            _createVNode(_component_router_link, { to: "/applications" }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("Applications")
              ])),
              _: 1
            }),
            _cache[2] || (_cache[2] = _createElementVNode("span", null, ">", -1)),
            _createVNode(_component_router_link, {
              to: `/applications/${_unref(route).params.referenceId}`
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(route).params.referenceId), 1)
              ]),
              _: 1
            }, 8, ["to"])
          ]),
          (props.application.data?.loanId)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: `/loans/${props.application.data.referenceId}`,
                class: "loan-link"
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode(" This application has been completed and moved to Loan Service. View Loan > ")
                ])),
                _: 1
              }, 8, ["to"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})