import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, unref as _unref, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "spinner-container"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "select-buttons-container" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "text-container" }
const _hoisted_6 = { class: "main-text" }
const _hoisted_7 = { class: "subtext" }
const _hoisted_8 = { class: "card" }
const _hoisted_9 = { class: "action-button-container" }
const _hoisted_10 = ["disabled"]
const _hoisted_11 = ["disabled"]
const _hoisted_12 = ["disabled"]

import '@/assets/css/card.css';
	import { onMounted, ref, watch, computed } from 'vue';
	import { useRoute, useRouter } from 'vue-router';
	import axios from 'axios';
	import functions from '@/use/functions';
	import Spinner from '@/components/shared/Spinner.vue';
	import PermissionsWrapper from '@/components/wrappers/PermissionsWrapper.vue';
	import { Permissions, PermissionLevels } from '@/models/permissions';
	import { Disbursement, Draw, ValidDisbursementStatusIds } from '@/models/loans';
	import PostDisbursementChangeForm from './PostDisbursementChangeForm.vue';
	import DisbursementForm from './DisbursementForm.vue';
	import { useDisbursementFormFields } from '@/composables/loan/useDisbursementForm';
	import { usePostDisbursementChangeFormFields } from '@/composables/loan/usePostDisbursementChangeForm';
	import { useDisbursementData } from '@/composables/loan/useDisbursementData';
	import { usePostDisbursementChangeData } from '@/composables/loan/usePostDisbursementChangeData';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'DrawDisbursements',
  props: {
    loanId: {},
    draw: {},
    onDisbursementChangedAsync: { type: Function }
  },
  setup(__props: any) {

	const props = __props;

	const route = useRoute();
	const router = useRouter();

	const isLoading = ref<boolean>(true);
	const isSubmitting = ref<boolean>(false);
	const disbursements = ref<Disbursement[]>([]);
	const selectedDisbursement = ref<Disbursement | null>(null);
	const previousDisbursement = ref<Disbursement | null>(null);
	const mode = ref<'view' | 'edit'>('view');
	const postDisbursementChangeFormVisible = ref<boolean>(false);

	const { disbursementStatusOptions, disbursementStatusReasonOptions } = useDisbursementData();
	const { formFields, validationSchema } = useDisbursementFormFields({
		selectedDisbursement,
		mode,
		disbursementStatusOptions,
		disbursementStatusReasonOptions
	});

	const { disbursementCancellationReasonOptions } = usePostDisbursementChangeData();
	const { changeFormFields, changeValidationSchema } = usePostDisbursementChangeFormFields({
		selectedDisbursement,
		disbursementCancellationReasonOptions
	});

	const changeMode = (newMode: 'view' | 'edit') => {
		if (newMode === 'view') {
			if (previousDisbursement.value) {
				selectedDisbursement.value = previousDisbursement.value;
			}
		}

		mode.value = newMode;
	};

	const canEditDisbursement = computed(() => {
		return ValidDisbursementStatusIds.includes(selectedDisbursement.value?.disbursementStatusTypeId || 0);
	});

	const selectDisbursement = (disbursement: Disbursement) => {
		selectedDisbursement.value = disbursement;

		mode.value = 'view';

		router.replace({
			query: { ...route.query, disbursementId: disbursement.id },
			hash: route.hash
		});
	};

	const showPostDisbursementChangeModal = () => (postDisbursementChangeFormVisible.value = true);

	const closePostDisbursementChangeModal = () => (postDisbursementChangeFormVisible.value = false);

	async function createPostDisbursementChangeAsync(formData: Record<string, any>) {
		const disbursement = selectedDisbursement.value;

		if (!disbursement) {
			return;
		}

		isSubmitting.value = true;

		try {
			await axios.post(`/api/loanservice/disbursements/${disbursement.id}/post-disbursement-change`, {
				disbursementId: disbursement.id,
				originalDisbursementDate: disbursement.transactionDate,
				originalDisbursementAmount: disbursement.disbursedAmount,
				...formData
			});

			closePostDisbursementChangeModal();

			await props.onDisbursementChangedAsync();
		} catch (error) {
			functions.openModal({
				title: 'Oops!',
				description: 'There was an issue creating a post-disbursement change. Please try again later.'
			});
		} finally {
			isSubmitting.value = false;
		}
	}

	async function updateDisbursementAsync(formData: Record<string, any>) {
		const disbursement = selectedDisbursement.value;

		if (!disbursement) {
			return;
		}

		isSubmitting.value = true;

		try {
			if (formData.comment) {
				await createCommentAsync(formData.comment);
			}

			const request = {
				id: disbursement.id,
				disbursementTypeId: disbursement.disbursementTypeId,
				...formData
			};

			await axios.post(`/api/loanservice/disbursements/${disbursement.id}`, request);

			await props.onDisbursementChangedAsync();
		} catch (error) {
			functions.openModal({
				title: 'Oops!',
				description: 'There was an issue updating the disbursement. Please try again later.'
			});
		} finally {
			isSubmitting.value = false;
		}
	}

	async function createCommentAsync(comment: string) {
		const request = {
			loanId: props.loanId,
			comment
		};

		await axios.post('/api/comments/loan', request);
	}

	async function getDisbursementsAsync() {
		try {
			isLoading.value = true;

			const response = await axios(`/api/loanservice/draws/${props.draw.id}/disbursements`);

			disbursements.value = response.data;

			selectInitialDisbursement();
		} catch (error) {
			functions.openModal({
				title: 'Oops!',
				description: 'There was an issue getting the disbursements. Please try again later.'
			});
		} finally {
			isLoading.value = false;
		}
	}

	const selectInitialDisbursement = () => {
		const queryDisbursementId = route.query.disbursementId;
		const firstDisbursement = disbursements.value[0];

		if (disbursements.value.length === 0) {
			router.replace({
				query: { ...route.query, disbursementId: undefined },
				hash: route.hash
			});

			return;
		}

		const matchingDisbursement = disbursements.value.find(
			(disbursement) => disbursement.id === Number(queryDisbursementId)
		);

		selectDisbursement(matchingDisbursement || firstDisbursement);
	};

	watch(
		() => props.draw,
		async () => {
			await getDisbursementsAsync(); // todo: fix on adding new draw...
		}
	);

	onMounted(async () => {
		await getDisbursementsAsync();
	});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (isLoading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(Spinner)
        ]))
      : (disbursements.value.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _cache[3] || (_cache[3] = _createElementVNode("hr", null, null, -1)),
            _cache[4] || (_cache[4] = _createElementVNode("h3", null, "Disbursements", -1)),
            _createElementVNode("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(disbursements.value, (disbursement) => {
                return (_openBlock(), _createElementBlock("button", {
                  key: disbursement.id,
                  class: _normalizeClass(['icon-button', { secondary: disbursement != selectedDisbursement.value }]),
                  onClick: ($event: any) => (selectDisbursement(disbursement))
                }, [
                  _cache[2] || (_cache[2] = _createElementVNode("i", { class: "icon icon-money" }, null, -1)),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("span", _hoisted_6, _toDisplayString(disbursement.disbursedAmountFormatted), 1),
                    _createElementVNode("span", _hoisted_7, _toDisplayString(disbursement.transactionDateDisplay), 1)
                  ])
                ], 10, _hoisted_4))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(PermissionsWrapper, {
                permissionIds: [_unref(Permissions).Draws],
                permissionLevel: _unref(PermissionLevels).Update
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_9, [
                    (mode.value === 'view' && canEditDisbursement.value)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          disabled: isSubmitting.value,
                          class: "secondary",
                          onClick: showPostDisbursementChangeModal
                        }, " Post-Disbursement Change ", 8, _hoisted_10))
                      : _createCommentVNode("", true),
                    (canEditDisbursement.value)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 1,
                          disabled: mode.value === 'edit',
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (changeMode('edit')))
                        }, " Edit ", 8, _hoisted_11))
                      : _createCommentVNode("", true),
                    (mode.value !== 'view')
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 2,
                          disabled: isSubmitting.value,
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (changeMode('view')))
                        }, " Cancel ", 8, _hoisted_12))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              }, 8, ["permissionIds", "permissionLevel"]),
              (_openBlock(), _createBlock(DisbursementForm, {
                key: selectedDisbursement.value?.id,
                mode: mode.value,
                fields: _unref(formFields),
                validationSchema: _unref(validationSchema),
                isSubmitting: isSubmitting.value,
                onSubmit: updateDisbursementAsync
              }, null, 8, ["mode", "fields", "validationSchema", "isSubmitting"]))
            ])
          ]))
        : _createCommentVNode("", true),
    (postDisbursementChangeFormVisible.value)
      ? (_openBlock(), _createBlock(PostDisbursementChangeForm, {
          key: 2,
          isSubmitting: isSubmitting.value,
          fields: _unref(changeFormFields),
          validationSchema: _unref(changeValidationSchema),
          onSubmit: createPostDisbursementChangeAsync,
          onClose: closePostDisbursementChangeModal
        }, null, 8, ["isSubmitting", "fields", "validationSchema"]))
      : _createCommentVNode("", true)
  ]))
}
}

})