import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/modal-close.png'


const _hoisted_1 = { class: "modal-cancel-overlay" }
const _hoisted_2 = { class: "cancel-modal" }
const _hoisted_3 = { class: "modal-header" }
const _hoisted_4 = ["value"]
const _hoisted_5 = ["value"]
const _hoisted_6 = ["value"]
const _hoisted_7 = { class: "modal-button-container" }

import axios from "axios";
    import { LOSType, Type } from "@/types/types";
    import { useApplicationStore } from "@/store/store/applicationVMStore";
    import { useTypeStore } from "@/store/store/typeStore";
    import { ref, computed } from "vue";
    import {Cancellation} from "@/types/application";
    import functions from "@/use/functions";

    
export default /*@__PURE__*/_defineComponent({
  __name: 'CancellationModal',
  emits: ["close", "update"],
  setup(__props, { emit: __emit }) {

    const emit = __emit;

    const typeStore = useTypeStore();
    const applicationStore = useApplicationStore();
    const cancellation = ref<Cancellation>({
       statusReasonId: 0,
       applicantId: 0,
       cancellationReason: ""
    });

    const statusReasons = computed(() =>
        typeStore.getTypes(Type.ApplicationStatusReasons)
    );

    const handleReasonChange = () => {
        var status = statusReasons.value.find(r => r.id == cancellation.value.statusReasonId);

        if(status !== undefined){
           cancellation.value.cancellationReason = status.name || "";
        }
    }

    const saveCancellation = async () => {
      try {
          var response = await axios.post(`/api/applications/${applicationStore.applicationId}/Refi/Cancel`, cancellation.value);
          
          if(response.data.success)
          {
            emit('update');
            emit('close', false);

            functions.openModal({
                title: "Success!",
                description: "Application has been saved",
            });
          }else{

            const modal = {
              title: "Error",
              description: response.data.errors.join(",") || "Failed to save cancellation request",
            };

            functions.openModal(modal);
          }
      }catch(error){
         emit('close', false);

         const modal = {
              title: "Error",
              description: error.response.data || "Failed to save cancellation request",
          };

          functions.openModal(modal);
      }
  }

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      onSubmit: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (saveCancellation()), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("a", {
            class: "close-btn",
            "ria-label": "Close",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('close', false)), ["prevent"]))
          }, _cache[5] || (_cache[5] = [
            _createElementVNode("img", {
              src: _imports_0,
              alt: "Close modal button"
            }, null, -1)
          ]))
        ]),
        _cache[10] || (_cache[10] = _createElementVNode("h3", { class: "modal-title" }, "Request to Cancel Application", -1)),
        _cache[11] || (_cache[11] = _createElementVNode("p", { class: "modal-text" }, " Select applicant requesting to cancel the application ", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "modal-options",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((cancellation.value.applicantId) = $event)),
          required: ""
        }, [
          _cache[6] || (_cache[6] = _createElementVNode("option", { value: "0" }, "-- Select Applicant --", -1)),
          (_unref(applicationStore).viewModel.hasPrimaryApplicant)
            ? (_openBlock(), _createElementBlock("option", {
                key: 0,
                value: _unref(applicationStore).viewModel.primaryApplicant.id
              }, _toDisplayString(_unref(applicationStore).viewModel.primaryApplicant.fullName) + " - " + _toDisplayString(_unref(applicationStore).viewModel.primaryApplicant.applicantTypeName), 9, _hoisted_4))
            : _createCommentVNode("", true),
          (_unref(applicationStore).viewModel.hasCoApplicant)
            ? (_openBlock(), _createElementBlock("option", {
                key: 1,
                value: _unref(applicationStore).viewModel.coApplicant.id
              }, _toDisplayString(_unref(applicationStore).viewModel.coApplicant.fullName) + " - " + _toDisplayString(_unref(applicationStore).viewModel.coApplicant.applicantTypeName), 9, _hoisted_5))
            : _createCommentVNode("", true)
        ], 512), [
          [_vModelSelect, cancellation.value.applicantId]
        ]),
        _cache[12] || (_cache[12] = _createElementVNode("p", { class: "modal-text" }, " Please select the reason this application is being cancelled ", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "modal-options",
          onChange: handleReasonChange,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((cancellation.value.statusReasonId) = $event)),
          required: ""
        }, [
          _cache[7] || (_cache[7] = _createElementVNode("option", { value: "0" }, "-- Select Reason --", -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(statusReasons.value, (reason) => {
            return (_openBlock(), _createElementBlock("option", {
              key: reason.id,
              value: reason.id
            }, _toDisplayString(reason.name), 9, _hoisted_6))
          }), 128))
        ], 544), [
          [_vModelSelect, cancellation.value.statusReasonId]
        ]),
        _cache[13] || (_cache[13] = _createElementVNode("div", { class: "modal-text-attest-container" }, [
          _createElementVNode("p", { class: "modal-text-attest" }, " Canceling this application during the Right to Cancel period cannot be reversed. Do you wish to proceed? ")
        ], -1)),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("button", {
            type: "button",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (emit('close', false))),
            class: "modal-btn no-btn"
          }, _cache[8] || (_cache[8] = [
            _createElementVNode("span", null, "No", -1)
          ])),
          _cache[9] || (_cache[9] = _createElementVNode("button", {
            type: "submit",
            class: "modal-btn yes-btn"
          }, [
            _createElementVNode("span", null, "Yes")
          ], -1))
        ])
      ])
    ], 32)
  ]))
}
}

})