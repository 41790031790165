import { CustomPricing } from "./customPricing";
import { Exception } from "./evaluation";
export interface Override
{
    overrideTypeId?: number;
    pricingTierIds?: number[];
    customPricing?: CustomPricing;
    locLimit?: string;
    freeFormReason?: string;
    reviewBy?: string;
    reviewDateTime?: string;
    isReviewed: boolean;
    isCustomPricing: boolean;
}

export interface OverridesRefi
{
    overrideTypeId?: number;
    freeFormReason?: string;
    reviewBy?: string;
    reviewDateTime?: string;
    isReviewed: boolean;
    isCustomPricing: boolean;
    exceptions?: Array<Exception>;

}

export enum OverrideTypes
{
    LOCLimitMod = 10
}

export interface OverrideVMOptions {
    overrideTypeId: number;
    overrideReasonId: number;
    overrideName: string;
}

export interface AdditionalOverride
{
    overrideTypeId?: number;
    overrideReasonId?: number;
    freeFormReason?: string;
    isReviewed: boolean;
}


