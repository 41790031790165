import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, unref as _unref, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "spinner-container"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "select-buttons-container" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "text-container" }
const _hoisted_6 = { class: "main-text" }
const _hoisted_7 = { class: "subtext" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = { class: "card" }
const _hoisted_10 = { class: "action-button-container" }
const _hoisted_11 = ["disabled"]
const _hoisted_12 = { key: 0 }

import '@/assets/css/card.css';
	import { onMounted, ref, computed } from 'vue';
	import { useRoute, useRouter } from 'vue-router';
	import axios from 'axios';
	import functions from '@/use/functions';
	import Spinner from '@/components/shared/Spinner.vue';
	import DrawForm from '@/components/loan/loc/DrawForm.vue';
	import DrawDisbursements from '../loc/DrawDisbursements.vue';
	import PermissionsWrapper from '@/components/wrappers/PermissionsWrapper.vue';
	import { Permissions, PermissionLevels } from '@/models/permissions';
	import { Draw, ValidDrawStatusIds } from '@/models/loans';
	import { useDrawFormFields } from '@/composables/loan/useDrawForm';
	import { useDrawData } from '@/composables/loan/useDrawData';
	import { useModificationRecord } from '@/composables/loan/useModificationRecord';
	import { useDrawTermination } from '@/composables/loan/useDrawTermination';
	import { useDrawActions } from '@/composables/loan/useDrawActions';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'DrawsTab',
  props: {
    loanId: {},
    availableToDrawAmount: {},
    programTypeId: {},
    productTypeId: {},
    lenderId: {},
    onDrawsChanged: { type: Function }
  },
  setup(__props: any) {

	const props = __props;

	const route = useRoute();
	const router = useRouter();

	const isLoading = ref<boolean>(true);
	const draws = ref<Draw[]>([]);
	const selectedDraw = ref<Draw | null>(null);
	const previousDraw = ref<Draw | null>(null);
	const mode = ref<'view' | 'add' | 'edit'>('view');
	const availableToDrawAmount = ref<number>(props.availableToDrawAmount);

	const { isSubmitting, createDrawAsync, updateDrawAsync } = useDrawActions(props.loanId, refreshDataAsync);
	const { confirmDrawTerminationAsync } = useDrawTermination(selectedDraw, refreshDataAsync);
	const { isProviderMethod, confirmModificationRecordGenerationAsync } = useModificationRecord(selectedDraw);
	const { certificationMethodOptions, drawStatusOptions, enrollmentTermOptions, schoolLevelOptions } = useDrawData(
		props.programTypeId
	);

	const { formFields, validationSchema } = useDrawFormFields({
		selectedDraw,
		mode,
		availableToDrawAmount,
		drawStatusOptions,
		certificationMethodOptions,
		schoolLevelOptions,
		enrollmentTermOptions
	});

	async function refreshDataAsync() {
		await props.onDrawsChanged();
		await getDrawsAsync();
	}

	async function handleSubmitAsync(formData: Record<string, any>) {
		if (mode.value === 'add') {
			await createDrawAsync(formData);

			if (draws.value.length > 0) {
				selectDraw(draws.value[draws.value.length - 1]); // select the newest draw
			}
		} else if (mode.value === 'edit') {
			await updateDrawAsync(formData, selectedDraw.value);
		}
	}

	const canEditDraw = computed(() => {
		return mode.value !== 'add' && ValidDrawStatusIds.includes(selectedDraw.value?.drawStatusTypeId || 0);
	});

	const changeMode = (newMode: 'view' | 'add' | 'edit') => {
		if (newMode === 'add') {
			previousDraw.value = selectedDraw.value;
			selectedDraw.value = new Draw();
		} else if (newMode === 'view' && mode.value === 'add') {
			if (previousDraw.value) {
				selectedDraw.value = previousDraw.value;
			}
		}

		mode.value = newMode;
	};

	const selectDraw = (draw: Draw) => {
		selectedDraw.value = draw;

		mode.value = 'view';

		router.replace({
			query: { ...route.query, drawId: draw.id },
			hash: route.hash
		});
	};

	async function getDrawsAsync() {
		try {
			const response = await axios(`/api/loanservice/loans/${props.loanId}/draws`);

			draws.value = response.data;

			if (draws.value.length > 0) {
				selectInitialDraw();
			}
		} catch (error) {
			functions.openModal({
				title: 'Oops!',
				description: 'There was an issue getting the draws. Please try again later.'
			});
		}
	}

	const selectInitialDraw = () => {
		const queryDrawId = route.query.drawId;

		if (queryDrawId) {
			const matchingDraw = draws.value.find((draw) => draw.id === Number(queryDrawId));

			if (matchingDraw) {
				selectDraw(matchingDraw);
			} else {
				selectDraw(draws.value[0]); // default to first if not in list
			}
		} else if (draws.value.length > 0) {
			selectDraw(draws.value[0]); // default to first if not in query
		}
	};

	onMounted(async () => {
		await getDrawsAsync();

		isLoading.value = false;
	});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (isLoading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(Spinner)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(draws.value, (draw) => {
              return (_openBlock(), _createElementBlock("button", {
                key: draw.id,
                class: _normalizeClass(['icon-button', { secondary: draw != selectedDraw.value }]),
                onClick: ($event: any) => (selectDraw(draw))
              }, [
                _cache[5] || (_cache[5] = _createElementVNode("i", { class: "icon icon-money" }, null, -1)),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("span", _hoisted_6, "$" + _toDisplayString(draw.requestedAmount) + ".00", 1),
                  _createElementVNode("span", _hoisted_7, "Sequence #" + _toDisplayString(draw.sequenceNumber), 1)
                ])
              ], 10, _hoisted_4))
            }), 128)),
            _createVNode(PermissionsWrapper, {
              permissionIds: [_unref(Permissions).Draws],
              permissionLevel: _unref(PermissionLevels).Create
            }, {
              default: _withCtx(() => [
                (mode.value !== 'edit')
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: "icon-button dashed",
                      disabled: mode.value == 'add',
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (changeMode('add')))
                    }, _cache[6] || (_cache[6] = [
                      _createElementVNode("span", { class: "icon" }, "+", -1),
                      _createElementVNode("div", { class: "text-container" }, [
                        _createElementVNode("span", { class: "main-text" }, "Add New")
                      ], -1)
                    ]), 8, _hoisted_8))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["permissionIds", "permissionLevel"])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(PermissionsWrapper, {
              permissionIds: [_unref(Permissions).Draws],
              permissionLevel: _unref(PermissionLevels).Update
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_10, [
                  (canEditDraw.value)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        class: "danger",
                        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_unref(confirmDrawTerminationAsync) && _unref(confirmDrawTerminationAsync)(...args)))
                      }, " Terminate "))
                    : _createCommentVNode("", true),
                  (_unref(isProviderMethod) && mode.value === 'view')
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 1,
                        class: "secondary",
                        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_unref(confirmModificationRecordGenerationAsync) && _unref(confirmModificationRecordGenerationAsync)(...args)))
                      }, " Generate Modification "))
                    : _createCommentVNode("", true),
                  (canEditDraw.value)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 2,
                        disabled: mode.value === 'edit',
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (changeMode('edit')))
                      }, "Edit", 8, _hoisted_11))
                    : _createCommentVNode("", true),
                  (mode.value !== 'view')
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 3,
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (changeMode('view')))
                      }, "Cancel"))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            }, 8, ["permissionIds", "permissionLevel"]),
            (_openBlock(), _createBlock(DrawForm, {
              key: selectedDraw.value?.id,
              fields: _unref(formFields),
              validationSchema: _unref(validationSchema),
              mode: mode.value,
              productTypeId: _ctx.productTypeId,
              programTypeId: _ctx.programTypeId,
              lenderId: _ctx.lenderId,
              isSubmitting: _unref(isSubmitting),
              onSubmit: handleSubmitAsync
            }, null, 8, ["fields", "validationSchema", "mode", "productTypeId", "programTypeId", "lenderId", "isSubmitting"]))
          ]),
          (mode.value === 'view')
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                (selectedDraw.value)
                  ? (_openBlock(), _createBlock(DrawDisbursements, {
                      key: 0,
                      loanId: _ctx.loanId,
                      draw: selectedDraw.value,
                      onDisbursementChangedAsync: refreshDataAsync
                    }, null, 8, ["loanId", "draw"]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]))
  ]))
}
}

})