import { ProductIds } from "@/types/types";
import { defineStore } from "pinia";
import { ref, computed, watch } from "vue";
import { ApplicationProgramRequirement } from "@/models/programRequirements";
import { useIdentityStore } from "@/store/store/identityStore";
import { useApplicationStore } from "@/store/store/applicationVMStore";
import { TabIdentifiers } from "@/types/tabs";
import { $api } from "@/services/api1";
import functions from "@/use/functions";

export const useProgramRequirementsStore = defineStore(
  "programRequirements",
  () => {
    const programRequirementsDecisions = ref<
      Array<ApplicationProgramRequirement>
    >([]);

    const identityStore = useIdentityStore();
    const applicationStore = useApplicationStore();

    const identityTabCheckmarkStatus = computed(() => {
      return identityProgramRequirementDecision?.value?.length > 0
        ? verifyIdentityCheckmark()
        : "incomplete";
    });

    const identityProgramRequirementDecision = computed(() =>
      programRequirementsDecisions.value?.filter(
        (pr: ApplicationProgramRequirement) =>
          pr.requirementTypeName?.trim()?.toLowerCase() === "identity"
      )
    );

    function updateProgramRequirement(
      requirementTypeName,
      updatedProgramRequirementDecision
    ) {
      const index = programRequirementsDecisions.value.findIndex(
        (item: ApplicationProgramRequirement) =>
          item?.requirementTypeName === requirementTypeName
      );

      if (index !== -1) {
        programRequirementsDecisions.value[index] =
          updatedProgramRequirementDecision;
      }
    }

    function handleResponseErrors(errors: string[]) {
      if (errors && errors.length > 0) {
        let html = "";
        errors.forEach((error: string) => {
          html += `<li style="color: red; font-weight: 700;">${error}</li>`;
        });
        const modalHtml =
          html === ""
            ? false
            : `<ul class='xmodal-changes' style="list-style: none; padding: 0;">${html}</ul>`;

        functions.openModal({
          title: "Error",
          description:
            "There was an issue updating the program requirement status",
          html: modalHtml,
        });
      }
    }

    async function getApplicationProgramRequirements() {
      try {
        const applicationId = applicationStore.applicationId;
        const response =
          await $api.applications.getApplicationProgramRequirements(
            applicationId
          );
        if (response?.length > 0) {
          programRequirementsDecisions.value = response;
        }
      } catch (error) {
        console.error("Error in initializeProgramRequirements:", error);
        functions.openModal({
          title: "Error",
          description: "There was an issue loading program requirements.",
        });
      }
    }

    async function syncIdentityProgramRequirementDecision(
      applicationId: number
    ) {
      await identityStore.getIdentity(applicationId);
      const identity = identityStore.identity;

      const identityRequirement: ApplicationProgramRequirement = {
        requirementTypeName: "Identity",
        requirementStatusName:
          identity?.requirementDecision?.requirementStatusName,
        requirementStatusId: identity?.requirementDecision?.requirementStatusId,
        requirementTypeId: identity?.requirementDecision?.requirementTypeId,
        applicationId: applicationId,
        id: 0,
        overrides: [],
      };
      updateProgramRequirement("Identity", identityRequirement);
    }

    function verifyIdentityCheckmark() {
      let result = "";
      const decision = identityProgramRequirementDecision.value[identityProgramRequirementDecision.value.length - 1];
      if (
        decision.requirementStatusName === "Passed" ||
        decision.requirementStatusName == "Passed - CU Override"
      ) {
        result = "complete";
      } else if (decision.requirementStatusName === "Failed") {
        result = "failed";
      } else {
        result = "incomplete";
      }

      const tabIdentifier = TabIdentifiers.IdentityTab as string;
      applicationStore.updateTabStatus(tabIdentifier, result);

      return result;
    }

    return {
      programRequirementsDecisions,
      identityProgramRequirementDecision,
      updateProgramRequirement,
      identityTabCheckmarkStatus,
      syncIdentityProgramRequirementDecision,
      verifyIdentityCheckmark,
      getApplicationProgramRequirements,
      handleResponseErrors,
    };
  }
);
