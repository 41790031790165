import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "xtitlearea" }
const _hoisted_2 = { class: "button-container" }
const _hoisted_3 = {
  class: "xusericon",
  "data-size": "small"
}
const _hoisted_4 = { class: "xusericon-picture" }
const _hoisted_5 = {
  class: "xusericon",
  "data-size": "small"
}
const _hoisted_6 = { class: "xusericon-picture" }

import {
    ref,
    onMounted,
    computed,
    watch,
  } from "vue";
  import DocumentsUploaded from "./DocumentsUploaded.vue";
  import DocumentsGenerated from "./DocumentsGenerated.vue";
  import moment from "moment-timezone";
  import ApplicationComments from "@/components/ApplicationComments.vue";

  interface Props {
    isTabActive: boolean;
    applicants: any | null;
    isLoading?: boolean | null;
    canEdit?: boolean;
    commentsId: any | null;
    commentData: [];
    application: any | null;
    generateLegalPacket: any | null;
    getDocumentsByApplicant: any | null;
    getDocumentsByApplication: any | null;
    commentsComponentKey: any | null;
    initializeSelectedTypes: any | null;
  }

  interface Emits {
    (eventName: "toggle", value: unknown): void;
    (eventName: "getApplicationComments"): void;
    (eventName: "getPdfDocument", value: unknown): void;
  }

  
export default /*@__PURE__*/_defineComponent({
  __name: 'DocumentsTab',
  props: {
    isTabActive: { type: Boolean },
    applicants: {},
    isLoading: { type: [Boolean, null] },
    canEdit: { type: Boolean },
    commentsId: {},
    commentData: {},
    application: {},
    generateLegalPacket: {},
    getDocumentsByApplicant: {},
    getDocumentsByApplication: {},
    commentsComponentKey: {},
    initializeSelectedTypes: {}
  },
  emits: ["toggle", "getApplicationComments", "getPdfDocument"],
  setup(__props: any, { emit: __emit }) {

  const props = __props;
  const emit = __emit;
  const documents = ref({ selected: "uploaded" });
  const application = ref(props.application);
  const applicants = ref(props.applicants);
  const applicationStatusId = ref(application.value.data.applicationStatusId);
  const commentData = computed(() => props.application.commentData);

  const componentKey = ref(0);

  // Keeping temporarily for reference
  // const buttonTypes = computed(() => {
  //   let types = ["uploaded"];

  //   if (!isCreditUnionUser.value) {
  //     types.push("generated");
  //   }
  //   return types;
  // });

  const documentDate = (dateString: string, timezone: string | null = null) => {
    const localTimezone = timezone || moment.tz.guess();
    return moment
      .tz(dateString, localTimezone)
      .format("MMMM DD YYYY, h:mm A z");
  };

  const activeComponent = computed(() => {
    return documents.value.selected === "uploaded"
      ? DocumentsUploaded
      : DocumentsGenerated;
  });

  const renderKey = ref(0);
  const forceRerender = (component: any) => {
    component.value += 1;
  };

  function handleButtonClick(type: string) {
    documents.value.selected = type;
  }

    // Keeping temporarily for reference
  // function getIcon(type: string) {
  //   return type === "uploaded" ? "file-upload" : "file-contract";
  // }

  // function getTitle(type: string) {
  //   return type === "uploaded" ? "Uploaded Documents" : "Generated Documents";
  // }

  function handleInitSelectedTypes(initializeFunc: any) {
    initializeFunc();
  }

  watch(
    () => props?.commentsComponentKey,
    () => {
      if (props?.isTabActive) {
        forceRerender(renderKey);
      }
    }
  );

  watch(
    () => props?.isTabActive,
    (newValue) => {
      if (newValue) {
        componentKey.value++;
        initializeData();
      }
    },
    {
      immediate: true,
    }
  );

  async function initializeData() {
    try {
      await props.getDocumentsByApplicant();
      await props.getDocumentsByApplication();
    } catch (error) {
      console.log(`Error: onMounted() could not be initialized`, error);
    }
  }

  if (props?.isTabActive) {
    initializeData();
  }

  onMounted(async () => {
    handleInitSelectedTypes;
    try {
      await props.getDocumentsByApplicant();
      await props.getDocumentsByApplication();
    } catch (error) {
      console.log(`Error: onMounted() could not be initialized`, error);
    }
  });

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_ctx.isTabActive)
    ? (_openBlock(), _createElementBlock("div", {
        class: "xpage-content-page",
        id: "xdocuments-tab",
        key: componentKey.value
      }, [
        _createElementVNode("div", _hoisted_1, [
          _cache[5] || (_cache[5] = _createElementVNode("div", { id: "feedback-banner-target" }, null, -1)),
          _cache[6] || (_cache[6] = _createElementVNode("h2", null, "Documents", -1)),
          _createVNode(ApplicationComments, {
            type: "documents",
            id: _ctx.commentsId,
            comments: commentData.value
          }, null, 8, ["id", "comments"])
        ]),
        _cache[10] || (_cache[10] = _createElementVNode("h3", { class: "smallcapitals" }, "Select an Operation :", -1)),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: _normalizeClass(["xnamebutton", { xselected: documents.value.selected === 'uploaded' }]),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (handleButtonClick('uploaded')))
          }, [
            _createElementVNode("h4", null, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_font_awesome_icon, {
                    icon: ['fas', 'file-upload'],
                    style: {"color":"white"}
                  })
                ]),
                _cache[7] || (_cache[7] = _createElementVNode("div", {
                  class: "xusericon-gender",
                  "data-gender": ""
                }, null, -1))
              ]),
              _cache[8] || (_cache[8] = _createElementVNode("div", { class: "testbox-outer" }, [
                _createElementVNode("div", { class: "testbox-name" }, "Uploaded Documents")
              ], -1))
            ])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(["xnamebutton", { xselected: documents.value.selected === 'generated' }]),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (handleButtonClick('generated')))
          }, [
            _createElementVNode("h4", null, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_font_awesome_icon, {
                    icon: ['fas', 'file-contract'],
                    style: {"color":"white"}
                  })
                ])
              ]),
              _cache[9] || (_cache[9] = _createElementVNode("div", { class: "testbox-outer" }, [
                _createElementVNode("div", { class: "testbox-name" }, "Generated Documents")
              ], -1))
            ])
          ], 2)
        ]),
        _cache[11] || (_cache[11] = _createElementVNode("div", { class: "xform-section" }, null, -1)),
        _createElementVNode("div", null, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(activeComponent.value), {
            applicants: applicants.value,
            application: application.value,
            isTabActive: _ctx.isTabActive,
            canEdit: _ctx.canEdit,
            onToggle: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('toggle', $event))),
            onGetApplicationComments: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('getApplicationComments'))),
            onGetPdfDocument: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('getPdfDocument', $event))),
            generateLegalPacket: _ctx.generateLegalPacket,
            documentDate: documentDate,
            commentData: commentData.value,
            onInitializeSelectedTypes: _ctx.initializeSelectedTypes
          }, null, 40, ["applicants", "application", "isTabActive", "canEdit", "generateLegalPacket", "commentData", "onInitializeSelectedTypes"]))
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})