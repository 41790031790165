import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, vShow as _vShow, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 1 }

import { ref, onMounted, useSlots, onBeforeUnmount, nextTick } from 'vue';
	import { InformationCircleIcon } from '@heroicons/vue/24/outline';

	interface PopoverStyle {
		left?: string;
		right?: string;
		bottom?: string;
	}

	
export default /*@__PURE__*/_defineComponent({
  __name: 'Popover',
  props: {
    text: {},
    icon: {}
  },
  setup(__props: any) {

	const props = __props;

	const slots = useSlots();

	const iconComponent = ref(props.icon || InformationCircleIcon);
	const isPopoverVisible = ref(false);

	const popoverStyle = ref<PopoverStyle>({});
	const iconContainerElement = ref<HTMLElement | null>(null);
	const popoverElement = ref<HTMLElement | null>(null);

	const showPopover = async () => {
		isPopoverVisible.value = true;

		await nextTick();

		adjustPopoverPosition();
	};

	const hidePopover = () => isPopoverVisible.value = false;

	const adjustPopoverPosition = () => {
		const container = iconContainerElement.value;
		const popoverEl = popoverElement.value;

		if (container && popoverEl) {
			const containerRect = container.getBoundingClientRect();
			const popoverRect = popoverEl.getBoundingClientRect();

			// adjust if popover overflows on the right
			if (containerRect.left + popoverRect.width / 2 > window.innerWidth) {
				popoverStyle.value = {
					right: '0'
				};
			}

			// adjust if popover overflows on the left
			if (containerRect.right - popoverRect.width / 2 < 0) {
				popoverStyle.value = {
					left: '0'
				};
			}

			// adjust if popover overflows on the bottom
			if (containerRect.bottom + popoverRect.height > window.innerHeight - 10) {
				popoverStyle.value.bottom = '100%';
			}
		}
	};

	onMounted(() => {
		if (!props.text && !slots.default) {
			console.warn("Popover: Either 'text' prop or default slot content is required.");
		}

		window.addEventListener('resize', adjustPopoverPosition);
	});

	onBeforeUnmount(() => {
		window.removeEventListener('resize', adjustPopoverPosition);
	});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "icon-container",
    ref_key: "iconContainerElement",
    ref: iconContainerElement,
    onMouseenter: showPopover,
    onMouseleave: hidePopover
  }, [
    _renderSlot(_ctx.$slots, "icon", {}, () => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(iconComponent.value), { class: "icon" }))
    ]),
    _withDirectives(_createElementVNode("div", {
      ref_key: "popoverElement",
      ref: popoverElement,
      style: _normalizeStyle(popoverStyle.value),
      class: "popover"
    }, [
      (_ctx.$slots.default)
        ? _renderSlot(_ctx.$slots, "default", { key: 0 })
        : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.text), 1))
    ], 4), [
      [_vShow, isPopoverVisible.value]
    ])
  ], 544))
}
}

})