<template>
  <div class="xheader">
    <div class="xheader-logo-mobile">
      <a href="#">
        <img src="@/assets/images/logo-student-choice-mobile.png" />
      </a>
    </div>

    <div
      class="xheader-menu-mobile"
      @click="store.state.mobileMenu = !store.state.mobileMenu">
      <div class="icon-menu"></div>
    </div>
    <!-- TODO: Refactor this search or bring in component searchbar, currently stable but error prone-->
    <div class="xheader-search">
      <input
        type="text"
        placeholder="Search..."
        v-model.trim="search.typing"
        @focus="search.focus = true"
        @blur="blurSearchbox"
        v-on:keyup="searchDropdown(search.typing)"
        v-on:keyup.enter="$event.target.blur()" />
      <div
        class="xheader-search-dropdown"
        v-if="search.typing.length >= 3 && search.focus">
        <nav v-if="computedApplicationsDropdown">
          <router-link
            v-for="(application, index) in computedApplicationsDropdown.slice(0,5)"
            :key="index"
            :to="`/${application.searchCategory.toLowerCase()}s/${
              application.referenceId
            }`"
            replace>
            <div class="xusericon" data-size="small">
              <div class="xusericon-picture">
                {{ application.initials || '' }}
              </div>
            </div>
            <div class="xheader-search-dropdown-result">
              <h4>
                <span
                  v-html="application.name || 'No Name'"
                  style="vertical-align: middle"></span>
                <span
                  class="xinfopill"
                  :data-type="application.searchCategory"
                  >{{ application.searchCategory }}</span
                >
              </h4>
              <h5
                v-if="application.searchNotification"
                v-html="application.searchNotification"></h5>
              <h5
                v-else
                v-html="application.lenderName || 'No Lender Provided'"></h5>
            </div>
          </router-link>
        </nav>
      </div>
      <div
        class="xheader-search-dropdown"
        v-if="search.typing.length < 3 && search.focus">
        <nav>
          <div
            v-if="recentlyViewed.length > 0 && search.typing.length < 3"
            style="
              text-transform: uppercase;
              font-size: 11px;
              margin-top: 20px;
              margin-bottom: 10px;
              padding-left: 15px;
            ">
            Recently Viewed
          </div>
          <router-link
            v-for="(application, index) in recentlyViewed.slice(0, 5)"
            :key="index"
            :to="`/${application.page}/${application.id}`"
            replace>
            <div class="xusericon" data-size="small">
              <div class="xusericon-picture">
                {{ application.firstName?.charAt(0)
                }}{{ application.lastName?.charAt(0) }}
              </div>
              <div class="xusericon-gender" data-gender=""></div>
            </div>
            <div class="xheader-search-dropdown-result">
              <h4>
                <span
                  v-html="application.name || 'No Name'"
                  style="vertical-align: middle"></span>
                <span class="xinfopill" :data-type="application.category">{{
                  application.category
                }}</span>
              </h4>
              <h5 v-html="functions.getLenderName(application.lenderId)"></h5>
            </div>
          </router-link>
          <div
            style="
              display: none;
              text-transform: uppercase;
              font-size: 11px;
              line-height: 36px;
              text-align: center;
              background: #eeeeee;
              cursor: pointer;
            "
            @click.prevent="headerSearch = ''">
            Show More
          </div>
        </nav>
      </div>
    </div>

    <div class="xheader-user" v-if="store.state.oidcStore.user">
      <a href="#" @click.prevent="toggle('headerDropdownVisble')">
        <div
          class="xusericon"
          data-size="small"
          style="float: left"
          @mouseenter="showTooltip = true"
          @mouseleave="showTooltip = false">
          <div class="xusericon-picture">
            {{ applicantInitials }}
          </div>
          <div v-if="showTooltip" class="tooltip">
            Your current role is: {{ roleNames.join(', ') }}
          </div>
        </div>
        <div class="xheader-user-name">
          {{ firstName }}
          {{ lastName }}
          <i class="icon-down-open-big"></i>
        </div>
        <div class="clear"></div>
      </a>
      <div
        class="xheader-user-dropdown"
        :class="{ visible: headerDropdownVisble }">
        <nav>
          <router-link to="/profile" class="icon-home" style="display: none"
            >Profile</router-link
          >
          <router-link
            to="/settings"
            class="icon-doc-text"
            style="display: none"
            >Settings</router-link
          >
          <router-link to="/logout">Logout</router-link>
        </nav>
      </div>
    </div>
    <div class="clear"></div>
  </div>
</template>

<script setup>
  import store from '@/store';
  import { onMounted, reactive, computed, ref } from 'vue';
  import functions from '@/use/functions.js';
  import useFunctions from '@/use/functions1';
  import { usePermissionsStore } from '@/store/store/permissionsStore';

  const { decodeHTMLEncodedStr } = useFunctions();

  // TODO: Refactor to use refs
  // --------------------------
  // This is a global ref declaration '$ref' that needed to be used as to not force a re-factor of the entire component
  // This is a temporary solution until the entire component can be re-factored to use Vue refs the correct way and all
  // legacy code is removed. Using $ref and direct store state mutation usually leads to tight coupling and is not
  // recommended so fully encapsulating app state in Pinia will make the app more predictable, scalable, maintain and
  // way easier to test.
  // --------------------------
  const showTooltip = ref(false);

  var headerSearch = ref('');
  var headerDropdownVisble = ref(false);
  var recentlyViewed = ref([]);
  var search = reactive({
    applicationData: [],
    loanData: [],
    typing: '',
    type: '',
    text: '',
    focus: false,
  });

  const permissionsStore = usePermissionsStore();

  const roleNames = computed(() => {
    if (permissionsStore.currentUser.roles) {
      return permissionsStore.currentUser.roles.map((role) => role.name);
    }
    return [];
  });

  async function searchDropdown(searchText) {
    // clean the search text
    searchText = functions.cleanSearchText(searchText);
    search.typing = searchText;

    // search the applications
				try {
					const appResponse = await functions.getApplications(searchText);
					search.applicationData = appResponse.data?.data || [];
				} catch (error) {
					search.applicationData = [];
					console.log(error);
				}

				try {
					const loanResponse = await functions.getLoans(searchText);
					search.loanData = loanResponse.data?.items || [];
				} catch (error) {
					search.loanData = [];
					console.log(error);
				}
		}
    // functions
    //   .getApplications(searchText)
    //   .then((response) => {
    //     search.applicationData = response.data.data;
    //   })
    //   .catch((error) => {
    //     search.applicationData = [];
    //     console.log(error);
    //   });

    // search the loans
    // functions
    //   .getLoans(searchText)
    //   .then((response) => {
    //     search.loanData = response.data.items;
    //   })
    //   .catch((error) => {
    //     search.loanData = [];
    //     console.log(error);
    //   });


  function toggle(key) {
    eval(`${key}.value=!${key}.value`);
  }

  function blurSearchbox() {
    setTimeout(() => {
      search.focus = false;
    }, 200);
  }

  const firstName = computed(() => {
    return decodeHTMLEncodedStr(store.state.oidcStore.user.given_name);
  });

  const lastName = computed(() => {
    return decodeHTMLEncodedStr(store.state.oidcStore.user.family_name);
  });

  const applicantInitials = computed(() => {
    return `${firstName?.value?.charAt(0)} ${lastName?.value?.charAt(0)}` || '';
  });

  const computedApplicationsDropdown = computed(() => {
    var data = [];

    if (Array.isArray(search.loanData)) {
      data = data.concat(search.loanData);
    }

    if (Array.isArray(search.applicationData)) {
      data = data.concat(search.applicationData);
    }

    return data;
  });

  onMounted(async () => {
    functions.getLenders();

    // if the localstorage has any recently viewed pages
    if (localStorage.getItem('cache.RecentlyViewedPages')) {
      // append to recentlyViewed
      recentlyViewed = JSON.parse(
        localStorage.getItem('cache.RecentlyViewedPages')
      );
    }
  });
</script>
<style scoped>
  .tooltip {
    position: absolute;
    left: -600%;
    top: 100;
    background-color: black;
    color: white;
    padding: 5px;
    border-radius: 5px;
    white-space: nowrap;
  }
</style>
