<template>
	<div class="spinner"></div>
</template>

<style scoped>
	.spinner {
		border: 8px solid rgba(0, 0, 0, 0.1);
		border-left-color: black;
		border-radius: 50%;
		width: 60px;
		height: 60px;
		animation: spin 0.75s linear infinite;
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}
</style>
