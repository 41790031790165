import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["disabled"]

import { computed, toRefs } from "vue";
  import { usePermissionsAccess } from "@/composables/usePermissionsAccess";
  import { Permissions, PermissionLevels } from "@/models/permissions";

  
export default /*@__PURE__*/_defineComponent({
  __name: 'PermissionsWrapper',
  props: {
    permissionIds: {
      type: Array as () => Permissions[],
      default: () => [],
    },
    permissionLevel: {
      type: Number as () => PermissionLevels,
      default: PermissionLevels.None,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showDisabledIfNotVisible: {
      // shows if not visible but component is disabled
      type: Boolean,
      default: false,
    },
  },
  emits: ["click"],
  setup(__props, { emit: __emit }) {

  const props = __props;

  const { permissionIds, permissionLevel, disabled, showDisabledIfNotVisible } =
    toRefs(props);

  const emit = __emit;

  const handleClick = (event) => {
    emit("click", event);
  };
                
  const permissionsAccess = usePermissionsAccess();

  const isVisible = computed(() => {
    const permissionIdsValue = permissionIds?.value || [];
    return (
      permissionIdsValue.length === 0 ||
      permissionsAccess.hasPermission(permissionIdsValue, permissionLevel.value)
    );
  });

  const isDisabled = computed(() => {
    return (
      disabled.value || (!isVisible.value && showDisabledIfNotVisible.value)
    );
  });

  const wrapperClass = computed(() => ({
    "permission-wrapper": true,
    "disable-clicks": isDisabled.value,
  }));

return (_ctx: any,_cache: any) => {
  return (isVisible.value || _unref(showDisabledIfNotVisible))
    ? (_openBlock(), _createElementBlock("div", _mergeProps({
        key: 0,
        disabled: isDisabled.value,
        class: wrapperClass.value,
        onClick: handleClick
      }, _ctx.$attrs), [
        _renderSlot(_ctx.$slots, "default")
      ], 16, _hoisted_1))
    : _createCommentVNode("", true)
}
}

})