import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "xpage-breadcrumbs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loader = _resolveComponent("loader")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_page = _resolveComponent("page")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_loader, { key: 0 }))
    : (_openBlock(), _createBlock(_component_page, { key: 1 }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_router_link, {
              to: "/dashboard",
              class: "icon-home"
            }),
            _cache[1] || (_cache[1] = _createElementVNode("span", null, ">", -1)),
            _createVNode(_component_router_link, { to: "/administration" }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("Administration")
              ])),
              _: 1
            })
          ]),
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "xpage-inner" }, [
            _createElementVNode("h2", null, "Administration")
          ], -1))
        ]),
        _: 1
      }))
}