import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { Document } from "@/models/opal";
import { useApplicationStore } from "@/store/store/applicationVMStore";
import axios from "axios";
import { useStore } from "vuex";
import { $api } from "@/services/api1";
import { useTypeStore } from "@/store/store/typeStore";
import { Option } from "@/models/form";
import {
  DocumentStatusTypeIds,
  DocumentStatusReasonIds,
} from "@/types/documents";

interface ApiResponse {
  success: boolean;
  results?: string;
  errors?: string[];
}

export const useDocumentsTabStore = defineStore("documentsTab", () => {
  const documents = ref<Array<any>>([]);
  const documentsList = ref<Array<any>>([]);
  const currentlyEditingDocIds = ref<Array<number>>([]);
  const currentlyEditingDocOwners = ref<Array<number>>([]);
  const documentPdfModalOpen = ref<boolean>(false);

  const uploadedDocuments = ref<Array<any>>([]);
  const generatedDocuments = ref<Array<any>>([]);
  const manualGenerateDocList = ref<Array<any>>([]);

  const store = useStore();
  const applicationStore = useApplicationStore();
  const typeStore = useTypeStore();

  const documentStatusReasonOptions = computed<Option[]>(
    () => typeStore.getDocumentStatusReasonOptions
  );
  const rejectedDocumentStatusReasons = computed<Option[]>(
    () => getDocumentStatusReasons(DocumentStatusTypeIds.REJECTED) || []
  );

  const escalatedDocumentStatusReasons = computed<Option[]>(
    () => getDocumentStatusReasons(DocumentStatusTypeIds.ESCALATED) || []
  );

  function currentDocumentStatusReasons(status: number): Array<Option> {
    if (status === DocumentStatusTypeIds.REJECTED) {
      return rejectedDocumentStatusReasons.value;
    } else if (status === DocumentStatusTypeIds.ESCALATED) {
      return escalatedDocumentStatusReasons.value;
    } else {
      return [];
    }
  }

  function getDocumentStatusReasons(status: number) {
    if (status === DocumentStatusTypeIds.REJECTED) {
      return documentStatusReasonOptions.value.filter(
        (dsr: Option) =>
          dsr.value !== DocumentStatusReasonIds.UNIQUE_DOCUMENT_TYPE &&
          dsr.value !== DocumentStatusReasonIds.UNSURE_OF_DOCUMENT_INFO &&
          dsr.value !== DocumentStatusReasonIds.PARTIALLY_USABLE_DOCUMENT &&
          dsr.value !== DocumentStatusReasonIds.OTHER
      );
    }

    if (status === DocumentStatusTypeIds.ESCALATED) {
      return documentStatusReasonOptions.value.filter(
        (dsr: Option) =>
          dsr.value !== DocumentStatusReasonIds.UNREADABLE &&
          dsr.value !== DocumentStatusReasonIds.INCOMPLETE &&
          dsr.value !== DocumentStatusReasonIds.NOT_USABLE &&
          dsr.value !== DocumentStatusReasonIds.DATA_INCOMPLETE &&
          dsr.value !== DocumentStatusReasonIds.DUPLICATE
      );
    }
  }
  async function fetchGeneratedDocuments(applicationId: number) {
    try {
      const response = await axios.get(`/api/documents/${applicationId}/list`);
      if (response.data.results) {
        generatedDocuments.value = response.data.results.generatedDocuments;
      }
    } catch (error) {
      console.error("Error fetching generated documents", error);
      throw error;
    }
  }

  function isEditDisabled(document: Document) {
    return document.documentStatusTypeName === "Accepted";
  }

  function updateDocument(
    documentId: number,
    updatedDocument: Partial<Document>
  ) {
    const index = documents.value.findIndex(
      (item) => item.documentId === documentId
    );

    if (index !== -1) {
      documents.value[index] = {
        ...documents.value[index],
        ...updatedDocument,
      };
    }
  }

  function updateUploadedDocument(
    documentId: number,
    updatedDocument: Partial<Document>
  ) {
    const index = uploadedDocuments.value.findIndex(
      (item) => item.documentId === documentId
    );

    if (index !== -1) {
      uploadedDocuments.value[index] = {
        ...uploadedDocuments.value[index],
        ...updatedDocument,
      };
    }
  }

  function toggleEditMode(documentId: number, applicantId: number) {
    const docIndex = currentlyEditingDocIds.value.indexOf(documentId);
    const ownerIndex = currentlyEditingDocOwners.value.indexOf(applicantId);

    if (docIndex === -1 && ownerIndex === -1) {
      currentlyEditingDocIds.value.push(documentId);
      currentlyEditingDocOwners.value.push(applicantId);
    } else {
      untoggleEditMode(documentId);
    }
  }

  function untoggleEditMode(documentId: number) {
    const index = currentlyEditingDocIds.value.indexOf(documentId);
    if (index !== -1) {
      currentlyEditingDocIds.value.splice(index, 1);
      currentlyEditingDocOwners.value.splice(index, 1);
    }
  }

  async function getDocumentsList() {
    try {
      const applicationId = applicationStore.applicationId;
      const response = await axios.get(`/api/documents/${applicationId}/list`);
      const data = response.data.results;
      documentsList.value = data.documents;
      uploadedDocuments.value = data.uploadedDocuments;
      generatedDocuments.value = data.generatedDocuments;
      manualGenerateDocList.value = data.manualGenerateDocList;
    } catch (error) {
      console.error(`Error getting documents list: ${error}`);
      throw error;
    }
  }

  async function generateManualDocument(
    documentTypeId: number,
    applicantId: number,
    comment: string
  ): Promise<ApiResponse> {
    try {
      const payload = {
        DocumentTypeId: documentTypeId,
        ApplicationId: applicationStore.applicationId,
        ApplicantId: applicantId,
        Comment: comment,
        UserId: store.state.oidcStore.user.sub,
        UserName: store.state.oidcStore.user.name,
      };

      const response = await axios.post(`/api/documents/manualdocgen`, payload);

      if (response.status === 200 && response.data.success === true) {
        return {
          success: true,
          results: response.data.results || "Document generated successfully",
        };
      } else {
        return {
          success: false,
          results: response.data.results || "Failed",
          errors: response.data.errors || [
            "Unknown error occurred while generating the document",
          ],
        };
      }
    } catch (error: any) {
      console.error("Error in generateManualDocument:", error);
      return {
        success: false,
        errors: [
          error.message ||
            "Network error occurred while generating the document",
        ],
      };
    }
  }

  async function getApplicantDocuments() {
    try {
      const getDocuments = async (applicantId: number) => {
        const response = await $api.documents.getDocumentsByApplicant(
          applicantId
        );
        return response;
      };

      const documentPromises = applicationStore.applicants.map((person) => {
        return person.id ? getDocuments(person.id) : Promise.resolve([]);
      });

      const allDocuments = await Promise.all(documentPromises);

      documents.value = allDocuments.flat();
      uploadedDocuments.value = documents.value.filter(
        (d: Document) =>
          d.documentStatusTypeId !== DocumentStatusTypeIds.GENERATED
      );
    } catch (error) {
      console.error("Error: getDocumentsByApplicant()", error);
    }
  }

  return {
    documents,
    documentsList,
    uploadedDocuments,
    generatedDocuments,
    manualGenerateDocList,
    getDocumentsList,
    updateDocument,
    updateUploadedDocument,
    isEditDisabled,
    currentlyEditingDocIds,
    currentlyEditingDocOwners,
    toggleEditMode,
    untoggleEditMode,
    documentPdfModalOpen,
    generateManualDocument,
    getApplicantDocuments,
    fetchGeneratedDocuments,
    currentDocumentStatusReasons,
  };
});
