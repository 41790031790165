import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "form" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-50" }
const _hoisted_5 = { class: "col-50" }
const _hoisted_6 = { class: "results" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col" }
const _hoisted_9 = { class: "col" }
const _hoisted_10 = {
  key: 0,
  class: "details"
}
const _hoisted_11 = { class: "row" }
const _hoisted_12 = ["disabled"]

import {
  reactive,
  ref,
  onMounted,
  computed,
  watch,
} from "vue";
import BaseFormField from "@/components/form-fields/BaseFormField.vue";
import { FormSchema, FieldSchema, Option } from "@/models/form";
import { useStore } from "vuex";
import { Applicant } from "@/models/opal";
import { $api } from "@/services/api1";
import useValidation from "@/use/validation";
import functions from "@/use/functions.js";
import useFunctions from "@/use/functions1";

interface Props {
  document: any;
  application: any;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'DocumentSplit',
  props: {
    document: {},
    application: {}
  },
  setup(__props: any) {

const props = __props;
const store = useStore();
const schema = reactive({
  documentTypeId: {
    name: "documentTypeId",
    type: "select",
    hideLabel: false,
    label: "Document Type",
    hideErrorMessage: false,
    required: true
  } as FieldSchema,
  applicantId: {
    name: "applicantId",
    type: "select",
    hideLabel: false,
    label: "Applicant",
    hideErrorMessage: true,
    required: true
  } as FieldSchema,
  pageFrom: {
    name: "pageFrom",
    type: "number",
    hideLabel: false,
    label: "Page From",
    hideErrorMessage: false,
    required: true
  } as FieldSchema,
  pageTo: {
    name: "pageTo",
    type: "number",
    hideLabel: false,
    label: "Page To",
    hideErrorMessage: false,
    required: true
  } as FieldSchema,
  applicantViewable: {
    name: "applicantViewable",
    type: "checkbox",
    hideLabel: false,
    label: "Applicant Viewable",
    hideErrorMessage: true,
    required: false
  } as FieldSchema,
});
const { validateForm } = useValidation();
const { sortLosTypesByName } = useFunctions();
let info = reactive({} as any);
let docSplits = reactive([] as any[]);
const splittingDocuments = ref<boolean>(false);

const documentTypes = sortLosTypesByName(store.state.types['DocumentType']?.results);
const documentTypeOptions = computed(() => documentTypes?.map((d: any) => {
  return {
    label: d.name,
    value: d.id
  }
}));

const applicantOptions = props.application?.applicants?.map((a: Applicant) => {
  return {
    label: `${a.fullName} - ${a.applicantTypeName}`,
    value: a.id
  }
})


const pages = computed<Array<any>>((i: any) => {
  return [...Array(info.pageCount).keys()].map((i) => i + 1);
});

function documentTypeName(docTypeId: number) {
  return documentTypes?.find((dt: any) => dt.id === docTypeId)?.name;
}

function applicantName(applicantId: number) {
  return applicantOptions.find((dt: any) => dt.value === applicantId)?.label;

}

async function getPageCount() {
  const response = await $api.documents.getDocumentPdfProperties(props?.document?.documentId);
  console.log('getPageCount()', response);

  if (response.pageCount) {
    return response.pageCount;
  } else {
    console.log("Error retrieving page count:");
    return;
  }

}

function setForm(splitRequest: any) {
  info.documentTypeId = splitRequest.documentId;
  info.pageFrom = splitRequest.options.startIndex;
  info.pageTo = splitRequest.options.endIndex;
  info.documentTypeId = splitRequest.options.documentType;
  info.applicantId = splitRequest.options.applicantId;
}
function resetForm() {
  info.documentTypeId = undefined;
  info.pageFrom = undefined;
  info.pageTo = undefined;
  info.documentTypeId = undefined;
  info.applicantId = undefined;
  info.editing = false;
  info.applicantViewable = false;
}

function split() {
  let documentSplitOptions = {
    startIndex: info.pageFrom,
    endIndex: info.pageTo,
    documentType: info.documentTypeId,
    applicantViewable: info.applicantViewable,
    applicantId: info.applicantId,
    rotationAngle: null
  }
  return documentSplitOptions;
}

function saveSplit() {
  console.log('save split()', info);
  const valid = validateForm(schema, info);

  // validate that pageFrom is less than or equal to pageTo
  if (info.pageFrom > info.pageTo) {
    functions.openModal({
      title: "Alert",
      description: "Please enter a valid range before saving option. Page To cannot be greater than Page From",
    });
  }
  if (valid && info.pageFrom <= info.pageTo) {
    docSplits.push(split());
    resetForm();
  }
}

async function submit() {
  splittingDocuments.value = true;

  let modifiedOptions = docSplits.map((request) => {
    const modifiedRequest = { ...request };

    // zero base indices
    modifiedRequest.startIndex -= 1;
    modifiedRequest.endIndex -= 1;

    return modifiedRequest;
  });

  let request = {
    documentId: props?.document?.documentId,
    options: modifiedOptions
  }

  const splitDocumentResponse = await $api.documents.splitDocument(request.documentId, request);

  if (splitDocumentResponse?.isSuccess) {
    docSplits.splice(0, docSplits.length);
    splittingDocuments.value = false;
  } else {
    functions.openModal({
      title: "Error",
      description: "There was an error splitting documents.",
    });
    splittingDocuments.value = false;
  }
}

function edit(request: any) {
  setForm(request);
}

function remove(index: any) {
  docSplits.splice(index, 1);
}


onMounted(async () => {
  info.pageCount = await getPageCount();
  info.documentId = props?.document?.documentId;
})

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(BaseFormField, {
        schema: schema.documentTypeId,
        modelValue: _unref(info).documentTypeId,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(info).documentTypeId) = $event)),
        modelModifiers: { number: true },
        options: documentTypeOptions.value
      }, null, 8, ["schema", "modelValue", "options"]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(BaseFormField, {
            modelValue: _unref(info).pageFrom,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(info).pageFrom) = $event)),
            modelModifiers: { number: true },
            schema: schema.pageFrom,
            options: pages.value,
            compare: _unref(info)
          }, null, 8, ["modelValue", "schema", "options", "compare"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(BaseFormField, {
            modelValue: _unref(info).pageTo,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(info).pageTo) = $event)),
            modelModifiers: { number: true },
            schema: schema.pageTo,
            options: pages.value,
            compare: _unref(info)
          }, null, 8, ["modelValue", "schema", "options", "compare"])
        ])
      ]),
      _createVNode(BaseFormField, {
        schema: schema.applicantId,
        modelValue: _unref(info).applicantId,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(info).applicantId) = $event)),
        modelModifiers: { number: true },
        options: _unref(applicantOptions)
      }, null, 8, ["schema", "modelValue", "options"]),
      _createVNode(BaseFormField, {
        schema: schema.applicantViewable,
        modelValue: _unref(info).applicantViewable,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(info).applicantViewable) = $event))
      }, null, 8, ["schema", "modelValue"]),
      _createElementVNode("div", { class: "row" }, [
        _createElementVNode("button", {
          class: "btn btn-sm",
          type: "submit",
          onClick: saveSplit
        }, "Save Option")
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(docSplits), (docSplit, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: index,
            class: "list-item"
          }, [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("span", null, "Page(s) " + _toDisplayString(docSplit.startIndex), 1),
                  _cache[5] || (_cache[5] = _createTextVNode(" ")),
                  _createElementVNode("span", null, "- " + _toDisplayString(docSplit.endIndex), 1)
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_font_awesome_icon, {
                    class: "icon",
                    onClick: ($event: any) => (docSplit.showDetails = !docSplit.showDetails),
                    icon: docSplit.showDetails ? 'fa-solid fa-circle-minus' : 'fa-solid fa-circle-plus'
                  }, null, 8, ["onClick", "icon"])
                ])
              ]),
              (docSplit.showDetails)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createElementVNode("p", null, [
                      _cache[6] || (_cache[6] = _createTextVNode("Document Type: ")),
                      _createElementVNode("span", null, _toDisplayString(documentTypeName(docSplit.documentType)), 1)
                    ]),
                    _createElementVNode("p", null, [
                      _cache[7] || (_cache[7] = _createTextVNode("Applicant: ")),
                      _createElementVNode("span", null, _toDisplayString(applicantName(docSplit.applicantId)), 1)
                    ]),
                    _createElementVNode("p", null, [
                      _cache[8] || (_cache[8] = _createTextVNode("Applicant Viewable: ")),
                      _createElementVNode("span", null, _toDisplayString(docSplit.applicantViewable), 1)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_11, [
        (_unref(docSplits)?.length > 0)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              type: "button",
              class: "btn btn-sm",
              onClick: submit,
              disabled: splittingDocuments.value
            }, "Split All", 8, _hoisted_12))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})