import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "icon-up-open-big"
}
const _hoisted_2 = {
  key: 1,
  class: "icon-down-open-big"
}
const _hoisted_3 = { class: "padding-form-container" }
const _hoisted_4 = { autocomplete: "off" }
const _hoisted_5 = { class: "nav-text-left" }
const _hoisted_6 = ["name", "checked", "onInput"]
const _hoisted_7 = { class: "smallcapitals padding-top-inline" }
const _hoisted_8 = { class: "denial-reasons" }
const _hoisted_9 = {
  key: 1,
  class: "comment-area"
}
const _hoisted_10 = { class: "xdropdown-buttons" }

import { reactive, ref, watch, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { FieldSchema, FormSchema, Option } from "@/models/form";
import { LOSApplication, Document } from "@/models/opal";
import BaseFormField from "@/components/form-fields/BaseFormField.vue";
import { $api } from "@/services/api1";
import functions from "@/use/functions";
import useFormHelpers from "@/use/formHelpers";
import useValidation from "@/use/validation";
import useFunctions from "@/use/functions1";
import { Permissions, PermissionLevels } from "@/models/permissions";
import PermissionsWrapper from "@/components/wrappers/PermissionsWrapper.vue";
import OpalButton from "@/components/shared/OpalButton.vue";
import { useDocumentsTabStore } from "@/store/store/documentsTabStore";

interface Props {
  document: Document;
  documents: Document[];
  application: LOSApplication;
  canEdit?: boolean;
  onClick: (event: Event) => void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DocumentStatus',
  props: {
    document: {},
    documents: {},
    application: {},
    canEdit: { type: Boolean },
    onClick: {}
  },
  emits: ["documentStatusChanged", "getComments"],
  setup(__props: any, { emit: __emit }) {

const store = useStore();
const documentsTabStore = useDocumentsTabStore();
const dropdownRef = ref<HTMLElement | null>(null);

const emit = __emit;
const { documentStatusReasonsOptions } = useFormHelpers();
const { validateForm } = useValidation();
const { decodeHTMLEncodedStr, sortLosTypesByName } = useFunctions();

const handleClick = (event: Event) => {
  if (props.onClick) {
    props.onClick(event);
  }
};

const handleClickOutside = (event: MouseEvent) => {
  if (dropdownRef.value && !dropdownRef.value.contains(event.target as Node)) {
    showStatusDropdown.value = false;
  }
};

const documentSchema = reactive<FormSchema>({
  documentStatusTypeId: {
    name: "documentStatusTypeId",
    type: "radio",
    hideLabel: true,
    hideErrorMessage: true,
    required: true,
  } as FieldSchema,
  documentStatusReasonId: {
    name: "documentStatusReasonId",
    type: "select",
    hideLabel: true,
    hideErrorMessage: false,
    errorMessage: "A reason is required",
    required: false,
  } as FieldSchema,
  comment: {
    name: "comment",
    type: "textarea",
    hideLabel: true,
    required: false,
  } as FieldSchema,
});

const savingChanges = ref<boolean>(false);
const sortedDocumentStatuses = sortLosTypesByName(
  store?.state.types["DocumentStatus"]?.results
);

const documentStatusOptions = sortedDocumentStatuses
  .filter((ds: any) => ds.name !== "Generated" && ds.name !== "Signed")
  .map((o: any) => {
    return {
      label: o.name ||  o.label,
      value: o.id || o.value,
    } as Option;
  });

const info = reactive({
  document: {} as any,
  originalDocument: {} as any,
} as any);

const props = __props;
const showStatusReasonsDropdown = ref<boolean>(false);
const showStatusDropdown = ref<boolean>(false);
const showCommentArea = ref<boolean>(false);

let originalDocuments = reactive({} as any);
function cancel() {
  showStatusDropdown.value = false;
  showStatusReasonsDropdown.value = false;
  resetDropdown();
}

function closeDropdown() {
  showStatusDropdown.value = false;
}

function resetDropdown() {
  info.comment = undefined;
  info.document = Object.assign({}, props.document);
}

function handleDocumentStatusChange(event: any) {
  info.document.documentStatusTypeId = event.value;
  info.document.documentStatusTypeName = event.label;

  if (
    info.document.documentStatusTypeName === "Rejected" ||
    info.document.documentStatusTypeName === "Escalated for Manager Review"
  ) {
    info.document.showStatusReasonSection = true;
  } else {
    info.document.showStatusReasonSection = false;
  }
}

function handleDocumentStatusReasonChange(evt: any) {
  const originalDocument = originalDocuments[info.document.applicantDocumentId];

  const newStatusReason = store?.state.types[
    "DocumentStatusReasons"
  ]?.results.find((dsr: any) => dsr.id === evt.documentStatusReasonId);
  if (
    newStatusReason &&
    newStatusReason.name === "Other" &&
    originalDocument?.documentStatusReasonName === newStatusReason.name
  ) {
    info.document.documentStatusReasonName = newStatusReason.name;
    info.comment =
      info.document.applicantDocumentComments[
        info.document.applicantDocumentComments.length - 1
      ]?.comment || null;

    documentSchema.comment.required = true;
    showCommentArea.value = true;
  } else if (
    newStatusReason &&
    newStatusReason.name === "Other" &&
    originalDocument?.documentStatusReasonName !== newStatusReason.name
  ) {
    info.document.documentStatusReasonName = newStatusReason.name;
    info.comment = "";
    documentSchema.comment.required = true;
    showCommentArea.value = true;
  } else {
    showCommentArea.value = false;
  }
}

function submit(document: Document) {
  if (
    document.documentStatusTypeName === "Rejected" ||
    document.documentStatusTypeName === "Escalated for Manager Review"
  ) {
    documentSchema.documentStatusReasonId.required = true;
  }

  const cleanedComment = info?.comment
    ? info?.comment?.trim().length !== 0
      ? info?.comment.trim()
      : undefined
    : undefined;

  info.comment = cleanedComment;

  const valid = validateForm(documentSchema, {
    documentStatusTypeId: document.documentStatusTypeId,
    documentStatusReasonId: document.documentStatusReasonId,
    comment: info.comment,
  });

  if (!valid) {
    return;
  } else {
    updateDocumentStatus();
  }
}

function setDropdown() {
  info.document.applicantDocumentComments =
    props.document.applicantDocumentComments;
  info.document.documentStatusReasonId = props.document.documentStatusReasonId;
  info.document.documentStatusReasonName =
    props.document.documentStatusReasonName;
  info.document.documentStatusTypeId = props.document.documentStatusTypeId;
  info.document.documentStatusTypeName = props.document.documentStatusTypeName;

  if (info.document.documentStatusTypeName === "Other") {
    showCommentArea.value = true;
    documentSchema.comment.required = true;
    info.comment =
      info.document.applicantDocumentComments[
        info.document.applicantDocumentComments.length - 1
      ]?.comment || null;
  }

  validateForm(documentSchema, {
    documentStatusTypeId: info.document.documentStatusTypeId,
    documentStatusReasonId: info.document.documentStatusReasonId,
    comment: info.comment,
  });
}

function dropdownClicked() {
  showStatusDropdown.value = !showStatusDropdown.value;

  if (showStatusDropdown.value === true) {
    originalDocuments[info.document.applicantDocumentId] = Object.assign(
      {},
      info.document
    );
    setDropdown();
    if (
      info.document.documentStatusTypeName === "Rejected" ||
      info.document.documentStatusTypeName === "Escalated for Manager Review"
    ) {
      info.document.showStatusReasonSection = true;

      if (info.document.documentStatusTypeName === "Other") {
        showCommentArea.value = true;
      }
    } else {
      info.document.showStatusReasonSection = false;
      showCommentArea.value = false;
      resetDropdown();
    }
  }
  //
  if (showStatusDropdown.value === false) {
    cancel();
  }
}

async function updateDocumentStatus() {
  savingChanges.value = true;
  const originalDocument = originalDocuments[info.document.applicantDocumentId];

  info.document.editStatus = !info.document.editStatus;
  let userDisplayName = `${decodeHTMLEncodedStr(
    store?.state?.oidcStore?.user?.given_name
  )} ${decodeHTMLEncodedStr(store?.state?.oidcStore?.user?.family_name)}`;

  let documentStatusUpdate = {
    applicantId: info.document?.applicantId,
    appDocumentId: info.document.applicantDocumentId,
    documentStatusTypeId: info.document.documentStatusTypeId,
    documentStatusReasonId: info.document.documentStatusReasonId,
    documentId: info.document.documentId,
    documentStatusReasonName: info.document.documentStatusReasonName,
    comment: info.comment,
    updatedBy: userDisplayName,
  };

  const lastIndex = info.document.applicantDocumentComments.length - 1 || 0;
  const lastReason =
    info.document.applicantDocumentComments.length > 0
      ? info.document.applicantDocumentComments[lastIndex]?.comment
      : undefined;

  if (
    info.document.documentStatusTypeId !==
      originalDocument?.documentStatusTypeId ||
    info.document.documentStatusReasonId !==
      originalDocument?.documentStatusReasonId ||
    info.comment !== lastReason
  ) {
    const response = await $api.documents.updateDocumentStatus(
      props.application.data.id,
      info.document.applicantId,
      info.document.applicantDocumentId,
      documentStatusUpdate
    );
    if (
      response.applicantDocumentId &&
      response.documentStatusTypeId &&
      response.documentStatusTypeName
    ) {
      info.document.applicantDocumentComments =
        response.applicantDocumentComments;
      info.document.documentStatusTypeId = response.documentStatusTypeId;
      info.document.documentStatusTypeName = response.documentStatusTypeName;
      info.document.documentStatusReasonId = response.documentStatusReasonId;
      info.document.documentStatusReasonName =
        response.documentStatusReasonName;

      delete originalDocuments[info.document.applicantDocumentId];

      documentsTabStore.updateDocument(response.documentId, response);
      emit("documentStatusChanged", info.document);
      emit("getComments");
      closeDropdown();
    } else {
      functions.openModal({
        title: "Error",
        description: "There was an issue updating the document status",
      });

      info.document.documentStatusTypeId =
        originalDocument?.documentStatusTypeId;
      info.document.documentStatusTypeName =
        originalDocument?.documentStatusTypeName;
      info.document.documentStatusReasonId =
        originalDocument?.documentStatusReasonId;
      info.document.documentStatusReasonName =
        originalDocument?.documentStatusReasonName;
    }
  } else {
    closeDropdown();
  }
  savingChanges.value = false;
}

watch(
  () => info.document.documentStatusTypeName,
  (newVal, oldVal) => {
    if (
      (oldVal === "Rejected" && newVal === "Escalated for Manager Review") ||
      (oldVal === "Escalated for Manager Review" && newVal === "Rejected")
    ) {
      info.document.documentStatusReasonId = null;
      info.document.documentStatusReasonName = null;
      info.document.showStatusReasonSection = true;
      info.comment = null;
      showCommentArea.value = false;
      documentSchema.documentStatusReasonId.required = true;
    } else if (
      (oldVal !== "Rejected" || oldVal !== "Escalated for Manager Review") &&
      (newVal === "Rejected" || newVal === "Escalated for Manager Review")
    ) {
      info.document.showStatusReasonSection = true;
      documentSchema.documentStatusReasonId.required = true;
    } else {
      info.document.showStatusReasonId = null;
      info.document.showStatusReasonName = null;
      info.document.showStatusReasonSection = false;
      documentSchema.documentStatusReasonId.required = false;
    }
  }
);

watch(
  () => info.document.documentStatusReasonName,
  (value) => {
    if (value === "Other") {
      showCommentArea.value = true;
      documentSchema.comment.required = true;
    } else {
      documentSchema.comment.required = false;
      showCommentArea.value = false;
    }
  }
);

onMounted(async () => {
  info.document = Object.assign({}, props?.document);
  setDropdown();
});

onMounted(() => {
  document.addEventListener("mousedown", handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener("mousedown", handleClickOutside);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "xpage-top-details-status",
    onClick: handleClick
  }, [
    _createVNode(PermissionsWrapper, {
      disabled: !_ctx.canEdit,
      showDisabledIfNotVisible: true,
      permissionIds: [_unref(Permissions).DocumentStatus],
      permissionLevel: _unref(PermissionLevels).Update
    }, {
      default: _withCtx(() => [
        _createElementVNode("h4", { onClick: dropdownClicked }, [
          _createTextVNode(_toDisplayString(info.document.documentStatusTypeName) + " ", 1),
          (showStatusDropdown.value)
            ? (_openBlock(), _createElementBlock("i", _hoisted_1))
            : (_openBlock(), _createElementBlock("i", _hoisted_2))
        ]),
        (showStatusDropdown.value)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "xheader-search-dropdown margin-top-inline",
              ref_key: "dropdownRef",
              ref: dropdownRef
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("form", _hoisted_4, [
                  _createElementVNode("nav", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(documentStatusOptions), (status) => {
                      return (_openBlock(), _createElementBlock("label", {
                        class: "xradio",
                        key: status.label
                      }, [
                        _createTextVNode(_toDisplayString(status.label) + " ", 1),
                        _createElementVNode("input", {
                          type: "radio",
                          name: `radio${status.id}`,
                          checked: info.document.documentStatusTypeId === status.value,
                          onInput: ($event: any) => (handleDocumentStatusChange(status))
                        }, null, 40, _hoisted_6),
                        _cache[4] || (_cache[4] = _createElementVNode("span", null, null, -1))
                      ]))
                    }), 128))
                  ]),
                  (info.document.showStatusReasonSection)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createElementVNode("h3", _hoisted_7, _toDisplayString(info.document.documentStatusTypeName === "Rejected"
                    ? "Reject"
                    : "Escalation") + " Reasons ", 1),
                        _createElementVNode("div", _hoisted_8, [
                          _createVNode(BaseFormField, {
                            modelValue: info.document.documentStatusReasonId,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((info.document.documentStatusReasonId) = $event)),
                            modelModifiers: { number: true },
                            schema: documentSchema.documentStatusReasonId,
                            options: 
                    _unref(documentStatusReasonsOptions)(
                      info.document.documentStatusTypeName
                    )
                  ,
                            onChange: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (
                    handleDocumentStatusReasonChange(info.document)
                  ), ["prevent"]))
                          }, null, 8, ["modelValue", "schema", "options"])
                        ])
                      ], 64))
                    : _createCommentVNode("", true),
                  (showCommentArea.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _cache[5] || (_cache[5] = _createElementVNode("h3", { class: "smallcapitals padding-top-inline" }, " Reason for Change: ", -1)),
                        _createVNode(BaseFormField, {
                          modelValue: info.comment,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((info.comment) = $event)),
                          schema: documentSchema.comment
                        }, null, 8, ["modelValue", "schema"])
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("button", {
                      onClick: _withModifiers(cancel, ["prevent"])
                    }, "Cancel"),
                    _createVNode(OpalButton, {
                      onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (submit(info.document)), ["prevent"])),
                      loading: savingChanges.value,
                      disabled: savingChanges.value,
                      text: "Save Changes"
                    }, null, 8, ["loading", "disabled"])
                  ])
                ])
              ])
            ], 512))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["disabled", "permissionIds", "permissionLevel"])
  ]))
}
}

})