import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "pdf-xmodal-animate" }
const _hoisted_2 = ["data-fullscreen"]
const _hoisted_3 = { class: "pdf-xmodal" }
const _hoisted_4 = {
  key: 0,
  class: "pdf-xmodal-title"
}
const _hoisted_5 = {
  key: 1,
  class: "pdf-xmodal-doc-details"
}
const _hoisted_6 = { class: "description-container" }
const _hoisted_7 = { class: "italic-text" }
const _hoisted_8 = { class: "doc-id-container" }
const _hoisted_9 = { class: "italic-text" }
const _hoisted_10 = {
  key: 2,
  class: "pdf-viewer-container"
}
const _hoisted_11 = {
  key: 3,
  class: "split-container"
}
const _hoisted_12 = { class: "left" }
const _hoisted_13 = { class: "right" }
const _hoisted_14 = { class: "pdf-viewer-container" }

import { computed } from '@vue/runtime-core';
import PdfViewer from '@/components/PdfViewer.vue';
import DocumentSplit from "@/components/documents/DocumentSplit.vue";
import { usePdfViewerModalStore } from "@/store/store/pdfViewerModalStore";


export default /*@__PURE__*/_defineComponent({
  __name: 'PdfViewerModal',
  setup(__props) {

const pdfViewerModalStore = usePdfViewerModalStore();

const pdfModal = computed(() => pdfViewerModalStore.pdfModal);
const application = computed(() => pdfModal.value?.application);
const documentId = computed(() => pdfViewerModalStore.pdfModal?.docId ? pdfViewerModalStore.pdfModal?.docId : "");
const document = computed(() => pdfModal.value?.documents?.find((d: any) => d.documentId === parseInt(documentId.value)));
const pdfModalActive = computed(() => pdfViewerModalStore.pdfModal.active);

const closePdfModal = () => {
	pdfViewerModalStore.pdfModal.active = false;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(pdfModalActive))
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "pdf-xmodal-wrapper",
          onClick: closePdfModal
        }, [
          _createElementVNode("div", {
            class: "pdf-xmodal-inner",
            "data-fullscreen": _unref(pdfModal).fullscreen,
            onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", {
                class: "pdf-xmodal-close",
                onClick: _withModifiers(closePdfModal, ["stop"])
              }, "X"),
              (_unref(pdfModal).title)
                ? (_openBlock(), _createElementBlock("h2", _hoisted_4, _toDisplayString(_unref(pdfModal).title), 1))
                : _createCommentVNode("", true),
              (_unref(pdfModal).description || _unref(pdfModal).docId)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _cache[1] || (_cache[1] = _createElementVNode("span", { class: "bold-text" }, "Document Description:", -1)),
                      _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(pdfModal).description), 1)
                    ]),
                    _createElementVNode("span", _hoisted_8, [
                      _cache[2] || (_cache[2] = _createElementVNode("span", { class: "bold-text" }, "Document Id:", -1)),
                      _createElementVNode("span", _hoisted_9, _toDisplayString(_unref(pdfModal).docId), 1)
                    ])
                  ]))
                : _createCommentVNode("", true),
              (!_unref(pdfModal).splitting)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createVNode(PdfViewer, {
                      docId: _unref(pdfModal).docId
                    }, null, 8, ["docId"])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createVNode(DocumentSplit, {
                        document: _unref(document),
                        application: _unref(application)
                      }, null, 8, ["document", "application"])
                    ]),
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("div", _hoisted_14, [
                        _createVNode(PdfViewer, {
                          docId: _unref(pdfModal).docId
                        }, null, 8, ["docId"])
                      ])
                    ])
                  ]))
            ])
          ], 8, _hoisted_2)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})