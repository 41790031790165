import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "pagination"
}
const _hoisted_2 = { key: 2 }
const _hoisted_3 = { class: "xclicked" }
const _hoisted_4 = {
  key: 7,
  class: "pagination__dots"
}

import { ref, PropType} from "vue";
import { useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'Pagination',
  props: {
    applications: {
        type: Object as PropType<any>,
        required: true
    },
    triggerSearch: {
        type: Function,
        required: true
    },
  pageNumber: {
    type: Number,
    required: false
  },
    pageTotal: {
        type: Number,
        required: true
    }
},
  setup(__props) {

const props = __props;


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (__props.applications.query.pageTotal > 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (__props.applications.query.pageNumber > 1)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (
                  __props.triggerSearch(
                    __props.applications.search.typing,
                    __props.applications.query.pageNumber - 1
                  )
                ))
              }, " < "))
            : _createCommentVNode("", true),
          (__props.applications.query.pageNumber > 1)
            ? (_openBlock(), _createElementBlock("button", {
                key: 1,
                onClick: _cache[1] || (_cache[1] = ($event: any) => (__props.triggerSearch(__props.applications.search.typing, 1)))
              }, " 1 "))
            : _createCommentVNode("", true),
          (__props.applications.query.pageNumber > 3)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, "..."))
            : _createCommentVNode("", true),
          (__props.applications.query.pageNumber - 2 > 1)
            ? (_openBlock(), _createElementBlock("button", {
                key: 3,
                onClick: _cache[2] || (_cache[2] = ($event: any) => (
                  __props.triggerSearch(
                    __props.applications.search.typing,
                    __props.applications.query.pageNumber - 2
                  )
                ))
              }, _toDisplayString(__props.applications.query.pageNumber - 2), 1))
            : _createCommentVNode("", true),
          (__props.applications.query.pageNumber - 1 > 1)
            ? (_openBlock(), _createElementBlock("button", {
                key: 4,
                onClick: _cache[3] || (_cache[3] = ($event: any) => (
                  __props.triggerSearch(
                    __props.applications.search.typing,
                    __props.applications.query.pageNumber - 1
                  )
                ))
              }, _toDisplayString(__props.applications.query.pageNumber - 1), 1))
            : _createCommentVNode("", true),
          _createElementVNode("button", _hoisted_3, _toDisplayString(__props.applications.query.pageNumber), 1),
          (
                  __props.applications.query.pageNumber + 1 <
                  __props.applications.query.pageTotal
                )
            ? (_openBlock(), _createElementBlock("button", {
                key: 5,
                onClick: _cache[4] || (_cache[4] = ($event: any) => (
                  __props.triggerSearch(
                    __props.applications.search.typing,
                    __props.applications.query.pageNumber + 1
                  )
                ))
              }, _toDisplayString(__props.applications.query.pageNumber + 1), 1))
            : _createCommentVNode("", true),
          (
                  __props.applications.query.pageNumber + 2 <
                  __props.applications.query.pageTotal
                )
            ? (_openBlock(), _createElementBlock("button", {
                key: 6,
                onClick: _cache[5] || (_cache[5] = ($event: any) => (
                  __props.triggerSearch(
                    __props.applications.search.typing,
                    __props.applications.query.pageNumber + 2
                  )
                ))
              }, _toDisplayString(__props.applications.query.pageNumber + 2), 1))
            : _createCommentVNode("", true),
          (
                  __props.applications.query.pageNumber <
                  __props.applications.query.pageTotal - 2
                )
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, "..."))
            : _createCommentVNode("", true),
          (
                  __props.applications.query.pageTotal > 1 &&
                  __props.applications.query.pageNumber < __props.applications.query.pageTotal
                )
            ? (_openBlock(), _createElementBlock("button", {
                key: 8,
                onClick: _cache[6] || (_cache[6] = ($event: any) => (
                  __props.triggerSearch(
                    __props.applications.search.typing,
                    __props.applications.query.pageTotal
                  )
                ))
              }, _toDisplayString(__props.applications.query.pageTotal), 1))
            : _createCommentVNode("", true),
          (
                  __props.applications.query.pageNumber < __props.applications.query.pageTotal
                )
            ? (_openBlock(), _createElementBlock("button", {
                key: 9,
                onClick: _cache[7] || (_cache[7] = ($event: any) => (
                  __props.triggerSearch(
                    __props.applications.search.typing,
                    __props.applications.query.pageNumber + 1
                  )
                ))
              }, " > "))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})