import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "xpage-content-page",
  id: "xidentity-tab"
}
const _hoisted_2 = { class: "xtitlearea" }
const _hoisted_3 = { class: "program-requirements-wrapper" }
const _hoisted_4 = { class: "context-table-container" }

import { toRefs, computed } from "vue";
import Notifications from "@/components/shared/Notifications.vue";
import ApplicationComments from "@/components/ApplicationComments.vue";
import IdentityResults from "./IdentityResults.vue";
import { Permissions, PermissionLevels } from "@/models/permissions";
import PermissionsWrapper from "@/components/wrappers/PermissionsWrapper.vue";
import IdentityProgramRequirements from "@/components/identity/IdentityProgramRequirements.vue";
import { LOSApplication } from "@/models/opal";

interface Props {
  application: LOSApplication;
  isTabActive: boolean;
  applicants: any;
  canEdit: boolean;
  isLoading: boolean;
  notificationsData: object;
  commentsId: any;
  getLexisNexisReport: any;
  cvi: { id: string; description: string };
  nas: { id: string; description: string };
  nap: { id: string; description: string };
  descriptions: any;
  riskIndicatorDescription: string[];
  riskIndicatorDescriptions: any;
  commentsComponentKey: any;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'IdentityTab',
  props: {
    application: {},
    isTabActive: { type: Boolean },
    applicants: {},
    canEdit: { type: Boolean },
    isLoading: { type: Boolean },
    notificationsData: {},
    commentsId: {},
    getLexisNexisReport: {},
    cvi: {},
    nas: {},
    nap: {},
    descriptions: {},
    riskIndicatorDescription: {},
    riskIndicatorDescriptions: {},
    commentsComponentKey: {}
  },
  emits: ["getTags"],
  setup(__props: any, { emit: __emit }) {

const emit = __emit;
const props = __props;
const riskIndicatorDescription = toRefs(props);
const commentData = computed(() => props.application.commentData);

return (_ctx: any,_cache: any) => {
  return (_ctx.isTabActive)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(Notifications, { notificationsData: _ctx.notificationsData }, null, 8, ["notificationsData"]),
        _createElementVNode("div", _hoisted_2, [
          _cache[1] || (_cache[1] = _createElementVNode("h2", null, "Identity", -1)),
          _createVNode(ApplicationComments, {
            type: "identity",
            id: _ctx.commentsId,
            comments: commentData.value
          }, null, 8, ["id", "comments"]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(PermissionsWrapper, {
              disabled: !_ctx.canEdit,
              showDisabledIfNotVisible: true,
              permissionIds: [_unref(Permissions).Identity],
              permissionLevel: _unref(PermissionLevels).Update
            }, {
              default: _withCtx(() => [
                _createVNode(IdentityProgramRequirements, {
                  onGetTags: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('getTags'))),
                  application: _ctx.application
                }, null, 8, ["application"])
              ]),
              _: 1
            }, 8, ["disabled", "permissionIds", "permissionLevel"])
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(IdentityResults, {
            applicants: _ctx.applicants,
            canEdit: _ctx.canEdit,
            isLoading: _ctx.isLoading,
            getLexisNexisReport: _ctx.getLexisNexisReport,
            cvi: _ctx.cvi,
            nas: _ctx.nas,
            nap: _ctx.nap,
            riskIndicatorDescription: _unref(riskIndicatorDescription)
          }, null, 8, ["applicants", "canEdit", "isLoading", "getLexisNexisReport", "cvi", "nas", "nap", "riskIndicatorDescription"])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})