import { ref, onMounted } from 'vue';
import axios from 'axios';
import { ServiceType } from '@/models/loans';

export function useDisbursementData() {
	const disbursementStatusOptions = ref<ServiceType[]>([]);
	const disbursementStatusReasonOptions = ref<ServiceType[]>([]);

	async function getStatusOptionsAsync() {
		try {
			const response = await axios.get('/api/loantypes/DisbursementStatusType');
			disbursementStatusOptions.value = response.data.results.map((type: ServiceType) => ({
				id: type.id,
				name: type.name
			}));
		} catch (error) {
			console.error('Error fetching disbursement statuses:', error);
		}
	}

	async function getStatusReasonOptionsAsync() {
		try {
			const response = await axios.get('/api/loantypes/DrawDisbursementStatusReasonType');
			disbursementStatusReasonOptions.value = response.data.results.map((type: ServiceType) => ({
				id: type.id,
				name: type.name
			}));
		} catch (error) {
			console.error('Error fetching disbursement status reasons:', error);
		}
	}

	onMounted(async () => {
		await getStatusOptionsAsync();
		await getStatusReasonOptionsAsync();
	});

	return {
		disbursementStatusOptions,
		disbursementStatusReasonOptions
	};
}
