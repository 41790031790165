import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, mergeProps as _mergeProps, withKeys as _withKeys, vShow as _vShow, withDirectives as _withDirectives, isRef as _isRef } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "help"
}
const _hoisted_3 = {
  key: 1,
  class: "hint"
}
const _hoisted_4 = ["value"]
const _hoisted_5 = { value: "" }
const _hoisted_6 = ["value", "selected"]
const _hoisted_7 = ["checked"]
const _hoisted_8 = { key: 4 }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = {
  key: 0,
  class: "help"
}
const _hoisted_11 = {
  key: 1,
  class: "hint"
}
const _hoisted_12 = ["checked", "value", "name", "onChange"]
const _hoisted_13 = {
  key: 5,
  class: "autocomplete-container"
}
const _hoisted_14 = ["type", "name", "value", "placeholder"]
const _hoisted_15 = { class: "results-wrapper" }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = ["name", "value", "maxLength", "allowZero", "allowDecimals"]
const _hoisted_18 = ["name", "value", "placeholder"]
const _hoisted_19 = ["title", "name", "value", "placeholder", "maxlength"]
const _hoisted_20 = ["type", "name", "value", "placeholder", "autocomplete", "maxlength", "title", "pattern"]
const _hoisted_21 = ["name", "value", "maxlength"]
const _hoisted_22 = ["type", "name", "value", "placeholder", "autocomplete", "maxlength", "title", "pattern"]
const _hoisted_23 = {
  key: 12,
  class: "hint"
}

import { onBeforeMount, reactive, ref, onUnmounted, useSlots, computed, watch, onMounted } from "vue";
import useValidation from "../../use/validation";
import { FormField, FieldSchema, Option } from "../../models/form";
import useFormatter from "../../use/formatter";
import type { PropType } from "vue";
import { vMaska, MaskInput  } from "maska";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import useHtmlUtilities from "@/use/htmlUtilities";


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseFormField',
  props: {
  schema: {
    type: Object as PropType<FieldSchema>,
    required: true,
  },
  modelValue: {
    type: [String, Number, Boolean, Object],
    default: "",
  },
  options: {
    type: Array as PropType<Array<Option>>,
    default: [] as Array<Option>,
  },
  autocompleteOptions: {
    type: Array as PropType<Array<any>>,
    default: [] as Array<any>,
  },
  compare: {
    type: [String, Number, Boolean, Object],
    required: false,
  },
  validateOnKeyDown: {
    type: Boolean,
    default: true,
  },
  validateOnKeyUp: {
    type: Boolean,
    default: true,
  },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;
const slots = useSlots();
const ssnInput = ref(null);
const selectInput = ref(null);
const phoneValue = ref("");
const rawValue = ref("");

const hasHelpSlot = computed<boolean>(() => !!slots.help);
const autoCompleteResultsErrorMessage = computed<string>(() => {
  if (props?.schema?.autocompleteProperty === "schoolName") {
    return "Your school is not on our eligible schools list, and your school must be listed to be eligible for disbursements. Please check your spelling.";
  }
  if (field?.autoCompleteResultsErrorMessage) {
    return field?.autoCompleteResultsErrorMessage;
  }
    return "Error";
});

const { validate, validateNationalIdNumber } = useValidation();
const { formatPhoneNumber, formatMoney } = useFormatter();
const { isElementVisible } = useHtmlUtilities();
const autocompleteResults = ref(null);
const autocompleteSearchInputError = ref("");
const el = ref();
// initialize maska plugin
new MaskInput("[data-maska]");

let autocompleteSearch = ref<string>("");
let field = reactive({} as FormField);
let cleanedValue = ref<string | undefined>("");
let autoCompleteResults = reactive([]) as Option[];
let autoCompleteIsOpen = ref<boolean>(false);
let autoCompleteArrowCounter = ref<number>(-1);

const ignoreKeys = [
  "Backspace",
  "ArrowLeft",
  "ArrowRight",
  "Tab",
  "Enter",
  "Delete",
  "Shift",
  "CapsLock",
];

function validateInput(event: any) {
  switch (props?.schema.type) {
    case "phone":
      validatePhoneInput(event);
      break;
    case "nationalIdNumber":
      validateSsnInput(event);
      break;
    case "zipcode":
      validateZipCodeInput(event);
      break;
    case "year":
      validateYearInput(event);
      break;
      case "money":
      validateMoneyInput(event);
      break;
    default:
      break;
  }
}

function validateYearInput(event: any) {
  if (ignoreKeys.indexOf(event.key) >= 0) {
    return;
  }
  const validDigit = /^[0-9]$/.test(event.key);
  if (!validDigit && event.key !== "Backspace") {
    event.preventDefault();
  }
}

function validateMoneyInput(event: any) {
  if (ignoreKeys.indexOf(event.key) >= 0) {
    return;
  }
  const validDigit = /^[0-9.]$/.test(event.key) || /^[.]$/.test(event.key);
  if ((!validDigit && event.key !== "Backspace")) {
    event.preventDefault();
  }
}

function validateSsnInput(event: any) {
  if (ignoreKeys.indexOf(event.key) >= 0) {
    return;
  }
  const validDigit = /^[0-9]$/.test(event.key) || /^[-]$/.test(event.key);
  const cleanedSsn = `${props?.modelValue}${event.key}`.split("-").join("");
  if ((!validDigit && event.key !== "Backspace") || cleanedSsn.length > 9) {
    event.preventDefault();
  }
}
function validateZipCodeInput(event: any) {
  if (ignoreKeys.indexOf(event.key) >= 0 || event.type === "keydown") {
    return;
  }
  const validDigit = /^[0-9]$/.test(event.key) || /^[-]$/.test(event.key);
  if (event.key !== undefined && (!validDigit || event.key === "Backspace")) {
    event.preventDefault();
  }
}
function handleOnBlur() {
  let stringValue = props?.modelValue?.toString();
  switch (field.type) {
    case "nationalIdNumber":
      // We only use the cleanedValue when the user has left the field
      cleanedValue.value = "";
      for (let i = 0; i < stringValue?.length; i++) {
        cleanedValue.value +=
          i <= 2 || i === 4 || i === 5 ? "*" : stringValue[i];
      }
      break;
    case "money":
      cleanedValue = formatMoney(parseInt(stringValue) || 0);
      break;
    default:
      break;
  }
}
function validatePhoneInput(event: any) {
  if (ignoreKeys.indexOf(event.key) >= 0 || event.type === "keydown") {
    return;
  }
  const cleanedPhone = `${event.target.value}${event.key}`.replace(
    /[^0-9]/g,
    ""
  );
  const re = /^[0-9\b]+$/;
  const validChar = /^[0-9]$/.test(event.key) || /^[)( ._-]+$/g.test(event.key);
  if (
    event.key !== undefined &&
    (!validChar || event.key === "Backspace" || cleanedPhone.length > 10)
  ) {
    event.preventDefault();
  }
}
function formatValue() {
  let cleanValue;
  switch (props?.schema.type) {
    case "phone":
      cleanValue = `${props?.modelValue}`.replace(/[^0-9]/g, "");
      if (cleanValue.trim().length === 10) {
        emit("update:modelValue", formatPhoneNumber(cleanValue));
      }
      break;
    case "money":
      cleanValue = `${props?.modelValue}`.replace(/[^$]/g, "");
      emit("update:modelValue", formatMoney(parseInt(cleanValue)));
      break;
    default:
      break;
  }
}
function handleBlur(schema: any, value: any, compare: any) {
  validate(schema, value, compare);
  if (schema.onBlur) {
    schema.onBlur(schema, value, compare);
  }
  formatValue();
}
function handleClickOutside(event: any) {
  if (!(el.value as HTMLElement).contains(event.target)) {
    autoCompleteIsOpen.value = false;
  }
}
function handleAutoCompleteBlur(schema: FormField | FieldSchema, value: any, compare: any) {
  if (
    props?.schema?.autocompleteProperty &&
    autocompleteSearch.value !==
    (value[props?.schema?.autocompleteProperty || ""] || "").trim()
  ) {
    emit("update:modelValue", { id: null });
    value = undefined;
  }
  validate(schema, value, compare);
}
function setAutocompleteValue(schema: any, result: any) {
  emit("update:modelValue", result);
  autocompleteSearch.value = props?.schema?.autocompleteProperty
        ? result[props?.schema?.autocompleteProperty]
        : result;
  autoCompleteIsOpen.value = false;
  autoCompleteArrowCounter.value = -1;
schema.errors = [];
}

function onAutocompleteChange(event) {
    autoCompleteIsOpen.value = true;
    autocompleteSearch.value = event.target.value;

    const inputValue = autocompleteSearch.value.toLowerCase();
    const options = props?.autocompleteOptions || [];

    autoCompleteResults = options.filter((item) => {
        if (typeof item === 'string') {
            return item.toLowerCase().includes(inputValue);
        } else if (typeof props?.modelValue === 'object' && props?.schema?.autocompleteProperty) {
            const autoCompleteProperty = String(item[props.schema.autocompleteProperty]).toLowerCase();
            return autoCompleteProperty.includes(inputValue);
        }
        return false;
    }).slice(0, 10);
}

function handlePaste(event: any) {
  let data = event.clipboardData.getData("text/plain");
  let matched = /^https?:\/\/.*$/.test(data);
  if (matched) {
    event.preventDefault();
  }
}

function onArrowDown() {
  // index appears to be offset by - 1
  if (autoCompleteArrowCounter.value < autoCompleteResults.length - 1) {
    autoCompleteArrowCounter.value = autoCompleteArrowCounter.value + 1;
    // Needs a split second to update the view
    setTimeout(() => {
      const results = autocompleteResults.value as any;
      const active = results.querySelector(".is-active");
      const isVisible = isElementVisible(active, results);
      if (!isVisible) {
        results.scrollTo(active.offsetBottom, active.offsetTop);
      }
    }, 50);
  }
}

function onArrowUp() {
  if (autoCompleteArrowCounter.value > 0) {
    autoCompleteArrowCounter.value = autoCompleteArrowCounter.value - 1;
    // Needs a split second to update the view
    setTimeout(() => {
      const results = autocompleteResults.value as any;
      const active = results.querySelector(".is-active");
      const isVisible = isElementVisible(active, results);
      if (!isVisible) {
        results.scrollTo(active.offsetTop, active.offsetBottom);
      }
    }, 50);
  }
}

function onEnter() {
  if (autoCompleteArrowCounter.value > -1) {
    const result: any = autoCompleteResults[autoCompleteArrowCounter.value];
    emit("update:modelValue", result);
    autocompleteSearch.value = props?.schema?.autocompleteProperty
      ? result[props?.schema?.autocompleteProperty]
      : result;
      autoCompleteArrowCounter.value = -1;
      autoCompleteIsOpen.value = false;
  }
}

function onEscape() {
  emit("update:modelValue", { id: null });
}

watch(() => props.modelValue, (newValue, oldValue) => {

  if (newValue == oldValue) {
    return;
  }

  // update autocomplete on external change
  if (props?.schema?.autocompleteProperty && props?.schema?.type === "autocomplete") {
    autocompleteSearch.value = newValue[props.schema.autocompleteProperty || ""];
  }

  if(props?.schema?.label !== field.label){
    field.label = props.schema.label;
  }

});

onBeforeMount(() => {
  // create form field
  if (props?.schema) {
    field = {
      ...props?.schema,
      touched: false,
      valid: false,
    } as FormField;
  }

  if (props?.schema?.type === "autocomplete") {
      autocompleteSearch.value = (props?.modelValue as any)[props?.schema?.autocompleteProperty || ""];
    }
  // If we don't do this it defaults booleans as true
  if (field.type === "checkbox" && props?.modelValue === "") {
    emit("update:modelValue", false);
  }
});
    onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});
onUnmounted(() => {
  // Destroy plugin
  const myMask =  new MaskInput("[data-maska]");
  myMask.destroy();
  document.removeEventListener("click", handleClickOutside);
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "el",
    ref: el,
    class: _normalizeClass(["xfield form-field", { checkbox: __props.schema.type === 'checkbox' }])
  }, [
    (_unref(field).type !== 'checkbox' && _unref(field).type !== 'radio' && !_unref(field).hideLabel)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: _normalizeClass(_unref(field).hideLabel ? 'hide' : '')
        }, [
          _renderSlot(_ctx.$slots, "label", {}, () => [
            _createTextVNode(_toDisplayString(_unref(field).label ? _unref(field).label : " ") + " ", 1),
            (_unref(field).required)
              ? (_openBlock(), _createElementBlock("i", _hoisted_1, "*"))
              : _createCommentVNode("", true)
          ]),
          (hasHelpSlot.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_unref(FontAwesomeIcon), {
                  class: "info",
                  icon: "fa-circle-info"
                }),
                _createElementVNode("div", {
                  class: _normalizeClass(["tooltip", _unref(field).tooltipLocation])
                }, [
                  _renderSlot(_ctx.$slots, "help", {}, () => [
                    _cache[37] || (_cache[37] = _createTextVNode(" Shalom from the tooltip! "))
                  ])
                ], 2)
              ]))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    (
        _ctx.$slots.hint &&
        __props.schema.hintPosition === 'top' &&
        _unref(field).type !== 'checkbox' &&
        _unref(field).type !== 'radio'
      )
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "hint")
        ]))
      : _createCommentVNode("", true),
    (_unref(field).type === 'select')
      ? (_openBlock(), _createElementBlock("select", _mergeProps({ key: 2 }, _ctx.$attrs, {
          value: __props.modelValue,
          class: ["form-control select", {
      'is-invalid': __props.schema.touched && !__props.schema.valid,
    }],
          onChange: _cache[0] || (_cache[0] = ($event: any) => {
        _ctx.$emit('update:modelValue',($event.target as any).value);
        _unref(validate)(__props.schema, ($event.target as any).value, __props.compare);
      }),
          onBlur: _cache[1] || (_cache[1] = ($event: any) => {
      handleOnBlur();
      _unref(validate)(__props.schema, __props.modelValue, __props.compare);})
        }), [
          _createElementVNode("option", _hoisted_5, [
            _renderSlot(_ctx.$slots, "default-option")
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.options, (option, index) => {
            return (_openBlock(), _createElementBlock("option", {
              value: option.value ? option.value : option,
              key: index,
              selected: option === __props.modelValue
            }, _toDisplayString(option.label ? option.label : option), 9, _hoisted_6))
          }), 128))
        ], 16, _hoisted_4))
      : (_unref(field).type === 'checkbox')
        ? (_openBlock(), _createElementBlock("label", _mergeProps({ key: 3 }, _ctx.$attrs, { class: "checkbox-container" }), [
            _renderSlot(_ctx.$slots, "label", {}, () => [
              _createTextVNode(_toDisplayString(_unref(field).label ? _unref(field).label : " "), 1)
            ]),
            _createElementVNode("input", _mergeProps(_ctx.$attrs, {
              class: ["form-check-input", {
          'is-invalid': __props.schema.touched && !__props.schema.valid,
        }],
              type: "checkbox",
              checked: __props.modelValue as any,
              onChange: _cache[2] || (_cache[2] = ($event: any) => {
          _ctx.$emit('update:modelValue', ($event.target as any).checked);
          _unref(validate)(__props.schema, ($event.target as any).checked, __props.compare);
        })
            }), null, 16, _hoisted_7),
            _cache[38] || (_cache[38] = _createElementVNode("span", { class: "checkmark" }, null, -1))
          ], 16))
        : (_unref(field).type === 'radio' && __props.options && __props.options.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              (!_unref(field).hideLabel)
                ? (_openBlock(), _createElementBlock("label", _hoisted_9, [
                    _renderSlot(_ctx.$slots, "label", {}, () => [
                      _createTextVNode(_toDisplayString(_unref(field).label ? _unref(field).label : " "), 1)
                    ]),
                    (hasHelpSlot.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          _createVNode(_unref(FontAwesomeIcon), { icon: "fas fa-circle-info" }),
                          _createElementVNode("div", {
                            class: _normalizeClass(["tooltip", _unref(field).tooltipLocation])
                          }, [
                            _renderSlot(_ctx.$slots, "help", {}, () => [
                              _cache[39] || (_cache[39] = _createTextVNode(" Shalom from the tooltip! "))
                            ])
                          ], 2)
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.$slots.hint && __props.schema.hintPosition === 'top')
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _renderSlot(_ctx.$slots, "hint")
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _mergeProps(_ctx.$attrs, { class: "radio-btns-container" }), [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.options, (option) => {
                  return (_openBlock(), _createElementBlock("label", {
                    key: option.value,
                    class: "xradio radio-container"
                  }, [
                    _renderSlot(_ctx.$slots, "radioLabel", {}, () => [
                      _createTextVNode(_toDisplayString(option.label ? option.label : ""), 1)
                    ]),
                    _createElementVNode("input", _mergeProps({
                      type: "radio",
                      ref_for: true,
                      ref: "radioInput",
                      checked: __props.modelValue === option.value,
                      value: option.value,
                      name: _unref(field).name || 'radio-group',
                      onChange: ($event: any) => {
              _ctx.$emit('update:modelValue', option.value !== undefined ? option.value : option);
              _unref(validate)(__props.schema, ($event.target as any).value, __props.compare);
            }
                    }, _ctx.$attrs), null, 16, _hoisted_12),
                    _cache[40] || (_cache[40] = _createElementVNode("span", { class: "checkmark" }, null, -1))
                  ]))
                }), 128))
              ], 16)
            ]))
          : (_unref(field).type === 'autocomplete')
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _createElementVNode("input", _mergeProps(_ctx.$attrs, {
                  class: ["form-control cuscd-desc", {
          'is-invalid': __props.schema.touched && !__props.schema.valid,
        }],
                  autocomplete: "off",
                  type: __props.schema.type === 'compare' ? __props.schema.compareType : __props.schema.type,
                  name: __props.schema.name ? __props.schema.name : undefined,
                  value: _unref(autocompleteSearch),
                  placeholder: _unref(field).placeholder ? _unref(field).placeholder : undefined,
                  onBlur: _cache[3] || (_cache[3] = ($event: any) => (handleAutoCompleteBlur(__props.schema, __props.modelValue, __props.compare))),
                  onInput: _cache[4] || (_cache[4] = ($event: any) => (onAutocompleteChange($event))),
                  onKeydown: [
                    _withKeys(onArrowDown, ["down"]),
                    _withKeys(onArrowUp, ["up"]),
                    _withKeys(onEnter, ["enter"])
                  ],
                  onKeyup: _cache[5] || (_cache[5] = ($event: any) => (
          __props.validateOnKeyUp || __props.schema.touched
            ? _unref(validate)(__props.schema, __props.modelValue, __props.compare)
            : undefined
        ))
                }), null, 16, _hoisted_14),
                _createElementVNode("div", _hoisted_15, [
                  _withDirectives(_createElementVNode("div", {
                    ref_key: "autocompleteSearchInputError",
                    ref: autocompleteSearchInputError,
                    class: "results-error"
                  }, _toDisplayString(autoCompleteResultsErrorMessage.value), 513), [
                    [_vShow, _unref(autoCompleteIsOpen) && _unref(autoCompleteResults).length === 0]
                  ]),
                  _withDirectives(_createElementVNode("ul", {
                    class: "autocomplete-results",
                    ref_key: "autocompleteResults",
                    ref: autocompleteResults
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(autoCompleteResults), (result, i) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: i,
                        class: _normalizeClass(["autocomplete-result", { 'is-active': i === _unref(autoCompleteArrowCounter) }]),
                        onClick: ($event: any) => (setAutocompleteValue(__props.schema, result))
                      }, _toDisplayString(__props.schema.autocompleteProperty
                ? result[__props.schema.autocompleteProperty]
                : result), 11, _hoisted_16))
                    }), 128))
                  ], 512), [
                    [_vShow, _unref(autoCompleteIsOpen) && _unref(autoCompleteResults).length > 0]
                  ])
                ])
              ]))
            : (_unref(field).type === 'money')
              ? _withDirectives((_openBlock(), _createElementBlock("input", _mergeProps({ key: 6 }, _ctx.$attrs, {
                  type: "text",
                  class: ["form-control money", {
        'is-invalid': __props.schema.touched && !__props.schema.valid,
      }],
                  "data-maska": "0.99",
                  "data-maska-tokens": "0:\\d:multiple|9:\\d:optional",
                  name: __props.schema.name ? __props.schema.name : undefined,
                  value: __props.modelValue,
                  maxLength: _unref(field).maxLength ? _unref(field).maxLength : undefined,
                  allowZero: _unref(field).allowZero ? _unref(field).allowZero : undefined,
                  allowDecimals: _unref(field).allowDecimals ? _unref(field)?.allowDecimals : undefined,
                  onKeydown: _cache[6] || (_cache[6] = ($event: any) => (validateInput($event))),
                  onPaste: _cache[7] || (_cache[7] = ($event: any) => (handlePaste($event))),
                  onInput: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('update:modelValue', ($event.target as any).value))),
                  onBlur: _cache[9] || (_cache[9] = ($event: any) => (_unref(validate)(__props.schema, __props.modelValue, __props.compare))),
                  onKeyup: _cache[10] || (_cache[10] = ($event: any) => (
        __props.validateOnKeyUp || __props.schema.touched
          ? _unref(validate)(__props.schema, __props.modelValue, __props.compare)
          : undefined
      ))
                }), null, 16, _hoisted_17)), [
                  [_unref(vMaska)]
                ])
              : (_unref(field).type === 'nationalIdNumber')
                ? (_openBlock(), _createElementBlock("input", _mergeProps({ key: 7 }, _ctx.$attrs, {
                    ref_key: "ssnInput",
                    ref: ssnInput,
                    type: "text",
                    class: ["form-control", {
        'is-invalid': __props.schema.touched && !__props.schema.valid,
      }],
                    name: __props.schema.name ? __props.schema.name : undefined,
                    value: _unref(cleanedValue) || __props.modelValue,
                    placeholder: _unref(field).placeholder ? _unref(field).placeholder : undefined,
                    onFocus: _cache[11] || (_cache[11] = ($event: any) => (_isRef(cleanedValue) //@ts-ignore
 ? cleanedValue.value = undefined : cleanedValue = undefined)),
                    onPaste: _cache[12] || (_cache[12] = ($event: any) => (handlePaste($event))),
                    onKeydown: _cache[13] || (_cache[13] = ($event: any) => (__props.validateOnKeyDown ? validateSsnInput($event) : undefined)),
                    onInput: _cache[14] || (_cache[14] = ($event: any) => (_ctx.$emit('update:modelValue', ($event.target as any).value))),
                    onBlur: _cache[15] || (_cache[15] = ($event: any) => {
        handleOnBlur();
        _unref(validateNationalIdNumber)(__props.schema, __props.modelValue, __props.compare);
      }),
                    onKeyup: _cache[16] || (_cache[16] = ($event: any) => (
        __props.validateOnKeyUp || __props.schema.touched
          ? _unref(validateNationalIdNumber)(__props.schema, __props.modelValue, __props.compare)
          : undefined
      ))
                  }), null, 16, _hoisted_18))
                : (_unref(field).type === 'zipcode')
                  ? (_openBlock(), _createElementBlock("input", _mergeProps({ key: 8 }, _ctx.$attrs, {
                      ref: "zipInput",
                      type: "text",
                      class: ["form-control", {
        'is-invalid': __props.schema.touched && !__props.schema.valid,
      }],
                      title: _unref(field).title ? _unref(field).title : undefined,
                      name: __props.schema.name ? __props.schema.name : undefined,
                      value: _unref(cleanedValue) || __props.modelValue,
                      placeholder: _unref(field).placeholder ? _unref(field).placeholder : undefined,
                      maxlength: (__props.modelValue as string).includes('-') ? 10 : 9,
                      onPaste: _cache[17] || (_cache[17] = ($event: any) => (handlePaste($event))),
                      onFocus: _cache[18] || (_cache[18] = ($event: any) => (_isRef(cleanedValue) //@ts-ignore
 ? cleanedValue.value = undefined : cleanedValue = undefined)),
                      onKeydown: _cache[19] || (_cache[19] = ($event: any) => (validateZipCodeInput($event))),
                      onKeypress: _cache[20] || (_cache[20] = ($event: any) => (validateZipCodeInput($event))),
                      onInput: _cache[21] || (_cache[21] = ($event: any) => (_ctx.$emit('update:modelValue', ($event.target as any).value))),
                      onBlur: _cache[22] || (_cache[22] = ($event: any) => {
        handleOnBlur();
        _unref(validate)(__props.schema, __props.modelValue, __props.compare);
      }),
                      onKeyup: _cache[23] || (_cache[23] = ($event: any) => (
        __props.validateOnKeyUp || __props.schema.touched
          ? _unref(validate)(__props.schema, __props.modelValue, __props.compare)
          : undefined
      ))
                    }), null, 16, _hoisted_19))
                  : (_unref(field).type ==='phone')
                    ? _withDirectives((_openBlock(), _createElementBlock("input", _mergeProps({ key: 9 }, _ctx.$attrs, {
                        class: ["form-control cuscd-desc", {
        'is-invalid': __props.schema.touched && !__props.schema.valid,
      }],
                        "data-maska": "###-###-####",
                        type: __props.schema.type === 'compare' ? __props.schema.compareType : __props.schema.type,
                        name: __props.schema.name ? __props.schema.name : undefined,
                        value: __props.modelValue,
                        placeholder: _unref(field).placeholder ? _unref(field).placeholder : undefined,
                        autocomplete: _unref(field).autocomplete ? _unref(field).autocomplete : undefined,
                        maxlength: _unref(field).maxLength,
                        title: _unref(field).title ? _unref(field).title : undefined,
                        pattern: _unref(field).pattern ? _unref(field).pattern : undefined,
                        onPaste: _cache[24] || (_cache[24] = ($event: any) => (handlePaste($event))),
                        onKeydown: _cache[25] || (_cache[25] = ($event: any) => (validateInput($event))),
                        onKeypress: _cache[26] || (_cache[26] = ($event: any) => (validateInput($event))),
                        onInput: _cache[27] || (_cache[27] = ($event: any) => (_ctx.$emit('update:modelValue', ($event.target as any).value))),
                        onBlur: ($event: any) => (undefined),
                        onKeyup: _cache[28] || (_cache[28] = ($event: any) => (
        __props.validateOnKeyUp || __props.schema.touched
          ? _unref(validate)(__props.schema, __props.modelValue, __props.compare)
          : undefined
      ))
                      }), null, 16, _hoisted_20)), [
                        [_unref(vMaska)]
                      ])
                    : (_unref(field).type === 'textarea')
                      ? (_openBlock(), _createElementBlock("textarea", _mergeProps({ key: 10 }, _ctx.$attrs, {
                          class: ["form-control", {
        'is-invalid': __props.schema.touched && !__props.schema.valid,
      }],
                          name: __props.schema.name ? __props.schema.name : undefined,
                          value: (__props.modelValue as string),
                          maxlength: _unref(field).maxLength ? _unref(field).maxLength : undefined,
                          onInput: _cache[29] || (_cache[29] = ($event: any) => (_ctx.$emit('update:modelValue', ($event.target as any).value))),
                          onKeyup: _cache[30] || (_cache[30] = ($event: any) => (
        (__props.validateOnKeyUp || __props.schema.touched) && __props.modelValue
          ? _unref(validate)(__props.schema, __props.modelValue, __props.compare)
          : undefined
      ))
                        }), null, 16, _hoisted_21))
                      : (_openBlock(), _createElementBlock("input", _mergeProps({ key: 11 }, _ctx.$attrs, {
                          class: ["form-control cuscd-desc", {
        'is-invalid': __props.schema.touched && !__props.schema.valid,
        valid:
          __props.schema.touched &&
          __props.schema.valid &&
          (__props.schema.type === 'email' ||
            __props.schema.type === 'phone' ||
            __props.schema.type === 'password' ||
            __props.schema.type === 'compare'),
      }],
                          type: __props.schema.type === 'compare' ? __props.schema.compareType : __props.schema.type,
                          name: __props.schema.name ? __props.schema.name : undefined,
                          value: __props.modelValue,
                          placeholder: _unref(field).placeholder ? _unref(field).placeholder : undefined,
                          autocomplete: _unref(field).autocomplete ? _unref(field).autocomplete : undefined,
                          maxlength: _unref(field).maxLength,
                          title: _unref(field).title ? _unref(field).title : undefined,
                          pattern: _unref(field).pattern ? _unref(field).pattern : undefined,
                          onPaste: _cache[31] || (_cache[31] = ($event: any) => (handlePaste($event))),
                          onKeydown: _cache[32] || (_cache[32] = ($event: any) => (validateInput($event))),
                          onKeypress: _cache[33] || (_cache[33] = ($event: any) => (validateInput($event))),
                          onInput: _cache[34] || (_cache[34] = ($event: any) => (_ctx.$emit('update:modelValue', ($event.target as any).value))),
                          onBlur: _cache[35] || (_cache[35] = ($event: any) => (handleBlur(__props.schema, __props.modelValue, __props.compare))),
                          onKeyup: _cache[36] || (_cache[36] = ($event: any) => (
        (__props.validateOnKeyUp || __props.schema.touched) && __props.modelValue
          ? _unref(validate)(__props.schema, __props.modelValue, __props.compare)
          : undefined
      ))
                        }), null, 16, _hoisted_22)),
    (_ctx.$slots.hint && __props.schema.hintPosition === 'bottom')
      ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
          _renderSlot(_ctx.$slots, "hint")
        ]))
      : _createCommentVNode("", true),
    (__props.schema.errors && __props.schema.errors.length >= 1 && !_unref(field).hideErrorMessage)
      ? (_openBlock(), _createElementBlock("ul", {
          key: 13,
          class: _normalizeClass(["invalid-feedback", {
        multiple: __props.schema.errors && __props.schema.errors.length > 1,
      }])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.schema.errors, (error, index) => {
            return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(error), 1))
          }), 128))
        ], 2))
      : _createCommentVNode("", true)
  ], 2))
}
}

})