import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { computed, ref, watch, reactive } from "vue";
import { ContextMenuComponent as EjsContextmenu } from "@syncfusion/ej2-vue-navigations";
import { MenuItemModel, MenuEventArgs, BeforeOpenCloseMenuEventArgs, ContextMenu } from '@syncfusion/ej2-navigations';
import { enableRipple } from '@syncfusion/ej2-base';
import { LOSApplication } from "@/models/opal";
import { useStore } from "vuex";
import useFunctions from "@/use/functions1";

interface Props {
  name?: string;
  programRequirement: any;
  appRequirementInformation: any;
  application: LOSApplication,
}

export default /*@__PURE__*/_defineComponent({
  __name: 'ProgramRequirementsContextMenu',
  props: {
    name: {},
    programRequirement: {},
    appRequirementInformation: {},
    application: {}
  },
  emits: ["updateRequirementStatus", "addRequirementStatus"],
  setup(__props: any, { emit: __emit }) {

enableRipple(true);
const store = useStore();
const cmenu = ref({} as any);

const props = __props;
const emit = __emit;
const isExternalUser = computed(() => isNaN(parseInt(store.state.oidcStore.user.los_access)) === false);
const contextMenuId = computed(() => `${props.name?.trim()}`);
const applicationStatus = computed(() => props.application.data.applicationStatusName?.trim().toLowerCase());
const { decodeHTMLEncodedStr, sortLosTypesByName, sortLosTypesByDescription } = useFunctions();

const sortedRequirementStatuses =  sortLosTypesByName(store?.state.types["RequirementStatuses"]?.results);
const requirementStatusOptions = computed(() => sortedRequirementStatuses?.map(
  (o: any) => {
    return {
    text: o.name,
    id: `RequirementStatusIdSelected_${o.id}`,
    showItemOnClick: true,
    items: [{
      text: o.description,
      id: `RequirementStatusIdSelectedDescription_${o.id}`,
    }]
    } 
}));
let menuItems: MenuItemModel[] = reactive(requirementStatusOptions.value);

async function onSelect(args: MenuEventArgs) {
  const menuObj = cmenu.value;

  if (args.item.id && props.name !== 'Employment') {
    const requirementStatusId = args.item.id.split("_")?.[1].trim() || "";
    let request = {} as any;
    if (props.appRequirementInformation?.applicationRequirementDecisionId > 0) {
      request = {
        applicationId: props.appRequirementInformation?.applicationId || props.application?.data?.id,
        applicationRequirementDecisionId: props.appRequirementInformation?.applicationRequirementDecisionId,
        requirementStatusId: parseInt(requirementStatusId),
      }
      emit('updateRequirementStatus', request);

    } else {
      const requirementStatusId = args.item.id.split("_")?.[1].trim() || "";
      request = {
        applicationId: props.appRequirementInformation?.applicationId || props.application?.data?.id,
        requirementTypeId:  props.appRequirementInformation?.requirementTypeId,
        requirementStatusId:  parseInt(requirementStatusId),
      }
      emit('addRequirementStatus', request);
    }


    menuObj.close();
  }
}

function beforeOpen(args: BeforeOpenCloseMenuEventArgs) {
  const menuObj = cmenu.value;
  const notAvailable =
  (props.appRequirementInformation?.programRequirementLimit === 'N/A') ||
  (props?.appRequirementInformation?.programRequirementTypeName?.trim()?.toLowerCase() === 'employment') ||
  (props?.appRequirementInformation?.programRequirementTypeName?.trim()?.toLowerCase() === 'dti' &&  props?.appRequirementInformation?.programRequirementTypeName?.trim()?.toLowerCase() === 'n/a');
  // console.log(`${props?.appRequirementInformation?.programRequirementTypeName} available to edit`, !notAvailable);

  if (notAvailable) {
    args.cancel = true;
  }
}

function beforeItemRender(args: MenuEventArgs) {
  if (props.name === 'Employment') {
    args.element.classList.add('inactive');
  }
}


return (_ctx: any,_cache: any) => {
  return (applicationStatus.value === 'submitted & processing' || applicationStatus.value === 'evaluation')
    ? (_openBlock(), _createBlock(_unref(EjsContextmenu), {
        key: 0,
        ref_key: "cmenu",
        ref: cmenu,
        target: `#${contextMenuId.value}`,
        items: _unref(menuItems),
        onSelect: onSelect,
        onBeforeItemRender: beforeItemRender,
        onBeforeOpen: beforeOpen
      }, null, 8, ["target", "items"]))
    : _createCommentVNode("", true)
}
}

})