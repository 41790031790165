import { ref, onMounted } from 'vue';
import axios from 'axios';
import { ServiceType } from '@/models/loans';

export function usePostDisbursementChangeData() {
	const disbursementCancellationReasonOptions = ref<ServiceType[]>([]);

	async function getStatusReasonOptionsAsync() {
		try {
			const response = await axios.get('/api/loantypes/DisbursementCancellationReason');
			disbursementCancellationReasonOptions.value = response.data.results.map((type: ServiceType) => ({
				id: type.id,
				name: type.description
			}));
		} catch (error) {
			console.error('Error fetching disbursement cancellation reasons:', error);
		}
	}

	onMounted(async () => {
		await getStatusReasonOptionsAsync();
	});

	return {
		disbursementCancellationReasonOptions
	};
}
