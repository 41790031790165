import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "modal-overlay" }
const _hoisted_2 = { class: "modal-container" }
const _hoisted_3 = ["for"]
const _hoisted_4 = ["disabled"]

import { reactive, watchEffect, computed, onMounted } from 'vue';
	import { Form, Field, ErrorMessage } from 'vee-validate';
	import { Schema } from 'yup';
	import { ServiceType } from '@/models/loans';
	import Popover from '@/components/shared/Popover.vue';

	interface FormField {
		name: string;
		label: string;
		type: string;
		value: any;
		width: string;
		options?: ServiceType[];
		tooltip?: string;
	}

	
export default /*@__PURE__*/_defineComponent({
  __name: 'PostDisbursementChangeForm',
  props: {
    onApply: { type: Function },
    onClose: { type: Function },
    fields: {},
    validationSchema: {}
  },
  setup(__props: any, { expose: __expose }) {

	const props = __props;

	const formData = reactive<Record<string, any>>({});
	const appliedFormData = reactive<Record<string, any>>({});

	const isFormValid = computed(() => {
		return (errors: Partial<Record<string, string | undefined>>) => errors && Object.keys(errors).length === 0;
	});

	function handleApply() {
		Object.assign(appliedFormData, formData); // persist applied changes
		props.onApply({ ...formData });
		props.onClose();
	}

	function clearFormData() {
		props.fields.forEach((field) => {
			formData[field.name] = field.value;
		});
	}

	function handleClose() {
		Object.assign(formData, appliedFormData); // reset to previously applied
		props.onClose();
	}

	__expose({
		clearFormData
	});

	watchEffect(() => {
		props.fields.forEach((field) => {
			formData[field.name] = field.value; // only update form
		});
	});

	onMounted(() => {
		props.fields.forEach((field) => {
			formData[field.name] = field.value;
		});

		Object.assign(appliedFormData, formData);
	});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "modal-header" }, [
        _createElementVNode("h3", null, "Post-Disbursement Change")
      ], -1)),
      _createVNode(_unref(Form), {
        "validation-schema": _ctx.validationSchema,
        class: "form-container",
        onSubmit: handleApply
      }, {
        default: _withCtx(({ errors }) => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (field) => {
            return (_openBlock(), _createElementBlock("div", {
              key: field.name,
              class: _normalizeClass(['form-input', field.width])
            }, [
              _createElementVNode("label", {
                for: field.name
              }, [
                _createTextVNode(_toDisplayString(field.label) + " ", 1),
                (field.tooltip)
                  ? (_openBlock(), _createBlock(Popover, {
                      key: 0,
                      text: field.tooltip
                    }, null, 8, ["text"]))
                  : _createCommentVNode("", true)
              ], 8, _hoisted_3),
              _createVNode(_unref(Field), {
                id: field.name,
                name: field.name,
                type: field.type,
                modelValue: formData[field.name],
                "onUpdate:modelValue": ($event: any) => ((formData[field.name]) = $event)
              }, null, 8, ["id", "name", "type", "modelValue", "onUpdate:modelValue"]),
              _createVNode(_unref(ErrorMessage), {
                name: field.name,
                class: "error"
              }, null, 8, ["name"])
            ], 2))
          }), 128)),
          _createElementVNode("button", {
            class: "submit-button col-6",
            type: "submit",
            disabled: !isFormValid.value(errors)
          }, "Apply", 8, _hoisted_4),
          _createElementVNode("button", {
            class: "secondary col-3",
            type: "button",
            onClick: _withModifiers(clearFormData, ["prevent"])
          }, "Clear"),
          _createElementVNode("button", {
            class: "secondary col-3",
            type: "button",
            onClick: _withModifiers(handleClose, ["prevent"])
          }, "Close")
        ]),
        _: 1
      }, 8, ["validation-schema"])
    ])
  ]))
}
}

})