import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "modal-content" }
const _hoisted_2 = { class: "modal-header" }
const _hoisted_3 = { class: "modal-body" }
const _hoisted_4 = {
  key: 0,
  class: "modal-sidebar"
}
const _hoisted_5 = { class: "modal-main" }

import { ref, computed, provide, onMounted, onUnmounted } from 'vue';
	import store from '@/store';
	import {
		PdfViewerComponent as EjsPdfViewer,
		Toolbar,
		Magnification,
		Navigation,
		LinkAnnotation,
		BookmarkView,
		Print,
		ThumbnailView,
		TextSelection,
		TextSearch
	} from '@syncfusion/ej2-vue-pdfviewer';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'DocumentViewerModal',
  props: {
    documentId: {},
    documentName: {},
    onClose: { type: Function }
  },
  setup(__props: any) {

	const props = __props;

	const oidcUser = computed(() => store.getters['oidcStore/oidcAccessToken']);
	const pdfViewerId = computed(() => `pdfViewer_${props.documentId}`);

	const pdfViewerRef = ref<EjsPdfViewer | null>(null);
	const ajaxRequestSettings = ref({
		ajaxHeaders: [
			{
				headerName: 'Authorization',
				headerValue: `Bearer ${oidcUser.value}`
			}
		]
	});

	const serviceUrl = ref('/api/pdf');
	const docId = ref(String(props.documentId)); // server is expecting docId

	const handleOverlayClick = (event: MouseEvent) => {
		const target = event.target as HTMLElement;
		if (target.classList.contains('modal-overlay')) {
			props.onClose();
		}
	};

	onMounted(() => {
		document.body.style.overflow = 'hidden';
	});

	onUnmounted(() => {
		document.body.style.overflow = '';

		if (pdfViewerRef.value) {
			pdfViewerRef.value.destroy();
		}
	})

	provide('PdfViewer', [
		Toolbar,
		Magnification,
		Navigation,
		LinkAnnotation,
		BookmarkView,
		Print,
		ThumbnailView,
		TextSelection,
		TextSearch
	]);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: handleOverlayClick
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h3", null, _toDisplayString(_ctx.documentName), 1),
        _createElementVNode("span", {
          class: "close",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args)))
        }, "×")
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.$slots.sidebar)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "sidebar")
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_unref(EjsPdfViewer), {
            ref_key: "pdfViewerRef",
            ref: pdfViewerRef,
            id: pdfViewerId.value,
            serviceUrl: serviceUrl.value,
            documentPath: docId.value,
            ajaxRequestSettings: ajaxRequestSettings.value,
            downloadFileName: _ctx.documentName
          }, null, 8, ["id", "serviceUrl", "documentPath", "ajaxRequestSettings", "downloadFileName"])
        ])
      ])
    ])
  ]))
}
}

})