import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "batch-header" }
const _hoisted_2 = { class: "header-section" }
const _hoisted_3 = { class: "row-data" }
const _hoisted_4 = { class: "row-data" }
const _hoisted_5 = { class: "row-data" }
const _hoisted_6 = { class: "header-section" }
const _hoisted_7 = { class: "row-data" }
const _hoisted_8 = { class: "row-data" }
const _hoisted_9 = { class: "row-data" }
const _hoisted_10 = { class: "header-section" }
const _hoisted_11 = { class: "row-data" }
const _hoisted_12 = { class: "row-data" }
const _hoisted_13 = { class: "row-data" }

import { toRefs } from 'vue';
	import { BatchDetailsModel } from '../../models/payoffBatches';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'BatchHeader',
  props: {
    batch: {}
  },
  setup(__props: any) {

	const props = __props;

	const { batch } = toRefs(props);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("span", null, "Batch Id", -1)),
        _cache[1] || (_cache[1] = _createElementVNode("span", { class: "dots" }, null, -1)),
        _createElementVNode("span", null, _toDisplayString(_unref(batch)?.idFormatted), 1)
      ]),
      _createElementVNode("p", _hoisted_4, [
        _cache[2] || (_cache[2] = _createElementVNode("span", null, "Created", -1)),
        _cache[3] || (_cache[3] = _createElementVNode("span", { class: "dots" }, null, -1)),
        _createElementVNode("span", null, _toDisplayString(_unref(batch)?.createdAtFormatted), 1)
      ]),
      _createElementVNode("p", _hoisted_5, [
        _cache[4] || (_cache[4] = _createElementVNode("span", null, "Prefund", -1)),
        _cache[5] || (_cache[5] = _createElementVNode("span", { class: "dots" }, null, -1)),
        _createElementVNode("span", null, _toDisplayString(_unref(batch)?.createdAtFormatted), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("p", _hoisted_7, [
        _cache[6] || (_cache[6] = _createElementVNode("span", null, "Total", -1)),
        _cache[7] || (_cache[7] = _createElementVNode("span", { class: "dots" }, null, -1)),
        _createElementVNode("span", null, _toDisplayString(_unref(batch)?.totalAmountFormatted), 1)
      ]),
      _createElementVNode("p", _hoisted_8, [
        _cache[8] || (_cache[8] = _createElementVNode("span", null, "Disbursement Count", -1)),
        _cache[9] || (_cache[9] = _createElementVNode("span", { class: "dots" }, null, -1)),
        _createElementVNode("span", null, _toDisplayString(_unref(batch)?.activePayoffCount), 1)
      ]),
      _createElementVNode("p", _hoisted_9, [
        _cache[10] || (_cache[10] = _createElementVNode("span", null, "Disbursement Report Date", -1)),
        _cache[11] || (_cache[11] = _createElementVNode("span", { class: "dots" }, null, -1)),
        _createElementVNode("span", null, _toDisplayString(_unref(batch)?.disbursementReportDateFormatted), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("p", _hoisted_11, [
        _cache[12] || (_cache[12] = _createElementVNode("span", null, "Approved By", -1)),
        _cache[13] || (_cache[13] = _createElementVNode("span", { class: "dots" }, null, -1)),
        _createElementVNode("span", null, _toDisplayString(_unref(batch)?.approvedBy ?? 'N/A'), 1)
      ]),
      _createElementVNode("p", _hoisted_12, [
        _cache[14] || (_cache[14] = _createElementVNode("span", null, "Approved Date", -1)),
        _cache[15] || (_cache[15] = _createElementVNode("span", { class: "dots" }, null, -1)),
        _createElementVNode("span", null, _toDisplayString(_unref(batch)?.approvedAtFormatted ?? 'N/A'), 1)
      ]),
      _createElementVNode("p", _hoisted_13, [
        _cache[16] || (_cache[16] = _createElementVNode("span", null, "Account Reports Date", -1)),
        _cache[17] || (_cache[17] = _createElementVNode("span", { class: "dots" }, null, -1)),
        _createElementVNode("span", null, _toDisplayString(_unref(batch)?.accountReportsDateFormatted), 1)
      ])
    ])
  ]))
}
}

})