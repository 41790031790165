import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["value"]
const _hoisted_3 = ["disabled"]

import { ref, reactive, computed, watchEffect } from 'vue';
	import axios from 'axios';
	import functions from '@/use/functions';
	import { Form, Field, ErrorMessage } from 'vee-validate';
	import { Borrower, LoanDocument, UpdateDocumentRequest } from '@/models/loans';
	import { useFormFields } from '@/composables/loan/documents/useEditDocumentForm';
	import { useDocumentData } from '@/composables/loan/documents/useDocumentData';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'EditDocumentForm',
  props: {
    document: {},
    borrowers: {},
    onDocumentChangedAsync: { type: Function }
  },
  setup(__props: any) {

	const props = __props;

	const isSubmitting = ref<boolean>(false);

	const updateDocumentRequest = ref<UpdateDocumentRequest>({
		documentTypeId: props.document.documentTypeId,
		borrowerId: props.document.borrowerId
	});

	const { documentTypeOptions } = useDocumentData();

	const { formFields, validationSchema } = useFormFields({
		document: updateDocumentRequest,
		borrowers: props.borrowers,
		documentTypeOptions
	});

	const formData = reactive<Record<string, any>>({});

	const isFormValid = computed(() => {
		return (errors: Partial<Record<string, string | undefined>>) => errors && Object.keys(errors).length === 0;
	});

	async function updateDocumentAsync(formData: Record<string, any>) {
		isSubmitting.value = true;

		try {
			const request = {
				id: props.document.id,
				...formData
			};

			await axios.post(`/api/loandocuments/${props.document.id}`, request);

			await props.onDocumentChangedAsync();
		} catch (error) {
			functions.openModal({
				title: 'Oops!',
				description: 'There was an issue updating the document. Please try again later.'
			});
		} finally {
			isSubmitting.value = false;
		}
	}

	watchEffect(() => {
		formFields.value.forEach((field) => {
			formData[field.name] = field.value;
		});
	});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Form), {
    "validation-schema": _unref(validationSchema),
    class: "form-container",
    onSubmit: updateDocumentAsync
  }, {
    default: _withCtx(({ errors }) => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(formFields), (field) => {
        return (_openBlock(), _createElementBlock("div", {
          key: field.name,
          class: _normalizeClass(['form-input', field.width])
        }, [
          _createElementVNode("label", {
            for: field.name
          }, _toDisplayString(field.label), 9, _hoisted_1),
          _createVNode(_unref(Field), {
            as: "select",
            id: field.name,
            name: field.name,
            modelValue: formData[field.name],
            "onUpdate:modelValue": ($event: any) => ((formData[field.name]) = $event),
            disabled: isSubmitting.value
          }, {
            default: _withCtx(() => [
              _cache[0] || (_cache[0] = _createElementVNode("option", null, null, -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(field.options, (option) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: option.id,
                  value: option.id
                }, _toDisplayString(option.name), 9, _hoisted_2))
              }), 128))
            ]),
            _: 2
          }, 1032, ["id", "name", "modelValue", "onUpdate:modelValue", "disabled"]),
          _createVNode(_unref(ErrorMessage), {
            name: field.name,
            class: "error"
          }, null, 8, ["name"])
        ], 2))
      }), 128)),
      _createElementVNode("button", {
        class: "submit-button col-12",
        type: "submit",
        disabled: !isFormValid.value(errors) || isSubmitting.value
      }, " Submit ", 8, _hoisted_3)
    ]),
    _: 1
  }, 8, ["validation-schema"]))
}
}

})