import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "xpage-content-page",
  id: "xincome-tab"
}
const _hoisted_2 = { class: "xtitlearea" }
const _hoisted_3 = { class: "xincome-sections" }
const _hoisted_4 = { class: "name-selection" }
const _hoisted_5 = { class: "income-table-wrapper" }

import {
    computed,
  } from "vue";
  import ApplicationComments from "@/components/ApplicationComments.vue";
  import IncomeTable from "./IncomeTable.vue";
  import { Applicant, LOSApplication } from "@/models/opal";
  import store from "@/store";
  import ProgramRequirements from "@/components/income/ProgramRequirements.vue";
  import DebtToIncome from "@/components/income/DebtToIncome.vue";
  import IncomeDebt from "@/components/income/IncomeDebt.vue";
  import Income from "@/components/income/Income.vue";
  import NameButton from "@/components/application/NameButton.vue";
  // import { useIsCreditUnionUser } from "@/use/useIsCreditUnionUser";
  import { Permissions, PermissionLevels } from "@/models/permissions";
  import PermissionsWrapper from "@/components/wrappers/PermissionsWrapper.vue";


  interface Props {
    isTabActive: boolean;
    primaryApplicant: Applicant | any;
    secondaryApplicant: Applicant | any;
    application: LOSApplication;
    commentsId: Array<any>;
    applicants: any;
    store: any;
    showDocuments?: boolean;
    applicant?: Applicant;
    canEdit: boolean;
  }
  
export default /*@__PURE__*/_defineComponent({
  __name: 'IncomeTab',
  props: {
    isTabActive: { type: Boolean },
    primaryApplicant: {},
    secondaryApplicant: {},
    application: {},
    commentsId: {},
    applicants: {},
    store: {},
    showDocuments: { type: Boolean },
    applicant: {},
    canEdit: { type: Boolean }
  },
  emits: [
    "nameBtnClicked",
    "getApplication",
    "getDocs",
    "addBtnClicked",
    "updateIncomesClicked",
    "getApplicantIncomes",
    "selectApplicant",
    "getComments",
    "programRequirementDecisionUpdated",
    "programRequirementDecisionAdded",
    "checkIncomeTab",
    "applicantIncomeUpdated",
  ],
  setup(__props: any, { emit: __emit }) {

  const emit = __emit;

  const props = __props;
  const commentData = computed(() => props.application.commentData);
  // const isCreditUnionUser = useIsCreditUnionUser();
  const currentApplication = computed(() => props.application);
  const currentApplicant = computed(() => props.applicants[props.applicants.selected]);

  function isSelected(applicantType: any) {
    return props?.applicants?.selected === applicantType;
  }


return (_ctx: any,_cache: any) => {
  return (_ctx.isTabActive)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[11] || (_cache[11] = _createElementVNode("h2", null, "Income", -1)),
          _createVNode(ApplicationComments, {
            type: "income",
            id: _ctx.commentsId,
            comments: commentData.value
          }, null, 8, ["id", "comments"]),
          _createVNode(PermissionsWrapper, {
            disabled: !_ctx.canEdit,
            showDisabledIfNotVisible: true,
            permissionIds: [_unref(Permissions).Income],
            permissionLevel: _unref(PermissionLevels).Update
          }, {
            default: _withCtx(() => [
              _createVNode(ProgramRequirements, {
                application: currentApplication.value,
                applicant: _ctx.applicant,
                canEdit: _ctx.canEdit,
                onProgramRequirementDecisionUpdated: _cache[0] || (_cache[0] = ($event: any) => (
              _ctx.$emit('programRequirementDecisionUpdated', $event)
            )),
                onProgramRequirementDecisionAdded: _cache[1] || (_cache[1] = ($event: any) => (
              _ctx.$emit('programRequirementDecisionAdded', $event)
            )),
                onCheckIncomeTab: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('checkIncomeTab', $event)))
              }, null, 8, ["application", "applicant", "canEdit"])
            ]),
            _: 1
          }, 8, ["disabled", "permissionIds", "permissionLevel"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(DebtToIncome, {
            primaryApplicant: _ctx.primaryApplicant,
            secondaryApplicant: _ctx.secondaryApplicant
          }, null, 8, ["primaryApplicant", "secondaryApplicant"]),
          _createVNode(Income, {
            primaryApplicant: _ctx.primaryApplicant,
            secondaryApplicant: _ctx.secondaryApplicant
          }, null, 8, ["primaryApplicant", "secondaryApplicant"]),
          _createVNode(IncomeDebt, {
            application: _ctx.application,
            primaryApplicant: _ctx.primaryApplicant,
            secondaryApplicant: _ctx.secondaryApplicant
          }, null, 8, ["application", "primaryApplicant", "secondaryApplicant"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[12] || (_cache[12] = _createElementVNode("h3", { class: "smallcapitals" }, "View Documents For:", -1)),
          _createVNode(NameButton, {
            applicant: _ctx.primaryApplicant || [],
            selected: isSelected('primary'),
            applicantType: 'primary',
            onNameBtnClicked: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('nameBtnClicked', 'primary')))
          }, null, 8, ["applicant", "selected"]),
          (_ctx.secondaryApplicant?.id)
            ? (_openBlock(), _createBlock(NameButton, {
                key: 0,
                applicant: _ctx.secondaryApplicant,
                selected: isSelected('secondary'),
                applicantType: 'secondary',
                onNameBtnClicked: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('nameBtnClicked', 'secondary')))
              }, null, 8, ["applicant", "selected"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(PermissionsWrapper, {
            permissionIds: [_unref(Permissions).Income],
            permissionLevel: _unref(PermissionLevels).Read
          }, {
            default: _withCtx(() => [
              _createVNode(IncomeTable, {
                applicant: currentApplicant.value,
                applicants: _ctx.applicants,
                application: _ctx.application,
                store: _unref(store),
                canEdit: _ctx.canEdit,
                onGetComments: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('getComments'))),
                onAddBtnClicked: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('addBtnClicked'))),
                onGetApplication: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('getApplication'))),
                onGetApplicantIncomes: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('getApplicantIncomes'))),
                onApplicantIncomeUpdated: _cache[9] || (_cache[9] = ($event: any) => (_ctx.$emit('applicantIncomeUpdated'))),
                onGetDocs: _cache[10] || (_cache[10] = ($event: any) => (_ctx.$emit('getDocs')))
              }, null, 8, ["applicant", "applicants", "application", "store", "canEdit"])
            ]),
            _: 1
          }, 8, ["permissionIds", "permissionLevel"])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})