<template>
	<div class="container-centered">
		<div>
			<h2>Page Not Found</h2>
			<p>Oopsie woopsie! Something went wrong!</p>
		</div>
	</div>
</template>

<style scoped>
	.container-centered {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100vh;
		text-align: center;
	}
</style>
