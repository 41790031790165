import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "file-upload",
    "on:onDragLeave": _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.dragleave && _ctx.dragleave(...args))),
    onOndragover: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.dragover && _ctx.dragover(...args))),
    "on:onDrop": _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.drop && _ctx.drop(...args)))
  }, [
    _createElementVNode("div", {
      class: "drag-drop-area",
      id: _ctx.uppyId
    }, null, 8, _hoisted_1),
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "UppyProgressBar" }, null, -1)),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.errorMessage), 1)
  ], 32))
}