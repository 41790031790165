import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, createSlots as _createSlots, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "data-row" }
const _hoisted_2 = { class: "actions-container" }
const _hoisted_3 = ["onClick"]

import { ref, computed } from 'vue';
	import { Borrower, LoanDocument } from '@/models/loans';
	import { PencilIcon, EyeIcon, ScissorsIcon } from '@heroicons/vue/24/solid';
	import DocumentViewerModal from '@/components/loan/documents/DocumentViewerModal.vue';
	import SplitDocumentForm from './SplitDocumentForm.vue';
	import EditDocumentForm from './EditDocumentForm.vue';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'DocumentRow',
  props: {
    document: {},
    borrowers: {},
    onDocumentChangedAsync: { type: Function }
  },
  setup(__props: any) {

	const props = __props;

	const viewingDocument = ref(false);
	const mode = ref<'view' | 'edit' | 'split' | 'rotate'>('view');

	const document = computed<LoanDocument>(() => props.document);

	const borrower = computed<Borrower | null | undefined>(() =>
		props.borrowers.find((b) => b.id === document.value.borrowerId)
	);

	const modeConfig = {
		view: {
			icon: EyeIcon,
			component: null,
			props: () => ({}),
			popoverText: 'View Document'
		},
		edit: {
			icon: PencilIcon,
			component: EditDocumentForm,
			props: () => ({
				document: document.value,
				borrowers: props.borrowers,
				onDocumentChangedAsync: props.onDocumentChangedAsync
			}),
			popoverText: 'Edit Document'
		},
		split: {
			icon: ScissorsIcon,
			component: SplitDocumentForm,
			props: () => ({
				documentId: document.value.id,
				borrowers: props.borrowers
			}),
			popoverText: 'Split Document'
		}
	};

	const currentSidebarComponent = computed(() => modeConfig[mode.value].component);
	const currentSidebarProps = computed(() => modeConfig[mode.value].props() || {});

	const changeModeAndView = (newMode: 'view' | 'edit' | 'split') => {
		mode.value = newMode;
		viewingDocument.value = true;
	};

	const closeDocument = () => (viewingDocument.value = false);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("tr", _hoisted_1, [
    _createElementVNode("td", null, _toDisplayString(document.value.name), 1),
    _createElementVNode("td", null, _toDisplayString(document.value.uploadAtUtcFormatted), 1),
    _createElementVNode("td", null, _toDisplayString(document.value.documentTypeHumanized), 1),
    _createElementVNode("td", null, _toDisplayString(borrower.value?.fullName || 'N/A'), 1),
    _createElementVNode("td", _hoisted_2, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(modeConfig, (action, key) => {
        return _createElementVNode("button", {
          key: key,
          onClick: ($event: any) => (changeModeAndView(key))
        }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(action.icon), { class: "action-icon" }))
        ], 8, _hoisted_3)
      }), 64))
    ]),
    (viewingDocument.value)
      ? (_openBlock(), _createBlock(DocumentViewerModal, {
          key: 0,
          documentId: document.value.id,
          documentName: document.value.name,
          onClose: closeDocument
        }, _createSlots({ _: 2 }, [
          (mode.value !== 'view')
            ? {
                name: "sidebar",
                fn: _withCtx(() => [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(currentSidebarComponent.value), _normalizeProps(_guardReactiveProps(currentSidebarProps.value)), null, 16))
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["documentId", "documentName"]))
      : _createCommentVNode("", true)
  ]))
}
}

})