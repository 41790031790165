import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withModifiers as _withModifiers, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "component-wrapper" }
const _hoisted_2 = { class: "xpage-top" }
const _hoisted_3 = { class: "xpage-top-col1" }
const _hoisted_4 = { class: "xpage-top-profile" }
const _hoisted_5 = { class: "applicant-name" }
const _hoisted_6 = { class: "lender-name" }
const _hoisted_7 = { class: "status-container" }
const _hoisted_8 = { class: "application-actions" }
const _hoisted_9 = { class: "xpage-top-details-status" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "xcollapse-mobile" }
const _hoisted_13 = { class: "xpage-top-col2" }
const _hoisted_14 = { class: "loan-data" }
const _hoisted_15 = { class: "loan-info" }
const _hoisted_16 = { class: "summary-mid-value" }
const _hoisted_17 = { class: "summary-low-value" }
const _hoisted_18 = { class: "xpage-top-col3" }
const _hoisted_19 = { class: "loan-data" }
const _hoisted_20 = { class: "loan-info" }
const _hoisted_21 = { class: "summary-mid-value" }
const _hoisted_22 = { class: "summary-low-value" }
const _hoisted_23 = { class: "xpage-top-right" }
const _hoisted_24 = { class: "stepper" }
const _hoisted_25 = { class: "step-icons" }
const _hoisted_26 = ["innerHTML"]
const _hoisted_27 = { class: "step-content" }
const _hoisted_28 = { class: "stat" }
const _hoisted_29 = {
  key: 0,
  class: "step-arrow"
}

import { ref, onMounted, computed } from "vue";
import { storeToRefs } from "pinia";
import { useApplicationStore } from "@/store/store/applicationVMStore";
import { Permissions, PermissionLevels } from "@/models/permissions";
import PermissionsWrapper from "@/components/wrappers/PermissionsWrapper.vue";
import PermissionsButton from "@/components/wrappers/PermissionsButton.vue";
import ApplicationStatus from "@/components/v2/application/ApplicationStatus.vue";
import CancellationModal from "@/components/v2/application/refi/CancellationModal.vue";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/vue/24/solid";
import functions from "@/use/functions";
import axios from "axios";
import { useRouter } from "vue-router";
import store from "@/store";

interface StatItem {
  name: string;
  status?: string;
  date?: string;
  step?: string | number;
}

interface ErrorResponseData {
  message?: string;
  errors?: string[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Details',
  setup(__props) {

const applicationStore = useApplicationStore();
const router = useRouter();
const { summary } = storeToRefs(applicationStore);

const currentStepIndex = ref(-1);
const showCancelModal = ref(false);
const mobileHidden = ref(true);

const rightToCancel = applicationStore.viewModel.rightToCancelDisplay;
const submittedDisplay = applicationStore.viewModel.submittedDisplay;
const esignEnvelopeCreatedDisplay =
  applicationStore.viewModel.esignEnvelopeCreatedDisplay;
const esignEnvelopeSignedDisplay =
  applicationStore.viewModel.esignEnvelopeSignedDisplay;
const anticipatedDisbursementDate =
  applicationStore.viewModel.anticipatedDibursementDisplay;
  const maxLoanAmount = applicationStore.viewModel.maxApprovedLimit

const stats: StatItem[] = [
  { name: "Application<br>Submitted", date: submittedDisplay },
  { name: "E-Sign Envelope<br>Created", date: esignEnvelopeCreatedDisplay },
  { name: "E-Sign Envelope<br>Signed", date: esignEnvelopeSignedDisplay },
  { name: "Right to Cancel<br>End Date", date: rightToCancel },
  {
    name: "Anticipated<br>Disbursement Date",
    date: anticipatedDisbursementDate,
  },
];

const showMoveToLoanService = computed(() => {
  return (
    applicationStore.viewModel.statusName === "Complete" &&
    !applicationStore.viewModel.loanId
  );
});

const toggleIconClass = computed(() =>
  mobileHidden.value ? "icon-down-open-big" : "icon-up-open-big"
);

const toggleApplicationDetailsMobile = () => {
  mobileHidden.value = !mobileHidden.value;
};

const updateApplication = async () => {
  const referenceId = applicationStore.referenceId;
  await Promise.all([
    applicationStore.getApplication(referenceId),
    applicationStore.getApplicationSummary(referenceId),
    applicationStore.getApplicationComments(referenceId),
  ]);
};

function getStepClass(index: number): string {
  if (!stats[index].date || stats[index].date !== "N/A") {
    return "step-complete";
  }

  if (currentStepIndex.value === -1 || index === currentStepIndex.value) {
    if (currentStepIndex.value === -1) {
      currentStepIndex.value = index;
    }
      return "step-active";
  }
  return "step-na";
}

async function moveToLoanService(confirmation) {
  if (confirmation) {
    functions.openModal({
      title: "Confirm",
      description:
        "Are you sure you want to move this application to Loan Service?",
      buttons: [
        { title: "Cancel", onClick: () => functions.closeModal() },
        { title: "Submit", onClick: () => moveToLoanService(true) },
      ],
    });
  } else {
    functions.closeModal();

    try {
      await axios.post(
        `/api/applications/${applicationStore.applicationId}/migrate`,
        {
          AuthorizedByUserId: store.state.oidcStore.user?.sub,
          MigrateApplicationTargets: applicationStore.viewModel.id,
        },
        { headers: { "Content-Type": "application/json" } }
      );

      functions.openModal({
        title: "Success",
        description: "Application has been successfully moved to Loan Service",
        buttons: [
          {
            title: "Close",
            onClick: () => {
              functions.closeModal();
              // refresh the page
              router.go(0);
            },
          },
        ],
      });
    } catch (error) {
      console.error("Error moving application to loan service", error);

      const modal = {
        title: "Error",
        description:
          "There was an issue moving this application to Loan Service",
        html: "",
      };

      if (axios.isAxiosError(error)) {
        const data = error.response?.data as ErrorResponseData;

        if (data) {
          if (data.message) {
            modal.description = data.message;
          }
          if (data.errors?.length) {
            modal.html = `<ul>${data.errors
              .map((e: string) => `<li>${e}</li>`)
              .join("")}</ul>`;
          }
        } else {
          modal.description = error.message;
        }
      } else if (error instanceof Error) {
        modal.description = error.message;
      } else {
        modal.description = "An unknown error occurred";
      }
      functions.openModal(modal);
    }
  }
}

onMounted(() => {
  for (let i = 0; i < stats.length; i++) {
    if (!stats[i].date || stats[i].date === "N/A") {
      currentStepIndex.value = i;
      break;
    }
  }
  if (currentStepIndex.value === -1) {
    currentStepIndex.value = stats.length - 1;
  }
});

return (_ctx: any,_cache: any) => {
  const _directive_auto_animate = _resolveDirective("auto-animate")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h2", _hoisted_5, _toDisplayString(_unref(summary).primaryApplicantFullName), 1),
          _createElementVNode("h3", _hoisted_6, _toDisplayString(_unref(summary).lenderName), 1),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(PermissionsWrapper, {
                showDisabledIfNotVisible: true,
                permissionIds: [_unref(Permissions).ProgramConfiguration],
                permissionLevel: _unref(PermissionLevels).Update
              }, {
                default: _withCtx(() => [
                  _createVNode(ApplicationStatus)
                ]),
                _: 1
              }, 8, ["permissionIds", "permissionLevel"]),
              (_unref(summary).isRightToCancel)
                ? (_openBlock(), _createBlock(PermissionsButton, {
                    key: 0,
                    permissionIds: [_unref(Permissions).RightToCancel],
                    permissionLevel: _unref(PermissionLevels).Update,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (showCancelModal.value = true)),
                    class: "cancel-application-btn"
                  }, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode(" Cancel Application ")
                    ])),
                    _: 1
                  }, 8, ["permissionIds", "permissionLevel"]))
                : _createCommentVNode("", true),
              _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_9, [
                (showMoveToLoanService.value)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      type: "submit",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (moveToLoanService(true))),
                      class: "loan-service-btn"
                    }, [
                      (showMoveToLoanService.value)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_10, "Move To Loan Service »"))
                        : (_openBlock(), _createElementBlock("span", _hoisted_11, "Processing..."))
                    ]))
                  : _createCommentVNode("", true)
              ])), [
                [_directive_auto_animate, { ..._ctx.$animate, duration: 100 }]
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("a", {
          href: "#",
          class: _normalizeClass(toggleIconClass.value),
          onClick: _withModifiers(toggleApplicationDetailsMobile, ["prevent"])
        }, null, 2)
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _cache[4] || (_cache[4] = _createElementVNode("h3", { class: "summary-top-value" }, "Loan Amount", -1)),
            _createElementVNode("h3", _hoisted_16, _toDisplayString(_unref(summary).loanAmount), 1),
            _createElementVNode("h4", _hoisted_17, " Max Approved: " + _toDisplayString(_unref(maxLoanAmount)), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("div", _hoisted_20, [
            _cache[5] || (_cache[5] = _createElementVNode("h3", { class: "summary-top-value" }, "Interest Rate", -1)),
            _createElementVNode("h3", _hoisted_21, _toDisplayString(_unref(summary).rate), 1),
            _createElementVNode("h4", _hoisted_22, _toDisplayString(_unref(summary).rateTypeName), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_23, [
        _createElementVNode("div", _hoisted_24, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(stats, (item, index) => {
            return _createElementVNode("div", {
              key: item.name,
              class: _normalizeClass(['step', getStepClass(index)])
            }, [
              _createElementVNode("div", _hoisted_25, [
                (index < currentStepIndex.value)
                  ? (_openBlock(), _createBlock(_unref(CheckCircleIcon), {
                      key: 0,
                      class: "step-icon"
                    }))
                  : (index === currentStepIndex.value)
                    ? (_openBlock(), _createBlock(_unref(ExclamationCircleIcon), {
                        key: 1,
                        class: "step-icon"
                      }))
                    : (_openBlock(), _createBlock(_unref(QuestionMarkCircleIcon), {
                        key: 2,
                        class: "step-icon"
                      }))
              ]),
              _createElementVNode("span", {
                class: "step-title",
                innerHTML: item.name
              }, null, 8, _hoisted_26),
              _createElementVNode("div", _hoisted_27, [
                _createElementVNode("div", _hoisted_28, _toDisplayString(item.date), 1)
              ]),
              (index < stats.length - 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                    (_openBlock(), _createElementBlock("svg", {
                      xmlns: "http://www.w3.org/2000/svg",
                      class: _normalizeClass(["arrow-icon", ['arrow-na', { 'arrow-na': item.date === 'N/A' }]]),
                      viewBox: "0 0 20 20",
                      fill: "currentColor"
                    }, _cache[6] || (_cache[6] = [
                      _createElementVNode("path", {
                        fill: "currentColor",
                        d: "M11 16H3V8h8V2l10 10l-10 10zm2-9v3H5v4h8v3l5-5z"
                      }, null, -1)
                    ]), 2))
                  ]))
                : _createCommentVNode("", true)
            ], 2)
          }), 64))
        ])
      ]),
      (showCancelModal.value)
        ? (_openBlock(), _createBlock(CancellationModal, {
            key: 0,
            onUpdate: updateApplication,
            onClose: _cache[2] || (_cache[2] = ($event: any) => (showCancelModal.value = $event))
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})