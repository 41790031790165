import { Ref } from 'vue';
import axios from 'axios';
import functions from '@/use/functions';
import { Draw } from '@/models/loans';

export function useDrawTermination(selectedDraw: Ref<Draw | null>, onTerminationAsync: () => Promise<void>) {
	async function confirmDrawTerminationAsync() {
		functions.openModal({
			title: 'Confirm Termination',
			description: 'Are you sure you want to terminate this draw?',
			buttons: [
				{
					title: 'Terminate',
					onClick: async () => await terminateDrawAsync()
				},
				{
					title: 'Back',
					onClick: () => functions.closeModal()
				}
			]
		});
	}

	async function terminateDrawAsync() {
		try {
			await axios.patch(`/api/loanservice/draws/${selectedDraw.value?.id}/terminate`);

			functions.openModal({
				title: 'Success',
				description: 'Draw successfully terminated!'
			});

			await onTerminationAsync();
		} catch (error) {
			functions.openModal({
				title: 'Oops!',
				description: 'There was an issue terminating the draw. Please try again later.'
			});
		}
	}

	return {
		confirmDrawTerminationAsync
	};
}
