import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "xincome-sections border-none" }
const _hoisted_2 = { class: "xincome-section border-none summary" }
const _hoisted_3 = { class: "xdetails-table summary" }
const _hoisted_4 = { class: "xdetails-col" }
const _hoisted_5 = { class: "xtext" }
const _hoisted_6 = { class: "xdetails-col" }
const _hoisted_7 = { class: "xtext" }

import { computed } from "vue";
import useFormatter from "@/use/formatter";

interface Props {
  applicant: any;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'IncomeTotals',
  props: {
    applicant: {}
  },
  setup(__props: any) {

const props = __props;
const { formatMoney } = useFormatter();
const prior = computed(() =>
  props.applicant.totalVerifiedPriorIncomeAmount
    ? formatMoney(props.applicant.totalVerifiedPriorIncomeAmount)
    : "N/A"
);
const priorPrior = computed(() =>
  props.applicant.totalVerifiedPriorPriorIncomeAmount
    ? formatMoney(props.applicant.totalVerifiedPriorPriorIncomeAmount)
    : "N/A"
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "xname" }, "Verified Prior Year Income", -1)),
          _createElementVNode("div", _hoisted_5, _toDisplayString(prior.value), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "xname" }, "Verified Prior-Prior Year Income", -1)),
          _createElementVNode("div", _hoisted_7, _toDisplayString(priorPrior.value), 1)
        ])
      ])
    ])
  ]))
}
}

})