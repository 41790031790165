import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "help"
}
const _hoisted_4 = {
  key: 1,
  class: "hint"
}
const _hoisted_5 = ["checked", "value", "name", "onChange"]
const _hoisted_6 = {
  key: 1,
  class: "hint"
}

import { onBeforeMount, reactive, ref, onUnmounted, useSlots, computed, watch, onMounted } from "vue";
import useValidation from "@/use/validation";
import { FormField, FieldSchema, Option } from "@/models/form";
import useFormatter from "@/use/formatter";
import type { PropType } from "vue";
import { vMaska, MaskInput  } from "maska";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import useHtmlUtilities from "@/use/htmlUtilities";


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseFormFieldRadioGroup',
  props: {
  schema: {
    type: Object as PropType<FieldSchema>,
    required: true,
  },
  modelValue: {
    type: [String, Number, Boolean, Object],
    default: "",
  },
  options: {
    type: Array as PropType<Array<Option>>,
    default: [] as Array<Option>,
  },
  autocompleteOptions: {
    type: Array as PropType<Array<any>>,
    default: [] as Array<any>,
  },
  compare: {
    type: [String, Number, Boolean, Object],
    required: false,
  },
  validateOnKeyDown: {
    type: Boolean,
    default: true,
  },
  validateOnKeyUp: {
    type: Boolean,
    default: true,
  },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;
const slots = useSlots();
const ssnInput = ref(null);
const selectInput = ref(null);
const phoneValue = ref("");
const rawValue = ref("");

const hasHelpSlot = computed<boolean>(() => !!slots.help);
const autoCompleteResultsErrorMessage = computed<string>(() => {
  if (props?.schema?.autocompleteProperty === "schoolName") {
    return "Your school is not on our eligible schools list, and your school must be listed to be eligible for disbursements. Please check your spelling.";
  }
  if (field?.autoCompleteResultsErrorMessage) {
    return field?.autoCompleteResultsErrorMessage;
  }
    return "Error";
});

const { validate } = useValidation();
const { formatPhoneNumber, formatMoney } = useFormatter();
const { isElementVisible } = useHtmlUtilities();

const el = ref();


let field = reactive({} as FormField);
let cleanedValue = ref<string | undefined>("");

const ignoreKeys = [
  "Backspace",
  "ArrowLeft",
  "ArrowRight",
  "Tab",
  "Enter",
  "Delete",
  "Shift",
  "CapsLock",
];

function handlePaste(event: any) {
  let data = event.clipboardData.getData("text/plain");
  let matched = /^https?:\/\/.*$/.test(data);
  if (matched) {
    event.preventDefault();
  }
}

onBeforeMount(() => {
  // create form field
  if (props?.schema) {
    field = {
      ...props?.schema,
      touched: false,
      valid: false,
    } as FormField;
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "el",
    ref: el,
    class: _normalizeClass(["xfield form-field", { checkbox: __props.schema.type === 'checkbox' }])
  }, [
    (_unref(field).type === 'radio' && __props.options && __props.options.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (!_unref(field).hideLabel)
            ? (_openBlock(), _createElementBlock("label", _hoisted_2, [
                _renderSlot(_ctx.$slots, "label", {}, () => [
                  _createTextVNode(_toDisplayString(_unref(field).label ? _unref(field).label : " "), 1)
                ]),
                (hasHelpSlot.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createVNode(_unref(FontAwesomeIcon), { icon: "fas fa-circle-info" }),
                      _createElementVNode("div", {
                        class: _normalizeClass(["tooltip", _unref(field).tooltipLocation])
                      }, [
                        _renderSlot(_ctx.$slots, "help", {}, () => [
                          _cache[0] || (_cache[0] = _createTextVNode(" Shalom from the tooltip! "))
                        ])
                      ], 2)
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (_ctx.$slots.hint && __props.schema.hintPosition === 'top')
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _renderSlot(_ctx.$slots, "hint")
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _mergeProps(_ctx.$attrs, { class: "radio-btns-container" }), [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.options, (option) => {
              return (_openBlock(), _createElementBlock("label", {
                key: option.value,
                class: "xradio"
              }, [
                _renderSlot(_ctx.$slots, "radioLabel", {}, () => [
                  _createTextVNode(_toDisplayString(option.label ? option.label : ""), 1)
                ]),
                _createElementVNode("input", _mergeProps({
                  type: "radio",
                  ref_for: true,
                  ref: "radioInput",
                  checked: __props.modelValue === option.value,
                  value: option.value,
                  name: `radio${_unref(field).name}` || 'radio-group',
                  onChange: ($event: any) => {
              _ctx.$emit('update:modelValue', option.value !== undefined ? option.value : option);
              _unref(validate)(__props.schema, ($event.target as any).value, __props.compare);
            }
                }, _ctx.$attrs), null, 16, _hoisted_5),
                _cache[1] || (_cache[1] = _createElementVNode("span", null, null, -1))
              ]))
            }), 128))
          ], 16)
        ]))
      : _createCommentVNode("", true),
    (_ctx.$slots.hint && __props.schema.hintPosition === 'bottom')
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _renderSlot(_ctx.$slots, "hint")
        ]))
      : _createCommentVNode("", true),
    (__props.schema.errors && __props.schema.errors.length >= 1 && !_unref(field).hideErrorMessage)
      ? (_openBlock(), _createElementBlock("ul", {
          key: 2,
          class: _normalizeClass(["invalid-feedback", {
        multiple: __props.schema.errors && __props.schema.errors.length > 1,
      }])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.schema.errors, (error, index) => {
            return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(error), 1))
          }), 128))
        ], 2))
      : _createCommentVNode("", true)
  ], 2))
}
}

})