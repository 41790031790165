import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "loan-summary" }
const _hoisted_2 = { class: "profile-section" }
const _hoisted_3 = { class: "profile-picture" }
const _hoisted_4 = { class: "column" }
const _hoisted_5 = { class: "subtext" }
const _hoisted_6 = { class: "subtext" }
const _hoisted_7 = { class: "status" }
const _hoisted_8 = { class: "column" }
const _hoisted_9 = { class: "value" }
const _hoisted_10 = { class: "subtext" }
const _hoisted_11 = { class: "column" }
const _hoisted_12 = { class: "value" }
const _hoisted_13 = { class: "subtext" }
const _hoisted_14 = { class: "details-wrapper" }

import { ref, computed } from 'vue';
	import SlideTransition from '@/components/shared/SlideTransition.vue';
	import Details from '@/components/loan/Details.vue';
	import { LoanDetails } from '@/models/loans';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'Header',
  props: {
    referenceId: {},
    loanDetails: {}
  },
  setup(__props: any) {

	const props = __props;

	const detailsVisible = ref(false);

	const toggleDetails = () => {
		detailsVisible.value = !detailsVisible.value;
	};

	const primaryBorrower = computed(() => {
		const borrowers = props.loanDetails.borrowers || [];
		if (borrowers && borrowers.length > 0) {
			return borrowers[0];
		}
		return null;
	});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", null, _toDisplayString(primaryBorrower.value?.initials || '?'), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h2", null, _toDisplayString(primaryBorrower.value?.fullName || 'Unknown'), 1),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.loanDetails.lenderName), 1),
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.loanDetails.programName), 1),
        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.loanDetails.loanStatusHumanized), 1)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _cache[0] || (_cache[0] = _createElementVNode("h3", null, "Total Disbursed Amount", -1)),
        _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.loanDetails.disbursedAmountFormatted), 1),
        _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.loanDetails.loanAmountTitle) + ": " + _toDisplayString(_ctx.loanDetails.loanAmountFormatted), 1)
      ]),
      _createElementVNode("div", _hoisted_11, [
        _cache[1] || (_cache[1] = _createElementVNode("h3", null, "Application Interest Rate", -1)),
        _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.loanDetails.applicationInterestRateFormatted), 1),
        _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.loanDetails.rateTypeHumanized), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("button", {
        onClick: toggleDetails,
        class: "toggle-button"
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(detailsVisible.value ? 'arrow-up' : 'arrow-down')
        }, null, 2)
      ]),
      _createVNode(SlideTransition, { "is-visible": detailsVisible.value }, {
        default: _withCtx(() => [
          _createVNode(Details, {
            referenceId: _ctx.referenceId,
            loanDetails: _ctx.loanDetails
          }, null, 8, ["referenceId", "loanDetails"])
        ]),
        _: 1
      }, 8, ["is-visible"])
    ])
  ], 64))
}
}

})