import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "knockout-container" }
const _hoisted_2 = { class: "knockouts" }
const _hoisted_3 = { class: "knockout" }
const _hoisted_4 = { class: "knockout-factor-description-name" }



interface Props {
	creditKnockoutsFactors: KnockoutFactors[] | null;
	knockoutFactorsDescription: string;
}

interface KnockoutFactors {
	knockoutFactorsCode: string;
	knockoutFactorsDescription: string;
	creditKnockoutsFactors: KnockoutFactors[] | null;
	value: string;
	decision: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CreditKnockoutsFactors',
  props: {
    creditKnockoutsFactors: {},
    knockoutFactorsDescription: {}
  },
  setup(__props: any) {

const props = __props;

const addSpaceBeforeCaps = (str: string) => {
	return str.replace(/([A-Z])/g, " $1").trim();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("header", null, [
          _createElementVNode("h4", _hoisted_4, _toDisplayString(addSpaceBeforeCaps(_ctx.knockoutFactorsDescription)), 1)
        ])
      ])
    ])
  ]))
}
}

})