import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "xpage-top" }
const _hoisted_2 = { class: "xpage-top-left" }
const _hoisted_3 = { class: "xpage-top-profile" }
const _hoisted_4 = { class: "xpage-top-profile-icon" }
const _hoisted_5 = { style: {"padding-top":"7px"} }
const _hoisted_6 = {
  key: 0,
  class: "xpage-top-details-status"
}
const _hoisted_7 = { class: "xcollapse-mobile" }
const _hoisted_8 = { class: "xpage-top-right-col" }
const _hoisted_9 = { class: "xpage-top-right-col" }
const _hoisted_10 = { class: "xpage-top-right-col" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }

import { onMounted } from "vue";
import { useRoute } from "vue-router";
import moment from "moment-timezone";
import functions from "@/use/functions";
import ApplicationStatus from "@/components/application/ApplicationStatus.vue";
import { Permissions, PermissionLevels } from "@/models/permissions";
import PermissionsWrapper from "../wrappers/PermissionsWrapper.vue";
import ApplicationTabsGroup from "./ApplicationTabsGroup.vue";

interface Props {
  application: any;
  applicants: any;
  store: any;
  getApplication: any;
  moveToLoanService: any;
  animate: any;
  localTimezone: string;
  lenderName: string;
  canEdit?: boolean | null;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ApplicationDetails',
  props: {
    application: {},
    applicants: {},
    store: {},
    getApplication: {},
    moveToLoanService: {},
    animate: {},
    localTimezone: {},
    lenderName: {},
    canEdit: { type: [Boolean, null] }
  },
  emits: [
  "moveToLoanService",
  "getApplication",
  "toggleApplicationDetails",
  "toggleApplicationDetailsMobile",
],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const route = useRoute();

const emit = __emit;

onMounted(() => {
  // console.log("ApplicationDetails.vue mounted");
  functions.getType("ApplicationStatus");
  // console.log(ApplicationDetails.vue props.application.data)
});

return (_ctx: any,_cache: any) => {
  const _directive_auto_animate = _resolveDirective("auto-animate")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(props.applicants["primary"].initials), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("h2", null, _toDisplayString(props.applicants["primary"].fullName || "No Name"), 1)
        ]),
        _createElementVNode("h3", null, _toDisplayString(props.lenderName || "No Lender Provided"), 1),
        _createVNode(PermissionsWrapper, {
          showDisabledIfNotVisible: true,
          permissionIds: [_unref(Permissions).ProgramConfiguration],
          permissionLevel: _unref(PermissionLevels).Update
        }, {
          default: _withCtx(() => [
            _createVNode(ApplicationStatus, {
              canEdit: _ctx.canEdit,
              store: _ctx.store,
              application: _ctx.application,
              applicants: _ctx.applicants,
              animate: { ..._ctx.$animate, duration: 100 },
              onGetApplication: _cache[0] || (_cache[0] = ($event: any) => (_ctx.getApplication(_unref(route).params.referenceId)))
            }, null, 8, ["canEdit", "store", "application", "applicants", "animate"])
          ]),
          _: 1
        }, 8, ["permissionIds", "permissionLevel"]),
        (
            props.application.data.applicationStatusName == 'Complete' &&
            !_ctx.application.data.loanId
          )
          ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("button", {
                type: "submit",
                class: "moveToLoanService-btn",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.moveToLoanService(true)))
              }, " Move To Loan Service » ")
            ])), [
              [_directive_auto_animate, { ..._ctx.$animate, duration: 100 }]
            ])
          : _createCommentVNode("", true),
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "clear" }, null, -1))
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("a", {
          href: "#",
          class: _normalizeClass([
            props.application.mobileHidden
              ? 'icon-down-open-big'
              : 'icon-up-open-big',
          ]),
          onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.$emit('toggleApplicationDetailsMobile')), ["prevent"]))
        }, null, 2)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["xpage-top-right", { xhideformobile: props.application.mobileHidden }])
      }, [
        _createElementVNode("div", _hoisted_8, [
          _cache[4] || (_cache[4] = _createElementVNode("h2", null, "Line of Credit", -1)),
          _createElementVNode("h3", null, _toDisplayString(props.application.data.loanAmount
                ? `$${props.application.data.loanAmount?.toLocaleString(
                    "en-US"
                  )}`
                : "—"), 1),
          _createElementVNode("h4", null, " Requested " + _toDisplayString(props.application.initialDrawData?.amount
                ? `$${props.application.initialDrawData?.amount?.toLocaleString(
                    "en-US"
                  )}`
                : "—"), 1)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _cache[5] || (_cache[5] = _createElementVNode("h2", null, "Anticipated Graduation", -1)),
          _createElementVNode("h3", null, _toDisplayString(_unref(moment)(
                props.application.data.schoolConfiguration?.graduationDate
              ).format("MMMM YYYY") || "—"), 1),
          _createElementVNode("h4", null, _toDisplayString(props.application.data.schoolConfiguration?.schoolName ||
              "No School Provided"), 1)
        ]),
        _createElementVNode("div", _hoisted_10, [
          _cache[7] || (_cache[7] = _createElementVNode("h2", null, "Interest Rate", -1)),
          (props.application.loanConfigData)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createElementVNode("h3", null, _toDisplayString(props.application.data.ratePlans?.filter(
                  (r) => r.id == props.application.loanConfigData.ratePlanId
                )?.[0]?.rate
                  ? props.application.data.ratePlans?.filter(
                      (r) => r.id == props.application.loanConfigData.ratePlanId
                    )?.[0]?.rate + "%"
                  : "—"), 1),
                _createElementVNode("h4", null, _toDisplayString(props.application.data.ratePlans?.filter(
                  (r) => r.id == props.application.loanConfigData.ratePlanId
                )?.[0]?.rateTypeName
                  ? props.application.data.ratePlans?.filter(
                      (r) => r.id == props.application.loanConfigData.ratePlanId
                    )?.[0]?.rateTypeName
                  : "Pending Approval"), 1)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_12, _cache[6] || (_cache[6] = [
                _createElementVNode("h3", null, "—", -1),
                _createElementVNode("h4", null, "Pending Approval", -1)
              ])))
        ])
      ], 2),
      _cache[8] || (_cache[8] = _createElementVNode("div", { class: "clear" }, null, -1))
    ])
  ]))
}
}

})