import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, unref as _unref } from "vue"

const _hoisted_1 = { class: "xpage-content-page" }
const _hoisted_2 = { class: "xpage-breadcrumbs" }
const _hoisted_3 = { class: "page-content" }
const _hoisted_4 = { class: "table-wrapper" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "buttons-container" }
const _hoisted_8 = ["disabled"]

import { ref, onMounted, computed } from 'vue';
	import { useRouter, useRoute } from 'vue-router';
	import axios from 'axios';
	import Loader from '@/components/Loader.vue';
	import Page from '@/components/layout/Page.vue';
	import functions from '@/use/functions';
	import { Permissions, PermissionLevels } from '@/models/permissions';
	import PermissionsWrapper from '@/components/wrappers/PermissionsWrapper.vue';
	import BatchHeader from './BatchHeader.vue';
	import BatchPayoffRow from './BatchPayoffRow.vue';
	import { BatchDetailsModel, BatchReferenceModel } from '../../models/payoffBatches';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'BatchDetails',
  setup(__props) {

	const route = useRoute();
	const router = useRouter();

	const batch = ref<BatchDetailsModel | null>(null);
	const payoffReferences = ref<BatchReferenceModel[]>([]);

	const loading = ref(false);
	const isProcessing = ref(false);

	const headers = ref([
		{ label: 'In|Out', sortable: false, key: '' },
		{ label: 'Credit Union', sortable: true, key: 'lenderName' },
		{ label: 'Borrower (F/L)', sortable: true, key: 'borrowerName' },
		{ label: 'Scheduled Amount', sortable: true, key: 'disbursementAmountFormatted' },
		{ label: 'Status', sortable: true, key: 'disbursementStatusHumanized' },
		{ label: 'Method', sortable: true, key: 'disbursementMethodHumanized' },
		{ label: 'Reference', sortable: true, key: 'referenceId' },
		{ label: 'Lender Use (LoanId + PayoffId)', sortable: true, key: 'lenderUseOnly' },
		{ label: 'Sequence', sortable: true, key: 'sequenceNumber' }
	]);

	const fetchBatchDetailsAsync = async () => {
		try {
			const response = await axios.get(`/api/payoffbatches/${route.params.batchId}`);

			batch.value = response.data;
			payoffReferences.value = response.data.payoffReferences;
		} catch (error) {
			console.error(error);

			functions.openModal({
				title: 'Oops!',
				description: `Failed to get details for payoff batch ${route.params.batchId}. Please try again later.`
			});
		} finally {
			loading.value = false;
		}
	};

	const approveBatchAsync = async () => {
		if (isProcessing.value) {
			return;
		}

		isProcessing.value = true;
		loading.value = true;

		try {
			await axios.post(`/api/payoffbatches/${route.params.batchId}/approve`);
			await fetchBatchDetailsAsync();
		} catch (error) {
			console.error(error);

			functions.openModal({
				title: 'Oops!',
				description: `Failed to approve payoff batch ${route.params.batchId}. Please try again later.`
			});
		} finally {
			isProcessing.value = false;
			loading.value = false;
		}
	};

	const goBack = () => router.go(-1);

	const sortKey = ref('');
	const sortOrder = ref(1);

	const sortedPayoffReferences = computed(() => {
		if (!sortKey.value) {
			return payoffReferences.value; // no sorting, return original order
		}

		return [...payoffReferences.value].sort((a, b) => {
			let valA = a.payoff ? a.payoff[sortKey.value] : null;
			let valB = b.payoff ? b.payoff[sortKey.value] : null;

			if (typeof valA === 'string') {
				valA = valA.toLowerCase();
			}

			if (typeof valB === 'string') {
				valB = valB.toLowerCase();
			}

			if (valA < valB) {
				return -1 * sortOrder.value;
			} else if (valA > valB) {
				return 1 * sortOrder.value;
			}

			return 0;
		});
	});

	const sortBy = (key: string) => {
		if (sortKey.value === key) {
			if (sortOrder.value === 1) {
				sortOrder.value = -1; // second click, descending
			} else if (sortOrder.value === -1) {
				sortKey.value = ''; // third click, reset sort
				sortOrder.value = 1; // default sort order for the next click
			}
		} else {
			sortKey.value = key;
			sortOrder.value = 1; // first click, ascending
		}
	};

	const getSortArrow = (key: string) => {
		if (sortKey.value === key) {
			return sortOrder.value === 1 ? '▲' : sortOrder.value === -1 ? '▼' : ' ';
		}
		return '';
	};

	const isActiveSort = (key: string) => (sortKey.value === key ? 'active-sort' : '');

	onMounted(async () => {
		loading.value = true;

		try {
			await fetchBatchDetailsAsync();
		} catch (error) {
			console.error(error);
		} finally {
			loading.value = false;
		}
	});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (loading.value)
      ? (_openBlock(), _createBlock(Loader, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(Page, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_router_link, {
              to: "/",
              class: "icon-home"
            }),
            _cache[1] || (_cache[1] = _createElementVNode("span", null, ">", -1)),
            _createVNode(_component_router_link, { to: `/batches` }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("Batches")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(BatchHeader, { batch: batch.value }, null, 8, ["batch"]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("table", null, [
                _createElementVNode("thead", null, [
                  _createElementVNode("tr", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(headers.value, (header) => {
                      return (_openBlock(), _createElementBlock("th", {
                        key: header.key
                      }, [
                        (header.sortable)
                          ? (_openBlock(), _createElementBlock("span", {
                              key: 0,
                              onClick: ($event: any) => (sortBy(header.key)),
                              class: _normalizeClass(isActiveSort(header.key))
                            }, _toDisplayString(header.label) + " " + _toDisplayString(getSortArrow(header.key)), 11, _hoisted_5))
                          : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(header.label), 1))
                      ]))
                    }), 128))
                  ])
                ]),
                _createElementVNode("tbody", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sortedPayoffReferences.value, (payoffReference) => {
                    return (_openBlock(), _createBlock(BatchPayoffRow, {
                      key: payoffReference.payoffId,
                      batchId: batch.value?.id,
                      payoffReference: payoffReference,
                      isBatchApproved: batch.value?.isApproved,
                      isProcessing: isProcessing.value,
                      onBatchUpdateAsync: fetchBatchDetailsAsync
                    }, null, 8, ["batchId", "payoffReference", "isBatchApproved", "isProcessing"]))
                  }), 128))
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(PermissionsWrapper, {
                permissionIds: [_unref(Permissions).DisbursementBatches],
                permissionLevel: _unref(PermissionLevels).Update
              }, {
                default: _withCtx(() => [
                  _createElementVNode("button", {
                    disabled: batch.value?.isApproved || isProcessing.value,
                    onClick: approveBatchAsync
                  }, " Approve ", 8, _hoisted_8)
                ]),
                _: 1
              }, 8, ["permissionIds", "permissionLevel"]),
              _createElementVNode("button", {
                class: "secondary",
                onClick: goBack
              }, "Back")
            ])
          ])
        ]),
        _: 1
      })
    ])
  ], 64))
}
}

})