import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  class: "reissue-section",
  style: {"margin-top":"15px"}
}
const _hoisted_3 = { class: "reissue-info-text" }
const _hoisted_4 = {
  key: 0,
  class: "reissue-section"
}
const _hoisted_5 = { class: "reissue-info-text" }
const _hoisted_6 = {
  for: "checkbox",
  class: "checkbox-label"
}
const _hoisted_7 = {
  key: 1,
  class: "reissue-section"
}
const _hoisted_8 = ["value"]
const _hoisted_9 = {
  key: 2,
  class: "reissue-section reissue-button-container"
}

import { useIsCreditUnionUser } from "@/use/useIsCreditUnionUser";
	import axios from "axios";
	import moment from "moment";
	import { Ref, computed, ref, watch } from "vue";
	import { Permissions, PermissionLevels } from "@/models/permissions";
	import PermissionsWrapper from "../wrappers/PermissionsWrapper.vue";
	import OpalButton from "@/components/shared/OpalButton.vue";

	interface Props {
		application: any;
		applicant: any;
		applicants: any;
		applicantFullName: string;
		creditId: any;
		knockoutCode: any;
		store: any;
		canEdit: boolean;
	}

	
export default /*@__PURE__*/_defineComponent({
  __name: 'CreditReissue',
  props: {
    application: {},
    applicant: {},
    applicants: {},
    applicantFullName: {},
    creditId: {},
    knockoutCode: {},
    store: {},
    canEdit: { type: Boolean }
  },
  setup(__props: any) {

	const props = __props;
	const creditKnockouts = ref<any[]>([]);
	const creditRepullTypes = ref<any[]>([]);
	const step = ref(1);
	const checkboxChecked = ref(false);
	const selectedReason = ref("");
	const showChevron = ref(true);
	const hasKnockoutCode = ref(false);
	const loading = ref<boolean>(false);
	let isButtonClicked = ref(false);

	const cancelReissue = () => {
		showChevron.value = true;
		step.value = 1;
		checkboxChecked.value = false;
		selectedReason.value = "";
	};

	const handleCheckboxChange = () => {
		if (checkboxChecked.value) {
			step.value = 3;
		} else {
			step.value = 2;
		}
	};

	const nextStep = () => {
		showChevron.value = false;
		if (step.value === 1 || (step.value === 3 && selectedReason.value)) {
			step.value++;
		}
	};

	const repullCredit = async () => {
		isButtonClicked.value = true;
		try {
			loading.value = true;
			const internalUserId = props?.store.state.oidcStore.user.sub;
			const applicantUserId = props.applicants[props.applicant].userId;
			const selectedReasonObject = creditRepullTypes.value.find(
				(type) => type.id === selectedReason.value
			);
			console.log("Internal User Id:", internalUserId);
			console.log("Applicant User Id:", applicantUserId);
			console.log("Selected Reason:", selectedReasonObject);

			const payload = {
				creditId: props.creditId,
				creditRepullReasonId: selectedReason.value,
				internalUserId: internalUserId,
				applicantUserId: applicantUserId,
			};
			const response = await axios.post(
				`/api/applications/${props.application.data.id}/creditrepullconsents/`,
				payload
			);
			console.log("Repull requested:", response);

			window.location.reload();
		} catch (error) {
			console.log(error);
			loading.value = false;
		}
	};

	const isCreditUnionUser = useIsCreditUnionUser();

	const reissueEndDate = computed(() => {
		return moment(props.application.data.submittedAtUtc).add(90, "days");
	});

	const reissueEndDateFormatted = computed(() => {
		return reissueEndDate.value.format("MM/DD/YYYY");
	});

	const showButton = computed(() => {
		return (
			isCreditReissue() &&
			isDateParamAcceptable() &&
			hasKnockoutCode.value &&
			isCreditUnionUser
		);
	});

	const findMostRecentCreditId = () => {
		const applicantId = props?.applicants?.[props.applicant]?.id;

		if (!applicantId) {
			return props.creditId;
		}

		const creditData = props?.application?.creditData?.[0];
		if (!creditData) {
			return props.creditId;
		}

		const applicantCredit = creditData.applicantCredits?.find(
			(ac) => ac.applicantId === applicantId
		);

		return applicantCredit?.creditId ?? props.creditId;
	};

	//The creditId needs to be aware of the future pulls
	const initializeCreditReissue = async () => {
		try {
			const creditId = findMostRecentCreditId();

			const knockoutResponse = await axios.get(
				`/api/credits/knockouts/${creditId}`
			);
			creditKnockouts.value = knockoutResponse.data;
		} catch (error) {
			console.log(error);
		}
		try {
			const repullTypesResponse = await axios.get(
				`/api/applications/creditrepullreasons`
			);
			creditRepullTypes.value = repullTypesResponse.data.map((type) => ({
				id: type.id,
				name: type.name,
			}));
		} catch (error) {
			console.log(error);
		}
		checkForKOCodes(hasKnockoutCode);
	};

	function isCreditReissue() {
		return props.application?.data.applicationStatusId === 2;
	}

	function checkForKOCodes(hasKnockoutCode: Ref<boolean>) {
		const codesToCheck = [
			"CBE",
			"SupFile",
			"Nhit",
			"ConStmt",
			"SecAlert",
			"SecFreeze",
			"ADDMM",
			"SSNMM",
			"SSN12",
			"ZIPMM",
		];
		const knockoutCodes = creditKnockouts.value.map(
			(knockout) => knockout.knockoutCode
		);
		hasKnockoutCode.value = knockoutCodes.some((code) =>
			codesToCheck.includes(code)
		);
	}

	function isDateParamAcceptable() {
		const submittedDate = moment(props.application.data.submittedAtUtc);
		const today = moment();
		return today.diff(submittedDate, "days") <= 90;
	}

	watch(selectedReason, (newValue) => {
		if (newValue) {
			step.value = 4;
		}
	});

	watch(
		[() => props.applicantFullName, () => props.creditId],
		async ([newValue, newCreditId], [oldValue, oldCreditId]) => {
			if (newValue !== oldValue || newCreditId !== oldCreditId) {
				// Leaving this for now for debugging purposes
				// console.log(`Applicant Name or CreditId changed: ${newValue} ${newCreditId}`);
				await initializeCreditReissue();
			}
		},
		{ immediate: true }
	);

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    (showButton.value)
      ? (_openBlock(), _createBlock(PermissionsWrapper, {
          key: 0,
          disabled: !_ctx.canEdit,
          showDisabledIfNotVisible: true,
          permissionIds: [_unref(Permissions).Credit],
          permissionLevel: _unref(PermissionLevels).Update
        }, {
          default: _withCtx(() => [
            (showButton.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("span", _hoisted_3, [
                      _createTextVNode(" Current applicant " + _toDisplayString(props.applicantFullName) + " is eligible for a credit re-pull until " + _toDisplayString(reissueEndDateFormatted.value) + ". ", 1),
                      (showChevron.value)
                        ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                            key: 0,
                            class: "chevron-right-icon",
                            icon: ['fas', 'circle-chevron-right'],
                            onClick: nextStep
                          }))
                        : (_openBlock(), _createBlock(_component_font_awesome_icon, {
                            key: 1,
                            title: "Cancel Re-pull",
                            class: "circle-xmark-icon",
                            icon: ['fas', 'circle-xmark'],
                            onClick: cancelReissue
                          }))
                    ])
                  ]),
                  (step.value === 2)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createElementVNode("span", _hoisted_5, [
                          _cache[2] || (_cache[2] = _createTextVNode(" I have obtained consent from the applicant to re-pull their credit. ")),
                          _withDirectives(_createElementVNode("input", {
                            id: "checkbox",
                            type: "checkbox",
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((checkboxChecked).value = $event)),
                            onChange: handleCheckboxChange,
                            class: "custom-checkbox"
                          }, null, 544), [
                            [_vModelCheckbox, checkboxChecked.value]
                          ]),
                          _createElementVNode("label", _hoisted_6, [
                            _createVNode(_component_font_awesome_icon, {
                              icon: 
									checkboxChecked.value ? 'fas fa-square-check' : 'far fa-square'
								
                            }, null, 8, ["icon"])
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (step.value === 3 && checkboxChecked.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _withDirectives(_createElementVNode("select", {
                          class: "reissue-info-text",
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((selectedReason).value = $event))
                        }, [
                          _cache[3] || (_cache[3] = _createElementVNode("option", {
                            disabled: "",
                            value: ""
                          }, "Please select a reason", -1)),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(creditRepullTypes.value, (type) => {
                            return (_openBlock(), _createElementBlock("option", {
                              key: type.id,
                              value: type.id
                            }, _toDisplayString(type.name), 9, _hoisted_8))
                          }), 128))
                        ], 512), [
                          [_vModelSelect, selectedReason.value]
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (step.value === 4 && selectedReason.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createVNode(OpalButton, {
                          class: "submit",
                          disabled: _unref(isButtonClicked),
                          type: "submit",
                          loading: loading.value,
                          text: "Re-pull Credit",
                          onClick: repullCredit
                        }, null, 8, ["disabled", "loading"])
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["disabled", "permissionIds", "permissionLevel"]))
      : _createCommentVNode("", true)
  ]))
}
}

})