import { defineComponent as _defineComponent } from 'vue'
import { setBlockTracking as _setBlockTracking, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "xusericon",
  "data-size": "small"
}
const _hoisted_2 = { class: "testbox-outer" }

import { Applicant } from "@/models/opal";

interface Props {
  selected: boolean;
  applicant: Applicant;
  applicantType: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'NameButton',
  props: {
    selected: { type: Boolean },
    applicant: {},
    applicantType: {}
  },
  emits: ["nameBtnClicked", "addAuthorizedThirdPartyClicked"],
  setup(__props: any, { emit: __emit }) {

const emit = __emit;

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_ctx.applicant?.id > 0)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["xnamebutton", { xselected: _ctx.selected }]),
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('nameBtnClicked')))
      }, [
        _createElementVNode("h4", null, [
          _createElementVNode("div", _hoisted_1, [
            _cache[0] || (
              _setBlockTracking(-1, true),
              (_cache[0] = _createElementVNode("div", { class: "xusericon-picture" }, [
                _createTextVNode(_toDisplayString(_ctx.applicant.initials), 1)
              ])).cacheIndex = 0,
              _setBlockTracking(1),
              _cache[0]
            ),
            _cache[4] || (_cache[4] = _createElementVNode("div", {
              class: "xusericon-gender",
              "data-gender": ""
            }, null, -1))
          ]),
          _createElementVNode("div", _hoisted_2, [
            _cache[1] || (
              _setBlockTracking(-1, true),
              (_cache[1] = _createElementVNode("div", { class: "testbox-name" }, [
                _createTextVNode(_toDisplayString(_ctx.applicant.fullName), 1)
              ])).cacheIndex = 1,
              _setBlockTracking(1),
              _cache[1]
            ),
            _cache[2] || (
              _setBlockTracking(-1, true),
              (_cache[2] = _createElementVNode("div", { class: "testbox-type" }, [
                _createTextVNode(_toDisplayString(_ctx.applicant.applicantTypeName), 1)
              ])).cacheIndex = 2,
              _setBlockTracking(1),
              _cache[2]
            )
          ])
        ])
      ], 2))
    : _createCommentVNode("", true)
}
}

})