import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "stipulations",
  id: "table_detail"
}
const _hoisted_2 = ["id", "value", "onClick"]
const _hoisted_3 = {
  key: 1,
  class: "text-edit"
}
const _hoisted_4 = ["onUpdate:modelValue"]
const _hoisted_5 = ["id"]

import { reactive, computed, ref, onMounted, watch } from "vue";
  import {
    Applicant,
    LOSApplication,
    Stipulation,
    LinkDocumentToStipulationRequest,
  } from "@/models/opal";
  import useFormatter from "@/use/formatter";
  import StipContextMenu from "@/components/stipulations/StipContextMenu.vue";
  import StipCancellationForm from "@/components/stipulations/StipCancellationForm.vue";
  import StipulationForm from "@/components/stipulations/StipulationForm.vue";
  import StipulationDetails from "@/components/stipulations/StipulationDetails.vue";
  import PdfViewer from "@/components/PdfViewer.vue";
  import { useStore } from "vuex";
  import { $api } from "@/services/api1";
  import functions from "@/use/functions";
  import useStipulations from "@/use/stipulations";

  interface Props {
    stipulations: Array<Stipulation>;
    application: LOSApplication;
    status: string;
    items: Array<any>;
  }
  
export default /*@__PURE__*/_defineComponent({
  __name: 'StipulationsTable',
  props: {
    stipulations: {},
    application: {},
    status: {},
    items: {}
  },
  emits: [
    "editBtnClicked",
    "getApplicationStips",
    "updateStipulationsData",
    "stipUpdated",
    "stipsUpdated",
    "getApplicationComments",
  ],
  setup(__props: any, { emit: __emit }) {

  const store = useStore();
  const emit = __emit;

  const props = __props;
  const applicants = computed(() => props?.application?.applicants);
  const { formatDateTime } = useFormatter();
  const { replaceObjectWithId } = useStipulations();
  const stipTableWrapper = ref(null);
  const active = ref<boolean>(false);
  let filteredStips = reactive([] as any[]);
  let stipulationsInfo = reactive({
    sortedStips: [] as any[],
  } as any);
  const applicationId = computed(() => props.application.data.id);

  const stipulationsData = computed(() =>
    props.items?.map((stip: any) => {
      return {
        ...stip,
        fullName: getApplicant(stip.applicantId)?.fullName,
        created: formatDateTime(stip.createdAt),
        lastChanged: formatDateTime(stip.lastChangedAt),
        showDetails: false,
        showDocument: false,
        editStipText: false,
        showCancellationForm: false,
      };
    })
  );

  const showContextMenu = computed(() => {
    return (
      props.application.data.applicationStatusName?.trim().toLowerCase() !==
        "submitted & processing" &&
      props.application.data.applicationStatusName?.trim().toLowerCase() ===
        "evaluation"
    );
  });

  const sortedStips = computed(() => {
    filteredStips = stipulationsData.value;
    return filteredStips;
  });

  const headers = [
    { label: "Stip Type", name: "stipType", width: 15 },
    { label: "Requested For", name: "requestedFor", width: 25 },
    { label: "Created At", name: "createdAt", width: 15 },
    { label: "Last Changed At", name: "lastChangedAt", width: 15 },
    { label: "Stip Text", name: "stipText", width: 25 },
  ];

  function getApplicant(applicantId: number) {
    return props?.application?.applicants.find(
      (a: Applicant) => a.id === applicantId
    );
  }
  function handleLeftClick(stip: any) {
    if (showContextMenu.value) {
      return;
    } else {
      if (
        stip.documentId &&
        stip.documentId > 0 &&
        stip.applicantDocumentId &&
        stip.applicantDocumentId > 0
      ) {
        stip.showDocument = !stip.showDocument;
      } else {
        stip.showDetails = !stip.showDetails;
      }
    }
  }
  function toggleEdit(stip: any) {
    console.log("toggleEdit emitted");
    stip.showModal = true;
  }
  function toggleStipText(stip: any) {
    stip.editStipText = !stip.editStipText;
  }

  function saveStipText(stip: any) {
    stip.editStipText = false;
  }

  function toggleCancellationForm(stip: any, status: any) {
    stip.originalStip = Object.assign({}, stip);
    stip.stipulationStatusId = status.id;
    stip.stipulationStatusName = status.name;
    stip.showCancellationForm = true;
  }
  function toggleDetails(stip: any) {
    stip.showDetails = !stip.showDetails;
  }
  function toggleDocument(stip: any) {
    stip.showDocument = !stip.showDocument;
  }
  function closeStipCancelForm(stip: any) {
    stip.showCancellationForm = false;
  }

  function stipulationStatusReasonId(statusName: string) {
    let statusReason = "Open";
    switch (statusName) {
      case "Open":
        statusReason = "Open";
        break;
      case "Completed":
        statusReason = "Fulfilled";
        break;
      case "CU-Override":
        statusReason = "Override";
        break;
    }
    const statusReasonUpdate = store.state.types[
      "StipulationStatusReasons"
    ]?.results?.find((s: any) => s.name.trim() === statusReason);
    return statusReasonUpdate.id;
  }

  async function updateStipulationStatus(status: any, stip: any) {
    let updateStipRequest = {
      stipulationStatusId: status.id,
      stipulationStatusReasonId: stipulationStatusReasonId(status.name),
      stipulationTextOverride: null,
      revertToDefaultText: false,
      updatedByUserId: store.state.oidcStore.user.sub,
    };

    // If cancelled or cu-override prompt for additional info
    if (status.name === "Cancelled" || status.name === "CU-Override") {
      toggleCancellationForm(stip, status);
    } else {
      let response = {} as any;
      // applicant level
      if (stip.applicantId && stip.applicantId > 0) {
        response = await $api.applications.updateApplicantStipulation(
          applicationId.value,
          stip.applicantId,
          stip.id,
          updateStipRequest
        );
      } else {
        response = await $api.applications.updateApplicationStipulation(
          applicationId.value,
          stip.id,
          updateStipRequest
        );
      }

      const original = props.application.stipulationsData.find(
        (s: Stipulation) => s.id === stip.id
      );

      if (response.id) {
        // re-sort
        emit("getApplicationStips");
      }
      // handle error
      else {
        functions.openModal({
          title: "Error",
          description: "There was an issue updating the stip status",
        });
        stip.stipulationStatusId = original.stipulationStatusId;
        stip.stipulationStatusName = original.stipulationStatusName;
      }
    }
  }

  async function updateStipulationText(stip: any) {
    stip.editStipText = !stip.editStipText;

    let updateStipRequest = {
      stipulationStatusId: stip.stipulationStatusId,
      stipulationStatusReasonId: stip.stipulationStatusReasonId,
      stipulationTextOverride: stip.stipulationText,
      revertToDefaultText: false,
      updatedByUserId: store.state.oidcStore.user.sub,
      requestedFor: stip.fullName || null,
    };

    let response = {} as any;
    // applicant level
    if (stip.applicantId && stip.applicantId > 0) {
      response = await $api.applications.updateApplicantStipulation(
        applicationId.value,
        stip.applicantId,
        stip.id,
        updateStipRequest
      );
    } else {
      response = await $api.applications.updateApplicationStipulation(
        applicationId.value,
        stip.id,
        updateStipRequest
      );
    }
    const original = props.application.stipulationsData.find(
      (s: Stipulation) => s.id === stip.id
    );

    if (response.id) {
      stip.stipulationText = response.stipulationText;
      emit("getApplicationComments");
    } else {
      functions.openModal({
        title: "Error",
        description: "There was an issue updating the stip text",
      });

      stip.stipulationText = original.stipulationText;
    }
  }

  async function linkDocument(e: any) {
    const request = {
      applicantDocumentId: e.applicantDocumentId,
      documentName: e.documentName,
      requestedFor: e.requestedFor,
      changeStatusCurrentlyLinkedApprovedPendingDocument:
        e.changeStatusCurrentlyLinkedApprovedPendingDocument,
      changeStatusCurrentlyLinkedApprovedPendingDocumentTo:
        e.changeStatusCurrentlyLinkedApprovedPendingDocumentTo,
    } as LinkDocumentToStipulationRequest;

    const response = await $api.applications.linkDocumentToStipulation(
      applicationId.value,
      e.stipulationId,
      request
    );

    if (
      response.id &&
      response.documentId !== null &&
      response.applicantDocumentId !== null
    ) {
      emit("getApplicationStips");
      emit("getApplicationComments");
      // until i can get bug fixed on this hot-swap
      // stipUpdated(response);
    }

    if (response.errors) {
      functions.openModal({
        title: "Error",
        description: "There was an issue linking the document",
      });
    }
  }

  async function unlinkDocument(e: any) {
    const response = await $api.applications.unlinkDocumentFromStipulation(
      applicationId.value,
      e.stipulationId,
      e.applicantDocumentId
    );
    const original = stipulationsData.value.find(
      (s: Stipulation) => s.id === e.stipulationId
    );

    // handle happy path
    if (
      response.id &&
      response.documentId === null &&
      response.applicantDocumentId === null
    ) {
      original.documentId = response.documentId;
      original.applicantDocumentId = response.applicantDocumentId;
      const updated = {
        ...response,
        fullName: getApplicant(response.applicantId)?.fullName || null,
        created: formatDateTime(response.createdAt),
        lastChanged: formatDateTime(response.lastChangedAt),
        showDetails: false,
        showDocument: false,
        editStipText: false,
        showCancellationForm: false,
      };

      emit("getApplicationStips");
      emit("getApplicationComments");
      // until i can get bug fixed on this hot-swap
      // replaceObjectWithId(stipulationsData.value,response.id, updated);
      // stipUpdated(response);
    }
    if (response.errors) {
      functions.openModal({
        title: "Error",
        description: "There was an issue unlinking the document",
      });
    }
  }

  // Helper fxns
  function stipAdded(e: any) {
    const newStip = {
      ...e,
      fullName: getApplicant(e.applicantId)?.fullName || null,
      created: formatDateTime(e.createdAt),
      lastChanged: formatDateTime(e.lastChangedAt),
      showDetails: false,
      showDocument: false,
      editStipText: false,
      showCancellationForm: false,
    };
    // check if record exists already

    stipulationsData.value.push(newStip);
  }

  function stipUpdated(newStip: any) {
    emit("stipUpdated", newStip);
  }

  function stipsUpdated(updatedStipsData: any) {
    emit("stipsUpdated", updatedStipsData);
  }
  function statusReasonChanged(e: any) {
    console.log("statusReasonChanged", e);
    stipUpdated(e);
    closeStipCancelForm(e);
  }

  onMounted(() => {
    stipulationsInfo.sortedStips = sortedStips.value;
  });
  watch(
    () => sortedStips.value,
    () => {
      stipulationsInfo.sortedStips = sortedStips.value;
    },
    { deep: true }
  );

return (_ctx: any,_cache: any) => {
  return (_unref(stipulationsInfo).sortedStips?.length > 0)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "stipulation-table-wrapper",
        ref_key: "stipTableWrapper",
        ref: stipTableWrapper
      }, [
        _createElementVNode("table", _hoisted_1, [
          _createElementVNode("tr", null, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(headers, (header, index) => {
              return _createElementVNode("th", {
                key: index,
                class: _normalizeClass(`col col-${header.width}`)
              }, _toDisplayString(header.label), 3)
            }), 64))
          ]),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(stipulationsInfo).sortedStips, (stip) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: stip.id
              }, [
                _createElementVNode("tr", {
                  class: _normalizeClass(["stip-row", stip.showDetails ? 'expanded' : 'collapsed']),
                  id: `${stip.stipulationTypeName.replace(' ', '_').trim()}_${stip.id}`,
                  value: stip.id,
                  onClick: _withModifiers(($event: any) => (handleLeftClick(stip)), ["prevent"])
                }, [
                  _createVNode(StipContextMenu, {
                    name: `${stip.stipulationTypeName.replace(' ', '_').trim()}_${stip.id}`,
                    stipulation: stip,
                    application: _ctx.application,
                    onToggleStipText: ($event: any) => (toggleStipText(stip)),
                    onAddStipulation: _cache[0] || (_cache[0] = ($event: any) => (stipAdded($event))),
                    onUpdateStipulationStatus: ($event: any) => (updateStipulationStatus($event, stip)),
                    onUpdateStipulationText: ($event: any) => (updateStipulationText(stip)),
                    onToggleEdit: ($event: any) => (toggleEdit(stip)),
                    onToggleDetails: ($event: any) => (toggleDetails(stip)),
                    onToggleCancellationForm: ($event: any) => (toggleCancellationForm(stip, $event)),
                    onGetApplicationStips: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('getApplicationStips'))),
                    onLinkDocument: linkDocument,
                    onUnlinkDocument: unlinkDocument,
                    onToggleDocument: ($event: any) => (toggleDocument(stip))
                  }, null, 8, ["name", "stipulation", "application", "onToggleStipText", "onUpdateStipulationStatus", "onUpdateStipulationText", "onToggleEdit", "onToggleDetails", "onToggleCancellationForm", "onToggleDocument"]),
                  _createElementVNode("td", null, _toDisplayString(stip.stipulationTypeDescription), 1),
                  _createElementVNode("td", null, _toDisplayString(stip.applicantId ? stip.fullName : "Application"), 1),
                  _createElementVNode("td", null, _toDisplayString(stip.created), 1),
                  _createElementVNode("td", null, _toDisplayString(stip.lastChanged), 1),
                  _createElementVNode("td", null, [
                    (stip.editStipText === false)
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          class: _normalizeClass(stip.isEditing === false ? 'disabled' : '')
                        }, _toDisplayString(stip.stipulationText), 3))
                      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                          _withDirectives(_createElementVNode("textarea", {
                            "onUpdate:modelValue": ($event: any) => ((stip.stipulationText) = $event),
                            style: {"width":"100%","border":"1px solid #dddddd","height":"75px"}
                          }, null, 8, _hoisted_4), [
                            [_vModelText, stip.stipulationText]
                          ])
                        ]))
                  ])
                ], 10, _hoisted_2),
                _createElementVNode("tr", {
                  class: "stip-details",
                  id: `${stip.stipulationTypeName.trim()}_${stip.id}`
                }, [
                  _createElementVNode("td", {
                    colspan: "5",
                    class: _normalizeClass(stip.showDetails ? 'expanded' : 'collapsed')
                  }, [
                    (
                    stip.isExpectedDocument &&
                    stip.documentId &&
                    stip.showDocument
                  )
                      ? (_openBlock(), _createBlock(PdfViewer, {
                          key: 0,
                          docId: `${stip.documentId}`,
                          id: `${stip.stipulationTypeName.trim()}_${stip.id}`,
                          stipulationId: stip.id
                        }, null, 8, ["docId", "id", "stipulationId"]))
                      : _createCommentVNode("", true),
                    (stip.showDetails)
                      ? (_openBlock(), _createBlock(StipulationDetails, {
                          key: 1,
                          stipulation: stip,
                          application: _ctx.application
                        }, null, 8, ["stipulation", "application"]))
                      : _createCommentVNode("", true)
                  ], 2)
                ], 8, _hoisted_5),
                (stip.showCancellationForm)
                  ? (_openBlock(), _createBlock(StipCancellationForm, {
                      key: 0,
                      stipulation: stip,
                      application: _ctx.application,
                      status: stip.stipulationStatusName,
                      onClose: ($event: any) => (closeStipCancelForm(stip)),
                      onCloseCancellationForm: ($event: any) => (closeStipCancelForm(stip)),
                      onStatusReasonChanged: _cache[2] || (_cache[2] = ($event: any) => (statusReasonChanged($event))),
                      onGetApplicationStips: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('getApplicationStips'))),
                      onStipUpdated: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('stipUpdated', $event))),
                      onGetApplicationComments: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('getApplicationComments')))
                    }, null, 8, ["stipulation", "application", "status", "onClose", "onCloseCancellationForm"]))
                  : _createCommentVNode("", true),
                (stip.showModal)
                  ? (_openBlock(), _createBlock(StipulationForm, {
                      key: 1,
                      stipulations: stipulationsData.value,
                      stipulation: stip,
                      application: _ctx.application,
                      onCloseStipModal: ($event: any) => (stip.showModal = false)
                    }, null, 8, ["stipulations", "stipulation", "application", "onCloseStipModal"]))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128))
          ])
        ])
      ], 512))
    : _createCommentVNode("", true)
}
}

})