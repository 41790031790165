import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "esign-details" }
const _hoisted_2 = {
  key: 0,
  class: "xpage-history"
}
const _hoisted_3 = { class: "column-item name center-text no-wrap" }
const _hoisted_4 = { class: "column-item name center-text" }
const _hoisted_5 = { class: "column-item name center-text" }
const _hoisted_6 = { class: "column-item name center-text" }
const _hoisted_7 = { class: "column-item name center-text" }
const _hoisted_8 = { class: "column-item name center-text" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "column-item name center-text" }
const _hoisted_11 = { key: 1 }

import {
    computed,
    reactive,
    nextTick,
  } from "vue";
  import { $api } from "@/services/api1";
  import moment from "moment-timezone";
  import useFileUtilities from "@/use/fileUtilities";
  import { Permissions, PermissionLevels } from "@/models/permissions";
  import PermissionsWrapper from "@/components/wrappers/PermissionsWrapper.vue";

  interface Props {
    application: any;
    canEdit: boolean;
  }
  
export default /*@__PURE__*/_defineComponent({
  __name: 'EsignEnvelopes',
  props: {
    application: {},
    canEdit: { type: Boolean }
  },
  emits: ["setEsignData"],
  setup(__props: any, { emit: __emit }) {

  const props = __props;
  const emit = __emit;
  const linkRefs = reactive({} as any);
  const { base64ToBlob } = useFileUtilities();

  const esignData = computed(() => props.application.esignData);
  const headers = [
    { name: "envelopeId", label: "Envelope ID" },
    { name: "envelopeStatus", label: "Envelope Status" },
    {
      name: "primaryApplicantSigningStatus",
      label: "Primary Signing Status",
    },
    { name: "coApplicantSigningStatus", label: "Co-Applicant Signing Status" },
    { name: "generatedDocument", label: "Generated Document" },
    { name: "downloadDocument", label: "Download Document" },
  ];

  function formatDate(dateStr: string) {
    try {
      if (dateStr) {
        return moment(`${dateStr}Z`).tz(moment.tz.guess()).format("MMMMDDYYY");
      }
    } catch (ex) {
      console.log("Could not format date time");
      return dateStr;
    }
  }

  async function getEsignStatus() {
    const response = await $api.esign.getApplicationEsignStatus(
      props.application?.data?.id
    );
    if (response.length > 0) {
      response.forEach((e: any) => {
        e.coRecipientStatus =
          e.coRecipientStatus === "Null" ? null : e.coRecipientStatus;
      });
      emit("setEsignData", response);
    }
    // console.log("getEsignData() response", response);
  }

  async function download(document: any) {
    nextTick(async () => {
      let refId = `download_${document.documentId}`;
      let link = linkRefs[refId] as HTMLAnchorElement;

      let b64 = await $api.documents.getDocument(document.documentId);
      const blob = base64ToBlob(b64);
      if (blob.size > 0) {
        const blobURL = URL.createObjectURL(blob);
        let a = link as HTMLAnchorElement;

        a.setAttribute(
          "download",
          `${document?.documentType}_${props.application.data.referenceId}`
        );
        a.setAttribute("href", `${blobURL}`);
        a.click();
        URL.revokeObjectURL(blobURL);
      } else {
        console.log("FAILED TO DOWNLOAD");
      }
    });
  }

  getEsignStatus();

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createBlock(PermissionsWrapper, {
    disabled: !_ctx.canEdit,
    showDisabledIfNotVisible: true,
    permissionIds: [_unref(Permissions).Esign],
    permissionLevel: _unref(PermissionLevels).Update
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (esignData.value?.length > 0)
          ? (_openBlock(), _createElementBlock("table", _hoisted_2, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(headers, (header) => {
                return _createElementVNode("th", {
                  class: "column-title",
                  key: header.name
                }, [
                  _createElementVNode("span", null, _toDisplayString(header.label), 1)
                ])
              }), 64)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(esignData.value, (record) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: record.envelopeId,
                  class: "xclickable"
                }, [
                  _createElementVNode("td", _hoisted_3, _toDisplayString(record.envelopeId), 1),
                  _createElementVNode("td", _hoisted_4, _toDisplayString(record.envelopeStatus), 1),
                  _createElementVNode("td", _hoisted_5, _toDisplayString(record.primaryRecipientStatus), 1),
                  _createElementVNode("td", _hoisted_6, _toDisplayString(record.coRecipientStatus || "N/A"), 1),
                  _createElementVNode("td", _hoisted_7, _toDisplayString(record.fileName), 1),
                  _createElementVNode("td", _hoisted_8, [
                    _createTextVNode(_toDisplayString(record.downloadDocument) + " ", 1),
                    _createElementVNode("a", {
                      class: "download",
                      onClick: ($event: any) => (download(record))
                    }, [
                      _createVNode(_component_font_awesome_icon, {
                        class: "download-icon",
                        icon: ['fas', 'cloud-arrow-down']
                      }),
                      _cache[0] || (_cache[0] = _createTextVNode(" "))
                    ], 8, _hoisted_9),
                    _createElementVNode("a", {
                      ref_for: true,
                      ref: 
                (el) => {
                  linkRefs[`download_${record.documentId}`] = el;
                }
              
                    }, null, 512)
                  ]),
                  _createElementVNode("td", _hoisted_10, [
                    _createElementVNode("span", null, [
                      _createTextVNode(_toDisplayString(record.generatedDocument) + " ", 1),
                      (record.documentType === 'Signed Legal Packet')
                        ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                            key: 0,
                            class: "download-icon",
                            icon: ['fas', 'cloud-arrow-down']
                          }))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]))
              }), 128))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_11, _cache[1] || (_cache[1] = [
              _createElementVNode("p", null, "No eSign documents available", -1)
            ])))
      ])
    ]),
    _: 1
  }, 8, ["disabled", "permissionIds", "permissionLevel"]))
}
}

})