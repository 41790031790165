import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "xpage-tabs" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["data-status"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["data-status"]

import { Permissions, PermissionLevels } from "@/models/permissions";
import PermissionsWrapper from "@/components/wrappers/PermissionsWrapper.vue";
import { storeToRefs } from "pinia";
import { useApplicationStore } from "@/store/store/applicationVMStore";


export default /*@__PURE__*/_defineComponent({
  __name: 'TabsGroup',
  emits: ["openTab"],
  setup(__props, { emit: __emit }) {

const store = useApplicationStore();
const { viewModel } = storeToRefs(store);
const emit = __emit;

const changeTab = (identifier: string) => {
  store.updateActiveTab(identifier);
  emit("openTab", identifier);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(viewModel)?.tabs, (tab, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
        (tab.permissionId)
          ? (_openBlock(), _createBlock(PermissionsWrapper, {
              key: 0,
              permissionIds: [_unref(Permissions)[tab.permissionId]],
              permissionLevel: tab.permissionLevel
            }, {
              default: _withCtx(() => [
                _createElementVNode("button", {
                  onClick: ($event: any) => (changeTab(tab.identifier)),
                  class: _normalizeClass({ xselected: tab.isTabActive })
                }, [
                  (tab.tabStatus)
                    ? (_openBlock(), _createElementBlock("i", {
                        key: 0,
                        class: "icon-ok-circle",
                        "data-status": tab.tabStatus
                      }, null, 8, _hoisted_3))
                    : _createCommentVNode("", true),
                  _createTextVNode(" " + _toDisplayString(tab.name), 1)
                ], 10, _hoisted_2)
              ]),
              _: 2
            }, 1032, ["permissionIds", "permissionLevel"]))
          : (_openBlock(), _createBlock(PermissionsWrapper, {
              key: 1,
              permissionLevel: tab.permissionLevel
            }, {
              default: _withCtx(() => [
                _createElementVNode("button", {
                  onClick: ($event: any) => (changeTab(tab.identifier)),
                  class: _normalizeClass({ xselected: tab.isTabActive })
                }, [
                  (tab.tabStatus)
                    ? (_openBlock(), _createElementBlock("i", {
                        key: 0,
                        class: "icon-ok-circle",
                        "data-status": tab.tabStatus
                      }, null, 8, _hoisted_5))
                    : _createCommentVNode("", true),
                  _createTextVNode(" " + _toDisplayString(tab.name), 1)
                ], 10, _hoisted_4)
              ]),
              _: 2
            }, 1032, ["permissionLevel"]))
      ], 64))
    }), 128))
  ]))
}
}

})