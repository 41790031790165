import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, toDisplayString as _toDisplayString, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "xfields inline" }
const _hoisted_4 = { class: "xfield" }
const _hoisted_5 = { class: "xfield" }
const _hoisted_6 = { class: "xform-section-loc" }
const _hoisted_7 = { class: "checkbox-item" }
const _hoisted_8 = ["value", "disabled", "onChange"]
const _hoisted_9 = { for: "application-override" }
const _hoisted_10 = { class: "credit-union-decision-text" }
const _hoisted_11 = ["disabled"]
const _hoisted_12 = { class: "remaining-textbox" }
const _hoisted_13 = { class: "checkbox-container-acknowledge" }
const _hoisted_14 = { class: "xfields" }
const _hoisted_15 = { class: "checkfield" }
const _hoisted_16 = ["disabled"]
const _hoisted_17 = { class: "xfield" }
const _hoisted_18 = { class: "button-container" }
const _hoisted_19 = ["disabled"]
const _hoisted_20 = ["disabled"]

import {ref, computed, onMounted, watch } from "vue";
  import useFormatter from "@/use/formatter";
  import { OverrideVMOptions, Override, OverrideTypes,AdditionalOverride } from "@/types/override";
  import Loader from "@/components/Loader.vue";
  import axios from "axios";
  import functions from "@/use/functions";
  import { Permissions, PermissionLevels } from "@/models/permissions";
  import PermissionsWrapper from "@/components/wrappers/PermissionsWrapper.vue";
  import { useApplicationStore} from "@/store/store/applicationVMStore";

  interface Props {
    application: any;
    applicants: any;
	canEdit: boolean;
  }
  
export default /*@__PURE__*/_defineComponent({
  __name: 'OverridesAdditionalLOC',
  props: {
    application: {},
    applicants: {},
    canEdit: { type: Boolean }
  },
  setup(__props: any) {

  const props = __props;

  const applicationStore = useApplicationStore();

  const freeFormReason = ref('');

  const isAcknowledged = ref<boolean>(false);

  const overrideLOC = ref<Override>({
    reviewBy: "",
    reviewDateTime: "",
    overrideTypeId: OverrideTypes.LOCLimitMod,
    freeFormReason: "",
    isReviewed: false,
    isCustomPricing: false,
  });


  const overrideAdditional = ref<Override>({
    reviewBy: "",
    reviewDateTime: "",
    overrideTypeId: 0,
    freeFormReason: "",
    isReviewed: false,
    isCustomPricing: false,
  });

  const selectedAdditionalOptions = ref<Array<AdditionalOverride>>([]);

  const submitButtonDisabled = ref<boolean>(true);

  const handleCheckboxChange = (option: any, event: Event) => {
  const inputElement = event.target as HTMLInputElement;  
  const isChecked = inputElement.checked;

  if (isChecked) {
    const newOverride: AdditionalOverride = {
      overrideTypeId: option.overrideTypeId,
      overrideReasonId: option.overrideReasonId,
      isReviewed: false,
      freeFormReason: freeFormReason.value,
    };
    const existingOverrideIndex = selectedAdditionalOptions.value.findIndex(o => o.overrideTypeId === option.overrideTypeId);
    if (existingOverrideIndex === -1) {
      selectedAdditionalOptions.value.push(newOverride);
    }
  } else {
    selectedAdditionalOptions.value = selectedAdditionalOptions.value.filter(o => o.overrideTypeId !== option.overrideTypeId);
  }

  updateSubmitButtonState();
};


  const isSubmitted = ref(false);
  const overrideOptions = ref<OverrideVMOptions[]>([]);


  function reloadWindow() {
    window.location.reload();
  }

  const addPricingException = ref<boolean>(true);
  const showPricingErrorMsg = ref<boolean>(false);


  const remainingLOCCharacters = computed(
    () => 512 - (freeFormReason.value?.length || 0)
  );

  function resetAdditionalOverridesForm() {
    selectedAdditionalOptions.value = [];
    isAcknowledged.value = false;
    freeFormReason.value = "";
    submitButtonDisabled.value = false;

  }  

  async function submitLOCForm() {
    const referenceId = props.application.data.referenceId;
    const url = `/api/applications/${referenceId}/Override/Additional`;
try {
  isSubmitted.value = true;
  const response = await axios.post(url, selectedAdditionalOptions.value);
  if (response.data.success === true) {
    overrideLOC.value = response.data.results;
    functions.openModal({
      title: "Success",
      description: "Additional Override has been saved.",
      buttons: [
        {
          title: "OK",
          onClick: async () => {
            await functions.closeModal();
            await getOverrideDetails();
          },
        },
      ],
    });
  }
  else {
  functions.openModal({
    title: "Additional Override",
    description: "Additional Override has not been saved.",
    buttons: [
      {
        title: "OK",
        onClick: async () => {
          await functions.closeModal();
          reloadWindow();
        },
      },
    ],
  });
}
} catch (error) {
  console.log("Error saving details:", error);
}

isSubmitted.value = false;
resetAdditionalOverridesForm();

}



  async function getOverrideDetails() {
    const referenceId = props.application.data.referenceId;
    const url = `/api/applications/${referenceId}/Overrides`;
    try {
      const response = await axios.get(url);
      if (response.data.success === true) {
        overrideLOC.value = response.data.results.overrideLOC;
        overrideAdditional.value = response.data.results.overrideAdditional
      }
      overrideOptions.value = response.data.results.overrideOptions;      
    } catch (error) {
      console.log("Error saving details:", error);
    }
  }

  const updateSubmitButtonState = () => {
  submitButtonDisabled.value = 
    freeFormReason.value.length === 0 || selectedAdditionalOptions.value.length === 0;
};
  watch(freeFormReason, (newReason) => {
    selectedAdditionalOptions.value.forEach((override) => {
      override.freeFormReason = newReason;
    });
    updateSubmitButtonState();
  });

  onMounted(() => {
    getOverrideDetails();
  });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (isSubmitted.value)
      ? (_openBlock(), _createBlock(Loader, {
          key: 0,
          class: "loader-wrapper-additional"
        }))
      : _createCommentVNode("", true),
    _createVNode(PermissionsWrapper, {
      disabled: !_ctx.canEdit,
      showDisabledIfNotVisible: true,
      permissionIds: [_unref(Permissions).Overrides],
      permissionLevel: _unref(PermissionLevels).Update
    }, {
      default: _withCtx(() => [
        _createElementVNode("form", {
          onSubmit: _withModifiers(submitLOCForm, ["prevent"]),
          autocomplete: "off"
        }, [
          _createElementVNode("div", _hoisted_1, [
            _cache[13] || (_cache[13] = _createElementVNode("div", { class: "card-header" }, [
              _createElementVNode("h3", null, "Additional Overrides")
            ], -1)),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _cache[6] || (_cache[6] = _createElementVNode("label", null, "Override Decision By:", -1)),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((overrideAdditional.value.reviewBy) = $event)),
                    class: "input-field-textarea",
                    placeholder: "Override decision by",
                    disabled: ""
                  }, null, 512), [
                    [_vModelText, overrideAdditional.value.reviewBy]
                  ])
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _cache[7] || (_cache[7] = _createElementVNode("label", null, "Date and Time", -1)),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((overrideAdditional.value.reviewDateTime) = $event)),
                    class: "input-field-textarea",
                    placeholder: "Date and Time",
                    disabled: ""
                  }, null, 512), [
                    [_vModelText, overrideAdditional.value.reviewDateTime]
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _cache[8] || (_cache[8] = _createElementVNode("label", null, "Select additional override(s) as needed:", -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(overrideOptions.value, (option) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "checkbox-group",
                    key: option.overrideTypeId
                  }, [
                    _createElementVNode("div", _hoisted_7, [
                      _withDirectives(_createElementVNode("input", {
                        type: "checkbox",
                        id: "application-override",
                        name: "overrides",
                        value: option,
                        disabled: !!overrideAdditional.value.reviewDateTime,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((selectedAdditionalOptions).value = $event)),
                        onChange: ($event: any) => (handleCheckboxChange(option, $event))
                      }, null, 40, _hoisted_8), [
                        [_vModelCheckbox, selectedAdditionalOptions.value]
                      ]),
                      _createElementVNode("label", _hoisted_9, _toDisplayString(option.overrideName), 1)
                    ])
                  ]))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_10, [
                _cache[9] || (_cache[9] = _createElementVNode("label", { class: "title-writeup" }, "Override Reason Comments", -1)),
                _withDirectives(_createElementVNode("textarea", {
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((freeFormReason).value = $event)),
                  disabled: isAcknowledged.value || !!overrideAdditional.value.reviewDateTime,
                  id: "message",
                  maxlength: "512",
                  rows: "4",
                  class: "custom-textarea",
                  placeholder: "Write up your comments here...",
                  required: ""
                }, "\t\t\t\t  ", 8, _hoisted_11), [
                  [_vModelText, freeFormReason.value]
                ]),
                _createElementVNode("div", _hoisted_12, _toDisplayString(remainingLOCCharacters.value) + " characters remaining ", 1)
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, [
                    _withDirectives(_createElementVNode("input", {
                      type: "checkbox",
                      placeholder: "Initiated By",
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((isAcknowledged).value = $event)),
                      disabled: !!overrideAdditional.value.reviewDateTime,
                      required: ""
                    }, null, 8, _hoisted_16), [
                      [_vModelCheckbox, isAcknowledged.value]
                    ])
                  ]),
                  _cache[10] || (_cache[10] = _createElementVNode("span", { class: "checkbutton" }, " I acknowledge I reviewed and completed Application Override. ", -1)),
                  _cache[11] || (_cache[11] = _createElementVNode("div", { class: "checkfield" }, null, -1)),
                  _cache[12] || (_cache[12] = _createElementVNode("div", { class: "checkfield" }, null, -1))
                ])
              ]),
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("button", {
                    class: "draw-button submit",
                    onClick: [
                      _cache[5] || (_cache[5] = _withModifiers(() => {}, ["prevent"])),
                      resetAdditionalOverridesForm
                    ],
                    disabled: !!overrideAdditional.value.reviewDateTime
                  }, " Cancel ", 8, _hoisted_19),
                  _createElementVNode("button", {
                    class: "draw-button submit",
                    type: "submit",
                    disabled: !!overrideAdditional.value.reviewDateTime || submitButtonDisabled.value
                  }, " Save ", 8, _hoisted_20)
                ])
              ])
            ])
          ])
        ], 32)
      ]),
      _: 1
    }, 8, ["disabled", "permissionIds", "permissionLevel"])
  ]))
}
}

})