import { defineStore } from "pinia";
import { ref } from "vue";
import useFormatter from "@/use/formatter";

export const useValidationStore = defineStore("validation", () => {
  const { formatMoney } = useFormatter();

  const fields = ref({
    displayedLOCLimit: {
      errors: [] as string[],
      valid: undefined as undefined | boolean,
    },
    displayedREFILimit: {
      errors: [] as string[],
      valid: undefined as undefined | boolean,
    },
  });

  interface Field {
    errors: string[];
    valid?: boolean;
  }

  function validateLoanLimitInput(
    field: Field,
    value: number,
    compare: number
  ) {
    field.errors = [];
    field.valid = true;

    if (value !== undefined && value !== null && typeof value == "number") {
      if (value > compare) {
        field.valid = true;
        field.errors = [
          `Amount entered is greater than the program limit: ${formatMoney(
            compare
          )}.`,
        ];
      } else if (value < compare) {
        field.valid = false;
        field.errors = [
          `Amount entered is less than the approved LOC limit: ${formatMoney(
            compare
          )}. Limits lower than the approved LOC Limit cannot be processed.`,
        ];
      } else if (value === compare) {
        field.valid = false;
        field.errors = [
          "Amount entered cannot be the same as the approved LOC Limit.",
        ];
      } else {
        field.valid = true;
        field.errors = [];
      }
    } else {
      field.valid = true;
      field.errors = [];
    }
  }

  function validateRefiLoanLimitInput(
    field: Field,
    value: number,
    compare: {
      loanProgramLimit: number;
      maxApprovedLimit?: number;
    }
  ) {
    field.errors = [];
    field.valid = true;

    if (value !== undefined && value !== null && typeof value == "number") {
      const validLoanLimitValue =
        compare.maxApprovedLimit &&
        value > compare.maxApprovedLimit &&
        compare.maxApprovedLimit !== value;
      const validLoanProgramLimitValue =
        value > compare.loanProgramLimit && compare.loanProgramLimit !== value;

      if (compare.maxApprovedLimit && !validLoanLimitValue) {
        field.valid = false;

        const error =
          compare.maxApprovedLimit !== value
            ? `Amount entered is less than the max loan approved amount: ${formatMoney(
                compare.maxApprovedLimit
              )}. Limits lower than the max loan approved amount cannot be processed.`
            : "Amount entered cannot be the same as the max loan approved amount.";
        field.errors = [error];
      } else if (compare.maxApprovedLimit && validLoanLimitValue) {
        field.valid = true;
        field.errors = [
          `Amount entered is greater than the max loan approved amount: ${formatMoney(
            compare.maxApprovedLimit
          )}.`,
        ];
      } else if (!compare.maxApprovedLimit && !validLoanProgramLimitValue) {
        field.valid = false;

        const error =
          compare.loanProgramLimit !== value
            ? `Amount entered is less than the Loan Program Limit: ${formatMoney(
                compare.loanProgramLimit
              )}. Limits lower than the Loan Program Limit cannot be processed.`
            : "Amount entered cannot be the same as the Loan Progam Limit amount.";

        field.errors = [error];
      } else if (!compare.maxApprovedLimit && validLoanProgramLimitValue) {
        field.valid = true;
        field.errors = [
          `Amount entered is greater than the Loan Program Limit: ${formatMoney(
            compare.loanProgramLimit
          )}.`,
        ];
      }
    } else {
      field.valid = true;
      field.errors = [];
    }
  }

  return {
    validateLoanLimitInput,
    validateRefiLoanLimitInput,
    fields,
  };
});
