import { computed, Ref } from 'vue';
import * as yup from 'yup';
import { Disbursement, ServiceType } from '@/models/loans';

export interface ValidationData {
	selectedDisbursement: Ref<Disbursement | null>;
	disbursementCancellationReasonOptions: Ref<ServiceType[]>;
}

export function usePostDisbursementChangeFormFields(data: ValidationData) {
	const changeFormFields = computed(() => {
		return [
			{
				name: 'disbursementCancellationReasonId',
				label: 'Cancellation Reason',
				type: 'select',
				value: 0,
				width: 'col-12',
				options: data.disbursementCancellationReasonOptions.value
			},
			{
				name: 'adjustmentAmount',
				label: 'Adjustment Amount',
				type: 'number',
				value: 0,
				width: 'col-6'
			},
			{
				name: 'processingDate',
				label: 'Processing Date',
				type: 'date',
				value: '',
				width: 'col-6',
				tooltip: 'Date the refund is received by Student Choice'
			},
			{
				name: 'settleDate',
				label: 'Settle Date',
				type: 'date',
				value: '',
				width: 'col-6',
				tooltip: 'Estimated date the funds should settle at the lender'
			},
			{
				name: 'effectiveDate',
				label: 'Effective Date',
				type: 'date',
				value: '',
				width: 'col-6',
				tooltip: 'Date the refund should be effective at the servicer'
			}
		];
	});

	const changeValidationSchema = computed(() => {
		return yup.object({
			adjustmentAmount: yup
				.number()
				.typeError('Adjustment Amount is invalid')
				.required('Adjustment Amount is required')
				.min(1, 'Adjustment Amount must be greater than $0')				
				.test('has-disbursed-amount', 'Disbursed Amount is required to enter Adjustment Amount', function () {
					return data.selectedDisbursement.value?.disbursedAmount !== undefined;
				})
				.test(
					'is-under-limit',
					`Adjustment Amount cannot exceed $${data.selectedDisbursement.value?.disbursedAmount}`,
					function (value) {
						const limit = data.selectedDisbursement.value?.disbursedAmount;
						return limit ? value <= limit : false;
					}
				),
			disbursementCancellationReasonId: yup
				.number()
				.typeError('Cancellation Reason is invalid')
				.required('Cancellation Reason is required')
				.min(1, 'Cancellation Reason is required'),
			processingDate: yup
				.date()
				.typeError('Processing Date is invalid')
				.required('Processing Date is required'),
			settleDate: yup
				.date()
				.typeError('Settle Date is invalid')
				.required('Settle Date is required'),
			effectiveDate: yup
				.date()
				.typeError('Effective Date is invalid')
				.required('Effective Date is required')
		});
	});

	return {
		changeFormFields,
		changeValidationSchema
	};
}
