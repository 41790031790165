import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "xpage-content-page",
  id: "xcredit-tab"
}
const _hoisted_2 = { class: "xtitlearea" }
const _hoisted_3 = {
  key: 0,
  class: "xwarningbox"
}

import { ref, watch, onMounted, computed } from "vue";
import ApplicationComments from "@/components/ApplicationComments.vue";
import CreditResults from "./CreditResults.vue";
import { Permissions, PermissionLevels } from "@/models/permissions";
import PermissionsWrapper from "@/components/wrappers/PermissionsWrapper.vue";

interface Props {
  isTabActive: boolean;
  applicants: any;
  application: any;
  commentsId: any;
  commentsComponentKey: any;
  isLoading: boolean;
  creditReport: any;
  creditData?: any;
  getCreditReport: any;
  getCreditsByApplication: any;
  disabled: boolean;
  creditId: any;
  credits: any;
  creditScore: any;
  creditKnockouts: any;
  creditKnockoutsFactors: any;
  decisionTypeId?: string;
  knockoutCodes?: any;
  store: any;
  canEdit: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CreditTab',
  props: {
    isTabActive: { type: Boolean },
    applicants: {},
    application: {},
    commentsId: {},
    commentsComponentKey: {},
    isLoading: { type: Boolean },
    creditReport: {},
    creditData: {},
    getCreditReport: {},
    getCreditsByApplication: {},
    disabled: { type: Boolean },
    creditId: {},
    credits: {},
    creditScore: {},
    creditKnockouts: {},
    creditKnockoutsFactors: {},
    decisionTypeId: {},
    knockoutCodes: {},
    store: {},
    canEdit: { type: Boolean }
  },
  emits: [
  "select-applicant",
  "disableForm",
  "setLiabilities",
  "getCreditReport",
  "getApplicantIncomes",
  "applicantLiabilityUpdated",
],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;
const creditInfo = ref({});
const applicants = ref(props.applicants);
const application = ref(props.application);
const creditReport = ref({});
const commentData = computed(() => props.application.commentData);

const hasApplicantCreditReport = computed(() => {
  const creditData = props.application?.creditData
  return !creditData || creditData.length === 0;
});

const handleSelectApplicant = (selectedType: string) => {
  applicants.value.selected = selectedType;
  applicants.value["backup"] = JSON.parse(
    JSON.stringify(applicants.value[selectedType])
  );
};

const renderKey = ref(0);
const forceRerender = (component: any) => {
  component.value += 1;
};

watch(
  () => props?.commentsComponentKey,
  () => {
    if (props?.isTabActive) {
      forceRerender(renderKey);
    }
  }
);

onMounted(async () => {
  try {
    await props.getCreditsByApplication?.();
    const creditData = props.application.creditData?.[0]?.applicantCredits;

    if (creditData) {
      ["primary", "secondary"].forEach(async (applicantType) => {
        const applicantId = props.applicants[applicantType]?.id;
        const credit = creditData.find((c) => c.applicantId === applicantId);

        if (credit && props.getCreditReport) {
          const {
            decisionTypeId,
            creditScore,
            creditKnockouts,
            creditKnockoutsFactors,
          } = await props.getCreditReport(credit.creditId, applicantType);
          creditInfo.value[applicantType] = {
            decisionTypeId,
            creditScore,
            creditKnockouts,
            creditKnockoutsFactors,
          };
        }
      });
    }
  } catch (error) {
    console.log(
      `CreditTab.vue Error: onMounted() could not be initialized`,
      error
    );
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.isTabActive)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _cache[3] || (_cache[3] = _createElementVNode("h2", null, "Credit", -1)),
            _createVNode(ApplicationComments, {
              type: "credit",
              id: _ctx.commentsId,
              comments: commentData.value
            }, null, 8, ["id", "comments"])
          ]),
          _createVNode(PermissionsWrapper, {
            permissionIds: [_unref(Permissions).Credit],
            permissionLevel: _unref(PermissionLevels).Read
          }, {
            default: _withCtx(() => [
              (hasApplicantCreditReport.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, " There is no credit report for this applicant. "))
                : (_openBlock(), _createBlock(CreditResults, {
                    key: 1,
                    isLoading: _ctx.isLoading,
                    disabled: _ctx.disabled,
                    applicants: applicants.value,
                    application: application.value,
                    creditReport: creditReport.value,
                    canEdit: _ctx.canEdit,
                    creditData: _ctx.creditData,
                    getCreditReport: _ctx.getCreditReport,
                    getCreditsByApplication: _ctx.getCreditsByApplication,
                    onSelectApplicant: handleSelectApplicant,
                    credits: _ctx.credits,
                    store: _ctx.store,
                    creditId: _ctx.creditId,
                    creditInfo: creditInfo.value,
                    creditScore: _ctx.creditScore,
                    decisionTypeId: _ctx.decisionTypeId,
                    creditKnockouts: _ctx.creditKnockouts,
                    creditKnockoutsFactors: _ctx.creditKnockoutsFactors,
                    onSetLiabilities: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('setLiabilities', $event))),
                    onGetCreditReport: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('getCreditReport'))),
                    onApplicantLiabilityUpdated: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('applicantLiabilityUpdated')))
                  }, null, 8, ["isLoading", "disabled", "applicants", "application", "creditReport", "canEdit", "creditData", "getCreditReport", "getCreditsByApplication", "credits", "store", "creditId", "creditInfo", "creditScore", "decisionTypeId", "creditKnockouts", "creditKnockoutsFactors"]))
            ]),
            _: 1
          }, 8, ["permissionIds", "permissionLevel"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})