import { defineStore } from "pinia";
import { $api } from "@/services/api1";
import { ApplicantCredit, Credit, CreditHistory, CreditRePullReason, CreditTab, KnockoutDetail } from "@/types/credit";

export const useCreditStore = defineStore("creditStore", {
  state: () => {
    return {
      referenceId: "" as string,
      creditTab: {} as CreditTab,
      previousTab: {} as CreditTab,
      creditRepullTypes: [] as CreditRePullReason[],
      credit: [] as Credit[],
      selectedApplicantId: 0 as number,
      previousSelectedApplicantId: 0 as number,      
    };
  },

  getters: {
    KnockoutDetails(state): KnockoutDetail[]{
       return state.creditTab.knockoutDetails
    },
    ApplicantUserId(state) {
      return (applicantId: number) => state.creditTab.applicantOptions.find(a => a.id == applicantId)?.userId || "";
    },
    ApplicantFullName(state) {
       return (applicantId: number) => state.creditTab.applicantOptions.find(a => a.id == applicantId)?.fullName || "";
    },
    ApplicantOptions(state) 
    {

      const strategies = {
        previous: () => state.previousTab.applicantOptions,
        current: () => state.creditTab.applicantOptions,
      };

      return (isPrevious) => (isPrevious ? strategies.previous() : strategies.current());
    },
    CreditSelected(state){

      const strategies = {
        previous: () => state.previousTab.applicants[state.previousSelectedApplicantId] || ({} as ApplicantCredit),
        current: () => state.creditTab.applicants[state.selectedApplicantId] || ({} as ApplicantCredit),
      };
    
      return (isPrevious) => (isPrevious ? strategies.previous() : strategies.current());
    },
    HasManyCreditHistories(state): boolean {
      return state.previousTab.applicants[state.previousSelectedApplicantId].creditHistories.length > 1;
    },
    Credit(state): Credit {
      return (
        state.credit.find((a) => a.applicantId == state.selectedApplicantId) ||
        ({} as Credit)
      );
    },
    decisionMapping(): Record<number, { label: string; color: string, textColor: string }> {
      return {
        1: {
          label: "Hard Decline",
          color: "rgba(227, 24, 56, 0.2)",
          textColor: "#E31838",
        },
        2: {
          label: "Soft Decline",
          color: "rgba(227, 24, 56, 0.2)",
          textColor: "#E31838",
        },
        3: {
          label: "Evaluate",
          color: "rgba(247, 148, 29, 0.2)",
          textColor: "#F7941D",
        },
        4: {
          label: "Conditionally Approved",
          color: "rgba(72, 168, 66, 0.2)",
          textColor: "#48A842",
        },
        5: {
          label: "Not Evaluated",
          color: "rgba(46, 49, 146, 0.2)",
          textColor: "#2E3192",
        },
      };
    }
  },

  actions: {
    setReferenceId(referenceId: string){
      this.referenceId = referenceId;
    },
    updateActiveApplicant(applicantId: number, isPrevious: boolean) {
        const targetTab = isPrevious ? this.previousTab : this.creditTab;

        if(isPrevious){
          this.previousSelectedApplicantId = applicantId;
        }else{
          this.selectedApplicantId = applicantId;
        }

        targetTab.applicantOptions.forEach((applicant) => {
          applicant.isSelected = false;
        });

        const applicant = targetTab.applicantOptions.find(a => a.id == applicantId);

        if(applicant !== undefined){
           applicant.isSelected = true;
        }

    },

    async handleCreditHistoryChange(creditHistoryId: number, isPrevious: boolean){

      const creditHistory: CreditHistory | undefined  = this.CreditSelected(isPrevious).creditHistories.find(c => c.id == creditHistoryId);

      if(creditHistory !== undefined){
        await this.getCreditReportByReference(this.referenceId, creditHistory, isPrevious);
      }
    },

    async getCreditReportByReference(referenceId: string, creditHistory: CreditHistory, isPrevious: boolean)
    {

        const creditTab = await $api.credits.getCreditReportByReference(referenceId, creditHistory);

        if(!this.creditTab || Object.keys(this.creditTab).length === 0){
            this.creditTab = creditTab.currentCredit;
            this.previousTab = creditTab.previousCredit;
            this.creditRepullTypes = creditTab.creditRepullTypes;
        }
        else {
          const targetTab = isPrevious ? this.previousTab : this.creditTab;
          Object.entries(creditTab.currentCredit.applicants).forEach(([key, value]) => {
              targetTab.applicants[key] = value;
          });
        
        }
       
    },
    async getCreditReportByApplicant(
      applicationId: number,
      applicantId: number
    ) {
      if (!this.credit.some((a) => a.applicantId == applicantId)) {
        const creditReport = await $api.credits.getCreditReportByApplicant(
          applicationId,
          applicantId
        );

        if (creditReport) {
          this.credit.push(creditReport);
        }
      }
    },
  },
});
