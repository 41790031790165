import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["value"]
const _hoisted_3 = ["disabled"]

import { reactive, watchEffect, computed } from 'vue';
	import { Form, Field, ErrorMessage } from 'vee-validate';
	import { Schema } from 'yup';

	interface FormField {
		name: string;
		label: string;
		type: string;
		value: any;
		width: string;
		showOnEdit: boolean;
		disabledOnEdit: boolean;
	}

	
export default /*@__PURE__*/_defineComponent({
  __name: 'DisbursementForm',
  props: {
    onSubmit: { type: Function },
    isSubmitting: { type: Boolean },
    fields: {},
    validationSchema: {},
    mode: {}
  },
  setup(__props: any) {

	const props = __props;

	const formData = reactive<Record<string, any>>({});

	const isFormValid = computed(() => {
		return (errors: Partial<Record<string, string | undefined>>) => errors && Object.keys(errors).length === 0;
	});

	function isFieldDisabled(field: FormField) {
		const disabledOnEdit = props.mode === 'edit' && field.disabledOnEdit === true;

		return props.mode === 'view' || props.isSubmitting || disabledOnEdit;
	}

	async function onSubmit() {
		if (props.onSubmit) {
			await props.onSubmit({ ...formData });
		}
	}

	watchEffect(() => {
		props.fields.forEach((field) => {
			formData[field.name] = field.value;
		});
	});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Form), {
    "validation-schema": _ctx.validationSchema,
    class: "form-container",
    onSubmit: onSubmit
  }, {
    default: _withCtx(({ errors }) => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (field) => {
        return (_openBlock(), _createElementBlock("div", {
          key: field.name,
          class: _normalizeClass(['form-input', field.width])
        }, [
          _createElementVNode("label", {
            for: field.name
          }, _toDisplayString(field.label), 9, _hoisted_1),
          (field.type === 'select')
            ? (_openBlock(), _createBlock(_unref(Field), {
                key: 0,
                as: "select",
                id: field.name,
                name: field.name,
                modelValue: formData[field.name],
                "onUpdate:modelValue": ($event: any) => ((formData[field.name]) = $event),
                disabled: isFieldDisabled(field)
              }, {
                default: _withCtx(() => [
                  _cache[0] || (_cache[0] = _createElementVNode("option", null, null, -1)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(field.options, (option) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: option.id,
                      value: option.id
                    }, _toDisplayString(option.name), 9, _hoisted_2))
                  }), 128))
                ]),
                _: 2
              }, 1032, ["id", "name", "modelValue", "onUpdate:modelValue", "disabled"]))
            : (_openBlock(), _createBlock(_unref(Field), {
                key: 1,
                id: field.name,
                name: field.name,
                type: field.type,
                modelValue: formData[field.name],
                "onUpdate:modelValue": ($event: any) => ((formData[field.name]) = $event),
                disabled: isFieldDisabled(field)
              }, null, 8, ["id", "name", "type", "modelValue", "onUpdate:modelValue", "disabled"])),
          _withDirectives(_createVNode(_unref(ErrorMessage), {
            name: field.name,
            class: "error"
          }, null, 8, ["name"]), [
            [_vShow, _ctx.mode !== 'view']
          ])
        ], 2))
      }), 128)),
      (_ctx.mode !== 'view')
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "submit-button col-12",
            type: "submit",
            disabled: !isFormValid.value(errors) || _ctx.isSubmitting
          }, " Submit ", 8, _hoisted_3))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["validation-schema"]))
}
}

})