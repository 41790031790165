import { ApplicantVM } from "./applicant";
import { Tag } from "./tag";
import { LOSType } from "./types";
import { RatePlanDataVM } from "./pricing";
import { ProgramTier } from "@/types/evaluation";

export interface ApplicationDetail {
  isFullService?: boolean;
  hasRecommendation?: boolean;
  analystRecommendation?: string;
  lenderName?: string;
}

export interface TabVM {
  name: string;
  identifier: string;
  permissionId?: string;
  permissionLevel: number;
  tabStatus?: string;
  isTabActive: boolean;
}

export interface ApplicationDetailVM {
  rightToCancelDisplay: string;
  rightToCancel: string;
  anticipatedDisbursementDate: string;
  anticipatedDibursementDisplay: string;
  id: number;
  referenceId: string;
  lenderId: number;
  lenderName: string;
  productTypeId: number;
  programTypeId?: number;
  tollFreeNumber: string;
  esignEnvelopeCreatedDisplay: string;
  esignEnvelopeSignedDisplay: string;
  loanId: number;
  created: string;
  expiration: string;
  statusName: string;
  statusId: number;
  submittedDisplay: string;
  submitted: Date;
  hasPrimaryApplicant: boolean;
  hasCoApplicant: boolean;
  pageIdentifier: string;
  isLoanReady: boolean;
  canEdit: boolean;
  maxApprovedLimit: string;
  loanProgramLimit: string;
  applicationDetailsVM: ApplicationDetail;
  primaryApplicant: ApplicantVM;
  coApplicant: ApplicantVM;
  tabs: TabVM[];
  tags: Tag[];
  tabMembership: TabMembershipVM;
  applicationRatePlans: ApplicationRatePlans[];
	programTierRefi: ProgramTier[];
}

export interface ApplicationSummary {
  primaryApplicantInitials: string;
  primaryApplicantFullName: string;
  statusId: number;
  statusName: string;
  loanId?: number | null;
  loanAmount: string;
  initialDrawAmount: string;
  graduationDate: string;
  schoolName: string;
  isRateSet: boolean;
  rate: string;
  rateTypeName: string;
  lenderName: string;
  disabledStatuses: boolean;
  applicationStatuses: LOSType[];
  isRightToCancel: boolean;
}

export interface ApplicationComment {
  id: number;
  applicationId: number;
  userId?: string | null;
  comment?: string | null;
  lastChangedAt?: Date | null;
  userDisplayName?: string | null;
  initials?: string | null;
}

export interface Applicant {
  id: number;
  fullName: string;
  initials: string;
  applicantTypeName: string;
}

export interface MembershipVM {
  applicantId: number;
  isMember: boolean;
  memberId: string;
  membershipStatusId: number;
  membershipStatusName: string;
  isJointOnStudentAccount: boolean;
}

export interface TabMembershipVM {
  primaryApplicantMembership: MembershipVM;
  coApplicantMembership: MembershipVM;
}

export enum NationalIdNumberTypes {
  SSN = 1,
  ITIN = 2,
}

export enum ApplicationStatuses {
  NotSubmitted = 1,
  Evaluation = 2,
  SubmittedProcessing = 3,
  Complete = 4,
  Declined = 5,
  Cancelled = 6,
  SMRDeclined = 7,
  RightToCancel = 8,
}

export class ApplicationStatusIds {
  public static get NOT_SUBMITTED(): number {
    return ApplicationStatuses.NotSubmitted;
  }
  public static get EVALUATION(): number {
    return ApplicationStatuses.Evaluation;
  }
  public static get SUBMITTED_AND_PROCESSING(): number {
    return ApplicationStatuses.SubmittedProcessing;
  }
  public static get COMPLETE(): number {
    return ApplicationStatuses.Complete;
  }
  public static get DECLINED(): number {
    return ApplicationStatuses.Declined;
  }
  public static get CANCELLED(): number {
    return ApplicationStatuses.Cancelled;
  }
  public static get SMR_DECLINED(): number {
    return ApplicationStatuses.SMRDeclined;
  }
  public static get RIGHT_TO_CANCEL(): number {
    return ApplicationStatuses.RightToCancel;
  }
}

export interface AddressVM {
  id: number;
  addressId: number;
  addressTypeId: number;
  applicantId: number;
  address1: string;
  address2: string;
  city: string;
  stateId: number;
  stateName: string;
  postalCode: string;
  countryId: number;
  countryName: string;
}

interface SchoolVM {
  programTypeId: number;
  schoolId: number;
  schoolName: string;
  levelTypeId: number;
  levelTypeName: string;
  major: string;
  degreeProgram: string;
  highestDegreeLevel?: string;
  displayMajorDegree: string;
}

export interface ApplicantFormVM extends ApplicantVM {
  firstName: string;
  lastName: string;
  middleName: string;
  citizenshipStatusId: number;
  citizenshipStatusName: string;
  dateOfBirth: string;
  address: AddressVM;
  phoneNumber: string;
  phoneNumberTypeId: number;
  phoneNumberTypeName: string;
}

export interface AuthorizedThirdPartyVM {
  id: number;
  firstName: string;
  lastName: string;
  fullName: string;
  initials: string;
  emailAddress: string;
  address: AddressVM;
  phoneNumber: string;
  phoneNumberTypeId: number;
  phoneNumberTypeName: string;
  authorizedByApplicantId: number;
  selected: boolean;
}

interface NotificationVM {
  id: number;
  status: string;
  title: string;
  text: string;
}

export interface TabApplicationVM {
  programTypeId: number;
  school: SchoolVM;
  hasPrimaryApplicant: boolean;
  primaryApplicant: ApplicantFormVM;
  hasCoApplicant: boolean;
  coApplicant: ApplicantFormVM;
  hasAuthorizedThirdParty: boolean;
  authorizedThirdParty: AuthorizedThirdPartyVM[];
  notifications: NotificationVM[];
}

export interface Cancellation {
  applicantId: number;
  statusReasonId: number;
  cancellationReason: string;
}

export interface ApplicationRatePlans {
  variableRateVMs?: RatePlanDataVM; // Mark as optional to align with backend
  fixedRateVMs?: RatePlanDataVM; // Mark as optional to align with backend
  yearlyTerm?: number;
  pricingTierIds?: number[]; // Mark as optional to align with backend
}
