import { computed, Ref } from 'vue';
import * as yup from 'yup';
import { Borrower, ServiceType, DocumentProperties } from '@/models/loans';

export interface ValidationData {
	properties: Ref<DocumentProperties>;
	borrowers: Borrower[];
	documentTypeOptions: Ref<ServiceType[]>;
}

export function useFormFields(data: ValidationData) {
	const borrowerOptions: ServiceType[] = data.borrowers.map((borrower) => ({
		id: borrower.id,
		name: borrower.fullName
	}));

	const formFields = computed(() => {
		return [
			{
				name: 'documentTypeId',
				label: 'Document Type',
				type: 'select',
				value: null,
				width: 'col-12',
				options: data.documentTypeOptions.value
			},
			{
				name: 'borrowerId',
				label: 'Borrower',
				type: 'select',
				value: null,
				width: 'col-12',
				options: borrowerOptions
			},
			{
				name: 'startIndex',
				label: 'Page From',
				type: 'number',
				value: null,
				width: 'col-6'
			},
			{
				name: 'endIndex',
				label: 'Page To',
				type: 'number',
				value: null,
				width: 'col-6'
			},
			{
				name: 'borrowerVisible',
				label: 'Borrower Visible',
				type: 'checkbox',
				value: false,
				width: 'col-12'
			}
		];
	});

	const validationSchema = computed(() => {
		return yup.object({
			documentTypeId: yup
				.number()
				.typeError('Document Type is invalid')
				.required('Document Type is required')
				.min(1),
			borrowerId: yup
				.number()
				.typeError('Borrower is invalid')
				.required('Borrower is required')
				.min(1),
			startIndex: yup
				.number()
				.typeError('Page From is invalid')
				.required('Page From is required')
				.min(1, 'Page From cannot be less than 1')
				.max(data.properties.value.pageCount, `Page From cannot be greater than ${data.properties.value.pageCount}`),
			endIndex: yup
				.number()
				.typeError('Page To is invalid')
				.required('Page To is required')
				.min(yup.ref('startIndex'), 'Page To cannot be less than Page From')
				.max(data.properties.value.pageCount, `Page To cannot be greater than ${data.properties.value.pageCount}`),
			borrowerVisible: yup
				.boolean()
				.default(false)
		});
	});

	return {
		formFields,
		validationSchema
	};
}
