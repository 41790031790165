export enum Type
{
    Addresses= "Addresses",
    ApplicationStatus= "ApplicationStatus",
    ApplicationStatusReasons = "ApplicationStatusReasons",
    Countries="Countries",
    Citizenship = "Citizenship",
    DocumentStatus="DocumentStatus",
		DocumentStatusReasons="DocumentStatusReasons",
    DocumentType="DocumentType",
    Employments = "Employments",
    GraduateDegreePrograms = "GraduateDegreePrograms",
    Income="Income",
    IncomePeriods="IncomePeriods",
    IncomeStatus="IncomeStatus",
    IncomeYears="IncomeYears",
    JudgmentDenials="JudgmentDenials",
    Majors = "Majors",
    Membership="Membership",
    NationalIdNumberTypes="NationalIdNumberTypes",
    PhoneNumbers="PhoneNumbers",
    ProgramRequirements="ProgramRequirements",
    Products="Products",
    Rates="Rates",
    RequirementStatuses="RequirementStatuses",
    Stipulations="Stipulations",
    StipulationStatuses = "StipulationStatuses",
    StipulationStatusReasons = "StipulationStatusReasons",
    StipulationTypes = "StipulationTypes",
    States="States",
    Requirements="Requirements"
}

export interface TypeDictionary{
    [index: string]: Array<LOSType>
}


export interface LOSType {
    id: number;
    abbreviation?: string;
    name?: string;
    description?: string;
    programTypeId?: number;
    createdBy?: string;
    visible?: boolean;
    createdAtUtc?: Date;
    updatedBy?: string;
    updatedAtUtc?: Date;
    disabled: boolean;
    selected: boolean;
}

export enum DocumentEntityTypes{
    Application = 1,
    Loan = 2
}

export interface BaseOption {
    label: string;
    value: number;
}

export interface StipContextOption {
    text: string,
    id: string,
    showItemOnClick: boolean,
}

export enum StipulationTypes{
    AdditionalIncome=18,
    EstablishMembership=21,
    UnfreezeCredit=42,
    UnSuppressBureau=43,
    ContactCBRCorrection=44,
    ConfirmPayoffRequest=62,
    LoanAccountStatement=63,
    PayoffStatement=65,
    ResponsibilityForUnderPayment=68,
    ServicerPayoffAddress = 71
}

export enum StipulationStatuses {
    Open = 1,
    Completed = 2,
    CUOverride = 3,
    Cancelled = 4,
    Pending = 5
}

export enum StipulationStatusReasons {
    Open = 1,
    Fulfilled = 2,
    Override = 3,
    UnableToProvide = 4,
    Replaced = 5,
    ItemNotNeeded = 6
}

export enum Products {
  LOC = 1,
  REFI = 2,
}

export class ProductIds {
  public static get LOC(): number {
    return Products.LOC;
  }
  public static get REFI(): number {
    return Products.REFI;
  }
}

export enum ApplicationStatusTypeNames {
  NOT_SUBMITTED = "Not Submitted",
  EVALUATION = "Evaluation",
  SUBMITTED_AND_PROCESSING = "Submitted & Processing",
  COMPLETE = "Complete",
  DECLINED = "Declined",
  CANCELLED = "Cancelled",
  SMR_DECLINED = "SMR Declined",
  RIGHT_TO_CANCEL = "Right To Cancel",
}
