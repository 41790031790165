import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "xpage-content-page",
  id: "xmembership-tab"
}
const _hoisted_2 = { class: "xtitlearea" }

import {
  ref,
  watch,
  computed,
} from "vue";
import Notifications from "@/components/shared/Notifications.vue";
import ApplicationComments from "@/components/ApplicationComments.vue";
import MembershipForm from "./MembershipForm.vue";
import { Permissions, PermissionLevels } from "@/models/permissions";
import PermissionsButton from "../wrappers/PermissionsButton.vue";

interface Props {
  loading?: boolean;
  store?: any;
  isTabActive: boolean;
  disabled: boolean;
  notificationsData: object;
  commentsId: any;
  commentsComponentKey: any;
  applicants: any;
  application: any;
  membershipTypes: any;
  canEdit: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MembershipTab',
  props: {
    loading: { type: Boolean },
    store: {},
    isTabActive: { type: Boolean },
    disabled: { type: Boolean, default: false },
    notificationsData: {},
    commentsId: {},
    commentsComponentKey: {},
    applicants: {},
    application: {},
    membershipTypes: {},
    canEdit: { type: Boolean }
  },
  emits: ["submitForm", "toggle", "forceRerender"],
  setup(__props: any, { emit: __emit }) {

const emit = __emit;
const props = __props;
const renderKey = ref(0);
const forceRerender = (component: any) => {
  component.value += 1;
};
const cancelBtnClicked = ref<boolean>(false);
const isFormDisabled = computed(() => props.disabled);
const commentData = computed(() => props.application.commentData);

const onEditClick = () => {
  if (isFormDisabled.value) {
    cancelBtnClicked.value = false;
    emit("toggle");
  } else {
    cancelBtnClicked.value = true;
  }
};

watch(
  () => props?.commentsComponentKey,
  () => {
    if (props?.isTabActive) {
      forceRerender(renderKey);
    }
  }
);

return (_ctx: any,_cache: any) => {
  return (_ctx.isTabActive)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(Notifications, { notificationsData: _ctx.notificationsData }, null, 8, ["notificationsData"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(PermissionsButton, {
            permissionIds: [_unref(Permissions).ProofOfMembership],
            permissionLevel: _unref(PermissionLevels).Update,
            disabled: !_ctx.canEdit,
            showDisabledIfNotVisible: true,
            class: "xedit",
            onClick: onEditClick
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.disabled ? "Edit" : "Cancel"), 1)
            ]),
            _: 1
          }, 8, ["permissionIds", "permissionLevel", "disabled"]),
          _cache[2] || (_cache[2] = _createElementVNode("h2", null, "Membership", -1)),
          _createVNode(ApplicationComments, {
            type: "membership",
            id: _ctx.commentsId,
            comments: commentData.value
          }, null, 8, ["id", "comments"])
        ]),
        _createVNode(MembershipForm, {
          primaryApplicant: _ctx.applicants['primary'],
          secondaryApplicant: _ctx.applicants['secondary'],
          canEdit: _ctx.canEdit,
          disabled: _ctx.disabled,
          membershipTypes: _ctx.membershipTypes,
          cancelBtnClicked: cancelBtnClicked.value,
          onSubmitForm: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('submitForm', $event))),
          onToggle: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('toggle')))
        }, null, 8, ["primaryApplicant", "secondaryApplicant", "canEdit", "disabled", "membershipTypes", "cancelBtnClicked"])
      ]))
    : _createCommentVNode("", true)
}
}

})