import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, vModelText as _vModelText, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "xcommentbox-wrapper" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 0,
  class: "xcommentbox-main"
}
const _hoisted_6 = {
  key: 0,
  type: "submit",
  style: {"margin-top":"5px"}
}
const _hoisted_7 = { class: "smallcapitals" }
const _hoisted_8 = {
  key: 0,
  class: "xcomments",
  style: {"padding-bottom":"20px"}
}
const _hoisted_9 = {
  class: "xusericon",
  "data-size": "medium",
  style: {"float":"left","display":"block","margin-right":"10px"}
}
const _hoisted_10 = { class: "xusericon-picture" }
const _hoisted_11 = { class: "xcomments-content" }

import { computed, ref, onMounted } from "vue";
import store from "@/store";
import functions from "@/use/functions.js";
import useFunctions from "@/use/functions1";
import moment from "moment-timezone";
import { $api } from "@/services/api1";
import { useApplicationStore } from "@/store/store/applicationVMStore";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";

interface User {
  sub: string;
  given_name: string;
  family_name: string;
}

interface OidcStore {
  user: User;
}

interface RootState {
  oidcStore: OidcStore;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ApplicationComments',
  emits: ["getComments"],
  setup(__props, { emit: __emit }) {

const route = useRoute();
const applicationStore = useApplicationStore();

const { comments } = storeToRefs(applicationStore);

const animate = computed(() => {
  return { disrespectUserMotionPreference: true };
});

const newComment = ref<string>("");
const focusComment = ref({} as HTMLElement);
const { decodeHTMLEncodedStr } = useFunctions();
let showComments = ref<boolean>(false);
const emit = __emit;

function validateComment(request) {
  if (!request.comment) {
    functions.openModal("Please enter a comment before submitting.");
    return;
  } else {
    return true;
  }
}

async function saveApplicationComment() {
  let response = {} as any;
  let request = {} as any;

  const storeState = store.state as RootState;

  request = {
    id: 0,
    applicationId: applicationStore.viewModel.id,
    userId: storeState.oidcStore.user.sub,
    userDisplayName: `${storeState.oidcStore.user.given_name} ${storeState.oidcStore.user.family_name}`,
    comment: newComment.value,
  };

  const validComment = validateComment(request);

  if (validComment) {
    response = await $api.applications.addOrUpdateApplicationComment(request);
    if (response.comment && response.id) {
      response.comment = decodeHTMLEncodedStr(response.comment);
      response.date = moment(response.lastChangedAt)
        .tz(moment.tz.guess())
        .format("MMMM D, YYYY, h:mm A z");
      response.initials = getInitials(response.userDisplayName);
      response.userDisplayName =
        decodeHTMLEncodedStr(response.userDisplayName) || "No Name";
      applicationStore.applicationComments.unshift(response);
    }

    newComment.value = "";
  }
}

function toggleComments() {
  // toggle showComments true and false
  showComments.value = !showComments.value;
  // delay 500ms so the animation can finish before the focus
  setTimeout(() => {
    focusComment.value?.focus();
  }, 500);
}

function getInitials(input) {
  if (input) {
    // split each name into an array on space, map each name to only the first letter, join the letters together, return only two letters maximum
    return input
      .split(" ")
      .map((name) => name[0])
      .join("")
      .substr(0, 2);
  } else {
    // return "NN" for no name
    return "NN";
  }
}

onMounted(() => {
  const referenceId = route.params.referenceId.toString();
  applicationStore.getApplicationComments(referenceId);
});

return (_ctx: any,_cache: any) => {
  const _directive_auto_animate = _resolveDirective("auto-animate")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
      (!_unref(showComments))
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "xcommentbox",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (toggleComments()))
          }, _cache[4] || (_cache[4] = [
            _createElementVNode("i", { class: "icon-pencil-alt" }, null, -1),
            _createTextVNode(" Write a comment... ")
          ])))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: "xcommentbox-expand",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (toggleComments()))
      }, [
        (!_unref(showComments))
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("span", null, _toDisplayString(_unref(comments).length), 1),
              _cache[5] || (_cache[5] = _createTextVNode(" Comment")),
              (_unref(comments).length !== 1)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, "s"))
                : _createCommentVNode("", true),
              _cache[6] || (_cache[6] = _createElementVNode("i", { class: "icon-down-open-big" }, null, -1))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[7] || (_cache[7] = [
              _createTextVNode("Hide Comments "),
              _createElementVNode("i", { class: "icon-up-open-big" }, null, -1)
            ])))
      ])
    ])), [
      [_directive_auto_animate, animate.value]
    ]),
    _cache[12] || (_cache[12] = _createElementVNode("div", { class: "clear" }, null, -1)),
    _createElementVNode("div", null, [
      (_unref(showComments))
        ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_5, [
            _withDirectives((_openBlock(), _createElementBlock("form", {
              onSubmit: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (saveApplicationComment()), ["prevent"])),
              style: {"padding-bottom":"20px"}
            }, [
              _cache[8] || (_cache[8] = _createElementVNode("h3", { class: "smallcapitals" }, [
                _createElementVNode("i", {
                  class: "icon-pencil-alt",
                  style: {"margin-right":"5px"}
                }),
                _createTextVNode(" Write a Comment: ")
              ], -1)),
              _withDirectives(_createElementVNode("textarea", {
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((newComment).value = $event)),
                ref_key: "focusComment",
                ref: focusComment,
                maxlength: "4000"
              }, null, 512), [
                [_vModelText, newComment.value]
              ]),
              (newComment.value.length > 0)
                ? (_openBlock(), _createElementBlock("input", _hoisted_6))
                : _createCommentVNode("", true)
            ], 32)), [
              [_directive_auto_animate, animate.value]
            ]),
            _createElementVNode("h3", _hoisted_7, [
              _cache[9] || (_cache[9] = _createTextVNode(" Comments (")),
              _createElementVNode("span", null, _toDisplayString(_unref(comments).length), 1),
              _cache[10] || (_cache[10] = _createTextVNode(") "))
            ]),
            (_unref(comments))
              ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_8, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(comments), (comment, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "xcommentbox-main-item",
                      key: index
                    }, [
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("div", _hoisted_10, _toDisplayString(comment.initials), 1),
                        _cache[11] || (_cache[11] = _createElementVNode("div", {
                          class: "xusericon-gender",
                          "data-gender": ""
                        }, null, -1))
                      ]),
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("h4", null, _toDisplayString(comment.userDisplayName), 1),
                        _createElementVNode("p", null, _toDisplayString(comment.comment), 1),
                        _createElementVNode("h5", null, _toDisplayString(comment.date), 1)
                      ])
                    ]))
                  }), 128))
                ])), [
                  [_directive_auto_animate, animate.value]
                ])
              : _createCommentVNode("", true)
          ])), [
            [_directive_auto_animate, animate.value]
          ])
        : _createCommentVNode("", true)
    ])
  ], 64))
}
}

})