import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["value"]
const _hoisted_3 = ["disabled"]
const _hoisted_4 = ["disabled"]

import { reactive, ref, watchEffect, computed, watch } from 'vue';
	import type { ComponentPublicInstance } from 'vue';
	import { Form, Field, ErrorMessage } from 'vee-validate';
	import { Schema } from 'yup';
	import { PayoffPostDisbursementChange } from '@/models/loans';
	import PostDisbursementChangeForm from './PostDisbursementChangeForm.vue';
	import { usePostDisbursementChangeFormFields } from '@/composables/loan/refi/usePostDisbursementChangeForm';

	interface FormField {
		name: string;
		label: string;
		type: string;
		value: any;
		width: string;
		showOnEdit: boolean;
		disabledOnEdit: boolean;
	}

	interface PostDisbursementChangeFormInstance {
		clearFormData: () => void;
	}

	
export default /*@__PURE__*/_defineComponent({
  __name: 'PayoffForm',
  props: {
    onSubmit: { type: Function },
    originalDisbursementAmount: {},
    isSubmitting: { type: Boolean },
    fields: {},
    validationSchema: {},
    mode: {}
  },
  setup(__props: any) {

	const props = __props;

	const formData = reactive<Record<string, any>>({});
	const postDisbursementChangeFormRef = ref<(ComponentPublicInstance & PostDisbursementChangeFormInstance) | null>(
		null
	);

	const postDisbursementChange = ref<PayoffPostDisbursementChange | null>(null);
	const postDisbursementChangeFormVisible = ref<boolean>(false);

	const { changeFormFields, changeValidationSchema } = usePostDisbursementChangeFormFields({
		disbursementAmount: props.originalDisbursementAmount
	});

	const isFormValid = computed(() => {
		return (errors: Partial<Record<string, string | undefined>>) => errors && Object.keys(errors).length === 0;
	});

	const isPostDisbursementChange = computed(() => formData['payoffDisbursementStatusId'] === 5);
	const editChangeVisible = computed(() => isPostDisbursementChange.value && postDisbursementChange.value);
	const submitButtonClass = computed(() => ['submit-button', editChangeVisible.value ? 'col-6' : 'col-12']);

	const showPostDisbursementChangeModal = () => (postDisbursementChangeFormVisible.value = true);
	const closePostDisbursementChangeModal = () => (postDisbursementChangeFormVisible.value = false);

	function applyPostDisbursementChange(formData: Record<string, any>) {
		postDisbursementChange.value = { ...formData } as PayoffPostDisbursementChange;
	}

	function isFieldDisabled(field: FormField) {
		const disabledOnEdit = props.mode === 'edit' && field.disabledOnEdit === true;

		return props.mode === 'view' || props.isSubmitting || disabledOnEdit;
	}

	async function onSubmit() {
		if (isPostDisbursementChange.value && !postDisbursementChange.value) {
			showPostDisbursementChangeModal();
		} else if (props.onSubmit) {
			await props.onSubmit({ ...formData }, postDisbursementChange.value);
			clearPostDisbursementChange();
		}
	}

	function clearPostDisbursementChange() {
		postDisbursementChange.value = null;
		postDisbursementChangeFormRef.value?.clearFormData();
	}

	watch(
		() => formData['payoffDisbursementStatusId'],
		(newValue, oldValue) => {
			if (oldValue === 5 && newValue !== 5) {
				clearPostDisbursementChange();
			}
		}
	);

	watchEffect(() => {
		props.fields.forEach((field) => {
			formData[field.name] = field.value;
		});
	});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Form), {
    "validation-schema": _ctx.validationSchema,
    class: "form-container",
    onSubmit: onSubmit
  }, {
    default: _withCtx(({ errors }) => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (field) => {
        return (_openBlock(), _createElementBlock("div", {
          key: field.name,
          class: _normalizeClass(['form-input', field.width])
        }, [
          _createElementVNode("label", {
            for: field.name
          }, _toDisplayString(field.label), 9, _hoisted_1),
          (field.type === 'select')
            ? (_openBlock(), _createBlock(_unref(Field), {
                key: 0,
                as: "select",
                id: field.name,
                name: field.name,
                modelValue: formData[field.name],
                "onUpdate:modelValue": ($event: any) => ((formData[field.name]) = $event),
                disabled: isFieldDisabled(field)
              }, {
                default: _withCtx(() => [
                  _cache[0] || (_cache[0] = _createElementVNode("option", null, null, -1)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(field.options, (option) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: option.id,
                      value: option.id
                    }, _toDisplayString(option.name), 9, _hoisted_2))
                  }), 128))
                ]),
                _: 2
              }, 1032, ["id", "name", "modelValue", "onUpdate:modelValue", "disabled"]))
            : (_openBlock(), _createBlock(_unref(Field), {
                key: 1,
                id: field.name,
                name: field.name,
                type: field.type,
                modelValue: formData[field.name],
                "onUpdate:modelValue": ($event: any) => ((formData[field.name]) = $event),
                disabled: isFieldDisabled(field)
              }, null, 8, ["id", "name", "type", "modelValue", "onUpdate:modelValue", "disabled"])),
          _withDirectives(_createVNode(_unref(ErrorMessage), {
            name: field.name,
            class: "error"
          }, null, 8, ["name"]), [
            [_vShow, _ctx.mode !== 'view']
          ])
        ], 2))
      }), 128)),
      (_ctx.mode === 'edit')
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: _normalizeClass(submitButtonClass.value),
            type: "submit",
            disabled: !isFormValid.value(errors) || _ctx.isSubmitting
          }, _toDisplayString(isPostDisbursementChange.value && !postDisbursementChange.value ? 'Create Post-Disbursement Change' : 'Submit'), 11, _hoisted_3))
        : _createCommentVNode("", true),
      (_ctx.mode === 'edit' && editChangeVisible.value)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            class: "secondary col-6",
            type: "button",
            disabled: !isFormValid.value(errors) || _ctx.isSubmitting,
            onClick: _withModifiers(showPostDisbursementChangeModal, ["prevent"])
          }, " Edit Post-Disbursement Change ", 8, _hoisted_4))
        : _createCommentVNode("", true),
      _withDirectives(_createVNode(PostDisbursementChangeForm, {
        ref_key: "postDisbursementChangeFormRef",
        ref: postDisbursementChangeFormRef,
        isSubmitting: _ctx.isSubmitting,
        fields: _unref(changeFormFields),
        validationSchema: _unref(changeValidationSchema),
        onApply: applyPostDisbursementChange,
        onClose: closePostDisbursementChangeModal
      }, null, 8, ["isSubmitting", "fields", "validationSchema"]), [
        [_vShow, postDisbursementChangeFormVisible.value]
      ])
    ]),
    _: 1
  }, 8, ["validation-schema"]))
}
}

})