import { ref, onMounted } from 'vue';
import axios from 'axios';
import { ServiceType } from '@/models/loans';

export function useDocumentData() {
	const documentTypeOptions = ref<ServiceType[]>([]);

	async function getDocumentTypeOptionsAsync() {
		try {
			const response = await axios.get('/api/loandocuments/types/DocumentType');

			documentTypeOptions.value = response.data.results.map((type: ServiceType) => ({
				id: type.id,
				name: type.name
			}));
		} catch (error) {
			console.error('Error fetching document types:', error);
		}
	}

	onMounted(async () => {
		await getDocumentTypeOptionsAsync();
	});

	return {
		documentTypeOptions
	};
}
