import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = { class: "button-text" }



export interface Props {
  loading?: boolean
  text?: string
}

export default /*@__PURE__*/_defineComponent({
  __name: 'OpalButton',
  props: {
    loading: { type: Boolean },
    text: { default: 'Opal' }
  },
  setup(__props: any) {

const props = __props;


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", _mergeProps(_ctx.$attrs, {
    class: ["btn btn-primary", { loading: _ctx.loading }],
    disabled: _ctx.loading,
    onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
  }), [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.text), 1)
  ], 16, _hoisted_1))
}
}

})