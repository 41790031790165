import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "xincome-section debt" }
const _hoisted_2 = { class: "amount" }

import { computed, onMounted, reactive, ref, watch } from "vue";
import { Applicant, LOSApplication } from "@/models/opal";

interface Props {
  applicants: any;
  application: LOSApplication | any;
  primaryApplicant: Applicant | any;
  secondaryApplicant: Applicant | any;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'CreditDebt',
  props: {
    applicants: {},
    application: {},
    primaryApplicant: {},
    secondaryApplicant: {}
  },
  setup(__props: any) {

const props = __props;

const monthlyDebt = computed(() => {
  if (props?.applicants?.selected === 'secondary') {
    return props?.application.debtData["secondary"]?.debtValue
      ? `$${props?.application.debtData["secondary"]?.debtValue?.toLocaleString("en-US")}`
      : "Not Provided"
  }
  else if (props?.applicants?.selected === 'primary') {
    return props?.application.debtData["primary"]?.debtValue
      ? `$${props?.application.debtData["primary"]?.debtValue?.toLocaleString("en-US")}`
      : "Not Provided"
  }
  else {
    return `Not Provided`
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", null, _toDisplayString(monthlyDebt.value), 1),
      _cache[0] || (_cache[0] = _createElementVNode("h5", null, "Total Monthly Debt", -1))
    ])
  ]))
}
}

})