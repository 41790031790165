import { Ref, computed } from 'vue';
import axios from 'axios';
import functions from '@/use/functions';
import { Draw } from '@/models/loans';

export function useModificationRecord(selectedDraw: Ref<Draw | null>) {
	const isProviderMethod = computed(() => {
		const draw = selectedDraw.value;

		if (!draw) {
			return false;
		}

		return draw.certificationMethodId === 1 || draw.certificationMethodId === 2;
	});

	async function confirmModificationRecordGenerationAsync() {
		const draw = selectedDraw.value;

		if (!draw) {
			return;
		}

		functions.openModal({
			title: 'Confirm',
			description: `Are you sure you want to generate a modification record for draw ${draw.cluid}?`,
			buttons: [
				{
					title: 'Generate',
					onClick: async () => await generateModificationRecordAsync()
				},
				{
					title: 'Back',
					onClick: () => functions.closeModal()
				}
			]
		});
	}

	async function generateModificationRecordAsync() {
		const draw = selectedDraw.value;

		if (!draw) {
			return;
		}

		try {
			await axios.post(`/api/loanservice/draws/${draw.id}/modification`);

			functions.openModal({
				title: 'Success',
				description: `A modification record was successfully generated and sent for draw ${draw.cluid}!`
			});
		} catch (error) {
			let statusCode = 'Unknown';

			if (axios.isAxiosError(error) && error.response) {
				statusCode = error.response.status.toString();
			}

			functions.openModal({
				title: 'Error',
				description: `Failed to generate and send a modification record for draw ${draw.cluid}. Response status code: ${statusCode}`
			});
		}
	}

	return {
		isProviderMethod,
		confirmModificationRecordGenerationAsync
	};
}
