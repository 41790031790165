import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["for"]

import { ref } from 'vue';
	import { Form, Field } from 'vee-validate';
	import { SubServicerAddress } from '@/models/loans';

	interface FormField {
		name: string;
		label: string;
		type: string;
		value: any;
		width: string;
	}

	
export default /*@__PURE__*/_defineComponent({
  __name: 'ServicerMailingInformation',
  props: {
    subServicerName: {},
    address: {}
  },
  setup(__props: any) {

	const props = __props;

	const formFields = ref<FormField[]>([
		{
			name: 'subServicerName',
			label: 'Send Payment To',
			type: 'string',
			value: props.subServicerName,
			width: 'col-4'
		},
		{
			name: 'attentionTo',
			label: 'Attention To',
			type: 'string',
			value: props.address?.attentionTo?.name,
			width: 'col-4'
		},
		{
			name: 'address1',
			label: 'Address 1',
			type: 'string',
			value: props.address?.address1,
			width: 'col-6'
		},
		{
			name: 'address2',
			label: 'Address 2',
			type: 'string',
			value: props.address?.address2,
			width: 'col-6'
		},
		{
			name: 'city',
			label: 'City',
			type: 'string',
			value: props.address?.city,
			width: 'col-4'
		},
		{
			name: 'state',
			label: 'State',
			type: 'string',
			value: props.address?.state,
			width: 'col-2'
		},
		{
			name: 'zipCode',
			label: 'Zip Code',
			type: 'string',
			value: props.address?.zipCode,
			width: 'col-3'
		}
	]);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createElementVNode("h3", null, "Servicer Mailing Information", -1)),
    _createVNode(_unref(Form), { class: "form-container" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formFields.value, (field) => {
          return (_openBlock(), _createElementBlock("div", {
            key: field.name,
            class: _normalizeClass(['form-input', field.width])
          }, [
            _createElementVNode("label", {
              for: field.name
            }, _toDisplayString(field.label), 9, _hoisted_1),
            _createVNode(_unref(Field), {
              id: field.name,
              name: field.name,
              type: field.type,
              modelValue: field.value,
              "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
              disabled: "true"
            }, null, 8, ["id", "name", "type", "modelValue", "onUpdate:modelValue"])
          ], 2))
        }), 128))
      ]),
      _: 1
    })
  ], 64))
}
}

})