import OverridesAdditional from "@/components/v2/overrides/OverridesAdditional.vue";
import { defineAsyncComponent } from "vue";

export function useComponentFactory() {
	const componentRegistry = {
		ApplicationTabSLR: defineAsyncComponent(
			() => import("@/components/v2/application/RefiTab.vue")
		),	
		ApplicationTabLOC: defineAsyncComponent(
			() => import("@/components/v2/application/ApplicationTab.vue")
		),
		CreditTabLOC: defineAsyncComponent(
			() => import("@/components/v2/credit/CreditTabLOC.vue")
		),
		CreditTabSLR: defineAsyncComponent(
			() => import("@/components/v2/credit/CreditTabSLR.vue")
		),
		DocumentTabSLR: defineAsyncComponent(
			() => import("@/components/v2/documents/DocumentsTabSLR.vue")
		),
		DocumentTabLOC: defineAsyncComponent(
			() => import("@/components/v2/documents/DocumentsTabLOC.vue")
		),
		IncomeTabLOC: defineAsyncComponent(
			() => import("@/components/v2/income/IncomeTab.vue")
		),
		IncomeTabSLR: defineAsyncComponent(
			() => import("@/components/v2/income/IncomeTab.vue")
		),
		IdentityTabLOC: defineAsyncComponent(
			() => import("@/components/v2/identity/IdentityTab.vue")
		),
		IdentityTabSLR: defineAsyncComponent(
			() => import("@/components/v2/identity/IdentityTab.vue")
		),
		MembershipTabLOC: defineAsyncComponent(
			() => import("@/components/v2/membership/MembershipTab.vue")
		),
		MembershipTabSLR: defineAsyncComponent(
			() => import("@/components/v2/membership/MembershipTab.vue")
		),
		FinalReviewTabLOC: defineAsyncComponent(
			() => import("@/components/v2/final-review/FinalReviewTab.vue")
		),
		FinalReviewTabSLR: defineAsyncComponent(
			() => import("@/components/v2/final-review/FinalReviewTab.vue")
		),
		EvaluationTabLOC: defineAsyncComponent(
			() => import("@/components/v2/evaluations/EvaluationsTabLOC.vue")
		),
		EvaluationTabSLR: defineAsyncComponent(
			() => import("@/components/v2/evaluations/EvaluationsTabLOC.vue")
		),
		EsignTabLOC: defineAsyncComponent(
			() => import("@/components/v2/esign/EsignTabLOC.vue")
		),
		EsignTabSLR: defineAsyncComponent(
			() => import("@/components/v2/esign/EsignTabLOC.vue")
		),
		PricingTabLOC: defineAsyncComponent(
			() => import("@/components/v2/pricing/PricingLOCTab.vue")
		),
		PricingTabSLR: defineAsyncComponent(
			() => import("@/components/v2/pricing/PricingSLRTab.vue")
		),
		OverridesTabLOC: defineAsyncComponent(
			() => import("@/components/v2/overrides/OverridesTabLOC.vue")
		),
		OverridesTabSLR: defineAsyncComponent(
			() => import("@/components/v2/overrides/OverridesTabLOC.vue")
		),
		StipulationsTabLOC: defineAsyncComponent(
			() => import("@/components/v2/stipulations/StipulationsTab.vue")
		),
		StipulationsTabSLR: defineAsyncComponent(
			() => import("@/components/v2/stipulations/StipulationsTab.vue")
		),
		DocumentUploaded: defineAsyncComponent(
			() => import("@/components/v2/documents/DocumentsUploaded.vue")
		),
		DocumentGenerated: defineAsyncComponent(
			() => import("@/components/v2/documents/DocumentsGenerated.vue")
		),
		AddStipFormLOC: defineAsyncComponent(
			() => import("@/components/v2/stipulations/line-of-credit/AddStipulationForm.vue")
		),
		AddStipFormSLR: defineAsyncComponent(
			() => import("@/components/v2/stipulations/refi-loan/AddStipulationForm.vue")
		),
		EvaluationCreditDecisionLOC: defineAsyncComponent(
			() => import("@/components/v2/evaluations/EvaluationsCreditUnionDecision.vue")
		),
		EvaluationCreditDecisionSLR: defineAsyncComponent(
			() => import("@/components/v2/evaluations/refi-evaluations/EvaluationsCreditUnionDecisionRefi.vue")
		),
		OverrideReviewLOC: defineAsyncComponent(
			() => import("@/components/v2/overrides/overrides-loc/OverridesReview.vue")
		),
		OverrideReviewSLR: defineAsyncComponent(
			() => import("@/components/v2/overrides/overrides-refi/OverridesReviewRefi.vue")
		),
		PayoffTabSLR: defineAsyncComponent(
			() => import("@/components/v2/payoff/PayoffTab.vue")
		),
		PayoffTabLOC: defineAsyncComponent(
			() => import("@/components/v2/payoff/PayoffTab.vue")
		),
		OverridesAdditional: defineAsyncComponent(
			() => import("@/components/v2/overrides/OverridesAdditional.vue")
		)
	};

	const createComponent = (identifier: string) => {
		return componentRegistry[identifier] || null;
	};

	return {
		createComponent,
	};
}