import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "evaluation-section"
}
const _hoisted_2 = { class: "evaluation-table" }
const _hoisted_3 = { class: "evaluation-return-values" }
const _hoisted_4 = { class: "evaluation-return-values" }
const _hoisted_5 = { class: "evaluation-return-values" }
const _hoisted_6 = { class: "evaluation-return-values" }
const _hoisted_7 = { class: "evaluation-return-values" }
const _hoisted_8 = { class: "evaluation-return-values" }
const _hoisted_9 = { class: "evaluation-table evaluation-table-bottom" }
const _hoisted_10 = { class: "evaluation-return-values" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = ["title"]
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { class: "evaluation-return-values" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = ["title"]
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { class: "evaluation-return-values" }
const _hoisted_19 = ["id"]

import { computed, ref, onMounted } from "vue";
  import Loader from "@/components/Loader.vue";
  import { Applicant } from "@/models/opal";
  import { useEvaluationsTabStore } from "@/store/store/evaluationsStore";

  interface Props {
    applicants: {
      primary?: Applicant;
      secondary?: Applicant;
    };
    application: {
      creditReport: {
        primary: {
          creditScore: number;
        };
        secondary: {
          creditScore: number;
        };
      };
      data: {
        referenceId: string;
        loanAmount: number;
        applicationStatusId: number;
      };
    };
  }

  
export default /*@__PURE__*/_defineComponent({
  __name: 'EvaluationsDetails',
  props: {
    applicants: {},
    application: {}
  },
  setup(__props: any) {

  const reloadKey = ref(0);

  const store = useEvaluationsTabStore();

  const props = __props;
  const evaluationDetails = ref({});

  const getEvaluationsForApplicant = (applicantId: number) => {
	const detailedEvaluations = evaluationDetails.value[applicantId];
	if (detailedEvaluations) {
	  return detailedEvaluations;
	} else {
    return store.evaluations.filter((evaluation) => evaluation.applicantId === applicantId);
	}
  };

  const applicantList = computed(() => {
    return ["primary", "secondary"]
      .map((key) => props.applicants[key])
      .filter(Boolean);
  });

  onMounted(async () => {
    const referenceId = props.application.data.referenceId;

    store.getEvaluationDetails(referenceId);
  });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "page-content",
    key: reloadKey.value
  }, [
    (!_unref(store).isDataLoaded)
      ? (_openBlock(), _createBlock(Loader, { key: 0 }))
      : _createCommentVNode("", true),
    (_unref(store).isDataLoaded)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["applicant-container", { 'multiple-applicants': applicantList.value.length == 2 }])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(applicantList.value, (applicant, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: `applicant-${index}`,
              class: "applicant-box"
            }, [
              _createElementVNode("h3", null, _toDisplayString(applicant?.applicantTypeName), 1),
              (getEvaluationsForApplicant(applicant.id))
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("table", _hoisted_2, [
                      _cache[5] || (_cache[5] = _createElementVNode("tr", null, [
                        _createElementVNode("th", { class: "evaluation-text" }),
                        _createElementVNode("th", { class: "evaluation-text" }, "Current"),
                        _createElementVNode("th", { class: "evaluation-text" }, "Program Requirements")
                      ], -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getEvaluationsForApplicant(
                applicant.id
              ), (evaluation, index) => {
                        return (_openBlock(), _createElementBlock("tr", { key: index }, [
                          _cache[0] || (_cache[0] = _createElementVNode("td", { class: "evaluation-text" }, "Credit Score", -1)),
                          _createElementVNode("td", {
                            class: _normalizeClass(["evaluation-return-values", { 'red-text': evaluation.isCreditScoreEval }])
                          }, _toDisplayString(evaluation.creditScore), 3),
                          _createElementVNode("td", _hoisted_3, _toDisplayString(evaluation.creditScoreApproved), 1)
                        ]))
                      }), 128)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getEvaluationsForApplicant(
                applicant.id
              ), (evaluation, index) => {
                        return (_openBlock(), _createElementBlock("tr", { key: index }, [
                          _cache[1] || (_cache[1] = _createElementVNode("td", { class: "evaluation-text" }, "DTI", -1)),
                          _createElementVNode("td", {
                            class: _normalizeClass(["evaluation-return-values", { 'red-text': evaluation.isDTIEval }])
                          }, _toDisplayString(evaluation.dti), 3),
                          _createElementVNode("td", _hoisted_4, _toDisplayString(evaluation.dtiApproved ? evaluation.dtiApproved : "N/A"), 1)
                        ]))
                      }), 128)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getEvaluationsForApplicant(
                applicant.id
              ), (evaluation, index) => {
                        return (_openBlock(), _createElementBlock("tr", { key: index }, [
                          _cache[2] || (_cache[2] = _createElementVNode("td", { class: "evaluation-text" }, "Income", -1)),
                          _createElementVNode("td", {
                            class: _normalizeClass(["evaluation-return-values", { 'red-text': evaluation.isIncomeEval }])
                          }, _toDisplayString(evaluation.income), 3),
                          _createElementVNode("td", _hoisted_5, _toDisplayString(evaluation.incomeApproved ? evaluation.incomeApproved : "N/A"), 1)
                        ]))
                      }), 128)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getEvaluationsForApplicant(
                applicant.id
              ), (evaluation, index) => {
                        return (_openBlock(), _createElementBlock("tr", { key: index }, [
                          _cache[3] || (_cache[3] = _createElementVNode("td", { class: "evaluation-text" }, "Employment", -1)),
                          _createElementVNode("td", {
                            class: _normalizeClass(["evaluation-return-values", { 'red-text': evaluation.isEmploymentEval }])
                          }, _toDisplayString(evaluation.employed), 3),
                          _createElementVNode("td", _hoisted_6, _toDisplayString(evaluation.employmentApproved), 1)
                        ]))
                      }), 128)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getEvaluationsForApplicant(
                applicant.id
              ), (evaluation, index) => {
                        return (_openBlock(), _createElementBlock("tr", { key: index }, [
                          _cache[4] || (_cache[4] = _createElementVNode("td", { class: "evaluation-text" }, "App Max Approved Limit", -1)),
                          _createElementVNode("td", _hoisted_7, _toDisplayString(evaluation.locQualifiedAmnt), 1),
                          _createElementVNode("td", _hoisted_8, _toDisplayString(evaluation.locApproved), 1)
                        ]))
                      }), 128))
                    ]),
                    _createElementVNode("table", _hoisted_9, [
                      _cache[9] || (_cache[9] = _createElementVNode("tr", null, [
                        _createElementVNode("th", { class: "evaluation-text" }),
                        _createElementVNode("th", { class: "evaluation-text" }, "Current")
                      ], -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getEvaluationsForApplicant(
                applicant.id
              ), (evaluation, index) => {
                        return (_openBlock(), _createElementBlock("tr", { key: index }, [
                          _cache[6] || (_cache[6] = _createElementVNode("td", { class: "evaluation-text" }, "Credit - Alerts", -1)),
                          _createElementVNode("td", _hoisted_10, [
                            (evaluation.creditEvaluation?.creditAlerts)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(evaluation.creditEvaluation.creditAlerts, (alert, alertIndex) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      key: alertIndex,
                                      class: "credit-ko",
                                      title: alert.description
                                    }, _toDisplayString(alert.code), 9, _hoisted_12))
                                  }), 128))
                                ]))
                              : (_openBlock(), _createElementBlock("span", _hoisted_13, "N/A"))
                          ])
                        ]))
                      }), 128)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getEvaluationsForApplicant(applicant.id), (evaluation, index) => {
                        return (_openBlock(), _createElementBlock("tr", { key: index }, [
                          _cache[7] || (_cache[7] = _createElementVNode("td", { class: "evaluation-text" }, "Credit – KOs", -1)),
                          _createElementVNode("td", _hoisted_14, [
                            (evaluation.creditEvaluation?.creditKOs)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(evaluation.creditEvaluation.creditKOs, (ko, koIndex) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      key: koIndex,
                                      class: "credit-ko",
                                      title: ko.description
                                    }, _toDisplayString(ko.code), 9, _hoisted_16))
                                  }), 128))
                                ]))
                              : (_openBlock(), _createElementBlock("span", _hoisted_17, "N/A"))
                          ])
                        ]))
                      }), 128)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getEvaluationsForApplicant(
                applicant.id
              ), (evaluation, index) => {
                        return (_openBlock(), _createElementBlock("tr", { key: index }, [
                          _cache[8] || (_cache[8] = _createElementVNode("td", { class: "evaluation-text" }, "Pricing", -1)),
                          _createElementVNode("td", _hoisted_18, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(evaluation.creditEvaluation
                    ?.pricing, (pricing, index) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: index,
                                id: `p${index}`
                              }, _toDisplayString(pricing), 9, _hoisted_19))
                            }), 128))
                          ])
                        ]))
                      }), 128))
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ], 2))
      : _createCommentVNode("", true)
  ]))
}
}

})