import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "modal-overlay"
}
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = { class: "modal-header" }
const _hoisted_4 = { class: "header-right" }
const _hoisted_5 = ["value"]
const _hoisted_6 = { class: "modal-body" }
const _hoisted_7 = { class: "report-container" }
const _hoisted_8 = { class: "left-column" }
const _hoisted_9 = { class: "credit-report-details" }
const _hoisted_10 = { class: "textbox-container" }
const _hoisted_11 = { class: "right-column" }
const _hoisted_12 = { class: "liabilities-table" }
const _hoisted_13 = ["onUpdate:modelValue"]
const _hoisted_14 = ["value"]
const _hoisted_15 = { class: "summary-row" }
const _hoisted_16 = { class: "summary-item" }
const _hoisted_17 = { class: "value" }
const _hoisted_18 = { class: "summary-item" }
const _hoisted_19 = { class: "value" }
const _hoisted_20 = { class: "summary-item" }
const _hoisted_21 = { class: "value" }
const _hoisted_22 = { class: "summary-item" }
const _hoisted_23 = { class: "value" }
const _hoisted_24 = { class: "summary-item" }
const _hoisted_25 = { class: "value" }

import { reactive, ref, onMounted } from "vue";
import { useCreditStore } from "@/store/store/creditStore";
import { $api } from "@/services/api1";
import functions from "@/use/functions";
import { useRoute } from "vue-router";
import { CreditHistory } from "@/types/credit";
import { useLoadingStore } from "@/store/store/loadingStore";


export default /*@__PURE__*/_defineComponent({
  __name: 'CreditReportLiabilitiesSLR',
  props: {
  isOpen: {
    type: Boolean,
    default: false,
  },
  isPreviousPull: {
    type: Boolean,
    default: false,
  },
  creditHistoryId: {
    type: Number,
    default: 0,
  },
},
  emits: [
  "close",
  "setLiabilities",
  "getCreditReportData",
  "applicantLiabilityUpdated",
],
  setup(__props, { emit: __emit }) {

const creditStore = useCreditStore();
const creditHistoryId = ref(0);
const route = useRoute();

const props = __props;

const emits = __emit;

const percentageOptions = reactive([
  { label: "0%", value: 0 },
  { label: "50%", value: 50 / 100 },
  { label: "100%", value: 100 / 100 },
]);

const openReportInNewTab = () => {
  const newTab = window.open("", "_blank");
  if (!newTab) {
    functions.openModal({
      title: "Error",
      description: "Please allow popups for this site",
    });
    return;
  }

  const htmlContent = `
      <html>
        <head>
          <title>Credit Report</title>
          <style>
            body {
              font-family: Montserrat, arial, sans-serif;
              margin: 0;
              padding: 20px;
              background-color: #f2f3f5;
            }
              .report-container {
                max-width: 1200px;
                margin: 0 auto;
                padding: 20px;
                background-color: #fff;
                border-radius: 8px;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
              }
                textarea {
                  width: 100%;
                  height: 100%;
                  padding: 15px;
                  font-size: 16px;
                  font-family: Montserrat, arial, sans-serif;
                  border: 1px solid #ccc;
                  border-radius: 4px;
                  resize: none;
                  background-color: #f2f3f5;
                }
          </style>
        </head>
        <body>
          <div class="report-container">
            <textarea readonly>${
              creditStore.CreditSelected(props.isPreviousPull)
                .humanReadableReport
            }</textarea>
          </div>
        </body>
      </html>
    `;

  newTab.document.write(htmlContent);
  newTab.document.close();
};

async function handleSaveChanges() {
  const loadingStore = useLoadingStore();
  loadingStore.setLoading(true);

  try {
    const liabilities = creditStore.CreditSelected(
      props.isPreviousPull
    ).liabilities;
    for (const liability of liabilities) {
      await handleLiabilityPercentageChange(null, liability);
    }
    functions.openModal({
      title: "Success",
      description: "Liabilities updated successfully.",
    });
  } catch (error) {
    console.error("Error during liability update:", error);
    functions.openModal({
      title: "Error",
      description: "There was an issue updating the liability",
    });
  } finally {
    loadingStore.setLoading(false);
  }
}

async function handleLiabilityPercentageChange(event, liability) {
  const updateApplicantLiabilityRequest = {
    applicantId: creditStore.selectedApplicantId,
    applicantLiabilityId: liability.id,
    includePercentage: liability.includePercentage,
  };

  try {
    const updateApplicantLiabilityResponse =
      await $api.applicants.updateApplicantLiability(
        liability.applicantId,
        updateApplicantLiabilityRequest
      );
    if (updateApplicantLiabilityResponse.errors) {
      functions.openModal({
        title: "Error",
        description: "There was an issue updating the liability",
      });
      return;
    }
    const referenceId = route.params.referenceId.toString();
    await creditStore.getCreditReportByReference(
      referenceId,
      {} as CreditHistory,
      false
    );
  } catch (error) {
    console.error("Error during liability update:", error);
  }
}

function closeModal() {
  emits("close", creditHistoryId.value);
}

onMounted(() => {
  const creditHistory = creditStore
    .CreditSelected(props.isPreviousPull)
    .creditHistories.find((a) => a.isSelected);

  if (creditHistory !== undefined) {
    creditHistoryId.value = creditHistory.id;
  }
});

return (_ctx: any,_cache: any) => {
  return (props.isOpen)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[3] || (_cache[3] = _createElementVNode("h2", { class: "modal-title" }, "Most Recent Credit Report & Liabilities", -1)),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("button", {
                class: "close-button",
                onClick: closeModal
              }, "X"),
              _withDirectives(_createElementVNode("select", {
                class: "report-select",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((creditHistoryId).value = $event)),
                onChange: _cache[1] || (_cache[1] = ($event: any) => (
              _unref(creditStore).handleCreditHistoryChange(
                creditHistoryId.value,
                props.isPreviousPull
              )
            ))
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(creditStore).CreditSelected(
                props.isPreviousPull
              ).creditHistories, (history, index) => {
                  return (_openBlock(), _createElementBlock("option", {
                    value: history.id,
                    key: index
                  }, _toDisplayString(history.name), 9, _hoisted_5))
                }), 128))
              ], 544), [
                [_vModelSelect, creditHistoryId.value]
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _withDirectives(_createElementVNode("textarea", {
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((
                    _unref(creditStore).CreditSelected(props.isPreviousPull)
                      .humanReadableReport
                  ) = $event)),
                      readonly: "",
                      disabled: "",
                      class: "custom-textarea"
                    }, "                ", 512), [
                      [_vModelText, 
                    _unref(creditStore).CreditSelected(props.isPreviousPull)
                      .humanReadableReport
                  ]
                    ])
                  ])
                ]),
                _createElementVNode("div", { class: "open-in-new-tab-btn" }, [
                  _createElementVNode("button", {
                    class: "new-tab-btn",
                    onClick: openReportInNewTab
                  }, " Open In New Tab ")
                ])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("table", _hoisted_12, [
                  _cache[4] || (_cache[4] = _createElementVNode("thead", null, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("th", null, "Trade Name"),
                      _createElementVNode("th", null, "Account #"),
                      _createElementVNode("th", null, "Account Type"),
                      _createElementVNode("th", null, "ECOA"),
                      _createElementVNode("th", null, "Balance"),
                      _createElementVNode("th", null, "Monthly Obligation"),
                      _createElementVNode("th", null, "Percentage")
                    ])
                  ], -1)),
                  _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(creditStore).CreditSelected(
                    props.isPreviousPull
                  ).liabilities, (liability) => {
                      return (_openBlock(), _createElementBlock("tr", {
                        key: liability.id
                      }, [
                        _createElementVNode("td", null, _toDisplayString(liability.creditorName), 1),
                        _createElementVNode("td", null, _toDisplayString(liability.accountNumber), 1),
                        _createElementVNode("td", null, _toDisplayString(liability.liabilityTypeName), 1),
                        _createElementVNode("td", null, _toDisplayString(liability.accountOwnerTypeShortCode), 1),
                        _createElementVNode("td", null, _toDisplayString(liability.currentBalanceAmount), 1),
                        _createElementVNode("td", null, _toDisplayString(liability.debtAmount), 1),
                        _createElementVNode("td", null, [
                          _withDirectives(_createElementVNode("select", {
                            "onUpdate:modelValue": ($event: any) => ((liability.includePercentage) = $event)
                          }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(percentageOptions, (option) => {
                              return (_openBlock(), _createElementBlock("option", {
                                key: option.value,
                                value: option.value
                              }, _toDisplayString(option.label), 9, _hoisted_14))
                            }), 128))
                          ], 8, _hoisted_13), [
                            [
                              _vModelSelect,
                              liability.includePercentage,
                              void 0,
                              { number: true }
                            ]
                          ])
                        ])
                      ]))
                    }), 128))
                  ])
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    _cache[5] || (_cache[5] = _createElementVNode("span", { class: "label" }, "Stated Income", -1)),
                    _createElementVNode("span", _hoisted_17, _toDisplayString(_unref(creditStore).CreditSelected(props.isPreviousPull)
                    .statedIncomeDisplay), 1)
                  ]),
                  _createElementVNode("div", _hoisted_18, [
                    _cache[6] || (_cache[6] = _createElementVNode("span", { class: "label" }, "Verified Income", -1)),
                    _createElementVNode("span", _hoisted_19, _toDisplayString(_unref(creditStore).CreditSelected(props.isPreviousPull)
                    .verifiedIncomeDisplay), 1)
                  ]),
                  _createElementVNode("div", _hoisted_20, [
                    _cache[7] || (_cache[7] = _createElementVNode("span", { class: "label" }, "Stated DTI", -1)),
                    _createElementVNode("span", _hoisted_21, _toDisplayString(_unref(creditStore).CreditSelected(props.isPreviousPull)
                    .statedDTIDisplay), 1)
                  ]),
                  _createElementVNode("div", _hoisted_22, [
                    _cache[8] || (_cache[8] = _createElementVNode("span", { class: "label" }, "Verified DTI", -1)),
                    _createElementVNode("span", _hoisted_23, _toDisplayString(_unref(creditStore).CreditSelected(props.isPreviousPull)
                    .verifiedDTIDisplay), 1)
                  ]),
                  _createElementVNode("div", _hoisted_24, [
                    _cache[9] || (_cache[9] = _createElementVNode("span", { class: "label" }, "Total Monthly Obligation", -1)),
                    _createElementVNode("span", _hoisted_25, _toDisplayString(_unref(creditStore).CreditSelected(props.isPreviousPull)
                    .monthlyDebtDisplay), 1)
                  ])
                ]),
                _createElementVNode("button", {
                  class: "save-button",
                  onClick: handleSaveChanges
                }, " Save Changes ")
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})