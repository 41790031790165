export interface Tag {
  id: number;
  tagTypeId: number;
  tagStatusId: number;
  tagStatusName: string;
}

export enum TagTypes {
  FinalReview = 11,
  DocReview = 13,
}

export class TagTypeIds {
  public static get FinalReview(): number {
    return TagTypes.FinalReview;
  }
  public static get DocReview(): number {
    return TagTypes.DocReview;
  }
}
