import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "detail" }
const _hoisted_2 = { class: "xdetails-table" }
const _hoisted_3 = { class: "xdetails-col doc-grid" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "xtext text-wrap" }
const _hoisted_6 = { class: "col" }
const _hoisted_7 = { class: "xtext" }
const _hoisted_8 = { class: "col" }
const _hoisted_9 = { class: "col" }
const _hoisted_10 = { class: "col" }
const _hoisted_11 = { class: "xtext" }
const _hoisted_12 = { class: "detail-container" }
const _hoisted_13 = { class: "row center" }
const _hoisted_14 = ["name", "id"]

import { computed, ref } from "vue";
  import IncomeDocumentStatus from "./IncomeDocumentStatus.vue";
  import IncomeDocumentYear from "@/components/income/IncomeDocumentYear.vue";
  import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
  import PdfViewer from "@/components/PdfViewer.vue";
  import { LOSApplication } from "@/models/opal";
  import { useIncomeTabStore } from "@/store/store/incomeTabStore";

  interface IncomeDocument {
  applicantId: number;
  applicantDocumentId: string;
  year: number | null;
  documentYear: string | null;
  documentId: number;
  documentName: string;
  documentType?: any;
  showDocument?: boolean;
}

  interface Props {
    applicantDocumentId: number;
    incomeDocument: IncomeDocument;
    incomeDocuments: IncomeDocument[];
    application: LOSApplication;
    canEdit: boolean;
  }

  
export default /*@__PURE__*/_defineComponent({
  __name: 'IncomeDocumentHeader',
  props: {
    applicantDocumentId: {},
    incomeDocument: {},
    incomeDocuments: {},
    application: {},
    canEdit: { type: Boolean }
  },
  emits: [
    "updateIncomeDocument",
    "toggleDetails",
    "getComments",
    "documentYearChanged",
    "documentStatusChanged",
  ],
  setup(__props: any, { emit: __emit }) {

  const incomeTabStore = useIncomeTabStore();

  const emit = __emit;

  const props = __props;
  const incomeDoc = computed(() => props.incomeDocument);
  const isDocumentOpen = ref(false);

  const buttonText = computed(() => {
  return isDocumentOpen.value ? 'Close' : 'View';
});

  const selectedIncomeDocument = computed(() => {
    return incomeTabStore.incomeDocuments.find(
      (doc) => doc.applicantDocumentId === props.applicantDocumentId
    ) || props.incomeDocument;
  });

  const toggleIcon = computed(() => {
    return incomeTabStore.isIncomeDetailShowing(selectedIncomeDocument.value)
      ? "fa-solid fa-circle-chevron-up"
      : "fa-solid fa-circle-chevron-down";
  });

  function toggleDocumentView() {
    isDocumentOpen.value = !isDocumentOpen.value;
    incomeDoc.value.showDocument = !incomeDoc.value.showDocument;
  }

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", null, [
      _createElementVNode("fieldset", null, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "xname" }, "Document Name", -1)),
              _createElementVNode("div", _hoisted_5, _toDisplayString(selectedIncomeDocument.value.documentName), 1)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _cache[5] || (_cache[5] = _createElementVNode("div", { class: "xname" }, "Document Type", -1)),
              _createElementVNode("div", _hoisted_7, _toDisplayString(selectedIncomeDocument.value.documentType?.name), 1)
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(IncomeDocumentYear, {
                applicantDocumentId: _ctx.applicantDocumentId,
                incomeDocument: incomeDoc.value,
                incomeDocuments: _ctx.incomeDocuments,
                onDocumentYearChanged: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('documentYearChanged', $event))),
                canEdit: _ctx.canEdit
              }, null, 8, ["applicantDocumentId", "incomeDocument", "incomeDocuments", "canEdit"])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _cache[6] || (_cache[6] = _createElementVNode("div", { class: "xname" }, "Document Status ", -1)),
              _createVNode(IncomeDocumentStatus, {
                applicantDocumentId: _ctx.applicantDocumentId,
                application: _ctx.application,
                canEdit: _ctx.canEdit,
                incomeDocument: incomeDoc.value,
                incomeDocuments: _unref(incomeTabStore).incomeDocuments,
                onGetComments: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('getComments'))),
                onDocumentStatusChanged: _cache[2] || (_cache[2] = ($event: any) => (
                  _ctx.$emit('documentStatusChanged', $event)
                ))
              }, null, 8, ["applicantDocumentId", "application", "canEdit", "incomeDocument", "incomeDocuments"])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", {
                  onClick: toggleDocumentView,
                  class: "view-link"
                }, _toDisplayString(buttonText.value), 1)
              ])
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      (incomeDoc.value.showDocument)
        ? (_openBlock(), _createBlock(PdfViewer, {
            key: 0,
            docId: `${incomeDoc.value.documentId}`
          }, null, 8, ["docId"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("a", {
        class: "toggle",
        name: `${selectedIncomeDocument.value.documentName}`,
        id: `${selectedIncomeDocument.value.documentId}_toggle`,
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('toggleDetails')))
      }, [
        _createVNode(_unref(FontAwesomeIcon), {
          class: "icon toggle",
          icon: toggleIcon.value
        }, null, 8, ["icon"])
      ], 8, _hoisted_14)
    ])
  ]))
}
}

})