import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, Teleport as _Teleport, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { class: "xpage-history-container" }
const _hoisted_5 = { class: "xpage-history" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = {
  class: "column-item center-text",
  style: {"width":"auto","white-space":"nowrap"}
}
const _hoisted_8 = {
  class: "column-item center-text",
  style: {"width":"auto","white-space":"nowrap"}
}
const _hoisted_9 = {
  class: "column-item center-text",
  style: {"width":"auto","white-space":"nowrap"}
}
const _hoisted_10 = {
  class: "column-item center-text",
  style: {"width":"auto","white-space":"nowrap"}
}
const _hoisted_11 = { key: 3 }

import { computed, ref, onMounted } from "vue";
import store from "@/store";
import axios from "axios";
import PdfViewerModal from "@/components/PdfViewerModal.vue";
import FeedbackBanner, {
  FeedbackData,
} from "@/components/shared/FeedbackBanner.vue";
import { Permissions, PermissionLevels } from "@/models/permissions";
import PermissionsWrapper from "@/components/wrappers/PermissionsWrapper.vue";
import PermissionsButton from "@/components/wrappers/PermissionsButton.vue";

interface Props {
  isTabActive: boolean | null;
  applicants: any | null;
  store?: any | null;
  application: any | null;
  generateLegalPacket: any | null;
  documentDate: any | null;
  commentData: Comment[];
  canEdit?: boolean | null;
}

interface Comment {
  userDisplayName: string;
  lastChangedAt: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DocumentsGenerated',
  props: {
    isTabActive: { type: [Boolean, null] },
    applicants: {},
    store: {},
    application: {},
    generateLegalPacket: {},
    documentDate: {},
    commentData: {},
    canEdit: { type: [Boolean, null] }
  },
  emits: ["toggle", "getPdfDocument"],
  setup(__props: any, { emit: __emit }) {

const emit = __emit;

const props = __props;

const shouldShowBanner = ref(false);

const canGenerateLegalPacket = computed(() => {
  return [3].includes(props.application.data.applicationStatusId);
});

const handleGenerateClick = () => {
  generateLegalPacket();
  shouldShowBanner.value = !shouldShowBanner.value;
};

const feedbackDataArray = ref<FeedbackData[]>([]);

const closeBanner = () => {
  feedbackDataArray.value = [];
};

const sortDocuments = computed(() => {
  return [...props.application.documents]
    .filter(
      (document) =>
        document.documentStatusTypeName === "Generated" ||
        document?.documentType?.name === "Signed Legal Packet"
    )
    .sort(
      (a, b) =>
        new Date(b.createdAtUtc).getTime() - new Date(a.createdAtUtc).getTime()
    );
});

const isDisabled = ref(false);

const timeRemaining = ref(0);

const extractFileName = (encodedFileName: string): string => {
  const decodedFileName = decodeURIComponent(encodedFileName);
  const parts = decodedFileName.split("\\");

  const firstPrefix = "AA";
  const secondPrefix = "UG";

  let matchedPart = parts.find((part) => part.startsWith(firstPrefix));

  if (!matchedPart) {
    matchedPart = parts.find((part) => part.startsWith(secondPrefix));
  }

  return matchedPart || `System Generated`;
};

async function generateLegalPacket() {
  console.log("generateLegalPacket clicked");
  isDisabled.value = true;
  try {
    const applicantDetails = [
      {
        applicantTypeId: props.applicants.primary.applicantTypeId,
        electronicCommunicationStamp: props.application.data.submittedAtUtc,
        mobileCommunicationStamp: props.application.data.submittedAtUtc,
        termsAndConditionsStamp: props.application.data.submittedAtUtc,
      },
    ];

    if (
      props.applicants.secondary &&
      props.applicants.secondary.applicantTypeId
    ) {
      applicantDetails.push({
        applicantTypeId: props.applicants.secondary.applicantTypeId,
        electronicCommunicationStamp: props.application.data.submittedAtUtc,
        mobileCommunicationStamp: props.application.data.submittedAtUtc,
        termsAndConditionsStamp: props.application.data.submittedAtUtc,
      });
    }

    const payload = {
      RequestorUserId: store.state.oidcStore.user.sub,
      ApplicantDetails: applicantDetails,
    };

    const response = await axios.post(
      `/api/esign/${props.application.data.id}/CreateLegalPacketEsignEnvelope`,
      payload
    );

    if (response.status >= 200 || response.status < 300) {
      feedbackDataArray.value.push({
        status: response.status,
        title: "Success",
        message: `Legal packet successfully generated and posted for ${props.application.data.id}`,
        type: "success",
      });
      console.log("Legal packet successfully generated");

      setTimeout(() => {
        location.reload();
      }, 1000);
    } else {
      console.log("Unexpected Response Status", response.status);
      throw new Error("New Error: Error generating legal packet");
    }
  } catch (error: any) {
    feedbackDataArray.value.push({
      status: error.response ? error.response.status : 500,
      title: "Error",
      message: "An error occurred while generating the legal packet",
      type: "error",
    });
  } finally {
    isDisabled.value = false;
  }
}

// this is a Coconut..do not touch
function calculateTimeDifference(
  documentCreatedAtUtc: string,
  commentLastChangedAt: string
): number | null {
  const documentCreatedAt = new Date(documentCreatedAtUtc + "Z");
  const commentLastChanged = new Date(commentLastChangedAt + "Z");
  const differenceInMilliseconds =
    commentLastChanged.getTime() - documentCreatedAt.getTime();
  const differenceInSeconds = differenceInMilliseconds / 1000;
  return differenceInSeconds < 10 ? differenceInSeconds : null;
}

function getUserDisplayName(): string {
  return "System Generated";
}

onMounted(() => {
  timeRemaining.value = 0;
  isDisabled.value = false;
});

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    (feedbackDataArray.value.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (shouldShowBanner.value)
            ? (_openBlock(), _createBlock(_Teleport, {
                key: 0,
                to: "#feedback-banner-target"
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(feedbackDataArray.value, (item, index) => {
                  return (_openBlock(), _createBlock(FeedbackBanner, {
                    key: `item-${index}`,
                    feedbackDataArray: feedbackDataArray.value,
                    type: item.type,
                    onCloseBanner: closeBanner
                  }, null, 8, ["feedbackDataArray", "type"]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (props.application.data.applicationStatusId === 3)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[1] || (_cache[1] = _createElementVNode("h3", null, "Generate Documents", -1)),
          (canGenerateLegalPacket.value)
            ? (_openBlock(), _createBlock(PermissionsButton, {
                key: 0,
                permissionIds: [_unref(Permissions).DocumentProcessing],
                permissionLevel: _unref(PermissionLevels).Update,
                showDisabledIfNotVisible: true,
                disabled: isDisabled.value || !_ctx.canEdit,
                onClick: handleGenerateClick,
                class: "generateButton",
                style: {"margin-bottom":"20px"}
              }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode(" Generate Legal Packet ")
                ])),
                _: 1
              }, 8, ["permissionIds", "permissionLevel", "disabled"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (
        _ctx.application.documents.length > 0 &&
        _ctx.application.documents.some(
          (doc) => doc.documentStatusTypeName === 'Generated'
        )
      )
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(PdfViewerModal),
          _createVNode(PermissionsWrapper, {
            permissionIds: [_unref(Permissions).DocumentProcessing],
            permissionLevel: _unref(PermissionLevels).Read
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("table", _hoisted_5, [
                  _cache[3] || (_cache[3] = _createElementVNode("th", { class: "column-title" }, [
                    _createElementVNode("span", null, "Document Name")
                  ], -1)),
                  _cache[4] || (_cache[4] = _createElementVNode("th", { class: "column-title" }, [
                    _createElementVNode("span", null, "Created On")
                  ], -1)),
                  _cache[5] || (_cache[5] = _createElementVNode("th", { class: "column-title" }, [
                    _createElementVNode("span", null, "Created By User")
                  ], -1)),
                  _cache[6] || (_cache[6] = _createElementVNode("th", { class: "column-title" }, [
                    _createElementVNode("span", null, "View & Download")
                  ], -1)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sortDocuments.value, (document, index) => {
                    return (_openBlock(), _createElementBlock("tr", {
                      class: "xclickable",
                      key: index,
                      onClick: ($event: any) => (
                _ctx.$emit('getPdfDocument', {
                  name: document.name + document.fileExtension,
                  document: document.documentId,
                  splitting: false,
                })
              )
                    }, [
                      _createElementVNode("td", _hoisted_7, [
                        _createElementVNode("span", null, _toDisplayString(document.documentType.name), 1)
                      ]),
                      _createElementVNode("td", _hoisted_8, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.documentDate(`${document.createdAtUtc}Z`)), 1)
                      ]),
                      _createElementVNode("td", _hoisted_9, [
                        _createElementVNode("span", null, _toDisplayString(getUserDisplayName()), 1)
                      ]),
                      _createElementVNode("td", _hoisted_10, [
                        _createElementVNode("div", null, [
                          _createElementVNode("span", null, [
                            _createTextVNode(_toDisplayString(extractFileName(document.name)) + " ", 1),
                            _createVNode(_component_font_awesome_icon, {
                              class: "download-icon",
                              icon: ['fas', 'cloud-arrow-down']
                            })
                          ])
                        ])
                      ]),
                      _cache[2] || (_cache[2] = _createElementVNode("td", { class: "arrow" }, [
                        _createElementVNode("i", { class: "icon-right-dir" })
                      ], -1))
                    ], 8, _hoisted_6))
                  }), 128))
                ])
              ])
            ]),
            _: 1
          }, 8, ["permissionIds", "permissionLevel"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_11, "This application has 0 documents"))
  ]))
}
}

})