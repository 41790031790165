import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, vShow as _vShow } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "xpage-content-page",
  id: "xpricing-tab"
}
const _hoisted_2 = { class: "xtitlearea" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { autocomplete: "off" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "xradio" }
const _hoisted_9 = ["value", "checked", "onInput"]
const _hoisted_10 = { class: "xfields" }
const _hoisted_11 = { class: "xfield" }
const _hoisted_12 = ["value"]
const _hoisted_13 = { class: "xfield" }
const _hoisted_14 = ["value"]
const _hoisted_15 = { class: "xfield" }
const _hoisted_16 = ["onUpdate:modelValue"]
const _hoisted_17 = { class: "xfield" }
const _hoisted_18 = ["onUpdate:modelValue"]
const _hoisted_19 = ["value"]
const _hoisted_20 = { class: "xfield" }
const _hoisted_21 = ["onUpdate:modelValue"]
const _hoisted_22 = { class: "xfield" }
const _hoisted_23 = ["value"]
const _hoisted_24 = { class: "xfield" }
const _hoisted_25 = { class: "xfield" }
const _hoisted_26 = { class: "xfield" }
const _hoisted_27 = { class: "xfield" }
const _hoisted_28 = ["disabled"]
const _hoisted_29 = ["value", "selected", "onChange"]
const _hoisted_30 = ["disabled"]
const _hoisted_31 = { class: "xfield" }
const _hoisted_32 = ["disabled"]
const _hoisted_33 = ["value"]
const _hoisted_34 = ["value"]
const _hoisted_35 = {
  key: 1,
  class: "xwarningbox"
}

import { $api } from "@/services/api1";
import ApplicationComments from "@/components/ApplicationComments.vue";
import {
  ref,
  watch,
  reactive,
  onMounted,
  computed,
} from "vue";
import usePaymentOptions from "@/use/paymentOptions";
import useFormatter from "@/use/formatter";
import functions from "@/use/functions";
import { VariablePricing } from "@/types/pricing";
import { Permissions, PermissionLevels } from "@/models/permissions";
import PermissionsButton from "../wrappers/PermissionsButton.vue";

interface Props {
  loading?: boolean;
  isTabActive: boolean;
  disabled: boolean;
  commentsId: any;
  commentsComponentKey: any;
  applicants: any;
  application: any;
  store?: any;
  lenderProgramName: string;
  canEdit: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PricingTab',
  props: {
    loading: { type: Boolean },
    isTabActive: { type: Boolean },
    disabled: { type: Boolean, default: false },
    commentsId: {},
    commentsComponentKey: {},
    applicants: {},
    application: {},
    store: {},
    lenderProgramName: {},
    canEdit: { type: Boolean }
  },
  emits: [
  "submitForm",
  "toggle",
  "getLoanConfig",
  "update:loanConfig.ratePlanId",
  "getComments",
],
  setup(__props: any, { emit: __emit }) {

const emit = __emit;

const variablePricing = ref<VariablePricing>({
  margin: "0.0%",
  floor: "0.0%",
  ceiling: "0.0%",
});

let info = reactive({} as any);
let loanConfig = reactive({} as any);

const getComments = ref<boolean>(false);
const props = __props;

const { formatMoney } = useFormatter();
const { getPaymentOptionHeader } = usePaymentOptions();
const commentData = computed(() => props.application.commentData);

const isWarningBoxShown = computed(() => {
  return !(
    props?.application &&
    props?.application?.ratePlanData &&
    props?.application?.ratePlanData?.length >= 1
  );
});

const lenderProgram = computed(() => props.lenderProgramName);
const cancelBtnClicked = ref<boolean>(false);
const isDisabled = ref(props.disabled);

const programAltCode = computed(() => {
  const result: Record<number, string> = {};
  props.application?.ratePlanData?.forEach((plan) => {
    if (plan && plan?.id && plan?.altCode) {
      result[plan.id] = plan.altCode;
    }
  });

  return result;
});

const onEditClick = () => {
  if (isDisabled.value) {
    cancelBtnClicked.value = false;
    emit("toggle");
  } else {
    cancelBtnClicked.value = true;
  }
};

function handleRadioInput(event: any) {
  info.selectedRatePlanId = event.id;
  info.selectedRatePlan = props?.application?.ratePlanData?.find(
    (a: any) => a.id === event.id
  );
}

function handleRepaymentSelection(repayment: any) {
  info.selectedRepaymentOption = repayment;
}

function setLoanConfig() {
  info.selectedRatePlanId = props?.application?.loanConfigData?.ratePlanId;
  info.selectedRepaymentTypeId =
    props?.application?.loanConfigData?.repaymentTypeId;
}

async function updateLoanConfiguration(event: any) {
  event.preventDefault();
  event.stopPropagation();
  // Get updated selected rate plan
  const selectedRatePlan = props?.application?.ratePlanData?.find(
    (a: any) => a.id === info.selectedRatePlanId
  );
  // Get updated selected rate plan info
  let loanConfigRequest = {
    applicationId: props?.application?.data?.id,
    selectedRatePlanId: selectedRatePlan.id,
    selectedByApplicantId: loanConfig.selectedByApplicantId,
    repaymentTypeId: info.selectedRepaymentTypeId,
  };
  let response = await $api.applications.addOrUpdateAppLoanConfig(
    loanConfigRequest
  );
  if (response.ratePlanId) {
    emit("toggle");
    emit("getLoanConfig");
    getLoanConfiguration();
    getComments.value = true;
  } else {
    functions.openModal({
      title: "Error",
      description: "There was an issue saving the loan configuration",
    });
    setLoanConfig();
  }
}

async function getLoanConfiguration() {
  if (!props?.application?.loanConfigData.applicationId) {
    const response = await $api.applications.getAppLoanConfig(
      props?.application?.data?.id
    );
    if (response.applicationId) {
      loanConfig = Object.assign({}, response);
    }
  } else {
    loanConfig = Object.assign({}, props?.application?.loanConfigData);
  }
}

async function getPricingTier() {
  const variablePricingTier = 2;

  try {
    var ratePlan = props?.application.ratePlanData?.find(
      (r) => r.rateTypeId == variablePricingTier
    );

    if (!ratePlan) {
      console.log(
        `Failed to get pricing tier: No rate plan found for application id: ${props.application.data.id}`
      );
      return;
    }

    let response = await $api.pricing.getPricingTier(
      ratePlan?.pricingTierId,
      props?.application?.data?.id
    );

    if (!response) {
      console.log("Failed to get pricing tier: No response");
      return;
    }

    variablePricing.value = response;
  } catch (error) {
    console.log("Failed to get pricing tier: ", error);
  }
}

async function initializeTab() {
  await getPricingTier();
  await getLoanConfiguration();
  setLoanConfig();
}

watch(
  () => props?.isTabActive,
  async () => {
    if (props?.isTabActive) {
      // re/init on tab select
      await initializeTab();
    }
  }
);

watch(
  () => info.selectedRatePlanId,
  () => {
    // reset the repayment type if it's not the original
    info.selectedRepaymentOption = "";
    info.newRepayment = "";
  }
);

onMounted(async () => {
  // init on page reload where tab is initially selected
  await initializeTab();
});

return (_ctx: any,_cache: any) => {
  return (_ctx.isTabActive)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (!isWarningBoxShown.value && _ctx.application?.ratePlanData?.length >= 1)
            ? (_openBlock(), _createBlock(PermissionsButton, {
                key: 0,
                permissionIds: [_unref(Permissions).Pricing],
                permissionLevel: _unref(PermissionLevels).Update,
                disabled: !_ctx.canEdit,
                showDisabledIfNotVisible: true,
                class: "xedit",
                onClick: onEditClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.disabled ? "Edit" : "Cancel"), 1)
                ]),
                _: 1
              }, 8, ["permissionIds", "permissionLevel", "disabled"]))
            : _createCommentVNode("", true),
          _cache[7] || (_cache[7] = _createElementVNode("h2", null, "Pricing", -1)),
          _createVNode(ApplicationComments, {
            type: "pricing",
            id: _ctx.commentsId,
            comments: commentData.value
          }, null, 8, ["id", "comments"])
        ]),
        (_ctx.application?.ratePlanData?.length >= 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("form", _hoisted_4, [
                _createElementVNode("fieldset", { disabled: _ctx.disabled }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.application.ratePlanData, (ratePlan, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "xform-section",
                      key: index
                    }, [
                      (_ctx.disabled)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                            _createElementVNode("h3", null, _toDisplayString(ratePlan.rateTypeName) + " Rate - " + _toDisplayString(ratePlan.id === _unref(info).selectedRatePlanId
                    ? "Selected"
                    : "Not Selected"), 1)
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                            _createElementVNode("label", _hoisted_8, [
                              _createElementVNode("h3", null, _toDisplayString(ratePlan.rateTypeName) + " Rate", 1),
                              _createElementVNode("input", {
                                type: "radio",
                                value: ratePlan.id,
                                checked: _unref(info).selectedRatePlanId === ratePlan.id,
                                onInput: ($event: any) => (handleRadioInput(ratePlan))
                              }, null, 40, _hoisted_9),
                              _cache[8] || (_cache[8] = _createElementVNode("span", null, null, -1))
                            ])
                          ])),
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("div", _hoisted_11, [
                          _cache[9] || (_cache[9] = _createElementVNode("label", null, "APR", -1)),
                          _createElementVNode("input", {
                            disabled: "",
                            type: "text",
                            value: ratePlan.apr ? `${ratePlan.apr}%` : 'Not Provided'
                          }, null, 8, _hoisted_12)
                        ]),
                        _createElementVNode("div", _hoisted_13, [
                          _cache[10] || (_cache[10] = _createElementVNode("label", null, "Rate", -1)),
                          _createElementVNode("input", {
                            disabled: "",
                            type: "text",
                            value: ratePlan.rate ? `${ratePlan.rate}%` : 'Not Provided'
                          }, null, 8, _hoisted_14)
                        ]),
                        _createElementVNode("div", _hoisted_15, [
                          _cache[12] || (_cache[12] = _createElementVNode("label", null, "Rate Overriden", -1)),
                          _withDirectives(_createElementVNode("select", {
                            disabled: "",
                            "onUpdate:modelValue": ($event: any) => ((ratePlan.rateOverriden) = $event)
                          }, _cache[11] || (_cache[11] = [
                            _createElementVNode("option", { value: "true" }, "True", -1),
                            _createElementVNode("option", { value: "false" }, "False", -1)
                          ]), 8, _hoisted_16), [
                            [_vModelSelect, ratePlan.rateOverriden]
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_17, [
                          _cache[13] || (_cache[13] = _createElementVNode("label", null, "Rate Type", -1)),
                          _withDirectives(_createElementVNode("select", {
                            disabled: "",
                            "onUpdate:modelValue": ($event: any) => ((ratePlan.rateTypeId) = $event)
                          }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.state.types['Rates']
                      ?.results, (rates, index) => {
                              return (_openBlock(), _createElementBlock("option", {
                                key: index,
                                value: rates.id
                              }, _toDisplayString(rates.name), 9, _hoisted_19))
                            }), 128))
                          ], 8, _hoisted_18), [
                            [_vModelSelect, ratePlan.rateTypeId]
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_20, [
                          _cache[14] || (_cache[14] = _createElementVNode("label", null, "Repayment Term", -1)),
                          _withDirectives(_createElementVNode("input", {
                            disabled: "",
                            type: "text",
                            "onUpdate:modelValue": ($event: any) => ((ratePlan.repaymentTerm) = $event)
                          }, null, 8, _hoisted_21), [
                            [_vModelText, ratePlan.repaymentTerm]
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_22, [
                          _cache[15] || (_cache[15] = _createElementVNode("label", null, "Program Alt Code", -1)),
                          _createElementVNode("input", {
                            disabled: "",
                            type: "text",
                            value: `${programAltCode.value[ratePlan.id]} - ${lenderProgram.value}`
                          }, null, 8, _hoisted_23)
                        ]),
                        _withDirectives(_createElementVNode("div", _hoisted_24, [
                          _cache[16] || (_cache[16] = _createElementVNode("label", null, "Margin", -1)),
                          _withDirectives(_createElementVNode("input", {
                            disabled: "",
                            type: "text",
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((variablePricing.value.margin) = $event))
                          }, null, 512), [
                            [_vModelText, variablePricing.value.margin]
                          ])
                        ], 512), [
                          [_vShow, ratePlan.rateTypeId == 2]
                        ]),
                        _withDirectives(_createElementVNode("div", _hoisted_25, [
                          _cache[17] || (_cache[17] = _createElementVNode("label", null, "Floor", -1)),
                          _withDirectives(_createElementVNode("input", {
                            disabled: "",
                            type: "text",
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((variablePricing.value.floor) = $event))
                          }, null, 512), [
                            [_vModelText, variablePricing.value.floor]
                          ])
                        ], 512), [
                          [_vShow, ratePlan.rateTypeId == 2]
                        ]),
                        _withDirectives(_createElementVNode("div", _hoisted_26, [
                          _cache[18] || (_cache[18] = _createElementVNode("label", null, "Ceiling", -1)),
                          _withDirectives(_createElementVNode("input", {
                            disabled: "",
                            type: "text",
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((variablePricing.value.ceiling) = $event))
                          }, null, 512), [
                            [_vModelText, variablePricing.value.ceiling]
                          ])
                        ], 512), [
                          [_vShow, ratePlan.rateTypeId == 2]
                        ]),
                        _createElementVNode("div", _hoisted_27, [
                          _cache[21] || (_cache[21] = _createElementVNode("label", null, "Repayment", -1)),
                          (ratePlan.id === _unref(info).selectedRatePlanId)
                            ? _withDirectives((_openBlock(), _createElementBlock("select", {
                                key: 0,
                                disabled: 
                    _ctx.disabled ||
                    (!_ctx.disabled && ratePlan.id !== _unref(info).selectedRatePlanId)
                  ,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(info).selectedRepaymentTypeId) = $event))
                              }, [
                                _cache[19] || (_cache[19] = _createElementVNode("option", { value: "" }, "Repayment Type", -1)),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(ratePlan.repayments, (repayment, index) => {
                                  return (_openBlock(), _createElementBlock("option", {
                                    key: index,
                                    value: repayment.repaymentTypeId,
                                    selected: _unref(info).selectedRepaymentOption === repayment,
                                    onChange: ($event: any) => (handleRepaymentSelection(repayment))
                                  }, _toDisplayString(_unref(getPaymentOptionHeader)(repayment.repaymentTypeName)) + " " + _toDisplayString(_unref(formatMoney)(repayment.monthlyInSchoolPayment)) + " In School - " + _toDisplayString(_unref(formatMoney)(repayment.monthlyPostSchoolPayment)) + " Post Graduation ", 41, _hoisted_29))
                                }), 128))
                              ], 8, _hoisted_28)), [
                                [_vModelSelect, _unref(info).selectedRepaymentTypeId]
                              ])
                            : _withDirectives((_openBlock(), _createElementBlock("select", {
                                key: 1,
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(info).newRepayment) = $event)),
                                disabled: 
                    _ctx.disabled ||
                    (!_ctx.disabled && ratePlan.id !== _unref(info).selectedRatePlanId)
                  
                              }, _cache[20] || (_cache[20] = [
                                _createElementVNode("option", { value: "" }, "Repayment Type", -1)
                              ]), 8, _hoisted_30)), [
                                [_vModelSelect, _unref(info).newRepayment]
                              ])
                        ])
                      ])
                    ]))
                  }), 128)),
                  _createElementVNode("div", _hoisted_31, [
                    _cache[22] || (_cache[22] = _createElementVNode("label", null, "Selected By", -1)),
                    _withDirectives(_createElementVNode("select", {
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(loanConfig).selectedByApplicantId) = $event)),
                      required: "",
                      disabled: _ctx.disabled
                    }, [
                      _createElementVNode("option", {
                        value: _ctx.applicants['primary'].id
                      }, _toDisplayString(_ctx.applicants["primary"].fullName) + " - " + _toDisplayString(_ctx.applicants["primary"].applicantTypeName), 9, _hoisted_33),
                      (_ctx.applicants['secondary'])
                        ? (_openBlock(), _createElementBlock("option", {
                            key: 0,
                            value: _ctx.applicants['secondary'].id
                          }, _toDisplayString(_ctx.applicants["secondary"].fullName) + " - " + _toDisplayString(_ctx.applicants["secondary"].applicantTypeName), 9, _hoisted_34))
                        : _createCommentVNode("", true)
                    ], 8, _hoisted_32), [
                      [_vModelSelect, _unref(loanConfig).selectedByApplicantId]
                    ])
                  ])
                ], 8, _hoisted_5),
                (!_ctx.disabled)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: "submit",
                      type: "submit",
                      onClick: _cache[6] || (_cache[6] = ($event: any) => (updateLoanConfiguration($event)))
                    }, " Submit "))
                  : _createCommentVNode("", true)
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_35, " This application does not have any rate plans in the database "))
      ]))
    : _createCommentVNode("", true)
}
}

})