import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import '@/assets/css/scrollbar.css';
	import { ref, onMounted, watch } from 'vue';
	import axios from 'axios';
	import { FieldSchema } from '@/models/form';
	import { School } from '@/models/loans';
	import BaseFormField from '@/components/form-fields/BaseFormField.vue';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'SchoolSearchSelect',
  props: {
    disabled: { type: Boolean },
    initialSchool: {},
    programTypeId: {},
    productTypeId: {},
    lenderId: {}
  },
  emits: ['update:selectedSchool'],
  setup(__props: any, { emit: __emit }) {

	const props = __props;

	const schoolSelectSchema: FieldSchema = {
		name: 'school',
		type: 'autocomplete',
		autocompleteProperty: 'schoolName',
		autoCompleteResultsErrorMessage: 'No matches found',
		hideLabel: false,
		label: 'School',
		placeholder: 'Search...',
		required: false
	};

	const selectedSchool = ref({} as School);
	const filteredSchools = ref<School[]>([]);

	const emit = __emit;

	watch(selectedSchool, (newValue) => {
		emit('update:selectedSchool', newValue);
	});

	function selectSchool(school: School) {
		selectedSchool.value = school;
	}

	async function getSchoolsAsync() {
		try {
			const response = await axios.get('/api/schools', {
				params: {
					programTypeId: props.programTypeId,
					productTypeId: props.productTypeId,
					lenderId: props.lenderId
				}
			});

			filteredSchools.value = response.data;

			if (props.initialSchool) {
				const matchingSchool = filteredSchools.value.find((school) => {
					const matchingCode = school.schoolDOEId === props.initialSchool?.schoolDOEId;
					const matchingBranch = school.schoolBranchDOEId === props.initialSchool?.schoolBranchDOEId;

					return matchingCode && matchingBranch;
				});

				if (matchingSchool) {
					selectSchool(matchingSchool);
				}
			}
		} catch (error) {
			console.error('Failed to get schools:', error);
		}
	}

	onMounted(async () => {
		await getSchoolsAsync();
	});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseFormField, {
    class: "base-form-field",
    disabled: _ctx.disabled,
    modelValue: selectedSchool.value,
    "onUpdate:modelValue": [
      _cache[0] || (_cache[0] = ($event: any) => ((selectedSchool).value = $event)),
      selectSchool
    ],
    schema: schoolSelectSchema,
    autocompleteOptions: filteredSchools.value
  }, null, 8, ["disabled", "modelValue", "autocompleteOptions"]))
}
}

})