import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "xpage-top-details-status" }
const _hoisted_2 = {
  key: 0,
  class: "xheader-search-dropdown"
}
const _hoisted_3 = { class: "form-padding" }
const _hoisted_4 = { autocomplete: "off" }
const _hoisted_5 = ["name", "checked", "onInput"]
const _hoisted_6 = { class: "smallcapitals text-padding" }
const _hoisted_7 = { class: "denial-reasons" }
const _hoisted_8 = {
  key: 1,
  class: "comment-area"
}
const _hoisted_9 = { class: "xdropdown-buttons" }

import { ref, reactive, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { FieldSchema, Option } from "@/models/form";
import { LOSApplication, IncomeDocument } from "@/models/opal";
import BaseFormField from "@/components/form-fields/BaseFormField.vue";
import { $api } from "@/services/api1";
import functions from "@/use/functions";
import useFormHelpers from "@/use/formHelpers";
import useValidation from "@/use/validation";
import useFunctions from "@/use/functions1";
import { Permissions, PermissionLevels } from "@/models/permissions";
import PermissionsWrapper from "@/components/wrappers/PermissionsWrapper.vue";
import OpalButton from "@/components/shared/OpalButton.vue";
import { useDocumentsTabStore } from "@/store/store/documentsTabStore";
import { useIncomeTabStore } from "@/store/store/incomeTabStore";

interface Props {
  incomeDocument: IncomeDocument;
  incomeDocuments: IncomeDocument[];
  application: LOSApplication;
  canEdit: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'IncomeDocumentStatus',
  props: {
    incomeDocument: {},
    incomeDocuments: {},
    application: {},
    canEdit: { type: Boolean }
  },
  emits: ["documentStatusChanged", "getComments"],
  setup(__props: any, { emit: __emit }) {

const store = useStore();
const incomeTabStore = useIncomeTabStore();
const documentsTabStore = useDocumentsTabStore();

const props = __props;
const emit = __emit;

const { documentStatusReasonsOptions } = useFormHelpers();
const { validateForm } = useValidation();
const { decodeHTMLEncodedStr, sortLosTypesByName } = useFunctions();

const documentSchema = {
  documentStatusTypeId: {
    name: "documentStatusTypeId",
    type: "radio",
    hideLabel: true,
    hideErrorMessage: true,
    required: true,
  } as FieldSchema,
  documentStatusReasonId: {
    name: "documentStatusReasonId",
    type: "select",
    hideLabel: true,
    hideErrorMessage: false,
    errorMessage: "A reason is required",
    required: false,
  } as FieldSchema,
  comment: {
    name: "comment",
    type: "textarea",
    hideLabel: true,
    required: false,
  } as FieldSchema,
};

const info = reactive({
  incomeDocument: { ...props.incomeDocument }, 
  originalIncomeDocument: {},
  comment: undefined as string | undefined,
  savingChanges: false,
});

const savingChanges = ref<boolean>(false);
const showStatusReasonsDropdown = ref<boolean>(false);
const showStatusDropdown = ref<boolean>(false);
const showCommentArea = ref<boolean>(false);

const sortedDocumentStatuses = sortLosTypesByName(store.state.types["DocumentStatus"]?.results);

const documentStatusOptions = sortedDocumentStatuses
  .filter((ds: any) => ds.name !== "Generated" && ds.name !== "Signed")
  .map((o: any) => {
    return {
      label: o.name || o.label,
      value: o.id || o.value,
    } as Option;
  });

let originalIncomeDocuments = reactive({} as any);
function cancel() {
  showStatusDropdown.value = false;
  showStatusReasonsDropdown.value = false;
  resetDropdown();
}

function closeDropdown() {
  showStatusDropdown.value = false;
}

function resetDropdown() {
  info.comment = undefined;
  // revert to original
  info.incomeDocument = Object.assign({}, props.incomeDocument);
}

function cleanComment() {
  if (info.comment) {
    const trimmed = info.comment.trim();
    info.comment = trimmed.length ? trimmed : undefined;
  }
}

function isFormValid(): boolean {
  return validateForm(documentSchema, {
    documentStatusTypeId: info.incomeDocument.documentStatusTypeId,
    documentStatusReasonId: info.incomeDocument.documentStatusReasonId,
    comment: info.comment,
  });
}

function handleDocumentStatusChange(event: any) {
  info.incomeDocument.documentStatusTypeId = event.value;
  info.incomeDocument.documentStatusTypeName = event.label;

  if (
    info.incomeDocument.documentStatusTypeName === "Rejected" ||
    info.incomeDocument.documentStatusTypeName ===
      "Escalated for Manager Review"
  ) {
    info.incomeDocument.showStatusReasonSection = true;
  } else {
    info.incomeDocument.showStatusReasonSection = false;
  }
}

function handleDocumentStatusReasonChange(evt: any) {
  const originalIncomeDocument =
    originalIncomeDocuments[info.incomeDocument.applicantDocumentId];

  const newStatusReason = store?.state.types[
    "DocumentStatusReasons"
  ]?.results.find((dsr: any) => dsr.id === evt.documentStatusReasonId);
  if (
    newStatusReason &&
    newStatusReason.name === "Other" &&
    originalIncomeDocument?.documentStatusReasonName === newStatusReason.name
  ) {
    info.incomeDocument.documentStatusReasonName = newStatusReason.name;
    info.comment =
      info.incomeDocument.applicantDocumentComments[
        info.incomeDocument.applicantDocumentComments.length - 1
      ]?.comment || null;

    documentSchema.comment.required = true;
    showCommentArea.value = true;
  } else if (
    newStatusReason &&
    newStatusReason.name === "Other" &&
    originalIncomeDocument?.documentStatusReasonName !== newStatusReason.name
  ) {
    info.incomeDocument.documentStatusReasonName = newStatusReason.name;
    info.comment = undefined;
    documentSchema.comment.required = true;
    showCommentArea.value = true;
  } else {
    showCommentArea.value = false;
  }
}

function submit(incomeDocument: IncomeDocument) {
  if (
    incomeDocument.documentStatusTypeName === "Rejected" ||
    incomeDocument.documentStatusTypeName === "Escalated for Manager Review"
  ) {
    documentSchema.documentStatusReasonId.required = true;
  }

  cleanComment();

  if (!isFormValid()) {
    return;
  } 
    updateDocumentStatus();
  }

function setDropdown() {
  info.incomeDocument.applicantDocumentComments =
    props.incomeDocument.applicantDocumentComments;
  info.incomeDocument.documentStatusReasonId =
    props.incomeDocument.documentStatusReasonId;
  info.incomeDocument.documentStatusReasonName =
    props.incomeDocument.documentStatusReasonName;
  info.incomeDocument.documentStatusTypeId =
    props.incomeDocument.documentStatusTypeId;
  info.incomeDocument.documentStatusTypeName =
    props.incomeDocument.documentStatusTypeName;

  if (info.incomeDocument.documentStatusReasonName === "Other") {
    showCommentArea.value = true;
    documentSchema.comment.required = true;
    info.comment =
      info.incomeDocument.applicantDocumentComments[
        info.incomeDocument.applicantDocumentComments.length - 1
      ]?.comment || null;
  }

  validateForm(documentSchema, {
    documentStatusTypeId: info.incomeDocument.documentStatusTypeId,
    documentStatusReasonId: info.incomeDocument.documentStatusReasonId,
    comment: info.comment,
  });
}

function dropdownClicked() {
  showStatusDropdown.value = !showStatusDropdown.value;

  if (showStatusDropdown.value === true) {
    originalIncomeDocuments[info.incomeDocument.applicantDocumentId] =
      Object.assign({}, info.incomeDocument);
    setDropdown();
    if (
      info.incomeDocument.documentStatusTypeName === "Rejected" ||
      info.incomeDocument.documentStatusTypeName ===
        "Escalated for Manager Review"
    ) {
      info.incomeDocument.showStatusReasonSection = true;

      if (info.incomeDocument.documentStatusReasonName === "Other") {
        showCommentArea.value = true;
      }
    } else {
      info.incomeDocument.showStatusReasonSection = false;
      showCommentArea.value = false;
      resetDropdown();
    }
  }

  if (showStatusDropdown.value === false) {
    cancel();
  }
}

async function updateDocumentStatus() {
  savingChanges.value = true;
  const originalIncomeDocument =
    originalIncomeDocuments[info.incomeDocument.applicantDocumentId];

  const userDisplayName = `${decodeHTMLEncodedStr(
    store?.state?.oidcStore?.user?.given_name
  )} ${decodeHTMLEncodedStr(store?.state?.oidcStore?.user?.family_name)}`;

  const documentStatusUpdate = {
    applicantId: info.incomeDocument?.applicantId,
    appDocumentId: info.incomeDocument.applicantDocumentId,
    documentStatusTypeId: info.incomeDocument.documentStatusTypeId,
    documentStatusReasonId: info.incomeDocument.documentStatusReasonId,
		documentId: info.incomeDocument.documentId,
    documentStatusReasonName: info.incomeDocument.documentStatusReasonName,
    comment: info.comment,
    updatedBy: userDisplayName,
  };
  // check if 'other' and reason already exists
  const lastIndex =
    info.incomeDocument.applicantDocumentComments.length - 1 || 0;
  const lastReason =
    info.incomeDocument.applicantDocumentComments.length > 0
      ? info.incomeDocument.applicantDocumentComments[lastIndex]?.comment
      : undefined;

  // handle changes present
  if (
    info.incomeDocument.documentStatusTypeId !==
      originalIncomeDocument?.documentStatusTypeId ||
    info.incomeDocument.documentStatusReasonId !==
      originalIncomeDocument?.documentStatusReasonId ||
    info.comment !== lastReason
  ) {
    const response = await $api.documents.updateDocumentStatus(
      props.application.data.id,
      info.incomeDocument.applicantId,
      info.incomeDocument.applicantDocumentId,
      documentStatusUpdate
    );
    if (
      response.applicantDocumentId &&
      response.documentStatusTypeId &&
      response.documentStatusTypeName
    ) {
      info.incomeDocument.applicantDocumentComments =
        response.applicantDocumentComments;
      info.incomeDocument.documentStatusTypeId = response.documentStatusTypeId;
      info.incomeDocument.documentStatusTypeName =
        response.documentStatusTypeName;
      info.incomeDocument.documentStatusReasonId =
        response.documentStatusReasonId;
      info.incomeDocument.documentStatusReasonTypeName =
        response.documentStatusReasonTypeName;

      delete originalIncomeDocuments[info.incomeDocument.applicantDocumentId];

      documentsTabStore.updateDocument(response.documentId, response);

      incomeTabStore.updateIncomeDocument(
        info.incomeDocument.applicantDocumentId,
        info.incomeDocument
      );
      emit("documentStatusChanged", info.incomeDocument);
      emit("getComments");
      closeDropdown();
    } else {
      functions.openModal({
        title: "Error",
        description: "There was an issue updating the document status",
      });
      // revert selection
      info.incomeDocument.documentStatusTypeId =
        originalIncomeDocument?.documentStatusTypeId;
      info.incomeDocument.documentStatusTypeName =
        originalIncomeDocument?.documentStatusTypeName;
      info.incomeDocument.documentStatusReasonId =
        originalIncomeDocument?.documentStatusReasonId;
      info.incomeDocument.documentStatusReasonTypeName =
        originalIncomeDocument?.documentStatusReasonTypeName;
    }
  }
  // handle no changes present
  else {
    closeDropdown();
  }

  savingChanges.value = false;
}

watch(
  () => info.incomeDocument.documentStatusTypeName,
  (newValue, oldValue) => {
    if (
      (oldValue === "Rejected" &&
        newValue === "Escalated for Manager Review") ||
      (oldValue === "Escalated for Manager Review" && newValue === "Rejected")
    ) {
      info.incomeDocument.documentStatusReasonId = null;
      info.incomeDocument.documentStatusReasonName = null;
      info.incomeDocument.showStatusReasonSection = true;
      info.comment = undefined;
      showCommentArea.value = false;
      documentSchema.documentStatusReasonId.required = true;
    } else if (
      (oldValue !== "Rejected" ||
        oldValue !== "Escalated for Manager Review") &&
      (newValue === "Rejected" || newValue === "Escalated for Manager Review")
    ) {
      info.incomeDocument.showStatusReasonSection = true;
      documentSchema.documentStatusReasonId.required = true;
    } else {
      info.incomeDocument.documentStatusReasonId = null;
      info.incomeDocument.documentStatusReasonName = null;
      info.incomeDocument.showStatusReasonSection = false;
      documentSchema.documentStatusReasonId.required = false;
    }
  }
);

watch(
  () => info.incomeDocument.documentStatusReasonName,
  (newValue) => {
    if (newValue === "Other") {
      showCommentArea.value = true;
      documentSchema.comment.required = true;
    } else {
      showCommentArea.value = false;
      documentSchema.comment.required = false;
    }
  }
);

onMounted(async () => {
  info.incomeDocument = Object.assign({}, props?.incomeDocument);
  setDropdown();
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(PermissionsWrapper, {
      disabled: !_ctx.canEdit,
      showDisabledIfNotVisible: true,
      permissionIds: [_unref(Permissions).Income],
      permissionLevel: _unref(PermissionLevels).Update
    }, {
      default: _withCtx(() => [
        _createElementVNode("h4", { onClick: dropdownClicked }, [
          _createTextVNode(_toDisplayString(info.incomeDocument.documentStatusTypeName) + " ", 1),
          _createElementVNode("i", {
            class: _normalizeClass(showStatusDropdown.value ? 'icon-up-open-big' : 'icon-down-open-big')
          }, null, 2)
        ]),
        (showStatusDropdown.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("form", _hoisted_4, [
                  _createElementVNode("nav", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(documentStatusOptions), (status) => {
                      return (_openBlock(), _createElementBlock("label", {
                        class: "xradio",
                        key: status.label
                      }, [
                        _createTextVNode(_toDisplayString(status.label) + " ", 1),
                        _createElementVNode("input", {
                          type: "radio",
                          name: `radio${status.id}`,
                          checked: 
                    info.incomeDocument.documentStatusTypeId === status.value
                  ,
                          onInput: ($event: any) => (handleDocumentStatusChange(status))
                        }, null, 40, _hoisted_5),
                        _cache[4] || (_cache[4] = _createElementVNode("span", null, null, -1))
                      ]))
                    }), 128))
                  ]),
                  (info.incomeDocument.showStatusReasonSection)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createElementVNode("h3", _hoisted_6, _toDisplayString(info.incomeDocument.documentStatusTypeName === "Rejected"
                    ? "Reject"
                    : "Escalation") + " Reasons ", 1),
                        _createElementVNode("div", _hoisted_7, [
                          _createVNode(BaseFormField, {
                            modelValue: info.incomeDocument.documentStatusReasonId,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((info.incomeDocument.documentStatusReasonId) = $event)),
                            modelModifiers: { number: true },
                            schema: documentSchema.documentStatusReasonId,
                            options: 
                    _unref(documentStatusReasonsOptions)(
                      info.incomeDocument.documentStatusTypeName
                    )
                  ,
                            onChange: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (
                    handleDocumentStatusReasonChange(info.incomeDocument)
                  ), ["prevent"]))
                          }, null, 8, ["modelValue", "schema", "options"])
                        ])
                      ], 64))
                    : _createCommentVNode("", true),
                  (showCommentArea.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _cache[5] || (_cache[5] = _createElementVNode("h3", { class: "smallcapitals text-padding" }, " Reason For Change: ", -1)),
                        _createVNode(BaseFormField, {
                          modelValue: info.comment,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((info.comment) = $event)),
                          schema: documentSchema.comment
                        }, null, 8, ["modelValue", "schema"])
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("button", {
                      onClick: _withModifiers(cancel, ["prevent"])
                    }, "Cancel"),
                    _createVNode(OpalButton, {
                      onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (submit(info.incomeDocument)), ["prevent"])),
                      loading: savingChanges.value,
                      disabled: savingChanges.value,
                      text: "Save Changes"
                    }, null, 8, ["loading", "disabled"])
                  ])
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["disabled", "permissionIds", "permissionLevel"])
  ]))
}
}

})