import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "error" }

import { ref, onMounted, computed } from "vue";
  import { useStore } from "vuex";
  import { getEnvironment } from "@/config/oidc";
  import Dashboard from "@uppy/dashboard";
  import XHRUpload from "@uppy/xhr-upload";
  import Uppy from "@uppy/core";
  import "@uppy/core/dist/style.css";
  import "@uppy/dashboard/dist/style.css";
  import { $api } from "@/services/api1";
  import { Permissions, PermissionLevels } from "@/models/permissions";
  import { usePermissionsStore } from "@/store/store/permissionsStore";
  import functions from "@/use/functions.js";

  
export default /*@__PURE__*/_defineComponent({
  __name: 'UppyUploader2',
  props: {
    name: { type: String },
    applicationId: { type: Number },
    applicantId: { type: Number },
    entityTypeId: { type: Number },
    userId: { type: String },
    documentTypeId: { type: Number },
  },
  emits: [
    "getDocuments",
    "update:modelValue",
    "uploadSuccess",
    "fileAdded",
  ],
  setup(__props, { emit: __emit }) {

  const props = __props;

  const store = useStore();
  const uppyId = `uppy_${props.name}`;
  const errorMessage = ref("");
  const applicantToLink = computed(() => props.applicantId);
  const emit = __emit;

  const metadata = computed(() => ({
    entityId: props.applicationId,
    personId: applicantToLink.value,
    entityTypeId: props.entityTypeId,
    userId: props.userId,
    documentTypeId: props.documentTypeId || 23,
  }));

  const instantiateUppy = () => {
    const uppy = new Uppy({
      id: uppyId,
      debug: false,
      autoProceed: false,
      allowMultipleUploads: false,
      infoTimeout: 6000, // 6 seconds
      locale: {
        strings: {
          noInternetConnection: `Please check your internet connection and try again. If the issue continues and your internet connection is working, please try again later or contact our support team.`,
          failedToUpload: `An error occurred while processing your file. Please try again. If the issue continues, please contact our support team`,
        }
      },
      restrictions: {
        maxNumberOfFiles: 1,
      },
      onBeforeUpload: (files) => {
        if (!userCanUpload()) {
          errorMessage.value = `Insufficient permissions. Please contact your system administrator.`;
          return false;
        }
        return true;
      },
      onBeforeFileAdded: (currentFile, files) => {
        const allowedFileTypes = [
          "doc",
          "docx",
          "pdf",
          "png",
          "jpg",
          "jpeg",
          "heic",
          "tif",
          "tiff",
          "xls",
          "xlsx",
        ];

        const isValidFileType = allowedFileTypes.includes(
          currentFile.extension?.toLowerCase()
        );
        const isValidFileSize = currentFile.size <= 100000000;
        const formattedTypes = allowedFileTypes
          .map((type) => type.toUpperCase())
          .join(", ");
        if (props.applicantId === undefined) {
          functions.openModal({
            title: "Alert",
            description: "Please select an applicant",
          });
          return false;
        }
        if (!isValidFileType) {
          errorMessage.value = `File format for ${currentFile.name} is not supported. Please upload a new file in one of these formats: ${formattedTypes}.`;
          return false;
        }
        if (!isValidFileSize) {
          errorMessage.value = `${currentFile.name} is too large. Maximum supported file size is 100MB. Please reduce the file size and try again.`;
          return false;
        }
        errorMessage.value = "";
        return true;
      },
    })
      .use(Dashboard, {
        proudlyDisplayPoweredByUppy: false,
        showLinkToFileUploadResult: true,
        disableInformer: true, // custom error logging
        target: `#${uppyId}`,
        inline: true,
        showSelectedFiles: true,
        height: 400,
        disableThumbnailGenerator: false,
      })
      .use(XHRUpload, {
        endpoint: `${
          getEnvironment().documents
        }/api/v1/DocUploadService/UploadDocument/`,
        formData: true,
        fieldName: "documentToUpload",
        allowedMetaFields: null,
        bundle: true,
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${store.getters["oidcStore/oidcAccessToken"]}`,
          Accept: "*/*",
        },
        getResponseError(responseText, response: any) {
        if (response.status === 422) {
          errorMessage.value = `The document you uploaded failed because it is password protected. Please try again with a document that does not have a password.`;
        } else {
          errorMessage.value = `An error occurred while processing your file. Please try again. If the issue continues, please contact our support team.`;
        }
        return new Error(`An error occurred while processing your file. Please try again. If the issue continues, please contact our support team.`);
        },
      });

    // Events for file uploads
    uppy.on("restriction-failed", (file, error) => {
      console.log("Uppy Restriction Error", error);
    });

    uppy.on("file-added", (file) => {
      emit('fileAdded');
      uppy.setMeta(metadata.value);
    });

    uppy.once("upload", (data) => {
      console.log("Uppy Upload", data);
    });

    uppy.on("complete", async (result) => {
      console.log("Successful Files:", result.successful);
      console.log("Failed Files:", result.failed);
      const file = result.successful[0];
      if (file) {
        emit("uploadSuccess", { result: true });
      }
      if (props.applicantId) {
        await $api.documents.getDocumentsByApplicant(
          file?.meta?.personId as number
        );
      }
      if (result.failed.length > 0) {
        errorMessage.value = `An error occurred while processing your file. Please try again. If the issue continues, please contact our support team.`;
      }
    });
  };

  const dragover = (event: any) => {
    event.preventDefault();
    event.currentTarget.classList.add("active-drag");
  };

  const dragleave = (event: any) => {
    event.currentTarget.classList.remove("active-drag");
  };

  const drop = (event: any) => {
    event.preventDefault();
    emit("update:modelValue", [...event.dataTransfer.files]);
    event.currentTarget.classList.remove("active-drag");
  };

  const userCanUpload = () => {
    const permissionsStore = usePermissionsStore();
    return permissionsStore.hasRequiredPermission(
      [Permissions.DocumentProcessing],
      PermissionLevels.Create
    );
  };

  onMounted(() => {
    instantiateUppy();
  });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "file-upload",
    "on:onDragLeave": dragleave,
    onOndragover: dragover,
    "on:onDrop": drop
  }, [
    _createElementVNode("div", {
      class: "drag-drop-area",
      id: uppyId
    }),
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "UppyProgressBar" }, null, -1)),
    _createElementVNode("div", _hoisted_1, _toDisplayString(errorMessage.value), 1)
  ], 32))
}
}

})