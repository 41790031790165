import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withModifiers as _withModifiers, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "spinner-container"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = { class: "comments-list" }
const _hoisted_5 = { class: "comment-avatar" }
const _hoisted_6 = { class: "avatar-initials" }
const _hoisted_7 = { class: "comment-content" }
const _hoisted_8 = { class: "comment-user" }
const _hoisted_9 = { class: "comment-text" }
const _hoisted_10 = { class: "comment-date" }

import { ref, onMounted } from 'vue';
	import axios from 'axios';
	import functions from '@/use/functions';
	import Spinner from '@/components/shared/Spinner.vue';
	import { Comment } from '@/models/loans';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'CommentsTab',
  props: {
    loanId: {}
  },
  setup(__props: any) {

	const props = __props;

	const isLoading = ref<boolean>(true);
	const comments = ref<Comment[]>([]);
	const comment = ref<string[] | string | number | undefined>(undefined);

	function getPillClass(type: number): string {
		switch (type) {
			case 2:
				return 'pill-green';
			default:
				return '';
		}
	}

	function getPillLabel(type: number): string {
		switch (type) {
			case 2:
				return 'School Portal';
			default:
				return 'Other';
		}
	}

	async function getCommentsAsync() {
		try {
			const response = await axios(`/api/comments/loan/${props.loanId}`);

			comments.value = response.data;
		} catch (error) {
			functions.openModal({
				title: 'Oops!',
				description: 'There was an issue getting the comments. Please try again later.'
			});
		}
	}

	async function createCommentAsync() {
		if (!comment.value) {
			return;
		}

		try {
			await axios.post('/api/comments/loan', {
				loanId: props.loanId,
				comment: comment.value
			});

			comment.value = undefined;

			await getCommentsAsync();
		} catch (error) {
			functions.openModal({
				title: 'Oops!',
				description: 'There was an issue creating the comment. Please try again later.'
			});
		}
	}

	onMounted(async () => {
		await getCommentsAsync();

		isLoading.value = false;
	});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (isLoading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(Spinner)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("form", {
            class: "comment-form",
            onSubmit: _withModifiers(createCommentAsync, ["prevent"])
          }, [
            _withDirectives(_createElementVNode("textarea", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((comment).value = $event)),
              required: "",
              maxlength: "4000",
              placeholder: "Write a comment..."
            }, null, 512), [
              [_vModelText, comment.value]
            ]),
            _createElementVNode("button", {
              type: "submit",
              disabled: !comment.value
            }, "Submit", 8, _hoisted_3)
          ], 32),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("h4", null, "Comments (" + _toDisplayString(comments.value.length) + ")", 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(comments.value, (comment) => {
              return (_openBlock(), _createElementBlock("div", {
                key: comment.id,
                class: "comment-item"
              }, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("span", _hoisted_6, _toDisplayString(comment.initials), 1)
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("span", _hoisted_8, [
                    _createTextVNode(_toDisplayString(comment.userDisplayName || 'Unknown') + " ", 1),
                    (comment.commentType !== 1)
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          class: _normalizeClass(['pill', getPillClass(comment.commentType)])
                        }, _toDisplayString(getPillLabel(comment.commentType)), 3))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("span", _hoisted_9, _toDisplayString(comment.comment), 1),
                  _createElementVNode("span", _hoisted_10, _toDisplayString(comment.createdAtFormatted), 1)
                ])
              ]))
            }), 128))
          ])
        ]))
  ]))
}
}

})