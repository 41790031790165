import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "stip-modal" }
const _hoisted_2 = { class: "xfields" }

import ModalOpal from "@/components/ModalOpal.vue";
import { PropType, reactive,computed } from "vue";
import { Applicant, LOSApplication, Stipulation } from "@/models/opal";
import BaseFormField from "@/components/form-fields/BaseFormField.vue";
import { useStore } from "vuex";
import { FieldSchema, FormSchema } from "@/models/form";
interface Props {
  stipulation: Stipulation,
  application: LOSApplication
}

export default /*@__PURE__*/_defineComponent({
  __name: 'StipulationForm',
  props: {
    stipulation: {},
    application: {}
  },
  emits: ["closeStipModal"],
  setup(__props: any, { emit: __emit }) {

const emit = __emit;

const store = useStore();
const props = __props;
const applicants = computed(()=> props?.application?.applicants);

const stipulationsOptions = computed(() => store.state.types['Stipulations']?.results?.map((stip: any) => {
  return {
    label: stip.description,
    value: stip.id
  }
}));
const stipulationStatusOptions = computed(() => store.state.types['StipulationStatuses']?.results?.map((stip: any) => {
  return {
    label: stip.name,
    value: stip.id
  }
}));
const stipulationStatusReasonOptions = computed(() => store.state.types['StipulationStatusReasons']?.results?.map((stip: any) => {
  return {
    label: stip.description,
    value: stip.id
  }
}));
const applicantOptions = props.application.applicants.map((a: Applicant) => {
  return {
    label: `${a.fullName} - ${a.applicantTypeName}`,
    value: a.id
  }
});
const stipulationSchema = reactive({
  stipulation: {
    name: "stipulation",
    type: "autocomplete",
    autocompleteProperty: "description",
    autoCompleteResultsErrorMessage: "No matches found",
    hideLabel: true
  } as FieldSchema,
  stipulationTypeId: {
    name: "stipulationTypeId",
    type: "select",
    label: "Stipulation Type",
    required: true
  } as FieldSchema,
  stipulationStatusId: {
    name: "stipulationStatusId",
    type: "select",
    label: "Stipulation Status",
    required: true
  } as FieldSchema,
  stipStatusReason: {
    name: "stipStatusReason",
    type: "select",
    label: "Stipulation Status Reason",
  } as FieldSchema,
  stipulationText: {
    name: "stipulationText",
    type: "text",
    label: "Stipulation Text",
  } as FieldSchema,
  applicantId: {
    name: "applicantId",
    type: "select",
    label: "Requested For",
    required: true
  } as FieldSchema,
  documentId: {
    name: "documentId",
    type: "select",
    hideLabel: true
  } as FieldSchema
}) as FormSchema;

let stipulationForm = reactive({} as any);


stipulationForm = computed(() => props.stipulation);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ModalOpal, {
    onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('closeStipModal')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("form", null, [
          _createElementVNode("fieldset", null, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(BaseFormField, {
                schema: stipulationSchema.stipulationTypeId,
                options: stipulationsOptions.value,
                modelValue: _unref(stipulationForm).stipulationTypeId,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(stipulationForm).stipulationTypeId) = $event)),
                modelModifiers: { number: true }
              }, null, 8, ["schema", "options", "modelValue"]),
              _createVNode(BaseFormField, {
                schema: stipulationSchema.applicantId,
                options: _unref(applicantOptions),
                modelValue: _unref(stipulationForm).applicantId,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(stipulationForm).applicantId) = $event)),
                modelModifiers: { number: true }
              }, null, 8, ["schema", "options", "modelValue"]),
              _createVNode(BaseFormField, {
                schema: stipulationSchema.stipStatus,
                options: stipulationStatusOptions.value,
                modelValue: _unref(stipulationForm).stipulationStatusId,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(stipulationForm).stipulationStatusId) = $event))
              }, null, 8, ["schema", "options", "modelValue"]),
              _createVNode(BaseFormField, {
                schema: stipulationSchema.stipStatusReason,
                options: stipulationStatusReasonOptions.value,
                modelValue: _unref(stipulationForm).stipulationStatusReasonId,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(stipulationForm).stipulationStatusReasonId) = $event))
              }, null, 8, ["schema", "options", "modelValue"]),
              _createVNode(BaseFormField, {
                schema: stipulationSchema.stipulationText,
                modelValue: _unref(stipulationForm).stipulationText,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(stipulationForm).stipulationText) = $event))
              }, null, 8, ["schema", "modelValue"])
            ])
          ]),
          _cache[6] || (_cache[6] = _createElementVNode("button", null, "Save Changes", -1))
        ])
      ])
    ]),
    _: 1
  }))
}
}

})