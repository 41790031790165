import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "spinner-container"
}
const _hoisted_2 = ["for"]
const _hoisted_3 = ["value"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["for"]
const _hoisted_6 = ["id", "name", "onUpdate:modelValue", "disabled"]
const _hoisted_7 = ["disabled"]
const _hoisted_8 = ["disabled"]
const _hoisted_9 = ["disabled"]
const _hoisted_10 = { class: "options-container" }

import { ref, onMounted, reactive, computed, watchEffect } from 'vue';
	import axios from 'axios';
	import functions from '@/use/functions';
	import { Form, Field, ErrorMessage } from 'vee-validate';
	import { Borrower, DocumentProperties, SplitDocumentOptions } from '@/models/loans';
	import { useFormFields } from '@/composables/loan/documents/useSplitDocumentForm';
	import { useDocumentData } from '@/composables/loan/documents/useDocumentData';
	import Spinner from '@/components/shared/Spinner.vue';
	import DocumentSplitOption from './DocumentSplitOption.vue';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'SplitDocumentForm',
  props: {
    documentId: {},
    borrowers: {}
  },
  setup(__props: any) {

	const props = __props;

	const isLoading = ref<boolean>(false);
	const isSubmitting = ref<boolean>(false);
	const documentProperties = ref<DocumentProperties>({
		pageCount: 0
	});

	const splitDocumentOptions = ref<SplitDocumentOptions[]>([]);

	const { documentTypeOptions } = useDocumentData();

	const { formFields, validationSchema } = useFormFields({
		properties: documentProperties,
		borrowers: props.borrowers,
		documentTypeOptions
	});

	const formData = reactive<Record<string, any>>({});

	const isFormValid = computed(() => {
		return (errors: Partial<Record<string, string | undefined>>) => errors && Object.keys(errors).length === 0;
	});

	function handleRemoveOption(option: SplitDocumentOptions) {
		const index = splitDocumentOptions.value.indexOf(option);

		if (index > -1) {
			splitDocumentOptions.value.splice(index, 1);
		}
	}

	const removeAllOptions = () => (splitDocumentOptions.value = []);

	async function getDocumentPropertiesAsync() {
		isLoading.value = true;

		try {
			var response = await axios.get(`/api/loandocuments/${props.documentId}/pdf-properties`);

			documentProperties.value = response.data;
		} catch (error) {
			functions.openModal({
				title: 'Oops!',
				description: 'There was an issue getting the document properties. Please try again later.'
			});
		} finally {
			isLoading.value = false;
		}
	}

	function addSplitDocumentOption(formData: Record<string, any>) {
		try {
			splitDocumentOptions.value.push({
				documentType: formData['documentTypeId'],
				applicantId: formData['borrowerId'],
				startIndex: formData['startIndex'] - 1,
				endIndex: formData['endIndex'] - 1,
				applicantViewable: formData['borrowerVisible'] as boolean
			} as SplitDocumentOptions);
		} catch (error) {
			functions.openModal({
				title: 'Oops!',
				description: 'There was an issue adding the split options. Please try again later.'
			});
		}
	}

	async function splitDocumentAsync() {
		isSubmitting.value = true;

		try {
			const request = {
				documentId: props.documentId,
				entityTypeId: 2, // loan
				options: splitDocumentOptions.value
			};

			await axios.post(`/api/loandocuments/${props.documentId}/split`, request);
		} catch (error) {
			functions.openModal({
				title: 'Oops!',
				description: 'There was an issue splitting the document. Please try again later.'
			});
		} finally {
			isSubmitting.value = false;
		}
	}

	watchEffect(() => {
		formFields.value.forEach((field) => {
			formData[field.name] = field.value;
		});
	});

	onMounted(async () => {
		await getDocumentPropertiesAsync();
	});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (isLoading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(Spinner)
        ]))
      : (_openBlock(), _createBlock(_unref(Form), {
          key: 1,
          "validation-schema": _unref(validationSchema),
          class: "form-container",
          onSubmit: addSplitDocumentOption
        }, {
          default: _withCtx(({ errors }) => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(formFields), (field) => {
              return (_openBlock(), _createElementBlock("div", {
                key: field.name,
                class: _normalizeClass(['form-input', field.width])
              }, [
                _createElementVNode("label", {
                  for: field.name
                }, _toDisplayString(field.label), 9, _hoisted_2),
                (field.type === 'select')
                  ? (_openBlock(), _createBlock(_unref(Field), {
                      key: 0,
                      as: "select",
                      id: field.name,
                      name: field.name,
                      modelValue: formData[field.name],
                      "onUpdate:modelValue": ($event: any) => ((formData[field.name]) = $event),
                      disabled: isSubmitting.value
                    }, {
                      default: _withCtx(() => [
                        _cache[0] || (_cache[0] = _createElementVNode("option", null, null, -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(field.options, (option) => {
                          return (_openBlock(), _createElementBlock("option", {
                            key: option.id,
                            value: option.id
                          }, _toDisplayString(option.name), 9, _hoisted_3))
                        }), 128))
                      ]),
                      _: 2
                    }, 1032, ["id", "name", "modelValue", "onUpdate:modelValue", "disabled"]))
                  : (field.type === 'checkbox')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createElementVNode("label", {
                          for: field.name
                        }, [
                          _createVNode(_unref(Field), {
                            id: field.name,
                            name: field.name,
                            modelValue: formData[field.name],
                            "onUpdate:modelValue": ($event: any) => ((formData[field.name]) = $event)
                          }, {
                            default: _withCtx(() => [
                              _withDirectives(_createElementVNode("input", {
                                type: "checkbox",
                                id: field.name,
                                name: field.name,
                                "onUpdate:modelValue": ($event: any) => ((formData[field.name]) = $event),
                                disabled: isSubmitting.value
                              }, null, 8, _hoisted_6), [
                                [_vModelCheckbox, formData[field.name]]
                              ])
                            ]),
                            _: 2
                          }, 1032, ["id", "name", "modelValue", "onUpdate:modelValue"]),
                          _cache[1] || (_cache[1] = _createElementVNode("span", { class: "custom-checkbox" }, null, -1))
                        ], 8, _hoisted_5)
                      ]))
                    : (_openBlock(), _createBlock(_unref(Field), {
                        key: 2,
                        id: field.name,
                        name: field.name,
                        type: field.type,
                        disabled: isSubmitting.value,
                        modelValue: formData[field.name],
                        "onUpdate:modelValue": ($event: any) => ((formData[field.name]) = $event)
                      }, null, 8, ["id", "name", "type", "disabled", "modelValue", "onUpdate:modelValue"])),
                _createVNode(_unref(ErrorMessage), {
                  name: field.name,
                  class: "error"
                }, null, 8, ["name"])
              ], 2))
            }), 128)),
            _createElementVNode("button", {
              class: "secondary col-6",
              type: "submit",
              disabled: !isFormValid.value(errors) || isSubmitting.value
            }, " Add Split ", 8, _hoisted_7),
            _createElementVNode("button", {
              class: "secondary col-6",
              type: "button",
              disabled: splitDocumentOptions.value.length === 0 || isSubmitting.value,
              onClick: removeAllOptions
            }, " Remove All ", 8, _hoisted_8),
            _createElementVNode("button", {
              class: "submit-button col-12",
              type: "button",
              disabled: splitDocumentOptions.value.length === 0 || isSubmitting.value,
              onClick: splitDocumentAsync
            }, " Submit ", 8, _hoisted_9)
          ]),
          _: 1
        }, 8, ["validation-schema"])),
    _createElementVNode("div", _hoisted_10, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(splitDocumentOptions.value, (option, index) => {
        return (_openBlock(), _createBlock(DocumentSplitOption, {
          key: index,
          options: option,
          documentTypeOptions: _unref(documentTypeOptions),
          borrowers: _ctx.borrowers,
          onRemoveOption: handleRemoveOption
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Split Option #" + _toDisplayString(index + 1), 1)
          ]),
          _: 2
        }, 1032, ["options", "documentTypeOptions", "borrowers"]))
      }), 128))
    ])
  ], 64))
}
}

})