import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "xedit",
  "data-label": "EmployerName"
}
const _hoisted_2 = ["disabled"]
const _hoisted_3 = {
  class: "xedit",
  "data-label": "Income Type"
}
const _hoisted_4 = {
  class: "xedit",
  "data-label": "Amount"
}
const _hoisted_5 = {
  class: "xedit",
  "data-label": "Period"
}
const _hoisted_6 = {
  class: "xedit",
  "data-label": "Year"
}
const _hoisted_7 = {
  class: "xedit",
  "data-label": "Total"
}
const _hoisted_8 = { class: "actions" }

import { Income } from "@/models/incomes";
import {
  reactive,
  watch,
  computed,
} from "vue";
import { useIncomeTabStore } from "@/store/store/incomeTabStore";
import { FormSchema, FieldSchema, Option } from "@/models/form";
import { useStore } from "vuex";
import useFunctions from "@/use/functions1";
import BaseFormField from "@/components/form-fields/BaseFormField.vue";
import useFormatter from "@/use/formatter";
import { IncomeDocument } from "@/models/opal";
import { Permissions, PermissionLevels } from "@/models/permissions";
import PermissionsWrapper from "@/components/wrappers/PermissionsWrapper.vue";
import { LOSType } from "@/types/types";

interface Props {
  incomeRecord: Income;
  incomeDocument: IncomeDocument;
  canEdit: boolean;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'IncomeRow',
  props: {
    incomeRecord: {},
    incomeDocument: {},
    canEdit: { type: Boolean }
  },
  emits: ["toggleEdit", "saveChanges", "confirmDelete"],
  setup(__props: any, { emit: __emit }) {

const incomeTabStore = useIncomeTabStore();
const store = useStore();
const { sortLosTypesByDescription } = useFunctions();
const { formatMoney } = useFormatter();

const props = __props;
let info = reactive({} as any);
let originalIncomes = reactive({} as any);

const calculatedTotal = computed(() =>
  incomeTabStore.calculateIncomeTotal(
    info.income,
    info.income.incomePeriodTypeId,
    info.income.incomePeriodTypeName
  )
);
const emit = __emit;
const schema = reactive({
  incomeYearTypeId: {
    name: "incomeYearTypeId",
    type: "select",
    hideLabel: true,
    hideErrorMessage: true,
    required: true,
  } as FieldSchema,
  applicantEmploymentName: {
    name: "applicantEmploymentName",
    type: "text",
    hideLabel: true,
    hideErrorMessage: true,
  } as FieldSchema,
  applicantEmploymentTypeId: {
    name: "applicantEmploymentTypeId",
    type: "select",
    hideLabel: true,
    hideErrorMessage: true,
  } as FieldSchema,
  incomeTypeId: {
    name: "incomeType",
    type: "select",
    hideLabel: true,
    required: true,
    hideErrorMessage: true,
  } as FieldSchema,
  incomeAmount: {
    name: "incomeAmount",
    type: "text",
    hideLabel: true,
    required: true,
    hideErrorMessage: true,
  } as FieldSchema,
  incomePeriodTypeId: {
    name: "incomePeriodTypeId",
    type: "select",
    hideLabel: true,
    required: true,
    hideErrorMessage: true,
  } as FieldSchema,
}) as FormSchema;

const sortedIncomeYears = sortLosTypesByDescription(
  store?.state?.types["IncomeYears"]?.results
);
const incomeYearOptions = sortedIncomeYears?.map((o: any) => {
  return {
    label: o.description,
    value: o.id,
  } as Option;
});

const sortedIncomeTypes = sortLosTypesByDescription(
  store?.state.types["Income"]?.results
);
const incomeTypeOptions = sortedIncomeTypes?.map((o: any) => {
  return {
    label: o.description,
    value: o.id,
  } as Option;
});

const sortedIncomePeriodTypes = sortLosTypesByDescription(
  store?.state.types["IncomePeriods"]?.results
);
const incomePeriodTypeOptions = sortedIncomePeriodTypes?.map((o: any) => {
  return {
    label: o.description || o.label,
    value: o.id || o.value,
  } as Option;
});

function handleIncomePeriodChange(e: number) {
  const id = e && typeof e === "string" ? parseInt(e) : e;
  const incomePeriod = store?.state.types["IncomePeriods"]?.results?.find(
    (i: LOSType) => i.id === id
  );

  info.income.incomePeriodTypeId = incomePeriod?.id;
  info.income.incomePeriodTypeName = incomePeriod?.name || "";
}

function toggleEdit() {
  incomeTabStore.toggleEdit(info.incomeDocument, info.income);
  const incomeId = info?.income?.id || info?.income?.incomeId;
  originalIncomes[incomeId] = Object.assign(
    {},
    info.incomeDocument?.incomes?.find((i: Income) => i.id === incomeId)
  );
  info.originalIncomes = originalIncomes;
  emit("toggleEdit", info);
}

function setData() {
  info.income = Object.assign({}, props?.incomeRecord);
  info.incomeDocument = Object.assign({}, props?.incomeDocument);
  info.schema = schema;
}

setData();

watch(
  () => props.incomeDocument,
  () => {
    setData();
  },
  { deep: true, immediate: true }
);
watch(
  () => props.incomeRecord,
  () => {
    setData();
  },
  { deep: true, immediate: true }
);

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("tr", null, [
    _createElementVNode("td", _hoisted_1, [
      _withDirectives(_createElementVNode("input", {
        disabled: _unref(incomeTabStore).isEditing(_unref(info).income) === false,
        type: "text",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(info).income.applicantEmploymentName) = $event))
      }, null, 8, _hoisted_2), [
        [_vModelText, _unref(info).income.applicantEmploymentName]
      ])
    ]),
    _createElementVNode("td", _hoisted_3, [
      _createVNode(BaseFormField, {
        disabled: _unref(incomeTabStore).isEditing(_unref(info).income) === false,
        schema: schema.incomeTypeId,
        modelValue: _unref(info).income.incomeTypeId,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(info).income.incomeTypeId) = $event)),
        modelModifiers: { number: true },
        options: _unref(incomeTypeOptions)
      }, null, 8, ["disabled", "schema", "modelValue", "options"])
    ]),
    _createElementVNode("td", _hoisted_4, [
      (_unref(incomeTabStore).isEditing(_unref(info).income) === false)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass(
          _unref(incomeTabStore).isEditing(_unref(info).income) === false ? 'disabled' : ''
        )
          }, _toDisplayString(_unref(formatMoney)(_unref(info).income.incomeAmount)), 3))
        : (_openBlock(), _createBlock(BaseFormField, {
            key: 1,
            disabled: _unref(incomeTabStore).isEditing(_unref(info).income) === false,
            schema: schema.incomeAmount,
            modelValue: _unref(info).income.incomeAmount,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(info).income.incomeAmount) = $event))
          }, null, 8, ["disabled", "schema", "modelValue"]))
    ]),
    _createElementVNode("td", _hoisted_5, [
      _createVNode(BaseFormField, {
        disabled: _unref(incomeTabStore).isEditing(_unref(info).income) === false,
        schema: schema.incomePeriodTypeId,
        modelValue: _unref(info).income.incomePeriodTypeId,
        "onUpdate:modelValue": [
          _cache[3] || (_cache[3] = ($event: any) => ((_unref(info).income.incomePeriodTypeId) = $event)),
          handleIncomePeriodChange
        ],
        modelModifiers: { number: true },
        options: _unref(incomePeriodTypeOptions)
      }, null, 8, ["disabled", "schema", "modelValue", "options"])
    ]),
    _createElementVNode("td", _hoisted_6, [
      _createVNode(BaseFormField, {
        disabled: _unref(incomeTabStore).isEditing(_unref(info).income) === false,
        schema: schema.incomeYearTypeId,
        modelValue: _unref(info).income.incomeYearTypeId,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(info).income.incomeYearTypeId) = $event)),
        modelModifiers: { number: true },
        options: _unref(incomeYearOptions)
      }, null, 8, ["disabled", "schema", "modelValue", "options"])
    ]),
    _createElementVNode("td", _hoisted_7, [
      _createElementVNode("span", {
        class: _normalizeClass(
          _unref(incomeTabStore).isEditing(_unref(info).income) === false ? 'disabled' : ''
        )
      }, _toDisplayString(calculatedTotal.value), 3)
    ]),
    _createElementVNode("td", _hoisted_8, [
      _createVNode(PermissionsWrapper, {
        disabled: !_ctx.canEdit,
        showDisabledIfNotVisible: true,
        permissionIds: [_unref(Permissions).Income],
        permissionLevel: _unref(PermissionLevels).Update
      }, {
        default: _withCtx(() => [
          _withDirectives(_createVNode(_component_font_awesome_icon, {
            class: "icon actions",
            icon: 'fa-solid fa-pen-to-square',
            onClick: toggleEdit
          }, null, 512), [
            [_vShow, _unref(incomeTabStore).isEditing(_unref(info).income) === false]
          ]),
          _withDirectives(_createVNode(_component_font_awesome_icon, {
            class: "icon actions",
            "data-color": "red",
            icon: "fa-solid fa-floppy-disk",
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('saveChanges', _unref(info))))
          }, null, 512), [
            [_vShow, _unref(incomeTabStore).isEditing(_unref(info).income)]
          ]),
          _createVNode(_component_font_awesome_icon, {
            class: "icon actions danger",
            "data-color": "red",
            icon: "fa-solid fa-trash",
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('confirmDelete', _unref(info))))
          })
        ]),
        _: 1
      }, 8, ["disabled", "permissionIds", "permissionLevel"])
    ])
  ]))
}
}

})