import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modal-content" }
const _hoisted_2 = { class: "modal-header" }
const _hoisted_3 = { class: "modal-body" }

import { onMounted, onUnmounted } from 'vue';
	import LoanDocumentUploader from '@/components/loan/documents/LoanDocumentUploader.vue';
	import { Borrower } from '@/models/loans';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'UploadDocumentModal',
  props: {
    loanId: {},
    borrowers: {},
    onClose: { type: Function }
  },
  setup(__props: any) {

	const props = __props;

	const handleOverlayClick = (event: MouseEvent) => {
		const target = event.target as HTMLElement;

		if (target.classList.contains('modal-overlay')) {
			props.onClose();
		}
	};

	onMounted(() => {
		document.body.style.overflow = 'hidden';
	});

	onUnmounted(() => {
		document.body.style.overflow = '';
	});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: handleOverlayClick
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[1] || (_cache[1] = _createElementVNode("h3", null, "Upload Document", -1)),
        _createElementVNode("span", {
          class: "close",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args)))
        }, "×")
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(LoanDocumentUploader, {
          loanId: _ctx.loanId,
          borrowers: _ctx.borrowers
        }, null, 8, ["loanId", "borrowers"])
      ])
    ])
  ]))
}
}

})