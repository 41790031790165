import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "header" }
const _hoisted_4 = { class: "initials-circle" }
const _hoisted_5 = { class: "item" }
const _hoisted_6 = { class: "value" }
const _hoisted_7 = { class: "item" }
const _hoisted_8 = { class: "value" }
const _hoisted_9 = { class: "item" }
const _hoisted_10 = { class: "value" }
const _hoisted_11 = { class: "item" }
const _hoisted_12 = { class: "value" }
const _hoisted_13 = { class: "item" }
const _hoisted_14 = { class: "value" }
const _hoisted_15 = {
  key: 1,
  class: "no-borrower-message"
}

import { Borrower } from '@/models/loans';
	

	
export default /*@__PURE__*/_defineComponent({
  __name: 'Borrower',
  props: {
    borrower: {}
  },
  setup(__props: any) {

	

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.borrower)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.borrower.initials), 1),
            _createElementVNode("h2", null, _toDisplayString(_ctx.borrower.fullName), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _cache[0] || (_cache[0] = _createElementVNode("span", { class: "label" }, "Phone", -1)),
            _cache[1] || (_cache[1] = _createElementVNode("span", { class: "dots" }, null, -1)),
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.borrower.phoneNumber || 'N/A'), 1)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[2] || (_cache[2] = _createElementVNode("span", { class: "label" }, "Email", -1)),
            _cache[3] || (_cache[3] = _createElementVNode("span", { class: "dots" }, null, -1)),
            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.borrower.emailAddress || 'N/A'), 1)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _cache[4] || (_cache[4] = _createElementVNode("span", { class: "label" }, "SSN", -1)),
            _cache[5] || (_cache[5] = _createElementVNode("span", { class: "dots" }, null, -1)),
            _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.borrower.nationalIdNumberFormatted), 1)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _cache[6] || (_cache[6] = _createElementVNode("span", { class: "label" }, "Birthdate", -1)),
            _cache[7] || (_cache[7] = _createElementVNode("span", { class: "dots" }, null, -1)),
            _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.borrower.dateOfBirthFormatted), 1)
          ]),
          _createElementVNode("div", _hoisted_13, [
            _cache[8] || (_cache[8] = _createElementVNode("span", { class: "label" }, "Address", -1)),
            _cache[9] || (_cache[9] = _createElementVNode("span", { class: "dots" }, null, -1)),
            _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.borrower.address), 1)
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_15, _cache[10] || (_cache[10] = [
          _createElementVNode("p", null, "Loan does not have a Co-borrower", -1)
        ])))
  ]))
}
}

})