import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "xcommentbox-main xform-section" }
const _hoisted_2 = { class: "xfields" }
const _hoisted_3 = { class: "xfield form-field" }
const _hoisted_4 = { class: "xcheck" }
const _hoisted_5 = ["checked"]

import { ref } from 'vue';

	interface Props {
		onFormSubmit: any;
		form: {
			comment: string;
			approved: boolean;
		}
	}

	
export default /*@__PURE__*/_defineComponent({
  __name: 'StatusReviewForm',
  props: {
    onFormSubmit: {},
    form: {}
  },
  setup(__props: any) {

	const props = __props;
	
	const form = ref(props.form);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onFormSubmit && _ctx.onFormSubmit(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[5] || (_cache[5] = _createElementVNode("label", { for: "comment" }, [
            _createTextVNode(" Comment "),
            _createElementVNode("i", null, "*")
          ], -1)),
          _withDirectives(_createElementVNode("textarea", {
            id: "comment",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.value.comment) = $event)),
            maxlength: "512",
            required: ""
          }, "\t\t\t\t\t", 512), [
            [_vModelText, form.value.comment]
          ]),
          _createElementVNode("label", _hoisted_4, [
            _cache[3] || (_cache[3] = _createTextVNode(" Approve ")),
            _withDirectives(_createElementVNode("input", {
              type: "checkbox",
              checked: form.value.approved,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((form.value.approved) = $event))
            }, null, 8, _hoisted_5), [
              [_vModelCheckbox, form.value.approved]
            ]),
            _cache[4] || (_cache[4] = _createElementVNode("span", null, null, -1))
          ])
        ])
      ]),
      _cache[6] || (_cache[6] = _createElementVNode("button", {
        type: "submit",
        class: "submit"
      }, " Submit ", -1))
    ], 32)
  ]))
}
}

})