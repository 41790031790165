import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "xincome-sections" }
const _hoisted_2 = { class: "liabilities-table" }
const _hoisted_3 = ["onUpdate:modelValue", "onChange"]
const _hoisted_4 = ["value"]

import { computed, reactive } from "vue";
import useFormatter from "@/use/formatter";
import { LOSApplication } from "@/models/opal";
import { $api } from "@/services/api1";
import { onMounted } from "vue";
import { nextTick } from "vue";
import { watch } from "vue";
import CreditDebt from "@/components/credit/CreditDebt.vue";
import CreditDebtToIncome from "@/components/credit/CreditDebtToIncome.vue";
import CreditIncome from "@/components/credit/CreditIncome.vue";
import { onBeforeMount } from "vue";
import functions from "@/use/functions";

interface Props {
  applicants: any;
  application: LOSApplication,
}

export default /*@__PURE__*/_defineComponent({
  __name: 'CreditLiabilities',
  props: {
    applicants: {},
    application: {}
  },
  emits: ["setLiabilities", "getCreditReportData", "applicantLiabilityUpdated"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit
const applicant = computed(() => props?.applicants[props?.applicants?.selected]);
const creditReport = computed(() => props?.application?.creditReport[props?.applicants?.selected]);
const liabilities = computed(() => props?.application?.creditReport[props?.applicants?.selected]?.applicantLiabilities);
const creditLiabilities = computed(() => props?.application?.creditReport[props?.applicants?.selected]?.creditReportLiabilities);
const primaryApplicant = computed(() => props?.applicants["primary"]);
const secondaryApplicant = computed(() => props?.applicants["secondary"]);
const percentageOptions = reactive([
  { label: "0%", value: 0 },
  { label: "50%", value: 50 / 100 },
  { label: "100%", value: 100 / 100 },
]);

let applicantLiabilities = reactive([] as any[]);
let creditReportLiabilities = reactive([] as any[]);
let info = reactive({} as any);
async function getLiabilities() {
  if (props?.application?.creditReport[props?.applicants?.selected] === undefined) {
    emit('getCreditReportData');
  }

  if (creditReport?.value?.creditReportId && creditReport?.value?.creditReportId > 0) {
    creditReportLiabilities = await $api.applicants.getLiabilitiesByCreditId(applicant.value?.id, creditReport.value?.creditReportId);
  }

  applicantLiabilities = await $api.applicants.getLiabilitiesByApplicantId(applicant.value?.id);

  emit('setLiabilities', {
    applicantLiabilities: applicantLiabilities,
    creditReportLiabilities: creditReportLiabilities
  });
}

async function handleLiabilityPercentageChange(event, liability) {
  const updateApplicantLiabilityRequest = {
    applicantId: liability.applicantId,
    applicantLiabilityId: liability.id,
    includePercentage: liability.includePercentage
  }

  const updateApplicantLiabilityResponse = await $api.applicants.updateApplicantLiability(liability.applicantId, updateApplicantLiabilityRequest);
  if (updateApplicantLiabilityResponse.errors) {
    functions.openModal({
      title: "Error",
      description: "There was an issue updating the liability",
    });
    // revert back to original
    liability.includePercentage = props?.application?.creditReport[props?.applicants?.selected]?.applicantLiabilities?.find((l: any) => l.id === liability.id)?.includePercentage
    console.log(`Error: updateApplicantLiability()`, updateApplicantLiabilityResponse.errors);
  }

  if (updateApplicantLiabilityResponse && updateApplicantLiabilityResponse?.id) {
    emit('applicantLiabilityUpdated');
  }
}

const headers = [
  { name: "TradeName", label: "Trade Name", colspan: 0 },
  { name: "AccountNumber", label: "Account Number", colspan: 0 },
  { name: "ECOAIndicator", label: "ECOA Indicator", colspan: 0 },
  { name: "AccountType", label: "Account Type", colspan: 0 },
  { name: "CBRBalance", label: "CBR Balance", colspan: 0 },
  { name: "MonthlyObligation", label: "Monthly Obligation", colspan: 0 },
  { name: "Percentage", label: "Percentage", colspan: 0 },
];

onMounted(() => {
  getLiabilities();
});

onBeforeMount(() => {
  liabilities.value?.forEach((liability: any) => {
    info[liability.id] = liability.includePercentage || 0;
  });
})
watch(
  () => props?.applicants?.selected,
  () => {
    getLiabilities();
  }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(CreditDebtToIncome, {
        primaryApplicant: primaryApplicant.value,
        secondaryApplicant: secondaryApplicant.value,
        applicants: _ctx.applicants
      }, null, 8, ["primaryApplicant", "secondaryApplicant", "applicants"]),
      _createVNode(CreditIncome, {
        primaryApplicant: primaryApplicant.value,
        secondaryApplicant: secondaryApplicant.value,
        applicants: _ctx.applicants
      }, null, 8, ["primaryApplicant", "secondaryApplicant", "applicants"]),
      _createVNode(CreditDebt, {
        application: _ctx.application,
        primaryApplicant: primaryApplicant.value,
        secondaryApplicant: secondaryApplicant.value,
        applicants: _ctx.applicants
      }, null, 8, ["application", "primaryApplicant", "secondaryApplicant", "applicants"])
    ]),
    _withDirectives(_createElementVNode("table", _hoisted_2, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(headers, (header) => {
            return _createElementVNode("td", {
              key: header.name
            }, _toDisplayString(header.label), 1)
          }), 64))
        ])
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(liabilities.value, (liability) => {
        return (_openBlock(), _createElementBlock("tr", {
          key: liability?.id
        }, [
          _createElementVNode("td", null, _toDisplayString(liability.creditorName), 1),
          _createElementVNode("td", null, _toDisplayString(liability.accountNumber), 1),
          _createElementVNode("td", null, _toDisplayString(liability.accountOwnerTypeShortCode), 1),
          _createElementVNode("td", null, _toDisplayString(liability.liabilityTypeName), 1),
          _createElementVNode("td", null, _toDisplayString(liability.currentBalanceAmount), 1),
          _createElementVNode("td", null, _toDisplayString(liability.debtAmount), 1),
          _createElementVNode("td", null, [
            _withDirectives(_createElementVNode("select", {
              "onUpdate:modelValue": ($event: any) => ((liability.includePercentage) = $event),
              onChange: ($event: any) => (handleLiabilityPercentageChange($event, liability))
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(percentageOptions, (option) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: option.value,
                  value: option.value
                }, _toDisplayString(option.label), 9, _hoisted_4))
              }), 128))
            ], 40, _hoisted_3), [
              [
                _vModelSelect,
                liability.includePercentage,
                void 0,
                { number: true }
              ]
            ])
          ])
        ]))
      }), 128))
    ], 512), [
      [_vShow, liabilities.value && liabilities.value?.length > 0]
    ])
  ]))
}
}

})