import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"



	
export default /*@__PURE__*/_defineComponent({
  __name: 'AnnualReviewTab',
  props: {
    loanId: {}
  },
  setup(__props: any) {

	const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div"))
}
}

})