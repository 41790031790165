import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, vModelSelect as _vModelSelect, vShow as _vShow, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = { class: "xpage-top-details-status-container" }
const _hoisted_2 = { class: "xpage-top-details-status" }
const _hoisted_3 = {
  key: 0,
  class: "icon-up-open-big"
}
const _hoisted_4 = {
  key: 1,
  class: "icon-down-open-big"
}
const _hoisted_5 = {
  key: 0,
  class: "xheader-search-dropdown"
}
const _hoisted_6 = { class: "application-st" }
const _hoisted_7 = ["disabled", "checked", "onInput"]
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "xdropdown-buttons" }
const _hoisted_10 = {
  key: 1,
  class: "denial-reason-box"
}
const _hoisted_11 = { class: "grid-container" }
const _hoisted_12 = { class: "column denial-reasons-column" }
const _hoisted_13 = { class: "new-denial-reason-entry" }
const _hoisted_14 = ["disabled"]
const _hoisted_15 = ["value"]
const _hoisted_16 = { class: "add-clear-buttons" }
const _hoisted_17 = ["disabled"]
const _hoisted_18 = {
  key: 0,
  class: "max-reasons-message"
}
const _hoisted_19 = { class: "column requested-for-column" }
const _hoisted_20 = { class: "applicant-labels-row" }
const _hoisted_21 = { class: "label-semi-bold" }
const _hoisted_22 = { class: "checkbox-container" }
const _hoisted_23 = ["value", "checked", "onChange", "disabled"]
const _hoisted_24 = { class: "added-denial-reasons-container" }
const _hoisted_25 = {
  key: 0,
  class: "added-denial-reasons"
}
const _hoisted_26 = { class: "reason-content" }
const _hoisted_27 = { class: "reason-number" }
const _hoisted_28 = { class: "reason-description" }
const _hoisted_29 = { class: "reason-applicants" }
const _hoisted_30 = ["onClick"]
const _hoisted_31 = ["required"]
const _hoisted_32 = { class: "xdropdown-buttons" }

import {
  computed,
  ref,
  reactive,
  onBeforeMount,
  watch,
  onMounted,
  nextTick,
} from "vue";
import { FormSchema } from "@/models/form";
import { $api } from "@/services/api1";
import { JudgmentalDenial } from "@/models/opal";
import { useApplicationStore } from "@/store/store/applicationVMStore";
import { ApplicationStatusIds } from "@/types/application";
import { storeToRefs } from "pinia";
import { Type, LOSType } from "@/types/types";
import { useTypeStore } from "@/store/store/typeStore";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import functions from "@/use/functions";
import useFunctions from "@/use/functions1";

interface DenialReasonEntry {
  reasonId: number;
  applicants: number[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ApplicationStatus',
  setup(__props) {

const { decodeHTMLEncodedStr } = useFunctions();

const store = useStore();
const route = useRoute();
const applicationStore = useApplicationStore();
const typeStore = useTypeStore();
const { viewModel } = storeToRefs(applicationStore);

// State
let checkboxFieldsSchema = reactive({} as FormSchema);
let denialReasons = reactive({} as any);
let selectedDenialReasons = ref([] as any[]);
let selectedDenialReasonIds = ref([] as number[]);
let reasonIds = ref([] as number[]);

const applicationJudgmentDenials = ref<JudgmentalDenial[]>([]);
const denialReasonEntries = ref<DenialReasonEntry[]>([]);
const selectedReason = ref<number | null>(null);

const previouslySelectedReasonIds = ref<number[]>([]);
const previouslySelectedReasons = ref<string[]>([]);
const showDenialReasonSection = ref<boolean>(false);
const showDenialReasonDropdown = ref<boolean>(false);
const showStatusDropdown = ref<boolean>(false);

const jdts = ref([] as LOSType[]);

const info = reactive({} as any);

const applicants = computed(() => applicationStore.Applicants);

const showDenialReasonForm = computed(
  () =>
    showStatusDropdown.value &&
    info.newApplicationStatusId === ApplicationStatusIds.DECLINED
);

const otherDenialReasonSelected = computed(() =>
  denialReasonEntries.value.some((entry) => entry.reasonId === 36)
);

const totalDenialReasons = computed(() => denialReasonEntries.value.length);

const applicationStatuses = computed<Array<LOSType>>(() =>
  applicationStore.summary.applicationStatuses.filter(
    (s: LOSType) => s.visible === true
  )
);

const currentApplicationStatusId = computed<number>(
  () => applicationStore.summary.statusId
);

const getDenialReasonName = (id: number) => {
  return jdts.value.find((jdt) => jdt.id === id)?.description;
};

async function getDenialReasons() {
  if (currentApplicationStatusId.value === ApplicationStatusIds.DECLINED) {
    let alreadySelectedDenialReasons =
      await $api.applications.getApplicationJudgementDenials(
        applicationStore.applicationId
      );

    if (
      alreadySelectedDenialReasons &&
      alreadySelectedDenialReasons?.judgmentDenialTypes?.length > 0
    ) {
      showDenialReasonDropdown.value = true;
      applicationJudgmentDenials.value =
        alreadySelectedDenialReasons?.judgmentDenialTypes;

      alreadySelectedDenialReasons?.judgmentDenialTypes?.forEach(
        (jdt: JudgmentalDenial) => {
          previouslySelectedReasons.value.push(jdt.judgmentDenialTypeName);
          previouslySelectedReasonIds.value.push(jdt.judgmentDenialTypeId);

          previouslySelectedReasons.value = [
            ...new Set(previouslySelectedReasons.value),
          ];
          previouslySelectedReasonIds.value = [
            ...new Set(previouslySelectedReasonIds.value),
          ];
        }
      );

      reasonIds.value = previouslySelectedReasonIds.value;
    }
  }
}

async function cancel() {
  showStatusDropdown.value = false;
  showDenialReasonSection.value = false;
  showDenialReasonDropdown.value = false;
  await resetDropdown();
  await nextTick();
}

function resetDropdown() {
  info.newApplicationStatusId = undefined;
  info.newStatusName = undefined;
  info.statusComment = undefined;
  info.otherDenialReason = undefined;
  resetDenialReasons();
  setApplicationStatus();
}

async function setApplicationStatus() {
  info.applicationStatusName = viewModel.value.statusName;

  if (info.applicationStatusName === "Declined") {
    showDenialReasonSection.value = true;
    showDenialReasonDropdown.value = true;
    await getDenialReasons();
  } else {
    resetDenialReasons();
    showDenialReasonSection.value = false;
    showDenialReasonDropdown.value = false;
  }
}

async function updateJudgmentalDenials() {
  const ApplicantJDTypeIds = denialReasonEntries.value
    .flatMap((entry) =>
      entry.applicants.map((applicantId) => ({
        applicantTypeId: applicants.value.find((a) => a.id === applicantId)
          ?.applicantTypeId,
        judgmentDenialTypeId: entry.reasonId,
      }))
    )
    .filter(
      (item) =>
        item.applicantTypeId !== undefined && item.judgmentDenialTypeId !== null
    );

  const applicationJudgementDenialRequest = {
    applicationId: applicationStore.applicationId,
    ApplicantJDTypeIds,
    otherJudgmentTypeReasonText: info?.otherDenialReason || "",
  };

  const response = await $api.applications.addOrUpdateJudgementDenials(
    applicationStore.applicationId,
    applicationJudgementDenialRequest
  );

  if (response.errors || !response.applicationId) {
    functions.openModal({
      title: "Error",
      description: "There was an issue updating the Application Status",
      buttons: [
        {
          title: "Close",
          onClick: async () => {
            functions.closeModal();
            await resetDropdown();
          },
        },
      ],
    });
    console.log(`Error: updateApplicationStatus()`, response.errors);
    return false;
  } else {
    const referenceId = applicationStore.referenceId;
    await Promise.all([
      applicationStore.getApplication(referenceId),
      applicationStore.getApplicationSummary(referenceId),
      applicationStore.getApplicationComments(referenceId),
    ]);

    return true;
  }
}

async function updateApplicationStatus() {
  let userDisplayName = `${decodeHTMLEncodedStr(
    store.state.oidcStore.user.given_name
  )} ${decodeHTMLEncodedStr(store.state.oidcStore.user.family_name)}`;

  let request = {
    id: 0,
    applicationId: applicationStore.application?.detailViewModel.id,
    comment: info.statusComment,
    userId: store.state.oidcStore.user.sub,
    userDisplayName: userDisplayName,
  };
  const response = await $api.applications.updateApplicationStatus(
    applicationStore.applicationId,
    info?.newApplicationStatusId,
    request
  );
  if (response.errors || !response.applicationId) {
    functions.openModal({
      title: "Error",
      description: "There was an issue updating the Application Status",
    });
    console.log(`Error: updateApplicationStatus()`, response.errors);
    return false;
  } else {
    const referenceId =
      applicationStore.referenceId || route.params.referenceId.toString();
    await Promise.all([
      applicationStore.getApplication(referenceId),
      applicationStore.getApplicationSummary(referenceId),
      applicationStore.getApplicationComments(referenceId),
    ]);

    return true;
  }
}

async function saveStatusChange() {
  functions.setModalLoading();

  if (info.newApplicationStatusId === ApplicationStatusIds.DECLINED) {
    const applicantJDTypes = denialReasonEntries.value.flatMap((entry) =>
      entry.applicants.map((applicant) => ({
        applicantTypeId: applicants.value.find((a) => a.id === applicant)
          ?.applicantTypeId,
        judgmentDenialTypeId: entry.reasonId,
      }))
    );

    if (applicantJDTypes.length === 0) {
      functions.openModal({
        title: "Alert",
        description: "Please select at least one denial reason.",
      });
      return;
    }
  }

  let response = false;
  if (
    info.newApplicationStatusId &&
    currentApplicationStatusId.value !== info.newApplicationStatusId
  ) {
    response = await updateApplicationStatus();
    if (!response) return;
  }
  if (info.newApplicationStatusId === ApplicationStatusIds.DECLINED) {
    response = await updateJudgmentalDenials();
  }
  if (response) {
    window.scrollTo(0, 0);
    functions.closeModal();
    showStatusDropdown.value = false;
    functions.setModalLoading(false);

    functions.openModal({
      title: "Success!",
      description: "The Application Status has been changed!",
      buttons: [
        {
          title: "Close",
          onClick: async () => {
            functions.closeModal();
            showStatusDropdown.value = false;
            info.statusComment = undefined;
            info.otherDenialReason = undefined;
            denialReasonEntries.value = [];
            showDenialReasonSection.value = false;
          },
        },
      ],
    });
  }
}

function removeReason(index: number) {
  denialReasonEntries.value.splice(index, 1);
}

function addAnotherReason() {
  if (totalDenialReasons.value >= 4) {
    functions.openModal({
      title: "Alert",
      description: "You have reached the maximum of 4 denial reasons.",
    });
    return;
  }
  selectedReason.value = null;
}

function dropdownClicked() {
  if (!applicationStore.summary.disabledStatuses) {
    showStatusDropdown.value = !showStatusDropdown.value;

    resetDenialReasons();
    resetDropdown();

    if (currentApplicationStatusId.value === ApplicationStatusIds.DECLINED) {
      showDenialReasonSection.value = true;
      showDenialReasonDropdown.value = true;
    } else {
      showDenialReasonSection.value = false;
      showDenialReasonDropdown.value = false;
    }
  }
}

function handleInput(event: LOSType) {
  info.newStatusName = event.name;
  info.newApplicationStatusId = event.id;

  const allowedStatuses = [
    ApplicationStatusIds.EVALUATION,
    ApplicationStatusIds.SUBMITTED_AND_PROCESSING,
  ];

  if (info.newApplicationStatusId === ApplicationStatusIds.DECLINED) {
    if (!allowedStatuses.includes(currentApplicationStatusId.value)) {
      functions.openModal({
        title: "Alert",
        description:
          "This status change is not allowed from the current application status",
        buttons: [
          {
            title: "Close",
            onClick: () => {
              functions.closeModal();
              closeDropdown();
              resetDropdown();
            },
          },
        ],
      });
    } else {
      resetDenialReasons();
      showDenialReasonSection.value = true;
      showDenialReasonDropdown.value = true;
    }
  } else {
    resetDenialReasons();
    showDenialReasonSection.value = false;
    showDenialReasonDropdown.value = false;
    if (info.newApplicationStatusId === currentApplicationStatusId.value) {
      info.newStatusName = undefined;
      info.newApplicationStatusId = undefined;
    } else {
      return;
    }
  }
}

function closeDropdown() {
  showStatusDropdown.value = false;
}

function isApplicantSelectedForReason(applicantId: number): boolean {
  if (selectedReason.value == null) {
    return false;
  }
  return denialReasonEntries.value.some(
    (entry) =>
      entry.reasonId === selectedReason.value &&
      entry.applicants.includes(applicantId)
  );
}

function resetDenialReasons() {
  selectedDenialReasons.value = [];
  selectedDenialReasonIds.value = [];

  for (let [key, value] of Object.entries(denialReasons)) {
    denialReasons[key] = false;
  }
}

function getApplicantNames(applicantIds: number[]): string[] {
  return applicants.value
    .filter((applicant) => applicantIds.includes(applicant.id))
    .map((applicant) => applicant.applicantTypeName);
}

function confirmStatusChange() {
  const isDeclinedStatus =
    info.newApplicationStatusId === ApplicationStatusIds.DECLINED ||
    (info.newApplicationStatusId === currentApplicationStatusId.value &&
      currentApplicationStatusId.value === ApplicationStatusIds.DECLINED);

  const description =
    isDeclinedStatus && applicationJudgmentDenials.value.length === 0
      ? "Selected decline reasons will be used on AA Notices and CANNOT be changed once you proceed. Are you sure you want to change this status?"
      : "Are you sure you want to change this status?";

  functions.openModal({
    title: "Confirm",
    description: description,
    buttons: [
      { title: "Cancel", onClick: () => functions.closeModal() },
      { title: "Save Changes", onClick: () => saveStatusChange() },
    ],
  });
}

function submit() {
  const isDeclinedStatus =
    info?.newApplicationStatusId === ApplicationStatusIds.DECLINED ||
    (info?.newStatusName === undefined &&
      currentApplicationStatusId.value === ApplicationStatusIds.DECLINED);

  const cleanedComment = info?.statusComment?.trim() || undefined;
  info.statusComment = cleanedComment;

  if (isDeclinedStatus && denialReasonEntries.value.length === 0) {
    functions.openModal({
      title: "Alert",
      description: "Please select at least one denial reason.",
    });
    return;
  }

  if (
    isDeclinedStatus &&
    previouslySelectedReasonIds.value.length === reasonIds.value.length &&
    !cleanedComment
  ) {
    functions.openModal({
      title: "Alert",
      description: "No changes were made. Do you want to make changes?",
      buttons: [
        {
          title: "Yes",
          onClick: async () => functions.closeModal(),
        },
        {
          title: "No",
          onClick: async () => {
            functions.closeModal();
            showStatusDropdown.value = false;
            await resetDropdown();
          },
        },
      ],
    });
    return;
  }

  if (
    (cleanedComment && cleanedComment.length < 5) ||
    (isDeclinedStatus && cleanedComment && cleanedComment.length < 5)
  ) {
    functions.openModal({
      title: "Alert",
      description:
        "A comment of at least 5 characters is required to change the status!",
    });
    return;
  }

  confirmStatusChange();
}

function toggleApplicantForReason(applicantId: number, event: Event) {
  if (!selectedReason.value) {
    functions.openModal({
      title: "Alert",
      description: "Please select a denial reason first.",
    });
    (event.target as HTMLInputElement).checked = false;
    return;
  }

  const isChecked = (event.target as HTMLInputElement).checked;

  const existingEntryIndex = denialReasonEntries.value.findIndex(
    (entry) => entry.reasonId === selectedReason.value
  );

  if (isChecked) {
    const existingEntry = denialReasonEntries.value.some(
      (entry) =>
        entry.reasonId === selectedReason.value &&
        entry.applicants.includes(applicantId)
    );

    if (!existingEntry) {
      denialReasonEntries.value.push({
        reasonId: selectedReason.value as number,
        applicants: [applicantId],
      });
    } else {
      const entryIndex = denialReasonEntries.value.findIndex(
        (entry) =>
          entry.reasonId === selectedReason.value &&
          entry.applicants.includes(applicantId)
      );
      if (entryIndex !== -1) {
        const entry = denialReasonEntries.value[entryIndex];
        entry.applicants = entry.applicants.filter((id) => id !== applicantId);

        if (entry.applicants.length === 0) {
          denialReasonEntries.value.splice(entryIndex, 1);
        }
      }
    }

    if (totalDenialReasons.value > 4) {
      functions.openModal({
        title: "Alert",
        description: "You have reached the maximum of 4 denial reasons.",
      });
    }
  }
}

watch(
  denialReasonEntries,
  (newEntries) => {
    reasonIds.value = newEntries
      .map((entry) => entry.reasonId)
      .filter((id): id is number => id !== null);
  },
  { immediate: true }
);

onBeforeMount(() => {
  if (typeStore.getTypes(Type.JudgmentDenials)) {
    typeStore.getTypes(Type.JudgmentDenials).forEach((jdt: any) => {
      checkboxFieldsSchema[jdt.name] = {
        type: "checkbox",
        name: jdt.name,
        label: jdt.description,
        required: false,
        hideLabel: true,
      };
    });
  }
});

onMounted(() => {
  const denialTypes = typeStore.getTypes(Type.JudgmentDenials);
  if (denialTypes) {
    jdts.value = denialTypes.sort((a, b) => {
      const isAOther = a.description?.toLowerCase().includes("other");
      const isBOther = b.description?.toLowerCase().includes("other");
      if (isAOther && !isBOther) return 1;
      if (isBOther && !isAOther) return -1;
      return (a.description ?? "").localeCompare(b.description ?? "");
    });
  }
});

return (_ctx: any,_cache: any) => {
  const _directive_auto_animate = _resolveDirective("auto-animate")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h4", { onClick: dropdownClicked }, [
        _createTextVNode(_toDisplayString(_unref(viewModel)?.statusName) + " ", 1),
        (showStatusDropdown.value)
          ? (_openBlock(), _createElementBlock("i", _hoisted_3))
          : (_openBlock(), _createElementBlock("i", _hoisted_4))
      ]),
      (showStatusDropdown.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("form", {
                onSubmit: _withModifiers(submit, ["prevent"]),
                autocomplete: "off"
              }, [
                _createElementVNode("nav", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(applicationStatuses.value, (status) => {
                    return (_openBlock(), _createElementBlock("label", {
                      class: "xradio label-text",
                      key: status.name
                    }, [
                      _createTextVNode(_toDisplayString(status.name) + " ", 1),
                      _createElementVNode("input", {
                        type: "radio",
                        disabled: status.disabled,
                        name: "radio-status",
                        checked: status.selected,
                        onInput: ($event: any) => (handleInput(status))
                      }, null, 40, _hoisted_7),
                      _cache[4] || (_cache[4] = _createElementVNode("span", null, null, -1))
                    ]))
                  }), 128)),
                  (info.newStatusName !== 'Declined')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _cache[5] || (_cache[5] = _createElementVNode("h3", {
                          class: "smallcapitals",
                          style: {"padding-top":"10px"}
                        }, " Reason For Change: ", -1)),
                        _createElementVNode("div", null, [
                          _withDirectives(_createElementVNode("textarea", {
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((info.statusComment) = $event)),
                            class: "reason-input"
                          }, "                  ", 512), [
                            [_vModelText, info.statusComment]
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_9, [
                          _createElementVNode("button", {
                            onClick: _withModifiers(cancel, ["prevent"])
                          }, "Cancel"),
                          _createElementVNode("button", {
                            onClick: _withModifiers(submit, ["prevent"])
                          }, "Save Changes")
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ], 32)
            ])
          ]))
        : _createCommentVNode("", true),
      (showDenialReasonForm.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _cache[8] || (_cache[8] = _createElementVNode("span", { class: "label-head" }, [
                  _createTextVNode("Denial Reason(s)"),
                  _createElementVNode("i", { style: {"color":"crimson"} }, "*")
                ], -1)),
                _cache[9] || (_cache[9] = _createElementVNode("p", null, "Please select a reason for the denial from the list below.", -1)),
                _cache[10] || (_cache[10] = _createElementVNode("span", { class: "label-semi-bold" }, "Denial Reason List:", -1)),
                _createElementVNode("div", _hoisted_13, [
                  _withDirectives(_createElementVNode("select", {
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((selectedReason).value = $event)),
                    disabled: totalDenialReasons.value >= 4
                  }, [
                    _cache[6] || (_cache[6] = _createElementVNode("option", {
                      value: null,
                      disabled: ""
                    }, "Not Selected", -1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(jdts.value, (jdt) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: jdt.id,
                        value: jdt.id
                      }, _toDisplayString(jdt.description), 9, _hoisted_15))
                    }), 128))
                  ], 8, _hoisted_14), [
                    [_vModelSelect, selectedReason.value]
                  ])
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("button", {
                    onClick: _withModifiers(addAnotherReason, ["prevent"]),
                    disabled: totalDenialReasons.value >= 4,
                    class: "add-btn"
                  }, " + Add Another Reason ", 8, _hoisted_17)
                ]),
                (totalDenialReasons.value >= 4)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_18, _cache[7] || (_cache[7] = [
                      _createElementVNode("p", null, "You have reached the maximum of 4 denial reasons.", -1)
                    ])))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_19, [
                _cache[11] || (_cache[11] = _createElementVNode("span", { class: "label-head" }, [
                  _createTextVNode("Requested For"),
                  _createElementVNode("i", { style: {"color":"crimson"} }, "*")
                ], -1)),
                _cache[12] || (_cache[12] = _createElementVNode("p", null, "Select at least one applicant to apply the denial reason(s).", -1)),
                _createElementVNode("div", _hoisted_20, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(applicants.value, (applicant) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "applicant-column",
                      key: applicant.id
                    }, [
                      _createElementVNode("span", _hoisted_21, _toDisplayString(applicant.applicantTypeName), 1),
                      _createElementVNode("div", _hoisted_22, [
                        _createElementVNode("input", {
                          type: "checkbox",
                          value: applicant.id,
                          checked: isApplicantSelectedForReason(applicant.id),
                          onChange: ($event: any) => (toggleApplicantForReason(applicant.id, $event)),
                          disabled: !selectedReason.value
                        }, null, 40, _hoisted_23)
                      ])
                    ]))
                  }), 128))
                ])
              ])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_24, [
                (denialReasonEntries.value.length > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                      _createElementVNode("ul", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(denialReasonEntries.value, (entry, index) => {
                          return (_openBlock(), _createElementBlock("li", {
                            key: index,
                            class: "added-denial-reason"
                          }, [
                            _createElementVNode("div", _hoisted_26, [
                              _createElementVNode("span", _hoisted_27, _toDisplayString(index + 1) + ".", 1),
                              _createElementVNode("span", _hoisted_28, _toDisplayString(entry.reasonId !== null
                        ? getDenialReasonName(entry.reasonId)
                        : "Unknown"), 1),
                              _createElementVNode("span", _hoisted_29, [
                                _cache[13] || (_cache[13] = _createTextVNode(" - Applicant: ")),
                                _createElementVNode("span", null, _toDisplayString(getApplicantNames(entry.applicants).join(", ")), 1)
                              ])
                            ]),
                            _createElementVNode("button", {
                              onClick: _withModifiers(($event: any) => (removeReason(index)), ["prevent"]),
                              class: "remove-reason"
                            }, " X ", 8, _hoisted_30)
                          ]))
                        }), 128))
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _cache[15] || (_cache[15] = _createElementVNode("h3", {
                class: "smallcapitals",
                style: {"padding-top":"10px"}
              }, [
                _createTextVNode(" Reason For Change:"),
                _createElementVNode("i", { style: {"color":"crimson"} }, "*")
              ], -1)),
              _createElementVNode("div", null, [
                _withDirectives(_createElementVNode("textarea", {
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((info.statusComment) = $event)),
                  class: "reason-input"
                }, "            ", 512), [
                  [_vModelText, info.statusComment]
                ])
              ]),
              _withDirectives(_createElementVNode("div", null, [
                _cache[14] || (_cache[14] = _createElementVNode("h3", { class: "smallcapitals" }, [
                  _createTextVNode(" Other Reason:"),
                  _createElementVNode("i", { style: {"color":"crimson"} }, "*")
                ], -1)),
                _withDirectives(_createElementVNode("textarea", {
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((info.otherDenialReason) = $event)),
                  required: otherDenialReasonSelected.value,
                  maxlength: "128",
                  class: "reason-input"
                }, "            ", 8, _hoisted_31), [
                  [_vModelText, info.otherDenialReason]
                ])
              ], 512), [
                [_vShow, otherDenialReasonSelected.value]
              ]),
              _createElementVNode("div", _hoisted_32, [
                _createElementVNode("button", {
                  onClick: _withModifiers(cancel, ["prevent"])
                }, "Cancel"),
                _createElementVNode("button", {
                  onClick: _withModifiers(submit, ["prevent"])
                }, "Save Changes")
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ])), [
    [_directive_auto_animate, _ctx.$animate]
  ])
}
}

})