import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "breadcrumb-container" }
const _hoisted_2 = { "aria-label": "breadcrumb" }
const _hoisted_3 = { class: "breadcrumb" }
const _hoisted_4 = { class: "breadcrumb-item" }
const _hoisted_5 = {
  key: 0,
  class: "breadcrumb-separator"
}
const _hoisted_6 = { key: 2 }
const _hoisted_7 = {
  key: 0,
  class: "breadcrumb-item"
}
const _hoisted_8 = { class: "current" }

import { computed } from 'vue';
	import { useRoute } from 'vue-router';

	interface Breadcrumb {
		label: string;
		to: { path: string };
		isActive: boolean;
	}

	
export default /*@__PURE__*/_defineComponent({
  __name: 'Breadcrumbs',
  props: {
    rootPath: {}
  },
  setup(__props: any) {

	const props = __props;

	const route = useRoute();

	const breadcrumbs = computed<Breadcrumb[]>(() => {
		let accumulatedPath = props.rootPath;

		return route.matched.map((match, index): Breadcrumb => {
			const label = (match.meta?.breadcrumb as string) || match.name?.toString() || '???';

			if (index > 0) {
				accumulatedPath += `/${match.path.split('/').pop()}`;
			}

			return {
				label,
				to: { path: accumulatedPath },
				isActive: index === route.matched.length
			};
		});
	});

	const parameterBreadcrumb = computed(() => {
		const paramValue = route.params.id || route.params.referenceId;
		return paramValue && paramValue !== breadcrumbs.value[breadcrumbs.value.length - 1]?.label ? paramValue : null;
	});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("nav", _hoisted_2, [
      _createElementVNode("ol", _hoisted_3, [
        _createElementVNode("li", _hoisted_4, [
          _createVNode(_component_router_link, {
            to: { path: _ctx.rootPath }
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createElementVNode("span", { class: "icon-home" }, null, -1)
            ])),
            _: 1
          }, 8, ["to"])
        ]),
        _cache[2] || (_cache[2] = _createElementVNode("span", { class: "breadcrumb-separator" }, " > ", -1)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(breadcrumbs.value, (breadcrumb, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: index,
            class: "breadcrumb-item"
          }, [
            (index > 0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, " > "))
              : _createCommentVNode("", true),
            (!breadcrumb.isActive)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 1,
                  to: breadcrumb.to
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(breadcrumb.label), 1)
                  ]),
                  _: 2
                }, 1032, ["to"]))
              : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(breadcrumb.label), 1))
          ]))
        }), 128)),
        (parameterBreadcrumb.value)
          ? (_openBlock(), _createElementBlock("li", _hoisted_7, [
              _cache[1] || (_cache[1] = _createElementVNode("span", { class: "breadcrumb-separator" }, " > ", -1)),
              _createElementVNode("span", _hoisted_8, _toDisplayString(parameterBreadcrumb.value), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})