import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "xform-section" }



	interface Props {
		onFormSubmit: any;
	}

	
export default /*@__PURE__*/_defineComponent({
  __name: 'PriorityStatusForm',
  props: {
    onFormSubmit: {}
  },
  setup(__props: any) {

	const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[2] || (_cache[2] = _createElementVNode("h3", null, " Priority Application ", -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("form", {
        onSubmit: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onFormSubmit && _ctx.onFormSubmit(...args)), ["prevent"]))
      }, _cache[1] || (_cache[1] = [
        _createElementVNode("button", {
          type: "submit",
          class: "submit"
        }, " Move to Managerial Review ", -1)
      ]), 32)
    ])
  ], 64))
}
}

})