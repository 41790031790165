import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "xpage-content-page",
  id: "xoverrides-tab"
}
const _hoisted_2 = { class: "xtitlearea" }
const _hoisted_3 = { class: "xpage-content" }

import { ref, onMounted, computed } from "vue";
import ApplicationComments from "@/components/ApplicationComments.vue";
import axios from "axios";
import OverridesReview from "./archive/OverridesReview.vue";
import OverridesAdditionalLOC from "@/components/overrides/archive/OverridesAdditionalLOC.vue";
import { ApplicationStatusIds } from "@/types/application";

interface Props {
  isTabActive: boolean;
  commentsId: any;
  commentsComponentKey: any;
  applicants: any;
  application: any;
  canEdit: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'OverridesTab',
  props: {
    isTabActive: { type: Boolean },
    commentsId: {},
    commentsComponentKey: {},
    applicants: {},
    application: {},
    canEdit: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;
const fullService = ref(true);
const commentData = computed(() => props.application.commentData);

const editable = computed(() =>
  [
    ApplicationStatusIds.SUBMITTED_AND_PROCESSING,
    ApplicationStatusIds.DECLINED,
    ApplicationStatusIds.CANCELLED,
  ].includes(props.application.data.applicationStatusId)
);

async function applicationsDetails() {
  const applicationId = props.application.data.id;
  const url = `/api/applications/${applicationId}/details`;
  try {
    const response = await axios.get(url);
    fullService.value = response.data.isFullService;
  } catch (error) {
    console.log("Error saving details:", error);
  }
}
// console.log('OverridesTab.vue status name:', props.application.data);
// console.log('application status id:', props.application.data.applicationStatusId);

onMounted(() => {
  applicationsDetails();
});

return (_ctx: any,_cache: any) => {
  return (_ctx.isTabActive)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[0] || (_cache[0] = _createElementVNode("h2", null, "Overrides", -1)),
          _createVNode(ApplicationComments, {
            type: "overrides",
            id: _ctx.commentsId,
            comments: commentData.value
          }, null, 8, ["id", "comments"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(OverridesReview, {
            canEdit: editable.value,
            applicants: props.applicants,
            application: props.application
          }, null, 8, ["canEdit", "applicants", "application"])
        ]),
        _createVNode(OverridesAdditionalLOC, {
          canEdit: editable.value,
          applicants: props.applicants,
          application: props.application
        }, null, 8, ["canEdit", "applicants", "application"])
      ]))
    : _createCommentVNode("", true)
}
}

})