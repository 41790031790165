import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "xwarningbox"
}

import { computed } from "vue";
import { Address, Applicant, LOSApplication, Stipulation } from "@/models/opal";
import useFunctions from "@/use/functions1";
interface Props {
  stipulation: Stipulation,
  application: LOSApplication,
}

export default /*@__PURE__*/_defineComponent({
  __name: 'AddressMismatch',
  props: {
    stipulation: {},
    application: {}
  },
  setup(__props: any) {

const props = __props;
const previousAddresses = computed(() => {
  const { decodeHTMLEncodedStr } = useFunctions();

 let applicant = props.application.applicants.find((s: Applicant) => s.id === props.stipulation.applicantId);
 let filteredAddresses = [] as Address[];

 if (applicant?.addresses && applicant?.addresses?.length > 0) {
  filteredAddresses = applicant?.addresses.filter((a: any) => a.addressTypeName === 'Previous Address');
  filteredAddresses.forEach((a: Address) => 
  {
    a.address1 = decodeHTMLEncodedStr(a.address1);
    a.address2 = decodeHTMLEncodedStr(a.address2);
    a.city = decodeHTMLEncodedStr(a.city);
 });
 }
  return filteredAddresses;
});


return (_ctx: any,_cache: any) => {
  return (previousAddresses.value && previousAddresses.value.length > 0)
    ? (_openBlock(), _createElementBlock("table", _hoisted_1, [
        _cache[1] || (_cache[1] = _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _createElementVNode("th", null, "Previous Addresses")
          ])
        ], -1)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(previousAddresses.value, (address, index) => {
          return (_openBlock(), _createElementBlock("tbody", { key: index }, [
            _createElementVNode("td", null, _toDisplayString([
          address.address1,
          address.address2,
          address.city,
          address.stateName,
          address.postalCode,
          address.countryName,
        ]
          .filter((n) => n)
          .join(", ") || "Not Provided"), 1),
            _cache[0] || (_cache[0] = _createElementVNode("td", null, null, -1))
          ]))
        }), 128))
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, "This applicant does not have any previous addresses in the database"))
}
}

})