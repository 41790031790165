import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "global-loading-overlay"
}

import { storeToRefs } from 'pinia';
import { useLoadingStore } from '@/store/store/loadingStore';


export default /*@__PURE__*/_defineComponent({
  __name: 'GlobalLoadingOverlay',
  setup(__props) {

const loadingStore = useLoadingStore();
const { isLoading } = storeToRefs(loadingStore);


return (_ctx: any,_cache: any) => {
  return (_unref(isLoading))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
        _createElementVNode("div", { class: "spinner" }, null, -1)
      ])))
    : _createCommentVNode("", true)
}
}

})