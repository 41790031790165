import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = {
  key: 0,
  class: "content"
}
const _hoisted_3 = ["value"]
const _hoisted_4 = {
  key: 1,
  class: "not-found"
}


	import { KeyDate } from '@/models/loans';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'ImportantDatesCard',
  props: {
    keyDates: {}
  },
  setup(__props: any) {

	

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "header" }, [
      _createElementVNode("h3", null, "Important Dates")
    ], -1)),
    (_ctx.keyDates.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.keyDates, (date, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "date-field",
              key: index
            }, [
              _createElementVNode("label", null, _toDisplayString(date.keyDateTypeHumanized), 1),
              _createElementVNode("input", {
                type: "text",
                placeholder: "MM/DD/YYYY",
                value: date.dateFormatted,
                readonly: ""
              }, null, 8, _hoisted_3)
            ]))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[0] || (_cache[0] = [
          _createElementVNode("h4", null, "No Dates Found", -1)
        ])))
  ]))
}
}

})