import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "stipulation-details" }
const _hoisted_2 = { class: "stipulation-details-content" }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 3 }

import { computed } from "vue";
import { Stipulation, LOSApplication } from "@/models/opal";
import { useStore } from "vuex";
import AddressMismatch from "@/components/stipulations/AddressMismatch.vue";
import StipVerification from "./StipVerification.vue";

interface Props {
	stipulation: Stipulation;
	application: LOSApplication;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'StipulationDetails',
  props: {
    stipulation: {},
    application: {}
  },
  setup(__props: any) {

const props = __props;

const store = useStore();

const hasStipVerification = computed(() => {
	return (
		(props.stipulation.addressInput?.fullAddress || props.stipulation.nameInput?.fullName || props.stipulation.dobInput?.dateOfBirth || props.stipulation.ssnInput?.ssn)
	);
});

const checkStipVerification = (stipulation: Stipulation): boolean => {
  return (
    !!stipulation.addressInput?.fullAddress ||
    !!stipulation.nameInput?.fullName ||
    !!stipulation.dobInput?.dateOfBirth ||
    !!stipulation.ssnInput?.ssn
  );
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.stipulation.stipulationTypeName === 'ADDMismatch')
        ? (_openBlock(), _createBlock(AddressMismatch, {
            key: 0,
            stipulation: _ctx.stipulation,
            application: _ctx.application
          }, null, 8, ["stipulation", "application"]))
        : (hasStipVerification.value)
          ? (_openBlock(), _createBlock(StipVerification, {
              key: 1,
              stipulation: _ctx.stipulation,
              hasStipVerification: checkStipVerification(_ctx.stipulation),
              application: _ctx.application
            }, null, 8, ["stipulation", "hasStipVerification", "application"]))
          : (
					_ctx.stipulation.isExpectedDocument && _ctx.stipulation.documentId === null && _ctx.stipulation.applicantDocumentId === null
				)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, " There is no document linked to this stipulation "))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(">> Details Not Available << ")))
    ])
  ]))
}
}

})