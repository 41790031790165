import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "xform-section"
}
const _hoisted_2 = { class: "xfields" }

import { computed, onMounted, reactive } from "vue";
import BaseFormField from "@/components/form-fields/BaseFormField.vue";
import { useStore } from "vuex";
import { Option } from "@/models/form";
import { useApplicationStore} from "@/store/store/applicationStore"

type Props = {
	schemas: Record<string, any>;
	schoolInformation: Record<string, any>;
	schoolLevels: Option[];
	programTypeId: number;
	majors: Option[];
	degreePrograms: Option[];
	current: string;
	application: any;
};

export default /*@__PURE__*/_defineComponent({
  __name: 'ApplicationLineOfCreditSchoolSection',
  props: {
    schemas: {},
    schoolInformation: {},
    schoolLevels: {},
    programTypeId: {},
    majors: {},
    degreePrograms: {},
    current: {},
    application: {}
  },
  setup(__props: any) {

const store = useStore();

const applicationStore = useApplicationStore();

const props = __props;
const localSchoolInformation = reactive({ ...props.schoolInformation });

const isMajorVisible = computed(() => props.programTypeId === 1);
const isDegreeProgramVisible = computed(() => props.programTypeId === 2);

onMounted(() => {
	if(props.schoolInformation.school){

		applicationStore.school = props.schoolInformation.school;
	}
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (
				props.current === 'Primary Applicant' &&
				props.application?.data?.schoolConfiguration
			)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _cache[4] || (_cache[4] = _createElementVNode("h3", null, "School", -1)),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(BaseFormField, {
              schema: _ctx.schemas.school,
              modelValue: _unref(applicationStore).school,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(applicationStore).school) = $event)),
              autocompleteOptions: _unref(store).state.types['Schools']
            }, null, 8, ["schema", "modelValue", "autocompleteOptions"]),
            _createVNode(BaseFormField, {
              schema: _ctx.schemas.schoolLevelTypeId,
              modelValue: 
						localSchoolInformation.schoolConfiguration.schoolLevelTypeId
					,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((
						localSchoolInformation.schoolConfiguration.schoolLevelTypeId
					) = $event)),
              modelModifiers: { number: true },
              options: _ctx.schoolLevels
            }, null, 8, ["schema", "modelValue", "options"]),
            _withDirectives(_createVNode(BaseFormField, {
              schema: _ctx.schemas.major,
              modelValue: localSchoolInformation.schoolConfiguration.major,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((localSchoolInformation.schoolConfiguration.major) = $event)),
              options: _ctx.majors
            }, null, 8, ["schema", "modelValue", "options"]), [
              [_vShow, isMajorVisible.value]
            ]),
            _withDirectives(_createVNode(BaseFormField, {
              schema: _ctx.schemas.degreeProgram,
              modelValue: 
						localSchoolInformation.schoolConfiguration.degreeProgram
					,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((
						localSchoolInformation.schoolConfiguration.degreeProgram
					) = $event)),
              modelModifiers: { number: true },
              options: _ctx.degreePrograms
            }, null, 8, ["schema", "modelValue", "options"]), [
              [_vShow, isDegreeProgramVisible.value]
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})