import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, vModelSelect as _vModelSelect, vModelRadio as _vModelRadio, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "xfields inline" }
const _hoisted_4 = { class: "xfield" }
const _hoisted_5 = { class: "xfield" }
const _hoisted_6 = { class: "xform-section-loc" }
const _hoisted_7 = {
  id: "tblLOCLimit",
  class: "exception-table"
}
const _hoisted_8 = { class: "exception-text" }
const _hoisted_9 = { class: "exception-list" }
const _hoisted_10 = { class: "input-prefix-container" }
const _hoisted_11 = ["disabled"]
const _hoisted_12 = {
  key: 0,
  class: "error-wrapper"
}
const _hoisted_13 = { class: "credit-union-decision-text" }
const _hoisted_14 = ["disabled"]
const _hoisted_15 = { class: "remaining-textbox" }
const _hoisted_16 = { class: "checkbox-container-acknowledge" }
const _hoisted_17 = { class: "xfields" }
const _hoisted_18 = { class: "checkfield" }
const _hoisted_19 = { class: "xfield" }
const _hoisted_20 = { class: "button-container" }
const _hoisted_21 = ["disabled"]
const _hoisted_22 = ["disabled"]
const _hoisted_23 = { class: "card-bottom" }
const _hoisted_24 = { class: "card-body" }
const _hoisted_25 = { class: "xfields" }
const _hoisted_26 = { class: "xfield" }
const _hoisted_27 = { class: "xfield" }
const _hoisted_28 = { class: "xfield" }
const _hoisted_29 = ["disabled"]
const _hoisted_30 = ["value"]
const _hoisted_31 = {
  key: 0,
  class: "error-message"
}
const _hoisted_32 = { id: "dropDownProgramTiers" }
const _hoisted_33 = { class: "xform-section-loc-top" }
const _hoisted_34 = {
  id: "tblFixedRate",
  class: "exception-table"
}
const _hoisted_35 = { class: "exception-text" }
const _hoisted_36 = { class: "pricing-list" }
const _hoisted_37 = { class: "exception-list" }
const _hoisted_38 = { class: "pricing-list" }
const _hoisted_39 = { class: "exception-list" }
const _hoisted_40 = { class: "pricing-list" }
const _hoisted_41 = { class: "xform-section-loc-bottom" }
const _hoisted_42 = { class: "exception-table" }
const _hoisted_43 = { class: "exception-text" }
const _hoisted_44 = { class: "pricing-list" }
const _hoisted_45 = { class: "exception-list" }
const _hoisted_46 = { class: "pricing-list" }
const _hoisted_47 = { class: "exception-list" }
const _hoisted_48 = { class: "pricing-list" }
const _hoisted_49 = { class: "exception-text" }
const _hoisted_50 = { class: "pricing-list" }
const _hoisted_51 = { class: "exception-list" }
const _hoisted_52 = { class: "pricing-list" }
const _hoisted_53 = { class: "exception-list" }
const _hoisted_54 = { class: "pricing-list" }
const _hoisted_55 = { class: "exception-list" }
const _hoisted_56 = { class: "pricing-list" }
const _hoisted_57 = {
  id: "TierSelection",
  style: {"padding":"0 0 20px 0"}
}
const _hoisted_58 = ["value"]
const _hoisted_59 = {
  key: 0,
  class: "error-message"
}
const _hoisted_60 = { class: "xform-section-existing-rates" }
const _hoisted_61 = { id: "tblExistingFixedRate" }
const _hoisted_62 = { class: "exception-table new-grid-table" }
const _hoisted_63 = ["checked", "value"]
const _hoisted_64 = { class: "exception-text" }
const _hoisted_65 = { class: "pricing-list" }
const _hoisted_66 = { class: "exception-list" }
const _hoisted_67 = { class: "pricing-list" }
const _hoisted_68 = { class: "exception-list" }
const _hoisted_69 = { class: "pricing-list" }
const _hoisted_70 = { id: "tblExistingVariableRate" }
const _hoisted_71 = { class: "exception-table new-grid-table" }
const _hoisted_72 = ["checked", "value"]
const _hoisted_73 = { class: "exception-text" }
const _hoisted_74 = { class: "pricing-list" }
const _hoisted_75 = { class: "exception-list" }
const _hoisted_76 = { class: "pricing-list" }
const _hoisted_77 = { class: "exception-list" }
const _hoisted_78 = { class: "pricing-list" }
const _hoisted_79 = { class: "exception-text" }
const _hoisted_80 = { class: "pricing-list" }
const _hoisted_81 = { class: "exception-list" }
const _hoisted_82 = { class: "pricing-list" }
const _hoisted_83 = { class: "exception-list" }
const _hoisted_84 = { class: "pricing-list" }
const _hoisted_85 = { class: "exception-list" }
const _hoisted_86 = { class: "pricing-list" }
const _hoisted_87 = { class: "xform-section-existing-rates" }
const _hoisted_88 = { class: "exception-table new-pricing-table" }
const _hoisted_89 = { class: "exception-text" }
const _hoisted_90 = { class: "input-suffix-container" }
const _hoisted_91 = { class: "exception-list" }
const _hoisted_92 = { class: "pricing-list" }
const _hoisted_93 = { class: "exception-list" }
const _hoisted_94 = { class: "input-suffix-container" }
const _hoisted_95 = { class: "exception-text" }
const _hoisted_96 = { class: "pricing-list" }
const _hoisted_97 = { class: "exception-list" }
const _hoisted_98 = { class: "input-suffix-container" }
const _hoisted_99 = { class: "exception-list" }
const _hoisted_100 = { class: "input-suffix-container" }
const _hoisted_101 = { class: "credit-union-decision-text" }
const _hoisted_102 = ["disabled"]
const _hoisted_103 = { class: "remaining-textbox" }
const _hoisted_104 = { class: "checkbox-container-acknowledge" }
const _hoisted_105 = { class: "xfields" }
const _hoisted_106 = { class: "checkfield" }
const _hoisted_107 = ["disabled"]
const _hoisted_108 = { class: "xfield" }
const _hoisted_109 = { class: "button-container" }
const _hoisted_110 = ["disabled"]
const _hoisted_111 = ["disabled"]

import { ref, computed, onMounted } from "vue";
  import useFormatter from "@/use/formatter";
  import {
    Recommendation,
    ProgramTier,
    OptionProgramTiers,
    OptionProgramTierTypes,
  } from "@/types/evaluation";
  import { Override, OverrideTypes } from "@/types/override";
  import Loader from "@/components/Loader.vue";
  import axios from "axios";
  import functions from "@/use/functions";
  import { Permissions, PermissionLevels } from "@/models/permissions";
  import PermissionsWrapper from "@/components/wrappers/PermissionsWrapper.vue";
	import { useValidationStore } from "@/store/store/validationStore";

	interface Props {
    application: any;
    applicants: any;
	canEdit: boolean;
  }

  
export default /*@__PURE__*/_defineComponent({
  __name: 'OverridesReview',
  props: {
    application: {},
    applicants: {},
    canEdit: { type: Boolean }
  },
  emits: ["submitForm"],
  setup(__props: any, { emit: __emit }) {

  const validationStore = useValidationStore();

  const overrideLOC = ref<Override>({
    reviewBy: "",
    reviewDateTime: "",
    overrideTypeId: OverrideTypes.LOCLimitMod,
    freeFormReason: "",
    isReviewed: false,
    isCustomPricing: false,
  });

  const overridePricing = ref<Override>({
    reviewBy: "",
    reviewDateTime: "",
    overrideTypeId: 0,
    freeFormReason: "",
    pricingTierIds: [],
    isReviewed: false,
    isCustomPricing: false,
  });

  //1.) To handle the custom pricing for pricing exceptions.
  //2.) fixed Rate Selection is what is current selected
  //3.) variable rate selection is what is current selected
  const isSubmitting = ref(false);
  const customPricingSelection = ref<ProgramTier>({});

  const fixedRateSelection = ref<ProgramTier>({});

  const variableRateSelection = ref<ProgramTier>({});

  const existingFixedRates = ref<Array<ProgramTier>>([]);

  const existingVariableRates = ref<Array<ProgramTier>>([]);

  const existingVariableRateSelection = ref<number>();

  const existingFixedRateSelection = ref<number>();

  //To keep track of loc limit.
  const newLOCLimit = ref<number>(0);

  //Drop Downs..
  const overrideTypes = ref<Array<Recommendation>>([]);

  const optionProgramTiers = ref<Array<OptionProgramTiers>>([
    {
      id: OptionProgramTierTypes.ExistingPricingTier,
      name: "Choose Existing Program Tiers",
    },
    {
      id: OptionProgramTierTypes.CustomPricingTier,
      name: "Create Custom Program Tiers",
    },
  ]);

  //This two variables are to track the selected option for both pricing drop downs.
  const programTierOptionSelection = ref<number>(0);

  // const pricingExcpetionReasonSelected = ref<number>(0);

  //This is to keep track when the user select different program tiers.
  //Also going to use this one as well for custom.
  //Ex. Custom will only have one element and program tiers always two.
  const existingProgramTiersSelection = ref<Array<any>>([]);

  const { formatMoney } = useFormatter();

  const props = __props;
  
  const emit = __emit;
  const pricingOverrideSelected = ref(false);
  const pricingExceptionSelected = ref(false);

  //Display elements when needed

  function reloadWindow() {
    window.location.reload();
  }

  const addPricingException = ref<boolean>(true);
  const showPricingErrorMsg = ref<boolean>(false);

  const remainingLOCCharacters = computed(
    () => 512 - (overrideLOC.value.freeFormReason?.length || 0)
  );

  const remainingPricingCharacters = computed(
    () => 512 - (overridePricing.value.freeFormReason?.length || 0)
  );

  //When check box press for add loc limit exception wire the form.
  const onChangeLOCLimit = () => {
		if (newLOCLimit.value && newLOCLimit.value >= 0) {
 			overrideLOC.value.locLimit = newLOCLimit.value.toFixed(2);
		}
  };

  //In the event that the user press Add Pricing Exception under  Existing Program Tiers but doesn't change
  // the radio buttons this prevents from submission.
  const IsExistingProgramTiersDifferent = () => {
    //Lets Get the selected value
    const foundFixedRateSelection = existingFixedRates.value.find(
      (item) => item.isSelected
    );

    const foundVariableRateSelection = existingVariableRates.value.find(
      (item) => item.isSelected
    );

    if (
      foundFixedRateSelection?.pricingTierId ==
        fixedRateSelection.value.pricingTierId ||
      foundVariableRateSelection?.pricingTierId ==
        variableRateSelection.value.pricingTierId
    ) {
      showPricingErrorMsg.value = true;
      return false;
    }

    showPricingErrorMsg.value = false;

    return true;
  };

  const getLastVariableRate = () => {
    return existingVariableRates.value.slice(-1)[0];
  };

  const getLastFixedRate = () => {
    return existingFixedRates.value.slice(-1)[0];
  };

  const HandleCustomProgramPricingSelection = () => {
    let pricingJSON: Array<ProgramTier> = [];

    if (
      variableRateSelection.value.isSelected &&
      fixedRateSelection.value.isSelected
    ) {

        overridePricing.value.customPricing = { 
            f: customPricingSelection?.value?.floor, 
            c: customPricingSelection?.value?.ceiling,
            m: customPricingSelection?.value?.margin,
            r:  customPricingSelection?.value?.fixedRate
          };


    } else { //NOTE: Sometimes apps don't have selected tiers in order to override. we select the last one.

        var fixedRate =  getLastFixedRate();
        var variableRate = getLastVariableRate();

        if(fixedRate !== undefined)
        {
          overridePricing.value.pricingTierIds?.push(fixedRate.pricingTierId!);
        }

        if(variableRate !== undefined)
        {
          overridePricing.value.pricingTierIds?.push(variableRate.pricingTierId!);
        }

        overridePricing.value.customPricing = { 
            f: customPricingSelection?.value?.floor, 
            c: customPricingSelection?.value?.ceiling,
            m: customPricingSelection?.value?.margin,
            r:  customPricingSelection?.value?.fixedRate
        };
    }
  };

  const HandleExistingProgramTiersSelection = () => {
    //Reset to nothing.
    existingProgramTiersSelection.value = [];

    //Reset selection tiers:
    overridePricing.value.pricingTierIds = [];

    //Lets Get the selected value
    const foundFixedRateSelection = existingFixedRates.value.find(
      (item) => item.isSelected
    );

    const foundVariableRateSelection = existingVariableRates.value.find(
      (item) => item.isSelected
    );

    if (
      foundFixedRateSelection?.pricingTierId ==
        fixedRateSelection.value.pricingTierId &&
      foundVariableRateSelection?.pricingTierId ==
        variableRateSelection.value.pricingTierId
    ) {
      return false;
    }

    if (foundFixedRateSelection !== undefined)
    {
      overridePricing.value.pricingTierIds?.push(foundFixedRateSelection.pricingTierId!)
    }
    

    if (foundVariableRateSelection !== undefined)
    {
      overridePricing.value.pricingTierIds?.push(foundVariableRateSelection.pricingTierId!)
    }
  };

  const onExistingFixedRateChange = () => {
    existingFixedRates.value.forEach((item) => {
      item.isSelected = item.pricingTierId == existingFixedRateSelection.value;
    });

    HandleExistingProgramTiersSelection();
  };

  const onExistingVariableRateChange = () => {
    existingVariableRates.value.forEach((item) => {
      item.isSelected =
        item.pricingTierId == existingVariableRateSelection.value;
    });

    HandleExistingProgramTiersSelection();
  };

  const IsProgramTiersSame = () => {
    return (
      customPricingSelection.value.fixedRate ==
        fixedRateSelection.value.fixedRate?.replace("%", "") &&
      customPricingSelection.value.margin ==
        variableRateSelection.value.margin?.replace("%", "") &&
      customPricingSelection.value.floor ==
        variableRateSelection.value.floor?.replace("%", "") &&
      customPricingSelection.value.ceiling ==
        variableRateSelection.value.ceiling?.replace("%", "")
    );
  };

  const onProgramTiersChange = () => {
    // customPricingSelection
    if (variableRateSelection.value.isSelected) {
      customPricingSelection.value.fixedRate =
        fixedRateSelection.value.fixedRate?.replace("%", "");

      customPricingSelection.value.variableRate =
        variableRateSelection.value.variableRate;

      customPricingSelection.value.margin =
        variableRateSelection.value.margin?.replace("%", "");

      customPricingSelection.value.index = variableRateSelection.value.index;

      customPricingSelection.value.floor =
        variableRateSelection.value.floor?.replace("%", "");

      customPricingSelection.value.ceiling =
        variableRateSelection.value.ceiling?.replace("%", "");
    } else {
      const lastVariableRate = getLastVariableRate();

      const lastFixedRate = getLastFixedRate();

      if (lastVariableRate !== undefined)
        customPricingSelection.value.index = lastVariableRate.index;

      customPricingSelection.value.fixedRate =
        lastFixedRate !== undefined
          ? lastFixedRate.fixedRate?.replace("%", "")
          : "0";
    }

    overridePricing.value.isCustomPricing =
      programTierOptionSelection.value ==
      OptionProgramTierTypes.CustomPricingTier;
  };

  const onChangeMarginCal = () => {
    const customMargin = Number(customPricingSelection?.value?.margin).toFixed(
      4
    );

    let indexMargin = Number(
      customPricingSelection.value.index?.replace("%", "")
    ).toFixed(4);

    if (variableRateSelection.value.isSelected) {
      indexMargin = Number(
        variableRateSelection.value.index?.replace("%", "")
      ).toFixed(4);
    }

    const floor = Number(customPricingSelection?.value?.floor).toFixed(4);

    const ceiling = Number(customPricingSelection?.value?.ceiling).toFixed(4);

    if (
      parseFloat(customMargin) + parseFloat(indexMargin) <
      parseFloat(floor)
    ) {
      customPricingSelection.value.margin =
        variableRateSelection.value.margin?.replace("%", "");
      customPricingSelection.value.variableRate =
        variableRateSelection.value.variableRate;
      functions.openModal({
        title: "Custom Pricing Error",
        description: "Margin + Index can't be lower than Floor",
        buttons: [
          {
            title: "OK",
            onClick: async () => {
              functions.closeModal();
            },
          },
        ],
      });
      return;
    } else if (
      parseFloat(customMargin) + parseFloat(indexMargin) >
      parseFloat(ceiling)
    ) {
      customPricingSelection.value.margin =
        variableRateSelection.value.margin?.replace("%", "");
      customPricingSelection.value.variableRate =
        variableRateSelection.value.variableRate;
      functions.openModal({
        title: "Custom Pricing Error",
        description: "Margin + Index can't be higher than Ceiling",
        buttons: [
          {
            title: "OK",
            onClick: async () => {
              functions.closeModal();
            },
          },
        ],
      });
      return;
    }

    // Convert the strings back to numbers before performing addition
    const customCal = (
      parseFloat(customMargin) + parseFloat(indexMargin)
    ).toFixed(4);

    customPricingSelection.value.variableRate = `${customCal}%`;
  };

  async function submitLOCForm() {
    const referenceId = props.application.data.referenceId;
    const url = `/api/applications/${referenceId}/Override`;
    isSubmitting.value = true;

		if (validationStore.fields.displayedLOCLimit.valid && newLOCLimit.value > 0) {
			try {
				const response = await axios.post(url, overrideLOC.value);

				if (response.data.success) {
					overrideLOC.value = response.data.results;
					functions.openModal({
						title: "Override Limit Exception",
						description: "Override Limit Exception has been saved.",
						buttons: [
							{
								title: "OK",
								onClick: async () => {
									await functions.closeModal();
									reloadWindow();
								},
							},
						],
					});
				}
			} catch (error) {
				console.log("Error saving details:", error);
			}
		}


    isSubmitting.value = false;
  }

  async function submitPricingForm() {
    if (
      programTierOptionSelection.value ==
      OptionProgramTierTypes.ExistingPricingTier
    ) {
      if (!IsExistingProgramTiersDifferent()) {
        return; //Kill Submit;;
      }

      HandleExistingProgramTiersSelection();
    } else if (
      programTierOptionSelection.value ==
      OptionProgramTierTypes.CustomPricingTier
    ) {
      if (IsProgramTiersSame()) {
        functions.openModal({
          title: "Custom Pricing Error",
          description:
            "Custom Program Tier values have not changed, please review your selections or select Existing Program Tier.",
          buttons: [
            {
              title: "OK",
              onClick: async () => {
                functions.closeModal();
              },
            },
          ],
        });
      }

      HandleCustomProgramPricingSelection();
      console.table(overridePricing.value);
    }

    isSubmitting.value = true;

    const referenceId = props.application.data.referenceId;

    const url = `/api/applications/${referenceId}/Override`;

    console.table(overridePricing.value);

    try {
      const response = await axios.post(url, overridePricing.value);

      if (response.data.success) {
        overridePricing.value = response.data.results;
        functions.openModal({
          title: "Override Limit Exception",
          description: "Override Limit Exception has been saved.",
          buttons: [
            {
              title: "OK",
              onClick: async () => {
                await functions.closeModal();
                reloadWindow();
              },
            },
          ],
        });
      }
    } catch (error) {
      console.log("Error saving details:", error);
    }

    isSubmitting.value = false;
  }

  function resetLOCForm() {
    overrideLOC.value = {
      reviewBy: "",
      reviewDateTime: "",
      overrideTypeId: OverrideTypes.LOCLimitMod,
      freeFormReason: "",
      isReviewed: false,
      isCustomPricing: false,
    };
    newLOCLimit.value = 0;
  }

  function resetPricingForm() {
    overridePricing.value = {
      reviewBy: "",
      reviewDateTime: "",
      overrideTypeId: 0,
      freeFormReason: "",
      isReviewed: false,
      isCustomPricing: false,
    };

    customPricingSelection.value = {};
    fixedRateSelection.value = {};
    variableRateSelection.value = {};
    existingFixedRates.value = [];
    existingVariableRates.value = [];
    existingVariableRateSelection.value = 0;
    existingFixedRateSelection.value = 0;
    // newLOCLimit.value = 0;
    programTierOptionSelection.value = 0;
    existingProgramTiersSelection.value = [];
    addPricingException.value = true;
    showPricingErrorMsg.value = false;
  }

  async function getPricingOverrideTypes() {
    const url = "/api/types/pricing/override";
    try {
      const response = await axios.get(url);
      overrideTypes.value = response.data;
    } catch (error) {
      console.log("Error saving details:", error);
    }
  }

  async function getPricingDetails() {
    const referenceId = props.application.data.referenceId;
    const url = `/api/applications/${referenceId}/Pricing/Details`;
    try {
      const response = await axios.get(url);

      if (response.data.success) {
        fixedRateSelection.value = response.data.results.fixedRate;

        variableRateSelection.value = response.data.results.variableRate;

        existingFixedRates.value = response.data.results.fixedRates;

        existingVariableRates.value = response.data.results.variableRates;

        // console.log(existingVariableRates);
      }
    } catch (error) {
      console.log("Error saving details:", error);
    }
  }

  async function getOverrideDetails() {
    const referenceId = props.application.data.referenceId;
    const url = `/api/applications/${referenceId}/Overrides`;
    try {
      const response = await axios.get(url);

      if (response.data.success) {
        overrideLOC.value = response.data.results.overrideLOC;

        overridePricing.value = response.data.results.overridePricing;

        console.table(response.data);
      }
    } catch (error) {
      console.log("Error saving details:", error);
    }
  }

	function validateLoanLimitInput() {
		const loanMaximum = props.application.data.loanAmount;
		validationStore.validateLoanLimitInput(validationStore.fields.displayedLOCLimit, newLOCLimit.value, loanMaximum);
	}

  onMounted(() => {
    getPricingOverrideTypes();
    getOverrideDetails();
    getPricingDetails();
  });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(PermissionsWrapper, {
      disabled: !_ctx.canEdit,
      showDisabledIfNotVisible: true,
      permissionIds: [_unref(Permissions).Overrides],
      permissionLevel: _unref(PermissionLevels).Update
    }, {
      default: _withCtx(() => [
        (isSubmitting.value)
          ? (_openBlock(), _createBlock(Loader, { key: 0 }))
          : (_openBlock(), _createElementBlock("form", {
              key: 1,
              onSubmit: _withModifiers(submitLOCForm, ["prevent"]),
              autocomplete: "off"
            }, [
              _createElementVNode("div", _hoisted_1, [
                _cache[25] || (_cache[25] = _createElementVNode("div", { class: "card-header" }, [
                  _createElementVNode("h3", null, "Override Limit Exception")
                ], -1)),
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _cache[17] || (_cache[17] = _createElementVNode("label", null, "Override Decision By:", -1)),
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((overrideLOC.value.reviewBy) = $event)),
                        class: "input-field-textarea",
                        placeholder: "Override decision by",
                        disabled: ""
                      }, null, 512), [
                        [_vModelText, overrideLOC.value.reviewBy]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_5, [
                      _cache[18] || (_cache[18] = _createElementVNode("label", null, "Date and Time", -1)),
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((overrideLOC.value.reviewDateTime) = $event)),
                        class: "input-field-textarea",
                        placeholder: "Date and Time",
                        disabled: ""
                      }, null, 512), [
                        [_vModelText, overrideLOC.value.reviewDateTime]
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("table", _hoisted_7, [
                      _cache[20] || (_cache[20] = _createElementVNode("tr", null, [
                        _createElementVNode("td", { class: "pricing-list-header" }, "LOC-Program Limit"),
                        _createElementVNode("td", { class: "pricing-list-header-loc" }, [
                          _createTextVNode(" New LOC Limit"),
                          _createElementVNode("i", { class: "asterisk" }, "*")
                        ])
                      ], -1)),
                      _createElementVNode("tr", null, [
                        _createElementVNode("td", _hoisted_8, _toDisplayString(_unref(formatMoney)(_ctx.application.data.loanAmount)), 1),
                        _createElementVNode("td", _hoisted_9, [
                          _createElementVNode("div", _hoisted_10, [
                            _cache[19] || (_cache[19] = _createElementVNode("span", { class: "input-prefix" }, "$", -1)),
                            _withDirectives(_createElementVNode("input", {
                              type: "number",
                              class: "prefixed-input",
                              name: "locLimit",
                              disabled: overrideLOC.value.isReviewed,
                              onChange: onChangeLOCLimit,
                              onInput: validateLoanLimitInput,
                              min: "1",
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((newLOCLimit).value = $event)),
                              required: ""
                            }, null, 40, _hoisted_11), [
                              [_vModelText, newLOCLimit.value]
                            ])
                          ]),
                          (!overrideLOC.value.reviewDateTime && !overrideLOC.value.isReviewed)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                _createElementVNode("ul", null, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(validationStore).fields.displayedLOCLimit.errors, (error, index) => {
                                    return (_openBlock(), _createElementBlock("li", {
                                      key: index,
                                      style: {}
                                    }, _toDisplayString(error), 1))
                                  }), 128))
                                ])
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _cache[21] || (_cache[21] = _createElementVNode("label", { class: "title-writeup" }, "Override Reason Comments", -1)),
                    _withDirectives(_createElementVNode("textarea", {
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((overrideLOC.value.freeFormReason) = $event)),
                      disabled: overrideLOC.value.isReviewed,
                      id: "message",
                      maxlength: "512",
                      rows: "4",
                      class: "block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500",
                      placeholder: "Write up your comments here...",
                      required: ""
                    }, "\t\t\t\t  ", 8, _hoisted_14), [
                      [_vModelText, overrideLOC.value.freeFormReason]
                    ]),
                    _createElementVNode("div", _hoisted_15, _toDisplayString(remainingLOCCharacters.value) + " characters remaining ", 1)
                  ]),
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("div", _hoisted_17, [
                      _createElementVNode("div", _hoisted_18, [
                        _withDirectives(_createElementVNode("input", {
                          type: "checkbox",
                          placeholder: "Initiated By",
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((overrideLOC.value.isReviewed) = $event)),
                          required: ""
                        }, null, 512), [
                          [_vModelCheckbox, overrideLOC.value.isReviewed]
                        ])
                      ]),
                      _cache[22] || (_cache[22] = _createElementVNode("span", { class: "checkbutton" }, " I acknowledge I reviewed and completed Application Override. ", -1)),
                      _cache[23] || (_cache[23] = _createElementVNode("div", { class: "checkfield" }, null, -1)),
                      _cache[24] || (_cache[24] = _createElementVNode("div", { class: "checkfield" }, null, -1))
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("button", {
                        class: "draw-button submit",
                        onClick: _withModifiers(resetLOCForm, ["prevent"]),
                        disabled: !!overrideLOC.value.reviewDateTime
                      }, " Cancel ", 8, _hoisted_21),
                      _createElementVNode("button", {
                        class: "draw-button submit",
                        type: "submit",
                        disabled: !!overrideLOC.value.reviewDateTime
                      }, " Save ", 8, _hoisted_22)
                    ])
                  ])
                ])
              ])
            ], 32))
      ]),
      _: 1
    }, 8, ["disabled", "permissionIds", "permissionLevel"]),
    _createVNode(PermissionsWrapper, {
      disabled: !_ctx.canEdit,
      showDisabledIfNotVisible: true,
      permissionIds: [_unref(Permissions).Overrides],
      permissionLevel: _unref(PermissionLevels).Update
    }, {
      default: _withCtx(() => [
        _createElementVNode("form", {
          onSubmit: _withModifiers(submitPricingForm, ["prevent"]),
          autocomplete: "off"
        }, [
          _createElementVNode("div", _hoisted_23, [
            _cache[51] || (_cache[51] = _createElementVNode("div", { class: "card-header" }, [
              _createElementVNode("h3", null, "Pricing Override")
            ], -1)),
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("div", _hoisted_25, [
                _createElementVNode("div", _hoisted_26, [
                  _cache[26] || (_cache[26] = _createElementVNode("label", null, "Override Decision By:", -1)),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((overridePricing.value.reviewBy) = $event)),
                    class: "input-field-textarea",
                    placeholder: "Override decision by",
                    disabled: ""
                  }, null, 512), [
                    [_vModelText, overridePricing.value.reviewBy]
                  ])
                ]),
                _createElementVNode("div", _hoisted_27, [
                  _cache[27] || (_cache[27] = _createElementVNode("label", null, "Date and Time", -1)),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((overridePricing.value.reviewDateTime) = $event)),
                    class: "input-field-textarea",
                    placeholder: "Date and Time",
                    disabled: ""
                  }, null, 512), [
                    [_vModelText, overridePricing.value.reviewDateTime]
                  ])
                ]),
                _createElementVNode("div", _hoisted_28, [
                  _cache[29] || (_cache[29] = _createElementVNode("label", null, [
                    _createTextVNode("Pricing Override Reason"),
                    _createElementVNode("i", null, "*")
                  ], -1)),
                  _withDirectives(_createElementVNode("select", {
                    class: "input-field",
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((overridePricing.value.overrideTypeId) = $event)),
                    disabled: !!overridePricing.value.reviewDateTime,
                    required: ""
                  }, [
                    _cache[28] || (_cache[28] = _createElementVNode("option", {
                      disabled: "",
                      value: ""
                    }, "--Select Override Reason--", -1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(overrideTypes.value, (override, index) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: index,
                        value: override.id
                      }, _toDisplayString(override.name), 9, _hoisted_30))
                    }), 128))
                  ], 8, _hoisted_29), [
                    [_vModelSelect, overridePricing.value.overrideTypeId]
                  ]),
                  (!pricingOverrideSelected.value && !overridePricing.value.overrideTypeId)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_31, "Please select an override reason"))
                    : _createCommentVNode("", true)
                ])
              ]),
              _withDirectives(_createElementVNode("div", _hoisted_32, [
                _createElementVNode("div", _hoisted_33, [
                  _createElementVNode("table", _hoisted_34, [
                    _cache[30] || (_cache[30] = _createElementVNode("thead", null, [
                      _createElementVNode("caption", null, " Fixed Rate ")
                    ], -1)),
                    _cache[31] || (_cache[31] = _createElementVNode("tr", null, [
                      _createElementVNode("td", { class: "pricing-list-header" }, "Pricing Tier"),
                      _createElementVNode("td", { class: "pricing-list-header-loc" }, "Score Range"),
                      _createElementVNode("td", { class: "pricing-list-header-loc" }, "Fixed Rate")
                    ], -1)),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_35, [
                        _createElementVNode("div", _hoisted_36, _toDisplayString(fixedRateSelection.value.letterGradePricingTier), 1)
                      ]),
                      _createElementVNode("td", _hoisted_37, [
                        _createElementVNode("div", _hoisted_38, _toDisplayString(fixedRateSelection.value.scoreRange), 1)
                      ]),
                      _createElementVNode("td", _hoisted_39, [
                        _createElementVNode("div", _hoisted_40, _toDisplayString(fixedRateSelection.value.fixedRate), 1)
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_41, [
                  _createElementVNode("table", _hoisted_42, [
                    _cache[32] || (_cache[32] = _createElementVNode("thead", null, [
                      _createElementVNode("caption", null, " Variable Rate ")
                    ], -1)),
                    _cache[33] || (_cache[33] = _createElementVNode("tr", null, [
                      _createElementVNode("td", { class: "pricing-list-header" }, "Pricing Tier"),
                      _createElementVNode("td", { class: "pricing-list-header-loc" }, "Score Range"),
                      _createElementVNode("td", { class: "pricing-list-header-loc" }, "Variable Rate"),
                      _createElementVNode("td", { class: "pricing-list-header-loc" }, "Margin"),
                      _createElementVNode("td", { class: "pricing-list-header-loc" }, "Index"),
                      _createElementVNode("td", { class: "pricing-list-header-loc" }, "Floor"),
                      _createElementVNode("td", { class: "pricing-list-header-loc" }, "Ceiling")
                    ], -1)),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_43, [
                        _createElementVNode("div", _hoisted_44, _toDisplayString(variableRateSelection.value.letterGradePricingTier), 1)
                      ]),
                      _createElementVNode("td", _hoisted_45, [
                        _createElementVNode("div", _hoisted_46, _toDisplayString(variableRateSelection.value.scoreRange), 1)
                      ]),
                      _createElementVNode("td", _hoisted_47, [
                        _createElementVNode("div", _hoisted_48, _toDisplayString(variableRateSelection.value.variableRate), 1)
                      ]),
                      _createElementVNode("td", _hoisted_49, [
                        _createElementVNode("div", _hoisted_50, _toDisplayString(variableRateSelection.value.margin), 1)
                      ]),
                      _createElementVNode("td", _hoisted_51, [
                        _createElementVNode("div", _hoisted_52, _toDisplayString(variableRateSelection.value.index), 1)
                      ]),
                      _createElementVNode("td", _hoisted_53, [
                        _createElementVNode("div", _hoisted_54, _toDisplayString(variableRateSelection.value.floor), 1)
                      ]),
                      _createElementVNode("td", _hoisted_55, [
                        _createElementVNode("div", _hoisted_56, _toDisplayString(variableRateSelection.value.ceiling), 1)
                      ])
                    ])
                  ])
                ]),
                _cache[46] || (_cache[46] = _createElementVNode("div", { style: {"display":"flex","justify-content":"space-between","padding":"10px 0 10px 0"} }, [
                  _createElementVNode("span", { class: "o-override-text" }, [
                    _createTextVNode("Select the type of pricing exception you'd like to make."),
                    _createElementVNode("i", { class: "asterisk" }, "*")
                  ])
                ], -1)),
                _createElementVNode("div", _hoisted_57, [
                  _withDirectives(_createElementVNode("select", {
                    onChange: onProgramTiersChange,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((programTierOptionSelection).value = $event)),
                    required: ""
                  }, [
                    _cache[34] || (_cache[34] = _createElementVNode("option", {
                      disabled: "",
                      value: ""
                    }, "--Select Override Type--", -1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(optionProgramTiers.value, (programTier, index) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: index,
                        value: programTier.id
                      }, _toDisplayString(programTier.name), 9, _hoisted_58))
                    }), 128))
                  ], 544), [
                    [_vModelSelect, programTierOptionSelection.value]
                  ]),
                  (!pricingExceptionSelected.value && !programTierOptionSelection.value)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_59, " Please change the pricing tier for Fixed Rate and Variable Rate to continue. "))
                    : _createCommentVNode("", true)
                ]),
                _withDirectives(_createElementVNode("div", _hoisted_60, [
                  _createElementVNode("div", _hoisted_61, [
                    _createElementVNode("table", _hoisted_62, [
                      _cache[35] || (_cache[35] = _createElementVNode("thead", null, [
                        _createElementVNode("caption", null, " Fixed Rate ")
                      ], -1)),
                      _cache[36] || (_cache[36] = _createElementVNode("tr", null, [
                        _createElementVNode("td", { class: "exception-text pricing-list-header" }, "Select"),
                        _createElementVNode("td", { class: "exception-text pricing-list-header-loc" }, " Pricing Tier "),
                        _createElementVNode("td", { class: "exception-text pricing-list-header-loc" }, " Score Range "),
                        _createElementVNode("td", { class: "exception-text pricing-list-header-loc" }, " Fixed Rate ")
                      ], -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(existingFixedRates.value, (fixedRate, index) => {
                        return (_openBlock(), _createElementBlock("tr", { key: index }, [
                          _createElementVNode("td", null, [
                            _withDirectives(_createElementVNode("input", {
                              type: "radio",
                              name: "existingFixedRate",
                              checked: fixedRate.isSelected,
                              value: fixedRate.pricingTierId,
                              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((existingFixedRateSelection).value = $event)),
                              onChange: onExistingFixedRateChange
                            }, null, 40, _hoisted_63), [
                              [_vModelRadio, existingFixedRateSelection.value]
                            ])
                          ]),
                          _createElementVNode("td", _hoisted_64, [
                            _createElementVNode("div", _hoisted_65, _toDisplayString(fixedRate.letterGradePricingTier), 1)
                          ]),
                          _createElementVNode("td", _hoisted_66, [
                            _createElementVNode("div", _hoisted_67, _toDisplayString(fixedRate.scoreRange), 1)
                          ]),
                          _createElementVNode("td", _hoisted_68, [
                            _createElementVNode("div", _hoisted_69, _toDisplayString(fixedRate.fixedRate), 1)
                          ])
                        ]))
                      }), 128))
                    ])
                  ]),
                  _cache[39] || (_cache[39] = _createElementVNode("div", { class: "exception-table-space" }, null, -1)),
                  _createElementVNode("div", _hoisted_70, [
                    _createElementVNode("table", _hoisted_71, [
                      _cache[37] || (_cache[37] = _createElementVNode("thead", null, [
                        _createElementVNode("caption", null, " Variable Rate ")
                      ], -1)),
                      _cache[38] || (_cache[38] = _createElementVNode("tr", null, [
                        _createElementVNode("td", { class: "exception-text pricing-list-header" }, "Select"),
                        _createElementVNode("td", { class: "exception-text pricing-list-header-loc" }, " Pricing Tier "),
                        _createElementVNode("td", { class: "exception-text pricing-list-header-loc" }, " Score Range "),
                        _createElementVNode("td", { class: "exception-text pricing-list-header-loc" }, " Variable Rate "),
                        _createElementVNode("td", { class: "exception-text pricing-list-header-loc" }, "Margin"),
                        _createElementVNode("td", { class: "exception-text pricing-list-header-loc" }, "Index"),
                        _createElementVNode("td", { class: "exception-text pricing-list-header-loc" }, "Floor"),
                        _createElementVNode("td", { class: "exception-text pricing-list-header-loc" }, " Ceiling ")
                      ], -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(existingVariableRates.value, (variableRate, index) => {
                        return (_openBlock(), _createElementBlock("tr", { key: index }, [
                          _createElementVNode("td", null, [
                            _withDirectives(_createElementVNode("input", {
                              type: "radio",
                              name: "existingVariableRate",
                              checked: variableRate.isSelected,
                              value: variableRate.pricingTierId,
                              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((existingVariableRateSelection).value = $event)),
                              onChange: onExistingVariableRateChange
                            }, null, 40, _hoisted_72), [
                              [_vModelRadio, existingVariableRateSelection.value]
                            ])
                          ]),
                          _createElementVNode("td", _hoisted_73, [
                            _createElementVNode("div", _hoisted_74, _toDisplayString(variableRate.letterGradePricingTier), 1)
                          ]),
                          _createElementVNode("td", _hoisted_75, [
                            _createElementVNode("div", _hoisted_76, _toDisplayString(variableRate.scoreRange), 1)
                          ]),
                          _createElementVNode("td", _hoisted_77, [
                            _createElementVNode("div", _hoisted_78, _toDisplayString(variableRate.variableRate), 1)
                          ]),
                          _createElementVNode("td", _hoisted_79, [
                            _createElementVNode("div", _hoisted_80, _toDisplayString(variableRate.margin), 1)
                          ]),
                          _createElementVNode("td", _hoisted_81, [
                            _createElementVNode("div", _hoisted_82, _toDisplayString(variableRate.index), 1)
                          ]),
                          _createElementVNode("td", _hoisted_83, [
                            _createElementVNode("div", _hoisted_84, _toDisplayString(variableRate.floor), 1)
                          ]),
                          _createElementVNode("td", _hoisted_85, [
                            _createElementVNode("div", _hoisted_86, _toDisplayString(variableRate.ceiling), 1)
                          ])
                        ]))
                      }), 128))
                    ])
                  ])
                ], 512), [
                  [_vShow, 
					programTierOptionSelection.value ==
					_unref(OptionProgramTierTypes).ExistingPricingTier
				  ]
                ]),
                _withDirectives(_createElementVNode("div", _hoisted_87, [
                  _createElementVNode("table", _hoisted_88, [
                    _cache[44] || (_cache[44] = _createElementVNode("thead", null, [
                      _createElementVNode("caption", { style: {"width":"300px"} }, " Create New Pricing for this Application ")
                    ], -1)),
                    _cache[45] || (_cache[45] = _createElementVNode("tr", null, [
                      _createElementVNode("td", { class: "exception-text pricing-list-header" }, [
                        _createTextVNode(" Fixed Rate"),
                        _createElementVNode("i", { class: "asterisk" }, "*")
                      ]),
                      _createElementVNode("td", { class: "exception-text pricing-list-header-loc" }, " Variable Rate "),
                      _createElementVNode("td", { class: "exception-text pricing-list-header-loc" }, [
                        _createTextVNode(" Margin"),
                        _createElementVNode("i", { class: "asterisk" }, "*")
                      ]),
                      _createElementVNode("td", { class: "exception-text pricing-list-header-loc" }, "Index"),
                      _createElementVNode("td", { class: "exception-text pricing-list-header-loc" }, [
                        _createTextVNode(" Floor"),
                        _createElementVNode("i", { class: "asterisk" }, "*")
                      ]),
                      _createElementVNode("td", { class: "exception-text pricing-list-header-loc" }, [
                        _createTextVNode(" Ceiling"),
                        _createElementVNode("i", { class: "asterisk" }, "*")
                      ])
                    ], -1)),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_89, [
                        _createElementVNode("div", _hoisted_90, [
                          _withDirectives(_createElementVNode("input", {
                            id: "fixedRateInput",
                            type: "number",
                            step: "0.0001",
                            class: "suffixed-input",
                            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((customPricingSelection.value.fixedRate) = $event))
                          }, null, 512), [
                            [_vModelText, customPricingSelection.value.fixedRate]
                          ]),
                          _cache[40] || (_cache[40] = _createElementVNode("span", { class: "input-suffix" }, "%", -1))
                        ])
                      ]),
                      _createElementVNode("td", _hoisted_91, [
                        _createElementVNode("div", _hoisted_92, _toDisplayString(customPricingSelection.value.variableRate), 1)
                      ]),
                      _createElementVNode("td", _hoisted_93, [
                        _createElementVNode("div", _hoisted_94, [
                          _withDirectives(_createElementVNode("input", {
                            id: "marginRateInput",
                            type: "number",
                            step: "0.0001",
                            class: "suffixed-input",
                            onChange: onChangeMarginCal,
                            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((customPricingSelection.value.margin) = $event))
                          }, null, 544), [
                            [_vModelText, customPricingSelection.value.margin]
                          ]),
                          _cache[41] || (_cache[41] = _createElementVNode("span", { class: "input-suffix" }, "%", -1))
                        ])
                      ]),
                      _createElementVNode("td", _hoisted_95, [
                        _createElementVNode("div", _hoisted_96, _toDisplayString(customPricingSelection.value.index), 1)
                      ]),
                      _createElementVNode("td", _hoisted_97, [
                        _createElementVNode("div", _hoisted_98, [
                          _withDirectives(_createElementVNode("input", {
                            type: "number",
                            step: "0.0001",
                            class: "suffixed-input",
                            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((customPricingSelection.value.floor) = $event))
                          }, null, 512), [
                            [_vModelText, customPricingSelection.value.floor]
                          ]),
                          _cache[42] || (_cache[42] = _createElementVNode("span", { class: "input-suffix" }, "%", -1))
                        ])
                      ]),
                      _createElementVNode("td", _hoisted_99, [
                        _createElementVNode("div", _hoisted_100, [
                          _withDirectives(_createElementVNode("input", {
                            type: "number",
                            step: "0.0001",
                            class: "suffixed-input",
                            "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((customPricingSelection.value.ceiling) = $event))
                          }, null, 512), [
                            [_vModelText, customPricingSelection.value.ceiling]
                          ]),
                          _cache[43] || (_cache[43] = _createElementVNode("span", { class: "input-suffix" }, "%", -1))
                        ])
                      ])
                    ])
                  ])
                ], 512), [
                  [_vShow, 
					programTierOptionSelection.value ==
					_unref(OptionProgramTierTypes).CustomPricingTier
				  ]
                ])
              ], 512), [
                [_vShow, addPricingException.value]
              ]),
              _createElementVNode("div", _hoisted_101, [
                _cache[47] || (_cache[47] = _createElementVNode("label", { class: "title-label" }, "Override Reason Comments", -1)),
                _withDirectives(_createElementVNode("textarea", {
                  "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((overridePricing.value.freeFormReason) = $event)),
                  disabled: overridePricing.value.isReviewed,
                  id: "message",
                  maxlength: "512",
                  rows: "4",
                  class: "block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500",
                  placeholder: "Write up your comments here...",
                  required: ""
                }, null, 8, _hoisted_102), [
                  [_vModelText, overridePricing.value.freeFormReason]
                ]),
                _createElementVNode("div", _hoisted_103, _toDisplayString(remainingPricingCharacters.value) + " characters remaining ", 1)
              ]),
              _createElementVNode("div", _hoisted_104, [
                _createElementVNode("div", _hoisted_105, [
                  _createElementVNode("div", _hoisted_106, [
                    _withDirectives(_createElementVNode("input", {
                      type: "checkbox",
                      "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((overridePricing.value.isReviewed) = $event)),
                      placeholder: "Initiated By",
                      disabled: !!overridePricing.value.reviewDateTime,
                      required: ""
                    }, null, 8, _hoisted_107), [
                      [_vModelCheckbox, overridePricing.value.isReviewed]
                    ])
                  ]),
                  _cache[48] || (_cache[48] = _createElementVNode("span", { class: "checkbutton" }, " I acknowledge I reviewed and completed Application Override. ", -1)),
                  _cache[49] || (_cache[49] = _createElementVNode("div", { class: "checkfield" }, null, -1)),
                  _cache[50] || (_cache[50] = _createElementVNode("div", { class: "checkfield" }, null, -1))
                ])
              ]),
              _createElementVNode("div", _hoisted_108, [
                _createElementVNode("div", _hoisted_109, [
                  _createElementVNode("button", {
                    class: "draw-button submit",
                    onClick: _withModifiers(resetPricingForm, ["prevent"]),
                    disabled: !!overridePricing.value.reviewDateTime
                  }, " Cancel ", 8, _hoisted_110),
                  _createElementVNode("button", {
                    class: "draw-button submit",
                    type: "submit",
                    disabled: !!overridePricing.value.reviewDateTime
                  }, " Save ", 8, _hoisted_111)
                ])
              ])
            ])
          ])
        ], 32)
      ]),
      _: 1
    }, 8, ["disabled", "permissionIds", "permissionLevel"])
  ]))
}
}

})