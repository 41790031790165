import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "stip-modal" }

import { reactive, computed, ref, watch } from "vue";
import BaseFormField from "@/components/form-fields/BaseFormField.vue";
import { FieldSchema, FormSchema } from "@/models/form";
import { Applicant, Stipulation, LOSApplication } from "@/models/opal";
import { useStore } from "vuex";
import ModalOpal from "@/components/ModalOpal.vue";
import useValidation from "@/use/validation";
import { $api } from "@/services/api1";
import OpalButton from "@/components/shared/OpalButton.vue";
import functions from "@/use/functions";
import useStipulations from "@/use/stipulations";
import useFormatter from "@/use/formatter";
import useFunctions from "@/use/functions1";

interface Props {
  stipulations: Array<Stipulation>;
  application: LOSApplication;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'AddStipulationForm',
  props: {
    stipulations: {},
    application: {}
  },
  emits: ["closeModal", "getApplicationStips", "getApplicationComments", "stipsUpdated", "stipAdded"],
  setup(__props: any, { emit: __emit }) {

const store = useStore();
const emit = __emit;

const props = __props;
const { validateForm, validateStipulationOverrideText } = useValidation();
const { getApplicant, lenderName, lenderTFN } = useStipulations();
const { formatPhoneNumber } = useFormatter();
const { decodeHTMLEncodedStr, sortLosTypesByName, sortLosTypesByDescription } = useFunctions();

const noApplicantInput = ['RateTermSelection', 'CompleteLoanConfiguration', 'ConfirmLoanConfiguration']

const loading = ref<boolean>(false);
// needed for autocomplete baseformfield to work
let stipulationInfo = reactive({ stipulation: {}, form: {} }) as any;
const stipulationSchema = reactive({
  stipulation: {
    name: "stipulation",
    type: "autocomplete",
    autocompleteProperty: "description",
    autoCompleteResultsErrorMessage: "No matches found",
    hideLabel: false,
    label: "Stipulation Type",
    placeholder: "Search...",
    required: true
  } as FieldSchema,
  stipulationText: {
    name: "stipulationText",
    type: "textarea",
    subtype: "stipulationText",
    label: "Stipulation Text",
    required: true
  } as FieldSchema,
  applicantId: {
    name: "applicantId",
    type: "select",
    label: "Requested For",
    required: true
  } as FieldSchema,
  applicantDocumentId: {
    name: "applicantDocumentId",
    type: "select",
    hideLabel: true
  } as FieldSchema
}) as FormSchema;

const stipulations = computed(() => sortLosTypesByDescription(store.state.types['StipulationTypes']));
const applicantOptions = props.application.applicants.map((a: Applicant) => {
  return {
    label: `${a.fullName} - ${a.applicantTypeName}`,
    value: a.id
  }
});

function resetForm() {
  stipulationInfo = { stipulation: {}, form: {} }
}

async function addStipulation() {
  const valid = validateForm(stipulationSchema,
    {
      stipulation: stipulationInfo.stipulation,
      stipulationText: stipulationInfo.form.stipulationText,
      applicantId: stipulationInfo.form.applicantId,
    }
  );

  const stipulationForm = {
    stipulationTypeId: stipulationInfo.stipulation.id,
    overrideText: stipulationInfo.form.stipulationText ? stipulationInfo.form.stipulationText : null,
    addedByUserId: store.state.oidcStore.user.sub,
    applicantName: stipulationInfo.form.applicantId ? getApplicant(props.application.applicants, stipulationInfo.form.applicantId)?.fullName : null
  }

  if(!validateStipulationOverrideText(stipulationForm.overrideText))
  {
    functions.openModal({
          title: "Error",
          description: "Stipulation text update is required.",
        });
    return;
  }

  if (valid) {
    loading.value = true
    // applicant level stip
    if (stipulationInfo.form.applicantId && stipulationInfo.form.applicantId > 0) {
      const response = await $api.applications.addApplicantStipulation(props.application?.data?.id, stipulationInfo.form.applicantId, stipulationForm);
      if (response.id) {
        loading.value = false;
        emit('closeModal');
        emit('getApplicationStips');
        emit('getApplicationComments');
        // once the mapping is fixed we can hot-swap in the stip instead of calling endpoint to GET stips
        // emit('stipAdded', response);
      } else {
        functions.openModal({
          title: "Error",
          description: "There was an issue adding stipulation",
        });
      }
    }
    // application level stip
    else {
      const response = await $api.applications.addApplicationStipulation(props.application?.data?.id, stipulationForm);
      if (response.id) {
        loading.value = false;
        emit('closeModal');
        // once the mapping is fixed we can hot-swap in the stip instead of calling endpoint to GET stips
        // emit('stipAdded', response);
        emit('getApplicationStips');
        emit('getApplicationComments');
      } else {
        functions.openModal({
          title: "Error",
          description: "There was an issue adding stipulation",
        });
      }
    }
  }
}

function formatStipText(defaultText: string) {
  let formattedText = defaultText;
  if (defaultText) {
    if (defaultText.includes("{{LenderName}}")) {
      formattedText = defaultText.replaceAll("{{LenderName}}", props.application.data.lenderName);
    }
    if (defaultText.includes("{{Lenders/TollFreeNumber}}")) {
      const tfn = store.state.types["Lenders"].find((lender) => lender.id == props.application.data.lenderId)?.tollFreeNumber;
      const cleanTfn = `${tfn.replace(/()/g, "")}`;
      formattedText = defaultText.replaceAll("{{Lenders/TollFreeNumber}}", cleanTfn);
    }
  }
  return formattedText;
}

watch(() => stipulationInfo.stipulation, (value) => {
  if (stipulationInfo.stipulation.name) {
    stipulationSchema.stipulation.valid = true;
  }
  if (stipulationInfo.stipulation.defaultText !== "") {
    stipulationInfo.form.stipulationText = formatStipText(stipulationInfo.stipulation.defaultText);
  }

  // application-level stipulation
  if (stipulationInfo.stipulation?.name && noApplicantInput.includes(stipulationInfo.stipulation?.name?.trim()) ) {
    stipulationSchema.applicantId.required = false;
    stipulationInfo.form.applicantId = null;
  } else {
    stipulationSchema.applicantId.required = true;
  }
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ModalOpal, {
    onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('closeModal')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("form", {
          onSubmit: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (addStipulation()), ["prevent"]))
        }, [
          _createVNode(BaseFormField, {
            schema: stipulationSchema.stipulation,
            modelValue: _unref(stipulationInfo).stipulation,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(stipulationInfo).stipulation) = $event)),
            autocompleteOptions: stipulations.value
          }, null, 8, ["schema", "modelValue", "autocompleteOptions"]),
          (stipulationSchema.applicantId.required)
            ? (_openBlock(), _createBlock(BaseFormField, {
                key: 0,
                schema: stipulationSchema.applicantId,
                options: _unref(applicantOptions),
                modelValue: _unref(stipulationInfo).form.applicantId,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(stipulationInfo).form.applicantId) = $event)),
                modelModifiers: { number: true }
              }, null, 8, ["schema", "options", "modelValue"]))
            : _createCommentVNode("", true),
          _createVNode(BaseFormField, {
            schema: stipulationSchema.stipulationText,
            modelValue: _unref(stipulationInfo).form.stipulationText,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(stipulationInfo).form.stipulationText) = $event))
          }, null, 8, ["schema", "modelValue"]),
          _createVNode(OpalButton, {
            class: "submit",
            type: "submit",
            loading: loading.value,
            text: "Add"
          }, null, 8, ["loading"])
        ], 32)
      ])
    ]),
    _: 1
  }))
}
}

})