import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "xdoublebox" }
const _hoisted_2 = {
  key: 0,
  class: "xdoublebox-inner-inner"
}
const _hoisted_3 = ["data-color"]
const _hoisted_4 = {
  class: "xusericon",
  "data-size": "medium",
  style: {"float":"left","margin-right":"10px"}
}
const _hoisted_5 = { class: "xusericon-picture" }
const _hoisted_6 = { style: {"line-height":"50px"} }
const _hoisted_7 = { class: "title-section" }
const _hoisted_8 = { class: "risk-title" }
const _hoisted_9 = { class: "risk-id" }
const _hoisted_10 = { class: "risk-section" }
const _hoisted_11 = { class: "title-section" }
const _hoisted_12 = { class: "risk-title" }
const _hoisted_13 = { class: "id-description" }
const _hoisted_14 = { class: "risk-description" }
const _hoisted_15 = { class: "risk-id" }
const _hoisted_16 = { class: "risk-section" }
const _hoisted_17 = { class: "title-section" }
const _hoisted_18 = { class: "risk-title" }
const _hoisted_19 = { class: "id-description" }
const _hoisted_20 = { class: "risk-description" }
const _hoisted_21 = { class: "risk-id" }
const _hoisted_22 = { class: "risk-section" }
const _hoisted_23 = { class: "title-section" }
const _hoisted_24 = { class: "risk-title" }
const _hoisted_25 = { class: "id-description" }
const _hoisted_26 = { class: "risk-description" }
const _hoisted_27 = { class: "risk-id" }
const _hoisted_28 = { class: "risk-section" }
const _hoisted_29 = { class: "title-section" }
const _hoisted_30 = { class: "risk-title" }
const _hoisted_31 = { key: 0 }
const _hoisted_32 = { class: "high-risk-description" }
const _hoisted_33 = { key: 1 }
const _hoisted_34 = { class: "id-createDate" }
const _hoisted_35 = {
  key: 1,
  class: "xdoublebox-inner-inner"
}
const _hoisted_36 = {
  class: "xusericon",
  "data-size": "medium",
  style: {"float":"left","margin-right":"10px"}
}
const _hoisted_37 = { class: "xusericon-picture" }
const _hoisted_38 = { style: {"line-height":"50px"} }

import { computed, ref } from "vue";
  import Loader from "@/components/Loader.vue";
  import useFunctions from "@/use/functions1";
  import moment from "moment";
  import { Permissions, PermissionLevels } from "@/models/permissions";
  import PermissionsButton from "@/components/wrappers/PermissionsButton.vue";
  
  interface Props {
    applicants: any;
    getLexisNexisReport: any;
    cvi: { id: string; description: string };
    nas: { id: string; description: string };
    nap: { id: string; description: string };
    riskIndicatorDescription: any;
    canEdit: boolean;
  }

  
export default /*@__PURE__*/_defineComponent({
  __name: 'IdentityResults',
  props: {
    applicants: {},
    getLexisNexisReport: {},
    cvi: {},
    nas: {},
    nap: {},
    riskIndicatorDescription: {},
    canEdit: { type: Boolean }
  },
  setup(__props: any) {

  const reloadKey = ref(0);
  const lexisNexisReport = ref(null);

  const loadingStates = ref<{ [key: string]: boolean }>({});

  const { decodeHTMLEncodedStr } = useFunctions();

  const props = __props;

  const buttonDetails = computed(() => {
    return ["primary", "secondary"].map((person) => {
      const identificationResult =
        props.applicants[person]?.identityData?.[0]?.identificationResult
          ?.reviewStatus;
      return {
        color: identificationResult == "pass" ? "green" : "red",
        label: identificationResult == "pass" ? "Passed" : "Failed",
      };
    });
  });

  const createDate = (date: string | null = null) => {
    return moment(date).format("MM/DD/YYYY, h:mm A z");
  };

  
const handleLexisNexisReport = async (person: string) => {
  loadingStates.value[person] = true;
  try {
    lexisNexisReport.value = await props.getLexisNexisReport(person);
  setTimeout(() => {
    window.location.reload();
  }, 500);
} catch (error) {
    loadingStates.value[person] = false;
  }
};

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "xpage-content",
    key: reloadKey.value,
    id: "targetDiv"
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(['primary', 'secondary'], (person, index) => {
        return _createElementVNode("div", {
          class: "xdoublebox-inner",
          key: index
        }, [
          (_ctx.applicants[person]?.verification)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", null, [
                  _createElementVNode("button", {
                    class: "xlarge-result",
                    "data-color": buttonDetails.value[index].color,
                    style: {"float":"right","margin":"0 0 10px 0","padding":"7px 40px"}
                  }, _toDisplayString(buttonDetails.value[index].label), 9, _hoisted_3),
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.applicants[person].initials), 1),
                    _cache[0] || (_cache[0] = _createElementVNode("div", {
                      class: "xusericon-gender",
                      "data-gender": ""
                    }, null, -1))
                  ]),
                  _createElementVNode("h3", _hoisted_6, _toDisplayString(_unref(decodeHTMLEncodedStr)(_ctx.applicants[person].firstName)) + " " + _toDisplayString(_unref(decodeHTMLEncodedStr)(_ctx.applicants[person].lastName)), 1),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _cache[1] || (_cache[1] = _createTextVNode(" Verification ID: ")),
                      _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.applicants[person].identityData?.[0]?.identityVerificationId), 1)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _cache[2] || (_cache[2] = _createTextVNode(" CVI Index: ")),
                      _createVNode(_component_font_awesome_icon, {
                        icon: ['fas', 'circle-info'],
                        class: "question-icon",
                        title: "Comprehensive Verification Index. The CVI is an overall evaluation of how well the consumer’s data was verified. CVI returns a value between 00 (nothing was verified) to 50 (everything was verified) in increments of 10. It is calculated using the combined NAP and NAS indices, along with a handful of optional overrides. "
                      })
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.applicants[person].cvi.description), 1),
                    _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.applicants[person].cvi.id), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, [
                      _cache[3] || (_cache[3] = _createTextVNode(" NAP Index: ")),
                      _createVNode(_component_font_awesome_icon, {
                        icon: ['fas', 'circle-info'],
                        class: "question-icon",
                        title: "An evaluation of how well LexisNexis Risk Solutions was able to match the input first name, last name, address, and phone number. The index returns as a value of 1 through 12 based on the elements that were matched."
                      })
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.applicants[person].nap.description), 1),
                  _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.applicants[person].nap.id), 1)
                ]),
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("div", _hoisted_23, [
                    _createElementVNode("div", _hoisted_24, [
                      _cache[4] || (_cache[4] = _createTextVNode(" NAS Index: ")),
                      _createVNode(_component_font_awesome_icon, {
                        icon: ['fas', 'circle-info'],
                        class: "question-icon",
                        title: "An evaluation of how well LexisNexis Risk Solutions was able to match the input first name, last name, address, and SSN. The index returns as a value of 1 through 12 based on the elements that were matched."
                      })
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_25, [
                    _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.applicants[person].nas.description), 1),
                    _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.applicants[person].nas.id), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_28, [
                  _createElementVNode("div", _hoisted_29, [
                    _createElementVNode("div", _hoisted_30, [
                      _cache[5] || (_cache[5] = _createTextVNode(" High Risk Indicators: ")),
                      _createVNode(_component_font_awesome_icon, {
                        icon: ['fas', 'circle-info'],
                        class: "question-icon",
                        title: "Up to six risk indicators may be returned per identity searched. These should be used to detect anomalous conditions that are separate from the ability to verify the input data."
                      })
                    ])
                  ]),
                  (
                _ctx.applicants[person]?.instantIdRiskIndicatorCodes?.length > 0 &&
                _ctx.applicants[person]?.instantIdRiskIndicatorCodes
              )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.applicants[person]
                  ?.instantIdRiskIndicatorCodes, (riskIndicatorCode) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: "id-description high-risk-codes",
                            key: riskIndicatorCode
                          }, [
                            _createElementVNode("span", _hoisted_32, _toDisplayString(riskIndicatorCode.description ||
                    "Nothing found for input criteria"), 1)
                          ]))
                        }), 128))
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_33, _cache[6] || (_cache[6] = [
                        _createElementVNode("div", { class: "id-description" }, [
                          _createElementVNode("span", { class: "risk-description" }, "Nothing found for input criteria")
                        ], -1)
                      ])))
                ]),
                _createElementVNode("div", _hoisted_34, " Data as of " + _toDisplayString(createDate(
                `${_ctx.applicants[person]?.identityData?.[0].lastChangedAt}Z`
              ) || "Identity data not found"), 1)
              ]))
            : (_ctx.applicants[person])
              ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_36, [
                      _createElementVNode("div", _hoisted_37, _toDisplayString(_ctx.applicants[person].initials), 1),
                      _cache[7] || (_cache[7] = _createElementVNode("div", {
                        class: "xusericon-gender",
                        "data-gender": ""
                      }, null, -1))
                    ]),
                    _createElementVNode("h3", _hoisted_38, _toDisplayString(_ctx.applicants[person]?.firstName) + " " + _toDisplayString(_ctx.applicants[person]?.lastName), 1),
                    _createVNode(PermissionsButton, {
                      permissionIds: [_unref(Permissions).LexisNexis],
                      permissionLevel: _unref(PermissionLevels).Update,
                      disabled: !_ctx.canEdit,
                      showDisabledIfNotVisible: true,
                      onClick: ($event: any) => (handleLexisNexisReport(person)),
                      class: "xlarge"
                    }, {
                      default: _withCtx(() => [
                        (loadingStates.value[person])
                          ? (_openBlock(), _createBlock(Loader, { key: 0 }))
                          : _createCommentVNode("", true),
                        _createTextVNode(" " + _toDisplayString(loadingStates.value[person]
                  ? "Pulling Lexis Nexis..."
                  : "Pull Lexis Nexis"), 1)
                      ]),
                      _: 2
                    }, 1032, ["permissionIds", "permissionLevel", "disabled", "onClick"])
                  ])
                ]))
              : _createCommentVNode("", true)
        ])
      }), 64))
    ])
  ]))
}
}

})