import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "banner-text" }

import { ref, computed } from "vue";

	export interface FeedbackData {
		status: number;
		title: string;
		message: string;
		type: "error" | "success";
		text?: string;
	}

	
export default /*@__PURE__*/_defineComponent({
  __name: 'FeedbackBanner',
  props: {
    feedbackDataArray: {},
    type: {}
  },
  emits: ["closeBanner"],
  setup(__props: any, { emit: __emit }) {

	const props = __props;
	
	const emit = __emit;
	const isOpen = ref(false);

	const closeBanner = () => {
		emit("closeBanner");
	};

	const bannerClass = computed(() => {
		return props.type === "error" ? "banner-error" : "banner-success";
	});

	const iconClass = computed(() => {
		return  props.type === "error" ? "icon-ok-circle" : "icon-ok-circle";
	});

	const statusClass = (status: number) => {
		return status < 400 ? `status-success-${status}` : `status-error-${status}`;
	};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([bannerClass.value])
  }, [
    _createVNode(_TransitionGroup, {
      name: "fade",
      tag: "div"
    }, {
      default: _withCtx(() => [
        (_openBlock(), _createElementBlock("div", {
          class: "banner-close",
          onClick: closeBanner,
          key: `close`
        }, "X")),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.feedbackDataArray, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: `item-${index}`,
            class: _normalizeClass(["banner-header", {hoverclass: !isOpen.value, [statusClass(item.status)]: true}]),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (isOpen.value = !isOpen.value))
          }, [
            _createElementVNode("i", {
              class: _normalizeClass(iconClass.value)
            }, null, 2),
            _createElementVNode("span", _hoisted_1, [
              _cache[1] || (_cache[1] = _createElementVNode("strong", null, "Status:", -1)),
              _createTextVNode(" " + _toDisplayString(item.status) + " | ", 1),
              _cache[2] || (_cache[2] = _createElementVNode("strong", null, "Title:", -1)),
              _createTextVNode(" " + _toDisplayString(item.title) + " | ", 1),
              _cache[3] || (_cache[3] = _createElementVNode("strong", null, "Message:", -1)),
              _createTextVNode(" " + _toDisplayString(item.message), 1)
            ])
          ], 2))
        }), 128))
      ]),
      _: 1
    })
  ], 2))
}
}

})