import axios from "axios";

async function get(url) {

	return await axios(url, {require:'json'}).then((response) => {

		return response;
				
	}).catch((error) => { 
		
		console.log(`API Failed: ${url}`, error); 
		return false;
	
	});

}

async function post(url) {

	return await axios(url, {require:'json'}).then((response) => {

		return response;
				
	}).catch((error) => { 
		
		console.log(`API Failed: ${url}`, error); 
		return false;

	});

}

var $api = {
	signout: async (unifiId) => {
		try {
			const response = await axios(`/api/account/signout/${unifiId}`);
			return response.data || [];
		} catch (error) {
			console.log("Error", error);
		}
	},
	getApplicationById: async (id) => { 
		return await get(`/api/applications/${id}`);
	},
	getLenders: async () => {
		return await get(`/api/lenders`);
	},
	getApplicationComments: async (id) => {
		return await get(`/api/applications/${id}/comments`);
	},
	getCreditReport: async (id) => {
		return await get(`/api/credits/${id}`);
	},
	getDocumentsByApplication: async (id) => {
		return await get(`/api/documents/application/${id}`);
	},
	getDocumentsByApplicant: async (id) => {
		return await get(`/api/documents/applicant/${id}`);
	},
	getCreditsByApplication: async (id) => {
		return await get(`/api/applications/${id}/credits`);
	},
	getIdentityByApplicant: async (id) => {
		return await get(`/api/applicants/${id}/identityverifications`);
	},
	getRatePlanByApplication: async (id) => {
		return await get(`/api/applications/${id}/rateplans`);
	},
	getRatePlanSingleByApplication: async (id) => {
		return await get(`/api/applications/${id}/rateplan/${id}`);
	},
	getType: async (type) => {
		return await get(`/api/types/${type}`);
	},
	getAuthorizedThirdParty: async (id) => {
		return await get(`/api/applications/${id}/authorizedusers`);
	}
};

export {$api};